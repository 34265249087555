/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useField } from 'formik';
import { Input } from 'semantic-ui-react';

export const CustomInput = ({
  labelText,
  getValue,
  requiredStar,
  defaultValue = '',
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;
  return (
    <div className="formik-custom-field act-inputs-new">
      <label
        className={requiredStar ? 'required-stars' : ''}
        htmlFor={props.id || props.name}
      >
        {labelText}
      </label>
      <Input
        {...field}
        {...props}
        onChange={(e, data) => {
          setValue(data.value);
          getValue && getValue(data.value);
        }}
        value={value}
        error={meta.touched && meta.error}
      />
      {meta.touched && meta.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
    </div>
  );
};
