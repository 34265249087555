import React from 'react';

import { CustomSegment } from '.';
export const IssuesBox = (props) => {
  return (
    <CustomSegment
      extraBottomMargin
      noPrint
      title={props.title}
      children={
        <div className={props.isInfo ? 'issues-box-info' : 'issues-box'}>
          <div className="padding-issue">
            {props.messages.map((item, i) => {
              return (
                <ul key={i}>
                  <li key={i}> {item}</li>
                </ul>
              );
            })}
          </div>
        </div>
      }
    />
  );
};
