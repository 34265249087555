import './auth.scss';

import React from 'react';

import { AvField, AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Container, Grid } from 'semantic-ui-react';
import { APP_NAME } from '../../utils/constant';
import { forgotPassword } from '../../store/actions';
import { Link } from 'react-router-dom';

class ForgotPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: false,
    };
  }

  componentDidMount() {
    document.title = `Forgot Password | ${APP_NAME}`;
  }

  async handleValidSubmit(event, values) {
    const { forgotPassword } = this.props;
    if (await forgotPassword(values.email)) {
      this.setState({ email: values.email });
    }
  }

  render() {
    const { forgotPassLoading } = this.props;
    const { email } = this.state;
    return (
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={8} />
        <Grid.Column mobile={16} tablet={16} computer={8}>
          {email ? (
            <Container className="right-heading-container">
              {/* <div className="heading-row"> */}
              <label className="headerText">Forgot password?</label>
              <br></br>
              <label className="headerDescription">
                We will help you back in
              </label>
              <label className={'recoverySub'}>
                We sent a recovery link to you at
              </label>
              <label className={'recoveryEmail'}>{email}</label>
              <Link to={'/signin'}>
                <label className={'backToSignIn'}>Go back to Sign In</label>
              </Link>
              <div className={'footerContainer'}>
                <label className={'footerContent'}>
                  Please contact our support team if you dont have access to
                  your email.
                </label>
                <br></br>
                <a
                  className="contactUs"
                  href="mailto:support@takso-outcomes.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact Us
                </a>
              </div>
              {/* </div> */}
            </Container>
          ) : (
            <Container className="right-heading-container">
              {/* <div className="heading-row"> */}
              <label className="headerText">Forgot your password?</label>
              <label className="headerDescription">
                We'll help you back in.
              </label>
              <br></br>
              <label className="headerNotification">
                Enter the email address you used when you joined and we willl
                email you instructions to reset your password.
              </label>
              <AvForm
                className={'form-group-conatiner'}
                onValidSubmit={(event, values) =>
                  this.handleValidSubmit(event, values)
                }
              >
                <AvField
                  label="Email"
                  className="signin-inputs"
                  placeholder="Enter your email"
                  name="email"
                  type="email"
                  errorMessage="Please enter valid email address"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Email is required',
                    },
                  }}
                />
                <br />
                <Button
                  type="submit"
                  className="btn btn-primary signin-button"
                  loading={forgotPassLoading}
                  disabled={forgotPassLoading}
                >
                  Reset Password
                </Button>
                <Link to={'/signin'}>
                  <label className={'goBackSignIn'}>Go back to Sign In</label>
                </Link>
              </AvForm>
              {/* </div> */}
            </Container>
          )}
        </Grid.Column>
        <Grid.Column mobile={16} tablet={5} computer={5} />
      </Grid.Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.authentication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      forgotPassword,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
