/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useField } from 'formik';
import { TextArea } from 'semantic-ui-react';

export const CustomTextArea = ({ labelText, requiredStar, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="formik-custom-field act-inputs-new">
      <label
        className={requiredStar ? 'required-stars' : ''}
        htmlFor={props.id || props.name}
      >
        {labelText}
      </label>
      <textarea
        {...field}
        {...props}
        error={meta.touched ? meta.error : 'false'}
      />
      {meta.touched && meta.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
    </div>
  );
};
