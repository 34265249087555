import React from 'react';
import { Message } from 'semantic-ui-react';
import { get } from '../utils/lodash';
import './styles.scss';

export const EvalPlanDisable = (props) => (
  <div className="locked-activity">
    <Message negative>
      <Message.Header>
        {get(props, 'haveResponses', false)
          ? 'Evaluation Closed'
          : 'Evaluation Disabled'}
      </Message.Header>
      <p>
        {get(props, 'haveResponses', false)
          ? 'This evaluation plan has been closed and no further changes can be made.'
          : 'This evaluation did not take place, participant outcomes have been surveyed in another evaluation plan.'}
      </p>
      <p>
        {get(props, 'disabledReason', null)
          ? `Reason: ${get(props, 'disabledReason', null)}`
          : ''}
      </p>
    </Message>
  </div>
);
