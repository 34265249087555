import './layout.scss';

import React, { Fragment, useEffect, useState } from 'react';

import { isMobile, isTablet } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dimmer, Loader } from 'semantic-ui-react';

import { WebSideBar } from '../../containers/sideBar/sideBar';
import { ALL_ROUTES, ROUTES } from '../../utils/constant';
import { find, isEmpty } from '../../utils/lodash';
import {
  getActivityHelpTips,
  getHelpPages,
  getUserProfile,
} from '../../store/actions';
import { isIndividualUser } from '../../utils/activity/userPermission';
import { MobileSideBar } from '../sideBar/mobileSideBar';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const Layout = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);

  const [state, setState] = useState({
    getUser: true,
    path: null,
    isAdminRoute: false,
  });

  const checkIsAdminRoute = (path) => {
    try {
      const route = find(ROUTES.adminRoutes, (obj) => {
        if (obj.route === path || obj.subRoutes.includes(path)) {
          return obj;
        }
      });
      return !isEmpty(route);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  useEffect(() => {
    setState((prevState) => ({ ...prevState, getUser: true }));

    const fetchData = async () => {
      try {
        dispatch(getHelpPages());
        dispatch(getActivityHelpTips());
        await dispatch(getUserProfile());
        const {
          match: { path },
        } = props;
        if (isIndividualUser(user)) {
          user.role = 'individualAdmin';
        }
        setState((prevState) => ({
          ...prevState,
          isAdminRoute: checkIsAdminRoute(path),
        }));
        const route = find(ALL_ROUTES, (data) => {
          if (data.route === path || data.subRoutes.includes(path)) {
            return data;
          }
        });

        if (route && !route.permission.includes(user.role)) {
          navigate('/dashboard');
        }
        setState((prevState) => ({ ...prevState, getUser: false, path }));
      } catch (error) {
        console.error('error: ', error);
        setState((prevState) => ({ ...prevState, getUser: false }));
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (props.match.path !== state.path) {
      const { path } = state;
      if (isIndividualUser(user)) {
        user.role = 'individualAdmin';
      }

      setState((prevState) => ({
        ...prevState,
        path: props.match.path,
        isAdminRoute: checkIsAdminRoute(path),
      }));

      const route = find(ALL_ROUTES, (data) => {
        if (data.route === path || data.subRoutes.includes(path)) {
          return data;
        }
      });

      if (route && !route.permission.includes(user.role)) {
        navigate('/dashboard');
      }
    }
  }, [props.match.path]);

  const { getUser, isAdminRoute } = state;

  return (
    <div className={isMobile ? null : 'main-layout'}>
      {getUser ? (
        <div className="loadingContainer">
          <Dimmer active>
            <Loader size="massive" />
          </Dimmer>
        </div>
      ) : (
        <Fragment>
          {isMobile || isTablet ? (
            <MobileSideBar {...props} isAdminRoute={isAdminRoute} />
          ) : (
            <WebSideBar
              notify={props.notificationDOMRef}
              {...props}
              isAdminRoute={isAdminRoute}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default Layout;
