import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getAllParentChartPreviewData,
  getProgramIncludedReportCharts,
  getAllParentChartData,
  getProgrammeReportDataByActivityType,
  getParentOutcomeReported,
} from '../../../store/actions';
import { map, isEmpty, get, isArray, compact } from '../../../utils/lodash';
import './activity.scss';
import { CustomSegment } from '../../../components';
import { Segment } from 'semantic-ui-react';
import { ProgrammeActivity } from '../../reports/reportHome/Program/ProgrammeActivity';
import { ProgrammeOutcomeAddress } from '../../reports/reportHome/Program/ProgrammeOutcomeAddress';
import {
  AggregateChart,
  IndividualRateOfChangeChart,
  SeparateChart,
  TrendRateOfChangeChart,
} from '../../../components/chartsWidgets';

const ParentOutcomeCharts = (props) => {
  const { parentReportOutcomeReport } = props;

  const RenderChart = (props) => {
    const { type, data, outcomeTitle } = props;

    if (!data) return <></>;

    const _dataset = {
      name: outcomeTitle,
      totalParticipants: get(data, 'totalParticipants', 0),
      activityCount: get(data, 'activityCount', 0),
      evalCount: get(data, 'evalCount', 0),
      programsActivityCount: get(data, 'programsActivityCount', 0),
      linkedActivityCount: get(data, 'linkedActivityCount', 0),
      totalRespondents: get(data, 'totalRespondents', 0),
      results: get(data, 'data', []),
      participantResponse: get(data, 'data', []),
      data: get(data, 'data', []),
      dataAll: get(data, 'dataAll', []),
    };

    switch (type) {
      case 'AGGREGATE':
        return <AggregateChart method={1} participants={_dataset} />;

      case 'SEPERATE':
        return <SeparateChart participants={_dataset} />;
      case 'TREND':
        return <TrendRateOfChangeChart participantsChangePrePost={_dataset} />;
      case 'INDIVIDUAL':
        return <IndividualRateOfChangeChart participantsChange={_dataset} />;
      case 'INTERCEPT_AGGREGATE':
        return <AggregateChart method={12} participants={_dataset} />;
      case 'INTERCEPT_SEPERATE':
        return <SeparateChart method={12} participants={_dataset} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      {map(parentReportOutcomeReport, (data, i) => {
        const outcomeTitle = get(data[0], 'outcome.title', '');
        const policyName = get(data[0], 'outcome.policy.policyName', '');

        const isChartDataEmpty = isEmpty(
          compact(data.map((item) => item.chartData))
        );

        return isChartDataEmpty ? (
          <></>
        ) : (
          <CustomSegment
            key={`${outcomeTitle}_${policyName}_${i}`}
            title={`Actual Outcomes - ${outcomeTitle} (${policyName})`}
            children={
              <div className="p-5 bg-white">
                {data.map((item) =>
                  item.chartData ? (
                    <div className="mb-5">
                      <RenderChart
                        type={item.type}
                        outcomeTitle={outcomeTitle}
                        data={item.chartData}
                      />
                    </div>
                  ) : (
                    <></>
                  )
                )}
              </div>
            }
            extraMargin
            toggle={false}
            attached
          />
        );
      })}
    </>
  );
};

class ParentReportCharts extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    try {
      const {
        // getAllParentChartPreviewData,
        currentActivity,
        // getAllImages,
        // getProgramIncludedReportCharts,
        getParentOutcomeReported,
        // getAllParentChartData,
        getProgrammeReportDataByActivityType,
      } = this.props;

      const filters = {
        statuses: [3],
        organisationLevel: 'all',
        usedDataOnly: true,
      };

      await getProgrammeReportDataByActivityType(
        'activities',
        filters,
        currentActivity.id
      );

      await getProgrammeReportDataByActivityType(
        'outcomes-addressed',
        filters,
        currentActivity.id
      );

      await getParentOutcomeReported(currentActivity.id);
      // await getProgramIncludedReportCharts(currentActivity.id);
      // await getAllImages({ getAllGraphImages: true, reportLoaded: true });
      // await getAllParentChartPreviewData(currentActivity.id); // outcome call
      // await getAllParentChartData(currentActivity.id);
      // await getAllImages({
      //   getAllGraphImages: false,
      //   reportLoaded: false,
      // });
    } catch (error) {
      // console.error("ParentReportCharts -> componentDidMount -> error", error)
    }
  }

  render() {
    const {
      currentActivity,
      // outcomes,
      // participantsChangePrePost,
      // participants,
      // programIncludedReports,
      parentReportOutcomeReport,
    } = this.props;

    // let changePrePost = cloneDeep(participantsChangePrePost);

    // let newParticipants = [],
    //   byMethodIntercept = [];
    // for (const item of participants) {
    //   let getIntercept = find(item.byMethod, (o) => {
    //     return o.methodId === 12;
    //   });

    //   if (getIntercept !== undefined) {
    //     byMethodIntercept.push(getIntercept);
    //   }

    //   let sameOutcomes = find(participantsChangePrePost, (o) => {
    //     return o.id === item.id;
    //   });

    //   if (sameOutcomes === undefined) {
    //     newParticipants.push(item);
    //   }
    // }

    // if (!isEmpty(byMethodIntercept)) {
    //   byMethodIntercept = uniqBy(byMethodIntercept, (o) => {
    //     return o.methodId;
    //   });
    // }

    // for (const changePre of changePrePost) {
    //   changePre.byMethod.push(...byMethodIntercept);
    // }

    // let newDataSet = newParticipants.concat(changePrePost);

    // if (
    //   !isEmpty(currentActivity) &&
    //   !isEmpty(outcomes) &&
    //   !isEmpty(flatten(newDataSet))
    // ) {
    //   map(newDataSet, (data) => {
    //     const getOutcome = find(outcomes, (o) => {
    //       return o.title === data.name;
    //     });

    //     if (!isEmpty(getOutcome)) {
    //       const domain = find(setParentDomains(currentActivity), (o) => {
    //         return o.policyId === getOutcome.policyId;
    //       });
    //       if (domain && domain.policy) {
    //         data.domain = domain.policy.policyName;
    //         data.policyValue = domain.policy.id;
    //       }
    //     }
    //   });
    // }

    const reportType = 'outcomes-addressed';

    const reportCharts = get(currentActivity, 'reportCharts');
    // activities charts
    const includeTypeChart = get(
      reportCharts,
      'activitiesCharts.includeTypeChart',
      false
    );
    const includeVenuesChart = get(
      reportCharts,
      'activitiesCharts.includeVenuesChart',
      false
    );
    const includeParticipantsChart = get(
      reportCharts,
      'activitiesCharts.includeParticipantsChart',
      false
    );
    const includeActivitiesMap = get(
      reportCharts,
      'activitiesCharts.includeActivitiesMap',
      false
    );
    const includeContractorsMap = get(
      reportCharts,
      'activitiesCharts.includeContractorsMap',
      false
    );

    // Outcome Address chart
    // const includeOutcomeAddressChart = !isEmpty(
    //   get(reportCharts, 'outcomeAddressedCharts', false)
    // );

    return (
      <Fragment>
        {get(reportCharts, 'activitiesCharts.haveReports', false) ? (
          <CustomSegment
            title="Activities"
            children={
              <>
                <Segment className="chart-holder">
                  <ProgrammeActivity
                    parent
                    inParentReportPage={true}
                    // dateRangeChosenLabel={dateRangeChosenLabel}
                    includeTypeChart={includeTypeChart}
                    includeVenuesChart={includeVenuesChart}
                    includeParticipantsChart={includeParticipantsChart}
                    includeActivitiesMap={includeActivitiesMap}
                    includeContractorsMap={includeContractorsMap}
                  />
                </Segment>
              </>
            }
            extraMargin
            toggle={false}
            attached
          />
        ) : (
          <></>
        )}

        {/* <CustomSegment
          title="Outcomes Addressed 2"
          children={
            <>
              {' '}
            
            </>
          }
          extraMargin
          toggle={false}
          attached
        /> */}
        {!isEmpty(get(reportCharts, 'outcomeAddressedCharts', [])) ? (
          <ProgrammeOutcomeAddress
            parent
            inParentReportPage={true}
            // onTypeChange={(value) => this.selectOutcomes(value)}
            setOutcomeValue={(reportType, id, selectedOutcome) =>
              this.setOutcomeValue(reportType, id, selectedOutcome)
            }
            currentActivity={currentActivity}
            // dateRangeChosenLabel={dateRangeChosenLabel}
            reportType={reportType}
            activityId={currentActivity.id}
          />
        ) : (
          <></>
        )}
        {!isEmpty(get(reportCharts, 'outcomeCharts', [])) ? (
          <ParentOutcomeCharts
            parentReportOutcomeReport={parentReportOutcomeReport}
          />
        ) : (
          <></>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { activity } = state;

  const participants =
    state.report &&
    state.report.participants &&
    isArray(state.report.participants)
      ? state.report && state.report.participants
      : [];

  const participantsChangePrePost =
    state.report &&
    state.report.participantsChangePrePost &&
    isArray(state.report.participantsChangePrePost)
      ? state.report && state.report.participantsChangePrePost
      : [];

  return {
    currentActivity: activity.currentActivity,
    actLoading: state.report.loading,
    participants,
    participantsChangePrePost,
    chartData: [...participants, ...participantsChangePrePost],
    outcomes: state.extra.outcomes,
    programIncludedReports: activity.programIncludedReports,
    participantsChange: state.report.participantsChange,
    parentReportOutcomeReport: state.report.parentReportOutcomeReport,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllParentChartPreviewData,
      getProgramIncludedReportCharts,
      getAllParentChartData,
      getProgrammeReportDataByActivityType,
      getParentOutcomeReported,
    },
    dispatch
  );
};

ParentReportCharts = connect(
  mapStateToProps,
  mapDispatchToProps
)(ParentReportCharts);

export { ParentReportCharts };
