/* eslint-disable eqeqeq */
/* eslint-disable no-eval */
import { size } from 'lodash';
import { getFormatOutputValidity } from '.';
import {
  cloneDeep,
  compact,
  difference,
  find,
  flattenDeep,
  get,
  isEmpty,
  isEqual,
  isString,
  map,
  sortedUniq,
  uniq,
} from './lodash';
import {
  isFestivalProgramActivity,
  isParentProgramActivity,
  isSingleAndLinkedActivity,
} from './activity';

export function projectValidation(activityProject) {
  const workingProject = cloneDeep(activityProject);

  let goalStatus = find(
    workingProject.completionProgress,
    (o) => o.section === 'Goal'
  );

  let objectiveStatus = find(
    workingProject.completionProgress,
    (o) => o.section === 'Objective'
  );
  let evidenceStatus = find(
    workingProject.completionProgress,
    (o) => o.section === 'Evidence'
  );
  let theoryStatus = find(
    workingProject.completionProgress,
    (o) => o.section === 'Theory of Change'
  );
  let activityPlanStatus = find(
    workingProject.completionProgress,
    (o) => o.section === 'Activity Plan' || o.section === 'Activity Plan'
  );
  let evaluationStatus = find(
    workingProject.completionProgress,
    (o) => o.section === 'Evaluation'
  );
  let documentationStatus = find(
    workingProject.completionProgress,
    (o) => o.section === 'Documentation'
  );

  let reportStatus = find(
    workingProject.completionProgress,
    (o) => o.section === 'Report'
  );

  // need to have all status otherwise data is not loaded properly or data loading status
  if (
    !isEmpty(goalStatus) &&
    !isEmpty(objectiveStatus) &&
    !isEmpty(evidenceStatus) &&
    !isEmpty(theoryStatus) &&
    !isEmpty(activityPlanStatus) &&
    !isEmpty(evaluationStatus) &&
    !isEmpty(documentationStatus) &&
    !isEmpty(reportStatus)
  ) {
    objective();
    evidence();
    theoryOfChange();
    activityPlan();
    evaluation();
    documentation();
    report();
  }

  function objective() {
    if (
      workingProject.completionProgress &&
      workingProject.completionProgress.length > 0
    ) {
      if (isParentProgramActivity(workingProject.activityPlanTypeId)) {
        if (!isEmpty(workingProject.parentOutcomes)) {
          const outcomesSelected = map(
            workingProject.parentOutcomes,
            (outcome) => outcome.policyValue
          );
          const domainsSelected = map(
            workingProject.parentDomains,
            (domain) => domain.policyId
          );
          if (workingProject.primaryPolicyId)
            domainsSelected.push(workingProject.primaryPolicyId);
          if (workingProject.secondaryPolicyId)
            domainsSelected.push(workingProject.secondaryPolicyId);

          const diff = difference(
            sortedUniq(domainsSelected),
            sortedUniq(outcomesSelected)
          );

          if (!isEmpty(diff)) {
            if (goalStatus.status == 2) {
              objectiveStatus.status = 1;
            } else {
              objectiveStatus.status = 0;
            }
          } else {
            objectiveStatus.status = 2;
          }
        } else {
          if (goalStatus.status == 2) {
            objectiveStatus.status = 1;
          } else {
            objectiveStatus.status = 0;
          }
        }
      } else {
        if (
          workingProject.objective &&
          workingProject.objective.length == 1 &&
          !workingProject.secondaryPolicyId
        ) {
          objectiveStatus.status = 2;
        } else if (
          workingProject.objective &&
          workingProject.objective.length == 2 &&
          workingProject.secondaryPolicyId
        ) {
          objectiveStatus.status = 2;
        } else {
          if (goalStatus.status == 2) {
            objectiveStatus.status = 1;
          } else {
            objectiveStatus.status = 0;
          }
        }
      }
    }
  }

  function evidence() {
    if (
      workingProject.completionProgress &&
      workingProject.completionProgress.length > 0
    ) {
      if (isParentProgramActivity(workingProject.activityPlanTypeId)) {
        if (isEmpty(workingProject.evidence)) {
          if (goalStatus.status === 2) {
            evidenceStatus.status = 1;
          } else {
            evidenceStatus.status = 0;
          }
        } else {
          evidenceStatus.status = 2;
        }
      } else {
        if (workingProject.objective && workingProject.objective.length) {
          if (
            workingProject.objective.length == 1 &&
            !workingProject.secondaryPolicyId
          ) {
            if (workingProject.objective[0].evidence.length > 0) {
              evidenceStatus.status = 2;
            } else {
              if (goalStatus.status == 2) {
                evidenceStatus.status = 1;
              } else {
                evidenceStatus.status = 0;
              }
            }
          } else if (
            workingProject.objective.length == 2 &&
            workingProject.secondaryPolicyId
          ) {
            if (
              typeof workingProject.objective[0].evidence === 'undefined' ||
              typeof workingProject.objective[0].evidence === 'undefined'
            ) {
              if (goalStatus.status == 2) {
                evidenceStatus.status = 1;
              } else {
                evidenceStatus.status = 0;
              }
              return;
            }
            if (
              workingProject.objective[0].evidence.length > 0 &&
              workingProject.objective[1].evidence.length > 0
            ) {
              evidenceStatus.status = 2;
            } else {
              if (goalStatus.status == 2) {
                evidenceStatus.status = 1;
              } else {
                evidenceStatus.status = 0;
              }
            }
          } else {
            if (goalStatus.status == 2) {
              evidenceStatus.status = 1;
            } else {
              evidenceStatus.status = 0;
            }
          }
        }
      }
    }
  }

  function theoryOfChange() {
    if (
      workingProject.completionProgress &&
      workingProject.completionProgress.length > 0
    ) {
      if (isParentProgramActivity(workingProject.activityPlanTypeId)) {
        if (
          isString(workingProject.projectTocDo) &&
          isString(workingProject.projectTocKnown) &&
          workingProject.projectTocDo != '' &&
          workingProject.projectTocKnown != ''
        ) {
          theoryStatus.status = 2;
        } else {
          if (goalStatus.status === 2) {
            theoryStatus.status = 1;
          } else {
            theoryStatus.status = 0;
          }
        }
      } else {
        if (workingProject.objective && workingProject.objective.length) {
          if (
            workingProject.objective.length == 1 &&
            !workingProject.secondaryPolicyId
          ) {
            if (
              workingProject.objective[0].tocDo != '' &&
              workingProject.objective[0].tocKnown != ''
            ) {
              theoryStatus.status = 2;
            } else {
              if (goalStatus.status == 2) {
                theoryStatus.status = 1;
              } else {
                theoryStatus.status = 0;
              }
            }
          } else if (
            workingProject.objective.length == 2 &&
            workingProject.secondaryPolicyId
          ) {
            if (workingProject.objective.length == 2) {
              if (
                workingProject.objective[0].tocDo != '' &&
                workingProject.objective[0].tocKnown != '' &&
                workingProject.objective[1].tocDo != '' &&
                workingProject.objective[1].tocKnown != ''
              ) {
                theoryStatus.status = 2;
              } else {
                if (goalStatus.status == 2) {
                  theoryStatus.status = 1;
                } else {
                  theoryStatus.status = 0;
                }
              }
            }
          } else {
            if (goalStatus.status == 2) {
              theoryStatus.status = 1;
            } else {
              theoryStatus.status = 0;
            }
          }
        }
      }
    }
  }

  function activityPlan() {
    var isValid = true;
    var fields = null;
    if (
      workingProject.completionProgress &&
      workingProject.completionProgress.length > 0
    ) {
      const isProjected = workingProject.statusId === 1;

      // check venues available for single activities
      if (
        isSingleAndLinkedActivity(workingProject.activityPlanTypeId) &&
        workingProject.statusId === 2 &&
        isEmpty(workingProject.venues)
      ) {
        activityPlanStatus.status = 1;
      } else {
        if (isParentProgramActivity(workingProject.activityPlanTypeId)) {
          // activity plan
          isValid = isActivityPlanValid(4);

          if (isValid) {
            if (workingProject.statusId === 2) {
              if (isEmpty(workingProject.linkedActivities)) {
                activityPlanStatus.status = 1;
              } else {
                const linkedStatus = compact(
                  map(workingProject.linkedActivities, (act) => {
                    if (act.hasOwnProperty('deleted')) {
                      if (act.deleted) return;
                      if (act.statusId === 3 && !act.childApproved) {
                        return 2;
                      }
                      return act.statusId;
                    }
                    return 1;
                  })
                );

                const diff = difference(sortedUniq(linkedStatus), [3]);

                if (!isEmpty(diff)) {
                  activityPlanStatus.status = 1;
                } else {
                  activityPlanStatus.status = 2;
                }
              }
            } else {
              // festival program
              if (
                isFestivalProgramActivity(workingProject.activityPlanTypeId) &&
                isEmpty(workingProject.parentFestivals)
              ) {
                activityPlanStatus.status = 1;
              } else {
                activityPlanStatus.status = 2;
              }
            }
          } else {
            if (goalStatus.status === 2) {
              activityPlanStatus.status = 1;
            } else {
              activityPlanStatus.status = 0;
            }
          }
        } else {
          const outputValidity = getFormatOutputValidity(
            workingProject,
            isProjected
          );

          if (!outputValidity) {
            isValid = false;
          }

          fields = loadValidators(4);
          fields.forEach(function (item) {
            var validator = eval('workingProject.' + item);
            if (item == 'name') {
              if (validator == 'Unnamed') {
                isValid = false;
              }
            }
            if (validator == '' || validator == null) {
              isValid = false;
            }
          });

          if (isValid) {
            activityPlanStatus.status = 2;
          } else {
            if (goalStatus.status == 2) {
              activityPlanStatus.status = 1;
            } else {
              activityPlanStatus.status = 0;
            }
          }
        }
      }
    }
  }
  function evaluation() {
    if (
      workingProject.completionProgress &&
      workingProject.completionProgress.length > 0
    ) {
      if (isParentProgramActivity(workingProject.activityPlanTypeId)) {
        if (
          workingProject.statusId === 1 &&
          workingProject.parentOutcomes.length === 0
        ) {
          evaluationStatus.status = 0;
        } else if (
          workingProject.statusId === 1 &&
          (objectiveStatus.status !== 2 ||
            evidenceStatus.status !== 2 ||
            theoryStatus.status !== 2 ||
            (activityPlanStatus.status !== 2 && workingProject.statusId === 1))
        ) {
          evaluationStatus.status = 1;
        } else if (
          workingProject.statusId === 1 &&
          objectiveStatus.status === 2 &&
          evidenceStatus.status === 2 &&
          theoryStatus.status === 2 &&
          activityPlanStatus.status === 2
        ) {
          evaluationStatus.status = 2;
          if (workingProject.approvalMethod === 1) {
            if (workingProject.approvedDate) {
              evaluationStatus.status = 2;
            } else {
              evaluationStatus.status = 1;
            }
          }
        } else if (workingProject.statusId === 2) {
          // check management code enabled
          if (!isEmpty(workingProject.codeLabel)) {
            if (!isEmpty(workingProject.ppmsCode)) {
              evaluationStatus.status = 2;
            } else {
              evaluationStatus.status = 1;
            }
          } else {
            evaluationStatus.status = 2;
          }
        }
      } else {
        if (!isEmpty(workingProject.objective)) {
          if (!isEmpty(workingProject.evaluationPlans)) {
            let getEvalPlans = map(
              get(workingProject, 'evaluationPlans', []),
              (plan) => {
                return map(plan.evaluationQuestions, (e) => e.objectiveId);
              }
            );
            if (!isEmpty(getEvalPlans)) {
              getEvalPlans = uniq(flattenDeep(getEvalPlans));
            }
            const objectiveIds = map(workingProject.objective, (e) => e.id);

            if (isEqual(objectiveIds.sort(), getEvalPlans.sort())) {
              let evalPlans = [];
              if (workingProject.statusId > 1) {
                evalPlans = workingProject.evaluationPlans.find(
                  (plan) =>
                    (!plan.dateMeasured && plan.methodId !== 9) ||
                    // ---------- for new self assessment ----------
                    (plan.methodId === 9 &&
                      (!plan.methodReason ||
                        isEmpty(plan.assessmentTypes) ||
                        !plan.actualParticipantsEvaluated)) ||
                    !plan.evaluationQuestions[0].selfAssessmentReason ||
                    (size(plan.evaluationQuestions) === 2 &&
                      !plan.evaluationQuestions[1].selfAssessmentReason)
                );
              }
              // need to get approval from approver for green light
              if (workingProject.approvalMethod === 1) {
                // green light approved activity
                if (workingProject.approvedDate) {
                  if (
                    (workingProject.statusId > 1 && !isEmpty(evalPlans)) ||
                    objectiveStatus.status !== 2 ||
                    evidenceStatus.status !== 2 ||
                    theoryStatus.status !== 2 ||
                    (activityPlanStatus.status !== 2 &&
                      workingProject.statusId === 1)
                  ) {
                    evaluationStatus.status = 1;
                  } else {
                    // check management code enabled
                    if (
                      !isEmpty(workingProject.codeLabel) &&
                      workingProject.statusId > 1
                    ) {
                      if (!isEmpty(workingProject.ppmsCode)) {
                        evaluationStatus.status = 2;
                      } else {
                        evaluationStatus.status = 1;
                      }
                    } else {
                      evaluationStatus.status = 2;
                    }
                  }
                } else {
                  if (goalStatus.status == 2) {
                    evaluationStatus.status = 1;
                  } else {
                    evaluationStatus.status = 0;
                  }
                }
              } else {
                if (
                  (workingProject.statusId > 1 && !isEmpty(evalPlans)) ||
                  objectiveStatus.status !== 2 ||
                  evidenceStatus.status !== 2 ||
                  theoryStatus.status !== 2 ||
                  (activityPlanStatus.status !== 2 &&
                    workingProject.statusId === 1)
                ) {
                  evaluationStatus.status = 1;
                } else {
                  if (
                    !isEmpty(workingProject.codeLabel) &&
                    workingProject.statusId > 1
                  ) {
                    if (!isEmpty(workingProject.ppmsCode)) {
                      evaluationStatus.status = 2;
                    } else {
                      evaluationStatus.status = 1;
                    }
                  } else {
                    evaluationStatus.status = 2;
                  }
                }
              }
            } else {
              if (goalStatus.status == 2) {
                evaluationStatus.status = 1;
              } else {
                evaluationStatus.status = 0;
              }
            }
          } else {
            if (goalStatus.status == 2) {
              evaluationStatus.status = 1;
            } else {
              evaluationStatus.status = 0;
            }
          }
        } else {
          evaluationStatus.status = 0;
        }
      }
    }
  }

  async function documentation() {
    documentationStatus.status = 0;

    if (
      workingProject.completionProgress &&
      workingProject.completionProgress.length > 0
    ) {
      if (workingProject.statusId > 1) {
        documentationStatus.status = 1;
        let isValid = true;
        if (!workingProject.summary || workingProject.summary == '') {
          isValid = false;
        }
        if (!workingProject.reflection || workingProject.reflection == '') {
          isValid = false;
        }
        if (isValid) {
          documentationStatus.status = 2;
        }
      } else {
        documentationStatus.status = 0;
      }
    }
  }

  function report() {
    reportStatus.status = 0;

    if (
      (goalStatus.status === 2 && workingProject.objective.length !== 0) ||
      (goalStatus.status === 2 &&
        isParentProgramActivity(workingProject.activityPlanTypeId) &&
        !isEmpty(workingProject.parentOutcomes))
    ) {
      reportStatus.status = 1;
    }

    if (
      goalStatus.status === 2 &&
      objectiveStatus.status === 2 &&
      evidenceStatus.status === 2 &&
      theoryStatus.status === 2 &&
      activityPlanStatus.status === 2 &&
      evaluationStatus.status === 2
    ) {
      if (workingProject.statusId === 1) {
        reportStatus.status = 2;
      } else if (
        workingProject.statusId > 1 &&
        documentationStatus.status === 2
      ) {
        reportStatus.status = 2;
      }
    }
  }

  function isActivityPlanValid(section) {
    let isValid = true;
    const fields = loadValidators(section);

    fields.forEach(function (item) {
      var validator = eval('workingProject.' + item);
      if (item == 'name' && validator == 'Unnamed') {
        isValid = false;
      }

      if (!validator) {
        const outputFormatGroup = get(
          workingProject,
          'eventDetail.outputFormat',
          null
        );

        if (outputFormatGroup) {
          let currentOutput;
          for (const formatItem of outputFormatGroup.format) {
            if (item === formatItem.name) {
              currentOutput = workingProject.output.find(
                (outputItem) =>
                  outputItem.outputFormatId === formatItem.outputFormatId
              );
              break;
            }
          }
          if (currentOutput) validator = currentOutput.projectedValue;
        }

        if (
          item == 'projectedTotalGrantsPool' &&
          (validator == '0.00' || validator == '0')
        ) {
          isValid = false;
        }

        if (!validator) isValid = false;
      }
    });

    return isValid;
  }

  function loadValidators(section) {
    var fields = null;
    if (section == 4) {
      fields = [
        'name',
        'greenLightNotifyDate',
        'eventStartDate',
        'eventEndDate',
        'projectedBudget',
        'projectedCost',
        'description',
      ];

      if (isParentProgramActivity(workingProject.activityPlanTypeId)) {
        if (workingProject.activityPlanTypeId === 3) {
          fields.push('parentFestivals');
          fields.push('projectedNumberOfFestivalActivities');
          fields.push('projectedTotalNumberOfParticipants');
        }
        if (workingProject.activityPlanTypeId === 4) {
          fields.push('projectedTotalGrantsPool');
          fields.push('grantApplicationClosingDate');
        }
        if (workingProject.activityPlanTypeId === 6) {
          fields.push('projectedNumberOfProgramActivities');
        }
      } else {
        // if liked activity push projectedGrantAmount field to validators
        if (workingProject.activityPlanTypeId === 4) {
          fields.push('projectedGrantAmount');
        }
        fields.push('eventDetailId');
      }
      return fields;
    }
    return null;
  }
  return workingProject;
}
