import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Icon,
  Label,
  Modal,
  Segment,
  Dropdown,
} from 'semantic-ui-react';
import { isBrowser, isTablet } from 'react-device-detect';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import moment from 'moment';
import React, { Component } from 'react';
import DateRangeChart from '../../../components/dateRangeChart';

import {
  CustomTable,
  EmptyContainer,
  CustomSegment,
} from '../../../components';
import {
  getSystemLog,
  getSystemLogTypes,
  getAllOrganisations,
  getUsers,
  getProjectsForSystemLog,
  getOrgUser,
  getOrgProjects,
} from '../../../store/actions';
import { isEmpty, map, get, find } from '../../../utils/lodash';

class SystemChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      filters: {
        startDate: moment().subtract(90, 'days').format('YYYY-M-D'),
        endDate: moment().format('YYYY-M-D'),
        type: '',
        user: '',
        activity: '',
        organisation: '',
        keyword: '',
      },
      orgId: '',
      typeId: '',
      userId: '',
      activityId: '',
      keyword: '',
      limit: 25,
      currentPage: 1,
    };
  }

  resetSearchFields() {
    this.setState((prevState) => ({
      typeId: '',
      userId: '',
      activityId: '',
      orgId: null,
      keyword: '',
      filters: {
        ...prevState.filters,
        type: '',
        user: '',
        activity: '',
        organisation: '',
        keyword: '',
      },
    }));
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  handleAdvanceSearchSubmit = async (values) => {
    try {
      const { getSystemLog } = this.props;
      const { limit, filters, orgId, activityId, userId, typeId, keyword } =
        this.state;

      const data = {
        keyword: keyword,
        organisation: orgId,
        activity: activityId,
        user: userId,
        type: typeId,
        endDate: filters.endDate,
        startDate: filters.startDate,
      };

      this.setState({
        filters: data,
        currentPage: 1,
      });

      await getSystemLog(this.state.currentPage, limit, data);
      this.toggle();
    } catch (error) {
      error.log('Search Submit ~ :', error);
    }
  };

  async componentDidMount() {
    try {
      const { currentPage, limit, filters } = this.state;
      const {
        getSystemLog,
        getSystemLogTypes,
        getAllOrganisations,
        getUsers,
        getProjectsForSystemLog,
      } = this.props;
      await Promise.all([
        getSystemLogTypes(),
        getAllOrganisations(),
        getUsers(),
        getProjectsForSystemLog(),
        getSystemLog(currentPage, limit, filters),
      ]);
    } catch (error) {
      console.error('Getting Error error :', error);
    }
  }

  pageChange(page) {
    const { limit, filters } = this.state;
    const { getSystemLog } = this.props;

    this.setState(
      {
        currentPage: page,
      },
      () => {
        getSystemLog(page, limit, filters);
      }
    );
  }

  renderTags() {
    const { filters } = this.state;
    const { systemLogType, organisationsAll, users, projects } =
      this.props.admin;

    const type = find(systemLogType, (e) => e.id === parseInt(filters.type));

    const organisation = find(
      organisationsAll,
      (e) => e.id === parseInt(filters.organisation)
    );
    const user = find(users, (e) => e.id === parseInt(filters.user));
    const project = find(projects, (e) => e.id === parseInt(filters.activity));

    const tags = {
      Organisation:
        organisation === undefined ? filters.organisation : organisation.name,
      Type: type === undefined ? filters.type : type.typeDetails,
      Activity: project === undefined ? filters.activity : project.name,
      User: user === undefined ? filters.user : user.firstName,
      Keyword: filters.keyword,
    };

    return map(tags, (tag, index) => {
      if (isEmpty(tag)) return null;
      return (
        <Label
          key={index}
          color="orange"
          className="tag-filter"
          removeIcon="delete"
          index={index}
          onRemove={this.onRemoveTag}
          content={`${index}: ${tag}`}
        ></Label>
      );
    });
  }

  onRemoveTag = (event, data) => {
    const { filters, limit, typeId, orgId, activityId, userId, keyword } =
      this.state;
    const { getSystemLog } = this.props;

    let type = typeId,
      org = orgId,
      activity = activityId,
      user = userId,
      searchKeyword = keyword;

    switch (data.index) {
      case 'Type':
        filters.type = '';
        type = '';
        break;

      case 'Organisation':
        filters.organisation = '';
        org = null;
        filters.activity = '';
        activity = '';
        filters.user = '';
        user = '';
        break;

      case 'Activity':
        filters.activity = '';
        activity = '';
        break;

      case 'User':
        filters.user = '';
        user = '';
        break;
      case 'Keyword':
        filters.keyword = '';
        searchKeyword = '';
        break;

      default:
        break;
    }

    this.setState(
      {
        filters,
        typeId: type,
        orgId: org,
        activityId: activity,
        userId: user,
        keyword: searchKeyword,
        currentPage: 1,
      },
      () => {
        getSystemLog(this.state.currentPage, limit, filters);
      }
    );
  };

  datePicker = async (event, picker) => {
    const { filters, limit, currentPage } = this.state;
    const { getSystemLog } = this.props;
    filters.startDate = moment(picker.startDate).format('YYYY-M-D');
    filters.endDate = moment(picker.endDate).format('YYYY-M-D');
    this.setState(
      {
        filters,
        currentPage: 1,
      },
      () => {
        getSystemLog(currentPage, limit, filters);
      }
    );
  };

  async onChangeOrganisation(event, data) {
    if (data.value) {
      if (data.value) {
        this.setState({
          orgId: data.value,
        });
      }
    }
  }

  async onChangeProject(event, data) {
    this.setState({
      activityId: data.value,
    });
  }

  async onChangeType(event, data) {
    this.setState({
      typeId: data.value,
    });
  }

  onChangeKeyword(value) {
    this.setState({
      keyword: value,
    });
  }

  async onChangeUser(event, data) {
    this.setState({
      userId: data.value,
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { getOrgUser, getOrgProjects } = this.props;
    const { orgId } = this.state;
    try {
      if (prevState.orgId !== orgId) {
        await Promise.all([
          await getOrgUser(orgId),
          await getOrgProjects(orgId),
        ]);
      }
    } catch (error) {}
  }

  getCalendarYearTimestamps() {
    let startMonthName = 'January',
      endMonthName = 'December';
    return {
      current: {
        start: moment().month(startMonthName).startOf('month'),
        end: moment().month(endMonthName).endOf('month'),
      },
      last: {
        start: moment()
          .subtract(1, 'year')
          .month(startMonthName)
          .startOf('month'),
        end: moment().subtract(1, 'year').month(endMonthName).endOf('month'),
      },
    };
  }

  getFinancialYearTimestamps() {
    let startMonthName = 'July',
      endMonthName = 'June';

    const currentMonth = new Date().getMonth();
    return {
      current: {
        start:
          currentMonth > 6
            ? moment().month(startMonthName).startOf('month')
            : moment()
                .subtract(1, 'year')
                .month(startMonthName)
                .startOf('month'),
        end:
          currentMonth > 6
            ? moment().add(1, 'year').month(endMonthName).endOf('month')
            : moment().month(endMonthName).endOf('month'),
      },
      last: {
        start:
          currentMonth > 6
            ? moment()
                .subtract(1, 'year')
                .month(startMonthName)
                .startOf('month')
            : moment()
                .subtract(2, 'year')
                .month(startMonthName)
                .startOf('month'),
        end:
          currentMonth > 6
            ? moment().month(endMonthName).endOf('month')
            : moment().subtract(1, 'year').month(endMonthName).endOf('month'),
      },
    };
  }

  render() {
    const { modal, currentPage, filters } = this.state;

    return (
      <Grid.Row>
        <CustomSegment
          title="Logs"
          children={
            <div>
              <div className="outcome-admin">
                <div className="system-log-date-range">
                  <div className="date-range-container mr-1">
                    <DateRangeChart
                      startDate={moment(filters.startDate)}
                      endDate={moment(filters.endDate)}
                      datePicker={this.datePicker}
                      preDefinedDateRanges
                    />
                  </div>
                  <div className="button-outcome">
                    <Modal
                      open={modal}
                      onOpen={() => this.toggle()}
                      closeIcon={
                        <Icon
                          onClick={() => this.toggle()}
                          name="close"
                          className="closeicon"
                        />
                      }
                      size="small"
                      trigger={
                        <Button className="Primary-Button">
                          <div className="add-icon-activity-add">
                            <Icon name="search" />
                            <div className="add-text"> Advance Search</div>
                          </div>
                        </Button>
                      }
                      className="advance-search"
                    >
                      <Modal.Header>Advance Search</Modal.Header>
                      <Modal.Content>
                        <SearchSystemChange
                          filters={filters}
                          toggle={() => this.toggle()}
                          handleAdvanceSearchSubmit={(values) =>
                            this.handleAdvanceSearchSubmit(values)
                          }
                          onChangeOrganisation={(event, values) =>
                            this.onChangeOrganisation(event, values)
                          }
                          onChangeProject={(event, values) =>
                            this.onChangeProject(event, values)
                          }
                          onChangeType={(event, values) =>
                            this.onChangeType(event, values)
                          }
                          onChangeUser={(event, values) =>
                            this.onChangeUser(event, values)
                          }
                          onChangeKeyword={(value) =>
                            this.onChangeKeyword(value)
                          }
                          resetSearchFields={() => this.resetSearchFields()}
                          {...this.state}
                          {...this.props}
                        />
                      </Modal.Content>
                    </Modal>
                  </div>
                </div>
              </div>
              <div className="filter-system-log">{this.renderTags()}</div>

              <SystemChangesTable
                currentPage={currentPage}
                pageChange={(page) => this.pageChange(page)}
                {...this.props}
              />
            </div>
          }
        />
      </Grid.Row>
    );
  }
}

class SystemChangesTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      limit: 10,
      columns: [
        {
          title: (
            <div className="user-heading-container">
              <strong>Date</strong>
            </div>
          ),
          render: (data) => {
            return moment(data.createdDateTime).format('ll hh:mm:ss A');
          },
          textAlign: isBrowser || isTablet ? 'left' : null,
          width: 2,
        },
        {
          title: (
            <div className="user-heading-container">
              <strong>Type</strong>
            </div>
          ),
          render: (data) => {
            return get(data, 'type.typeDetails', '-');
          },
          textAlign: isBrowser || isTablet ? 'left' : null,
          width: 2,
        },
        {
          title: (
            <div className="user-heading-container">
              <strong>Organisation</strong>
            </div>
          ),
          render: (data) => {
            return get(data, 'user.organisation.name', 'Guest User');
          },
          textAlign: isBrowser || isTablet ? 'left' : null,
          width: 2,
        },
        {
          title: (
            <div className="user-heading-container">
              <strong>User</strong>
            </div>
          ),
          render: (data) => {
            return (
              <div className="org-users-container">
                <span className="org-users-cdn-container">
                  <label className="org-users-mainText">
                    {get(data, 'user.name', '-')}
                  </label>
                </span>
                <br></br>
                <label className="org-users-text">
                  <label className="org-users-text">
                    {get(data, 'user.email', '-')}{' '}
                  </label>
                </label>
              </div>
            );
          },
          textAlign: isBrowser || isTablet ? 'left' : null,
          width: 3,
        },
        {
          title: (
            <div className="user-heading-container">
              <strong>Activity</strong>
            </div>
          ),
          render: (data) => {
            return get(data, 'project.name', '-');
          },
          textAlign: isBrowser || isTablet ? 'left' : null,
          width: 2,
        },
        {
          title: (
            <div className="user-heading-container">
              <strong>Message</strong>
            </div>
          ),
          render: (data) => {
            return data.description;
          },
          textAlign: isBrowser || isTablet ? 'left' : null,
          width: 4,
        },
      ],
    };
  }
  render() {
    const { columns } = this.state;
    const { pageChange, currentPage } = this.props;
    const { systemLogs, systemLogLoading } = this.props.admin;

    return (
      <div>
        <Segment
          className="super-admin-content-segment"
          loading={systemLogLoading}
          disabled={systemLogLoading}
          attached
        >
          <div className="system-log-table">
            {systemLogs.docs && systemLogs.docs.length ? (
              <CustomTable
                header
                columns={columns}
                data={systemLogs.docs}
                pagination
                customClass="super-admin-system-log-table tbody-hover"
                handlePaginationChange={(page) => pageChange(page)}
                page={currentPage}
                noOfPages={systemLogs.pages}
                keyExtractor={(item, index) => item.id}
              />
            ) : (
              <EmptyContainer msg="No Logs..." />
            )}
          </div>
        </Segment>
      </div>
    );
  }
}

class SearchSystemChange extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleValidSubmit(event, values) {
    const { handleAdvanceSearchSubmit } = this.props;
    handleAdvanceSearchSubmit(values);
  }

  OrganisationSelection() {
    const { organisationsAll } = this.props.admin;
    const optionList = map(organisationsAll, (item, i) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
    optionList.unshift({ key: null, value: 'all', text: 'All' });
    return optionList;
  }

  ProjectSelection() {
    const { orgProjects } = this.props.organization;
    const proj = map(orgProjects, (item, i) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
    proj.unshift({ key: null, value: 'all', text: 'All' });
    return proj;
  }

  TypeSelection() {
    const { systemLogType } = this.props.admin;
    const type = map(systemLogType, (item, i) => ({
      key: item.id,
      value: item.id,
      text: item.typeDetails,
    }));
    type.unshift({ key: null, value: 'all', text: 'All' });
    return type;
  }

  UsersSelection() {
    const { selectedUser } = this.props.admin;

    const user = map(selectedUser, (item, i) => ({
      key: item.id,
      value: item.id,
      text: item.name + ' - ' + item.email,
    }));
    user.unshift({ key: null, value: 'all', text: 'All' });
    return user;
  }

  render() {
    const {
      toggle,
      filters,
      onChangeOrganisation,
      onChangeProject,
      onChangeType,
      onChangeUser,
      resetSearchFields,
      onChangeKeyword,
    } = this.props;
    const { systemLogLoading } = this.props.admin;

    return (
      <AvForm
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="venue-form"
        ref={(c) => (this.form = c)}
        model={filters}
      >
        <label>Type</label>
        <Dropdown
          className="org-inputs"
          placeholder="Select Type"
          fluid
          search
          value={this.props.typeId}
          selection
          onChange={(event, data) => onChangeType(event, data)}
          options={this.TypeSelection()}
        />
        <div className="gap-between-collapse"></div>
        <label>Organisation</label>
        <Dropdown
          className="org-inputs"
          placeholder="Select organization"
          fluid
          search
          value={this.props.orgId}
          selection
          onChange={(event, data) => onChangeOrganisation(event, data)}
          options={this.OrganisationSelection()}
        />
        <div className="gap-between-collapse"></div>
        <label>Activity</label>
        <Dropdown
          className="org-inputs"
          placeholder="Select Activity"
          fluid
          search
          value={this.props.activityId}
          selection
          onChange={(event, data) => onChangeProject(event, data)}
          options={this.ProjectSelection()}
        />
        <div className="gap-between-collapse"></div>
        <label>User</label>
        <Dropdown
          className="org-inputs"
          placeholder="Select User"
          fluid
          search
          value={this.props.userId}
          selection
          onChange={(event, data) => onChangeUser(event, data)}
          options={this.UsersSelection()}
        />
        <div className="gap-between-collapse"></div>
        <AvField
          label="Keyword"
          className="org-inputs"
          name="keyword"
          value={this.props.keyword}
          type="text"
          onBlur={(e) => onChangeKeyword(e.target.value)}
        />

        <div className="model-buttons-systemLog">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => resetSearchFields()}
          >
            Reset
          </Button>

          <div className="save-system-log">
            <Button
              className="Secondary-Button"
              type="button"
              onClick={() => toggle()}
            >
              Cancel
            </Button>
            <Button
              content="Search"
              type="submit"
              className="Primary-Button"
              loading={systemLogLoading}
              disabled={systemLogLoading}
            />
          </div>
        </div>
      </AvForm>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.admin,
    organization: state.organization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSystemLog,
      getSystemLogTypes,
      getAllOrganisations,
      getUsers,
      getProjectsForSystemLog,
      getOrgUser,
      getOrgProjects,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemChange);
