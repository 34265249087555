import React from 'react';
import { Image, Modal, Header, Grid, Icon } from 'semantic-ui-react';
import dummy from '../assets/dummy.png';
import './styles.scss';
export class ImageView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { src } = this.props;
    if (src === dummy) {
      return <Image src={src} />;
    }
    return (
      <Modal
        trigger={
          <Image
            className="profile-picture"
            fluid
            centered
            bordered
            src={src}
          />
        }
        basic
        size="small"
      >
        <Header content="Organisation Logo" className="image-view-header" />
        <Modal.Content className="image-view">
          <Image src={src} />
        </Modal.Content>
      </Modal>
    );
  }
}

export class AvFieldUpload extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      image: props.imageUrl || dummy,
      file: null,
    };
    this.fileInputRef = React.createRef();
  }

  getImage(event) {
    if (event.target.files[0]) {
      this.setState({
        file: event.target.files[0],
        image: URL.createObjectURL(event.target.files[0]),
      });
    } else {
      return false;
    }
  }

  getCurrentImage() {
    const { file } = this.state;
    return file;
  }

  render() {
    const { image } = this.state;
    return (
      <div className="org-profile-pic-segment-edit">
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <span className="org-profile-container">
              <Image src={image} size="small" />
            </span>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={8}
            className="org-profile-btn-col"
          >
            <div className="org-profile-btn">
              <label
                className="org-click-to-upload"
                onClick={() => this.fileInputRef.current.click()}
              >
                Click to upload
              </label>
              {!!this.state.file ? (
                <div className="org-profile-pic-name">
                  <Icon name="attach" /> {this.state.file.name}
                </div>
              ) : null}
              <label className="org-profile-des">
                Supports jpg, png, gmp formats
              </label>
            </div>
            <input
              ref={this.fileInputRef}
              type="file"
              hidden
              accept="image/gmp, image/jpg, image/jpeg, image/png"
              onChange={(e) => this.getImage(e)}
            />
          </Grid.Column>
        </Grid.Row>
      </div>
    );
  }
}
