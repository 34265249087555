import './auth.scss';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Grid } from 'semantic-ui-react';
import { SuccessContainer } from '../../components';

class WelcomeToWhitebox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Grid.Row>
        <Container className="email-verification-container">
          <Grid>
            <Grid.Row>
              <SuccessContainer
                header="Hello!"
                msg="Welcome to Takso"
                description={[
                  <span>Your account has been activated successfully.</span>,
                  <br />,
                  <span>You can now sign in to your account</span>,
                ]}
                success={true}
                button={'Sign in'}
                buttonRoute="/signin"
              />
            </Grid.Row>
          </Grid>
        </Container>
      </Grid.Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeToWhitebox);
