import { AvField, AvForm } from 'availity-reactstrap-validation';
import { bindActionCreators } from 'redux';
import {
  Button,
  Input,
  Modal,
  Radio,
  Segment,
  Icon,
  Grid,
  Divider,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import React, { Fragment } from 'react';

import { CustomTooltip, FeatureWrapper, BrowseFile } from '../../../components';
import { get, isEmpty, find, parseInt } from '../../../utils/lodash';
import {
  updateOrganizationInfo,
  putOrgGoalReferences,
  putOrgArchivedGoalReferences,
  getOrgGoalRefList,
  deleteOrgGoalRefList,
  postCrossReferenceDocument,
  getCrossReferenceDocumentList,
} from '../../../store/actions';

import '../organization.scss';

class OrganizationGLApproval extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tips: false,
      activityApprovalMethod: 'allowSelf',
      financialYear: true,
      authCode: false,
      cultureCounts: false,
      department: false,
      smartyGrants: false,
      authorisationCode: '',
      crossReference: false,
      crossReferenceDocument: '',
      crossReferenceLabel: '',
      modal: false,
      reference: '',
      referenceArr: [
        {
          key: 1,
          value: '',
        },
      ],
      file: null,
      crossRefCurrentDoc: null,
      confirmationModal: false,
    };
  }

  async componentDidMount() {
    try {
      const {
        user: {
          organisation: { settings = [] },
        },
      } = this.props;
      const [config = {}] = settings;
      if (!isEmpty(config)) {
        this.setState({
          tips: config.activityApprovalMethod === 'allowSelf' ? false : true,
          activityApprovalMethod: config.activityApprovalMethod,
          financialYear:
            config.financialYear || config.financialYear === undefined
              ? true
              : false,
          authCode: config.authorisationCode ? true : false,
          authorisationCode: config.authorisationCode
            ? config.authorisationCode
            : null,
          cultureCounts: config.cultureCounts ? true : false,
          department: config.department ? true : false,
          crossReference: config.crossReference ? true : false,
          crossReferenceDocument: config.crossReferenceDocument
            ? config.crossReferenceDocument
            : null,
          crossReferenceLabel: config.crossReferenceLabel
            ? config.crossReferenceLabel
            : null,
          smartyGrants: config.smartyGrants ? true : false,
        });
      }

      // get organizations cross reference documents
      this.getCrossReferenceDocuments();
    } catch (error) {
      console.error(error);
    }
  }
  // componentDidUpdate() {
  //   this.props.setCrossReference(this.state.crossReference);
  // }

  authCodeToggle = () => {
    this.setState({ authCode: !this.state.authCode }, () => {
      if (!this.state.authCode && this.state.authorisationCode) {
        this.setState({ authorisationCode: null }, () => {
          this.updateOrganizationSettings('authorisationCode');
        });
      }
    });
  };

  cultureCountsToggle = () => {
    this.setState({ cultureCounts: !this.state.cultureCounts }, () => {
      this.updateOrganizationSettings('cultureCounts');
    });
  };

  crossReferenceToggle = async () => {
    this.setState(
      (prevState) => ({
        crossReference: !prevState.crossReference,
        // modal: prevState.crossReference,
      }),
      () => {
        if (!this.state.crossReference) {
          const { setCrossReference } = this.props;
          //hide child component (goal reference list component)
          setCrossReference(false);
          this.setState(
            () => ({ crossReferenceDocument: null }),
            () => {
              this.updateOrganizationSettings('crossReferenceDocument');
            }
          );
        }
      }
    );
  };

  smartyGrantsToggle = () => {
    this.setState({ smartyGrants: !this.state.smartyGrants }, () => {
      this.updateOrganizationSettings('smartyGrants');
    });
  };

  toggle = () => {
    this.setState(
      {
        tips: !this.state.tips,
        activityApprovalMethod: !this.state.tips ? 'ppmsCode' : 'allowSelf',
      },
      () => {
        this.updateOrganizationSettings('activityApprovalMethod');
      }
    );
  };

  onChangeDepartment = async () => {
    // department false
    if (!this.state.department) {
      this.setState({ department: true }, async () => {
        const data = await this.updateOrganizationSettings('department');
        this.setState({ department: !isEmpty(data) });
      });
    } else {
      this.setState({ department: false }, async () => {
        const data = await this.updateOrganizationSettings('department');
        if (isEmpty(data)) {
          this.setState({ department: true });
        }
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.department !== this.state.department) {
      this.props.setDepartment(this.state.department);
    }
  }

  onChange = (type, value) => {
    this.setState({ [type]: value });
  };

  financialYearToggle = async (isFinancialYear) => {
    await this.setState({ financialYear: isFinancialYear });
    this.updateOrganizationSettings('financialYear');
  };

  updateOrganizationSettings = async (type, value) => {
    try {
      const {
        user: { organisation = {} },
        updateOrganizationInfo,
      } = this.props;
      const updatedSettings = {
        ...organisation.settings[0],
        [type]: this.state[type],
      };

      if (type === 'crossReferenceDocument') {
        updatedSettings.crossReference = this.state[type] ? true : false;
      }

      const data = await updateOrganizationInfo({
        settings: [updatedSettings],
      });
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  toggleModal = (closed) => {
    const { crossRefCurrentDoc } = this.state;
    const { setCrossReference } = this.props;

    // TODO: remove below if condition only when creating new cross reference while toggling button
    if (!crossRefCurrentDoc) {
      this.setState(
        (prevState) => {
          return { modal: !prevState.modal };
        },
        () => {
          if (this.state.modal) {
            this.setState({ crossReferenceDocument: '' });
          }
          if (closed) {
            this.setState((prevState) => ({
              crossReference: !prevState.crossReference,
            }));
          }
        }
      );
    } else {
      // TODO: remove below code lines (all lines) when creating new cross reference while toggling button
      //remove else part
      // to manage child component (show)
      const { crossRefCurrentDoc } = this.state;
      setCrossReference(true);
      this.setState(
        {
          crossReference: true,
          crossReferenceDocument: crossRefCurrentDoc.documentTitle,
        },
        async () => {
          await this.updateOrganizationSettings('crossReferenceDocument');
        }
      );
    }
  };

  toggleReferenceModal = (status) => {
    this.setState({
      modal: status,
      crossReference: status,
    });
  };

  toggleConfirmationModal = (status) => {
    this.setState({ confirmationModal: status });
  };

  onValidSubmit = async () => {
    await this.updateOrganizationSettings('crossReferenceDocument');
    this.toggleModal();
  };

  async getCrossReferenceDocuments() {
    const { user, getCrossReferenceDocumentList } = this.props;

    await getCrossReferenceDocumentList(); // get all cross reference document list

    const { crossRefDocuments } = this.props;
    // get current active cross reference document
    const currentRefDoc = find(
      crossRefDocuments,
      (f) => f.documentEndedDateTime === null
    );

    if (currentRefDoc) {
      this.setState({
        crossRefCurrentDoc: currentRefDoc,
        documentTitle: currentRefDoc.currentRefDoc,
        referenceFieldLabel: currentRefDoc.documentTitle,
      });
    }
  }

  setFile = async (file) => {
    this.setState({ file });
  };

  onChange = (type, value) => {
    this.setState({
      [type]: value,
    });
  };

  crossReferenceDocumentHandler = async (event, values) => {
    const { file } = this.state;
    const { documentTitle, referenceFieldLabel } = values;
    const { postCrossReferenceDocument, user, setCrossReference } = this.props;

    let formData = new FormData();
    formData.append('organisationId', parseInt(user.organisationId));
    formData.append('documentTitle', documentTitle);
    formData.append('referenceFieldLabel', referenceFieldLabel);
    formData.set('uploadFile', file ? file.file : ' ');

    const isSubmitted = await postCrossReferenceDocument(formData);

    if (isSubmitted) {
      this.toggleModal();
    }

    this.setState(
      {
        crossReferenceDocument: documentTitle,
      },
      async () =>
        await this.updateOrganizationSettings('crossReferenceDocument') // update user settings
    );
    this.crossReferenceDocumentClear();

    // TODO: Remove below line when next task started
    await this.getCrossReferenceDocuments();

    await setCrossReference(true);
  };

  crossReferenceDocumentClear = async () => {
    this.setState({
      referenceFieldLabel: '',
      documentTitle: '',
      file: '',
    });
  };

  // TODO: This modal will be deleted when cross ref next task is finished
  InfoModal = () => {
    const { confirmationModal } = this.state;
    return (
      <Modal
        size="tiny"
        open={confirmationModal}
        closeIcon={
          <Icon
            onClick={() => this.toggleConfirmationModal(false)}
            name="close"
            className="closeicon"
          />
        }
        className="end-cross-referencing"
      >
        <Modal.Header>End cross referencing</Modal.Header>
        <Modal.Content>
          <label className="billing-info">
            Are you sure you no longer want activities to record cross
            references?
          </label>
          <div className="model-buttons">
            <Button
              className="Secondary-Button"
              type="button"
              onClick={() => this.toggleConfirmationModal(false)}
            >
              Cancel
            </Button>
            <Button
              // loading={endSubLoading}
              // disabled={endSubLoading}
              onClick={() => {
                this.crossReferenceToggle();
                this.toggleConfirmationModal(false);
              }}
              content="Yes"
              type="submit"
              className="Primary-Button"
            />
          </div>
        </Modal.Content>
      </Modal>
    );
  };

  CrossReferenceDocumentCreateModal = () => {
    const { modal } = this.state;
    const { crossRefDocLoading } = this.props;
    return (
      <Modal
        className="add-cross-ref-model"
        open={modal}
        size="tiny"
        closeIcon={
          <Icon
            onClick={() => this.toggleReferenceModal(false)}
            name="close"
            className="closeicon"
          />
        }
      >
        <Modal.Header>Add Cross-Reference Document</Modal.Header>
        <Modal.Content>
          <AvForm
            onValidSubmit={(event, values) => {
              this.crossReferenceDocumentHandler(event, values);
            }}
          >
            <div className="model-upper">
              <AvField
                name="documentTitle"
                label="Document Title"
                readOnly={crossRefDocLoading}
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Document title label is required',
                  },
                }}
              />
              <AvField
                name="referenceFieldLabel"
                label="Reference Field Label"
                readOnly={crossRefDocLoading}
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Reference field label is required',
                  },
                }}
              />
              <div className="reference-document">
                <BrowseFile
                  label="Upload Reference Document"
                  name="uploadReferenceDocument"
                  accept="*"
                  buttonText="Browse File"
                  mediaUpload={true}
                  infoMessage="Max file size - 50 MB"
                  setFile={this.setFile}
                  loading={crossRefDocLoading}
                  showMsg={true}
                  placeholder="Accepted file types: .pdf, .docx"
                />
              </div>
            </div>
            <Divider section />

            <div className="model-below">
              <div className="model-button">
                <Button
                  onClick={() => this.toggleReferenceModal(false)}
                  className="modal-cancel"
                >
                  Cancel
                </Button>
                <Button
                  loading={crossRefDocLoading}
                  className="modal-proceed"
                  content="Save"
                  type="submit"
                />
              </div>
            </div>
          </AvForm>
        </Modal.Content>
      </Modal>
    );
  };

  render() {
    const {
      organization: { loading = false },
      helpTips,
      user,
      account = false,
    } = this.props;

    const {
      authCode,
      cultureCounts,
      tips,
      crossReference,
      department,
      authorisationCode,
    } = this.state;

    return (
      <Segment className="org-settings-segment" loading={loading}>
        {account
          ? null
          : [
              // <FeatureWrapper
              //   featureName="financial_reporting_period_setting"
              //   children={
              <div>
                <div className="financial-method-container content-segment-settings">
                  <CustomTooltip
                    state={get(user, 'options.tips')}
                    //TODO: change account help tip here
                    contentObject={{
                      data: helpTips,
                      key: account ? '203' : null,
                    }}
                    position="top left"
                    wide="very"
                  >
                    <label className="financial-method-header">
                      Financial Reporting Period
                    </label>
                  </CustomTooltip>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <div className="financial-method-radio">
                          <input
                            name="financial"
                            type="radio"
                            className="financial-method-radio-btn"
                            onChange={() => this.financialYearToggle(true)}
                            checked={this.state.financialYear}
                          />
                          <label className="financial-method-radio-text">
                            Financial Year <br></br>{' '}
                            <span className="financial-help-method-org">
                              July 1 to June 30 Following Year
                            </span>
                          </label>
                        </div>
                      </Grid.Column>
                      <Grid.Column className="right-column" width={5}>
                        <div className="financial-method-radio">
                          <input
                            name="calendar"
                            type="radio"
                            className="financial-method-radio-btn"
                            onChange={() => this.financialYearToggle(false)}
                            checked={!this.state.financialYear}
                          />
                          <label className="financial-method-radio-text">
                            Calendar Year <br></br>{' '}
                            <span className="financial-help-method-org">
                              January 1 to December 31
                            </span>
                          </label>
                        </div>
                      </Grid.Column>
                      <Grid.Column width={6}></Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
              </div>,
              //   }
              // />,
              <div>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <div className="content-segment-settings">
                        <FeatureWrapper
                          featureName="culture_counts_setting"
                          children={
                            <Fragment className="content-segment-settings">
                              <div className="org two-step-radio-container">
                                <CustomTooltip
                                  state={get(user, 'options.tips')}
                                  //TODO: change account help tip here
                                  contentObject={{
                                    data: helpTips,
                                    key: account ? '198' : null,
                                  }}
                                  position="top left"
                                  wide="very"
                                >
                                  <label>Culture Counts</label>
                                </CustomTooltip>
                                <Radio
                                  toggle
                                  checked={cultureCounts}
                                  onChange={this.cultureCountsToggle}
                                />
                              </div>
                            </Fragment>
                          }
                        />
                      </div>
                    </Grid.Column>
                    <Grid.Column width={6}></Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <FeatureWrapper
                        featureName="management_code_setting"
                        children={
                          <div className="content-segment-settings">
                            <div className="org two-step-radio-container">
                              <CustomTooltip
                                state={get(user, 'options.tips')}
                                //TODO: change account help tip here
                                contentObject={{
                                  data: helpTips,
                                  key: account ? '200' : '124',
                                }}
                                position="top left"
                                wide="very"
                              >
                                <label>Management Code</label>
                              </CustomTooltip>
                              <Radio
                                toggle
                                checked={authCode}
                                onChange={this.authCodeToggle}
                              />
                            </div>
                          </div>
                        }
                      />
                    </Grid.Column>
                    <Grid.Column className="right-column" width={5}>
                      {authCode ? (
                        <div className="content-segment-settings">
                          <div className=" reference-holder-margin">
                            <div>
                              <Input
                                placeholder="Enter management code label"
                                name="authorisationCode"
                                type="text"
                                className="auth-code-input"
                                value={authorisationCode}
                                onChange={(e) => {
                                  this.onChange(
                                    'authorisationCode',
                                    e.target.value
                                  );
                                }}
                                onBlur={() =>
                                  this.updateOrganizationSettings(
                                    'authorisationCode'
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column width={6}></Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <FeatureWrapper
                        featureName="approval_report_setting"
                        children={
                          <div className="content-segment-settings">
                            <div className="org two-step-radio-container">
                              <CustomTooltip
                                state={get(user, 'options.tips')}
                                //TODO: change account help tip here
                                contentObject={{
                                  data: helpTips,
                                  key: account ? '194' : '123',
                                }}
                                position="top left"
                                wide="very"
                              >
                                <label>Require Approval</label>
                              </CustomTooltip>
                              <Radio
                                toggle
                                checked={tips}
                                onChange={this.toggle}
                              />
                            </div>
                          </div>
                        }
                      />
                    </Grid.Column>
                    <Grid.Column className="right-column" width={5}>
                      <div className="content-segment-settings">
                        <div className="org two-step-radio-container">
                          <CustomTooltip
                            state={get(user, 'options.tips')}
                            //TODO: change account help tip here
                            contentObject={{
                              data: helpTips,
                              key: account ? '202' : '190',
                            }}
                            position="top left"
                            wide="very"
                          >
                            <label>Cross Reference</label>
                          </CustomTooltip>
                          <Radio
                            toggle
                            checked={crossReference}
                            onChange={
                              crossReference
                                ? () => this.toggleConfirmationModal(true)
                                : () => {
                                    this.crossReferenceToggle();
                                    this.toggleModal();
                                  }
                            }
                          />
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={6}></Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <FeatureWrapper
                        featureName="approval_report_setting"
                        children={
                          <div className="content-segment-settings">
                            <div className="org two-step-radio-container">
                              <CustomTooltip
                                state={get(user, 'options.tips')}
                                content="Turn on if you wish to generate reports by department"
                                position="top left"
                                wide="very"
                              >
                                <label>Departments</label>
                              </CustomTooltip>
                              <Radio
                                toggle
                                checked={department}
                                onChange={this.onChangeDepartment}
                              />
                            </div>
                          </div>
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>,
            ]}

        <FeatureWrapper
          featureName="cross_reference_setting"
          children={
            <div className="content-segment-settings ">
              {crossReference ? (
                <div className="row reference-holder-margin">
                  <div className="col-lg-4 reference-holder-bottom">
                    <this.CrossReferenceDocumentCreateModal />
                  </div>
                </div>
              ) : null}
            </div>
          }
        />
        <this.InfoModal />
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    user: state.authentication.user,
    organization: state.organization,
    helpTips: state.extra.activityHelpTips,
    orgGoalsReferences: state.organization.orgGoalsReferences,
    crossRefDocuments: state.organization.crossRefDocuments,
    crossRefDocLoading: state.organization.crossRefDocLoading,
    organisationGoalsReferencesLoading:
      state.organization.organisationGoalsReferencesLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateOrganizationInfo,
      putOrgGoalReferences,
      putOrgArchivedGoalReferences,
      deleteOrgGoalRefList,
      getOrgGoalRefList,
      postCrossReferenceDocument,
      getCrossReferenceDocumentList,
    },
    dispatch
  );
};

OrganizationGLApproval = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationGLApproval);

export { OrganizationGLApproval };
