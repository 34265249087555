import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Grid,
  Segment,
  Button,
  Modal,
  Icon,
  Dropdown,
} from 'semantic-ui-react';
import { AvForm } from 'availity-reactstrap-validation';
import {
  find,
  filter,
  map,
  isEmpty,
  get,
  size,
} from '../../../../utils/lodash';
import { CustomTooltip } from '../../../../components';
import {
  getOrganizationUsers,
  getOrgProjects,
  reassignAllOrgActivities,
  reassignOrgActivity,
  getUserActivitiesCount,
} from '../../../../store/actions';
import '../organization.scss';
import AvRadioGroup from 'availity-reactstrap-validation/lib/AvRadioGroup';
import AvRadio from 'availity-reactstrap-validation/lib/AvRadio';
import ArchivedActivityInfoModal from './archivedActivityInfoModal';

class GroupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async handleValidSubmit(event, values) {
    const {
      selectedProject,
      activityUser,
      reassignOrgActivity,
      organization,
      form,
    } = this.props;

    await reassignOrgActivity(
      organization.organization.id,
      selectedProject.id,
      {
        user: activityUser,
        reassignType: values.activity,
      }
    );
    form && form.reset();
    this.newForm && this.newForm.reset();
    this.toggle();
  }

  onActivateToggle() {
    this.setState((prevState) => {
      return { singleActivity: !prevState.singleActivity };
    });
  }

  onActivateAll() {
    this.setState((prevState) => {
      return { allActivity: !prevState.allActivity };
    });
  }

  getTotalGroups() {
    const { orgProjects } = this.props.organization;
    const { selectedProject } = this.props;

    const sameGroup = filter(orgProjects, {
      projectGroupId: Number(selectedProject.projectGroupId),
    });

    return size(sameGroup);
  }

  render() {
    const { modal } = this.state;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="small"
        trigger={
          <div className="ui customColor button custom-margin">Reassign</div>
        }
      >
        <Modal.Header>Select an Option</Modal.Header>
        <Modal.Content>
          <AvForm
            ref={(c) => (this.newForm = c)}
            onValidSubmit={(event, values) =>
              this.handleValidSubmit(event, values)
            }
            className="activity-reassign"
          >
            <label>
              This activity is part of a group with {this.getTotalGroups()}{' '}
              other activities. If you wish to remove this activity from the
              group and reassign to another user, select Single activity. If you
              wish to reassign all activities in the group to a new user, select
              All activities.
            </label>

            <AvRadioGroup
              inline
              name="activity"
              label=""
              className="radio-reassign"
              required
            >
              <AvRadio label="Single Activity" value="singleActivity" />
              <AvRadio label="All Activities" value="allActivity" />
            </AvRadioGroup>

            <Button
              type="submit"
              className="ui customColor button custom-new-reassign"
            >
              Reassign
            </Button>
          </AvForm>
        </Modal.Content>
      </Modal>
    );
  }
}

class ActivityForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      assignUsers: [],
      selectedProject: '',
      activityUser: '',
      reAssignErrors: {
        reassignAnSelectFrom: false,
        reassignAnSelectTo: false,
      },
      reAssignErrorMsgs: {
        activityMsg: 'Select Activities is Required',
        assignToMsg: 'Assign to is Required',
      },
      selectedProjectId: '',
    };
  }

  async handleValidSubmit(event, values) {
    const { reassignOrgActivity, organization } = this.props;
    const { selectedProject, activityUser } = this.state;

    if (!activityUser || !selectedProject.id) {
      this.setState((prev) => ({
        reAssignErrors: {
          ...prev.reAssignErrors,
          reassignAnSelectFrom: !selectedProject.id && true,
          reassignAnSelectTo: !activityUser && true,
        },
      }));
    } else {
      if (selectedProject.projectGroupId === null) {
        await reassignOrgActivity(selectedProject.id, {
          user: activityUser,
        });
        this.form && this.form.reset();
        this.setState({
          activityUser: '',
          selectedProjectId: '',
        });
      }
    }
  }

  handleSelectActivity(event, value) {
    const { orgUsers, orgProjects } = this.props.organization;
    let users = [];
    let selectedProject = '';
    if (value) {
      selectedProject = find(orgProjects, { id: Number(value) });
      users = filter(orgUsers, (item) => {
        return item.id !== selectedProject.ownerId;
      });
    }
    this.setState({
      assignUsers: users,
      selectedProject: selectedProject,
      selectedProjectId: Number(value),
    });
  }

  // NOTE: This should be change currently only shows single activities by filtering
  selectProjects() {
    const { orgProjects } = this.props.organization;
    return map(orgProjects, (item, i) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
  }

  selectUsers() {
    const { assignUsers } = this.state;
    return map(assignUsers, (item, i) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
  }

  handleGetUser(event, values) {
    this.setState({
      activityUser: values,
    });
  }

  render() {
    const { user, helpTips, reassignAnActivity } = this.props;
    const {
      selectedProject,
      reAssignErrors,
      reAssignErrorMsgs,
      activityUser,
      selectedProjectId,
    } = this.state;

    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="activity-reassign"
      >
        <div className="reassign">
          <CustomTooltip
            state={get(user, 'options.tips')}
            contentObject={{ data: helpTips, key: '175' }}
            position="top left"
            wide="very"
          >
            <label className="reassign-header">Reassign An Activity</label>
          </CustomTooltip>
          <label className="reassign-description">
            Transfer management of individual activities
          </label>
        </div>

        <div className="select-items-row-org">
          <label className="input-label">Select Activity</label>
          <div
            className={reAssignErrors.reassignAnSelectFrom && 'error-container'}
          >
            <Dropdown
              className="org-inputs"
              options={this.selectProjects()}
              search
              selection
              placeholder="Select Activity"
              onChange={(event, data) =>
                this.handleSelectActivity(event, data.value)
              }
              value={selectedProjectId}
            />

            {reAssignErrors.reassignAnSelectFrom && (
              <label>{reAssignErrorMsgs.activityMsg}</label>
            )}
          </div>
        </div>

        <div className="select-items-row-org">
          <label className="input-label">Assign to</label>
          <div
            className={reAssignErrors.reassignAnSelectTo && 'error-container'}
          >
            <Dropdown
              className="org-inputs"
              options={this.selectUsers()}
              search
              selection
              placeholder="Assign to"
              onChange={(event, data) => this.handleGetUser(event, data.value)}
              value={activityUser}
            />

            {reAssignErrors.reassignAnSelectTo && (
              <label>{reAssignErrorMsgs.assignToMsg}</label>
            )}
          </div>
        </div>
        {selectedProject.projectGroupId === null || isEmpty(selectedProject) ? (
          <Button
            type="submit"
            className="ui Primary-Button "
            loading={reassignAnActivity}
            disabled={reassignAnActivity}
          >
            Reassign
          </Button>
        ) : (
          <GroupModal
            selectedProject={this.state.selectedProject}
            activityUser={activityUser}
            {...this.props}
            form={this.form}
          />
        )}
      </AvForm>
    );
  }
}

class AllActivityForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      assignUsers: [],
      activityFromId: '',
      assignToId: '',
      reAssignErrors: {
        reassignAllSelectFrom: false,
        reassignAllSelectTo: false,
      },
      reAssignErrorMsgs: {
        activityMsg: 'Select Activities is Required',
        assignToMsg: 'Assign to is Required',
      },
      archivedActivityInfoModal: false,
      activitiesCount: 0,
    };
  }

  async handleValidSubmit(event, values) {
    try {
      const { userActivities } = this.props;
      const { activityFromId, assignToId } = this.state;

      if (!activityFromId || !assignToId) {
        this.setState((prev) => ({
          reAssignErrors: {
            ...prev.reAssignErrors,
            reassignAllSelectFrom: !activityFromId && true,
            reassignAllSelectTo: !assignToId && true,
          },
        }));
      } else {
        if (
          userActivities.completedOwned > 0
          // TODO: if needed for checking invited ones uncomment below code
          // || userActivities.inActiveInvited > 0 ||
          // userActivities.inDevelopmentInvited > 0
        ) {
          this.handleArchivedActivityInfoModal(true, userActivities);
        } else {
          this.handleAssignAllActivities(false);
        }
      }
    } catch (error) {
      error.log('Reassign Submit ~~', error);
    }
  }

  async handleSelectActivityUser(event, value) {
    const {
      organization: { orgUsers },
      getUserActivitiesCount,
    } = this.props;
    let users = [];

    if (value) {
      const selectedUser = find(orgUsers, { id: Number(value) });

      await getUserActivitiesCount(selectedUser.id);

      users = filter(orgUsers, (item) => {
        return item.id !== selectedUser.id;
      });
    }
    this.setState({ assignUsers: users, activityFromId: value });
  }

  onChangeAssignUser(event, value) {
    this.setState({
      assignToId: value,
    });
  }

  selectFromUsers() {
    const { orgUsers } = this.props.organization;
    return map(orgUsers, (item, i) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
  }

  selectToUsers() {
    const { assignUsers } = this.state;
    return map(assignUsers, (item, i) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
  }

  handleArchivedActivityInfoModal(modal, count) {
    this.setState({
      archivedActivityInfoModal: modal,
      activitiesCount: count,
    });
  }

  async handleAssignAllActivities(
    isTransferArchivedActivities = false,
    isTransferInvitedActiveDevelopment = false
  ) {
    const { activityFromId, assignToId } = this.state;

    const { reassignAllOrgActivities, organization } = this.props;

    await reassignAllOrgActivities({
      from: activityFromId,
      to: assignToId,
      isTransferArchivedActivities,
      isTransferInvitedActiveDevelopment,
    });
    this.form && this.form.reset();
    this.setState({
      activityFromId: '',
      assignToId: '',
    });
  }

  render() {
    const { user, helpTips, reassignAll } = this.props;
    const {
      reAssignErrors,
      reAssignErrorMsgs,
      archivedActivityInfoModal,
      assignToId,
      activityFromId,
      activitiesCount,
    } = this.state;

    return (
      <>
        <AvForm
          ref={(c) => (this.form = c)}
          onValidSubmit={(event, values) =>
            this.handleValidSubmit(event, values)
          }
        >
          <div className="reassign">
            <CustomTooltip
              state={get(user, 'options.tips')}
              contentObject={{ data: helpTips, key: '174' }}
              position="top left"
              wide="very"
            >
              <label className="reassign-header">Reassign All Activities</label>
            </CustomTooltip>
            <label className="reassign-description">
              Transfer all managed activities from one user to another
            </label>
          </div>
          <div className="select-items-row-org">
            <label>Select Activities from</label>

            <div
              className={
                reAssignErrors.reassignAllSelectFrom && 'error-container'
              }
            >
              <Dropdown
                className="org-inputs"
                search
                selection
                placeholder="Select Activities from"
                options={this.selectFromUsers()}
                onChange={(event, data) =>
                  this.handleSelectActivityUser(event, data.value)
                }
                value={activityFromId}
              />
              <br />
              {reAssignErrors.reassignAllSelectFrom && (
                <label>{reAssignErrorMsgs.activityMsg}</label>
              )}
            </div>
          </div>

          <div className="select-items-row-org">
            <label>Assign to</label>
            <div
              className={
                reAssignErrors.reassignAllSelectTo && 'error-container'
              }
            >
              <Dropdown
                className="org-inputs"
                options={this.selectToUsers()}
                search
                selection
                placeholder="Assign to"
                onChange={(event, data) =>
                  this.onChangeAssignUser(event, data.value)
                }
                value={assignToId}
              />
              <br />
              {reAssignErrors.reassignAllSelectTo && (
                <label>{reAssignErrorMsgs.assignToMsg}</label>
              )}
            </div>
          </div>

          <Button
            type="submit"
            className="ui customColor button custom-margin"
            loading={reassignAll}
            disabled={reassignAll}
          >
            Reassign
          </Button>
        </AvForm>
        <ArchivedActivityInfoModal
          modal={archivedActivityInfoModal}
          setModal={(status) => this.handleArchivedActivityInfoModal(status)}
          count={activitiesCount}
          handleAssignAllActivities={(
            isTransferArchivedActivities,
            isTransferInvitedActiveDevelopment
          ) =>
            this.handleAssignAllActivities(
              isTransferArchivedActivities,
              isTransferInvitedActiveDevelopment
            )
          }
        />
      </>
    );
  }
}

class OrganizationUserReassign extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const { getOrgProjects, auth } = this.props;
    if (!isEmpty(auth.user) && auth.user.organisation) {
      await this.setState({ projectLoading: true });
      await getOrgProjects(auth.user.organisation.id);
      await this.setState({ projectLoading: false });
    }
  }

  render() {
    const { loading } = this.props.organization;
    const { projectLoading } = this.state;
    return (
      <Segment className="content-segment" loading={loading || projectLoading}>
        <Grid columns={2} divided stackable>
          <Grid.Row className="org-row">
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              className="org-reassign-column"
            >
              <AllActivityForm {...this.props} />
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              className="org-reassign-column"
            >
              <ActivityForm {...this.props} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    organization: state.organization,
    helpTips: state.extra.activityHelpTips,
    user: state.authentication.user,
    reassignAll: state.organization.reassignAll,
    userActivities: state.organization.userActivities,
    reassignAnActivity: state.organization.reassignAnActivity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrganizationUsers,
      getOrgProjects,
      reassignAllOrgActivities,
      reassignOrgActivity,
      getUserActivitiesCount,
    },
    dispatch
  );
};

OrganizationUserReassign = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationUserReassign);

export { OrganizationUserReassign };
