import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Checkbox,
  Grid,
  Segment,
  Modal,
  Button,
  Icon,
  Form,
} from 'semantic-ui-react';
import {
  find,
  groupBy,
  map,
  get,
  isEmpty,
  size,
  filter,
  compact,
} from '../../../../utils/lodash';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import {
  putActivity,
  putParentFestivalType,
  postProjectCrossReferences,
} from '../../../../store/actions';
import { CustomTooltip, EmptyContainer } from '../../../../components';
import {
  getActivityType,
  getPageDisable,
  threeDotSeparator,
} from '../../../../utils/';
import {
  isGrantLinkedActivity,
  isInternalLinkedActivity,
  isParentProgramActivity,
} from '../../../../utils/activity';
import '../activity.scss';
import ShowMoreText from 'react-show-more-text';
import { CustomOptionGroupDropDown } from '../../../../components/form';
import { Formik } from 'formik';
import {
  activityPlanDetailValidationSchema,
  activityPlanEventDetailInitialValues,
} from '../../../../validations/activityPlanValidation';
import moment from 'moment';

class ActivityData extends React.PureComponent {
  constructor(props) {
    super(props);
    this.eventDetailRef = React.createRef();
    this.state = {
      form: {
        name: props.currentActivity.name ? props.currentActivity.name : '',
        description: props.currentActivity.description
          ? props.currentActivity.description
          : '',
        referenceNumber: props.currentActivity.referenceNumber
          ? props.currentActivity.referenceNumber
          : '',
        eventDetail: props.currentActivity.eventDetail
          ? props.currentActivity.eventDetail.detail
          : undefined,
        grantId: props.currentActivity.grantId
          ? props.currentActivity.grantId
          : '',
      },
      referenceEditModal: false,
      projectAssociatedGoalReferencesState: props.currentActivity
        .crossReferenceDocument
        ? props.currentActivity.projectAssociatedGoalReferences
        : [], // contains project -> goal's all references
    };
  }

  renderActTypeOption(types) {
    return map(types, (item, i) => (
      <option key={i} value={item.id}>
        {item.detail}
      </option>
    ));
  }

  renderActivityTypes() {
    const { eventDetails } = this.props;
    const setGroup = groupBy(eventDetails, function (o) {
      return o.eventTypeDetail.type;
    });
    return map(setGroup, (item, group) => {
      return (
        <optgroup key={group} label={group}>
          {this.renderActTypeOption(item)}
        </optgroup>
      );
    });
  }

  onChange(value, type) {
    let { form } = this.state;

    form[type] = value;
    this.setState({ form });
  }

  async setActType(actTypeId) {
    try {
      const { eventDetails, putActivity } = this.props;
      const eventDetail = find(eventDetails, function (o) {
        return o.id === Number(actTypeId);
      });

      await putActivity('activityData', {
        eventDetailId: eventDetail.id,
      });
      const newForm = {
        ...this.state.form,
        eventDetail: eventDetail.detail,
      };
      await this.setState({
        form: newForm,
      });
    } catch (e) {
      const { currentActivity } = this.props;
      const newForm = {
        ...this.state.form,
        eventDetail: currentActivity.eventDetail.detail,
      };
      await this.setState({
        form: newForm,
      });
    }
  }

  onBlurInput(type, required = false) {
    const { putActivity } = this.props;
    const { form } = this.state;

    if (!required || (!isEmpty(form[type]) && required)) {
      putActivity('activityData', {
        [type]: form[type],
      });
    }
  }

  async handleChange(type) {
    try {
      const { currentActivity, putParentFestivalType } = this.props;
      await putParentFestivalType('activityData', currentActivity.id, {
        type,
      });
    } catch (error) {
      // console.error('ActivityObjective -> handleChange -> error', error);
    }
  }

  renderFestivalTypes() {
    let { festivalTypes, currentActivity, user, currentState } = this.props;
    if (isEmpty(festivalTypes)) return null;
    return map(festivalTypes, (item, i) => {
      const checked = find(currentActivity.parentFestivals, (o) => {
        return o.festivalTypeId === item.id;
      });
      return (
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={6}
          className="festival-type"
          key={i}
        >
          <Checkbox
            className="check-box"
            label={item.title}
            key={i}
            value={item.id}
            onChange={() => this.handleChange(item.id)}
            checked={isEmpty(checked) ? false : true}
            disabled={getPageDisable(currentActivity, user, currentState)}
          />
          <label className="check-box-description">{item.description}</label>
        </Grid.Column>
      );
    });
  }

  handleProjectReferenceModal = async (status) => {
    this.setState({
      referenceEditModal: status,
    });
  };

  handleProjectNewReferencesModal = () => {
    this.handleProjectReferenceModal(true);
  };

  handleReferencesChange = (referencesId, checked) => {
    const { projectAssociatedGoalReferencesState } = this.state;

    const reference = map(projectAssociatedGoalReferencesState, (m) => {
      if (m.id === referencesId) {
        return {
          ...m,
          isChecked: checked,
        };
      }
      return m;
    });

    this.setState({
      projectAssociatedGoalReferencesState: reference,
    });
  };

  onSaveProjectReferences = async () => {
    const { currentActivity, postProjectCrossReferences } = this.props;
    const { projectAssociatedGoalReferencesState } = this.state;
    const projectId = currentActivity.id;
    const projectReferences = filter(
      projectAssociatedGoalReferencesState,
      (reference) => {
        if (reference.isChecked) return reference.id;
      }
    );
    const projectReferencesIdArr = map(
      projectReferences,
      (reference) => reference.id
    );

    const isSuccess = await postProjectCrossReferences(
      projectId,
      projectReferencesIdArr
    );
    this.handleProjectReferenceModal(!isSuccess);
  };

  customGroupBy = (array, key) => {
    return array.reduce((result, obj) => {
      result[obj[key]] = result[obj[key]] || {
        id: obj.eventType,
        type: obj.eventTypeDetail.type,
      };

      return result;
    }, {});
  };

  renderHeaders = () => {
    const { eventDetails } = this.props;
    const setGroup = this.customGroupBy(eventDetails, 'eventType');

    return Object.values(setGroup).map((item, index) => {
      // eslint-disable-next-line
      return {
        id: item.id,
        content: item.type,
      };
    });
  };

  renderItems = () =>
    map(this.props.eventDetails, (eventDetail, i) => {
      return this.renderItemsData(eventDetail.eventType);
    });

  renderItemsData = (eventDetailId) => {
    const { eventDetails } = this.props;

    if (isEmpty(eventDetails)) return null;

    const data = eventDetails.filter(
      (eventDetail) => eventDetail.eventType === eventDetailId
    );
    return compact(
      map(data, (item, key) => {
        if (isEmpty(item)) return null;
        return {
          key,
          text: item.detail,
          value: item.id,
          rest: {
            key: item.eventType,
          },
        };
      })
    );
  };

  render() {
    const {
      currentActivity,
      user,
      helpTips,
      currentState,
      projectAssociatedGoalReferencesLoading,
    } = this.props;

    const { referenceEditModal, projectAssociatedGoalReferencesState } =
      this.state;

    const isPageDisabled = getPageDisable(currentActivity, user, currentState);

    const projectAssociatedGoalReferences =
      currentActivity.crossReferenceDocument
        ? currentActivity.projectAssociatedGoalReferences
        : [];
    const projectCrossReferenceDocument =
      currentActivity.crossReferenceDocument;
    const isGoalSelectedByProject = currentActivity.selectedPrimaryGoalId
      ? true
      : false;
    const projectCrossReferences = get(
      currentActivity,
      'projectCrossReferences',
      []
    );

    // need to identify old ref one or not
    const actType = getActivityType(currentActivity);

    const actTypeDef = currentActivity.eventDetail
      ? currentActivity.eventDetail.definition
        ? currentActivity.eventDetail.definition
        : null
      : null;

    return (
      <Segment
        className={`${
          getPageDisable(currentActivity, user, currentState)
            ? 'content-segment disabled-input-status'
            : 'content-segment'
        }`}
      >
        <AvForm ref={(c) => (this.form = c)} className="activity-form-single">
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={5}>
                <AvField
                  className={
                    isParentProgramActivity(currentActivity.activityPlanTypeId)
                      ? 'act-inputs-custom-grants'
                      : 'act-inputs-custom-act-name'
                  }
                  label={
                    <CustomTooltip
                      state={get(user, 'options.tips')}
                      contentObject={{
                        data: helpTips,
                        key: '37',
                      }}
                      position="top left"
                      wide="very"
                    >
                      <div>Activity Name</div>
                    </CustomTooltip>
                  }
                  name="name"
                  type="text"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Activity name is required',
                    },
                  }}
                  value={this.state.form.name}
                  onChange={(e) => this.onChange(e.target.value, 'name')}
                  onBlur={() => this.onBlurInput('name', true)}
                  disabled={getPageDisable(currentActivity, user, currentState)}
                />
              </Grid.Column>

              {/* 'Grant ID' is only available for Grant's Linked activities */}
              {currentActivity.parentActivity &&
                isGrantLinkedActivity(
                  currentActivity.parentActivity.activityPlanTypeId,
                  currentActivity.isLinkedActivity
                ) && (
                  <Grid.Column mobile={16} tablet={16} computer={4}>
                    <AvField
                      className="act-inputs-custom-grant"
                      label={
                        <CustomTooltip
                          state={get(user, 'options.tips')}
                          contentObject={{
                            data: helpTips,
                            key: '37',
                          }}
                          position="top left"
                          wide="very"
                        >
                          <div>Grant ID</div>
                        </CustomTooltip>
                      }
                      name="grantId"
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Grant ID is required',
                        },
                      }}
                      value={this.state.form.grantId}
                      onChange={(e) => this.onChange(e.target.value, 'grantId')}
                      onBlur={() => this.onBlurInput('grantId', true)}
                      disabled={getPageDisable(
                        currentActivity,
                        user,
                        currentState
                      )}
                    />
                  </Grid.Column>
                )}
            </Grid.Row>

            {currentActivity.activityPlanTypeId === 3 && (
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={11}>
                  <div className="form-group">
                    <div className="select-festival-type">
                      Select Festival Type
                    </div>
                  </div>
                  <Grid>{this.renderFestivalTypes()}</Grid>
                </Grid.Column>
              </Grid.Row>
            )}

            {size(projectAssociatedGoalReferences) > 0 &&
            isGoalSelectedByProject ? (
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={9}
                  className="select-ref"
                >
                  <div className="d-flex select-ref-header">
                    <div>
                      {threeDotSeparator({
                        name: `Select ${
                          projectCrossReferenceDocument
                            ? projectCrossReferenceDocument.referenceFieldLabel
                            : 'Reference'
                        }`,
                        length: 72,
                        isPopUp: false,
                      })}
                    </div>
                    {size(projectAssociatedGoalReferences) > 0 &&
                    size(projectCrossReferences) > 0 ? (
                      <div
                        className="manage-reference-lbl ml-auto"
                        onClick={
                          !isPageDisabled
                            ? () => this.handleProjectReferenceModal(true)
                            : () => {}
                        }
                      >
                        {!isPageDisabled && 'Manage Reference'}
                      </div>
                    ) : null}
                  </div>
                  <Segment className="select-ref-seg">
                    {projectCrossReferences &&
                    size(projectCrossReferences) > 0 ? (
                      <ul className="outcome-title-ul-custom">
                        {map(projectCrossReferences, (item, key) => {
                          // if (item.isChecked)
                          return (
                            <li key={key} className="ref-item">
                              {' '}
                              {get(item, 'crossReferenceLabel', '-')}
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <EmptyContainer
                        msg="No reference have been selected"
                        action={
                          size(projectAssociatedGoalReferences) > 0 &&
                          size(projectCrossReferences) === 0 &&
                          !isPageDisabled
                            ? {
                                lbl: 'Select Reference',
                                method: this.handleProjectNewReferencesModal,
                              }
                            : {}
                        }
                      />
                    )}
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            ) : null}
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={9}>
                {!isParentProgramActivity(
                  currentActivity.activityPlanTypeId
                ) && (
                  <Formik
                    initialValues={activityPlanEventDetailInitialValues(
                      this.state.form.eventDetail
                    )}
                    validationSchema={activityPlanDetailValidationSchema}
                  >
                    {({ handleSubmit, errors, isSubmitting }) => (
                      <Form>
                        <CustomOptionGroupDropDown
                          name="eventDetail"
                          id="eventDetail"
                          labelText={
                            <CustomTooltip
                              state={get(user, 'options.tips')}
                              contentObject={{
                                data: helpTips,
                                key: '77',
                              }}
                              position="top left"
                              wide="very"
                            >
                              <div>Select Activity Type</div>
                            </CustomTooltip>
                          }
                          placeholder="Select Activity Type"
                          renderHeaders={this.renderHeaders()}
                          renderItems={this.renderItems()}
                          disabled={getPageDisable(
                            currentActivity,
                            user,
                            currentState
                          )}
                          text={this.state.form.eventDetail}
                          onChange={(value) => {
                            this.setActType(value);
                          }}
                        />
                      </Form>
                    )}
                  </Formik>
                )}

                {!isParentProgramActivity(currentActivity.activityPlanTypeId) &&
                  actTypeDef && (
                    <div className="form-group">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: actTypeDef ? actTypeDef : null,
                        }}
                        className="objective-description"
                      />
                    </div>
                  )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={9}>
                <AvField
                  className="act-inputs-custom theory-textarea"
                  label={
                    <CustomTooltip
                      state={get(user, 'options.tips')}
                      contentObject={{
                        data: helpTips,
                        key: '100',
                      }}
                      position="top left"
                      wide="very"
                    >
                      <div>{`Summarise ${actType.text}`}</div>
                    </CustomTooltip>
                  }
                  name="description"
                  type="textarea"
                  placeholder={`Enter a summary description of the ${
                    isParentProgramActivity(currentActivity.activityPlanTypeId)
                      ? 'Program '
                      : 'Activity'
                  }`}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: `Summarise ${actType.text} is required`,
                    },
                  }}
                  value={this.state.form.description}
                  onChange={(e) => this.onChange(e.target.value, 'description')}
                  onBlur={() => this.onBlurInput('description', true)}
                  disabled={getPageDisable(currentActivity, user, currentState)}
                />
                {/* )} */}
              </Grid.Column>

              {/* {currentActivity.isParent ? null : (
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {actTypeDef ? (
                    <div className="form-group">
                      <label>
                        <div>SELECTED ACTIVITY TYPE DESCRIPTION</div>
                      </label>
                      <br />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: actTypeDef ? actTypeDef : null
                        }}
                        className="objective-description"
                      />
                    </div>
                  ) : null}
                </Grid.Column>
              )} */}
            </Grid.Row>
            {currentActivity.parentActivity
              ? isInternalLinkedActivity(currentActivity) && (
                  <Grid.Row>
                    <div className="report-year-text">
                      <label>Reporting Date</label>
                      <label className="report-label">
                        {currentActivity?.parentActivity?.reportYear
                          ? moment(
                              currentActivity.parentActivity.reportYear
                            ).format('DD MMM YYYY')
                          : '-'}
                      </label>
                    </div>
                  </Grid.Row>
                )
              : null}
          </Grid>
        </AvForm>
        <Modal
          open={referenceEditModal}
          size="tiny"
          closeIcon={
            <Icon
              onClick={() => this.handleProjectReferenceModal(false)}
              name="close"
              className="closeicon"
            />
          }
          className="select-ref-model"
        >
          <Modal.Header>
            {' '}
            {threeDotSeparator({
              name: `Select ${
                projectCrossReferenceDocument
                  ? projectCrossReferenceDocument.referenceFieldLabel
                  : 'Reference'
              }`,
              length: 40,
              isPopUp: false,
            })}
          </Modal.Header>
          <Modal.Content>
            <div>
              {map(projectAssociatedGoalReferencesState, (item, key) => {
                return (
                  <Fragment key={`reference_checkbox_group_${key}`}>
                    <div className="reference-checkbox">
                      <Checkbox
                        value={item.id}
                        className="check-box my-1"
                        checked={item.isChecked} //projectReferencesIdArr
                        onChange={(e, { checked }) =>
                          this.handleReferencesChange(item.id, checked)
                        }
                      />
                      <ShowMoreText
                        lines={1}
                        more="Show more"
                        less="Show less"
                        anchorClass="show-more-less-clickable"
                        className="reference-label"
                        expanded={false}
                        truncatedEndingComponent={'... '}
                      >
                        <p>{item.crossReferenceLabel}</p>
                      </ShowMoreText>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </Modal.Content>
          <Modal.Actions>
            <div className="model-buttons">
              <Button
                onClick={() => this.handleProjectReferenceModal(false)}
                className="Secondary-Button"
              >
                Cancel
              </Button>
              <Button
                loading={projectAssociatedGoalReferencesLoading}
                onClick={() => this.onSaveProjectReferences()}
                className="Primary-Button"
                content="Save"
              />
            </div>
          </Modal.Actions>
        </Modal>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    organization: state.organization,
    festivalTypes: state.extra.festivalTypes,
    eventDetails: state.extra.eventDetails,
    user: state.authentication.user,
    helpTips: state.extra.activityHelpTips,
    crossRefDocuments: state.organization.crossRefDocuments,
    projectCrossReferencesLoading: state.activity.projectCrossReferencesLoading,
    projectCrossRefDocument: state.activity.projectCrossRefDocument,
    projectAssociatedGoalReferences:
      state.activity.projectAssociatedGoalReferences,
    projectAssociatedGoalReferencesLoading:
      state.activity.projectCrossReferencesLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      putActivity,
      putParentFestivalType,
      postProjectCrossReferences,
    },
    dispatch
  );
};

ActivityData = connect(mapStateToProps, mapDispatchToProps)(ActivityData);

export { ActivityData };
