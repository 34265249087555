import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isBrowser, isTablet } from 'react-device-detect';
import { Link } from 'react-router-dom';
import moment from 'moment';
import React from 'react';
import {
  Segment,
  Modal,
  Icon,
  Grid,
  Tab,
  Dropdown,
  Popup,
} from 'semantic-ui-react';

import {
  EmptyContainer,
  CustomTable,
  CustomSegment,
} from '../../../components';
import { isEmpty, compact } from '../../../utils/lodash';
import { getActivityStatus, getActivityType } from '../../../utils';
import {
  getOrgActivityStat,
  getOwnedActivitiesByUser,
  getInvitedActivitiesByUser,
} from '../../../store/actions';

import './organization.scss';

const options = [
  {
    key: 'Ongoing',
    text: <b>Ongoing Activities</b>,
    value: 'ongoing',
  },
  {
    key: 'Completed',
    text: <b>Completed Activities</b>,
    value: 'completed',
  },
  {
    key: 'All Activities',
    text: <b>All Activities</b>,
    value: 'all',
  },
];

const act_type_options = [
  {
    key: 'All',
    text: <b>All</b>,
    value: 'all',
  },
  {
    key: 'Single',
    text: <b>Single</b>,
    value: 'single',
  },
  {
    key: 'Grant',
    text: <b>Grant</b>,
    value: 'grant',
  },
  {
    key: 'Festival',
    text: <b>Festival</b>,
    value: 'festival',
  },
  {
    key: 'Multi Activity',
    text: <b>Multi Activity</b>,
    value: 'multi-activity',
  },
  {
    key: 'Linked',
    text: <b>Linked</b>,
    value: 'linked',
  },
];

class ActivityListView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      filter: {
        owned: 'all',
        invited: 'all',
      },
      actType: {
        owned: 'all',
        invited: 'all',
      },
      currentPage: {
        owned: 1,
        invited: 1,
      },
    };
  }

  toggle() {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  }

  getActivityList(type, page = 1) {
    const {
      getOwnedActivitiesByUser,
      getInvitedActivitiesByUser,
      selectedUser,
    } = this.props;
    const { filter, actType } = this.state;

    if (type === 'owned') {
      getOwnedActivitiesByUser(
        'ownedProjectLoading',
        selectedUser.id,
        filter.owned,
        actType.owned,
        page
      );
    } else {
      getInvitedActivitiesByUser(
        'invitedProjectLoading',
        selectedUser.id,
        filter.invited,
        actType.invited,
        page
      );
    }
  }

  async setFilter(type, filterType, value) {
    await this.setState((preState, props) => {
      return {
        ...preState,
        [filterType]: { ...preState[filterType], [type]: value },
        currentPage: { ...preState.currentPage, [type]: 1 },
      };
    });
    this.getActivityList(type);
  }

  pageChange = async (type, page) => {
    await this.setState((state, props) => {
      return {
        ...state,
        currentPage: { ...state.currentPage, [type]: page },
      };
    });
    this.getActivityList(type, page);
  };

  resetFilters = () => {
    this.setState({
      filter: {
        owned: 'all',
        invited: 'all',
      },
      actType: {
        owned: 'all',
        invited: 'all',
      },
      currentPage: {
        owned: 1,
        invited: 1,
      },
    });
  };

  render() {
    const { modal, filter, actType } = this.state;
    const { selectedUser } = this.props;

    return (
      <div className="modal-section">
        <Modal
          trigger={
            <label className="org-trigger-actions">{selectedUser.name}</label>
          }
          size="large"
          open={modal}
          onOpen={() => this.toggle()}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
          className="user-activity"
        >
          <Modal.Header>User Activities - {selectedUser.name}</Modal.Header>
          <Modal.Content>
            <Grid.Row>
              {modal ? (
                <div className="tab-section">
                  <Tab
                    className="activity-tabs"
                    menu={{
                      secondary: true,
                      pointing: true,
                    }}
                    panes={[
                      {
                        menuItem: 'Managed Activities',
                        render: () => (
                          <Tab.Pane>
                            <CustomSegment
                              title=""
                              rightHeader={
                                <div className="activities-filter-container">
                                  <span className="activities-filter">
                                    Type: &nbsp;
                                    <Dropdown
                                      direction="left"
                                      inline
                                      selectOnBlur={false}
                                      value={actType.owned}
                                      options={act_type_options}
                                      onChange={(e, { value }) =>
                                        this.setFilter(
                                          'owned',
                                          'actType',
                                          value
                                        )
                                      }
                                    />
                                  </span>
                                  <span className="activities-filter">
                                    Show by: &nbsp;
                                    <Dropdown
                                      direction="left"
                                      inline
                                      selectOnBlur={false}
                                      value={filter.owned}
                                      options={options}
                                      onChange={(e, { value }) =>
                                        this.setFilter('owned', 'filter', value)
                                      }
                                    />
                                  </span>
                                </div>
                              }
                            >
                              <UsersOwnedActivities
                                selectedUser={selectedUser}
                                pageChange={(type, page) =>
                                  this.pageChange(type, page)
                                }
                                resetFilters={this.resetFilters}
                                {...this.state}
                                {...this.props}
                              />
                            </CustomSegment>
                          </Tab.Pane>
                        ),
                      },
                      {
                        menuItem: 'Invited Activities',
                        render: () => (
                          <Tab.Pane>
                            <CustomSegment
                              title=""
                              rightHeader={
                                <div className="activities-filter-container">
                                  <span className="activities-filter">
                                    Type: &nbsp;
                                    <Dropdown
                                      direction="left"
                                      inline
                                      selectOnBlur={false}
                                      value={actType.invited}
                                      options={act_type_options}
                                      onChange={(e, { value }) =>
                                        this.setFilter(
                                          'invited',
                                          'actType',
                                          value
                                        )
                                      }
                                    />
                                  </span>
                                  <span className="activities-filter">
                                    Show by: &nbsp;
                                    <Dropdown
                                      direction="left"
                                      inline
                                      selectOnBlur={false}
                                      value={filter.invited}
                                      options={options}
                                      onChange={(e, { value }) =>
                                        this.setFilter(
                                          'invited',
                                          'filter',
                                          value
                                        )
                                      }
                                    />
                                  </span>
                                </div>
                              }
                            >
                              <UsersInvitedActivities
                                selectedUser={selectedUser}
                                pageChange={(type, page) =>
                                  this.pageChange(type, page)
                                }
                                resetFilters={this.resetFilters}
                                {...this.state}
                                {...this.props}
                              />
                            </CustomSegment>
                          </Tab.Pane>
                        ),
                      },
                    ]}
                  />
                </div>
              ) : null}
            </Grid.Row>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class UsersOwnedActivities extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          width: 5,
          title: 'Activity Name',
          render: (data) => {
            return (
              <div className="activity-name">
                <div
                  className={`square activity-${getActivityStatus(
                    data.statusId
                  )}`}
                />
                {data.name ? data.name : ''}
              </div>
            );
          },
        },
        {
          width: 2,
          title: 'Type',
          render: (data) => {
            const actType = getActivityType(data);
            return actType.type;
          },
        },
        {
          width: 1,
          title: 'Todo',
          textAlign: 'center',
          render: (data) => {
            const { label, criticality } = data.notification;
            return !isEmpty(data.notification) ? (
              <Popup
                content={label}
                trigger={
                  <Icon
                    name="info circle"
                    color={criticality === 'HIGH' ? 'red' : 'orange'}
                  />
                }
              />
            ) : (
              '-'
            );
          },
        },
        {
          width: 3,
          title: 'Date Created',
          render: (data) => {
            return moment(data.createdDateTime).format('DD MMMM YYYY');
          },
        },
        {
          width: 3,
          title: 'Last Updated',
          render: (data) => {
            return moment(data.lastEditedDateTime).format('DD MMMM YYYY');
          },
        },
        {
          width: 2,

          render: (data) => {
            return (
              <div className="activity-actions">
                <Link to={`/activities/${data.id}/goal`} target="_blank">
                  <label className="activity-actions-warn">
                    Enter Activity
                  </label>
                </Link>
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    const { getOwnedActivitiesByUser, resetFilters, selectedUser } = this.props;
    resetFilters();
    getOwnedActivitiesByUser('ownedProjectLoading', selectedUser.id);
  }

  render() {
    const { columns } = this.state;
    const { currentPage } = this.props;
    const {
      userOwnedActivity,
      customLoading: { ownedProjectLoading },
    } = this.props.organization;

    return (
      <Segment className="content-segment" loading={ownedProjectLoading}>
        {userOwnedActivity.docs && userOwnedActivity.docs.length ? (
          <CustomTable
            header
            columns={compact(columns)}
            data={userOwnedActivity.docs}
            customClass="home-activity"
            pagination
            handlePaginationChange={(page) =>
              this.props.pageChange('owned', page)
            }
            page={currentPage.owned}
            noOfPages={userOwnedActivity.pages}
            keyExtractor={(item, index) => item.id}
          />
        ) : (
          <EmptyContainer msg="No activities…" />
        )}
      </Segment>
    );
  }
}

class UsersInvitedActivities extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          width: 4,
          title: 'Activity Name',
          render: (data) => {
            return (
              <div className="activity-name">
                <div
                  className={`square activity-${getActivityStatus(
                    data.statusId
                  )}`}
                />
                {data.name ? data.name : ''}
              </div>
            );
          },
        },
        {
          width: 1,
          title: 'Type',
          render: (data) => {
            const actType = getActivityType(data);
            return actType.type;
          },
        },
        {
          width: 1,
          title: 'Todo',
          textAlign: 'center',
          render: (data) => {
            const { label, criticality } = data.notification;
            return !isEmpty(data.notification) ? (
              <Popup
                content={label}
                trigger={
                  <Icon
                    name="info circle"
                    color={criticality === 'HIGH' ? 'red' : 'orange'}
                  />
                }
              />
            ) : (
              '-'
            );
          },
        },
        {
          width: 2,
          title: 'Owner',
          render: (data) => {
            return data.owner.name ? data.owner.name : '';
          },
        },
        {
          width: 3,
          title: 'Date Created',
          render: (data) => {
            return moment(data.createdDateTime).format('DD MMMM YYYY');
          },
        },
        {
          width: 3,
          title: 'Last Updated',
          render: (data) => {
            return moment(data.lastEditedDateTime).format('DD MMMM YYYY');
          },
        },
        {
          width: 2,
          title: '',
          render: (data) => {
            return !isEmpty(data.members) ? (
              <div className="activity-actions">
                <Link to={`/activities/${data.id}/goal`} target="_blank">
                  <label className="activity-actions-warn">
                    Enter Activity
                  </label>
                </Link>
              </div>
            ) : (
              <div className="activity-actions">-</div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    const { getInvitedActivitiesByUser, resetFilters, selectedUser } =
      this.props;
    resetFilters();
    getInvitedActivitiesByUser('invitedProjectLoading', selectedUser.id);
  }

  render() {
    const { columns } = this.state;
    const { currentPage } = this.props;
    const {
      userInvitedActivity,
      customLoading: { invitedProjectLoading },
    } = this.props.organization;

    return (
      <Segment className="content-segment" loading={invitedProjectLoading}>
        {userInvitedActivity.docs && userInvitedActivity.docs.length ? (
          <CustomTable
            header
            columns={compact(columns)}
            data={userInvitedActivity.docs}
            customClass="home-activity"
            pagination
            handlePaginationChange={(page) =>
              this.props.pageChange('invited', page)
            }
            page={currentPage.invited}
            noOfPages={userInvitedActivity.pages}
            keyExtractor={(item, index) => item.id}
          />
        ) : (
          <EmptyContainer msg="No activities…" />
        )}
      </Segment>
    );
  }
}

class OrganizationActivity extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      parentHeaders: [
        {
          title: '',
          colSpan: '1',
          className: '',
        },
        {
          title: 'Managed',
          colSpan: '6',
          className: 'table-bottom-border table-right-border',
        },
        {
          title: 'Invited',
          colSpan: '2',
          className: 'table-bottom-border',
        },
      ],
    };
  }

  async componentDidMount() {
    const { auth, getOrgActivityStat } = this.props;
    if (!isEmpty(auth.user)) {
      await getOrgActivityStat();
    }
  }

  getColumns() {
    return [
      {
        title: '',
        render: (data) => {
          return <ActivityListView selectedUser={data} {...this.props} />;
        },
      },
      {
        title: (
          <div className="user-act-heading-container">
            <label className="user-act-heading-type">In Development</label>
          </div>
        ),
        render: (data) => {
          return data.stats.unapproved;
        },
        textAlign: isBrowser || isTablet ? 'center' : null,
      },
      {
        title: (
          <div className="user-act-heading-container">
            <label className="user-act-heading-type">Missed Green Light</label>
          </div>
        ),
        render: (data) => {
          return data.stats.passedGreenLight;
        },
        textAlign: isBrowser || isTablet ? 'center' : null,
      },
      {
        title: (
          <div className="user-act-heading-container">
            <label className="user-act-heading-type">Active</label>
          </div>
        ),
        render: (data) => {
          return data.stats.approved;
        },
        textAlign: isBrowser || isTablet ? 'center' : null,
      },
      {
        title: (
          <div className="user-act-heading-container">
            <label className="user-act-heading-type">
              Documentation Delayed{' '}
            </label>
          </div>
        ),
        render: (data) => {
          return data.stats.documentationDelay;
        },
        textAlign: isBrowser || isTablet ? 'center' : null,
      },
      {
        title: (
          <div className="user-act-heading-container">
            <label className="user-act-heading-type">Ready to Archive </label>
          </div>
        ),
        render: (data) => {
          return data.stats.activityArchive;
        },
        textAlign: isBrowser || isTablet ? 'center' : null,
      },
      {
        title: (
          <div className="user-act-heading-container">
            <label className="user-act-heading-type">Completed</label>
          </div>
        ),
        render: (data) => {
          return data.stats.closed;
        },
        textAlign: isBrowser || isTablet ? 'center' : null,
        className: 'table-right-border',
      },
      {
        title: (
          <div className="user-act-heading-container">
            <label className="user-act-heading-type">In Development</label>
          </div>
        ),
        render: (data) => {
          return data.stats.invitedUnapproved;
        },
        textAlign: isBrowser || isTablet ? 'center' : null,
      },
      {
        title: (
          <div className="user-act-heading-container">
            <label className="user-act-heading-type">Active</label>
          </div>
        ),
        render: (data) => {
          return data.stats.invitedApproved;
        },
        textAlign: isBrowser || isTablet ? 'center' : null,
      },
    ];
  }

  render() {
    const { parentHeaders } = this.state;
    const {
      orgActivityStats,
      customLoading: { getOrgActivityStat },
    } = this.props.organization;
    return (
      <Segment className="content-segment" loading={getOrgActivityStat}>
        <Grid>
          <Grid.Row>
            <Grid.Column className="org-table-container">
              {orgActivityStats && orgActivityStats.length ? (
                <CustomTable
                  parentHeaders={parentHeaders}
                  header
                  columns={this.getColumns()}
                  data={orgActivityStats}
                  customClass="user-activity"
                  keyExtractor={(item, index) => item.id}
                />
              ) : (
                <EmptyContainer msg="No user activities yet…" />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    organization: state.organization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrgActivityStat,
      getOwnedActivitiesByUser,
      getInvitedActivitiesByUser,
    },
    dispatch
  );
};

OrganizationActivity = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationActivity);

export { OrganizationActivity };
