/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useField } from 'formik';
import { cloneDeep, get } from 'lodash';
import { Dropdown, Form, Grid, Table } from 'semantic-ui-react';
import {
  RESTRICTION_TYPES,
  permissionTypes as _permissionTypes,
} from '../../../utils/constant';

// TODO: Need to implement edit / default values
export const UserPermissionsInput = ({
  labelText,
  getValue,
  requiredStar,
  className = null,
  disabled,
  options,
  setFieldValue,
  ...props
}) => {
  const [, meta, helpers] = useField({ ...props });
  const { value } = meta;
  const { setValue } = helpers;

  const [permissionTypes, setPermissionTypes] =
    React.useState(_permissionTypes);
  // let defaultValueText = null;
  // if (disabled) {
  //   defaultValueText = get(
  //     options.find(item => item.key === value),
  //     'text',
  //     'No options available'
  //   );
  // }

  const selectPermissionType = (value, name, checked) => {
    let permissionArray;
    if (value === 'collaborator') {
      permissionArray = permissionTypes.map((permissionType) => {
        const isRestricted = RESTRICTION_TYPES.includes(permissionType.state);
        return {
          ...permissionType,
          title: permissionType.title,
          value: permissionType.title,
          permission: {
            read: true,
            write: isRestricted ? false : true,
          },
          isDisabled: isRestricted,
          state: permissionType.state,
        };
      });
    } else if (value === 'documentor') {
      permissionArray = permissionTypes.map((permissionType) => {
        const isRestricted = RESTRICTION_TYPES.includes(permissionType.state);
        return {
          ...permissionType,
          title: permissionType.title,
          value: permissionType.title,
          permission: {
            read: permissionType.documentor ? true : false,
            write: permissionType.documentor
              ? isRestricted
                ? false
                : true
              : false,
          },
          isDisabled: isRestricted,
          state: permissionType.state,
        };
      });
    } else if (value === 'reportOnly') {
      permissionArray = permissionTypes.map((permissionType) => {
        const isRestricted = RESTRICTION_TYPES.includes(permissionType.state);
        return {
          ...permissionType,
          title: permissionType.title,
          value: permissionType.title,
          permission: {
            read: permissionType.reportOnly ? true : false,
            write: permissionType.reportOnly
              ? isRestricted
                ? false
                : true
              : false,
          },
          isDisabled: isRestricted,
          state: permissionType.state,
        };
      });
    } else if (value === 'custom') {
      permissionArray = permissionTypes.map((permissionType) => {
        const isRestricted = RESTRICTION_TYPES.includes(permissionType.state);
        return {
          ...permissionType,
          title: permissionType.title,
          value: permissionType.title,
          permission: {
            read: false,
            write: false,
          },
          isDisabled: isRestricted,
          state: permissionType.state,
        };
      });
    }

    setPermissionTypes(permissionArray);
    setFieldValue('permissions', permissionArray);
  };

  const selectPermissionOption = (checked, id, type) => {
    let permissionArray = cloneDeep(permissionTypes);
    if (!checked && type === 'read') {
      permissionArray[id].permission.write = false;
      permissionArray[id].permission.read = false;
    } else if (checked && type === 'read') {
      permissionArray[id].permission.read = true;
    } else if (!checked && type === 'write') {
      permissionArray[id].permission.write = false;
    } else if (checked && type === 'write') {
      permissionArray[id].permission.write = true;
      permissionArray[id].permission.read = true;
    }

    setValue('custom');
    setPermissionTypes(permissionArray);
    setFieldValue('permissions', permissionArray);
  };

  return (
    <div className="formik-custom-field act-inputs-new">
      <label
        className={requiredStar ? 'required-stars' : ''}
        htmlFor={props.id || props.name}
      >
        {labelText}
      </label>
      {/* {disabled ? (
          <>
            <br />
            <label>{defaultValueText}</label>
          </>
        ) : ( */}
      <>
        <Dropdown
          selection
          scrolling
          value={value}
          options={options}
          onChange={(e, data) => {
            setValue(data.value);
            getValue && getValue(data.value);
            selectPermissionType(data.value);
          }}
          error={meta.touched && meta.error}
          className={className ? className : ''}
          disabled={disabled}
          {...props}
        />
      </>
      {value ? (
        <Grid>
          <Grid.Row>
            <div className="formik-permission-table-custom-border">
              <Table className="formik-permission-table-custom">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Activity Section </Table.HeaderCell>
                    <Table.HeaderCell>Read</Table.HeaderCell>
                    <Table.HeaderCell>Write</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {permissionTypes.map((permissionType, index) => {
                    const { read, write } = get(permissionType, 'permission', {
                      read: false,
                      write: false,
                    });

                    return (
                      <Table.Row key={index}>
                        <Table.Cell>{permissionType.title}</Table.Cell>
                        <Table.Cell className="formik-permission-activity-section-cell">
                          <div className=" act-inputs-new">
                            <Form.Field
                              label=""
                              control="input"
                              className="custom-radio"
                              type="checkbox"
                              checked={read}
                              onChange={(e) => {
                                selectPermissionOption(
                                  e.target.checked,
                                  index,
                                  'read'
                                );
                              }}
                              disabled={disabled}
                            />
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          {!permissionType.isDisabled ? (
                            <Form.Field
                              label=""
                              control="input"
                              className="custom-radio"
                              type="checkbox"
                              checked={write}
                              onChange={(e) => {
                                selectPermissionOption(
                                  e.target.checked,
                                  index,
                                  'write'
                                );
                              }}
                              disabled={disabled || permissionType.isDisabled}
                            />
                          ) : (
                            <></>
                          )}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </div>
          </Grid.Row>
        </Grid>
      ) : (
        <></>
      )}
      {meta.touched && meta.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}{' '}
    </div>
  );
};
