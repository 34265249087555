export const activeParticipantsList = [
  {
    id: 5,
    key: 5,
    text: 'Free',
    checked: false,
  },
  {
    id: 6,
    key: 6,
    text: 'Paying',
    checked: false,
  },
  {
    id: 7,
    key: 7,
    text: 'Paid',
    checked: false,
  },
];

export const activityStateList = [
  {
    id: 1,
    key: 1,
    text: 'In-Development',
    checked: false,
  },
  {
    id: 2,
    key: 2,
    text: 'Active',
    checked: false,
  },
  {
    id: 3,
    key: 3,
    text: 'Completed',
    checked: false,
  },
];

export const organizationLevelList = [
  {
    id: 1,
    key: 'all',
    value: 'all',
    text: 'All',
    checked: true,
  },
  {
    id: 2,
    key: 'internal',
    value: 'internal',
    text: 'Internal',
    checked: false,
  },
  {
    id: 3,
    key: 'external',
    value: 'external',
    text: 'External',
    checked: false,
  },
];

export const activityTypeList = [
  {
    id: 1,
    key: 1,
    text: 'Single Activity',
    checked: false,
  },
  {
    id: 3,
    key: 3,
    text: 'Festival Program',
    checked: false,
  },
  {
    id: 4,
    key: 4,
    text: 'Grant Program',
    checked: false,
  },

  {
    id: 6,
    key: 6,
    text: 'Multi-Activity Program',
    checked: false,
  },
];

export const receptiveParticipantsList = [
  {
    id: 3,
    key: 3,
    text: 'Free',
    checked: false,
  },
  {
    id: 4,
    key: 4,
    text: 'Paying',
    checked: false,
  },
];
