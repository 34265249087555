import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { find, get, isEmpty, isString } from '../utils/lodash';
import {
  grantTrialProgramProjectIds,
  grantTrialToolTips,
} from '../utils/pageText';

import './styles.scss';

export function CustomTooltip({
  state,
  title,
  content,
  contentObject,
  position,
  wide,
  children,
}) {
  let tooltip = null;

  /* TODO: remove below 3 lines [linked activities checking]
   * New grant application trial
   */
  const activity = useSelector((state) => state.activity);
  const projectId = get(activity, 'currentActivity.parentId', null);
  const isHelpTipTrialProject = grantTrialProgramProjectIds.includes(projectId);

  if (content && isString(content) && !isHelpTipTrialProject) {
    tooltip = content;
  } else if (!isEmpty(contentObject)) {
    let obj = null;

    /* TODO: remove this trial ended
     * New grant application trial
     */
    if (projectId && isHelpTipTrialProject) {
      obj = find(grantTrialToolTips, { tooltipId: Number(contentObject.key) });
    }

    /* TODO: remove this if condition
     * if specific project not contain tooltip get default tooltip
     */
    if (!obj) {
      if (contentObject.key) {
        obj = find(contentObject.data, { id: Number(contentObject.key) });
      } else if (contentObject.title) {
        obj = find(contentObject.data, { title: contentObject.title });
      }
    }
    tooltip = obj && obj.content ? obj.content : null;
  }

  return state && tooltip ? (
    <Popup
      trigger={children}
      title={title}
      content={
        <div>
          <div>{tooltip}</div>
        </div>
      }
      position={position ? position : 'bottom center'}
      wide={wide ? wide : true}
    />
  ) : (
    <Fragment>{children}</Fragment>
  );
}
