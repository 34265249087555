import React from 'react';
import { Grid, Segment, Container } from 'semantic-ui-react';
import { isEmpty, map } from '../../utils/lodash';
import { EmptyContainer } from '../../components';

// activities data
const ACTIVITIES = [
  {
    title: 'In Development',
    key: 'in-development',
    color: '#FF781D',
  },
  {
    title: 'Active',
    key: 'active',
    color: '#339933',
  },
  {
    title: 'Completed',
    key: 'completed',
    color: '#003398',
  },
];

// invited activities data
const INVITED_ACTIVITIES = [
  {
    title: 'Active',
    key: 'active',
    color: '#339933',
  },
];

export const ActivityStateSegment = ({ statUser = {}, loading, user = {} }) => (
  <Segment className="content-segment-dashboard" loading={loading}>
    {isEmpty(statUser) ? (
      <EmptyContainer />
    ) : (
      <Grid>
        <Grid.Row>
          <div className="dashboard-act-container">
            <label className="dashboard-act-heading">Managed Activities</label>
            {
              //set activities data here
              map(ACTIVITIES, (item, i) => (
                <Grid.Row key={i} className="no-padding">
                  <Container className="no-padding">
                    <div className="activity-cont">
                      <label
                        className="activity-count-owned"
                        style={{
                          color: item.color,
                        }}
                      >
                        {statUser.owned[item.key]
                          ? String(statUser.owned[item.key]).padStart(2, '0')
                          : 0}
                      </label>
                      <label className="activity-title">{item.title}</label>
                    </div>
                  </Container>
                </Grid.Row>
              ))
            }
          </div>
        </Grid.Row>
        <Grid.Row>
          <div className="dashboard-act-container-invited">
            <label className="dashboard-act-heading">Invited Activities</label>
            {
              //set activities data here
              map(INVITED_ACTIVITIES, (item, i) => (
                <Grid.Row key={i} className="no-padding">
                  <Container className="no-padding">
                    <div className="activity-cont">
                      <label
                        className="activity-count-invited"
                        style={{
                          color: item.color,
                        }}
                      >
                        {statUser.invited[item.key]
                          ? String(statUser.invited[item.key]).padStart(2, '0')
                          : 0}
                      </label>
                      <label className="activity-title">{item.title}</label>
                    </div>
                  </Container>
                </Grid.Row>
              ))
            }
            <label className="dashboard-act-info">
              Only activities that are in progress are shown
            </label>
          </div>
        </Grid.Row>
      </Grid>
    )}
  </Segment>
);

export default ActivityStateSegment;
