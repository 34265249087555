/* eslint-disable eqeqeq */
import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import getCountryCodeByName from '../utils/mapUtils';
import { MAP_DEFAULT_COUNTRY_CODE } from '../utils/constant';

export class LocationSearchInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      placeName: null,
      countryCode: this.props.country
        ? getCountryCodeByName(this.props.country)
        : MAP_DEFAULT_COUNTRY_CODE,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ placeName: this.props.value });
    }

    if (prevProps.country !== this.props.country) {
      const _countryCode = getCountryCodeByName(this.props.country);

      this.setState({
        countryCode: _countryCode ? _countryCode : MAP_DEFAULT_COUNTRY_CODE,
      });
    }
  }

  sendLocation(data) {
    const { getLocation } = this.props;
    if (data.address_components && data.address_components.length) {
      const country = data.address_components
        .filter((it) => {
          return it.types.indexOf('country') !== -1;
        })
        .map((it) => {
          return it.long_name;
        });

      const streetNumber = data.address_components
        .filter((it) => {
          return it.types.indexOf('street_number') !== -1;
        })
        .map((it) => {
          return it.long_name;
        });
      const streetAddress = data.address_components
        .filter((it) => {
          return it.types.indexOf('route') !== -1;
        })
        .map((it) => {
          return it.long_name;
        });
      const suburb = data.address_components
        .filter((it) => {
          return it.types.indexOf('locality') !== -1;
        })
        .map((it) => {
          return it.long_name;
        });
      const suburbExtra = data.address_components
        .filter((it) => {
          return it.types.indexOf('political') !== -1;
        })
        .map((it) => {
          return it.long_name;
        });
      const postalCodes = data.address_components
        .filter((it) => {
          return it.types.indexOf('postal_code') !== -1;
        })
        .map((it) => {
          return it.long_name;
        });
      const state = data.address_components
        .filter((it) => {
          return it.types.indexOf('administrative_area_level_1') !== -1;
        })
        .map((it) => {
          return it.long_name;
        });

      if (country[0]) {
        data.country = country[0];
      }
      if (streetNumber[0]) {
        data.streetNumber = streetNumber[0];
      }
      if (streetAddress[0]) {
        data.streetAddress = streetAddress[0];
      }
      if (suburb[0] || suburbExtra[0]) {
        data.suburb = suburb[0] || suburbExtra[0];
      }
      if (postalCodes[0]) {
        data.postcode = postalCodes[0];
      }
      if (state[0]) {
        data.state = state[0];
      }

      // make input field name empty after selecting location
      this.setState({
        placeName: '',
      });
    }

    getLocation(data);
  }

  handleKeyPress = (event) => {
    if (event.key == 'Enter') {
      event.preventDefault();
    }
  };

  onChange = (event) => {
    this.setState({
      placeName: event.target.value,
    });
  };

  render() {
    const { placeHolder, customClass, label } = this.props;
    const { placeName, countryCode } = this.state;

    if (!window.google) return null;

    return (
      <div className="form-group">
        <label> {label ? label : 'Address'} </label>
        <div className="input-group-append">
          <div className="ui icon input location-search-max-width">
            <Autocomplete
              placeholder={placeHolder ? placeHolder : 'Enter a location'}
              onKeyPress={this.handleKeyPress}
              onPlaceSelected={(data) => {
                this.sendLocation(data);
              }}
              className={`org-inputs is-touched is-pristine av-invalid form-control 
                ${customClass ? customClass : 'org-inputs-location-search'}`}
              types={[]}
              value={placeName}
              fields={['address_components', 'name', 'business_status']}
              onChange={this.onChange}
              options={{
                types: ['geocode', 'establishment'],
                componentRestrictions: { country: countryCode },
              }}
            />
            <i aria-hidden="true" className="search icon"></i>
          </div>
        </div>
      </div>
    );
  }
}
