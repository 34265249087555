import React from 'react';

import { NotificationContainer } from 'react-notifications';
import {
  Navigate,
  Route,
  BrowserRouter,
  Routes,
  useLocation,
  useParams,
  useNavigate,
} from 'react-router-dom';

// custom components
import { NotFound, PermissionDenied } from './components/errorStatus';
import { SignInContent } from './components/rightContent';
import { ROUTES } from './utils/constant';
// account components
import Account from './containers/account';
// activities components
import Activities from './containers/activities';
import ActivityParticipants from './containers/activities/participants';
import ActivityPreview from './containers/activities/library/preview';
import ActivitySteps from './containers/activities/steps';
//admin components
import ActivitySettingAdmin from './containers/admin/activitySettings/index';
import SystemFeatures from './containers/admin/systemFeatures/index';
import SystemChange from './containers/admin/systemLogs/index';
import SystemReports from './containers/admin/systemReports/index';
import SystemSetting from './containers/admin/systemSettings/index';
import UserOrganization from './containers/admin/userOrganization/index';
import AccountDisable from './containers/auth/accountDisable';
import ForgotPassword from './containers/auth/forgotPass';
import UserRegistration from './containers/auth/userRegistration';
import Help from './containers/auth/help';
import InviteLinkExpired from './containers/auth/inviteLinkExpired';
import PasswordLinkExpired from './containers/auth/passwordLinkExpired';
import AuthLayout from './containers/auth/layout';
import MailVerification from './containers/auth/mailVerification';
import OrganizationWelcome from './containers/auth/organizationWelcome';
import PasswordChangeSuccess from './containers/auth/passwordChangedSuccess';
import Profile from './containers/auth/profile';
import ResetPassword from './containers/auth/resetPass';
// user components
import SignIn from './containers/auth/signIn';
import SignUp from './containers/auth/signUp';
import verificationLink from './containers/auth/verification';
import WelcomeToWhitebox from './containers/auth/welcomeToWhitebox';
// browse components
import BrowseActivities from './containers/browser/browseActivities';
// root components
import Dashboard from './containers/dashboard';
import Layout from './containers/layout';
// organization components
import Organization from './containers/organization';
// Question Generator components
import questionGenerator from './containers/questionGenerator';
// report components
import Reports from './containers/reports';
// survey components
import Surveys from './containers/survey';
import history from './utils/history';
import { persistor } from './store';
import { userAuth } from './utils';
import { get } from './utils/lodash';
import { useSelector } from 'react-redux';
import withTracker from './lib/withPageTracker';
import verification from './containers/auth/verification';
import NavigationWrapper from './components/navigationWrapper';

// https://tylermcginnis.com/react-router-protected-routes-authentication/

if (!userAuth.isAuthenticated) {
  persistor.purge();
}

const NoMatch = (props) => {
  const navigate = useNavigate();
  return <NotFound navigate={navigate} {...props} />;
};

const PrivateRoute = ({ component: Component, permission, ...rest }) => {
  const user = useSelector((state) => state.authentication.user);
  const permissions = get(user, 'features', []);
  const featureAllowed =
    rest.features && rest.features.some((i) => permissions.includes(i));
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  return userAuth.isAuthenticated ? (
    <>
      <Layout
        {...rest}
        routes={ROUTES}
        title={rest.title}
        sub={rest.sub}
        match={{ pathname: location.pathname, path: rest.path, params: params }}
        route={{ permission: permissions }}
        navigate={navigate}
      >
        {rest.features ? (
          featureAllowed ? (
            <Component
              route={{ permission: permissions }}
              match={{
                pathname: location.pathname,
                path: rest.path,
                params: params,
              }}
            />
          ) : (
            <PermissionDenied />
          )
        ) : (
          <Component
            route={{ permission: permissions }}
            match={{
              pathname: location.pathname,
              path: rest.path,
              params: params,
            }}
          />
        )}
      </Layout>
      <NotificationContainer />
    </>
  ) : (
    <Navigate
      to={{
        pathname: '/',
        state: {
          from: location,
          redirectRoute: location,
          logoutReason: '',
        },
      }}
      navigate={navigate}
    />
  );
};

const SuperAdminRoute = ({ component: Component, permission, ...rest }) => {
  let location = useLocation();
  const params = useParams();
  const user = useSelector((state) => state.authentication.user);
  const permissions = get(user, 'features', []);
  const navigate = useNavigate();

  return userAuth.isAuthenticated ? (
    <>
      <Layout
        routes={ROUTES}
        title={rest.title}
        sub={rest.sub}
        match={{ pathname: location.pathname, path: rest.path, params: params }}
        route={{ permission: permissions }}
        navigate={navigate}
      >
        <Component
          match={{
            pathname: location.pathname,
            path: rest.path,
            params: params,
          }}
        />
      </Layout>
      <NotificationContainer />
    </>
  ) : (
    <Navigate
      to={{
        pathname: '/',
        state: { from: location },
      }}
    />
  );
};

const AuthRoute = ({ component: Component, ...rest }) => {
  let location = useLocation();
  const params = useParams();
  const user = useSelector((state) => state.authentication.user);
  const permissions = get(user, 'features', []);
  const navigate = useNavigate();

  const WrappedComponent = userAuth.isAuthenticated ? (
    <Navigate
      to={{
        pathname: '/dashboard',
        state: { from: location },
      }}
    />
  ) : (
    <>
      <AuthLayout
        match={{
          pathname: location.pathname,
          path: rest.path,
          params: params,
        }}
        location={location}
        route={{ permission: permissions }}
        navigate={navigate}
        {...rest}
      >
        <Component
          match={{
            pathname: location.pathname,
            path: rest.path,
            params: params,
          }}
          location={location}
          route={{ permission: permissions }}
        />
      </AuthLayout>
      <NotificationContainer />
    </>
  );

  // return WrappedComponent;
  return WrappedComponent;
};

const PublicRoute = ({ component: Component, ...rest }) => {
  let location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  return (
    <>
      <AuthLayout
        location={location}
        match={{
          pathname: location.pathname,
          path: rest.path,
          params: params,
        }}
        navigate={navigate}
        {...rest}
      >
        <Component
          match={{
            pathname: location.pathname,
            path: rest.path,
            params: params,
          }}
          location={location}
          navigate={navigate}
        />
      </AuthLayout>
      <NotificationContainer />
    </>
  );
};

const CustomRoute = ({ component: Component, ...rest }) => {
  let location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  return (
    <>
      <Component
        match={{ pathname: location.pathname, path: rest.path, params: params }}
        location={location}
        navigate={navigate}
        {...rest}
      />
      <NotificationContainer />
    </>
  );
};

const AppRouting = () => {
  return (
    // <BrowserRouter>
    <Routes>
      <Route
        exact
        path="/"
        element={
          <AuthRoute
            exact
            path="/"
            component={withTracker(SignIn)}
            rightComponent={<SignInContent />}
          />
        }
      />
      <Route
        path="/signin"
        element={
          <AuthRoute
            exact
            path="/signin"
            component={withTracker(SignIn)}
            rightComponent={<SignInContent />}
          />
        }
      />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute
            exact
            path="/dashboard"
            component={withTracker(Dashboard)}
            title="Dashboard"
            features={['dashboard']}
          />
        }
      />

      <Route
        path="/forgot-password"
        element={
          <PublicRoute
            exact
            path="/forgot-password"
            component={withTracker(ForgotPassword)}
            rightComponent={<SignInContent />}
          />
        }
      />
      <Route
        path="/signup"
        element={
          <PublicRoute
            exact
            path="/signup"
            component={withTracker(SignUp)}
            rightComponent={<SignInContent />}
          />
        }
      />
      <Route
        path="/verification"
        element={
          <PublicRoute
            exact
            path="/verification"
            component={withTracker(verification)}
            fullPage
          />
        }
      />
      <Route
        path="/organization-welcome"
        element={
          <PublicRoute
            exact
            path="/organization-welcome"
            component={withTracker(OrganizationWelcome)}
            fullPage
          />
        }
      />
      <Route
        path="/welcome-takso"
        element={
          <PublicRoute
            exact
            path="/welcome-takso"
            component={withTracker(WelcomeToWhitebox)}
            fullPage
          />
        }
      />
      <Route
        path="/password-changed"
        element={
          <PublicRoute
            exact
            path="/password-changed"
            component={withTracker(PasswordChangeSuccess)}
            fullPage
          />
        }
      />
      <Route
        path="/link-expired"
        element={
          <PublicRoute
            exact
            path="/link-expired"
            component={withTracker(InviteLinkExpired)}
            fullPage
          />
        }
      />
      <Route
        path="/password-link-expired"
        element={
          <PublicRoute
            exact
            path="/password-link-expired"
            component={withTracker(PasswordLinkExpired)}
            fullPage
          />
        }
      />
      <Route
        path="/reset-password/:id"
        element={
          <PublicRoute
            exact
            path="/reset-password/:id"
            component={withTracker(ResetPassword)}
            rightComponent={<SignInContent />}
          />
        }
      />
      <Route
        path="/invite*"
        element={
          <PublicRoute
            exact
            path="/invite*"
            component={withTracker(UserRegistration)}
            rightComponent={<SignInContent />}
          />
        }
      />
      <Route
        path="/account-disabled"
        element={
          <PublicRoute
            exact
            path="/account-disabled"
            component={withTracker(AccountDisable)}
            fullPage
          />
        }
      />
      <Route
        path="/account/verification/:token"
        element={
          <PublicRoute
            exact
            path="/account/verification/:token"
            component={withTracker(MailVerification)}
            fullPage
          />
        }
      />
      <Route
        path="/survey/:surveyId"
        element={
          <CustomRoute
            exact
            path="/survey/:surveyId"
            component={withTracker(Surveys)}
          />
        }
      />
      <Route
        path="/generate-question"
        element={
          <CustomRoute
            exact
            path="/generate-question"
            component={withTracker(questionGenerator)}
          />
        }
      />
      <Route
        path="/preview-survey/:surveyId"
        element={
          <PrivateRoute
            exact
            path="/preview-survey/:surveyId"
            component={withTracker(Surveys)}
            title="Preview Survey"
            features={['activities']}
          />
        }
      />

      <Route
        path="/dashboard"
        element={
          <PrivateRoute
            exact
            path="/dashboard"
            component={withTracker(Dashboard)}
            title="Dashboard"
            features={['dashboard']}
          />
        }
      />

      <Route
        path="/activities"
        element={
          <PrivateRoute
            exact
            path="/activities"
            component={withTracker(Activities)}
            title="Activities"
            features={['activities']}
          />
        }
      />

      <Route
        path="/activity/:id/preview"
        element={
          <PrivateRoute
            exact
            path="/activity/:id/preview"
            component={withTracker(ActivityPreview)}
            title="Activity Preview"
            sub
            features={['activities']}
          />
        }
      />

      <Route
        path="/activity/:id/participants"
        element={
          <PrivateRoute
            exact
            path="/activity/:id/participants"
            component={withTracker(ActivityParticipants)}
            title="Activity Participants"
            sub
            features={['activity_participants']}
          />
        }
      />

      <Route
        path="/activity/:id/participants"
        element={
          <PrivateRoute
            exact
            path="/activity/:id/participants"
            component={withTracker(ActivitySteps)}
            title="Activity"
            sub
            features={['activities']}
          />
        }
      />

      <Route
        path="/activities/:id"
        element={
          <PrivateRoute
            exact
            path="/activities/:id"
            component={withTracker(ActivitySteps)}
            title="Activity"
            sub
            features={['activities']}
          />
        }
      />

      <Route
        path="/activities/:id/:type"
        element={
          <PrivateRoute
            exact
            path="/activities/:id/:type"
            component={withTracker(ActivitySteps)}
            title="Activity"
            sub
            features={['activities']}
          />
        }
      />

      <Route
        path="/organisation"
        element={
          <PrivateRoute
            exact
            path="/organisation"
            component={withTracker(Organization)}
            title="Organisation"
            features={['account']}
          />
        }
      />

      <Route
        path="/organisation/:type"
        element={
          <PrivateRoute
            exact
            path="/organisation/:type"
            component={withTracker(Organization)}
            title="Organisation"
            features={['account']}
          />
        }
      />

      <Route
        path="/account"
        element={
          <PrivateRoute
            exact
            path="/account"
            component={withTracker(Account)}
            title="Account"
            features={['account']}
          />
        }
      />

      <Route
        path="/account/:type"
        element={
          <PrivateRoute
            exact
            path="/account/:type"
            component={withTracker(Account)}
            title="Account"
            features={['account']}
          />
        }
      />

      <Route
        path="/reports"
        element={
          <PrivateRoute
            exact
            path="/reports"
            component={withTracker(Reports)}
            title="Reports"
            features={['report']}
          />
        }
      />

      <Route
        path="/reports/:type"
        element={
          <PrivateRoute
            exact
            path="/reports/:type"
            component={withTracker(Reports)}
            title="Reports"
            features={['report']}
          />
        }
      />

      <Route
        path="/user-profile"
        element={
          <PrivateRoute
            exact
            path="/user-profile"
            component={withTracker(Profile)}
            title="User Profile"
          />
        }
      />

      <Route
        path="/admin-activity-settings"
        element={
          <SuperAdminRoute
            exact
            path="/admin-activity-settings"
            component={withTracker(ActivitySettingAdmin)}
            title="Activity Settings"
          />
        }
      />

      <Route
        path="/admin-activity-settings/:type"
        element={
          <SuperAdminRoute
            exact
            path="/admin-activity-settings/:type"
            component={withTracker(ActivitySettingAdmin)}
            title="Activity Settings"
          />
        }
      />

      <Route
        path="/system-settings"
        element={
          <SuperAdminRoute
            exact
            path="/system-settings"
            component={withTracker(SystemSetting)}
            title="System Settings"
          />
        }
      />

      <Route
        path="/system-settings/:type"
        element={
          <SuperAdminRoute
            exact
            path="/system-settings/:type"
            component={withTracker(SystemSetting)}
            title="System Settings"
          />
        }
      />

      <Route
        path="/system-features"
        element={
          <SuperAdminRoute
            exact
            path="/system-features"
            component={withTracker(SystemFeatures)}
            title="System Features"
          />
        }
      />

      <Route
        path="/system-features/:type"
        element={
          <SuperAdminRoute
            exact
            path="/system-features/:type"
            component={withTracker(SystemFeatures)}
            title="System Features"
          />
        }
      />

      <Route
        path="/system-logs"
        element={
          <SuperAdminRoute
            exact
            path="/system-logs"
            component={withTracker(SystemChange)}
            title="System Logs"
          />
        }
      />

      <Route
        path="/user-accounts"
        element={
          <SuperAdminRoute
            exact
            path="/user-accounts"
            component={withTracker(UserOrganization)}
            title="Accounts & Users"
          />
        }
      />

      <Route
        path="/user-accounts/:type"
        element={
          <SuperAdminRoute
            exact
            path="/user-accounts/:type"
            component={withTracker(UserOrganization)}
            title="Accounts & Users"
          />
        }
      />

      <Route
        path="/system-reports"
        element={
          <SuperAdminRoute
            exact
            path="/system-reports"
            component={withTracker(SystemReports)}
            title="System Reports"
          />
        }
      />

      <Route
        path="/system-reports/:type"
        element={
          <SuperAdminRoute
            exact
            path="/system-reports/:type"
            component={withTracker(SystemReports)}
            title="System Reports"
          />
        }
      />

      <Route
        path="/help"
        element={
          <PrivateRoute
            exact
            path="/help"
            component={withTracker(Help)}
            title="TAKSO OUTCOMES PLANNING PLATFORM – HELP"
          />
        }
      />

      <Route
        path="/library"
        element={
          <PrivateRoute
            exact
            path="/library"
            component={withTracker(BrowseActivities)}
            title="Library"
            features={['library']}
          />
        }
      />

      <Route
        path="/library/:id/preview"
        element={
          <PrivateRoute
            exact
            path="/library/:id/preview"
            component={withTracker(ActivityPreview)}
            title="Activity Preview"
            sub
            features={['library']}
          />
        }
      />

      <Route path="*" element={<NoMatch />} />
    </Routes>
    // </BrowserRouter>
  );
};
export default AppRouting;
