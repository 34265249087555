import './auth.scss';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Grid } from 'semantic-ui-react';
import { SuccessContainer } from '../../components';
import { APP_NAME } from '../../utils/constant';

class InviteLinkExpired extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = `Invitation Verification | ${APP_NAME}`;
  }

  render() {
    return (
      <Grid.Row>
        {/* <Grid.Column mobile={16} tablet={5} computer={5} />
        <Grid.Column mobile={16} tablet={6} computer={6}> */}
        <Container className="email-verification-container">
          <SuccessContainer
            msg={'This invite link is no longer active.'}
            description={[
              <span>
                Looks like this invitation link is broken or has already been
                used.
              </span>,
              <br />,
              <span>
                Please contact the activity manager, their details are in the
                invitation email.
              </span>,
            ]}
            linkExpired={true}
          />
        </Container>
        {/* </Grid.Column>
        <Grid.Column mobile={16} tablet={5} computer={5} /> */}
      </Grid.Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteLinkExpired);
