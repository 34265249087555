import { Segment, Grid } from 'semantic-ui-react';
import React, { Component } from 'react';
import Label from 'reactstrap/lib/Label';
import moment from 'moment';
import {
  datePickerEndTitle,
  datePickerStartTitle,
  getPageDisable,
} from '../../../../utils';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../activity.scss';
import thousands from 'thousands';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { isSingleLinkedActivity } from '../../../../utils/activity';
import { get } from '../../../../utils/lodash';
moment.locale('en-AU');

export class ActivityInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expenditure: undefined,
      grantAmount: undefined,
      startDate: null,
      endDate: null,
      greenLightNotifyDate: '',
      venueLoading: false,
      venueResults: [],
      venueValue: '',
      contractorLoading: false,
      contractorResults: [],
      contractorValue: '',
      expenditureError: '',
      grantAmountError: '',
      startDateError: '',
      endDateError: '',
      greenLightDateError: '',
      contractorCost: '',
      guestUserContractorsList: '',
      guestUserValue: '',
      guestUserVenuesList: '',
      guestUserVenueValue: '',
      contractorGuestLoading: false,
      venueGuestLoading: false,
      initialStartStateSettings: {
        autoApply: true,
        singleDatePicker: true,
        showDropdowns: true,
        startDate: props.activity.currentActivity.eventStartDate
          ? moment(props.activity.currentActivity.eventStartDate)
          : new Date(),
        maxDate: props.activity.currentActivity.eventEndDate
          ? moment(props.activity.currentActivity.eventEndDate)
          : null,
        minYear: 2017,
        maxYear: parseInt(moment().format('YYYY'), 10) + 10,
      },
      initialEndDateStateSettings: {
        autoApply: true,
        singleDatePicker: true,
        showDropdowns: true,
        startDate: props.activity.currentActivity.eventEndDate
          ? moment(props.activity.currentActivity.eventEndDate)
          : new Date(),
        minDate: props.activity.currentActivity.eventStartDate
          ? moment(props.activity.currentActivity.eventStartDate)
          : null,
        minYear: 2017,
        maxYear: parseInt(moment().format('YYYY'), 10) + 10,
      },
      partnerLoading: false,
      partnerValue: '',
      partnerResults: [],
      addPartnerModal: false,
      selectedPartner: {},
    };

    this.keyStartDateRef = React.createRef(Date.now());
    this.keyEndDateRef = React.createRef(Date.now());
  }

  async componentDidMount() {
    const { actual } = this.props;
    const { currentActivity } = this.props.activity;

    if (currentActivity.projectedCost || currentActivity.actualCost) {
      await this.setState({
        expenditure: !actual
          ? thousands(currentActivity.projectedCost)
          : thousands(currentActivity.actualCost),
        grantAmount: !actual
          ? thousands(currentActivity.projectedGrantAmount)
          : thousands(currentActivity.actualGrantAmount),
        greenLightNotifyDate: currentActivity.greenLightNotifyDate
          ? moment(currentActivity.greenLightNotifyDate).format('DD MMM YYYY')
          : currentActivity.greenLightNotifyDate,
        startDate: currentActivity.eventStartDate
          ? moment(currentActivity.eventStartDate).format('DD MMM YYYY')
          : null,
        endDate: currentActivity.eventEndDate
          ? moment(currentActivity.eventEndDate).format('DD MMM YYYY')
          : null,
        actualVolunteers: currentActivity.actualVolunteers || '0',
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { currentActivity } = this.props.activity;
    const { actual } = this.props;
    if (
      prevProps.activity.currentActivity !== currentActivity ||
      prevProps.actual !== actual
    ) {
      await this.setState({
        expenditure: !actual
          ? thousands(currentActivity.projectedCost)
          : thousands(currentActivity.actualCost),
        grantAmount: !actual
          ? thousands(currentActivity.projectedGrantAmount)
          : thousands(currentActivity.actualGrantAmount),
        greenLightNotifyDate: currentActivity.greenLightNotifyDate
          ? moment(currentActivity.greenLightNotifyDate).format('DD MMM YYYY')
          : currentActivity.greenLightNotifyDate,
        startDate: currentActivity.eventStartDate
          ? moment(currentActivity.eventStartDate).format('DD MMM YYYY')
          : null,
        endDate: currentActivity.eventEndDate
          ? moment(currentActivity.eventEndDate).format('DD MMM YYYY')
          : null,
        actualVolunteers: currentActivity.actualVolunteers || '0',
        expenditureError: '',
        grantAmountError: '',
        startDateError: '',
        endDateError: '',
        greenLightDateError: '',
      });
    }
  }

  render() {
    const {
      startDateError,
      endDateError,
      greenLightDateError,
      actualVolunteers,
      greenLightNotifyDate,
      grantAmount,
      startDate,
      endDate,
    } = this.state;

    const { actual, activity, auth, currentState } = this.props;
    let exist = {};
    if (
      activity.currentActivity.eventStartDate &&
      activity.currentActivity.eventEndDate
    ) {
      exist.value = `${moment(startDate).format('DD MMM YYYY')} - ${moment(
        endDate
      ).format('DD MMM YYYY')}`;
    }

    let existGreenLightNotifyDate = {};
    if (greenLightNotifyDate) {
      existGreenLightNotifyDate.value = `${moment(greenLightNotifyDate).format(
        'DD MMM YYYY'
      )}`;
    } else if (activity.currentActivity.greenLightDateError) {
      existGreenLightNotifyDate.value = `${moment(greenLightDateError).format(
        'DD MMM YYYY'
      )}`;
    }

    let existStartDate = {};
    if (startDate) {
      existStartDate.value = `${moment(startDate).format('DD MMM YYYY')}`;
    } else if (activity.currentActivity.startDateError) {
      existStartDate.value = `${moment(startDateError).format('DD MMM YYYY')}`;
    }

    let existEndDate = {};
    if (endDate) {
      existEndDate.value = `${moment(endDate).format('DD MMM YYYY')}`;
    } else if (activity.endDateError) {
      existEndDate.value = `${moment(endDateError).format('DD MMM YYYY')}`;
    }

    const currencyType = get(activity, 'currentActivity.currencyType');
    const currencySymbol = get(currencyType, 'sign', '$');

    return (
      <div className="document-input">
        <Segment className="inputs-segment">
          <Grid columns={2} stackable>
            <Grid.Row className="inputs-row">
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={4}
                className="date-padding extra-padding date-input-row"
              >
                <div className="disabled-padding">
                  <Segment
                    disabled={getPageDisable(
                      activity.currentActivity,
                      auth.user,
                      currentState,
                      actual
                    )}
                  >
                    <div className="expenditure-div">
                      <Label className="plan-input-label">Expenditure</Label>

                      <Label>
                        {activity.currentActivity.actualCost ? (
                          <>
                            {currencySymbol}
                            {thousands(activity.currentActivity.actualCost)}
                          </>
                        ) : (
                          ' - '
                        )}
                      </Label>
                    </div>
                  </Segment>
                </div>
              </Grid.Column>

              <Grid.Column
                mobile={16}
                tablet={8}
                computer={4}
                className="date-padding date-input-row extra-padding"
              >
                <div className="disabled-padding">
                  <Segment>
                    <div>
                      <Label className="plan-input-label">
                        {datePickerStartTitle(activity.currentActivity)}
                      </Label>
                    </div>
                    <Label>{startDate ? startDate : ' - '}</Label>
                  </Segment>
                </div>
              </Grid.Column>

              <Grid.Column
                mobile={16}
                tablet={8}
                computer={4}
                className="date-padding date-input-row extra-padding"
              >
                <div className="disabled-padding">
                  <Segment>
                    <Label className="plan-input-label">
                      {datePickerEndTitle(activity.currentActivity)}
                    </Label>
                    <div>
                      <Label>{endDate ? endDate : ' - '}</Label>
                    </div>
                  </Segment>
                </div>
              </Grid.Column>

              {actual ? (
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={4}
                  className="extra-padding"
                >
                  <div className="disabled-padding">
                    <Segment>
                      <div className="expenditure-div">
                        <Label className="plan-input-label">
                          Total Volunteers
                        </Label>
                        <div>
                          <Label>
                            {actualVolunteers ? actualVolunteers : ' - '}
                          </Label>
                        </div>
                      </div>
                    </Segment>
                  </div>
                </Grid.Column>
              ) : null}
            </Grid.Row>
            {isSingleLinkedActivity(
              activity.currentActivity.activityPlanTypeId,
              activity.currentActivity.isLinkedActivity
            ) && (
              <Grid.Row className="inputs-row">
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={4}
                  className="extra-padding"
                >
                  <div className="disabled-padding">
                    <Segment>
                      <div className="expenditure-div">
                        <Label>
                          {actual ? 'Actual' : 'Projected'}{' '}
                          {activity.currentActivity.activityPlanTypeId === 4
                            ? 'Grant Amount'
                            : '1'}
                        </Label>
                        <div>
                          {grantAmount ? <Label>${grantAmount}</Label> : ' - '}
                        </div>
                      </div>
                    </Segment>
                  </div>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    organization: state.organization,
    extra: state.extra,
    user: state.authentication.user,
    activity: state.activity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

ActivityInput = connect(mapStateToProps, mapDispatchToProps)(ActivityInput);
