import { countries } from 'country-list-json';
import { get } from './lodash';

const getCountryCodeByName = (countryName) => {
  if (countryName) {
    const foundedCountry = countries.find(
      (country) => country.name === countryName
    );
    return get(foundedCountry, 'code', null);
  }

  return null;
};

export default getCountryCodeByName;
