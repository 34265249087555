import { isEmpty } from 'lodash';
import * as Yup from 'yup';

export const departmentUnitValidationSchema = (
  isDepartment = true,
  specificGoalSelected,
  idEdit = false
) => {
  const keyName = isDepartment ? 'Department' : 'Unit';
  let validations = {
    name: Yup.string().required(`${keyName} Name is required!`),
    description: Yup.string().required('Description  is required!'),
    goals: specificGoalSelected
      ? Yup.array().required('Goal is required!')
      : Yup.array().optional(),
  };

  if (!isDepartment && idEdit) {
    validations = {
      ...validations,
      departmentId: Yup.string().required('Department  is required!'),
    };
  }

  return Yup.object().shape(validations);
};

// export const addUnitValidationValidationSchema = specificGoalSelected =>
//   Yup.object().shape({
//     name: Yup.string().required('Department Name is required!'),
//     description: Yup.string().required('Description  is required!'),
//     departmentId: Yup.string().required('Department  is required!'),
//     goals: specificGoalSelected
//       ? Yup.array().required('Goal is required!')
//       : Yup.array().optional(),
//   });

export const departmentUnitInitialValues = (isDepartment = false, form) => {
  return {
    name: form ? form.name : '',
    description: form ? form.description : '',
    departmentId: form ? form.departmentId : '',
    goalTitle: [110],
    goals:
      form && !isEmpty(form.departmentGoals)
        ? form.departmentGoals.map((item) => item.goalId)
        : [],
  };
};
