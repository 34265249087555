// single_program, festival_program, grant_program, multi_activity_program

export const getLinkActivityFeatureName = (activityPlanTypeId) => {
  switch (activityPlanTypeId) {
    case 1:
      return 'single_program';
    case 3:
      return 'festival_program';
    case 4:
      return 'grant_program';
    case 6:
      return 'multi_activity_program';
    default:
      return null;
  }
};
