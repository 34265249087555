import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { activityPlanTypes } from '../../store/actions';
import ActivityHome from './form/home';
import { APP_NAME } from '../../utils/constant';
import './activities.scss';

class Activities extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = `Activities | ${APP_NAME}`;
    try {
      const { activityPlanTypes } = this.props;
      activityPlanTypes();
    } catch (error) {
      console.error('Activities -> componentDidMount -> error', error);
    }
  }

  render() {
    return (
      //   <FeatureWrapper
      //   featureName="activities"
      //   children={
      //     <ActivityHome />
      //   }
      //   fallbackComponent={
      //     <UnAuthorizedAccess/>
      //   }
      // />
      <ActivityHome />
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      activityPlanTypes,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Activities);
