import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { APP_NAME } from '../../../utils/constant';
import { ReportSelector } from './reportSelector';
import { adminAllReportData } from '../../../store/actions';
import '../../reports/reports.scss';

class SystemReports extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    document.title = `System Reports | ${APP_NAME}`;
    const { adminAllReportData } = this.props;
    adminAllReportData();
  }

  render() {
    return <ReportSelector {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      adminAllReportData,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemReports);
