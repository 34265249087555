import { get } from './lodash';

export const grantTrialProgramProjectIds =
  process.env.REACT_APP_ENV_VALUE === 'production'
    ? [3203, 3213, 1473, 2727, 2875] // [1473, 2727, 2875] - BL Test Activity
    : process.env.REACT_APP_ENV_VALUE === 'stage'
      ? [3098, 3099, 3100, 1473, 2727, 2875] // [1473, 2727, 2875] - BL Test Activity
      : [1473, 2727, 2875];

/*
 * From id 5000 onward all tooltips are only available for this specific projects (not in db level too)
 * not those ids tooltips are available for existing system
 */
export const grantTrialToolTips = [
  /*
   * page - activity Navigation bar (activity steps)
   * field - Goal
   */
  {
    tooltipId: 10,
    content:
      'This page shows the goals and domains selected for the program this activity is part of.',
  },
  /*
   * page - activity Navigation bar (activity steps)
   * field - Objective
   */
  {
    tooltipId: 11,
    content:
      'On this page you will see the outcomes (Objective) that this activity is aiming to achieve',
  },
  /*
   * page - activity Navigation bar (activity steps)
   * field - Evidence
   */
  {
    tooltipId: 12,
    content:
      'This page records information about the intended outcomes and how to achieve them. See help to learn more.',
  },
  /*
   * page - activity Navigation bar (activity steps)
   * field - TOC
   */
  {
    tooltipId: 13,
    content:
      'On this page, you will create a program logic for the activity using what you have entered as evidence. See help for more information',
  },
  /*
   * page - activity Navigation bar (activity steps)
   * field - Activity plan
   */
  {
    tooltipId: 14,
    content:
      'This page records input and output information. Note: in planning stages, this page will record projections  and when the activity has been given the go ahead to proceed, actual information will be recorded',
  },
  /*
   * page - activity Navigation bar (activity steps)
   * field - Evaluation
   */
  {
    tooltipId: 15,
    content:
      'Follow the steps to create an evaluation plan for this activity. See help for more information',
  },
  /*
   * page - activity Navigation bar (activity steps)
   * field - Documentation
   */
  {
    tooltipId: 18,
    content:
      'Enter you final analysis and reflection of the activity and review data for final report.',
  },
  /*
   * page - activity Navigation bar (activity steps)
   * field - Report
   */
  {
    tooltipId: 19,
    content: 'Review and download final report and close activity record.',
  },
  /*
   * page - activity goal
   * field -  primary policy
   */
  {
    tooltipId: 31,
    content: null, // removed tooltip
  },
  /*
   * page - activity -> goal
   * field - secondary policy
   */
  {
    tooltipId: 176,
    content: null, // removed tooltip
  },
  /*
   * page - activity -> objective
   * field - Select objective for {{domain name}}
   */
  {
    tooltipId: 21,
    content: null,
  },
  /*
   * page - activity -> objective
   * field - Select objective for {{domain name}}
   */
  {
    tooltipId: 22,
    content: null,
  },
  /*
   * page - activity -> objective
   * field - select objective for {{domain name}}
   */
  {
    tooltipId: 104,
    content: null,
  },
  // TODO: need to check below
  /*
   * page - activity -> evidence
   * field - Search evidence
   */ {
    tooltipId: 23,
    content: 'Explore evidence entered by Takso users in their activities',
  },
  /*
   * page - activity -> evidence
   * field - Add new evidence
   */ {
    tooltipId: 24,
    content:
      'Enter information from your own experience or research to support this activity.',
  },
  /*
   * page - activity -> evidence
   * field - Content
   */ {
    tooltipId: 29,
    content:
      'Enter a summary of the information you want to use for evidence. Summaries should be clear and concise and avoid acronyms or other internal language as your evidence may be read by others.',
  },
  /*
   * page - activity -> evidence
   * field - Source
   */ {
    tooltipId: 30,
    content:
      'Enter a reference for the information you have added. Your reference should enable anyone reading evidence to find the original source – Author, year, title and publication are suggested even when entering a URL. For practice knowledge evidence, enter your title/position, year and what the knowledge is based on',
  },
  /*
   * page - activity -> ToC
   * field - Aiming for
   */ {
    tooltipId: 33,
    content: 'Summarise the key points from your evidence.',
  },

  /*
   * page - activity -> ToC
   * field - Aiming for
   */ {
    tooltipId: 34,
    content: 'Summarise the key points from your evidence.',
  },
  /*
   * page - activity -> ToC
   * field - We will
   */ {
    tooltipId: 35,
    content:
      'Considering the evidence you have summarized above, describe the type of activity or process that is mostly likely to achieve the outcome.',
  },
  /*
   * page - activity -> ToC
   * field - We will
   */ {
    tooltipId: 36,
    content:
      'Considering the evidence you have summarized above, describe the type of activity or process that is mostly likely to achieve the outcome.',
  },
  /*
   * page - activity -> Activity plan
   * field - Activity name
   */ {
    tooltipId: 37,
    content: 'The entered activity name can be changed or amended here',
  },
  /*
   * page - activity -> Activity plan
   * field - Select activity type
   */ {
    tooltipId: 77,
    content:
      'From the list, select the activity type that most describes your proposed activity',
  },
  /*
   * page - activity -> Activity plan
   * field - Summarise activity
   */ {
    tooltipId: 100,
    content:
      'This description will appear at the top of any reports generated for this activity.',
  },

  // FIXME:  couldn't find tooltip [added missing tool tip for existing system]

  /*
   * page - activity -> Activity plan
   * field - Activity Members (add - 99) [search - 96 (no need this)]
   */ {
    tooltipId: 99,
    content:
      'Select the Add guest user button to invite people to collaborate with you on this activity record. The program manager is automatically invited to this record.',
  },

  // FIXME:  couldn't find tooltip
  /*
   * page - activity -> Activity plan
   * field - Projected Program contribution
   */ {
    tooltipId: 5000,
    content:
      'The amount sought from the program to contribute to the delivery of this activity.',
  },

  // FIXME: need to check tooltip for projected and actual
  /*
   * page - activity -> Activity plan
   * field - Expenditure [projected - 38, actual - 62]
   */ {
    tooltipId: 38,
    content: 'Total amount needed to deliver this activity. ',
  },
  /*
   * page - activity -> Activity plan
   * field - Expenditure [projected - 38, actual - 62]
   */ {
    tooltipId: 62,
    content: 'Total amount needed to deliver this activity. ',
  },

  /*
   * page - activity -> Activity plan
   * field - Green light date
   */ {
    tooltipId: 108,
    content:
      'Enter the date you expect to have all planning pages in Takso complete and decision made to go ahead with this activity. Note, in some cases this date will have been set by the program manager.',
  },

  /*
   * page - activity -> Activity plan
   * field - Venue [Projected]
   */ {
    tooltipId: 126,
    content:
      'Select "Add new Venue" button to enter details of any venues required for this activity',
  },
  /*
   * page - activity -> Activity plan
   * field - Venue [Actual]
   */ {
    tooltipId: 134,
    content:
      'Select "Add new Venue" button to enter details of any venues required for this activity',
  },

  /*
   * page - activity -> Activity plan
   * field - Contractor [projected]
   */ {
    tooltipId: 127,
    content:
      'Select "Add new Contractor" button to enter details of any people and services you will need to hire for this activity.',
  },
  /*
   * page - activity -> Activity plan
   * field - Contractor [actual]
   */ {
    tooltipId: 135,
    content:
      'Select "Add new Contractor" button to enter details of any people and services you will need to hire for this activity.',
  },

  /*
   * page - activity -> Activity plan
   * field - Partner
   */ {
    tooltipId: 5001,
    content:
      'Select "Add new partner" to enter details of any people/organisations who are contributing to the activity and are not receiving payment.',
  },

  /*
   * page - activity -> Activity plan
   * field - Active participants free [projected]
   */ {
    tooltipId: 185,
    content:
      'Participants who are actively involved e.g. worship participants, performers etc and do not pay to be involved .',
  },
  /*
   * page - activity -> Activity plan
   * field - Active participants free [actual]
   */ {
    tooltipId: 154,
    content:
      'Participants who are actively involved e.g. worship participants, performers etc and do not pay to be involved .',
  },

  /*
   * page - activity -> Activity plan
   * field - Receptive participants free [projected]
   */ {
    tooltipId: 45,
    content:
      'Attendees not involved in creative production and not paying to attend activity.',
  },
  /*
   * page - activity -> Activity plan
   * field - Receptive participants free [actual]
   */ {
    tooltipId: 69,
    content:
      'Attendees not involved in creative production and not paying to attend activity.',
  },

  /*
   * page - activity -> Activity plan
   * field - Active participants paid
   */ {
    tooltipId: 137,
    content:
      'Participants who are actively involved e.g. worship participants, performers etc and are paid to attend activity.',
  },
  /*
   * page - activity -> Activity plan
   * field - Active participants paid
   */ {
    tooltipId: 155,
    content:
      'Participants who are actively involved e.g. worship participants, performers etc and are paid to attend activity.',
  },

  /*
   * page - activity -> Activity plan
   * field - Receptive participants paying [projected]
   */ {
    tooltipId: 179,
    content:
      'Attendees who are not involved in creative production who are paying to participate in this activity',
  },
  /*
   * page - activity -> Activity plan
   * field - Receptive participants paying [actual]
   */ {
    tooltipId: 162,
    content:
      'Attendees who are not involved in creative production who are paying to participate in this activity',
  },

  /*
   * page - activity -> Activity plan
   * field - Active participants paying [projected]
   */ {
    tooltipId: 138,
    content:
      'Participants who are actively involved e.g. worship participants, performers etc who pay a fee to engage in activity.',
  },
  /*
   * page - activity -> Activity plan
   * field - Active participants paying [actual]
   */ {
    tooltipId: 186,
    content:
      'Participants who are actively involved e.g. worship participants, performers etc who pay a fee to engage in activity.',
  },

  /*
   * page - activity -> Activity plan
   * field - Do you want to identify any core groups within these participants?
   */ {
    tooltipId: 208,
    content:
      'Create sub-group if you wish to divide a single participant group in smaller groups for evaluation.',
  },
  /*
   * page - activity -> Evaluation
   * field - Objective
   */ {
    tooltipId: 210,
    content:
      'Select Objectives you wish to include in this survey. You can create multiple evaluation plans but each outcome must be included in at least one plan.',
  },
  /*
   * page - activity -> Evaluation
   * field - Select evaluation group
   */ {
    tooltipId: 211,
    content:
      'Select the group/s you wish to direct this survey to. You can survey multiple groups in a single evaluation plan or create different plans for each group. Note: Core group surveys cannot be combined with other participant groups.',
  },
  /*
   * page - activity -> Evaluation
   * field - Select Evaluation method
   */ {
    tooltipId: 129,
    content: 'Select how you will conduct the survey',
  },
  /*
   * page - activity -> Evaluation
   * field - Select Data Collection method
   */ {
    tooltipId: 131,
    content:
      'Select how you will collect the data and enter results into Takso',
  },
  /*
   * page - activity -> Evaluation
   * field - Question
   */ {
    tooltipId: 213,
    content:
      'Select the outcome to preview the survey question.  Highlighted text can be clicked into and edited to make more appropriate to the activity and participants.',
  },
  /*
   * page - activity -> Evaluation
   * field -  Create multi-stage evaluation series
   */ {
    tooltipId: 5002,
    content:
      'This is designed for long term activities that follow the same participants over an extended period. ',
  },
  /*
   * page - activity -> Evaluation
   * field - Activity approval
   */ {
    tooltipId: 5003,
    content:
      'Once all planning requirement have been completed you will be able to green light the activity as ready to proceed. ',
  },
  /*
   * page - activity -> Documentation
   * field - Activity Description
   */ {
    tooltipId: 80,
    content: 'Check this description accurately describes what was delivered.',
  },
  /*
   * page - activity -> Documentation
   * field - Upload media
   */ {
    tooltipId: 57,
    content:
      'Upload activity records such as photos, short clips, and other documents. URLs are saved as text only and need to be copied and pasted into a browser to view.',
  },
  /*
   * page - activity -> Documentation
   * field - Actual Inputs
   */ {
    tooltipId: 5004,
    content:
      'Check this information is correct and make any required changes via the Activity page',
  },
  /*
   * page - activity -> Documentation
   * field - Actual Outputs
   */ {
    tooltipId: 5005,
    content:
      'Check this information is correct and make any required changes via the Activity page',
  },
  /*
   * page - activity -> Documentation
   * field - Evaluation results analysis
   */ {
    tooltipId: 219,
    content: `Consider the information from your evaluation and provide a analysis of what the evaluation tells you about participant's outcomes and the factors that were important.`,
  },
  /*
   * page - activity -> Documentation
   * field - Reflection
   */ {
    tooltipId: 81,
    content:
      'Looking back at the whole activity, provide a final reflection on the activity its successes and challenges.',
  },
  /*
   * page - activity -> Documentation
   * field - Unpublished report
   */ {
    tooltipId: 82,
    content:
      'Enter any thoughts and observations that you wish to record with the activity but not include in the final report. This could issues encountered in delivery, or factors that contributed to success. Entries in this field will only be visible to those with access to this record.',
  },
  /*
   * page - activity -> Documentation
   * field - make visible in Takso library
   */ {
    tooltipId: 111,
    content:
      'To aid learning in the sector, final activity reports are added to the Takso library for all users. If you are happy for the final report (next page) to be added to the library, turn this switch on.',
  },
];

// for changing hard coded or defines label texts or texts in system
export const grantTrialDefinedTexts = (textValue) => [
  /*
   * Original Text: Organisation Goal to be addressed, Select Goal
   */
  {
    id: 1,
    text: 'Goal',
  },
  /*
   * Original Text: Your primary policy domain will be populated based on the selected goal
   */
  {
    id: 2,
    text: 'Primary policy domain is populated based on selected goal',
  },
  /*
   * Original Text: Your secondary policy domain will be populated based on the selected goal
   */
  {
    id: 3,
    text: 'Secondary policy domain is populated based on selected goal',
  },
  /*
   * Original Text: Select objective for {{domain name}} domain
   */ {
    id: 4,
    text: `Objective for ${textValue} domain`,
  },
  /*
   * Original Text: Summarise the strongest points of evidence that leads to the activity
   */ {
    id: 5,
    text: 'Continue the sentence started above with your evidence summary',
  },
  /*
   * Original Text: Describe the activity required to achieve the objective (TODO: check in text it's ->outcome)
   */ {
    id: 6,
    text: 'Continue the sentence started above with your description',
  },
  /*
   * Original Text: Do you want to identify any core groups within these participants?
   */ {
    id: 7,
    text: 'Create participant sub group.',
  },
  /*
   * Original Text: Provide notes about the activity
   */ {
    id: 8,
    text: ' ', // to empty or remove label text send string with space
  },
  /*
   * Original Text: Make visible in Takso library
   */ {
    id: 9,
    text: 'Add report to Takso library',
  },
];

/*
 * Grant Trial Help Panel Texts
 */
export const grantTrialHelpTips = [
  /*
   * page - Goal
   */
  {
    helpTipType: 'activity.goal',
    content: ` 
    <p><strong>Goal&nbsp;</strong></p>
    <p><strong>Action:&nbsp;</strong></p>
    <p>The entries for this page were set when this record was created.  You do not need to do anything on this page.
   </p>
   
    <p><strong>Background:&nbsp;</strong></p>
    <p>This program of activities were created to address a goal of the funding organisation. The goal being addressed has been entered on this page, and should this activity be successful, it will have contributed to the achievement of the goal.
   </p> 
   
   <p><strong>Domains:&nbsp;</strong></p>
    <p>A domain is an area of activity. There are five domains (Cultural, Social, Economic, Environmental and Governance) and each domain has a set of outcomes that can be achieved by individual activities. The selected domains are based on the selected goal, the program manager’s decision.  In some cases, you may have been given a choice of outcomes for this activity and those choices will dictate the domains entered on this page. 
   </p>`,
  },

  /*
   * page - Objective
   */ {
    helpTipType: 'activity.objective',
    content: `
    <p><strong>Objective&nbsp;</strong></p>
    <p><strong>Action:&nbsp;</strong></p>
    <p>
        The entries for this page were set when this record was created.  You do not need to do anything on this page.
   </p>
   
    <p><strong>Background:&nbsp;</strong></p>
    <p> </p> 
   
   <p><strong>Outcomes:&nbsp;</strong></p>
    <p>
   The objective page identified the outcome/s you are seeking to achieve via this activity. By outcome, we mean the change that occurs for participants as a result of experiencing an activity. In some cases the outcomes selected on this page have been identified by the program manager. In other instances you will have been asked to select the outcomes you wish to focus on. These should be reflected on this page.  If there are any problems, please contact the program manager. 
   </p>
   `,
  },

  /*
   * page - Evidence
   */ {
    helpTipType: 'activity.evidence',
    content: `
    <p><strong>Evidence&nbsp;</strong></p>
    <p><strong>Action:&nbsp;</strong></p>
    <p>
        On this page you need to enter information on what you know about achieving your selected outcomes and why they are important to the community you intend to engage. The information entered on this page acts as an evidence base for your proposed activity.<br/><br/>
        
           You need to add a minimum of one piece of evidence for each outcome and this evidence can come from published sources or your own experience and practice. <br/><br/>
   
   You can enter evidence by selecting the “Add new evidence” button on the right of the page. If you are entering Practice knowledge evidence, you can add your position title as the source. Any practice knowledge you enter as evidence remains private to this activity and cannot be read by anyone who does not have access to this record.<br/><br/>
   
   You can also search evidence used by other Takso users in their activities by using the search field on the left of the page.<br/><br/>
   
   Once you have a least one piece of evidence linked to each outcome, you can move to the next page.
   
   
   </p>
   
    <p><strong>Evidence tips:&nbsp;</strong></p>
    <p>
        If you are not sure about what information to enter as evidence, the following questions may help: <br/><br/>
        
   <strong>Think about the people you hope to engage with this activity, </strong><br/><br/>
   
   Why are these outcomes important to them? <br/>
   Where are they now in relation to this outcome?<br/><br/><br/>
   
   <strong>Think about the outcomes,</strong><br/><br/>
   
   What types of activities or processes assist the achievement of the outcomes?<br/>
   How do you know your activity will achieve the outcomes?<br/>
   What resources do you have and what resources will you need to deliver your activity?<br/>
   
   </p> 
   `,
  },

  /*
   * page - TOC
   */ {
    helpTipType: 'activity.theoryOfChange',
    content: ` 
    <p><strong>Theory of Change&nbsp;</strong></p>
    <p><strong>Action:&nbsp;</strong></p>
    <p>
       On this page you need to enter information in the text boxes to create a pitch (theory of change) explaining why for your proposed activity will achieve the outcomes.<br/><br/>
       
   There are two text fields to complete for each outcome. Above each text field is a start of a sentence that you should continue in the text field. <br/><br/>
   
   
   The first field requires you to enter a summary of the ideas from the evidence you entered for the outcome. In the second you should describe what you will do, as a result of that evidence, to achieve the outcome. <br/><br/>
   
   
   A short way to think about this is:<br/><br/> 
   <strong>Aiming for</strong> outcome selected 
   <strong>and knowing</strong> summary of your evidence, <strong>we will</strong> do this to achieve the outcome.<br/><br/>
   
   After you have completed both fields, select the preview Theory of Change button to check that your theory of change reads as a coherent idea. 
   </p> 
   `,
  },

  /*
   * page - Activity Plan
   */ {
    helpTipType: 'activity.activityPlan',
    content: `
    <p><strong>Activity Plan&nbsp;</strong></p>
    <p><strong>Action:&nbsp;</strong></p>
    <p>
   This page records the practicalities of the activity and is used to plan and project the activity. Once the activity is confirmed to go ahead this page is reset and is where you will record actual activity data.
   </p>
   
   <p>
   <strong>Select Activity Type:</strong> use the drop down to select the most appropriate option that describes your activity. Note: if you are unsure of what to select, check the description under the field, which will appear with each option. <br/><br/>
   
   <strong>Activity summary:</strong> enter description of the activity.  
   <br/><br/>
   
   <strong>Activity members:</strong> The program manager who set up this record will be automatically entered in this field. If you would like other people to assist you, select Add Guest User and enter their details. On selecting invite, they will be sent an email to set up their password and log into this record.<br/><br/> 
   
   <strong>Projected Inputs:</strong><br/><br/>
   
   <strong>Projected Program Contribution:</strong> This is the amount that this activity seeks from the overall program budget.<br/><br/>
   
   <strong>Expenditure:</strong> enter the amount you expect to spend in the delivery of this activity (including funds sought from the program).<br/><br/>
   
   <strong>Green light date:</strong>  This is the date for which the final decision to proceed with the activity needs to me made. If the date has been entered by the program manager, you will need to ensure that all pages prior to green light have been completed by the entered green light date.<br/><br/>
   
   <strong>Activity Start and End dates:</strong>  enter the expected first and last date of the proposed activity. One day activities should have the same date entered for start and end date.<br/><br/>
   
   
   <strong>Venue:</strong>  Enter Any venues you intend to use in the delivery of this activity should be entered. <br/><br/>
   
   
   <strong>Contractors:</strong> If you will need to pay for any services to deliver this activity enter details here via the Add New Contractor button. When entering new contractors, you will be asked to select two levels of Occupation for the contractor, based on the Australian New Zealand Standard Classification of Occupations (ANZSCO). In the first field, select the broad occupational group and in the second select the specific occupation that you will contract.<br/><br/>
   
   If you know the service you will need but not the specific contractor, select the projected button at the top of the pop up window and this will allow you to save with just the ANZSCO information. <br/><br/>
   
   
   <strong>Partners:</strong> Enter any people or organisations that will contribute to the activity but will not be paid. These could include people who provide skills or resources or financial support. <br/><br/>
   
   <strong>Projected outputs:</strong>
   
   <strong>Other income:</strong>  If you expect to receive an income from the activity such as ticket sales, enter an estimated amount.
   
   <strong>Participant fields:</strong><br/><br/>
   The participant fields shown will very depending on the activity type selected. In the relevant participant fields, enter the number of participants you expect to engage with this activity. You do not need to enter a number is every field but there should be at least one field completed. <br/><br/>
   
   Participants are categorized into two main groups:
   <br/><br/>
   <strong>Active participants:</strong> are involved with the creative process such as performers, or workshop participants. Depending on your activity, active participants can be free, be paid by the organiser, or be paying to participate.
   <strong>Receptive participants:</strong> are not involved in creative production but attend creative activities such as audience members or exhibition visitors. They can be free or paying.<br/><br/>
   
   <strong>Core participants:</strong> This allows you to identify a sub-group from the participants you have identified above and is used if you wish to direct an evaluation survey to a group separate from the main survey.  If you create a core group, you will be able to create an evaluation plan for them on the next page.
   
   </p> 
   
    `,
  },

  /*
   * page - Evaluation
   */ {
    helpTipType: 'activity.evaluationPlan',
    content: `
    <p><strong>Evaluation&nbsp;</strong></p>
    <p><strong>Action:&nbsp;</strong></p>
    <p>
   On this page you will identify how you intend to evaluate the outcomes of this activity.  Takso has a pre-written question for every outcome, but you will need to consider how you want to survey participants. <br/> <br/>
   Select the Add new evaluation plan button and follow the steps to the creation and saving of an evaluation plan for this activity. 
   </p>
   
   <p>
   <strong>Background/ information</strong> 
   
   <p>Takso survey methods include direct interview, questionnaire or focus group. You can create survey multiple participant groups in one survey or create separate evaluation plans for your individual participant groups. Takso will not allow you to create two evaluation plans that are the same. When considering multiple evaluations, take into account the need for additional evaluation and whether you have the resources to undertake multiple surveys.
    <br/><br/>
    
    Start creating an evaluation plan by selecting the outcomes and participant groups you wish to evaluate.
   </p> 
   
   <strong>Recommended sample size,</strong> 
   <p>The sample size number has been included to offer you a guide rather than a requirement and is set to a high validity level. While it is important for effective evaluation to think about the number of respondents, it is important to do the evaluation with the number of people you can access depending on their availability and your resources. All evaluation counts.</p>
   
   <strong>Select Evaluation Respondent type</strong> 
   <p>In most cases the participants will be the people you ask to consider the outcomes of the activity. This may not always be appropriate. For example, if your participant group are young children, you may want to ask a parent to respond for their child as a proxy or you may consider asking an expert in the field who has knowledge of the activity to give their opinion of the participants experience.</p>
   
    
   <p><strong>Participant:</strong> select this option when the people who participate in the activity will be responding to the survey.</p>
   
   <p><strong>Proxy:</strong> select this option when the person responding to the survey will not be the participant themselves but someone who knows the participant such as a parent or care giver.</p>
   
   <p><strong>Expert:</strong> select this option when the person responding to the survey is not directly involved with the participants but has expertise that can be used to evaluate the participants’ experience.</p>
   
   <p><strong>Self-assessed:</strong> in instances where evaluation with participants, proxies or experts is not possible or a planned evaluation has not occurred, this method will ask the activity manager to give their own opinion on the participants’ outcomes. If you select this method, you do not have to complete any other fields on this page. The evaluation question will appear on this page after green light.
   </p>
   
   <strong>Select evaluation method</strong>
   <p>There are a range of methods built into Takso and you can also choose methods outside the system such as using SurveyMonkey, or paper-based surveys, and phone interviews. All the data, regardless of method is recorded back into Takso. <br/><br/>
   
   When selecting your evaluation method, you should consider what resources you have available. For example, do you have enough people available to conduct interviews. If you only have one person available, they may only be able to survey 10 people in an hour. Do you have enough email addresses to use an online survey (and are people engaged enough to respond), do you have phone numbers to do a phone interview? Do you have a small participant group in which case a focus group is useful? These should all be considered when selecting your evaluation method.
   </p>
   
   <p><strong>Interview:</strong> select this method if you will be speaking directly to the evaluation participants either in person or by phone.
   </p>
   
   <p><strong>Questionnaire:</strong> select this method if you intend evaluation participants to read and answer questions themselves either via an email link, paper handouts or an “evaluation kiosk” at an event.
   </p>
   
   <p><strong>Focus group:</strong> select this method when you intend to bring together a small group of evaluation participants to take part in a discussion on the activity and the outcomes. 

   </p>
   
   <strong>Select data collection method</strong>
   <p>How you collect the data will depend on the participants, the evaluation method, and your resources.
   </p>
   
   <p><strong>Takso Link:</strong> 
   Takso will generate a survey link and responses to the link will be automatically added to Takso. The survey links will be available on this page once the activity has been given the Green Light. 
   </p>
   
   <p><strong>CSV  Upload:</strong> 
   Select this method if you intend to conduct your evaluation via an external survey platform such as Survey Monkey or on paper. Results can then be uploaded into Takso using the CSV template available after green light. 
   </p>
   
   <strong>Survey question</strong>
   <p>After you have made your selection, the survey question will be able to be viewed in the page by opening the section marked with the outcome name. If you are doing a paper survey or using platforms such as Survey Monkey you can copy and paste the question into your preferred platform.<br/><br/>
   
   The questions have been designed to give respondents the information needed to understand the outcome before being asked to reflect on their experience. Therefore, the question format should not be changed. If you wish to gather additional information such as demographics, this should be done after asking the outcome question as the outcome questions require more reflection on the part of the respondent.<br/><br/>
   If you are conducting evaluation using the Takso link, there is an opportunity to change parts of the question text to make the evaluation more appropriate to the activity. The editable sections are highlighted in the question preview. If you do change any text, be sure the read the question again to ensure the sentence is grammatically correct.
   </p>
   
   
   <strong>Custom questions:
   </strong>
   <p>
       If you are using the Takso link and wish to gather any additional information, you can add additional questions. There are two pre-set questions to gather postcode and age information. If you would like any other information, check the custom question box and enter your own question. Any custom questions will appear in the survey link with a free text field for responses.<br/><br/>
       
   You can also request respondents’ email by checking the box and entering a request for email.
   
   </p>
   
   <strong>Survey completion message:
   </strong>
   <p>
      After submitting a response to the Takso link, respondents are presented with a submission success message. You can customize this message here. <br/> <br/>
   Once you have saved an evaluation plan.  <br/> <br/>
   
   If you have any missing information on the previous pages, you will see an outstanding information message in blue. If you have no outstanding information message, the activity is considered ready for green light. 
   
   </p>
   
   
   <strong>Evaluation page post green light:
   </strong>
   <p>
      After green light has been given the evaluation page is re-set to become the place where you manage the evaluation and compile results. <br/> <br/>
      
   Show Survey Information: In this section your will find survey links, if you are using the Takso link.  Two links are generated, the single use link will only accept one response from a single device and should be used if you are sending the link to individual people.  The multi-use link will accept multiple responses from a device and should be used if you expect more than one person to respond on a single device. You can also generate a QR code for each link.   <br/> <br/>
   
   
   If you selected CSV upload, you will find the survey questions to copy into your own survey tool.  If you need to change the data collection method, you can do this from here.  You are able to change the method up until the first response has been entered.
   
   
   </p>
   
   <strong>Show evaluation details:
   </strong>
   <p>
      Here is where responses are gathered for your survey.  If you are using the Takso link for the evaluation, you will see results as they come in here.<br/><br/>
      
   If you selected CSV upload as your data collection method, you will see a button to download a CSV template for you to enter results which can then be uploaded via the Upload button. <br/><br/>
   
   If you opted for a self-assessed evaluation, you will only see Show Evaluation details, which will have the self-assessment ready for completion. <br/><br/>
   
   Once you are certain that you have received all the responses you are likely to get, you can mark the evaluation as closed by responding to the question above the evaluation information button.  If the evaluation did not take place, answering no to this question will change the evaluation to a self-assessment for you to complete. 
   
   </p>
    `,
  },

  /*
   * page - Documentation
   */ {
    helpTipType: 'activity.documentation',
    content: `
    <p><strong>Documentation page:&nbsp;</strong></p>
    <p><strong>Action:&nbsp;</strong></p>
    <p>On this page you will complete your activity record before submitting the report to the program manager.  <br/> <br/>
    
   Documents and media: Upload any photos/ videos/ documents etc from the activity. <br/> <br/>
   
   Actual Inputs & Actual Outputs: The information for these sections are drawn from the entries you made on the <strong>Activity plan</strong> page after Green Light. Review data to check for accuracy and if any changes are required, return to the Activity plan page to update information. 
   </p>
   
   <p><strong>Evaluation Results Analysis:</strong>  In this section provide an analysis of what you have learnt from the evaluation. Did your participants experience the intended outcomes? To what extent? What did their comments tell you about what was important to their outcomes.
   </p>
   
   <p><strong>Reflection:</strong> In this section, look back at the activity as a whole, was it successful?  How do you know? Did it achieve the outcomes you expected? Did you learn anything new? Where there any problems or information you were no aware of when you began planning this activity?
   </p>
   
   <p><strong>Unpublished note:</strong>  If you want to record any information or comments that are important to record but should not be in the report, you can add them here. The notes are only readable by people who have access to this record. 
   </p>
   
   <strong>Background/ information</strong>
   <p>
   The Evaluation Results Analysis and Reflection entries are added to the public report for this activity.  When writing these sections, think about what information and learning that may useful or you would like to share with others.
   </p>
   
    `,
  },

  /*
   * page - Report
   */ {
    helpTipType: 'activity.report',
    content: `
    <p><strong>Report:&nbsp;</strong></p>
    <p><strong>Action:&nbsp;</strong></p>
    <p>This page presents the final report for your activity that has been compiled from entries in the previous pages. You can download a copy of this report via the Export button on the top right of the page.<br/><br/>
    
   If you have any information outstanding from the previous pages, you will see an outstanding information notification in blue at the top of the page. If you have no outstanding information, you are able to close the record by selecting the Archive Activity button at the bottom of the page. 
   
   </p>
   
   <strong>Background/ information:</strong>  
   <p>Once you have Archived your activity, the program manager is notified that your activity has been finalized and that the report is ready to view.  If the Program Manager would like you to add more information to the activity record, they will re-open the record and let you know what information they would like you to enter. 
   
   </p>
    `,
  },
];

// for getting projects texts only (for placeholders)
export const getGrantTrialDefinedTexts = (
  projectId,
  defaultText,
  textId,
  additionalTextValue
) => {
  const isTrialProject = grantTrialProgramProjectIds.includes(projectId);

  let textValue = null;
  if (isTrialProject) {
    const projectTextsData = grantTrialDefinedTexts(additionalTextValue);
    const projectTextItem = projectTextsData.find(
      (item) => item.id === Number(textId)
    );
    textValue = get(projectTextItem, 'text', null);
  }

  if (!textValue) {
    textValue = defaultText;
  }

  return textValue;
};
