/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

import { Button } from 'semantic-ui-react';
import { get, flattenDeep, sum } from '../../../utils/lodash';
import { CustomReportHeader } from '../../customReportHeader';
import { useSelector } from 'react-redux';

var chartdata = {};
export const TrendRateOfChangeChart = ({
  outcome,
  date,
  method,
  evalPlan,
  participantsChangePrePost,
  isDocumentationLegends,
  avoidLabel = false,
  inReportLbl = false, // to identify in report radio btn available
  isInReport = false, // to identify this chart included in given outcome report
  isDisabledInReportGrpBtn = false, // to identify this chart include in burton disable
  showLegends = true,
  isManualChart = false,
  ...props
}) => {
  const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [totalParticipant, setTotalParticipant] = useState(0);
  const [activityCount, setActivityCount] = useState(0);
  const [totalRespondents, setTotalRespondents] = useState(0);
  const [evalCount, setEvalCount] = useState(0);
  const [programsActivityCount, setProgramsActivityCount] = useState(0);
  const [linkedActivityCount, setLinkedActivityCount] = useState(0);
  const [inReport, setInReport] = useState(isInReport);

  useEffect(() => {
    setInReport(isInReport);
  }, [isInReport]);

  const [dataset, setDataset] = useState({ labels: [], datasets: [] });

  const loadingIncludeIn = useSelector(
    (state) => state.report.includeInReportLoading
  );

  useEffect(() => {
    setalldataTograph(participantsChangePrePost);
  }, [participantsChangePrePost]);

  const options = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.2,
      },
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: showLegends,
        labels: {
          font: {
            size: isManualChart ? 22 : 14,
            family: 'Inter',
          },
        },
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        custom: function (tooltip) {
          if (!tooltip) return;
          tooltip.displayColors = false;
        },
        callbacks: {
          label: (context) => {
            const participants = get(context, 'formattedValue', 0);
            const isAverage =
              get(context, 'dataset.category', '') === 'AVERAGE';
            const isAfterResponse =
              get(context, 'dataset.responseType', 'PRE') === 'POST';

            let labelType =
              (!isAverage && context.datasetIndex === 2) ||
              (isAverage && isAfterResponse)
                ? 'Post '
                : 'Pre ';
            labelType = `${labelType} ${isAverage ? 'Average' : 'Result'}`;

            return `${labelType} - ${context.label}  ${
              !isAverage ? `Count - ${participants}` : ''
            }`;
          },
          title: () => {
            return '';
          },
        },
        displayColors: false,
      },
    },
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        display: true,
        ticks: {
          max: 10,
          min: 1,
          stepSize: 1,
          font: {
            size: isManualChart ? 20 : 12,
            family: 'Inter',
            weight: '400',
          },
          callback: function (value, index, values) {
            if (value === 1) {
              return ['1', 'Not at All'];
            } else if (value === 10) {
              return ['10', 'Most Imaginable'];
            }
            return value;
          },
        },
        title: {
          display: true,
          // text: 'Evaluated Score',
          font: {
            size: isManualChart ? 22 : 14,
            family: 'Inter',
          },
        },
      },
      y: {
        display: true,
        ticks: {
          min: 0,
          precision: 0,
          font: {
            size: isManualChart ? 20 : 12,
            family: 'Inter',
            weight: '400',
          },
        },
        title: {
          display: true,
          text: 'Number of Respondents',
          font: {
            size: isManualChart ? 22 : 14,
            family: 'Inter',
          },
        },
      },
    },
  };

  const setalldataTograph = async (participantsChangePrePost) => {
    try {
      setTotalParticipant(
        get(participantsChangePrePost, 'totalParticipants', 0)
      );
      setTotalRespondents(
        get(participantsChangePrePost, 'totalRespondents', 0)
      );
      setEvalCount(get(participantsChangePrePost, 'evalCount', 0));
      setActivityCount(get(participantsChangePrePost, 'activityCount', 0));
      setProgramsActivityCount(
        get(participantsChangePrePost, 'programsActivityCount', 0)
      );
      setLinkedActivityCount(
        get(participantsChangePrePost, 'linkedActivityCount', 0)
      );

      if (participantsChangePrePost)
        if (!participantsChangePrePost.participantResponse) {
          participantsChangePrePost.participantResponse = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          ];
        }

      chartdata = formatChartData(participantsChangePrePost.data);
      if (chartdata) {
        chartdata.labels = [
          'not-at-all',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          'most-imaginable',
        ];

        chartdata.colors = [
          '#0868ac',
          { backgroundColor: '#0868ac', borderColor: '#0868ac' },
          '#7bccc4',
          { backgroundColor: '#7bccc4', borderColor: '#7bccc4' },
          '#0868ac',
          '#949FB1',
          '#4D5360',
        ];

        chartdata.summary = {
          totalRespondents,
          totalParticipant,
          evalCount,
          activityCount,
          programsActivityCount,
          linkedActivityCount,
        };
        chartdata.onClick = function (points, evt) {};
        chartdata.datasetOverride = [];
        if (chartdata.data) {
          if (chartdata.data.length === 2) {
            chartdata.series = ['Responses', 'Average'];
          }
          if (chartdata.data.length === 4) {
            chartdata.series = [
              'After Responses',
              'After Average',
              'Before Responses',
              'Before Average',
            ];
          }
        }

        let lines = [
          {
            category: 'AVERAGE',
            responseType: 'POST',
            label: chartdata.series[1] ? chartdata.series[1] : 'After Average',
            data: chartdata.data[1],
            pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
            type: 'line',
            backgroundColor: '#D16400',
            borderColor: '#D16400',
            fill: true,
            pointBackgroundColor: '#fff',
            pointBorderColor: 'rgba(253, 143, 88, 0.5)',
            pointHoverBackgroundColor: 'rgba(253, 143, 88, 0.5)',
          },
          {
            category: 'AVERAGE',
            responseType: 'PRE',
            label: chartdata.series[3] ? chartdata.series[3] : 'Before Average',
            data: chartdata.data[3],
            pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
            type: 'line',
            backgroundColor: '#2F80ED',
            borderColor: '#2F80ED',
            fill: true,
            pointBackgroundColor: '#fff',
            pointBorderColor: 'rgba(47, 128, 237, 0.5)',
            pointHoverBackgroundColor: 'rgba(47, 128, 237, 0.5)',
          },
          {
            category: 'RESPONSE',
            label: chartdata.series[0]
              ? chartdata.series[0]
              : 'After Responses',
            data: chartdata.data[0],
            pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
            type: 'line',
            backgroundColor: 'rgba(253, 143, 88, 0.5)',
            borderColor: '#F8CCA4',
            fill: true,
            pointBackgroundColor: '#fff',
            pointBorderColor: 'rgba(253, 143, 88, 0.5)',
            pointHoverBackgroundColor: 'rgba(253, 143, 88, 0.5)',
          },
          {
            category: 'RESPONSE',
            label: chartdata.series[2]
              ? chartdata.series[2]
              : 'Before Responses',
            data: chartdata.data[2],
            pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
            type: 'line',
            backgroundColor: 'rgba(47, 128, 237, 0.5)',
            borderColor: '#97BFF6',
            fill: true,
            pointBackgroundColor: '#fff',
            pointBorderColor: 'rgba(47, 128, 237, 0.5)',
            pointHoverBackgroundColor: 'rgba(47, 128, 237, 0.5)',
          },
        ];

        setDataset({
          labels: labels,
          datasets: lines,
        });
      } else {
        setDataset({
          labels: null,
          datasets: null,
        });
      }
    } catch (error) {
      console.error('error:', error);
    }
  };

  const formatChartData = (getdata) => {
    if (!getdata) {
      return null;
    }
    var chart = {};
    var dataSet = [];
    var chartData = [];
    var yMax = 0;
    var data = [];

    for (let x = 0; x < getdata.length; x++) {
      const result = Array.isArray(getdata[x])
        ? getdata[x]
        : Object.values(getdata[x]);

      if (result.length === 11) {
        result.pop();
      }
      data.push(result);
    }

    for (let x = 0; x < data.length; x++) {
      for (let i = 0; i < data[x].length; i++) {
        if (data[x][i] > yMax) {
          yMax = data[x][i];
        }
      }
    }
    for (let x = 0; x < data.length; x++) {
      for (var i = 0; i < data[x].length; i++) {
        if (data[x][i] > yMax) {
          yMax = data[x][i];
        }
        chartData.push({
          x: i + 1,
          y: sum(flattenDeep(getdata)) === 0 ? null : data[x][i],
        });
      }
      dataSet.push(chartData);
      chartData = [];
      dataSet.push(average(data[x], yMax));
    }

    if (dataSet.length === 2 || dataSet.length === 4) {
      chart.data = dataSet;
    }

    return chart;
  };

  const average = (data, yMax) => {
    if (sum(data) === 0) {
      return null;
    }

    var totalParticipants = data.reduce(
      (total, value) => total + parseFloat(value),
      0
    );
    var denominator = 10 * totalParticipants;
    var total = data.reduce(
      (sum, value, index) => sum + parseFloat(value) * (index + 1),
      0
    );
    var average = (total / denominator) * 10;
    average = isNaN(average) ? 0 : parseFloat(average.toFixed(2));

    return [
      { x: average, y: 0 },
      { x: average, y: yMax },
    ];
  };

  const onChangeInReport = () => {
    const _inReport = !inReport;
    setInReport(_inReport);
    props && props.onChangeInReport && props.onChangeInReport(_inReport);
  };

  let summary = {
    totalParticipant: totalParticipant,
    totalResponse: totalRespondents,
    activityCount: activityCount,
    evalCount: evalCount,
    programsActivityCount: programsActivityCount,
    linkedActivityCount: linkedActivityCount,
  };

  return (
    <>
      <div id={`self-assessed-post-pre-trend-chart`}>
        <div className="outcomes-summary-chart-div">
          {!avoidLabel ? (
            <div className="chart-header d-flex">
              Trend Rate of Change
              {inReportLbl && (
                <div
                  className="ml-auto mr-3"
                  style={{ display: ' flex', flexDirection: 'row' }}
                >
                  <div className="checkbox-container-include-in-report">
                    <div className="include-in-report-label">
                      Include in report
                    </div>
                    <div className="button-include">
                      <Button.Group
                        className="btn-include-in-report"
                        onClick={() =>
                          !isDisabledInReportGrpBtn && onChangeInReport()
                        }
                        disabled={isDisabledInReportGrpBtn}
                      >
                        <Button
                          active={inReport}
                          disabled={isDisabledInReportGrpBtn}
                        >
                          Yes
                        </Button>
                        <Button
                          active={!inReport}
                          disabled={isDisabledInReportGrpBtn}
                        >
                          No
                        </Button>
                      </Button.Group>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : null}
          <span id="downloadChartContainer">
            <CustomReportHeader
              date={date}
              outcome={outcome}
              evalPlan={evalPlan}
              method={method === '9' ? true : false}
              isIntercept={method === '12' ? true : false}
              da={{ summary }}
              legendResponse={true}
              isDocumentationLegends={isDocumentationLegends}
              isManualChart={isManualChart}
              {...props}
            />
            <div className="report-border-container">
              <Line options={options} data={dataset} />
            </div>
          </span>
        </div>
      </div>
    </>
  );
};
