const getMinifiedText = (originalText = null, length = 40) => {
  if (!originalText) return '';

  let minifiedText = originalText;
  if (originalText.length > length) {
    minifiedText = originalText.substring(0, length) + '...';
  }

  return minifiedText;
};

export default getMinifiedText;
