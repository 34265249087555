import moment from 'moment';
import React from 'react';
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvRadio,
  AvRadioGroup,
} from 'availity-reactstrap-validation';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Segment,
  Modal,
  Button,
  Icon,
  Label,
  Dropdown,
} from 'semantic-ui-react';
import { map, get } from '../../../../utils/lodash';
import { EmptyContainer, CustomTable, SeeMore } from '../../../../components';
import {
  getBroadcastNotifications,
  getAllOrganisationList,
  createBroadcastNotifications,
} from '../../../../store/actions';
import '../../../organization';

class NotificationForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOnlyAdmin: false,
      organisationIds: [],
      organisationError: false,
      userType: 'allOrganisation',
    };
  }

  async handleValidSubmit(event, values) {
    try {
      const { organisationIds, isOnlyAdmin } = this.state;
      const { toggle, createBroadcastNotifications, resetPage } = this.props;
      if (
        values.userType === 'selectedOrganisation' &&
        !organisationIds.length
      ) {
        this.setState({ organisationError: true });
      } else {
        const formData = {
          title: values.title,
          message: values.message,
          isAllSystemUsers: values.userType === 'allUsers',
        };
        if (
          values.userType === 'selectedOrganisation' ||
          values.userType === 'allOrganisation'
        ) {
          formData.isAllOrganisation = values.userType === 'allOrganisation';
          formData.organisationIds = organisationIds;
          formData.isOnlyAdmin = isOnlyAdmin;
        }

        await createBroadcastNotifications(formData);
        this.form && this.form.reset();
        if (resetPage) resetPage();
        toggle();
      }
    } catch (error) {
      console.error(
        'Error: createBroadcastNotifications ~ handleValidSubmit',
        error
      );
    }
  }

  getOrganisationOptions() {
    const { allOrganisation } = this.props;
    return map(allOrganisation, (item, i) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
  }

  onlyAdminToggel = () => {
    this.setState((prevState) => ({
      isOnlyAdmin: !prevState.isOnlyAdmin,
    }));
  };

  async onChangeOrganisation(event, data) {
    this.setState({
      organisationIds: data.value,
      organisationError: !data.value.length,
    });
  }

  render() {
    const { organisationError, isOnlyAdmin } = this.state;
    const { toggle, notificationsLoading } = this.props;
    const options = this.getOrganisationOptions();
    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="broadcast-form"
      >
        <AvField
          label="Title"
          className="org-inputs"
          name="title"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: 'Title is required',
            },
            maxLength: {
              value: 100,
            },
          }}
        />
        <AvField
          label="Message"
          className="org-inputs-textarea"
          name="message"
          type="textarea"
          validate={{
            required: {
              value: true,
              errorMessage: 'Message is required',
            },
          }}
        />
        <AvRadioGroup
          value={this.state.userType}
          inline
          name="userType"
          required
          errorMessage="Select one option is required"
        >
          <div className="financial-method-container-admin-broadcast">
            <div className="financial-method-radio">
              <AvRadio
                label="Send to All Organisation"
                value="allOrganisation"
                onChange={() => this.setState({ userType: 'allOrganisation' })}
              />
            </div>
            <div className="financial-method-radio">
              <AvRadio
                label="All System Users"
                value="allUsers"
                onChange={() => this.setState({ userType: 'allUsers' })}
              />
            </div>
            <div className="financial-method-radio">
              <AvRadio
                label="Send to Selected Organisation"
                value="selectedOrganisation"
                onChange={() =>
                  this.setState({ userType: 'selectedOrganisation' })
                }
              />
            </div>
          </div>
        </AvRadioGroup>
        {this.state.userType === 'selectedOrganisation' ? (
          <div>
            <label className="broadcast-dropdown-label">
              Select Organisation
            </label>
            <Dropdown
              fluid
              multiple
              search
              selection
              options={options}
              onChange={(event, data) => this.onChangeOrganisation(event, data)}
              error={organisationError}
              className="broadcast-dropdown"
            />
            {organisationError ? (
              <div className="invalid-dropdown-input">
                Select organisation is required
              </div>
            ) : null}
          </div>
        ) : null}

        {(this.state.userType === 'allOrganisation' ||
          this.state.userType === 'selectedOrganisation') && (
          <>
            <AvCheckboxGroup
              name="onlyAdmin"
              className="mt-4 onlyAdminCheckbox"
            >
              <AvCheckbox
                label="Send to Only Admins"
                checked={isOnlyAdmin}
                onChange={(e) => this.onlyAdminToggel()}
              />
            </AvCheckboxGroup>
          </>
        )}
        <br />

        <div className="activity-model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            content="Send"
            type="submit"
            className="Primary-Button"
            loading={notificationsLoading.broadcast}
            disabled={notificationsLoading.broadcast}
          />
        </div>
      </AvForm>
    );
  }
}

class CreateNotification extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    return (
      <div className="add-goal">
        <Modal
          trigger={
            <Button className="Primary-Button">
              <div className="add-icon-activity-add">
                <Icon name="plus circle" />
                <div className="add-text">Broadcast Message</div>
              </div>
            </Button>
          }
          size="small"
          open={modal}
          onOpen={() => this.toggle()}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
        >
          <Modal.Header>Broadcast New Message</Modal.Header>
          <Modal.Content>
            <NotificationForm {...this.props} toggle={() => this.toggle()} />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class ViewNotification extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { records } = this.props;
    return (
      <Modal
        trigger={<label className="org-checklist-actions-warn">View</label>}
        size="small"
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        className="view-broadcast-message"
      >
        <Modal.Header>View Broadcast Message</Modal.Header>
        <Modal.Content className="pre-line">
          {get(records, 'message')}
        </Modal.Content>
      </Modal>
    );
  }
}

const EvaluationGroup = (props) => {
  const {
    item: { organisation },
  } = props;
  return <div className="objective-eval">{organisation.name}</div>;
};

class Broadcast extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
    };
  }

  resetPage() {
    this.setState({
      currentPage: 1,
    });
  }

  componentDidMount() {
    const { getBroadcastNotifications, getAllOrganisationList } = this.props;
    getBroadcastNotifications();
    getAllOrganisationList();
  }

  columns() {
    return [
      {
        title: 'Title',
        render: (data) => {
          return (
            <label className="org-checklist-text">
              {get(data, 'title', '-')}
            </label>
          );
        },
      },
      {
        title: 'Organisation',
        width: 5,
        render: (data) => {
          return get(data, 'isAllSystemUsers') ? (
            <Label color="orange" horizontal className="label-padding">
              All System Users
            </Label>
          ) : get(data, 'isAllOrganisation') ? (
            <Label color="yellow" horizontal className="label-padding">
              All Organisations
            </Label>
          ) : (
            <>
              {get(data, 'organisationMessages', []).length &&
              get(data, 'organisationMessages', []).length > 3 ? (
                <SeeMore
                  minCount={3}
                  dataSet={get(data, 'organisationMessages')}
                  CustomComponent={EvaluationGroup}
                />
              ) : (
                map(get(data, 'organisationMessages'), (org) => {
                  return (
                    <div className="objective-eval">
                      {org.organisation.name}
                    </div>
                  );
                })
              )}
            </>
          );
        },
      },
      {
        title: 'Sender',
        render: (data) => {
          return (
            <label className="org-checklist-text">
              {get(data, 'sender.firstName', '-')} {get(data, 'sender.surname')}
            </label>
          );
        },
      },
      {
        title: 'Created At',
        render: (data) => {
          return (
            <label className="org-checklist-text">
              {get(data, 'createdAt')
                ? moment(get(data, 'createdAt')).format('D MMMM YYYY, hh:mm A')
                : '-'}
            </label>
          );
        },
      },
      {
        title: 'Action',
        render: (data) => {
          return (
            <div className="org-checklist-actions">
              <ViewNotification {...this.props} records={data} />
            </div>
          );
        },
      },
    ];
  }

  async broadcastPageChange(page) {
    const { getBroadcastNotifications } = this.props;
    await this.setState({ currentPage: page });
    getBroadcastNotifications(page);
  }

  render() {
    const { currentPage } = this.state;
    const { notificationsLoading, broadcastMessage } = this.props;
    return (
      <Segment
        className="acc-users-communication"
        loading={notificationsLoading.broadcast}
      >
        <div>
          <div className="outcome-admin-padded">
            <div className="button-outcome">
              <CreateNotification
                {...this.props}
                resetPage={() => this.resetPage()}
              />
            </div>
          </div>
        </div>
        <>
          {get(broadcastMessage, 'docs') && broadcastMessage.docs.length ? (
            <CustomTable
              header
              columns={this.columns()}
              data={broadcastMessage.docs}
              customClass="super-admin-table tbody-hover"
              pagination
              handlePaginationChange={(page) => this.broadcastPageChange(page)}
              page={currentPage}
              noOfPages={broadcastMessage.pages}
              keyExtractor={(item, index) => item.id}
            />
          ) : (
            <EmptyContainer msg="No notification found…" />
          )}
        </>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  const { organization, authentication, user } = state;
  return {
    user: authentication.user,
    notificationsLoading: user.notificationsLoading,
    broadcastMessage: user.broadcastMessage,
    organization: organization.organization,
    allOrganisation: organization.allOrganizations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getBroadcastNotifications,
      getAllOrganisationList,
      createBroadcastNotifications,
    },
    dispatch
  );
};

Broadcast = connect(mapStateToProps, mapDispatchToProps)(Broadcast);

export { Broadcast };
