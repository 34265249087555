/* eslint-disable eqeqeq */
import React from 'react';
import greenRight from '../assets/green-right.svg';
import profileIcon from '../assets/right-content-profile.svg';

export class SignInContent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="sign-in">
        <div className="container">
          <label className={'leftSideContent'}>
            <span className={'orangeText'}> Planning</span> and evaluation made
            simple... and the results are powerful
          </label>

          <div className={'middleContent'}>
            <div className="heading-content">
              <label className="takso-definition">
                What is <span className={'orangeText'}>Takso?</span>
              </label>
            </div>
            <img alt="greenRight" src={greenRight} />{' '}
            <label className={'list'}>
              <span className="bold-text">A productivity tool: </span>
              it saves time on planning and reporting activities
            </label>
            <br></br>
            <img alt="greenRight" src={greenRight} />{' '}
            <label className={'list'}>
              <span className="bold-text">A learning tool:</span> it enhances
              our knowledge as artists and producers
            </label>
            <br></br>
            <img alt="greenRight" src={greenRight} />{' '}
            <label className={'list'}>
              <span className="bold-text">An advocacy tool:</span> it provides
              the evidence for further investment
            </label>
            <br></br>
            <img alt="greenRight" src={greenRight} />{' '}
            <label className={'list'}>
              <span className="bold-text">An innovation tool:</span> it directs
              us towards new ways of thinking
            </label>
            <br></br>
            <img alt="greenRight" src={greenRight} />{' '}
            <label className={'list'}>
              <span className="bold-text">An evaluation tool:</span> it guides
              consistent planning for evaluation
            </label>
            <br></br>
            <img alt="greenRight" src={greenRight} />{' '}
            <label className={'list'}>
              <span className="bold-text">A bank of ideas:</span> it is a
              database of every cultural activity imaginable
            </label>
            <br></br>
            <img alt="greenRight" src={greenRight} />{' '}
            <label className={'list'}>
              <span className="bold-text">Is easy to use:</span> it is on-line
              and uses intuitive human processes
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export class SignUpContent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="container">
          <label className={'leftSideContent'}>
            Good news! <label className={'orangeText'}>Takso </label> could be a
            great fit for your business and people all over the world trust us.
          </label>
          <div className={'middleContent'}>
            <img alt="profileImg" className={'profileImg'} src={profileIcon} />{' '}
            <br></br>
            <label className={'quoteText'}>
              “I think what’s great about Intercom is that every message feels
              immediate and personal, allowing us to scale while staying
              authentic.”
            </label>
            <br></br>
            <label className={'quoteName'}>Chanie Hyde</label>
            <br></br>
            <label className={'quoteNameSub'}>Beta Launch</label>
          </div>
        </div>
      </div>
    );
  }
}
