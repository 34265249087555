import './activity.scss';

import React from 'react';

import {
  AvCheckbox,
  AvCheckboxGroup,
  AvField,
  AvForm,
} from 'availity-reactstrap-validation';
import moment from 'moment';
import { connect } from 'react-redux';
import Alert from 'reactstrap/lib/Alert';
import { bindActionCreators } from 'redux';
import {
  Button,
  Form,
  Grid,
  Icon,
  Modal,
  Search,
  Segment,
} from 'semantic-ui-react';
import ShowMoreText from 'react-show-more-text';

import { CustomTable, CustomTooltip } from '../../../components';
import {
  cloneDeep,
  debounce,
  difference,
  find,
  findIndex,
  forEach,
  get,
  isEmpty,
  map,
} from '../../../utils/lodash';
import {
  createProjectEvidence,
  deleteObjectiveEvidence,
  getEvidenceSearch,
  getEvidenceTypes,
  updateProjectEvidence,
} from '../../../store/actions';
import { getPageDisable, threeDotSeparator } from '../../../utils';
import { useDispatch } from 'react-redux';
import {
  evidenceInitialValues,
  evidenceValidationSchema,
} from '../../../validations/evidenceValidation';
import { Formik } from 'formik';
import { CustomDropdown, CustomTextArea } from '../../../components/form';
import { CheckboxComp } from '../../../components/form/checkboxes/checkBoxGroup';

const sourceFormat = {
  0: '',
  1: 'Enter either a name or position title, the current year, the activity title and source of information such as participant evaluation, personal observation, internal organisation report etc',
  2: 'Enter the surname and initial of the author/s or name of organisation, year of publication, title, source such as organisation or journal name/title and page number if applicable.',
  3: 'Source such as name of organisation, date, title of publication or event.',
};

const EvidenceForm = (props) => {
  const dispatch = useDispatch();
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState([]);
  const [evidenceType, setEvidenceType] = React.useState([]);
  const [evidenceMessage, setEvidenceMessage] = React.useState('');

  React.useEffect(() => {
    const { evidence, helpTips } = props;

    let helpMessage = '';
    if (evidence) {
      helpMessage = find(helpTips, {
        title: get(evidence, 'evidenceTypeData.evidenceType'),
      });
    }
    // this.setState({
    //   evidenceType: evidence ? evidence.evidenceType : 0,
    //   evidenceMessage: helpMessage ? helpMessage.content : '',
    // });

    setEvidenceType(evidence ? evidence.evidenceType : 0);
    setEvidenceMessage(helpMessage ? helpMessage.content : '');

    setEvidence();
  }, []);

  React.useEffect(() => {
    if (props.evidence) {
      setEvidence();
    }
  }, [props.evidence]);

  const setEvidence = async () => {
    const { evidence } = props;
    const { currentActivity } = props.activity;

    let defaultEvidence = [];
    let options = [];
    if (evidence && evidence.objective) {
      defaultEvidence = map(evidence.objective, (item) => {
        return item.id;
      });
    }
    if (currentActivity.objective) {
      options = map(currentActivity.objective, (item, i) => {
        return {
          key: item.id,
          text: `${item.outcome.title}`,
          label: `${item.outcome.title}`,
          value: item.id,
        };
      });
    }

    setOptions(options);
    setValue(defaultEvidence);
  };

  async function handleValidSubmit(values) {
    try {
      const { isNew, evidence, activity, toggle } = props;
      if (activity.currentActivity.objective) {
        const objective = values.linkedObjectives;

        const isDelete = difference(value, objective);
        const isAdded = difference(objective, value);
        if (values.linkedObjectives) delete values.linkedObjectives;
        let dataSet = {
          ...values,
          projectId: activity.currentActivity.id,
        };
        if (isNew) {
          if (!isEmpty(isAdded)) {
            dataSet.linkedObjectives = isAdded;
          }

          dispatch(createProjectEvidence('evidence', dataSet));
        } else {
          values.id = evidence.id;
          if (!isEmpty(isAdded)) {
            dataSet.assignObjectives = isAdded;
          }

          if (!isEmpty(isDelete)) {
            dataSet.unAssignObjectives = isDelete;
          }

          dispatch(
            updateProjectEvidence(
              'evidence',
              activity.currentActivity.id,
              evidence.id,
              dataSet
            )
          );
        }
      }

      toggle();
    } catch (error) {
      console.error('EvidenceForm :: handleValidSubmit -> error', error);
    }
  }

  function handleEvidenceType(evidenceType) {
    let extraEvidenceType = find(extra.evidenceTypes, {
      id: parseInt(evidenceType),
    });

    let helpMessage = find(helpTips, {
      title: extraEvidenceType.evidenceType,
    });

    setEvidenceMessage(helpMessage.content);
    setEvidenceType(evidenceType);
  }

  const {
    evidence,
    extra,
    auth,
    helpTips,
    activity,
    isNew,
    disabled,
    isOwned,
    isReadOnly,
  } = props;

  let evidenceNew = cloneDeep(evidence);

  if (!isNew) {
    let linkedObjectives = [];
    for (const item of evidence.objective) {
      linkedObjectives.push(item.id);
    }

    evidenceNew.linkedObjectives = linkedObjectives;
  }

  const evidenceTypes = extra.evidenceTypes.map((item, key) => ({
    key,
    value: item.id,
    text: item.evidenceType,
  }));

  return (
    <Formik
      initialValues={evidenceInitialValues(isNew ? evidence : evidenceNew)}
      validationSchema={evidenceValidationSchema()}
      onSubmit={handleValidSubmit}
    >
      {({ handleSubmit, isSubmitting, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <div className="my-2">
            <CustomDropdown
              selection
              name="evidenceType"
              id="evidenceType"
              labelText="Select evidence type"
              options={evidenceTypes}
              getValue={(value) => {
                handleEvidenceType(value);
              }}
              placeholder="Select evidence type"
              className="request-approval-dropdown"
              disabled={disabled}
            />
          </div>

          <div>{evidenceMessage}</div>

          <div className="my-2">
            <CustomTextArea
              id="content"
              name="content"
              placeholder="What is relevant about the evidence? (maximum 2000 characters)"
              labelText={
                <CustomTooltip
                  state={get(auth, 'user.options.tips')}
                  contentObject={{ data: helpTips, key: '29' }}
                  position="top left"
                  wide="very"
                >
                  <span>Content</span>
                </CustomTooltip>
              }
              // requiredStar
              disabled={disabled}
            />
          </div>
          <div className="my-2">
            <CustomTextArea
              id="source"
              name="source"
              placeholder="What is relevant about the evidence? (maximum 2000 characters)"
              labelText={
                <CustomTooltip
                  state={get(auth, 'user.options.tips')}
                  contentObject={{ data: helpTips, key: '30' }}
                  position="top left"
                  wide="very"
                >
                  <span>Source</span>
                </CustomTooltip>
              }
              // requiredStar
              disabled={disabled}
            />
          </div>

          {!isOwned ? (
            <div className="ui negative message mb-30">
              Evidence from the Takso library cannot be edited.
            </div>
          ) : null}

          <CheckboxComp
            labelText={'Select Objective'}
            options={options}
            id="linkedObjectives"
            name="linkedObjectives"
            setFieldValue={setFieldValue}
            disabled={!isReadOnly && !isOwned ? false : disabled}
          />

          <div className="model-btn-align-right">
            <Button
              content={isNew ? 'Create' : 'Update'}
              type="submit"
              className="Primary-Button"
              disabled={disabled || isSubmitting}
              loading={isSubmitting}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

class EvidenceViewForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      value: [],
    };
  }

  componentDidMount() {
    this.setEvidence();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.evidence !== this.props.evidence) {
      this.setEvidence();
    }
  }

  async setEvidence() {
    const { evidence } = this.props;
    const { currentActivity } = this.props.activity;

    let defaultEvidence = [];
    let options = [];
    if (evidence.objective) {
      defaultEvidence = map(evidence.objective, (item) => {
        return item.id;
      });
    }
    if (currentActivity.objective) {
      options = map(currentActivity.objective, (item, i) => {
        return {
          key: item.id,
          text: `${item.outcome.title}`,
          value: item.id,
        };
      });
    }
    await this.setState({
      options: options,
      value: defaultEvidence,
    });
  }

  handleSearchChange = async (event, errors, data) => {
    const { updateProjectEvidence, activity, evidence, toggle } = this.props;
    try {
      if (activity.currentActivity.objective) {
        const objective = data.linkedObjectives;
        const value = cloneDeep(this.state.value);
        const isDelete = difference(value, objective);
        const isAdded = difference(objective, value);
        let dataSet = {
          projectId: activity.currentActivity.id,
        };

        if (!isEmpty(isAdded)) {
          dataSet.assignObjectives = isAdded;
        }

        if (!isEmpty(isDelete)) {
          dataSet.unAssignObjectives = isDelete;
        }

        updateProjectEvidence(
          'evidence',
          activity.currentActivity.id,
          evidence.id,
          dataSet
        );
      }

      toggle();
    } catch (error) {
      console.error('EvidenceViewForm :: handleSearchChange -> error', error);
    }
  };

  render() {
    const { options } = this.state;
    const { evidence, extra } = this.props;
    const type = find(extra.evidenceTypes, {
      id: evidence.evidenceType,
    });
    const defaultValues = {
      linkedObjectives: map(evidence.objective, (item) => {
        return item.id;
      }),
    };
    return (
      <div>
        <div className="evidence-box ">
          <div className="evidence-title">Evidence Type</div>
          <label className="library-evidence-description">
            {type ? type.evidenceType : ''}
          </label>
        </div>

        <div className="evidence-box ">
          <div className="evidence-title">Content</div>
          <label className="library-evidence-description">
            {evidence.content}
          </label>
        </div>

        <div className="evidence-box ">
          <div className="evidence-title">Source</div>
          <label className="library-evidence-description">
            {evidence.source}
          </label>
        </div>

        <div className="evidence-box ">
          <div className="evidence-title">Date Added</div>
          <label className="library-evidence-description">
            {moment(evidence.addedDateTime).format('DD MMM YYYY')}
          </label>
        </div>

        <AvForm
          className="evidence-view-form"
          onSubmit={this.handleSearchChange}
          model={defaultValues}
          ref={(c) => (this.form = c)}
        >
          <AvCheckboxGroup
            className="form-title evidence-search-checkbox"
            name="linkedObjectives"
            label={
              <div className="evidence-objective-checkbox-group-label">
                Select Objective
              </div>
            }
          >
            {map(options, (item) => {
              return (
                <AvCheckbox
                  key={item.key}
                  label={
                    <div className="evidence-objective-checkbox-label">
                      {item.text}
                    </div>
                  }
                  value={item.value}
                />
              );
            })}
          </AvCheckboxGroup>

          <div className="model-btn-align-right">
            <Button
              content="Add"
              type="submit"
              className="evidence-save-button"
            />
          </div>
        </AvForm>
      </div>
    );
  }
}

class EvidenceDelete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async deleteOrgContractorHandle() {
    try {
      const { deleteObjectiveEvidence, evidence, activity } = this.props;
      deleteObjectiveEvidence(
        'evidence',
        activity.currentActivity.id,
        evidence.id
      );
      this.toggle();
    } catch (error) {
      console.error(
        'EvidenceDelete :: deleteOrgContractorHandle -> error',
        error
      );
    }
  }

  render() {
    const { modal } = this.state;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        onClose={() => this.toggle()}
        size="mini"
        trigger={<div className="remove-evidence">Remove</div>}
      >
        <Modal.Header>Remove Evidence</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to remove this evidence?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.toggle()} className="modal-cancel">
            No
          </Button>
          <Button
            onClick={() => this.deleteOrgContractorHandle()}
            className="modal-proceed"
            content="Yes"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

class EvidenceEdit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { evidence, activity, isReadOnly } = this.props;

    let isOwned = false;
    if (evidence.createdByProjectId === activity.currentActivity.id) {
      isOwned = true;
    }

    let disabled = false;
    if (!isOwned || isReadOnly) {
      disabled = true;
    }

    return (
      <Modal
        trigger={
          <div className="label-container" onClick={() => this.toggle()}>
            {disabled ? 'View Evidence' : 'Edit Evidence'}
          </div>
        }
        size="small"
        open={modal}
      >
        <Modal.Header className="goal-confirm-modal-header">
          <div className="modal-heading">
            {' '}
            {disabled ? 'View Evidence' : 'Edit Evidence'}
          </div>
          <div className="slim-close-icon" onClick={() => this.toggle()}>
            &times;
          </div>
        </Modal.Header>

        <Modal.Content>
          <EvidenceForm
            toggle={() => this.toggle()}
            isNew={false}
            disabled={disabled}
            isReadOnly={isReadOnly}
            isOwned={isOwned}
            {...this.props}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

class EvidenceCreate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { auth, helpTips } = this.props;
    return (
      <div>
        <Modal
          trigger={
            <div>
              <CustomTooltip
                state={get(auth, 'user.options.tips')}
                contentObject={{ data: helpTips, key: '24' }}
                position="bottom left"
                wide="very"
              >
                <div
                  onClick={() => this.toggle()}
                  className="Secondary-Button "
                >
                  <Icon name="plus" />
                  Add New Evidence
                </div>
              </CustomTooltip>
            </div>
          }
          size="small"
          open={modal}
          className="create-new-evidence"
        >
          <Modal.Header className="goal-confirm-modal-header">
            <div className="modal-heading">Create New Evidence</div>
            <div className="slim-close-icon" onClick={() => this.toggle()}>
              &times;
            </div>
          </Modal.Header>
          <Modal.Content>
            <EvidenceForm
              toggle={() => this.toggle()}
              isNew={true}
              isOwned={true}
              {...this.props}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class EvidenceAssign extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { evidence } = this.props;
    return (
      <div className="evidence-container">
        {map(evidence.objective, (item, i) => {
          return (
            <label
              className="evidence-objective-label"
              key={i}
            >{`${item.outcome.title}`}</label>
          );
        })}
      </div>
    );
  }
}

class ActivityEvidence extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      results: [],
      value: '',
      evidence: {},
      modal: false,
      onlyOwnEvidence: false,
    };
  }

  columns() {
    const {
      extra: { evidenceTypes },
      currentState,
      auth,
      activity,
    } = this.props;
    const { currentActivity } = activity;
    let disabled = getPageDisable(currentActivity, auth.user, currentState);

    return [
      {
        title: 'Name',
        width: 5,
        render: (data) => {
          // const type = find(evidenceTypes, {
          //   id: data.evidenceType
          // });

          return (
            <div>
              <label className="evidence-mainText">
                {data.content && data.content.length > 50
                  ? `${data.content.substring(0, 50)}...`
                  : data.content || '-'}
              </label>
            </div>
          );
        },
      },
      {
        title: 'Type',
        width: 3,
        render: (data) => {
          const type = find(evidenceTypes, {
            id: data.evidenceType,
          });

          return (
            <div>
              <label className="evidence-mainText">
                {type && type.evidenceType ? `${type.evidenceType}` : '-'}
              </label>
            </div>
          );
        },
      },
      {
        title: 'Objective',
        width: 6,
        render: (data) => {
          return (
            <div className="objective-container-evidence">
              <EvidenceAssign key={data.id} evidence={data} {...this.props} />
            </div>
          );
        },
        with: '500',
      },
      {
        title: '',
        width: 2,
        render: (data) => {
          return (
            <div>
              <Segment>
                <EvidenceEdit
                  evidence={data}
                  {...this.props}
                  isReadOnly={disabled}
                />
              </Segment>
            </div>
          );
        },
      },
      {
        title: '',
        width: 1,
        render: (data) => {
          return (
            <div>
              <Segment disabled={disabled} className="evidence-remove-button">
                <EvidenceDelete evidence={data} {...this.props} />
              </Segment>
            </div>
          );
        },
      },
    ];
  }

  componentWillMount() {
    this.resetComponent();
  }

  resetComponent() {
    this.setState({
      isLoading: false,
      results: [],
      value: '',
    });
  }

  handleResultSelect = (e, { result }) => {
    this.setState({
      evidence: result,
      modal: true,
    });
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ value }, async () => {
      if (this.state.value.length < 1) return this.resetComponent();

      const { getEvidenceSearch } = this.props;
      const { onlyOwnEvidence } = this.state;
      await getEvidenceSearch({ searchQuery: value, onlyOwnEvidence });
    });
  };

  createEvidenceList() {
    const { currentActivity } = this.props.activity;
    if (currentActivity.objective) {
      const result = [];

      forEach(cloneDeep(currentActivity.objective), (objective, i) => {
        forEach(objective.evidence, (evidence) => {
          const index = findIndex(result, { id: evidence.id });
          if (index > -1) {
            result[index].objective.push(objective);
          } else {
            evidence.objective = [objective];
            result.push(evidence);
          }
        });
      });
      return result;
    }
    return [];
  }

  noGoal() {
    return <Alert color="warning">Please select an goal</Alert>;
  }

  noObjective() {
    return <Alert color="warning">Please select an objective</Alert>;
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  onChangeFilter(check) {
    if (check) {
      this.setState({
        onlyOwnEvidence: true,
      });
    } else {
      this.setState({
        onlyOwnEvidence: false,
      });
    }
  }

  async onBlurFilter() {
    const { getEvidenceSearch } = this.props;
    const { onlyOwnEvidence, value } = this.state;
    await getEvidenceSearch({
      searchQuery: value || '',
      onlyOwnEvidence,
    });
  }

  render() {
    const { value } = this.state;

    const { auth, helpTips, activity, currentState } = this.props;

    const { evidenceLoading, evidenceSearch, currentActivity } = activity;

    const evidence = this.createEvidenceList();
    let objective = currentActivity.objective || [];
    const form = {
      goal: currentActivity.goal ? currentActivity.goal : this.noGoal(),
      description: currentActivity.goalDescription
        ? currentActivity.goalDescription
        : '-',
      objectiveOne: objective[0]
        ? objective[0].outcome
          ? objective[0].outcome.title
          : this.noObjective()
        : this.noObjective(),
      objectiveTwo: objective[1]
        ? objective[1].outcome
          ? objective[1].outcome.title
          : this.noObjective()
        : this.noObjective(),
    };

    return (
      <>
        <Segment
          className="content-segment objective"
          disabled={getPageDisable(currentActivity, auth.user, currentState)}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <div className="form-group-evidence-margin-left">
                  <label className="goal-label">Goal</label>

                  <label className="goal-title-single-evidence">
                    {form.goal}
                  </label>
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <div className="form-group-evidence-margin-right">
                  <label className="goal-label">Goal Description</label>
                  <ShowMoreText
                    lines={3}
                    more="Read More"
                    less="Read Less"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    truncatedEndingComponent={'... '}
                    className="pre-line"
                  >
                    <label>{form.description}</label>
                  </ShowMoreText>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="objective-row">
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <div className="form-group-evidence-margin-left">
                  <label className="goal-label">
                    Primary objective -{' '}
                    {`${
                      currentActivity.primaryPolicy
                        ? currentActivity.primaryPolicy.policyName
                        : ''
                    }`}
                  </label>

                  <label className="goal-title-single-evidence">
                    {form.objectiveOne}
                  </label>
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                {!currentActivity.secondaryPolicyId ? null : (
                  <div className="form-group-evidence-margin-right">
                    <label className="goal-label">
                      Secondary objective -{' '}
                      {`${
                        currentActivity.secondaryPolicy
                          ? currentActivity.secondaryPolicy.policyName
                          : ''
                      }`}
                    </label>
                    <label className="goal-title-single-evidence">
                      {form.objectiveTwo}
                    </label>
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment
          disabled={getPageDisable(currentActivity, auth.user, currentState)}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={10}>
                <div className="evidence-t">Evidence</div>

                {/* evidence manage */}

                <div className="search-inline-single-evidence">
                  <CustomTooltip
                    state={get(auth, 'user.options.tips')}
                    contentObject={{
                      data: helpTips,
                      key: '23',
                    }}
                    position="top left"
                    on="hover"
                    wide="very"
                  >
                    <Search
                      disabled={getPageDisable(
                        currentActivity,
                        auth.user,
                        currentState
                      )}
                      loading={evidenceLoading}
                      onResultSelect={this.handleResultSelect}
                      onSearchChange={debounce(this.handleSearchChange, 2000, {
                        leading: true,
                      })}
                      results={evidenceSearch}
                      value={value}
                      placeholder="Search for Evidence"
                      resultRenderer={({ content, source, addedDateTime }) => (
                        <div>
                          <div className="evidence-search-result">
                            <label className="evidence-header">
                              <b>
                                {threeDotSeparator({
                                  name: content,
                                  length: 45,
                                  isPopUp: false,
                                })}
                              </b>
                            </label>

                            <div className="info">
                              <label>
                                <strong>Source: </strong>
                                {threeDotSeparator({
                                  name: source,
                                  length: 40,
                                  isPopUp: false,
                                })}
                              </label>

                              <label className="evidence-date">
                                <strong>Date: </strong>
                                {addedDateTime
                                  ? moment(addedDateTime).format('DD MMM YYYY')
                                  : '-'}
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      className="evidenceSearch search-custom-height"
                    />
                  </CustomTooltip>
                  <div className="restrict-container">
                    <div className="checkbox-restrict">
                      <input
                        type="checkbox"
                        name="checkbox"
                        onChange={(e) => this.onChangeFilter(e.target.checked)}
                        onBlur={(e) => this.onBlurFilter()}
                      />
                    </div>
                    <div className="restrict-label">
                      Restrict to my evidence
                    </div>
                  </div>
                </div>

                <div>
                  <Modal
                    size="small"
                    open={this.state.modal}
                    className="library-evidence"
                  >
                    <Modal.Header className="goal-confirm-modal-header">
                      <div className="modal-heading"> Library Evidence</div>
                      <div
                        className="slim-close-icon"
                        onClick={() => this.toggle()}
                      >
                        &times;
                      </div>
                    </Modal.Header>
                    <Modal.Content>
                      {this.state.modal ? (
                        <EvidenceViewForm
                          toggle={() => this.toggle()}
                          evidence={this.state.evidence}
                          {...this.props}
                        />
                      ) : null}
                    </Modal.Content>
                  </Modal>
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={6}>
                <div className="add-evidence">
                  <EvidenceCreate {...this.props} />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment>
          <CustomTable
            header
            columns={this.columns()}
            data={evidence}
            height={true}
            emptyMessage="No evidence has been added"
            customClass="evidence-table-new"
            keyExtractor={(item, index) => {
              return item.evidenceId;
            }}
          />
        </Segment>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    activity: state.activity,
    user: state.user,
    extra: state.extra,
    helpTips: state.extra.activityHelpTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getEvidenceTypes,
      getEvidenceSearch,
      createProjectEvidence,
      updateProjectEvidence,
      deleteObjectiveEvidence,
    },
    dispatch
  );
};

ActivityEvidence = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityEvidence);

export { ActivityEvidence };
