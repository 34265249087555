/* eslint-disable eqeqeq */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Segment, Grid, Dropdown, Icon } from 'semantic-ui-react';
import {
  getProgramIncludedReportCharts,
  getProgrammeReportData,
  getOrgVenuesTypes,
  getOrganizationGoals,
  setProgrammeOutcomeData,
  setOutcomePage,
  setSidebarType,
} from '../../../store/actions';
import '../../reports/reportHome/report.scss';
import { OutcomesSummary, InputOutputReport } from '../../reports/reportHome';
import { FeatureWrapper, UnAuthorizedAccess } from '../../../components';
import { OutcomeChart } from '../../../components/chartsWidgets';
import { ProgrammeActivity } from '../../../containers/reports/reportHome/Program/ProgrammeActivity';
import { ProgrammeOutcomeAddress } from '../../../containers/reports/reportHome/Program/ProgrammeOutcomeAddress';
import { get } from '../../../utils/lodash';
import { SIDEBAR_TYPES } from '../../../utils/constant';

const reportTypes = [
  {
    key: 1,
    text: 'Activities',
    value: 'activities',
  },
  {
    key: 2,
    text: 'Outcomes',
    value: 'outcomes-addressed',
  },
  {
    key: 3,
    text: 'Outcomes Reported',
    value: 'outcomes-reported',
  },
  {
    key: 4,
    text: 'Inputs & Outputs',
    value: 'input-output-data',
  },
];

class AggregateReports extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      reportType: 'activities',
      outcomeType: undefined,
      methodType: 'all',
      chartType: undefined,
      outcomeError: false,
    };
  }

  async componentDidMount() {
    let {
      getProgramIncludedReportCharts,
      currentActivity,
      getProgrammeReportData,
      getOrgVenuesTypes,
    } = this.props;
    await getProgrammeReportData(
      this.state.reportType,
      this.props.currentActivity.id
    );
    await Promise.all([
      getProgrammeReportData(
        this.state.reportType,
        this.props.currentActivity.id
      ),
      getProgramIncludedReportCharts(currentActivity.id),
      getOrgVenuesTypes(),
    ]);
  }

  componentDidUpdate(prevProps) {
    if (!this.state.outcomeType) {
      this.setState({
        outcomeError: true,
      });
    } else {
      this.setState({
        outcomeError: false,
      });
    }
  }

  onTypeChange = async (e, { value }) => {
    this.setState(
      {
        reportType: value,
        outcomeType: undefined,
        methodType: 'all',
        chartType: undefined,
      },
      () => {
        this.props.getProgrammeReportData(value, this.props.currentActivity.id);
      }
    );
    this.props.setReportType(value);
  };

  onBackToOutcome = async (e, { value }) => {
    this.setState(
      {
        reportType: value,
        outcomeType: undefined,
        methodType: 'all',
        chartType: undefined,
      },
      () => {
        this.props.getProgrammeReportData(
          value,
          this.props.currentActivity.id,
          {
            outcomes: [],
          }
        );
      }
    );
    this.props.setReportType(value);
    this.props.setOutcomePage(!this.props.isOutcomeReportedPage);
  };

  selectOutcomes(value) {
    this.setState(
      {
        reportType: value,
        outcomeType: undefined,
        methodType: 'all',
        chartType: undefined,
      },
      () => {
        this.props.getProgrammeReportData(value, this.props.currentActivity.id);
      }
    );
    this.props.setReportType(value);
  }

  setOutcomeValue(reportType, id, selectedOutcome) {
    this.props.setProgrammeOutcomeData(reportType, selectedOutcome);
    this.props.setOutcomePage(!this.props.isOutcomeReportedPage);
  }

  onOutComeChange = async (e, { value }) => {
    let { getProgramIncludedReportCharts, currentActivity } = this.props;
    this.setState({
      outcomeType: value,
    });
    getProgramIncludedReportCharts(currentActivity.id);
  };

  setChartType(value) {
    this.setState({
      chartType: value,
    });
  }

  onMethodChange = (e, { value }) => {
    this.setState({
      methodType: value,
    });
  };

  participantsOutcomes() {
    const { participantsOutcomes } = this.props;
    const outcomes = participantsOutcomes.map((outcome, i) => {
      return { key: i, text: outcome, value: outcome };
    });
    return outcomes;
  }

  methodType() {
    const { methods } = this.props;
    const optionAll = [{ key: '', text: 'All', value: 'all' }];
    const method = methods.map((method, i) => {
      return { key: i, text: method.details, value: method.id };
    });
    return optionAll.concat(method);
  }

  // render program report
  renderReportType(reportType) {
    const { outcomeType, methodType, chartType, dateRangeChosenLabel } =
      this.state;
    const { user, currentActivity, outcomes, programIncludedReports } =
      this.props;

    switch (reportType) {
      case 'activities':
        return (
          <FeatureWrapper
            featureName="report_activity_summary"
            children={
              <ProgrammeActivity
                parent
                dateRangeChosenLabel={dateRangeChosenLabel}
              />
            }
            fallbackComponent={<UnAuthorizedAccess />}
          />
        );
      case 'outcome-summary':
        return (
          <FeatureWrapper
            featureName="report_outcome_summary"
            children={
              <OutcomesSummary
                currentActivity={currentActivity}
                outcomes={outcomes}
                type={outcomeType}
                method={methodType.toString()}
                chartType={chartType}
                outcome={outcomeType}
                setChartType={(value) => this.setChartType(value)}
                isEnabledIncludeReport={true}
                programIncludedReports={programIncludedReports}
              />
            }
            fallbackComponent={<UnAuthorizedAccess />}
          />
        );
      case 'input-output-data':
        return (
          <FeatureWrapper
            featureName="report_headline_data"
            children={
              <InputOutputReport
                user={user}
                currencyType={get(currentActivity, 'currencyType')}
                parent
              />
            }
            fallbackComponent={<UnAuthorizedAccess />}
          />
        );
      case 'outcomes-reported':
        return (
          <div className="programme-outcomes-report">
            <OutcomeChart
              setVisible={this.props.setVisible}
              parent
              inReportLbl={true}
            />
          </div>
        );
      case 'outcomes-addressed':
        return (
          <FeatureWrapper
            featureName="report_outcome_summary"
            children={
              <ProgrammeOutcomeAddress
                parent
                onTypeChange={(value) => this.selectOutcomes(value)}
                setOutcomeValue={(reportType, id, selectedOutcome) =>
                  this.setOutcomeValue(reportType, id, selectedOutcome)
                }
                currentActivity={currentActivity}
                dateRangeChosenLabel={dateRangeChosenLabel}
                reportType={reportType}
                activityId={currentActivity.id}
              />
            }
            fallbackComponent={<UnAuthorizedAccess />}
          />
        );
      default:
        return (
          <div className="blank-report-div">
            Your report will be previewed here
          </div>
        );
    }
  }

  render() {
    const { reportType } = this.state;
    const updatedReportTypes = reportTypes.filter(
      (item) => item.value !== 'outcomes-reported'
    );

    const reportTypeOptions = reportTypes.map((item) => {
      return {
        ...item,
        disabled: item.value === 'outcomes-reported',
      };
    });

    return (
      <Segment className="content-segment">
        <span className="advanced-filter-align">
          {!this.props.visible && (
            <span
              className="panel-lbl"
              onClick={() => this.props.setSidebarType(SIDEBAR_TYPES[2], true)}
            >
              ADVANCED FILTER
            </span>
          )}
        </span>
        <Grid className={this.props.visible ? '' : 'segment-margin'}>
          <Grid.Row>
            <div className="fin-head">
              <label className="input-label">Report Type</label>
              {reportType === 'outcomes-reported' ? (
                <Dropdown
                  className="report-type"
                  name="reportType"
                  onChange={this.onTypeChange}
                  options={reportTypeOptions}
                  placeholder="Choose an option"
                  selection
                  value={reportType}
                />
              ) : (
                <Dropdown
                  className="report-type"
                  name="reportType"
                  onChange={this.onTypeChange}
                  options={updatedReportTypes}
                  placeholder="Choose an option"
                  selection
                  value={reportType}
                />
              )}
            </div>
          </Grid.Row>
        </Grid>
        <Segment className="chart-holder">
          {reportType === 'outcomes-reported' ? (
            <div className="outcome-address-back">
              <label
                className="view-outcome-back"
                onClick={() =>
                  this.onBackToOutcome(null, { value: 'outcomes-addressed' })
                }
              >
                <Icon
                  name="arrow left"
                  className="outcome-address-back-arrow"
                />
                Back to Outcomes Addressed
              </label>
            </div>
          ) : null}
          {this.renderReportType(reportType)}
        </Segment>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  const { activity, authentication } = state;
  return {
    currentActivity: activity.currentActivity,
    user: authentication.user,
    actLoading: state.report.loading,
    isOutcomeReportedPage: state.report.isOutcomeReportedPage,
    outcomes: state.extra.outcomes,
    methods: state.extra.methods,
    participantsOutcomes: state.report.participantsOutcomes,
    programIncludedReports: state.activity.programIncludedReports,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProgramIncludedReportCharts,
      getProgrammeReportData,
      getOrgVenuesTypes,
      getOrganizationGoals,
      setProgrammeOutcomeData,
      setOutcomePage,
      setSidebarType,
    },
    dispatch
  );
};

AggregateReports = connect(
  mapStateToProps,
  mapDispatchToProps
)(AggregateReports);

export { AggregateReports };
