/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, Fragment } from 'react';
import {
  isEmpty,
  map,
  get,
  find,
  flattenDeep,
  sum,
} from '../../../../utils/lodash';
import '../report.scss';
import { GoogleMap } from '../../../../components/googleMap';
import { Button, Grid, Segment, Loader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { ChartComponent } from '../activitySummary';
import { useDispatch } from 'react-redux';
import { setParentChartIncludeIn } from '../../../../store/actions';
import { CustomSegment, EmptyContainer } from '../../../../components';
import { PROGRAM_REPORT_LOADING } from '../../../../utils/constant';
import { getActivityReportLoadingAttributes } from '../../../../utils/activity';
import { getPageDisable } from '../../../../utils';
import { isFiltersInitState } from '../../../../utils/activity/isFiltersInitState';

export const ProgrammeActivity = ({
  dateRangeChosenLabel,
  parent = false,
  superAdmin = false,
  inParentReportPage = false,
  includeTypeChart = true, // activity type chart
  includeVenuesChart = true, // activity venues chart
  includeParticipantsChart = true, // activity participant chart
  includeActivitiesMap = true, // activities venues map
  includeContractorsMap = true, // activities contractors map
}) => {
  const background = '#E0E0E0';
  const gray = '#757575';
  const black = '#000000';
  const white = '#ffff';

  const [activityType, setActivityType] = useState({
    mainTitle: [],
    dataset: [],
    development: [],
    active: [],
    completed: [],
    styles: {
      color: [],
      weight: [],
      highlight: [],
      size: [],
    },
  });

  const [venueType, setVenueType] = useState({
    mainTitle: [],
    dataset: [],
    development: [],
    active: [],
    completed: [],
    styles: {
      color: [],
      weight: [],
      highlight: [],
      size: [],
    },
  });

  const [participantsType, setParticipantsType] = useState({
    mainTitle: [],
    dataset: [],
    development: [],
    active: [],
    completed: [],
    styles: {
      color: [],
      weight: [],
      highlight: [],
      size: [],
    },
  });

  const currentActivity = useSelector(
    (state) => state.activity.currentActivity
  );

  const user = useSelector((state) => state.authentication.user);
  const reportFilterOptions = useSelector(
    (state) => state.report.reportFilterOptions
  );

  const _isFiltersInitState = isFiltersInitState(reportFilterOptions, [
    'outcome',
    'startDate',
    'endDate',
    'method',
    'renderOutcomeChart',
  ]);

  const isDisabledInReportGrpBtn =
    getPageDisable(currentActivity, user, 'project.edit.documentation') ||
    !_isFiltersInitState;

  const activitiesCharts = get(
    currentActivity,
    'reportCharts.activitiesCharts',
    null
  );
  const [activitiesInReport, setActivitiesInReport] = useState(
    get(activitiesCharts, 'includeTypeChart', false)
  );
  const [venuesInReport, setVenuesInReport] = useState(
    get(activitiesCharts, 'includeVenuesChart', false)
  );
  const [participantsInReport, setParticipantsInReport] = useState(
    get(activitiesCharts, 'includeParticipantsChart', false)
  );

  // const [activitiesMapInReport, setActivitiesMapInReport] = useState(
  //   get(activitiesCharts, 'includeActivitiesMap', false)
  // );

  // const [contractorsMapInReport, setContractorsMapInReport] = useState(
  //   get(activitiesCharts, 'includeContractorsMap', false)
  // );

  const venueChart = useSelector((state) => state.report.venueChart);
  const participantsData = useSelector(
    (state) => state.report.participantsData
  );

  const actSummary = useSelector((state) => state.report.actSummary);
  const outcomes = get(actSummary, 'outcomes', {});

  const stats = useSelector((state) => state.report.activityStats);

  //loaders
  const reportInitialState = useSelector((state) => state.report);
  const loadingValues = getActivityReportLoadingAttributes(
    PROGRAM_REPORT_LOADING,
    reportInitialState
  );
  const {
    activityStatsLoading,
    activityReportLoading,
    venueReportLoading,
    participantLoading,
    // organisationMapLoading, // commented out
    activityMapLoading,
    contractorMapLoading,
  } = loadingValues;

  const statuses = useSelector(
    (state) => state.report.reportFilterOptions.statuses
  );

  const report = useSelector((state) => state.report);

  const appendByStatusValue = (value, type, setArrayType) => {
    value.data.byStatus[type].map((data, i) =>
      setArrayType((prevState) => ({
        ...prevState,
        [type]: [...prevState[type], data],
      }))
    );
  };

  const makeChartObject = (chartType, setArrayType) => {
    if (!isEmpty(chartType)) {
      chartType.map((chartTitle) => {
        setArrayType((prevState) => ({
          ...prevState,
          mainTitle: [...prevState.mainTitle, chartTitle.mainTitle],
          dataset: [...prevState.dataset, null],
          styles: {
            size: [...prevState.styles.size, 16],
            color: [...prevState.styles.color, gray],
            weight: [...prevState.styles.weight, '600'],
            highlight: [...prevState.styles.highlight, background],
          },
        }));
        if (statuses.includes(1))
          setArrayType((prevState) => ({
            ...prevState,
            development: [...prevState.development, null],
          }));
        if (statuses.includes(2))
          setArrayType((prevState) => ({
            ...prevState,
            active: [...prevState.active, null],
          }));
        if (statuses.includes(3))
          setArrayType((prevState) => ({
            ...prevState,
            completed: [...prevState.completed, null],
          }));

        chartTitle.data.label.map((label, i) =>
          setArrayType((prevState) => ({
            ...prevState,
            mainTitle: [...prevState.mainTitle, label],
            styles: {
              size: [...prevState.styles.size, 14],
              color: [...prevState.styles.color, black],
              weight: [...prevState.styles.weight, '400'],
              highlight: [...prevState.styles.highlight, white],
            },
          }))
        );

        chartTitle.data.dataset.map((data, i) =>
          setArrayType((prevState) => ({
            ...prevState,
            dataset: [...prevState.dataset, data],
          }))
        );

        if (statuses.includes(1))
          appendByStatusValue(chartTitle, 'development', setArrayType);
        if (statuses.includes(2))
          appendByStatusValue(chartTitle, 'active', setArrayType);
        if (statuses.includes(3))
          appendByStatusValue(chartTitle, 'completed', setArrayType);
        return null;
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(venueChart)) {
      setVenueType({
        mainTitle: [],
        dataset: [],
        development: [],
        active: [],
        completed: [],
        styles: {
          color: [],
          weight: [],
          highlight: [],
          size: [],
        },
      });
      makeChartObject(venueChart, setVenueType);
    }
  }, [venueChart, statuses]);

  useEffect(() => {
    if (!isEmpty(outcomes)) {
      setActivityType({
        mainTitle: [],
        dataset: [],
        development: [],
        active: [],
        completed: [],
        styles: {
          color: [],
          weight: [],
          highlight: [],
          size: [],
        },
      });
      makeChartObject(outcomes, setActivityType);
    }
  }, [outcomes, statuses]);

  useEffect(() => {
    if (!isEmpty(participantsData)) {
      setParticipantsType({
        mainTitle: [],
        dataset: [],
        development: [],
        active: [],
        completed: [],
        styles: {
          color: [],
          weight: [],
          highlight: [],
          size: [],
        },
      });
      makeChartObject(participantsData, setParticipantsType);
    }
  }, [participantsData, statuses]);

  const dispatch = useDispatch();

  const onChangeActivitiesInReport = () => {
    const _activitiesInReport = !activitiesInReport;

    setActivitiesInReport(_activitiesInReport);
    dispatch(
      setParentChartIncludeIn(
        currentActivity.id,
        'ACTIVITIES_CHART',
        _activitiesInReport,
        'ACTIVITY_TYPE'
      )
    );
  };

  const onChangeVenuesInReport = () => {
    const _venuesInReport = !venuesInReport;
    setVenuesInReport(_venuesInReport);

    dispatch(
      setParentChartIncludeIn(
        currentActivity.id,
        'ACTIVITIES_CHART',
        _venuesInReport,
        'ACTIVITY_VENUES'
      )
    );
  };

  const onChangeParticipantsInReport = () => {
    const _participantsInReport = !participantsInReport;
    setParticipantsInReport(_participantsInReport);

    dispatch(
      setParentChartIncludeIn(
        currentActivity.id,
        'ACTIVITIES_CHART',
        _participantsInReport,
        'ACTIVITY_PARTICIPANTS'
      )
    );
  };

  // const onChangeActivitiesMapInReport = () => {
  //   const _activitiesMapInReport = !activitiesMapInReport;
  //   setActivitiesMapInReport(_activitiesMapInReport);

  //   dispatch(
  //     setParentChartIncludeIn(
  //       currentActivity.id,
  //       'ACTIVITIES_CHART',
  //       _activitiesMapInReport,
  //       'ACTIVITIES_MAP'
  //     )
  //   );
  // };

  // const onChangeContractorsMapInReport = () => {
  //   const _contractorsMapInReport = !contractorsMapInReport;
  //   setContractorsMapInReport(_contractorsMapInReport);

  //   dispatch(
  //     setParentChartIncludeIn(
  //       currentActivity.id,
  //       'ACTIVITIES_CHART',
  //       _contractorsMapInReport,
  //       'CONTRACTOR_MAP'
  //     )
  //   );
  // };

  const industrySectorTypes = useSelector(
    (state) => state.extra.industrySectorTypes
  );
  const organization = useSelector((state) => state.organization.organization);

  const getOrganizationSectorType = () => {
    try {
      const type = find(industrySectorTypes, (o) => {
        return o.id === organization.industrySectorTypeId;
      });
      if (isEmpty(type)) {
        return '-';
      } else {
        return type.name;
      }
    } catch (error) {
      return '-';
    }
  };

  const getHeight = (data) => {
    return `${5 * data.length}rem`;
  };

  const renderMaps = () => {
    const mapData = [];

    // {
    //   data: report.projectOrganizationMapData,
    //   title: 'Organisation',
    //   isEmptyData: isEmpty(report.projectOrganizationMapData),
    // loading:orgMapDataLoading
    // },

    if (includeActivitiesMap) {
      mapData.push({
        data: report.projectVenueMapData,
        title: 'Activities',
        isEmptyData: isEmpty(report.projectVenueMapData),
        loading: activityMapLoading,
      });
    }

    if (includeContractorsMap) {
      mapData.push({
        data: report.projectContractorMapData,
        title: 'Contractor',
        isEmptyData: isEmpty(report.projectContractorMapData),
        loading: contractorMapLoading,
      });
    }

    return (
      <Segment className="mb-2">
        {map(mapData, (item, key) => {
          // const isIncludedInReport =
          //   item.title === 'Activities'
          //     ? activitiesMapInReport
          //     : item.title === 'Contractor'
          //       ? contractorsMapInReport
          //       : false;

          return (
            <Fragment key={`programme_activity_${key}`}>
              {/* <br /> */}
              <div className="programme-activity-heading">
                <div className="content">
                  <label>{item.title}</label>
                  {/* {!inParentReportPage && (
                    <div
                      className="ml-auto mr-3"
                      style={{ display: ' flex', flexDirection: 'row' }}
                    >
                      <div className="checkbox-container-include-in-report">
                        <div className="include-in-report-label">
                          Include in report
                        </div>
                        <div className="button-include">
                          <Button.Group
                            className="btn-include-in-report"
                            onClick={() => {
                              if (item.title === 'Activities') {
                                onChangeActivitiesMapInReport();
                              } else if (item.title === 'Contractor') {
                                onChangeContractorsMapInReport();
                              }
                            }}
                            disabled={isDisabledInReportGrpBtn}
                          >
                            <Button
                              active={isIncludedInReport && true}
                              disabled={isDisabledInReportGrpBtn}
                            >
                              Yes
                            </Button>
                            <Button
                              active={!isIncludedInReport && true}
                              disabled={isDisabledInReportGrpBtn}
                            >
                              No
                            </Button>
                          </Button.Group>
                        </div>
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
              <div className="programme-map mb-5">
                <CustomSegment title={item.title} className="activities">
                  <Segment loading={report.orgMapDataLoading}>
                    {!item.isEmptyData ? (
                      <GoogleMap data={item.data} type={item.title} />
                    ) : (
                      <EmptyContainer msg="No data found." />
                    )}
                  </Segment>
                </CustomSegment>
              </div>
            </Fragment>
          );
        })}
      </Segment>
    );
  };

  const renderHeader = () => {
    return (
      <Grid>
        <Grid.Row className="programme-activity">
          <Grid.Column width={16}>
            <Grid className="activity-summary-grid" padded>
              {parent || superAdmin ? null : (
                <div className="org-down">
                  <Grid.Row>
                    <label>Organisation Type</label>
                  </Grid.Row>
                  <Grid.Row>
                    <h6>{getOrganizationSectorType()}</h6>
                  </Grid.Row>
                </div>
              )}
              {activityStatsLoading ? (
                <Loader active size="medium" indeterminate />
              ) : (
                <div className="left-align">
                  <div className="activity-name">
                    <div className="circle circle-bottom activity-warning" />
                    <div className="col-wise">
                      <div>
                        <label>In-development</label>
                      </div>
                      <div className="number-centre">
                        {stats.totalDevelopmentCount}
                      </div>
                    </div>
                  </div>

                  <div className="activity-name">
                    <div className="circle circle-bottom activity-success" />
                    <div className="col-wise">
                      <div>
                        <label>Active</label>
                      </div>
                      <div className="number-centre">
                        {stats.totalActiveCount}
                      </div>
                    </div>
                  </div>

                  <div className="activity-name">
                    <div className="circle circle-bottom activity-completed" />
                    <div className="col-wise">
                      <div>
                        <label>Completed</label>
                      </div>
                      <div className="number-centre">
                        {stats.totalCompletedCount}
                      </div>
                    </div>
                  </div>

                  <div className="activity-name">
                    <div className="col-wise">
                      <div>
                        <label>Total Activities</label>
                      </div>
                      <div className="number-centre">
                        {stats.totalActivityCount}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  // const handleIncludeInReport = (
  //   chartType,
  //   isIncluded,
  //   reportType = null,
  //   outcomeId = null
  // ) => {
  //   console.log(currentActivity);

  //   dispatch(
  //     setParentChartIncludeIn(
  //       currentActivity.id,
  //       chartType,
  //       isIncluded,
  //       reportType,
  //       outcomeId
  //     )
  //   );
  // };

  const renderActivitySummary = () => {
    return (
      <Segment>
        {inParentReportPage ? (
          <></>
        ) : (
          <div className="activity-count-info">{renderHeader()}</div>
        )}
        <Segment loading={activityReportLoading}>
          <br />

          <div
            className={
              inParentReportPage
                ? 'programme-activity-heading-no-border'
                : 'programme-activity-heading'
            }
          >
            <div className="content">
              <label>Activity Type</label>

              {!inParentReportPage && (
                <div
                  className="ml-auto mr-3"
                  style={{ display: ' flex', flexDirection: 'row' }}
                >
                  <div className="checkbox-container-include-in-report">
                    <div className="include-in-report-label">
                      Include in report
                    </div>
                    <div className="button-include">
                      <Button.Group
                        className="btn-include-in-report"
                        onClick={() =>
                          !isDisabledInReportGrpBtn &&
                          onChangeActivitiesInReport()
                        }
                        disabled={isDisabledInReportGrpBtn}
                      >
                        <Button
                          active={activitiesInReport && true}
                          disabled={isDisabledInReportGrpBtn}
                        >
                          Yes
                        </Button>
                        <Button
                          active={!activitiesInReport && true}
                          disabled={isDisabledInReportGrpBtn}
                        >
                          No
                        </Button>
                      </Button.Group>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            id="activityType"
            style={{
              height: !isEmpty(get(activityType, 'dataset', null))
                ? getHeight(get(activityType, 'dataset', 0))
                : 'auto',
              width: 'auto',
            }}
            className={isEmpty(activityType.dataset) ? 'programme-map' : null}
          >
            {isEmpty(get(activityType, 'dataset', null)) ? (
              <EmptyContainer msg="No data found." />
            ) : (
              <ChartComponent
                // toggle={activitiesInReport}
                data={
                  !isEmpty(get(actSummary, 'outcomes', [])) &&
                  !isEmpty(activityType.dataset) &&
                  sum(flattenDeep(activityType.dataset)) !== 0
                    ? activityType
                    : {
                        ...activityType,
                        dataset: [],
                        development: [],
                        active: [],
                        completed: [],
                      }
                }
                chartType={'activitiesOnly'}
                status={statuses}
              />
            )}
          </div>
        </Segment>
      </Segment>
    );
  };

  const renderActivityVenues = () => {
    return (
      <Segment loading={venueReportLoading}>
        <div className="programme-activity-heading">
          <div className="content">
            <label>Activity Venues</label>

            {!inParentReportPage && (
              <div
                className="ml-auto mr-3"
                style={{ display: ' flex', flexDirection: 'row' }}
              >
                <div className="checkbox-container-include-in-report">
                  <div className="include-in-report-label">
                    Include in report
                  </div>
                  <div className="button-include">
                    <Button.Group
                      className="btn-include-in-report"
                      onClick={() =>
                        !isDisabledInReportGrpBtn && onChangeVenuesInReport()
                      }
                      disabled={isDisabledInReportGrpBtn}
                    >
                      <Button
                        active={venuesInReport && true}
                        disabled={isDisabledInReportGrpBtn}
                      >
                        Yes
                      </Button>
                      <Button
                        active={!venuesInReport && true}
                        disabled={isDisabledInReportGrpBtn}
                      >
                        No
                      </Button>
                    </Button.Group>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          id="venueType"
          style={{
            height: !isEmpty(get(venueType, 'dataset', null))
              ? getHeight(get(venueType, 'dataset', 0))
              : 'auto',
            width: 'auto',
            background: 'white',
          }}
          className={isEmpty(venueType.dataset) ? 'programme-map' : null}
        >
          {isEmpty(get(venueType, 'dataset', null)) ? (
            <EmptyContainer msg="No data found." />
          ) : (
            <ChartComponent
              // toggle={venuesInReport}
              // data={!isEmpty(venueType) && venueType}
              data={
                !isEmpty(venueChart) &&
                !isEmpty(venueType.dataset) &&
                sum(flattenDeep(venueType.dataset)) !== 0
                  ? venueType
                  : {
                      ...venueType,
                      dataset: [],
                      development: [],
                      active: [],
                      completed: [],
                    }
              }
              chartType={'venuesOnly'}
              status={statuses}
            />
          )}
        </div>
      </Segment>
    );
  };

  const renderActivityParticipants = () => {
    return (
      <Segment loading={participantLoading}>
        <div className="programme-activity-heading">
          <div className="content">
            <label>Activity Participants</label>
            {!inParentReportPage && (
              <div
                className="ml-auto mr-3"
                style={{ display: ' flex', flexDirection: 'row' }}
              >
                <div className="checkbox-container-include-in-report">
                  <div className="include-in-report-label">
                    Include in report
                  </div>
                  <div className="button-include">
                    <Button.Group
                      className="btn-include-in-report"
                      onClick={() =>
                        !isDisabledInReportGrpBtn &&
                        onChangeParticipantsInReport()
                      }
                      disabled={isDisabledInReportGrpBtn}
                    >
                      <Button
                        active={participantsInReport && true}
                        disabled={isDisabledInReportGrpBtn}
                      >
                        Yes
                      </Button>
                      <Button
                        active={!participantsInReport && true}
                        disabled={isDisabledInReportGrpBtn}
                      >
                        No
                      </Button>
                    </Button.Group>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          id="participantsType"
          style={{
            height: !isEmpty(get(participantsType, 'dataset', null))
              ? getHeight(get(participantsType, 'dataset', 0))
              : 'auto',
            width: 'auto',
            background: 'white',
          }}
          className={isEmpty(participantsType.dataset) ? 'programme-map' : null}
        >
          {isEmpty(get(participantsType, 'dataset', null)) ? (
            <EmptyContainer msg="No data found." />
          ) : (
            <ChartComponent
              // toggle={participantsInReport}
              // data={!isEmpty(participantsType) && participantsType}
              data={
                !isEmpty(participantsData) &&
                !isEmpty(participantsType.dataset) &&
                sum(flattenDeep(participantsType.dataset)) !== 0
                  ? participantsType
                  : {
                      ...participantsType,
                      dataset: [],
                      development: [],
                      active: [],
                      completed: [],
                    }
              }
              chartType={'participantsOnly'}
              status={statuses}
            />
          )}
        </div>
      </Segment>
    );
  };

  return (
    <>
      {includeTypeChart ? renderActivitySummary() : <></>}
      {includeVenuesChart ? renderActivityVenues() : <></>}
      {includeParticipantsChart ? renderActivityParticipants() : <></>}
      {!inParentReportPage && renderMaps()}
    </>
  );
};
