import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { isBrowser, isTablet } from 'react-device-detect';
import { Grid, Icon, Popup, Dropdown } from 'semantic-ui-react';
import { get, isEmpty, map, size, compact, take } from '../../../utils/lodash';
import { CustomTable, EmptyContainer } from '../../../components';
import {
  getGoalStatus,
  getCriticalStatus,
  getScaleStatus,
} from '../../../utils';
import { CustomNotification } from '../../../components/customReportHeader';
import { isParentProgramActivity } from '../../../utils/activity';

const status_options = [
  {
    key: 'Ascending',
    text: <b>Ascending</b>,
    value: 'asc',
  },
  {
    key: 'Descending',
    text: <b>Descending</b>,
    value: 'desc',
  },
];

const heading_options = [
  {
    key: 'Goal',
    text: <b>Goal</b>,
    value: 'goal',
  },
  {
    key: 'ActivityName',
    text: <b>Activity Name</b>,
    value: 'activityName',
  },
  {
    key: 'Status',
    text: <b>Status</b>,
    value: 'status',
  },
];

class OrganisationSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {
    const { setStatus, setOrder, setSort } = this.props;
    if (this.props.status !== prevProps.status) {
      setStatus(this.props.status);
    }

    if (this.props.order !== prevProps.order) {
      setOrder(this.props.order);
    }
    if (this.props.sort !== prevProps.sort) {
      setSort(this.props.sort);
    }
  }

  render() {
    const { managementSummaryReport, status, dateRangeChosenLabel } =
      this.props;
    const development = get(managementSummaryReport, 'development', 0);
    const active = get(managementSummaryReport, 'active', 0);
    const completed = get(managementSummaryReport, 'completed', 0);
    return (
      <div>
        <Grid.Row className="activity-container">
          <Grid.Column mobile={16} tablet={8} computer={6}>
            {/* <div className="organisation-summary"> */}
            <div className="text-org">Strategic Plan Document</div>
            <div className="option-text">
              {' '}
              {!isEmpty(managementSummaryReport.referenceDocument)
                ? managementSummaryReport.referenceDocument
                : '-'}
            </div>
            {/* </div> */}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={10}>
            <div>
              {/* <div className="organisation-summary"> */}
              <div className="text-org">Activities Summary</div>

              <div className="activity-types">
                {!isEmpty(managementSummaryReport.activityTypes)
                  ? managementSummaryReport.activityTypes.join(', ')
                  : '-'}
              </div>
              {/* </div> */}
            </div>
          </Grid.Column>
        </Grid.Row>
        {dateRangeChosenLabel !== 'Since the Start' && (
          <Grid.Row className="org-notification-container">
            <div className="org-summary-custom-notification">
              <CustomNotification
                messageBanner={
                  'In development and Active figures reflect status on final day of the search period. Completed figure reflects activities completed in this period.  See help page for more information.'
                }
              />
            </div>
          </Grid.Row>
        )}
        <Grid.Row>
          <div className="organisation-summary">
            <div className="text-org-types">Activity Types</div>
            <br></br>
            <div className="development-types">
              <Fragment>
                {['all', 'ongoing'].includes(status) && (
                  <div className="act-summary-dev">
                    <Grid.Row>
                      <Grid.Column>
                        <div className="circle circle-bottom activity-warning"></div>
                      </Grid.Column>
                      <Grid.Column
                        className={'In-development'}
                      >{` In-development`}</Grid.Column>
                      <Grid.Column>{`: ${development}`}</Grid.Column>
                    </Grid.Row>
                  </div>
                )}
                {/* {development !== 0 && active !== 0 ? (
                          <Grid.Row></Grid.Row><div className="gap">,</div>
                        ) : null} */}
                {['all', 'active'].includes(status) && (
                  <div className="act-summary-dev">
                    <Grid.Row>
                      <Grid.Column>
                        <div className="circle circle-bottom activity-success"></div>
                      </Grid.Column>
                      <Grid.Column
                        className={'activeTypeActive'}
                      >{` Active`}</Grid.Column>
                      <Grid.Column>{`: ${active}`}</Grid.Column>
                    </Grid.Row>
                  </div>
                )}
                {/* {active !== 0 && completed !== 0 ? (
                          <div className="gap">,</div>
                        ) : null} */}
                {['all', 'completed'].includes(status) && (
                  <div className="act-summary-dev">
                    <Grid.Row>
                      <Grid.Column>
                        <div className="circle circle-bottom activity-completed"></div>
                      </Grid.Column>
                      <Grid.Column
                        className={'activeTypeComplete'}
                      >{` Completed `}</Grid.Column>
                      <Grid.Column>{`: ${completed}`}</Grid.Column>
                    </Grid.Row>
                  </div>
                )}
              </Fragment>
            </div>
          </div>
        </Grid.Row>
        <OrganisationSummaryList {...this.props} />
      </div>
    );
  }
}

export class OrganisationSummaryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //TODO: Remove after merge
      toggle: true,
    };
  }

  paginationTable() {
    try {
      const { managementSummaryReport, perPage, currentPage, pageChange } =
        this.props;
      const totalItems = map(
        managementSummaryReport.projects,
        (item, index) => {
          let totalItems = 0;
          if (item) {
            totalItems++;
          }
          return totalItems;
        }
      );

      let noOfPages = totalItems.length / perPage;

      if (Number.isInteger(noOfPages)) {
        noOfPages = Math.floor(noOfPages);
      } else {
        noOfPages = Math.floor(noOfPages) + 1;
      }

      let lastIndex = currentPage * perPage;
      let firstIndex = lastIndex - perPage;

      return (
        <div>
          {totalItems.length ? (
            <div className="organisation-table-horizontal">
              <CustomTable
                header
                columns={this.getColumns()}
                data={managementSummaryReport.projects.slice(
                  firstIndex,
                  lastIndex
                )}
                customHeaderClass="org-report-table-header"
                pagination
                customClass="organisation-summary-table"
                handlePaginationChange={(page) => pageChange(page)}
                page={currentPage}
                noOfPages={noOfPages}
                keyExtractor={(item, index) => item.id}
              />
            </div>
          ) : (
            <EmptyContainer msg="No Records..." />
          )}
        </div>
      );
    } catch (error) {
      error.log('Error in fetching table data~~', error);
    }
  }

  getScale(data) {
    const getScale = get(data, 'scale', []);
    if (isEmpty(getScale)) return '-';
    let [first, second] = getScale;
    // if (first) {
    //   // eslint-disable-next-line
    //   if (data.activity.objective[0].outcome.id != first.outcomeId) {
    //     const temp = first;
    //     first = second;
    //     second = temp;
    //   }
    // }
    let scale = '';
    map(first, (item, i) => {
      if (i !== 'outcomeId') scale += `${getScaleStatus(i)} ${item} `;
    });
    let scaleTwo = '';
    map(second, (item, i) => {
      if (i !== 'outcomeId') scaleTwo += `${getScaleStatus(i)} ${item} `;
    });
    return (
      <div className="mt-1">
        {!isEmpty(scale) ? <div style={{ width: '125px' }}>{scale}</div> : '-'}
        {!isEmpty(scaleTwo) && <div style={{ width: '125px' }}>{scaleTwo}</div>}
      </div>
    );
  }

  getColumns() {
    const { activityType } = this.props;

    let columns = [
      {
        width: 3,
        title: (
          <div className="organisation-summary-activity-name">
            Activity Name
          </div>
        ),
        render: (data) => {
          return (
            <div className="report-goal">
              <div
                className={`circle circle-right activity-${getGoalStatus(
                  data.status.type
                )}`}
              />
              <div className={'goal-description'}>
                <label className={'goal-info'}>
                  {data.activityName ? data.activityName : null}
                </label>
                {data.dates !== '-' ? (
                  <label className={'reportDate'}>{data.dates}</label>
                ) : null}
              </div>
            </div>
          );
        },
      },
      {
        width: 1,
        title: <div className="organisation-summary-action">Action</div>,
        render: (data) => {
          return <div>{data.action === null ? '-' : data.action}</div>;
        },
        textAlign: 'left',
      },

      {
        width: 3,
        title: <div className="organisation-summary-goal">Goal</div>,
        render: (data) => {
          return data.goal ? data.goal : '-';
        },
        textAlign: 'left',
      },
      ['all', 'single_activity', 'linked_activity'].includes(activityType)
        ? {
            title: (
              <div className="organisation-summary-activityType">
                Activity Type
              </div>
            ),
            render: (data) => {
              return data.activityType;
            },
            textAlign: 'left',
          }
        : null,
      {
        width: 4,
        title: <div className="organisation-summary-objective">Objectives</div>,
        render: (data) => {
          const outcomes = map(data.outcomes, (item, index) => {
            return (
              <div className="objective-table-div" key={index}>
                <ul>
                  <li>{item}</li>
                </ul>
                <hr className="intense-outcome-hr" />
              </div>
            );
          });

          return size(outcomes) > 2 ? (
            //  TODO: Add the reusable seemore component after re-skin merge into develop.
            <div>
              <ViewMore
                minCount={2}
                dataSet={outcomes}
                // CustomComponent={<div className="evaluation-group-bg">{outcomes}</div>}
              />
            </div>
          ) : (
            <div>{outcomes.length > 0 ? outcomes : '-'}</div>
          );
        },
        textAlign: 'left',
      },
      [
        'all',
        'grant_program',
        'festival_program',
        'multi_activity_program',
      ].includes(activityType)
        ? {
            title: (
              <div className="organisation-summary-number-linked-acts">
                Linked
              </div>
            ),

            render: (data) => {
              return (
                <div className="nooflinked-centre">
                  {isParentProgramActivity(data.activityPlanTypeId)
                    ? data.noOfLinkedActs
                    : '-'}
                </div>
              );
            },
            textAlign: 'left',
          }
        : null,
      {
        width: 2,
        title: <div className="organisation-summary-number-status">Status</div>,
        render: (data) => {
          return (
            <div>
              {data.status.type === 'COMPLETED' &&
              !isParentProgramActivity(data.activityPlanTypeId) ? (
                <div className="scale">{this.getScale(data)}</div>
              ) : (
                <div className="percentage">
                  <div>{`${get(data, 'status.percentage', 0)}%`}</div>
                  {data.status.note && (
                    <div className="per-right">
                      <Popup
                        content={
                          data.status.note.label ? data.status.note.label : null
                        }
                        trigger={
                          <Icon
                            name="warning circle"
                            color={getCriticalStatus(
                              data.status.note.criticality
                            )}
                            className="icon-org-summary"
                          ></Icon>
                        }
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        },
        textAlign: 'left',
      },
    ];

    return compact(columns);
  }

  render() {
    const { onChangeOrder, order, sort, onChangeSort } = this.props;

    return (
      <div className="org-report-list">
        <div className="org-sort">
          <span className="activities-filter">
            Sort By: &nbsp;
            <Dropdown
              direction="left"
              inline
              selectOnBlur={false}
              defaultValue={sort}
              options={heading_options}
              onChange={(e, { value }) => onChangeSort(value)}
            />
          </span>
          <span className="activities-filter">
            Order by: &nbsp;
            <Dropdown
              direction="left"
              inline
              defaultValue={order}
              selectOnBlur={false}
              options={status_options}
              onChange={(e, { value }) => onChangeOrder(value)}
            />
          </span>
        </div>

        {this.paginationTable()}
      </div>
    );
  }
}

// TODO:Delete after merge
export class ViewMore extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      toggle: true,
    };
  }

  render() {
    const { minCount, dataSet } = this.props;
    return (
      <div>
        {map(
          take(dataSet, this.state.toggle ? minCount : size(dataSet)),
          (group, i) => (
            <div key={i}>{group}</div>
          )
        )}
        <div
          className="see-more-see-less-toggle"
          onClick={() => this.setState({ toggle: !this.state.toggle })}
        >
          View {this.state.toggle ? 'More' : 'Less'}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    managementSummaryReport: state.report.managementSummaryReport,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

OrganisationSummary = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationSummary);

export { OrganisationSummary };
