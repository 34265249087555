import React from 'react';
import { Icon } from 'semantic-ui-react';
import './styles.scss';

export const Tooltip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}) => (
  <div {...tooltipProps} className="info-window">
    <label className="info-description">{step.content}</label>
    {continuous && (
      <label {...primaryProps} className="info-button">
        GOT IT
      </label>
    )}
  </div>
);

export const BeaconComponent = (props) => (
  <Icon circular name="info" className="info-pulse" {...props} />
);
