/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useField } from 'formik';
import { Checkbox } from 'semantic-ui-react';

export const CustomCheckbox = (props) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <div className="formik-custom-field act-inputs-new">
      <Checkbox error={meta.touched && meta.error} {...field} {...props} />
      {meta.touched && meta.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
    </div>
  );
};
