/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, get, debounce } from '../../../utils/lodash';
import {
  Grid,
  Radio,
  Icon,
  Divider,
  Accordion,
  Checkbox,
  Label,
  Form,
} from 'semantic-ui-react';
import {
  getOrganizationReportData,
  getProgrammeReportData,
  advancedFilterReset,
  getDepartmentFilterUnits,
  getCrossReferenceByGoal,
  advancedFilterAllReset,
  getDepartmentsLists,
  getDepartmentsListsByProgram,
  setSidebarType,
} from '../../../store/actions';
import { filter, find, isArray, isEqual } from 'lodash';
import {
  activeParticipantsList,
  activityStateList,
  organizationLevelList,
  activityTypeList,
  receptiveParticipantsList,
} from './constants';
import { REPORT_FILTER_OPTIONS, SIDEBAR_TYPES } from '../../../utils/constant';

const ReportFilter = ({
  dataset,
  parentStateHandler = null,
  horizontal = true,
  toggle = true,
  reportType,
  toggleDefaultValue = false,
  setToggleDefaultValue = null,
}) => {
  const reportFilterOptions = useSelector(
    (state) => state.report.reportFilterOptions
  );
  const [activityStatus, setActivityStatus] = useState([]);
  const [isToggle, setIsToggle] = useState(toggleDefaultValue);

  useEffect(() => {
    !isEqual(dataset, activityStatus) && setActivityStatus(dataset);
  }, [dataset]);

  useEffect(() => {
    if (reportFilterOptions.statuses.length === 0) {
      setIsToggle(false);
    }
  }, [reportFilterOptions]);

  useEffect(() => {
    setToggleDefaultValue && setToggleDefaultValue(isToggle);
  }, [isToggle]);

  useEffect(() => {
    setIsToggle(toggleDefaultValue);
  }, [toggleDefaultValue]);

  const toggleOnOff = () => {
    if (isToggle) {
      setActivityStatus((prevData) =>
        prevData.map((item) => {
          return { ...item, checked: false };
        })
      );
      setIsToggle(false);
    } else {
      setIsToggle(true);
    }
    const value = activityStatus.map((item) => {
      return { ...item, checked: isToggle ? false : true };
    });
    parentStateHandler(value);
  };

  const handleActivityStatus = (checked, key) => {
    let value = [];
    value = activityStatus.map((item) => {
      if (item.key === key)
        return { ...item, checked: item.checked ? false : true };
      return item;
    });
    parentStateHandler(value);
  };

  return (
    <div
      className={
        horizontal ? 'filter-components-column' : 'filter-components-row'
      }
    >
      {(reportType === 'activities' ||
        reportType === 'outcomes-addressed' ||
        isEmpty(reportType)) &&
        toggle && (
          <div className="two-step-radio-container-admin">
            <Radio
              toggle
              name="activateToggel"
              label={'Group by status'}
              onChange={() => toggleOnOff()}
              checked={reportFilterOptions.statuses.length > 0}
            />
          </div>
        )}

      {activityStatus &&
        activityStatus.map((item, key) => {
          return (
            <Grid.Column className="grid-column" key={`filter_status_${key}`}>
              <Checkbox
                className="check-box"
                label={item.text}
                checked={get(item, 'checked', false)}
                onChange={(e, { checked }) =>
                  handleActivityStatus(checked, item.id)
                }
                disabled={
                  reportType === 'activities' ||
                  reportType === 'outcomes-addressed'
                    ? !isToggle
                    : false
                }
              />
            </Grid.Column>
          );
        })}
    </div>
  );
};

const RadioGroup = ({
  dataset,
  parentStateHandler,
  // hideShowMore = true,
}) => {
  // const [showMore, setShowMore] = useState(false);
  const [optionArray, setOptionArray] = useState([]);

  useEffect(() => {
    !isEqual(dataset, optionArray) && setOptionArray(dataset);
  }, [dataset]);

  const handleChange = (e, { value }) => {
    let val = optionArray.map((item) => {
      if (item.value === value)
        return { ...item, checked: item.checked ? false : true };
      else {
        return { ...item, checked: false };
      }
    });
    setOptionArray(val);
    parentStateHandler(val);
  };

  return (
    <div>
      <Form.Group>
        {optionArray.map((item, key) => (
          <Form.Radio
            key={`filter_radio_group_show_more_${key}`}
            label={item.text}
            value={item.value}
            checked={get(item, 'checked', false)}
            onChange={handleChange}
          />
        ))}
      </Form.Group>
      {/* {hideShowMore ? (
        <div className="show-all-button">
          <p
            onClick={() => {
              showMore ? setShowMore(false) : setShowMore(true);
            }}
          >
            {showMore ? 'Show Less' : ' Show all'}
            <Icon className="ml-1" name="chevron down" />
          </p>
        </div>
      ) : null} */}
    </div>
  );
};

const initialState = {
  usedDataOnly: false,
  organizationLevelOptions: [],
  outcomeOptions: [],
  outcomeOption: [],
  methodOptions: [],
  venueOptions: [],
  activityStatusOptions: [],
  activityTypeOptions: [],
  receptiveParticipantsOptions: [],
  activeParticipantsOptions: [],
  goalsOptions: [],
  crossReferenceOptions: [],
  unitOptions: [],
  departmentOptions: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'OrganizationLevel':
      return {
        ...state,
        organizationLevelOptions: action.payload,
      };
    case 'UsedDataOnly':
      return {
        ...state,
        usedDataOnly: action.payload,
      };
    case 'Outcomes':
      return {
        ...state,
        outcomeOptions: action.payload,
      };
    case 'Outcome':
      return {
        ...state,
        outcomeOption: action.payload,
      };
    case 'Methods':
      return {
        ...state,
        methodOptions: action.payload,
      };
    case 'Venues':
      return {
        ...state,
        venueOptions: action.payload,
      };
    case 'SubVenues':
      return {
        ...state,
        subVenueOptions: action.payload,
      };

    case 'ActivityStatus':
      return {
        ...state,
        activityStatusOptions: action.payload,
      };

    case 'Activity':
      return {
        ...state,
        activityTypeOptions: action.payload,
      };

    case 'ReceptiveParticipants':
      return {
        ...state,
        receptiveParticipantsOptions: action.payload,
      };

    case 'ActiveParticipants':
      return {
        ...state,
        activeParticipantsOptions: action.payload,
      };

    case 'Goals':
      return {
        ...state,
        goalsOptions: action.payload,
      };

    case 'CrossReference':
      return {
        ...state,
        crossReferenceOptions: action.payload,
      };
    case 'Units':
      return {
        ...state,
        unitOptions: action.payload,
      };

    case 'Department':
      return {
        ...state,
        departmentOptions: action.payload,
      };

    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
};

const AdvancedFilter = (props) => {
  const prevProps = useRef();

  const [toggleDefaultValue, setToggleDefaultValue] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const reportFilterOptions = useSelector(
    (state) => state.report.reportFilterOptions
  );

  const [state, stateDispatch] = useReducer(reducer, initialState);
  const dispatch = useDispatch();

  const orgId = useSelector(
    (state) => state.authentication.user.organisationId
  );
  const outcomes = useSelector((state) => state.extra.outcomes);
  const methods = useSelector((state) => state.extra.methods);
  const orgVenueTypes = useSelector((state) => state.extra.orgVenueTypes);

  const departments = useSelector((state) => state.organization.deptList);
  const departmentUnits = useSelector(
    (state) => state.organization.departmentFilterUnit
  );
  const outcomeSummaryGoals = useSelector(
    (state) => state.organization.organisationGoals
  );

  const currentActivity = useSelector(
    (state) => state.activity.currentActivity
  );

  const isAdvancedFilter =
    useSelector((state) => state.sidebar.sideBarType) === SIDEBAR_TYPES[2];

  const isVisibleSidebar = useSelector(
    (state) => state.sidebar.isVisibleSidebar
  );
  const advancedToggle = isAdvancedFilter && isVisibleSidebar;

  const isOutcomeReportedPage = useSelector(
    (state) => state.report.isOutcomeReportedPage
  );
  const filterCrossReferences = useSelector(
    (state) => state.report.filterCrossReferences
  );
  const reportFilter = useSelector((state) => state.report.reportFilterOptions);
  const parentReportType = useSelector(
    (state) => state.report.parentReportType
  );

  let REPORT_TYPE = props.isProgram
    ? parentReportType
    : props.match.params.type;

  if (isOutcomeReportedPage) {
    REPORT_TYPE = 'outcomes-reported';
  }

  const resetSubOptions = (subItems, parentKey) => {
    return subItems.map((val) => {
      if (parentKey === val.parentVenueId) {
        return {
          ...val,
          checked: false,
        };
      } else {
        return val;
      }
    });
  };

  const handleVenueChange = async (checked, key) => {
    let value = [];
    value = state.venueOptions.map((item) => {
      const subItems = filter(
        item.subItems,
        (sub) => sub.parentVenueId === item.key
      );
      if (!checked && !isEmpty(subItems)) {
        //if parent unchecked the box, child will be reset
        item.subItems = resetSubOptions(subItems, key);
      }
      const getSubItem = find(subItems, (val) => val.key === key);
      if (getSubItem) {
        //child items
        getSubItem.checked = getSubItem.checked ? false : true;
        const uniqueSubItems = new Set([...item.subItems, getSubItem]);
        item.subItems = Array.from(uniqueSubItems);
      }
      if (item.key === key)
        //parent items
        return { ...item, checked: item.checked ? false : true };
      return item;
    });

    handleFilterChange({
      venues: getStateParentValue(value),
      subVenues: getStateIds(value).subOptionIds,
    });
    stateDispatch({
      type: 'Venues',
      payload: value,
    });
  };
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  // to check path got changed
  useEffect(() => {
    if (prevProps.path !== props.match.path) {
      clearAll(false);
      setActiveIndex(0);
    }
    prevProps.path = props.match.path;
  }, [props.match.path]);

  // to check activity path got changed (not program report available)
  useEffect(() => {
    if (
      props.match.path === '/activities/:id/:type' &&
      props.match.params.type !== 'documentation'
    ) {
      clearAll(false);
      setActiveIndex(0);
    }
  }, [props.match.pathname]);

  useEffect(() => {
    if (reportFilter.method === 'all') getAllMethods();
  }, [reportFilter.method]);

  useEffect(() => {
    stateDispatch({
      type: 'ActivityStatus',
      payload: activityStateList,
    });

    stateDispatch({
      type: 'OrganizationLevel',
      payload: organizationLevelList,
    });

    stateDispatch({
      type: 'Activity',
      payload: activityTypeList,
    });

    stateDispatch({
      type: 'ReceptiveParticipants',
      payload: receptiveParticipantsList,
    });

    stateDispatch({
      type: 'ActiveParticipants',
      payload: activeParticipantsList,
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      advancedToggle && scrollToId();
    }, 500);
  }, []);

  //changes
  useEffect(() => {
    if (!props.isProgram) {
      dispatch(getDepartmentsLists());
    } else {
      dispatch(getDepartmentsListsByProgram(props.projectId));
    }
  }, []);

  const setDepartmentUnit = (depIds) => {
    dispatch(getDepartmentFilterUnits(depIds));
  };

  const handleBulkUpdate = (array, selectedItem) => {
    const updatedArray = array.map((item) => {
      if (isArray(selectedItem)) {
        if (selectedItem.includes(item.key)) {
          return { ...item, checked: true };
        }
      } else {
        if (selectedItem === item.key) return { ...item, checked: true };
      }
      return item;
    });
    return updatedArray;
  };

  useEffect(() => {
    getAllOutcomes();
  }, [outcomes]);

  useEffect(() => {
    getOutcome();
  }, [reportFilter]);

  useEffect(() => {
    getAllMethods();
  }, [methods]);

  useEffect(() => {
    getAllGoals();
  }, [outcomeSummaryGoals]);

  useEffect(() => {
    getAllVenues();
  }, [orgVenueTypes]);

  useEffect(() => {
    getAllDepartment();
  }, [departments]);

  useEffect(() => {
    getAllUnits();
  }, [departmentUnits]);

  useEffect(() => {
    getAllReference();
  }, [filterCrossReferences]);

  const handleFilterChange = (filterObj, event = null) => {
    if (event) {
      event.stopPropagation();
    }

    filterObj.page = 1;
    if (props.isProgram) {
      dispatch(
        debounce(
          getProgrammeReportData(parentReportType, props.projectId, filterObj),
          1000,
          { leading: true }
        )
      );
    } else {
      dispatch(
        debounce(getOrganizationReportData(REPORT_TYPE, filterObj), 1000, {
          leading: true,
        })
      );
    }
  };

  const getAllOutcomes = () => {
    const { isProgram } = props;
    const outcomesOptions = [];

    if (isProgram) {
      const { parentOutcomes = null } = currentActivity;
      if (parentOutcomes) {
        const parentOutcomesIds = parentOutcomes.map((item) => item.outcomeId);

        outcomes.map((outcome) => {
          if (parentOutcomesIds.includes(outcome.id))
            outcomesOptions.push({
              key: outcome.id,
              text: outcome.title,
              value: outcome.id,
              checked: false,
              prePost: outcome.prePost,
            });
        });
      }
    } else {
      if (!isEmpty(outcomes)) {
        outcomes.map((outcome) =>
          outcomesOptions.push({
            key: outcome.id,
            text: outcome.title,
            value: outcome.id,
            checked: false,
            prePost: outcome.prePost,
          })
        );
      }
    }

    stateDispatch({
      type: 'Outcomes',
      payload:
        !isEmpty(reportFilter.outcomes) || !isArray(reportFilter.outcomes)
          ? handleBulkUpdate(outcomesOptions, reportFilter.outcomes)
          : outcomesOptions,
    });
  };

  const getOutcome = () => {
    const outcomesOption = [];

    const updatedOptions = outcomes.map((outcome) => {
      const option = {
        key: outcome.id,
        text: outcome.title,
        value: outcome.id,
        checked: false,
        prePost: outcome.prePost,
      };

      if (option.value === reportFilter.outcome) {
        option.checked = true;
      }

      return option;
    });

    outcomesOption.push(...updatedOptions);

    stateDispatch({
      type: 'Outcome',
      payload: outcomesOption,
    });
  };

  const clearAll = (callRequest = true) => {
    const isProgramReport = props.isProgram;
    filterReset(state.activityTypeOptions, 'Activity');
    filterReset(state.activityStatusOptions, 'ActivityStatus');
    filterReset(state.goalsOptions, 'Goals');
    filterReset(state.venueOptions, 'Venues');
    // filterReset(state.subVenueOptions, 'SubVenues');
    filterReset(state.outcomeOptions, 'Outcomes');
    filterReset(state.crossReferenceOptions, 'CrossReference');
    filterReset(state.departmentOptions, 'Department');
    filterReset(state.methodOptions, 'Methods');
    filterReset(state.receptiveParticipantsOptions, 'ReceptiveParticipants');
    filterReset(state.activeParticipantsOptions, 'ActiveParticipants');
    filterReset(state.organizationLevelOptions, 'OrganizationLevel');

    // filterReset(state.usedDataOnly, 'UsedDataOnly');

    stateDispatch({
      type: 'UsedDataOnly',
      payload: REPORT_FILTER_OPTIONS.usedDataOnly,
    });

    dispatch(
      advancedFilterAllReset(
        REPORT_TYPE,
        isProgramReport,
        props.projectId,
        callRequest
      )
    );
    setToggleDefaultValue(false);
  };

  const getAllMethods = () => {
    const methodOptions = [];
    if (!isEmpty(methods)) {
      methods.map((method, i) =>
        methodOptions.push({
          key: method.id,
          text: method.details,
          value: method.id,
          checked: false,
        })
      );

      const optionAll = [
        { key: 'all', text: 'All', value: 'all', checked: true },
      ];

      stateDispatch({
        type: 'Methods',
        payload: optionAll.concat(methodOptions),
      });
    }
  };

  const getAllVenues = () => {
    const venueOptions = [];
    if (!isEmpty(orgVenueTypes)) {
      orgVenueTypes.map((venue) =>
        venueOptions.push({
          key: venue.id,
          text: venue.name,
          value: venue.id,
          checked: false,
          subItems: venue.venueSubOptions.map((item) => {
            return {
              key: `subItem_${item.id}`,
              text: item.name,
              value: item.id,
              checked: false,
              parentVenueId: venue.id,
            };
          }),
        })
      );

      stateDispatch({
        type: 'Venues',
        payload: venueOptions,
      });
    }
  };

  const getAllGoals = () => {
    const goalOptions = [];
    if (!isEmpty(outcomeSummaryGoals)) {
      outcomeSummaryGoals.map((goal) =>
        goalOptions.push({
          key: goal.id,
          text: goal.goalTitle,
          value: goal.id,
          checked: false,
        })
      );

      stateDispatch({
        type: 'Goals',
        payload: goalOptions,
      });
    }
  };

  const getAllReference = () => {
    const crossReferenceOptions = [];
    if (!isEmpty(filterCrossReferences)) {
      filterCrossReferences.map((unit) =>
        crossReferenceOptions.push({
          key: unit.id,
          text: unit.title,
          value: unit.id,
          checked: false,
        })
      );

      stateDispatch({
        type: 'CrossReference',
        payload: crossReferenceOptions,
      });
    }
  };

  const getAllUnits = () => {
    const unitsOptions = [];
    if (!isEmpty(departmentUnits)) {
      departmentUnits.map((unit) =>
        unitsOptions.push({
          key: unit.id,
          text: unit.name,
          value: unit.id,
          checked: false,
        })
      );

      stateDispatch({
        type: 'Units',
        payload: unitsOptions,
      });
    }
  };

  const getAllDepartment = () => {
    const departmentOptions = [];
    if (!isEmpty(departments)) {
      departments.map((unit) =>
        departmentOptions.push({
          key: unit.id,
          text: unit.name,
          value: unit.id,
          checked: false,
        })
      );

      stateDispatch({
        type: 'Department',
        payload: departmentOptions,
      });
    }
  };

  function getStateIds(stateValues) {
    const keysArray = [],
      subsArray = [];
    stateValues.map((data) => data.checked && keysArray.push(data.key));
    stateValues.map((data) => {
      if (data.subItems) {
        data.subItems.map((item) => item.checked && subsArray.push(item.key));
      }
    });
    const updatedSubsArray = subsArray.map((item) =>
      parseInt(item.replace('subItem_', ''), 10)
    );

    return { parentIds: keysArray, subOptionIds: updatedSubsArray };
  }

  const filterReset = (values, type, event = null) => {
    try {
      if (event) {
        event.stopPropagation();
      }

      if (values) {
        if (type && type.toUpperCase() === 'ACTIVITYSTATUS') {
          const updatedArray = values.map((item) => {
            return { ...item, checked: false };
          });

          stateDispatch({
            type: type,
            payload: updatedArray,
          });
        } else if (type === 'OrganizationLevel') {
          const updatedArray = values.map((item) => {
            return { ...item, checked: item.value === 'all' ? true : false };
          });
          stateDispatch({
            type: type,
            payload: updatedArray,
          });
        } else {
          const updatedArray = values.map((item) => {
            if (!isEmpty(item.subItems)) {
              item.subItems = item.subItems.map((sub) => {
                return { ...sub, checked: false };
              });
            }
            return { ...item, checked: false };
          });
          stateDispatch({
            type: type,
            payload: updatedArray,
          });
        }
      }
    } catch (err) {
      console.error('filterReset err: ', err);
    }
  };

  const getStateParentValue = (value, initValue = false) => {
    if (initValue) {
      const [item] = getStateIds(value).parentIds;
      return item;
    } else {
      return getStateIds(value).parentIds;
    }
  };

  const scrollToId = () => {
    document.getElementById('outcome-scroll') &&
      document.getElementById('outcome-scroll').scrollIntoView({
        behavior: 'smooth',

        block: 'nearest',
      });
  };

  const activityTypeOptionsCount = getStateIds(state.activityTypeOptions)
    .parentIds.length;
  const activityStatusCount = getStateIds(state.activityStatusOptions).parentIds
    .length;
  const venueOptionsCount = getStateIds(state.venueOptions).parentIds.length;
  const receptiveParticipantsCount = getStateIds(
    state.receptiveParticipantsOptions
  ).parentIds.length;
  const activeParticipantsCount = getStateIds(state.activeParticipantsOptions)
    .parentIds.length;
  const crossReferenceOptionsCount = getStateIds(state.crossReferenceOptions)
    .parentIds.length;
  const departmentOptionsCount = getStateIds(state.departmentOptions).parentIds
    .length;
  const unitOptionsCount = getStateIds(state.unitOptions).parentIds.length;
  const outcomeOptionsCount = getStateIds(state.outcomeOptions).parentIds
    .length;
  const goalsOptionsCount = getStateIds(state.goalsOptions).parentIds.length;

  return (
    <div className="advance-filter">
      <div className="filter-header">
        <label className="filter-text">Filter By :</label>
        <div>
          {/* <label className="filter-save">Save</label> */}

          <label className="filter-reset" onClick={clearAll}>
            Clear All
          </label>

          <Icon
            name="close"
            className="info-close"
            onClick={() => {
              dispatch(setSidebarType(SIDEBAR_TYPES[2], false));
            }}
          />
        </div>
      </div>
      <div className="filter-body">
        <Accordion fluid>
          {REPORT_TYPE !== 'outcomes-reported' &&
            REPORT_TYPE !== 'input-output-data' && (
              <>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  className="accordion-title"
                  onClick={handleClick}
                >
                  <div className="btn-flex">
                    <label className="filter-label">Report</label>
                    <Icon
                      name="dropdown"
                      className="dropdown-advanced-filter"
                    />
                  </div>
                </Accordion.Title>
                <Accordion.Content
                  active={activeIndex === 0}
                  className="accordion-content"
                >
                  <div style={{ display: ' flex', flexDirection: 'row' }}>
                    <Checkbox
                      className="check-box"
                      label="Used Data"
                      checked={state.usedDataOnly}
                      onChange={(e, { checked }) => {
                        stateDispatch({
                          type: 'UsedDataOnly',
                          payload: checked,
                        });
                        handleFilterChange({
                          usedDataOnly: checked,
                        });
                      }}
                    />
                  </div>
                </Accordion.Content>
                <Divider className="divider-margin" />
              </>
            )}
          {REPORT_TYPE !== 'management-summary' && (
            <>
              <Accordion.Title
                active={activeIndex === 1}
                index={1}
                className="accordion-title"
                onClick={handleClick}
              >
                <div className="btn-flex">
                  <label className="filter-label">Designation</label>
                  <Icon name="dropdown" className="dropdown-advanced-filter" />
                </div>
              </Accordion.Title>
              <Accordion.Content
                active={activeIndex === 1}
                className="accordion-content"
              >
                <RadioGroup
                  dataset={state.organizationLevelOptions}
                  parentStateHandler={(value) => {
                    stateDispatch({
                      type: 'OrganizationLevel',
                      payload: value,
                    });
                    handleFilterChange({
                      organisationLevel: getStateParentValue(value).toString(),
                    });
                  }}
                  // hideShowMore={false}
                />
              </Accordion.Content>
              <Divider className="divider-margin" />
            </>
          )}
          {/* Activity Status */}
          {REPORT_TYPE !== 'outcomes-reported' && (
            <>
              <Accordion.Title
                active={activeIndex === 2}
                index={2}
                className="accordion-title"
                onClick={handleClick}
              >
                <div className="btn-flex">
                  <label className="filter-label">Activity Status</label>
                  <div className="filter-reset-flex">
                    <div className="open-act-status">
                      {activeIndex == 2 && activityStatusCount !== 0 && (
                        <Label
                          horizontal
                          className="orange-reset"
                          onClick={(event) => {
                            filterReset(
                              state.activityStatusOptions,
                              'ActivityStatus',
                              event
                            );
                            handleFilterChange(
                              {
                                statuses: [],
                              },
                              event
                            );
                          }}
                        >
                          Clear
                        </Label>
                      )}
                      <Label horizontal className="blue-label">
                        {activityStatusCount}
                      </Label>
                    </div>
                  </div>
                  <Icon name="dropdown" className="dropdown-advanced-filter" />
                </div>
              </Accordion.Title>
              <Accordion.Content
                active={activeIndex === 2}
                className="accordion-content"
              >
                <ReportFilter
                  toggleDefaultValue={toggleDefaultValue}
                  type="ACTIVITY_STATUS"
                  reportType={REPORT_TYPE}
                  dataset={state.activityStatusOptions}
                  setToggleDefaultValue={setToggleDefaultValue}
                  parentStateHandler={(value) => {
                    stateDispatch({
                      type: 'ActivityStatus',
                      payload: value,
                    });
                    handleFilterChange({
                      statuses: getStateParentValue(value),
                    });
                  }}
                />
              </Accordion.Content>
              <Divider className="divider-margin" />
            </>
          )}
          {/* Activity Type */}
          <Accordion.Title
            active={activeIndex === 3}
            index={3}
            className="accordion-title"
            onClick={handleClick}
          >
            <div className="btn-flex">
              <label className="filter-label">Record</label>
              <div className="filter-reset-flex">
                <div className="open-act-type">
                  {activeIndex === 3 && activityTypeOptionsCount !== 0 && (
                    <Label
                      horizontal
                      className="orange-reset"
                      onClick={(event) => {
                        filterReset(
                          state.activityTypeOptions,
                          'Activity',
                          event
                        );
                        handleFilterChange(
                          {
                            activityTypes: [],
                          },
                          event
                        );
                      }}
                    >
                      Clear
                    </Label>
                  )}
                  <Label horizontal className="blue-label">
                    {/* Replace this with the actual count */}
                    {activityTypeOptionsCount}
                  </Label>
                </div>
              </div>
              <Icon name="dropdown" className="dropdown-advanced-filter" />
            </div>
          </Accordion.Title>
          <Accordion.Content
            active={activeIndex === 3}
            className="accordion-content"
          >
            <div>
              {state.activityTypeOptions.map((item, index) => (
                <div key={`activity_type_${index}`}>
                  <Checkbox
                    label={item.text}
                    className="check-box"
                    checked={item.checked}
                    onChange={(e, { checked }) => {
                      const newValue = state.activityTypeOptions.map(
                        (option) =>
                          option.key === item.key
                            ? { ...option, checked }
                            : option
                      );
                      stateDispatch({
                        type: 'Activity',
                        payload: newValue,
                      });
                      handleFilterChange({
                        activityTypes: getStateParentValue(newValue),
                      });
                    }}
                  />
                </div>
              ))}
            </div>
          </Accordion.Content>
          <Divider className="divider-margin" />
          <Accordion.Title
            active={activeIndex === 4}
            index={4}
            onClick={handleClick}
            className="accordion-title"
          >
            <div className="btn-flex">
              <label className="filter-label">Venue</label>
              <div className="filter-reset-flex">
                <div className="open-venue">
                  {activeIndex === 4 && venueOptionsCount !== 0 && (
                    <Label
                      horizontal
                      className="orange-reset"
                      onClick={(event) => {
                        filterReset(state.venueOptions, 'Venues', event);
                        handleFilterChange(
                          {
                            venues: [],
                          },
                          event
                        );
                      }}
                    >
                      Clear
                    </Label>
                  )}
                  <Label horizontal className="blue-label">
                    {venueOptionsCount}
                  </Label>
                </div>
              </div>
              <Icon name="dropdown" className="dropdown-advanced-filter" />
            </div>
          </Accordion.Title>
          {!isEmpty(state.venueOptions) ? (
            <Accordion.Content
              active={activeIndex === 4}
              className="accordion-content"
            >
              <div>
                {state.venueOptions.map((item, index) => {
                  return (
                    <div key={`venue_option_${index}`}>
                      <Checkbox
                        label={item.text}
                        className="check-box"
                        checked={item.checked}
                        onChange={(e, { checked }) => {
                          // const newValue = state.venueOptions.map(option =>
                          //   option.key === item.key
                          //     ? { ...option, checked }
                          //     : option
                          // );
                          handleVenueChange(checked, item.key);
                        }}
                      />
                      <Grid.Column className="sub-option-check">
                        {item.subItems.map((sub, index) => {
                          if (
                            sub.parentVenueId === item.value &&
                            item.checked
                          ) {
                            return (
                              <div key={`department_option_${index}`}>
                                <Checkbox
                                  key={`subItem_${sub.key}`}
                                  className="check-box"
                                  label={sub.text}
                                  checked={get(sub, 'checked', false)}
                                  onChange={(e, { checked }) => {
                                    handleVenueChange(checked, sub.key);
                                  }}
                                />
                              </div>
                            );
                          }
                        })}
                      </Grid.Column>
                    </div>
                  );
                })}
              </div>
            </Accordion.Content>
          ) : (
            <Accordion.Content
              active={activeIndex === 4}
              className="accordion-content"
            >
              <div>
                <p>No Venue Found</p>
              </div>
            </Accordion.Content>
          )}
          <Divider className="divider-margin" />
          <Accordion.Title
            active={activeIndex === 5}
            index={5}
            className="accordion-title"
            onClick={handleClick}
          >
            <div className="btn-flex">
              <label className="filter-label">Participants</label>
              <div className="filter-reset-flex">
                <div className="open-participant">
                  {activeIndex == 4 &&
                    receptiveParticipantsCount + activeParticipantsCount !==
                      0 && (
                      <Label
                        horizontal
                        className="orange-reset"
                        onClick={(event) => {
                          filterReset(
                            state.participantsOptions,
                            'Participants',
                            event
                          );
                          stateDispatch({
                            type: 'ReceptiveParticipants',
                            payload: state.receptiveParticipantsOptions.map(
                              (option) => ({ ...option, checked: false })
                            ),
                          });
                          stateDispatch({
                            type: 'ActiveParticipants',
                            payload: state.activeParticipantsOptions.map(
                              (option) => ({ ...option, checked: false })
                            ),
                          });
                          handleFilterChange(
                            {
                              participants: [],
                            },
                            event
                          );
                        }}
                      >
                        Clear
                      </Label>
                    )}
                  <Label horizontal className="blue-label">
                    {receptiveParticipantsCount + activeParticipantsCount}
                  </Label>
                </div>
              </div>
              <Icon name="dropdown" className="dropdown-advanced-filter" />
            </div>
          </Accordion.Title>
          {!isEmpty(state.receptiveParticipantsOptions) ||
          !isEmpty(state.activeParticipantsOptions) ? (
            <Accordion.Content
              active={activeIndex === 5}
              className="accordion-content"
            >
              <label className="filter-label-co">Receptive Participants</label>
              <ReportFilter
                toggle={false}
                horizontal={false}
                dataset={state.receptiveParticipantsOptions}
                parentStateHandler={(value) => {
                  stateDispatch({
                    type: 'ReceptiveParticipants',
                    payload: value,
                  });
                  handleFilterChange({
                    participants: getStateIds(
                      state.activeParticipantsOptions
                    ).parentIds.concat(getStateParentValue(value)),
                  });
                }}
              />
              <label className="filter-label-co">Active Participants</label>
              <ReportFilter
                toggle={false}
                horizontal={false}
                dataset={state.activeParticipantsOptions}
                parentStateHandler={(value) => {
                  stateDispatch({
                    type: 'ActiveParticipants',
                    payload: value,
                  });
                  handleFilterChange({
                    participants: getStateIds(
                      state.receptiveParticipantsOptions
                    ).parentIds.concat(getStateParentValue(value)),
                  });
                }}
              />
            </Accordion.Content>
          ) : (
            <Accordion.Content
              active={activeIndex === 5}
              className="accordion-content"
            >
              <div>
                <p>No Participants Found</p>
              </div>
            </Accordion.Content>
          )}
          <Divider className="divider-margin" />
          <Accordion.Title
            className="accordion-title"
            active={activeIndex === 6}
            index={6}
            onClick={handleClick}
          >
            {/* <div className="btn-flex">
              <label className="filter-label">Outcomes</label>
              <Icon name="dropdown" />
            </div> */}
            <div className="btn-flex">
              <label className="filter-label">Outcomes</label>
              <div className="filter-reset-flex">
                <div className="open-outcomes">
                  {REPORT_TYPE !== 'outcomes-reported' &&
                  activeIndex === 6 &&
                  outcomeOptionsCount ? (
                    <Label
                      horizontal
                      className="orange-reset"
                      onClick={(event) => {
                        filterReset(state.outcomeOption, 'Outcomes', event);
                        handleFilterChange(
                          {
                            outcomes: [],
                          },
                          event
                        );
                      }}
                    >
                      Clear
                    </Label>
                  ) : null}
                  {REPORT_TYPE !== 'outcomes-reported' && (
                    <Label horizontal className="blue-label">
                      {outcomeOptionsCount}
                    </Label>
                  )}
                </div>
              </div>
              <Icon name="dropdown" className="dropdown-advanced-filter" />
            </div>
          </Accordion.Title>
          {!isEmpty(state.outcomeOption) ? (
            <Accordion.Content
              active={activeIndex === 6}
              className="accordion-content"
            >
              <div>
                {isOutcomeReportedPage ? (
                  <RadioGroup
                    dataset={state.outcomeOption}
                    parentStateHandler={(value) => {
                      stateDispatch({
                        type: 'Outcome',
                        payload: value,
                      });
                      handleFilterChange({
                        outcome: getStateParentValue(value, true),
                      });
                    }}
                  />
                ) : (
                  <div>
                    {state.outcomeOptions.map((item, index) => (
                      <div key={`outcome_option_${index}`}>
                        <Checkbox
                          className="check-box"
                          label={item.text}
                          checked={item.checked}
                          onChange={(e, { checked }) => {
                            const newValue = state.outcomeOptions.map(
                              (option) =>
                                option.key === item.key
                                  ? { ...option, checked }
                                  : option
                            );
                            stateDispatch({
                              type: 'Outcomes',
                              payload: newValue,
                            });
                            handleFilterChange({
                              outcomes: getStateParentValue(newValue),
                            });
                          }}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Accordion.Content>
          ) : (
            <Accordion.Content
              active={activeIndex === 6}
              className="accordion-content"
            >
              <div>
                <p>No Outcome Found</p>
              </div>
            </Accordion.Content>
          )}
          {REPORT_TYPE === 'outcomes-reported' &&
            !isEmpty(state.outcomeOptions) && (
              <div id="outcome-scroll">
                <Divider className="divider-margin" />

                <Accordion.Title
                  active={activeIndex === 10}
                  className="accordion-title"
                  index={10}
                  onClick={handleClick}
                >
                  <div className="btn-flex">
                    <label className="filter-label">Method</label>
                    <div className="filter-reset-flex">
                      <div className="open-method"></div>
                    </div>
                    <Icon
                      name="dropdown"
                      className="dropdown-advanced-filter"
                    />
                  </div>
                </Accordion.Title>
                {!isEmpty(state.methodOptions) ? (
                  <Accordion.Content
                    active={activeIndex === 10}
                    className="accordion-content"
                  >
                    <RadioGroup
                      dataset={state.methodOptions}
                      parentStateHandler={(value) => {
                        stateDispatch({
                          type: 'Methods',
                          payload: value,
                        });
                        handleFilterChange({
                          method: getStateParentValue(value, true),
                        });
                      }}
                    />
                  </Accordion.Content>
                ) : (
                  <Accordion.Content
                    active={activeIndex === 10}
                    className="accordion-content"
                  >
                    <div>
                      <p>No Methods Found</p>
                    </div>
                  </Accordion.Content>
                )}
              </div>
            )}
          {/* <div id="outcome-scroll">
            {REPORT_TYPE === 'outcomes-reported' &&
              !isEmpty(state.outcomeOptions) && (
                <div>
                  <Divider className="divider-margin" />
                  <label className="filter-label">Method</label>
                </div>
              )}
          </div> */}
          <Divider className="divider-margin" />
          {/* Goal */}
          {!props.isProgram && (
            <>
              <Accordion.Title
                active={activeIndex === 7}
                className="accordion-title"
                index={7}
                onClick={handleClick}
              >
                <div className="btn-flex">
                  <label className="filter-label">Goal</label>
                  <div className="filter-reset-flex">
                    <div className="open-goal">
                      {activeIndex === 7 && goalsOptionsCount !== 0 && (
                        <Label
                          horizontal
                          className="orange-reset"
                          onClick={(event) => {
                            filterReset(state.goalsOptions, 'Goals', event);
                            handleFilterChange(
                              {
                                goals: [],
                              },
                              event
                            );
                          }}
                        >
                          Clear
                        </Label>
                      )}
                      <Label horizontal className="blue-label">
                        {goalsOptionsCount}
                      </Label>
                    </div>
                  </div>
                  <Icon name="dropdown" className="dropdown-advanced-filter" />
                </div>
              </Accordion.Title>
              {!isEmpty(state.goalsOptions) ? (
                <Accordion.Content
                  active={activeIndex === 7}
                  className="accordion-content"
                >
                  <div>
                    {state.goalsOptions.map((item, index) => (
                      <div key={`goals_option_${index}`}>
                        <Checkbox
                          className="check-box"
                          label={item.text}
                          checked={item.checked}
                          onChange={(e, { checked }) => {
                            const newValue = state.goalsOptions.map((option) =>
                              option.key === item.key
                                ? { ...option, checked }
                                : option
                            );
                            stateDispatch({
                              type: 'Goals',
                              payload: newValue,
                            });
                            handleFilterChange({
                              goals: getStateParentValue(newValue),
                            });
                            !isEmpty(getStateParentValue(newValue))
                              ? dispatch(
                                  getCrossReferenceByGoal(
                                    getStateParentValue(newValue)
                                  )
                                )
                              : stateDispatch({
                                  type: 'CrossReference',
                                  payload: [],
                                });
                            advancedFilterReset(REPORT_TYPE, 'crossReferences');
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </Accordion.Content>
              ) : (
                <Accordion.Content
                  active={activeIndex === 7}
                  className="accordion-content"
                >
                  <div>
                    <p>No Goals Found</p>
                  </div>
                </Accordion.Content>
              )}
              <Divider className="divider-margin" />
            </>
          )}

          {!isEmpty(state.crossReferenceOptions) && (
            <>
              <Accordion.Title
                active={activeIndex === 8}
                index={8}
                className="accordion-title"
                onClick={handleClick}
              >
                <div className="btn-flex">
                  <label className="filter-label">Cross Reference</label>
                  <div className="filter-reset-flex">
                    <div className="open-cross-ref">
                      {activeIndex === 8 &&
                        crossReferenceOptionsCount !== 0 && (
                          <Label
                            horizontal
                            className="orange-reset"
                            onClick={(event) => {
                              filterReset(
                                state.crossReferenceOptions,
                                'CrossReference',
                                event
                              );
                              handleFilterChange(
                                {
                                  crossReferences: [],
                                },
                                event
                              );
                            }}
                          >
                            Clear
                          </Label>
                        )}
                      <Label horizontal className="blue-label">
                        {crossReferenceOptionsCount}
                      </Label>
                    </div>
                  </div>
                  <Icon name="dropdown" className="dropdown-advanced-filter" />
                </div>
              </Accordion.Title>

              <Accordion.Content
                active={activeIndex === 8}
                className="accordion-content"
              >
                <div>
                  {state.crossReferenceOptions.map((item, index) => (
                    <div key={`cross_reference_option_${index}`}>
                      <Checkbox
                        className="check-box"
                        label={item.text}
                        checked={item.checked}
                        onChange={(e, { checked }) => {
                          const newValue = state.crossReferenceOptions.map(
                            (option) =>
                              option.key === item.key
                                ? { ...option, checked }
                                : option
                          );
                          stateDispatch({
                            type: 'CrossReference',
                            payload: newValue,
                          });
                          handleFilterChange({
                            crossReferences: getStateParentValue(newValue),
                          });
                        }}
                      />
                    </div>
                  ))}
                </div>
              </Accordion.Content>
              <Divider className="divider-margin add-padding" />
            </>
          )}
          {!isEmpty(state.departmentOptions) && (
            <>
              <Accordion.Title
                active={activeIndex === 9}
                index={9}
                className="accordion-title"
                onClick={handleClick}
              >
                <div className="btn-flex">
                  <label className="filter-label">Department</label>
                  <div className="filter-reset-flex">
                    <div className="open-department">
                      {activeIndex === 9 && departmentOptionsCount !== 0 && (
                        <Label
                          horizontal
                          className="orange-reset"
                          onClick={(event) => {
                            filterReset(
                              state.departmentOptions,
                              'Department',
                              event
                            );
                            handleFilterChange(
                              {
                                departments: [],
                              },
                              event
                            );
                          }}
                        >
                          Clear
                        </Label>
                      )}
                      <Label horizontal className="blue-label">
                        {departmentOptionsCount}
                      </Label>
                    </div>
                  </div>
                  <Icon name="dropdown" className="dropdown-advanced-filter" />
                </div>
              </Accordion.Title>
              <Accordion.Content
                active={activeIndex === 9}
                className="accordion-content"
              >
                <div>
                  {state.departmentOptions.map((item, index) => (
                    <div key={`department_option_${index}`}>
                      <Checkbox
                        className="check-box"
                        label={item.text}
                        checked={item.checked}
                        onChange={(e, { checked }) => {
                          const newValue = state.departmentOptions.map(
                            (option) =>
                              option.key === item.key
                                ? { ...option, checked }
                                : option
                          );
                          stateDispatch({
                            type: 'Department',
                            payload: newValue,
                          });
                          handleFilterChange({
                            departments: getStateParentValue(newValue),
                          });
                          setDepartmentUnit(getStateParentValue(newValue));
                          !isEmpty(getStateParentValue(newValue))
                            ? dispatch(getDepartmentFilterUnits(newValue))
                            : stateDispatch({ type: 'Units', payload: [] });
                          advancedFilterReset(REPORT_TYPE, 'units');
                        }}
                      />
                    </div>
                  ))}
                </div>
                {!isEmpty(state.unitOptions) && (
                  <>
                    <Divider className="divider-margin add-padding" />
                    <Accordion.Title
                      active={activeIndex === 9}
                      className="accordion-title"
                      index={9}
                      onClick={handleClick}
                    >
                      <div className="btn-flex">
                        <label className="filter-label">Department Unit</label>
                        <div className="filter-reset-flex">
                          {unitOptionsCount !== 0 && (
                            <Label
                              horizontal
                              className="orange-reset"
                              onClick={(event) => {
                                filterReset(state.unitOptions, 'Units', event);
                                handleFilterChange(
                                  {
                                    units: [],
                                  },
                                  event
                                );
                              }}
                            >
                              Clear
                            </Label>
                          )}
                          <Label horizontal className="blue-label">
                            {unitOptionsCount}
                          </Label>
                        </div>
                      </div>
                    </Accordion.Title>
                    <Accordion.Content
                      active={activeIndex === 9}
                      className="accordion-content"
                    >
                      <div>
                        {state.unitOptions.map((item, index) => (
                          <div key={`unit_option_${index}`}>
                            <Checkbox
                              className="check-box"
                              label={item.text}
                              checked={item.checked}
                              onChange={(e, { checked }) => {
                                const newValue = state.unitOptions.map(
                                  (option) =>
                                    option.key === item.key
                                      ? { ...option, checked }
                                      : option
                                );
                                stateDispatch({
                                  type: 'Units',
                                  payload: newValue,
                                });
                                handleFilterChange({
                                  units: getStateParentValue(newValue),
                                });
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </Accordion.Content>
                  </>
                )}
              </Accordion.Content>
              <Divider className="divider-margin" />
            </>
          )}
        </Accordion>
      </div>
    </div>
  );
};

export default AdvancedFilter;
