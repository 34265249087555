import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isEmpty, get } from '../../utils/lodash';
import { Segment, Radio, Grid } from 'semantic-ui-react';

import { CustomSegment } from '../../components';
import { helpTips } from '../../store/actions';
import './auth.scss';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tips: false,
    };
  }

  toggle = () => {
    const { helpTips } = this.props;
    this.setState({ tips: !this.state.tips }, () => {
      helpTips(this.state);
    });
  };

  componentDidMount() {
    const { user } = this.props;
    if (!isEmpty(user.options)) {
      this.setState({
        tips: get(user, 'options.tips'),
      });
    }
  }

  render() {
    const { account = false, helpTipsContent, user } = this.props;
    return (
      <CustomSegment
        tooltip={{
          user: user,
          helpTips: helpTipsContent,
          //TODO: change account help tip here
          id: account ? '204' : null,
        }}
        title="Help Tips"
        className="help-tips"
        extraMargin
      >
        <Segment className="content-segment">
          <Grid.Row>
            <Grid.Column>
              <div className="help-tips">
                <Radio
                  toggle
                  label="Show application help tips"
                  checked={this.state.tips}
                  onChange={this.toggle}
                />
              </div>
              <div className="help-tips">
                <Radio
                  toggle
                  label="Show on-boarding help tips"
                  checked={this.state['on-board-tips']}
                  onChange={this.onBoardingToggle}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Segment>
      </CustomSegment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    helpTipsContent: state.extra.activityHelpTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      helpTips,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
