import React from 'react';
import { OrganizationContractors } from './contractors';
import OrganizationPartners from './partners';
import { Menu, Tab } from 'semantic-ui-react';
import { CustomTooltip } from '../../../../components';
import { compact } from 'lodash';
import '../organization.scss';

const OrganisationContacts = (props) => {
  const generateTabs = () => {
    const { helpTips } = props;

    const panes = compact([
      {
        menuItem: (
          <Menu.Item>
            <CustomTooltip
              state={''}
              //TODO: change account help tip here
              contentObject={{ data: helpTips, key: '194' }}
              position="top left"
              // wide="very"
            >
              <label className="contacts-tab">Contractors</label>
            </CustomTooltip>
          </Menu.Item>
        ),

        route: 'contractors',
        render: () => (
          <Tab.Pane
            menu={{ secondary: true, pointing: true }}
            className="custom-tab"
          >
            <OrganizationContractors account />
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item>
            <CustomTooltip
              state={''}
              //TODO: change account help tip here
              contentObject={{ data: helpTips, key: '194' }}
              position="top left"
              // wide="very"
            >
              <label className="contacts-tab">Partners</label>
            </CustomTooltip>
          </Menu.Item>
        ),

        route: 'partners',
        render: () => (
          <Tab.Pane
            menu={{ secondary: true, pointing: true }}
            className="custom-tab"
          >
            <OrganizationPartners {...props} account />
          </Tab.Pane>
        ),
      },
    ]);

    return panes;
  };

  return (
    <div>
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={generateTabs()}
        className="organisation-contact-tab"
      />
    </div>
  );
};

export default OrganisationContacts;
