/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { isPermanentlyDeleteActivity } from '../../../../../../utils/activity';

import { stopPropagation } from '../../../../../../utils';
import { Dropdown, Modal } from 'semantic-ui-react';
import { get } from '../../../../../../utils/lodash';
import DeactivateLinkedActivityForm from '../forms/deactivateLinkedActivityForm';
import { DeactivateExternalLinked } from './deactivatExternalLinked';

export default class DeactivateLinkedActivity extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      confirmDeactivateModel: false,
      confirmedDeactivation: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  toggleConfirmDeactivateModel() {
    this.setState((prevState) => ({
      confirmDeactivateModel: !prevState.confirmDeactivateModel,
    }));
  }

  setConfirmedDeactivation(value) {
    this.setState({ modal: value });
  }

  toggleDeactivateOrConfirmModal() {
    const { activity, currentActivity } = this.props;

    const parentActivityOrganisationId = get(
      currentActivity,
      'owner.organisationId'
    );

    const linkedActivityOrganisationId = get(activity, 'owner.organisationId');

    const isExternalOrgActivity =
      parentActivityOrganisationId &&
      linkedActivityOrganisationId &&
      parentActivityOrganisationId !== linkedActivityOrganisationId;

    if (!activity.deleted && isExternalOrgActivity) {
      this.setState({ confirmDeactivateModel: true, modal: false });
    } else {
      this.setState({ confirmDeactivateModel: false, modal: true });
    }
  }

  render() {
    const { modal, confirmDeactivateModel } = this.state;
    const { activity, user } = this.props;
    const guestUserOrganisation = get(
      activity,
      'guestOwner.organisation',
      null
    );
    // FIXME: guestUserOrganisation = linked activity owner [this is used in some db level, that's why used this name]
    const permanentDelete = isPermanentlyDeleteActivity(
      guestUserOrganisation,
      user.organisationId
    );

    return (
      <>
        <Modal
          open={modal}
          // onOpen={() => this.toggle()}
          onClick={(e) => stopPropagation(e)}
          onFocus={(e) => stopPropagation(e)}
          closeIcon={
            <div
              className="new-activity-modal-close-icon"
              onClick={() => this.toggle()}
            >
              &times;
            </div>
          }
          size="tiny"
          trigger={
            <Dropdown.Item className="activity-actions-dropdown-view">
              <label
                className={
                  permanentDelete
                    ? 'activity-actions-text-warning'
                    : 'activity-actions-text'
                }
                onClick={() => this.toggleDeactivateOrConfirmModal()}
              >
                {activity.deleted ? 'Activate' : 'Deactivate'}
              </label>
            </Dropdown.Item>
          }
        >
          <Modal.Header>
            <div className="small-modal-header">
              {activity.deleted ? 'Activate' : 'Deactivate'} Activity -{' '}
              {activity.name}
            </div>
          </Modal.Header>
          <Modal.Content>
            <DeactivateLinkedActivityForm
              toggle={() => this.toggle()}
              guestUserOrganisation={guestUserOrganisation}
              permanentDelete={permanentDelete}
              {...this.props}
            />
          </Modal.Content>
        </Modal>
        {/* External deactivate confirmation modal */}
        <DeactivateExternalLinked
          confirmDeactivateModel={confirmDeactivateModel}
          toggleConfirmDeactivateModel={() =>
            this.toggleConfirmDeactivateModel()
          }
          setConfirmedDeactivation={(value) =>
            this.setConfirmedDeactivation(value)
          }
        />
      </>
    );
  }
}
