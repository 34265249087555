import { get } from 'lodash';
import React, { useState } from 'react';
import { Icon, Modal } from 'semantic-ui-react';
export const ViewListModal = ({
  title,
  data,
  isUnit = false,
  departmentList = false,
}) => {
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  return (
    <Modal
      open={modal}
      closeIcon={<Icon onClick={toggle} name="close" className="closeicon" />}
      size="small"
      className="add-ref-model"
      trigger={
        <label onClick={toggle} className="view-container-goals">
          View
        </label>
      }
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <div className="goal-list-view">
          {departmentList
            ? data.map((item) => (
                <label>{get(item, 'deptGoals.name', '')}</label>
              ))
            : data.map((goal) => (
                <label>
                  {isUnit && get(goal, 'goals.goalTitle', null)
                    ? get(goal, 'goals.goalTitle', '-')
                    : get(goal, 'goalTitle', '-')}
                </label>
              ))}
        </div>
      </Modal.Content>
    </Modal>
  );
};
