import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Segment, Search, Dropdown } from 'semantic-ui-react';
import moment from 'moment';
import React from 'react';
import { debounce, get } from '../../utils/lodash';
import { CustomSegment, CustomTable, EmptyContainer } from '../../components';
import { getBrowseActivities, getReports } from '../../store/actions';
import {
  getActivityStatus,
  getActivityType,
  threeDotSeparator,
} from '../../utils';
import './browse.scss';

class BrowseActivities extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      value: '',
      columns: [
        {
          width: 5,
          title: <div className="lib-header">Activity Name</div>,
          render: (data) => {
            return (
              <div className="activity-name">
                <div
                  className={`circle activity-${getActivityStatus(
                    data.statusId
                  )}`}
                />
                <div className="date-added">
                  <label>{data.name ? data.name : ''}</label>
                  <div className="date-created">
                    <div>
                      <label>Date Created:&nbsp;</label>
                      <font>
                        {data.createdDateTime
                          ? moment(data.createdDateTime).format('DD MMM YYYY')
                          : null}
                      </font>
                    </div>
                    <div className="edit-update">
                      <label>Last Edited:&nbsp;</label>
                      <font>
                        {data.lastEditedDateTime
                          ? moment(data.lastEditedDateTime).format(
                              'DD MMM YYYY'
                            )
                          : null}
                      </font>
                    </div>
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          width: 1,
          title: 'Type',
          render: (data) => {
            const actType = getActivityType(data);
            return actType.type;
          },
        },
        {
          width: 3,
          title: 'Organization',
          render: (data) => {
            return (
              <div className="desc-cell">
                <label>{get(data, 'owner.organisation.name', null)}</label>
                <br />
              </div>
            );
          },
        },
        {
          width: 6,
          title: 'Activity Summary',
          render: (data) => {
            return (
              <div className="desc-cell">
                <div>
                  {threeDotSeparator({
                    name: data.description,
                    length: 300,
                    isPopUp: false,
                  })}
                </div>
              </div>
            );
          },
        },

        {
          width: 1,
          title: '',
          render: (data) => {
            return (
              <div className="action-new">
                <Dropdown
                  icon="ellipsis vertical"
                  direction="left"
                  inline
                  selectOnBlur={false}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href={`/library/${data.id}/preview`}
                      target="_blank"
                      as="a"
                      text="Preview"
                      className="preview"
                    />
                    {/* <Dropdown.Item
                      text="Export"
                      onClick={() =>
                        props.getReports(
                          `/project/${data.id}/report/export`,
                          'reviewReport'
                        )
                      }
                    >
                      <label className="preview">Export Report</label>
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            );
          },
        },
      ],
    };
  }

  async componentDidMount() {
    try {
      const { currentPage, value } = this.state;
      await this.props.getBrowseActivities(
        'activityBrowse',
        currentPage,
        value
      );
    } catch (error) {
      // console.error('BrowseActivities -> componentDidMount -> error', error);
    }
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ value, currentPage: 1 }, () => {
      const { getBrowseActivities } = this.props;
      const { currentPage } = this.state;
      getBrowseActivities('activityBrowse', currentPage, value);
    });
  };

  pageChange(page) {
    const { getBrowseActivities } = this.props;
    const { value } = this.state;
    this.setState(
      {
        currentPage: page,
      },
      () => {
        getBrowseActivities('activityBrowse', page, value);
      }
    );
  }

  render() {
    const { value, columns, currentPage } = this.state;

    const { allActivities } = this.props;

    return (
      <CustomSegment
        title="Activity Browser"
        subTitle="You can view all completed activities in the system and download a
        printable version."
        rightHeader={
          <div className="library-act">
            <Search
              className="activitySearch"
              onSearchChange={debounce(this.handleSearchChange, 1000, {
                leading: true,
              })}
              value={value}
              placeholder="Search Activities"
            />
          </div>
        }
      >
        <div className="new-lib-arr">
          <Segment
            className="content-segment broswe-act"
            loading={
              this.props.getReportsLoading.reviewReport
                ? this.props.getReportsLoading.reviewReport
                : this.props.actPut.activityBrowse
            }
          >
            {allActivities.docs && allActivities.docs.length ? (
              <CustomTable
                header
                data={allActivities.docs}
                columns={columns}
                pagination
                handlePaginationChange={(page) => this.pageChange(page)}
                page={currentPage}
                noOfPages={allActivities.pages}
                customClass="lib-table"
                keyExtractor={(item, index) => item.id}
              />
            ) : (
              <EmptyContainer msg="No activities..." />
            )}
          </Segment>
        </div>
      </CustomSegment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    allActivities: state.activity.allActivities,
    actPut: state.activity.actPut,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getBrowseActivities,
      getReports,
    },
    dispatch
  );
};

export default BrowseActivities = connect(
  mapStateToProps,
  mapDispatchToProps
)(BrowseActivities);
