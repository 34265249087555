/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  AvCheckbox,
  AvCheckboxGroup,
  AvField,
  AvForm,
  AvRadio,
  AvRadioGroup,
} from 'availity-reactstrap-validation';
import { Grid, Segment, Tab, Icon, Menu, Button } from 'semantic-ui-react';
import {
  map,
  get,
  find,
  isEmpty,
  concat,
  uniq,
  flattenDeep,
  sum,
} from '../../../../utils/lodash';
import { CustomTooltip } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { updateEvaluationSelfAssess } from '../../../../store/actions';
import { removeEditable } from '../../../../utils';
import '../activity.scss';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import { EvaluationSelfAssessedChart } from '../../../../components/chartsWidgets';

const marks = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
};
const trackStyle = {
  backgroundColor: '#dad3d3c9',
  height: '15px',
  marginTop: '-16px',
};
const handleStyle = {
  height: '50px',
  width: '50px',
  marginTop: '-35px',
  marginLeft: process.env.REACT_APP_ENV_VALUE !== 'production' ? '-25px' : '',
  backgroundColor: '#ef8123',
  border: 'none',
};
const activeDotStyle = {
  borderColor: '#dad3d3c9',
  backgroundColor: '#dad3d3c9',
  height: '2rem',
  width: '2rem',
  border: 'none',
  marginLeft: '-0.875rem',
};

const NewEvaluationPlanSection = ({
  evalPlan,
  data,
  evalManage,
  currentActivity,
  isFileChanged,
  evalAssesmentTypes,
  evalReasons,
  assessmentTypes,
  setAssessmentTypes,
  focusPlanId,
  user,
  currentState,
  pageDisabled,
  setActualParticipantsEvaluated,
  ...props
}) => {
  const [participants, setParticipants] = useState(
    evalPlan.actualParticipantsEvaluated
      ? evalPlan.actualParticipantsEvaluated
      : 0
  );

  const dispatch = useDispatch();
  const handleAssessmentChange = (check, value, id) => {
    let index = assessmentTypes.indexOf(id);
    if (check === true) {
      assessmentTypes.push(id);
    } else {
      if (assessmentTypes.includes(id)) {
        if (index > -1) {
          assessmentTypes.splice(index, 1);
        }
      }
    }
    setAssessmentTypes(uniq(assessmentTypes));

    dispatch(
      updateEvaluationSelfAssess(currentActivity.id, evalPlan.id, {
        assessmentTypes: uniq(assessmentTypes),
        seriesParentId: evalPlan.seriesParentId,
        seriesPosition: evalPlan.seriesPosition,
      })
    );
  };

  const handleParticipants = async (value) => {
    setParticipants(value);
    dispatch(
      updateEvaluationSelfAssess(currentActivity.id, evalPlan.id, {
        actualParticipantsEvaluated: Number(value),
        seriesParentId: evalPlan.seriesParentId,
        seriesPosition: evalPlan.seriesPosition,
      })
    );
  };

  const handleMethodChange = (methodId) => {
    dispatch(
      updateEvaluationSelfAssess(currentActivity.id, evalPlan.id, {
        methodReason: methodId,
        seriesParentId: evalPlan.seriesParentId,
        seriesPosition: evalPlan.seriesPosition,
      })
    );
  };

  return (
    <Fragment>
      <div className="url-card  plan-documentation">
        <div className="obj-div">
          <div className="tabs-evaluation">
            <div className="outcomes-segment">
              <Grid.Row>
                <label className="self-eval-header method">
                  <strong>Self-assessed evaluation method </strong>
                </label>
                {props.setEval.question.methodHeading ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.setEval.question.methodHeading,
                    }}
                    className="survey-gap"
                  />
                ) : null}
                <div className="eval-method-container">
                  <label
                    className="self-eval-header"
                    id={`${focusPlanId}_methodReason`}
                  >
                    Reason for this evaluation method
                    <span className="required-asterisk">*</span>
                  </label>

                  <Segment
                    disabled={
                      currentActivity.statusId === 3 || pageDisabled
                        ? true
                        : false
                    }
                  >
                    {evalReasons && evalReasons.length > 0 && (
                      <AvRadioGroup
                        id={`${focusPlanId}_methodReason`}
                        name={`${focusPlanId}_methodReason`}
                        className="eval-checkBox"
                        value={evalPlan.methodReason}
                        validate={{
                          required: {
                            value: true,
                            errorMessage:
                              'Reason for evaluation method is required',
                          },
                        }}
                      >
                        <Grid>
                          {map(evalReasons, (reason, i) => (
                            <Grid.Column
                              className="survey-row"
                              key={i}
                              mobile={16}
                              tablet={8}
                              computer={5}
                            >
                              <div className="eval-method-radio">
                                {/* {reason.name} */}
                                <AvRadio
                                  key={reason.id}
                                  type="radio"
                                  label={reason.name}
                                  value={reason.id}
                                  onChange={() => handleMethodChange(reason.id)}
                                />
                              </div>
                            </Grid.Column>
                          ))}
                        </Grid>
                      </AvRadioGroup>
                    )}
                  </Segment>
                </div>
              </Grid.Row>
              <Grid.Row>
                <div className="eval-method-container">
                  <label
                    className="self-eval-header"
                    id={`${focusPlanId}_assessmentTypes`}
                  >
                    What is your assessment based on?
                    <span className="required-asterisk">*</span>
                  </label>
                  <Segment
                    disabled={
                      currentActivity.statusId === 3 || pageDisabled
                        ? true
                        : false
                    }
                  >
                    {evalAssesmentTypes && evalAssesmentTypes.length > 0 && (
                      <AvCheckboxGroup
                        id={`${focusPlanId}_assessmentTypes`}
                        name={`${focusPlanId}_assessmentTypes`}
                        className="eval-checkBox firefox-checkbox-color"
                        validate={{
                          required: {
                            value: assessmentTypes.length > 0 ? false : true,
                            errorMessage: 'Assessment basis is required',
                          },
                        }}
                      >
                        <Grid>
                          {map(evalAssesmentTypes, (types, index) => (
                            <Grid.Column
                              key={index}
                              mobile={16}
                              tablet={8}
                              computer={16}
                              className="grid-column-assessment"
                            >
                              <div className="eval-method-checkbox">
                                <AvCheckbox
                                  key={`eval-method-checkbox_${index}`}
                                  label={types.name}
                                  value={types.id}
                                  onChange={(e) =>
                                    handleAssessmentChange(
                                      e.target.checked,
                                      e.target.value,
                                      types.id
                                    )
                                  }
                                  checked={
                                    assessmentTypes.includes(types.id)
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </Grid.Column>
                          ))}
                        </Grid>
                      </AvCheckboxGroup>
                    )}
                  </Segment>
                </div>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <div className="disabled-padding">
                    <Segment
                      disabled={currentActivity.statusId === 3 ? true : false}
                    >
                      <div className="number-participants-div">
                        <CustomTooltip
                          className="number-participants-bottom"
                          position="bottom left"
                        >
                          <AvField
                            id={`${focusPlanId}_actualParticipantsEvaluated`}
                            name={`${focusPlanId}_actualParticipantsEvaluated`}
                            label={
                              <>
                                Number Of Participants Considered
                                <span className="required-asterisk">*</span>
                              </>
                            }
                            className={`number-participants-input ${
                              (get(data, 'collectionMethod') === 'LINK' ||
                                get(data, 'collectionMethod') === 'CSV') &&
                              get(data, 'method.id', 0) !== 9
                                ? 'participants-disabled'
                                : ''
                            }`}
                            disabled={
                              ((get(data, 'collectionMethod') === 'LINK' ||
                                get(data, 'collectionMethod') === 'CSV') &&
                                get(data, 'method.id', 0) !== 9) ||
                              pageDisabled
                                ? true
                                : false
                            }
                            value={participants}
                            type="number"
                            min="1"
                            validate={{
                              required: {
                                value: true,
                                errorMessage:
                                  'Number Of Participants Considered is required',
                              },
                            }}
                            onBlur={(event) => {
                              handleParticipants(event.target.value);
                            }}
                          />
                          {/* <label>
                            Recommended sample size is 102 participants
                          </label> */}
                        </CustomTooltip>
                        {/*  */}
                      </div>
                    </Segment>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const ActivitySurvey = ({
  index,
  question,
  objectiveId,
  evalView,
  allQuestions = false,
  cumulative,
  method,
  currentActivity,
  reason_focusId,

  pageDisabled,
  evalPlan,
}) => {
  const evaluationQuestion = get(evalPlan, 'evaluationQuestions', []).find(
    (item) => item.objectiveId === objectiveId
  );

  const dispatch = useDispatch();
  const onRatingChange = (rating, name) => {
    dispatch(
      updateEvaluationSelfAssess(currentActivity.id, evalPlan.id, {
        seriesParentId: evalPlan.seriesParentId,
        seriesPosition: evalPlan.seriesPosition,
        results: {
          id: evaluationQuestion.id,
          resultType: name,
          result: rating,
          cumulative,
        },
      })
    );
  };

  const onReasonChange = (reason) => {
    dispatch(
      updateEvaluationSelfAssess(currentActivity.id, evalPlan.id, {
        seriesParentId: evalPlan.seriesParentId,
        seriesPosition: evalPlan.seriesPosition,
        reasonResult: {
          id: evaluationQuestion.id,
          reason,
        },
      })
    );
  };

  const getResultValue = (results, isPre = false) => {
    let result = results[0];
    if (isPre) {
      result = results[1];
    }

    const objIndex = result.findIndex((item) => item === 1) + 1;
    if (objIndex === -1) {
      return 1;
    }

    return objIndex;
  };

  if (isEmpty(evaluationQuestion)) return null;

  return (
    <div>
      <div>
        {evalView ? null : (
          <div>
            {question.methodHeading && !index ? (
              objectiveId === 913 || objectiveId === 914 ? (
                <div className="survey-gap">
                  <div>
                    This section is to enable Art, Not Apart to understand more
                    about the outcomes of its activities.
                    <br></br>
                    We ask you to consider the impact of the festival on you.
                    <br></br>
                    <br></br>
                  </div>
                </div>
              ) : objectiveId === 909 || objectiveId === 910 ? (
                <div className="survey-gap">
                  {allQuestions && objectiveId === 910 ? null : (
                    <div>
                      <span>
                        This survey is based on measurable outcomes developed by
                        Cultural Development Network as part of their ongoing
                        research. Participating in this research will enable{' '}
                        <label className="bold-text">Art, Not Apart</label> to
                        understand more about the outcomes of its activities and
                        areas for improvement.
                      </span>
                      <br></br>
                      <br></br>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: question.methodHeading,
                  }}
                  className="survey-gap"
                />
              )
            ) : null}
            {question.heading ? (
              objectiveId === 913 || objectiveId === 914 ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: question.heading.replace('activity', 'festival'),
                  }}
                  className="survey-gap"
                />
              ) : objectiveId === 909 || objectiveId === 910 ? (
                <div className="survey-gap">
                  {allQuestions && objectiveId === 910 ? null : (
                    <div>
                      <span>
                        As an artist in Art, Not Apart, please consider the
                        following questions carefully.
                      </span>
                      <br></br>
                      <br></br>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: question.heading,
                  }}
                  className="survey-gap"
                />
              )
            ) : null}
          </div>
        )}

        {question.question1 ? (
          objectiveId === 913 || objectiveId === 914 ? (
            <div
              dangerouslySetInnerHTML={{
                __html: question.question1.replace('activity', 'festival'),
              }}
              className="survey-gap"
            />
          ) : objectiveId === 909 ? (
            <div className="survey-gap">
              <div>
                <h3>Question 1. Knowledge, ideas or insight.</h3>
                <span>
                  By{' '}
                  <label className="bold-text">
                    knowledge, ideas or insight
                  </label>{' '}
                  we mean intellectual stimulation, critical reflection,
                  creative thinking or deeper understanding.
                </span>
                <br />
                <span>
                  Thinking about your participation in this festival, on a scale
                  of 1 to 10, where 1 is not at all and 10 is the most
                  imaginable, how would you rate the amount of{' '}
                  <label className="bold-text">
                    knowledge, ideas or insight
                  </label>{' '}
                  you have gained?
                </span>
                <br />
                <br />
              </div>
            </div>
          ) : objectiveId === 910 ? (
            <div className="survey-gap">
              <div>
                <h3>Question 2: Agency and Voice</h3>
                <span>
                  By{' '}
                  <label className="bold-text">agency and voice enabled</label>{' '}
                  we mean that you have the freedom to express your opinions
                  publicly and they are welcomed, respected, and valued.
                </span>
                <br />
                <span>
                  Having engaged with the festival, on a scale of 1-10, where 1
                  is not at all and 10 is the most imaginable, how much agency
                  and voice do you feel you have{' '}
                  <label className="bold-text">now?</label>
                </span>
                <br />
                <br />
              </div>
              <hr></hr>
            </div>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: question.question1,
              }}
              className="survey-gap"
            />
          )
        ) : get(question, 'question1') ? (
          <div>
            {question.heading ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: question.heading,
                }}
                className="survey-gap"
              />
            ) : null}

            <div
              dangerouslySetInnerHTML={{
                __html: question.question1,
              }}
              className="survey-gap"
            />
          </div>
        ) : null}
        <div>
          <Segment
            disabled={
              currentActivity.statusId === 3 || pageDisabled ? true : false
            }
          >
            {Boolean(cumulative) ? (
              <div className="survey-gap">
                <label>Select your response.</label>
                <>
                  <div className="slider-margin">
                    <Slider
                      min={0.5}
                      defaultValue={evaluationQuestion.postMean}
                      onAfterChange={(rating) =>
                        onRatingChange(rating, 'result1')
                      }
                      marks={marks}
                      max={10.5}
                      step={null}
                      trackStyle={trackStyle}
                      handleStyle={handleStyle}
                      activeDotStyle={activeDotStyle}
                      dotStyle={activeDotStyle}
                      railStyle={trackStyle}
                    />
                  </div>

                  <div className="slider-info-text">
                    <label>Not at all</label>
                    <label>Most imaginable</label>
                  </div>
                </>
                {/* On a scale of 1 to 10 from not at all to most imaginable. */}
                {question.question2 ? (
                  objectiveId === 913 || objectiveId === 914 ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: question.question2.replace(
                          'activity',
                          'festival'
                        ),
                      }}
                      className="survey-gap"
                    />
                  ) : objectiveId === 910 ? (
                    <div className="survey-gap">
                      <div>
                        <span>
                          Thinking back to{' '}
                          <label className="bold-text">before</label> you
                          engaged with the festival, how much agency and voice
                          do you feel you had{' '}
                          <label className="bold-text">then</label>, using the
                          same scale of 1-10?
                        </span>
                        <br />
                        <br />
                      </div>
                    </div>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: question.question2,
                      }}
                      className="survey-gap"
                    />
                  )
                ) : get(question, 'question2') ? (
                  <div>
                    {question.heading ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: question.heading,
                        }}
                        className="survey-gap"
                      />
                    ) : null}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: question.question2,
                      }}
                      className="survey-gap"
                    />
                  </div>
                ) : null}
                <label>
                  Select your <strong>before</strong> response.
                </label>
                <>
                  <div className="slider-margin">
                    <Slider
                      min={0.5}
                      defaultValue={evaluationQuestion.preMean}
                      onAfterChange={(rating) =>
                        onRatingChange(rating, 'result2')
                      }
                      marks={marks}
                      max={10.5}
                      step={null}
                      trackStyle={trackStyle}
                      handleStyle={handleStyle}
                      activeDotStyle={activeDotStyle}
                      dotStyle={activeDotStyle}
                      railStyle={trackStyle}
                    />
                  </div>
                  <div className="slider-info-text">
                    <label>Not at all</label>
                    <label>Most imaginable</label>
                  </div>
                </>
                {/* On a scale of 1 to 10 from not at all to most imaginable. */}
              </div>
            ) : (
              <div className="survey-gap">
                <label>Select your response.</label>
                <>
                  <div className="slider-margin">
                    <Slider
                      min={0.5}
                      defaultValue={evaluationQuestion.postMean}
                      onAfterChange={(rating) =>
                        onRatingChange(rating, 'result1')
                      }
                      marks={marks}
                      max={10.5}
                      step={null}
                      trackStyle={trackStyle}
                      handleStyle={handleStyle}
                      activeDotStyle={activeDotStyle}
                      dotStyle={activeDotStyle}
                      railStyle={trackStyle}
                    />
                  </div>
                  <div className="slider-info-text">
                    <label>Not at all</label>
                    <label>Most imaginable</label>
                  </div>
                </>
                {/* On a scale of 1 to 10 from not at all to most imaginable. */}
              </div>
            )}
          </Segment>
        </div>
        {get(method, 'method', null) === 'Intercept' ? null : (
          <Grid className="custom-gen-questions">
            <Grid.Row>
              <Grid.Column computer={8} mobile={16}>
                <AvField
                  name={`${objectiveId}_Reason`}
                  id={reason_focusId}
                  className="custom-gen-questions-textarea-style"
                  type="textarea"
                  label={
                    <>
                      {Boolean(cumulative)
                        ? `Can you tell us more about why you gave these two ratings?`
                        : 'Can you tell us more about why you gave this rating?'}
                      <span className="required-asterisk">*</span>
                    </>
                  }
                  disabled={
                    currentActivity.statusId === 3 || pageDisabled
                      ? true
                      : false
                  }
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Field is required',
                    },
                  }}
                  value={evaluationQuestion.selfAssessmentReason}
                  onBlur={(e) => onReasonChange(e.target.value)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        {objectiveId === 914 ? (
          // || objectiveId === 910
          <div>
            <Grid>
              <Grid.Row>
                <Grid.Column computer={8}>
                  <AvField
                    name={`${objectiveId}_Postcode`}
                    style={{ minHeight: '60px' }}
                    type="text"
                    label="Please tell us your postcode"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Row>
                <Grid.Column computer={8}>
                  <AvField
                    name={`${objectiveId}_Email`}
                    style={{ minHeight: '60px' }}
                    type="email"
                    label="If you’d like to win tickets to Australian Dance Party (political dance), Escape Ferocity (techno parties), Cellar Door (wine market) and Sound and Fury (performance art party) events, leave your email so you can receive this prize."
                  />
                </Grid.Column>
              </Grid.Row>
              <div className="survey-gap">
                This will only be used if you win a prize to contact you, that’s
                it.
                <br></br>
                Thank you.
              </div>
            </Grid>
          </div>
        ) : objectiveId === 910 ? (
          <div>
            <Grid>
              <Grid.Row>
                <Grid.Column computer={8}>
                  <AvField
                    name={`${objectiveId}_Comment`}
                    style={{ minHeight: '100px' }}
                    type="textarea"
                    label="Do you have any other comments about the festival?"
                  />
                </Grid.Column>
              </Grid.Row>
              <div className="survey-gap">
                That’s it – thanks so much.
                <br></br>
                The festival photos are available for download on the next page.
              </div>
            </Grid>
          </div>
        ) : null}
      </div>
    </div>
  );
};

function ActivityEvaluationSurvey({
  evalPlan,
  currentActivity,
  isDisabled,
  checked,
  setChartToReport,
  setToggle,
  ...props
}) {
  const [one = {}] = get(evalPlan, 'evaluationQuestions', []);
  const objectiveId = get(one, 'objectiveId', null);

  const [objectives, setObjectives] = useState([]);
  const [selectedObjective, setObjective] = useState(objectiveId);
  const [activeIndex, setActiveIndex] = useState(0);

  const [index, setIndex] = useState(0);
  const [assessmentTypes, setAssessmentTypes] = useState(
    evalPlan.assessmentTypes ? evalPlan.assessmentTypes.map((item) => item) : []
  );

  // unique focus id for each self assess evaluation plan
  const focusPlanId = evalPlan.id;
  const reason_focusId = `${focusPlanId}_${selectedObjective}_Reason`;

  const evaluationSelfAssessLoading = useSelector(
    (state) => state.activity.evaluationSelfAssessLoading
  );

  useEffect(() => {
    if (selectedObjective) {
      const getObjectives = map(
        get(evalPlan, 'evaluationQuestions', []),
        (obj) => {
          return {
            key: obj.objectiveId,
            text: get(obj, 'outcome.title', '-'),
            value: obj.objectiveId,
          };
        }
      );

      setObjectives(getObjectives);
      includeReportHandler(selectedObjective);
    }
  }, []);

  const evaluationQuestion = find(
    evalPlan.evaluationQuestions,
    (evaluation) => evaluation.objectiveId === selectedObjective
  );

  const evaluation = evaluationQuestion
    ? evaluationQuestion
    : {
        heading: '',
        methodHeading: '',
        question1: '',
        question2: '',
        objectiveId: '',
        outcome: '',
        method: '',
        cumulative: '',
      };

  const isCheckBoxCompleted = useCallback((objective) => {
    const item = evalPlan.evaluationQuestions.find(
      (item) => item.objectiveId === objective.key
    );

    const output =
      evaluationQuestion && item.selfAssessmentReason ? true : false;
    return output;
  });

  const changeObjective = (e, data) => {
    let objectiveId = objectives[data.activeIndex].value;

    setObjective(objectiveId);
    setActiveIndex(data.activeIndex);
    includeReportHandler(objectiveId);
  };

  const setEval = {
    question: {
      heading: evaluation.heading ? removeEditable(evaluation.heading) : '',
      methodHeading: evaluation.methodHeading
        ? removeEditable(evaluation.methodHeading)
        : '',
      question1: evaluation.question1
        ? removeEditable(evaluation.question1)
        : '',
      question2: evaluation.question2
        ? removeEditable(evaluation.question2)
        : '',
    },
    objectiveId: evaluation.objectiveId,
    outcome: evaluation.outcome,
    method: evaluation.method,
    cumulative: evaluation.cumulative,
  };

  const { evalReasons, evalAssesmentTypes, pageDisabled } = props;
  const renderTabs = () => {
    const outcomes = map(objectives, (objective, i) => {
      return {
        menuItem: (
          <Menu.Item
            className="activity-goal no-print"
            key={`project.edit.start${i}`}
          >
            <div>
              <span className="customStep-icon">
                {isCheckBoxCompleted(objective) ? (
                  <Icon
                    name="check"
                    className={
                      isCheckBoxCompleted(objective)
                        ? 'outcomes-obj-checked-icon'
                        : 'outcomes-obj-unchecked-icon'
                    }
                    circular
                    size="small"
                  />
                ) : (
                  <Icon
                    name="info"
                    className="outcomes-obj-error-icon"
                    circular
                    size="small"
                  />
                )}
              </span>
              {get(objective, 'text', '-')}
            </div>
          </Menu.Item>
        ),

        render: () => (
          <Tab.Pane
            key={i}
            menu={{ secondary: true, pointing: true }}
            className="outcomes-segment"
          >
            <div className="url-card ">
              {map(evalPlan.evaluationQuestions, (evaluation, i) => {
                if (evaluation.objectiveId === selectedObjective) {
                  setIndex(i);

                  return (
                    <ActivitySurvey
                      key={evaluation.objectiveId}
                      evalPlan={evalPlan}
                      evalView={{ evalReasons, evalAssesmentTypes }}
                      data={find(
                        evalPlan.evaluationQuestions,
                        (evaluation) =>
                          evaluation.objectiveId === selectedObjective
                      )}
                      reason_focusId={reason_focusId}
                      pageDisabled={pageDisabled}
                      currentActivity={currentActivity}
                      {...setEval}
                      {...props}
                    />
                  );
                }
              })}
            </div>
          </Tab.Pane>
        ),
      };
    });
    const newArray = concat(outcomes);
    return newArray;
  };

  const scrollToId = (id) => {
    id &&
      document.getElementById(id) &&
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
  };

  const includeReportHandler = (objectiveId) => {
    const currentPlan = get(evalPlan, 'evaluationQuestions', []).find(
      (item) => Number(item.objectiveId) === Number(objectiveId)
    );

    const ignoreAPISave = true;
    setChartToReport(
      get(currentPlan, 'inReport', false),
      objectiveId,
      ignoreAPISave
    );
  };
  const setNextObjective = () => {
    const objectiveId = objectives[1].value;
    setActiveIndex(1);
    setObjective(objectiveId);
    scrollToId(focusPlanId);

    includeReportHandler(objectiveId);
  };

  const setPrevObjective = () => {
    const objectiveId = objectives[0].value;
    setActiveIndex(0);
    setObjective(objectiveId);
    scrollToId(focusPlanId);

    includeReportHandler(objectiveId);
  };

  if (!evaluationQuestion) {
    return null;
  }

  const defaultValues = { assessmentTypes };
  const plaData = find(
    evalPlan.evaluationQuestions,
    (evaluation) => evaluation.objectiveId === selectedObjective
  );

  const evaluationQuestions = evalPlan.evaluationQuestions;
  const evaluationQuestion1 = evaluationQuestions[0];
  const evaluationQuestion2 =
    evaluationQuestions.length === 2 ? evaluationQuestions[1] : null;

  const isMetEvaluationQuestion1 =
    get(evaluationQuestion1, 'selfAssessmentReason', null) &&
    sum(flattenDeep(get(evaluationQuestion1, 'participantResponse', []))) !== 0;

  const isMetEvaluationQuestion2 =
    !evaluationQuestion2 ||
    (get(evaluationQuestion2, 'selfAssessmentReason', null) &&
      sum(flattenDeep(get(evaluationQuestion2, 'participantResponse', []))) !==
        0);

  const currentEvaluation = evaluationQuestions.find(
    (question) => question.objectiveId === selectedObjective
  );

  return (
    <div className="form-background">
      <AvForm
        model={defaultValues}
        id={`form_${evalPlan.id}`}
        name={`form_${evalPlan.id}`}
      >
        <div id={`newEvaluationPlanSection_${focusPlanId}`}>
          <NewEvaluationPlanSection
            currentActivity={currentActivity}
            focusPlanId={focusPlanId}
            setEval={setEval}
            assessmentTypes={assessmentTypes}
            setAssessmentTypes={setAssessmentTypes}
            isDisabled={isDisabled}
            evalPlan={evalPlan}
            {...props}
          />
        </div>
        <Tab
          id={`${focusPlanId}`}
          activeIndex={activeIndex}
          className="tabs-graph"
          menu={{ secondary: true, pointing: true }}
          panes={renderTabs()}
          onTabChange={(e, data) => changeObjective(e, data)}
        />

        {index === 0 ? (
          evalPlan.evaluationQuestions.length > 1 ? (
            <Button
              type="button"
              className="ui customColor-custom button outcome-btn"
              onClick={() => {
                setNextObjective();
              }}
            >
              Next
            </Button>
          ) : null
        ) : (
          <Button
            type="button"
            className="ui customColor-custom button outcome-btn"
            onClick={() => {
              setPrevObjective();
            }}
          >
            Back
          </Button>
        )}
      </AvForm>
      <Segment
        disabled={currentActivity.statusId === 3 ? true : false}
        loading={evaluationSelfAssessLoading}
      >
        {evalPlan.actualParticipantsEvaluated &&
        isMetEvaluationQuestion1 &&
        isMetEvaluationQuestion2 ? (
          <>
            <EvaluationSelfAssessedChart
              objectiveId={selectedObjective}
              checked={checked}
              actualParticipantsEvaluated={get(
                currentEvaluation,
                'actualParticipantsEvaluated',
                0
              )}
              totalParticipants={get(currentEvaluation, 'totalParticipants', 0)}
              isDisabled={isDisabled}
              setChartToReport={setChartToReport}
              response={get(currentEvaluation, 'participantResponse', null)}
            />
          </>
        ) : null}
      </Segment>
    </div>
  );
}

export default ActivityEvaluationSurvey;
