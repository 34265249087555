/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { max } from '../../../../utils/lodash';
import { Grid, Button } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

export const SelfAssessedTrendChart = ({
  response,
  activityCount = 0,
  totalParticipants = 0,
  totalRespondents = 0,
  inReportLbl = false, // to identify in report radio btn available
  isInReport = false, // to identify this chart included in given outcome report
  isDisabledInReportGrpBtn = false, // to identify this chart include in burton disable
  ...props
}) => {
  const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [dataset, setDataset] = useState({ labels: [], datasets: [] });

  const [inReport, setInReport] = useState(isInReport);

  useEffect(() => {
    setInReport(isInReport);
  }, [isInReport]);

  const loadingIncludeIn = useSelector(
    (state) => state.report.includeInReportLoading
  );

  const average = (data, yMax) => {
    var totalParticipants = data.reduce(
      (total, value) => total + parseFloat(value),
      0
    );
    var denominator = 10 * totalParticipants;
    var total = data.reduce(
      (sum, value, index) => sum + parseFloat(value) * (index + 1),
      0
    );
    var average = (total / denominator) * 10;
    average = isNaN(average) ? 0 : parseFloat(average.toFixed(2));

    return [
      { x: average, y: 0 },
      { x: average, y: yMax },
    ];
  };

  const options = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.1,
      },
    },
    interaction: {
      intersect: false,
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
        labels: {
          font: {
            size: 14,
            family: 'Inter',
          },
        },
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        // custom: function(tooltip) {
        //   if (!tooltip) return;
        //   tooltip.displayColors = false;
        // },
        // callbacks: {
        //   label: context => {
        //     if (
        //       isEmpty(context.chart.data.datasets[context.datasetIndex].label)
        //     )
        //       return;
        //     return `EvalPlan - ${
        //       context.chart.data.datasets[context.datasetIndex].evalPlan
        //     }   ${'Activity'} Name - ${
        //       context.chart.data.datasets[context.datasetIndex].label
        //     }`;
        //   },
        //   title: () => {
        //     return '';
        //   },
        // },
        // displayColors: false,
      },
    },
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        display: true,
        ticks: {
          max: 10,
          min: 1,
          stepSize: 1,
          font: {
            size: 12,
            family: 'Inter',
            weight: '400',
          },
          callback: function (value, index, values) {
            if (value === 1) {
              return ['1', 'Not at All'];
            } else if (value === 10) {
              return ['10', 'Most Imaginable'];
            }
            return value;
          },
        },
        title: {
          display: true,
          // text: 'Evaluated Score',
          font: {
            size: 14,
            family: 'Inter',
          },
        },
      },
      y: {
        display: true,
        ticks: {
          min: 0,
          precision: 0,
          font: {
            size: 12,
            family: 'Inter',
            weight: '400',
          },
        },
        title: {
          display: true,
          text: 'Average Number of Respondents',
          font: {
            size: 14,
            family: 'Inter',
          },
        },
      },
    },
  };

  useEffect(() => {
    createLineChart();
  }, [response]);

  const createLineChart = () => {
    let maxResponse = 0;
    if (response) {
      maxResponse = Math.max(...response.map((res) => max(res)));
    }
    try {
      let dataSets = [
        {
          label: 'Response',
          data: response[0],
          backgroundColor: 'rgba(253, 143, 88, 0.5)',
          borderColor: '#F8CCA4',
          fill: true,
          pointBackgroundColor: '#fff',
          pointBorderColor: 'rgba(253, 143, 88, 0.5)',
          pointHoverBackgroundColor: 'rgba(253, 143, 88, 0.5)',
          tension: 0.2,
        },
      ];

      dataSets.push({
        label: 'Average',
        data: average(response[0], maxResponse),
        fill: true,
        backgroundColor: '#d16401',
        borderColor: '#d16401',
        type: 'line',
      });

      if (response.length === 2) {
        dataSets = [
          {
            label: 'Average',
            data: average(response[0], maxResponse),
            fill: true,
            backgroundColor: '#d16401',
            borderColor: '#d16401',
            type: 'line',
          },
          {
            label: 'Average',
            data: average(response[1], maxResponse),
            fill: true,
            backgroundColor: '#2F80ED',
            borderColor: '#2F80ED',
            type: 'line',
          },
          {
            label: 'After Response',
            data: response[0],
            pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
            type: 'line',
            backgroundColor: 'rgba(253, 143, 88, 0.5)',
            borderColor: '#F8CCA4',
            fill: true,
            pointBackgroundColor: '#fff',
            pointBorderColor: 'rgba(253, 143, 88, 0.5)',
            pointHoverBackgroundColor: 'rgba(253, 143, 88, 0.5)',
            tension: 0.2,
          },
          {
            label: 'Before Response',
            data: response[1],
            pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
            type: 'line',
            backgroundColor: 'rgba(47, 128, 237, 0.5)',
            borderColor: '#97BFF6',
            fill: true,
            pointBackgroundColor: '#fff',
            pointBorderColor: 'rgba(47, 128, 237, 0.5)',
            pointHoverBackgroundColor: 'rgba(47, 128, 237, 0.5)',
            tension: 0.2,
          },
        ];
      }
      setDataset({
        labels: labels,
        datasets: dataSets,
      });
    } catch (error) {
      console.error(
        '🚀 ~ file: selfAssessedSeparateChart.js:277 ~ createLineChart ~ error:',
        error
      );
    }
  };

  const onChangeInReport = () => {
    const _inReport = !inReport;
    setInReport(_inReport);

    props && props.onChangeInReport && props.onChangeInReport(_inReport);
  };

  return (
    <div>
      <div id={`self-assessed-post-pre-trend-chart`}>
        <div className="outcomes-summary-chart-div">
          <div className="chart-header my-4 d-flex">
            Self Assessed Aggregate
            {inReportLbl && (
              <div
                className="ml-auto mr-3"
                style={{ display: ' flex', flexDirection: 'row' }}
              >
                <div className="checkbox-container-include-in-report">
                  <div className="include-in-report-label">
                    Include in report
                  </div>
                  <div className="button-include">
                    <Button.Group
                      className="btn-include-in-report"
                      onClick={() =>
                        !isDisabledInReportGrpBtn && onChangeInReport()
                      }
                      disabled={isDisabledInReportGrpBtn}
                    >
                      <Button
                        active={inReport}
                        disabled={isDisabledInReportGrpBtn}
                      >
                        Yes
                      </Button>
                      <Button
                        active={!inReport}
                        disabled={isDisabledInReportGrpBtn}
                      >
                        No
                      </Button>
                    </Button.Group>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* TODO: Need to create header */}
          {/* <CustomReportHeader
            isSelfAssessedMethod={true}
            date={date}
            type={type}
            chartTypeLabel={chartTypeLabel}
            outcome={outcome}
            evalPlan={evalPlan}
            da={{ summary }}
            evalPlansCount={evalPlansCount}
            {...props}
          /> */}
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <div
              style={{
                backgroundColor: '#fffff',
                display: 'flex',
                border: '1px solid #e0e0e0',
                borderBottom: 'none',
                padding: 12,
              }}
            >
              <div style={{ flex: 1 }}>Activity Count : {activityCount}</div>
              <div style={{ flex: 1 }}>Participants : {totalParticipants}</div>
              <div style={{ flex: 2 }}>Respondents :{totalRespondents}</div>
              <div style={{ flex: 2 }}>
                Respondent Percentage :{' '}
                {((totalRespondents / totalParticipants) * 100).toFixed(2)}%
              </div>
            </div>
          </Grid.Column>
          <div className="report-border-container">
            <Line options={options} data={dataset} />
          </div>
        </div>
      </div>
    </div>
  );
};
