/* TODO: Delete this when trial ended
 * New grant application trial
 */
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import {
  grantTrialDefinedTexts,
  grantTrialProgramProjectIds,
} from '../utils/pageText';

const ProjectText = ({ defaultText, textId, additionalTextValue }) => {
  const activity = useSelector((state) => state.activity);
  const projectId = get(activity, 'currentActivity.parentId', null);
  const isTrialProject = grantTrialProgramProjectIds.includes(projectId);

  let textValue = null;
  if (isTrialProject) {
    const projectTextsData = grantTrialDefinedTexts(additionalTextValue);
    const projectTextItem = projectTextsData.find(
      (item) => item.id === Number(textId)
    );
    textValue = get(projectTextItem, 'text', null);
  }

  if (!textValue) {
    textValue = defaultText;
  }

  return textValue;
};

export default ProjectText;
