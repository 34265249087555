import './auth.scss';

import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Grid } from 'semantic-ui-react';

import { APP_NAME } from '../../utils/constant';
import { getForgotVerification, resetPassword } from '../../store/actions';
import { Link } from 'react-router-dom';
import PasswordChange from '../../components/passwordChange';

class ResetPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    document.title = `Reset Password | ${APP_NAME}`;
    try {
      const {
        getForgotVerification,
        match: {
          params: { id = null },
        },
        navigate,
      } = this.props;
      await getForgotVerification(id, navigate);
    } catch (error) {
      console.error(error);
    }
  }

  async handleValidSubmit(values) {
    const {
      resetPassword,
      match: {
        params: { id = null },
      },
      navigate,
    } = this.props;
    await resetPassword(
      {
        token: id,
        password: values.password,
      },
      navigate
    );
  }

  render() {
    const { resetPassLoading } = this.props;
    return (
      <Grid.Row>
        {/* <Grid.Column mobile={16} tablet={16} computer={8} />
        <Grid.Column mobile={16} tablet={16} computer={8}> */}
        <Container className="right-heading-container">
          {/* <div className="heading-row"> */}
          <label className="headerText">Create New Password</label>
          <br></br>
          <>
            <label className="headerDescription">
              Set a new password for your account
            </label>

            <div className="w-85">
              <PasswordChange
                isFromForgotPassword={true}
                loading={resetPassLoading}
                handleValidSubmit={(values) => {
                  this.handleValidSubmit(values);
                }}
              />

              <Link to={'/signin'}>
                <label className={'goBackSignIn'}>Return to login</label>
              </Link>
            </div>
          </>
          {/* )} */}
          {/* </div> */}
        </Container>
        {/* </Grid.Column> */}
        {/* <Grid.Column mobile={16} tablet={5} computer={5} /> */}
      </Grid.Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.authentication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getForgotVerification,
      resetPassword,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
