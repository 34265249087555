import AvField from 'availity-reactstrap-validation/lib/AvField';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import React, { Component } from 'react';
import { Button, Icon, Modal, Radio } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateOutcome } from '../../../../store/actions';

export default class EditModalOutcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      form: {
        title: '',
        description: '',
        isEnable: '',
      },
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  editSelected(item) {
    this.setState({
      form: item,
    });
  }

  render() {
    const { modal } = this.state;
    const { item } = this.props;

    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="small"
        trigger={
          <label
            className="activity-actions-warn mb-0"
            onClick={() => this.editSelected(item)}
          >
            Edit
          </label>
        }
        className="edit-outcome"
      >
        <Modal.Header>Edit Outcome</Modal.Header>
        <Modal.Content>
          <EditOutcomeForm
            item={item}
            {...this.props}
            isNew={false}
            toggle={() => this.toggle()}
            form={this.state.form}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

class EditOutcomeForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      status: false,
    };
  }

  async handleValidSubmit(event, values) {
    try {
      const { form, updateOutcome, toggle } = this.props;
      const { status } = this.state;
      const data = { ...values };
      data.isEnable = status;

      await updateOutcome(form.id, form.policyId, data);
      toggle();
    } catch (error) {
      error.log('Error: EditOutcomeForm ~ handleValidSubmit', error);
    }
  }

  toggleCheckbox() {
    this.setState((prevState) => {
      return { status: !prevState.status };
    });
  }

  componentWillMount() {
    const { form } = this.props;
    this.setState({ status: Boolean(form.isEnable) });
  }

  render() {
    const { toggle, form, outcomeUpdateLoading } = this.props;
    const { status } = this.state;

    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="venue-form"
        model={form}
      >
        <AvField
          label="Title"
          className="org-inputs"
          name="title"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: 'Title is required',
            },
          }}
        />

        <AvField
          label="Description"
          className="org-inputs-textarea"
          name="description"
          type="textarea"
          validate={{
            required: {
              value: true,
              errorMessage: 'Description is required',
            },
          }}
        />

        <div className="edit-outcome-status-bar">
          <label>Enable</label>
          <div className="two-step-radio-container-admin status-right">
            <Radio
              name="isEnable"
              toggle
              className="modal-toggle"
              checked={status}
              onChange={() => this.toggleCheckbox()}
            />
          </div>
        </div>
        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            content="Save"
            type="submit"
            className="Primary-Button"
            loading={outcomeUpdateLoading}
            disabled={outcomeUpdateLoading}
          />
        </div>
      </AvForm>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    outcomes: state.extra.outcomes,
    outcomeUpdateLoading: state.admin.outcomeUpdateLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateOutcome,
    },
    dispatch
  );
};

EditModalOutcome = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditModalOutcome);
