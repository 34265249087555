import React, { PureComponent } from 'react';
import { Segment, Grid, Button, Modal, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationManager } from 'react-notifications';

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from 'availity-reactstrap-validation';
import { map, get } from '../../../../utils/lodash';

import {
  putActivity,
  postGroupSelected,
  getSelectedGroup,
  updateGroupSelected,
  deleteGroupSelected,
  updateProjectInputs,
} from '../../../../store/actions';
import '../activity.scss';
import { CustomTable, CustomTooltip } from '../../../../components';
import { getPageDisable } from '../../../../utils';
import ProjectText from '../../../../components/projectText';

export class SelectDelete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async handleGroupDelete() {
    const { deleteGroupSelected, groupId, currentActivity } = this.props;
    await deleteGroupSelected(groupId, currentActivity.id);
    this.toggle();
  }

  render() {
    const { modal } = this.state;
    const { triggerComp, groupParticipantLoading } = this.props;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="tiny"
        trigger={triggerComp}
      >
        <Modal.Header>Delete Confirmation</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this group?</p>

          <div className="participant-delete-button-container">
            <Button onClick={() => this.toggle()} className="modal-cancel">
              Cancel
            </Button>
            <Button
              className="modal-proceed"
              onClick={() => this.handleGroupDelete()}
              content="Delete "
              type="submit"
              loading={groupParticipantLoading}
              disabled={groupParticipantLoading}
              negative
            />
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

class GroupParticipants extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      targetGroup: false,
      confirm: false,
      modal: false,
      nameValue: false,
      isOpen: false,
      participantsValue: false,
      reasonValue: false,

      form: {
        name: '',
        // objectives: [],
        objectives: map(
          get(props.currentActivity, 'objective', []),
          (e) => e.id
        ),
        reason: '',
        size: '',
      },
      editMode: false,
      loadEditing: false,
    };
  }

  columns() {
    const { currentState, user, currentActivity } = this.props;

    let disabled = getPageDisable(currentActivity, user, currentState);

    return [
      {
        width: 4,
        title: 'Group Name',
        render: (data) => {
          return (
            <div className="current-member-text">
              {data ? (data.name ? data.name : null) : null}
            </div>
          );
        },
      },
      {
        width: 5,
        title: 'Reason',
        render: (data) => {
          return (
            <div className="current-member-text">
              {data ? (data.reason ? data.reason : null) : null}
            </div>
          );
        },
      },

      {
        width: 3,
        title: 'No of Participants',
        textAlign: 'center',
        render: (data) => {
          return (
            <div className="current-member-text">
              {data ? (data.size ? data.size : null) : null}
            </div>
          );
        },
      },
      {
        width: 2,
        render: (data) => {
          return (
            <Segment disabled={disabled}>
              <label
                className="activity-actions-warn mb-0 first-down "
                onClick={() => this.editSelectedGroup(data)}
              >
                Edit
              </label>
            </Segment>
          );
        },
      },
      {
        width: 2,
        render: (data) => {
          return (
            <Segment disabled={disabled}>
              <SelectDelete
                groupId={data.id}
                triggerComp={
                  <label className="activity-actions-error first-down ">
                    Remove
                  </label>
                }
                {...this.props}
              />
            </Segment>
          );
        },
      },
    ];
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async onParticipantGroupChange(targetGroup) {
    const { putActivity, getSelectedGroup, currentActivity } = this.props;
    await putActivity('projectedOutputs', {
      participantGroup: targetGroup,
    });
    if (!targetGroup) {
      await getSelectedGroup(currentActivity.id, 'GROUP');
    }
    this.setState({
      targetGroup,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.targetGroup === true &&
      get(this.props, 'currentActivity.participantGroup', false) === true
    ) {
      this.setState({
        targetGroup: true,
      });
    }
  }

  async componentDidMount() {
    try {
      const { getSelectedGroup, currentActivity } = this.props;
      this.setState({
        targetGroup: currentActivity.participantGroup,
      });
      getSelectedGroup(currentActivity.id, 'GROUP');
    } catch (error) {
      NotificationManager.error('Something went wrong');
    }
  }

  participantFormReset() {
    this.setState(
      {
        loadEditing: true,
      },
      () => {
        this.form && this.form.reset();
        this.setState({ loadEditing: false });
      }
    );
  }

  editSelectedGroup(form) {
    this.toggle();

    this.setState(
      {
        form,
        editMode: true,
      },
      () => {
        this.participantFormReset();
      }
    );
  }

  renderSelectedGroup() {
    const { selectedGroup } = this.props;

    return (
      <CustomTable
        header
        columns={this.columns()}
        data={selectedGroup}
        customClass="evidence-table-grant"
        emptyMessage="No users has been added"
        keyExtractor={(item, index) => item.id}
      />
    );
  }

  resetGroupParticipant() {
    this.toggle();
    this.setState(
      {
        form: {
          name: '',
          objectives: [],
          reason: '',
          size: '',
        },
        editMode: false,
      },
      () => {
        this.participantFormReset();
      }
    );
  }

  async handleValidSubmit(event, values) {
    try {
      const { form, editMode } = this.state;
      const { postGroupSelected, updateGroupSelected, currentActivity } =
        this.props;

      if (editMode) {
        await updateGroupSelected(values, form.id, currentActivity.id, this);
      } else {
        await postGroupSelected(values, currentActivity.id, this);
      }
    } catch (error) {
      // handle error here
    }
  }

  nameValue(value) {
    if (value.length > 0) {
      this.setState({
        nameValue: true,
      });
    } else {
      this.setState({
        nameValue: false,
      });
    }
  }

  participantsValue(value) {
    if (value.length > 0) {
      this.setState({
        participantsValue: true,
      });
    } else {
      this.setState({
        participantsValue: false,
      });
    }
  }

  reasonValue(value) {
    if (value.length > 0) {
      this.setState({
        reasonValue: true,
      });
    } else {
      this.setState({
        reasonValue: false,
      });
    }
  }

  render() {
    const {
      targetGroup,
      form,
      loadEditing,
      editMode,
      nameValue,
      participantsValue,
      reasonValue,
      modal,
    } = this.state;
    const {
      groupParticipantLoading,
      currentActivity,
      helpTips,
      user,
      pageDisabled = false,
    } = this.props;
    return (
      <Segment className="outputs-segment" disabled={pageDisabled}>
        <AvForm>
          <div className="outputs-col-custom-div">
            <Grid columns={2} stackable>
              <Grid.Row className="outputs-row">
                <>
                  <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={16}
                    className="outputs-col-custom"
                  >
                    <div className="disabled-padding">
                      <Segment
                        disabled={currentActivity.statusId >= 2 ? true : false}
                      >
                        <div className="core-group-flex-custom">
                          <div className="group-wrapper">
                            <div className="book-logo-wrapper">
                              <div>
                                <div className="core-participant-book-custom">
                                  <div className="group-book-logo">
                                    <svg
                                      width="22"
                                      height="20"
                                      viewBox="0 0 22 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M1 1H7C9.20914 1 11 2.79086 11 5V19C11 17.3431 9.65685 16 8 16H1V1Z"
                                        stroke="#EF8123"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M21 1H15C12.7909 1 11 2.79086 11 5V19C11 17.3431 12.3431 16 14 16H21V1Z"
                                        stroke="#EF8123"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className="core-participant-res">
                                <div className="group-check-wrapper">
                                  <CustomTooltip
                                    state={get(user, 'options.tips')}
                                    contentObject={{
                                      data: helpTips,
                                      key: '208',
                                    }}
                                    position="top left"
                                    wide="very"
                                  >
                                    <label className="core-participant-title participant-title-custom">
                                      <ProjectText
                                        defaultText="Do you want to identify any core groups within these participants?"
                                        textId={7}
                                      />
                                    </label>
                                  </CustomTooltip>

                                  <div className="group-objective-select">
                                    <AvRadioGroup
                                      inline
                                      name="coreGroup"
                                      required
                                      value={targetGroup ? '1' : '0'}
                                    >
                                      <AvRadio
                                        label="Yes"
                                        value="1"
                                        onClick={() =>
                                          this.onParticipantGroupChange(true)
                                        }
                                      />
                                      <AvRadio
                                        label="No"
                                        value="0"
                                        onClick={() =>
                                          this.onParticipantGroupChange(false)
                                        }
                                      />
                                    </AvRadioGroup>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {targetGroup ? (
                              <Button
                                className="Secondary-Button"
                                onClick={() => this.toggle()}
                              >
                                <Icon name="plus" />
                                Create new group
                              </Button>
                            ) : null}
                          </div>
                        </div>
                      </Segment>
                    </div>
                  </Grid.Column>
                </>
              </Grid.Row>
            </Grid>
            {targetGroup ? (
              loadEditing ? null : (
                <div className="participant-col">
                  {/* <Grid>
                    <Grid.Row> */}
                  <Grid.Column mobile={16} tablet={8} computer={16}>
                    <div className="">
                      <div className="add-user">
                        <Modal
                          // trigger={<label className="activity-actions-warn mb-0">Edit</label>}
                          size="small"
                          className="modal-grouping"
                          open={modal}
                          onOpen={() => this.toggle()}
                          closeIcon={
                            <Icon
                              onClick={() => this.resetGroupParticipant()}
                              name="close"
                              className="closeicon"
                            />
                          }
                        >
                          <Modal.Header>Add New Group</Modal.Header>
                          <Modal.Content>
                            <div className="participant-group-div-custom"></div>
                            <AvForm
                              ref={(c) => (this.form = c)}
                              onValidSubmit={(event, values) =>
                                this.handleValidSubmit(event, values)
                              }
                              autoComplete="off"
                              model={form}
                            >
                              <div className="avfield-wrap">
                                <div className="participant-group-div-custom">
                                  <AvField
                                    label="Group Name"
                                    name="name"
                                    placeholder="Enter group name"
                                    type="text"
                                    className="avfield-name"
                                    onChange={(e) =>
                                      this.nameValue(e.target.value)
                                    }
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: 'Group Name is required',
                                      },
                                    }}
                                    autoComplete="off"
                                  />
                                </div>

                                <div className="participant-group-div-custom">
                                  <AvField
                                    label={
                                      <CustomTooltip
                                        state={get(user, 'options.tips')}
                                        contentObject={{
                                          data: helpTips,
                                          key: '209',
                                        }}
                                        position="top left"
                                        wide="very"
                                      >
                                        <div>Number of Participants</div>
                                      </CustomTooltip>
                                    }
                                    name="size"
                                    type="number"
                                    className="avfield-participants"
                                    onChange={(e) =>
                                      this.participantsValue(e.target.value)
                                    }
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage:
                                          'Number of participants is required',
                                      },
                                      pattern: {
                                        value: '^[0-9]+$',
                                        errorMessage:
                                          'Only numbers are accepted',
                                      },
                                    }}
                                    min={0}
                                  />
                                </div>
                              </div>

                              <div className="participant-group-div-custom">
                                <AvField
                                  label={
                                    <CustomTooltip
                                      state={get(user, 'options.tips')}
                                      contentObject={{
                                        data: helpTips,
                                        key: '212',
                                      }}
                                      position="top left"
                                      wide="very"
                                    >
                                      <div>Reason for this Group</div>
                                    </CustomTooltip>
                                  }
                                  name="reason"
                                  type="textarea"
                                  className="avfield-reason"
                                  onChange={(e) =>
                                    this.reasonValue(e.target.value)
                                  }
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Group Reason  is required',
                                    },
                                  }}
                                  autoComplete="off"
                                />
                              </div>

                              <div className="participant-buttonGroup-div">
                                {editMode ? (
                                  <div className="participant-group-discard">
                                    <div
                                      className="participant-group-discard-button"
                                      type="button"
                                      loading={groupParticipantLoading}
                                      disabled={groupParticipantLoading}
                                      onClick={() =>
                                        this.resetGroupParticipant()
                                      }
                                    >
                                      Discard changes
                                    </div>
                                  </div>
                                ) : null}

                                {editMode ? (
                                  <Button
                                    type="submit"
                                    className="modal-proceed"
                                    loading={groupParticipantLoading}
                                    disabled={groupParticipantLoading}
                                  >
                                    Save Group
                                  </Button>
                                ) : (
                                  <div>
                                    <Button
                                      type="submit"
                                      className="modal-proceed"
                                      loading={groupParticipantLoading}
                                      disabled={
                                        !(
                                          nameValue &&
                                          participantsValue &&
                                          reasonValue
                                        ) || groupParticipantLoading
                                      }
                                    >
                                      Add Group
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </AvForm>
                          </Modal.Content>
                        </Modal>
                      </div>
                    </div>
                  </Grid.Column>
                  {/* </Grid.Row>
                  </Grid> */}
                  {this.renderSelectedGroup()}
                </div>
              )
            ) : null}
          </div>
        </AvForm>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    user: state.authentication.user,
    helpTips: state.extra.activityHelpTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      putActivity,
      postGroupSelected,
      getSelectedGroup,
      updateGroupSelected,
      deleteGroupSelected,
      updateProjectInputs,
    },
    dispatch
  );
};

GroupParticipants = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupParticipants);

export { GroupParticipants };
