// NOTE
// DEPRECATED
/**
  Simply replace `react-ga` with `react-ga4` and remove `ReactGA.pageview()`
  import ReactGA from "react-ga";
 */

import { get } from '../utils/lodash';
import React, { PureComponent } from 'react';
import ReactGA from 'react-ga4';

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    // ReactGA.set({
    //   page,
    //   ...options,
    // });
    // ReactGA.pageview(page);
    ReactGA.send({
      hitType: 'pageview',
      page,
      //  title: "Custom Title",
      ...options,
    });
  };

  // eslint-disable-next-line
  const HOC = class extends PureComponent {
    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.match.pathname + this.props.match.search;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.match.pathname + get(prevProps, 'match.search', '');

      const nextPage =
        this.props.match.pathname + get(this.props, 'match.search', '');

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;
