import { bindActionCreators } from 'redux';
import { Button, Icon, Modal, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { isBrowser, isTablet } from 'react-device-detect';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import moment from 'moment';
import React, { Component } from 'react';

import { CustomTable } from '../../../../components';
import {
  postIndustry,
  updateIndustry,
  getIndustryAll,
} from '../../../../store/actions';
import addIcon from '../../../../assets/add-icon.svg';

export default class IndustrySectorTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  async componentDidMount() {
    const { getIndustryAll } = this.props;
    getIndustryAll();
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  render() {
    const { modal } = this.state;
    return (
      <div>
        <div className="outcome-admin-padded">
          <div className="button-outcome">
            <Modal
              open={modal}
              onOpen={() => this.toggle()}
              closeIcon={
                <Icon
                  onClick={() => this.toggle()}
                  name="close"
                  className="closeicon"
                />
              }
              size="small"
              trigger={
                <Button className="Primary-Button">
                  <div className="add-icon-activity-add">
                    <img className="icons-add" src={addIcon} alt="icon" />
                    <div className="add-text">Add Industry Sector Type</div>
                  </div>
                </Button>
              }
              className="create-industry-sector-type"
            >
              <Modal.Header>Create Industry Sector Type</Modal.Header>
              <Modal.Content>
                <CreateIndustrySector
                  toggle={() => this.toggle()}
                  {...this.props}
                />
              </Modal.Content>
            </Modal>
          </div>
        </div>
        <div className="industry-list-container">
          <IndustryList {...this.props} />
        </div>
      </div>
    );
  }
}
class CreateIndustrySector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async handleValidSubmit(event, values) {
    try {
      const { postIndustry, toggle } = this.props;
      await postIndustry(values);
      toggle();
    } catch (error) {
      error.log('Industry create submit ~~', error);
    }
  }
  render() {
    const { toggle, industryLoading } = this.props;
    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="venue-form"
      >
        <AvField
          label="Title"
          className="org-inputs"
          name="name"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: 'Title is required',
            },
          }}
        />

        <AvField
          label="Sector Type Description"
          className="org-inputs-textarea"
          name="description"
          type="textarea"
          validate={{
            required: {
              value: true,
              errorMessage: 'Sector Type Description is required',
            },
          }}
        />

        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            content="Create"
            type="submit"
            className="Primary-Button"
            loading={industryLoading}
            disabled={industryLoading}
          />
        </div>
      </AvForm>
    );
  }
}

class EditIndustryModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      form: {
        name: '',
        description: '',
      },
    };
  }

  editSelectedQuestion(form) {
    this.setState({
      form,
    });
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  render() {
    const { modal, form } = this.state;
    const { item } = this.props;

    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="small"
        trigger={
          <label
            className="activity-actions-warn mb-0"
            onClick={() => this.editSelectedQuestion(item)}
          >
            Edit
          </label>
        }
      >
        <Modal.Header>Edit Industry Sector Type</Modal.Header>
        <Modal.Content>
          <EditIndustryForm
            form={form}
            toggle={() => this.toggle()}
            {...this.props}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

class IndustryList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'Name',
          render: (data) => {
            return <div className="venue-name">{data.name}</div>;
          },
          textAlign: isBrowser || isTablet ? 'left' : null,
          width: 9,
        },
        {
          title: 'Created Date',
          render: (data) => {
            return <div>{moment(data.createdAt).format('ll hh:mm A')}</div>;
          },
          width: 3,
        },
        {
          title: 'Updated Date',
          render: (data) => {
            return <div>{moment(data.updatedAt).format('ll hh:mm A')}</div>;
          },
          width: 3,
        },
        {
          title: <div></div>,
          render: (data) => {
            return (
              <div className="align-left">
                <EditIndustryModal
                  item={data}
                  {...this.props}
                  {...this.props}
                />
              </div>
            );
          },
          textAlign: isBrowser || isTablet ? 'right' : null,
          width: 1,
        },
      ],
      currentPage: 1,
    };
  }

  async componentDidMount() {
    try {
      const { currentPage } = this.state;
      const { getIndustryAll } = this.props;
      await getIndustryAll(currentPage);
    } catch (error) {}
  }

  pageChange(page) {
    const { getIndustryAll } = this.props;
    this.setState(
      {
        currentPage: page,
      },
      () => {
        getIndustryAll(page);
      }
    );
  }

  render() {
    const { industryAll, getIndustryAllLoading } = this.props;
    const { columns, currentPage } = this.state;
    return (
      <Segment
        className="content-segment"
        loading={getIndustryAllLoading}
        disabled={getIndustryAllLoading}
      >
        <CustomTable
          header
          columns={columns}
          data={industryAll.docs}
          pagination
          customClass="super-admin-table tbody-hover"
          handlePaginationChange={(page) => this.pageChange(page)}
          page={currentPage}
          noOfPages={industryAll.pages}
          keyExtractor={(item, index) => item.id}
        />
      </Segment>
    );
  }
}

class EditIndustryForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async onValidSubmit(event, values) {
    try {
      const { updateIndustry, form, toggle } = this.props;
      await updateIndustry(form.id, values);
      toggle();
    } catch (error) {
      // handle error here
    }
  }

  render() {
    const { toggle, form, industryLoading } = this.props;

    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.onValidSubmit(event, values)}
        className="venue-form"
        model={form}
      >
        <AvField
          label="Title"
          className="org-inputs"
          name="name"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: 'Title is required',
            },
          }}
        />

        <AvField
          label="Description"
          className="org-inputs-textarea"
          name="description"
          type="textarea"
          validate={{
            required: {
              value: true,
              errorMessage: 'Description  is required',
            },
          }}
        />

        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            content="Save"
            type="submit"
            className="Primary-Button"
            loading={industryLoading}
            disabled={industryLoading}
          />
        </div>
      </AvForm>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    industrySectorTypes: state.extra.industrySectorTypes,
    industryLoading: state.admin.industryLoading,
    industryAll: state.admin.industryAll,
    getIndustryAllLoading: state.admin.getIndustryAllLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postIndustry,
      updateIndustry,
      getIndustryAll,
    },
    dispatch
  );
};

IndustrySectorTypes = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndustrySectorTypes);

export { IndustrySectorTypes };
