/* eslint-disable */

export const PHONE_REG_EX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const EMAIL_REG_EX = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

export const DOMAIN_REG_EX = /^([a-z0-9]+(-[a-z0-9]+)*.)+[a-z]{2,}$/;

export const LOWERCASE_REG_EX = /^(?=.*[a-z])/;

export const UPPERCASE_REG_EX = /^(?=.*[A-Z])/;

export const NUMERIC_REG_EX = /^(?=.*[0-9])/;

export const SPECIAL_CHARACTER_REG_EX = /^(?=.*[!@#\$%\^&\*])/;

export const URL_REG_EX =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
