import React from 'react';
import { Segment, Icon } from 'semantic-ui-react';
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty, map } from '../../utils/lodash';
import { EmptyContainer } from '../../components';

const TodoSegment = ({ getUserReminders, todoList = [], loading = false }) => {
  const [page, setPage] = useState(1);
  // const history = useHistory();
  const navigate = useNavigate();

  const handlePaginationChange = useCallback(
    (activePage) => {
      activePage = activePage + 1;
      setPage(activePage);
      getUserReminders(activePage);
    },
    [getUserReminders]
  );

  const visitActivity = useCallback(
    (id) => {
      navigate(`/activities/${id}`);
    },
    [navigate]
  );

  const isVisible = page >= todoList.pages;

  return (
    <>
      <Segment className="content-segment-dashboard-todo" loading={loading}>
        {isEmpty(todoList.docs) ? (
          <EmptyContainer msg="No current to do list actions" />
        ) : (
          <Segment.Group className="todo-segments">
            {map(
              isEmpty(todoList.docs) ? todoList : todoList.docs,
              (item, i) => {
                return item ? (
                  <Segment
                    key={i}
                    className="todo-container"
                    onClick={() => visitActivity(item.projectId)}
                  >
                    <div className="todo-container-sub">
                      <div className="todo-info-wrap">
                        <div className="todo-info-wrap-sub">
                          <div className="todo-info-wrap-sub-container-icon">
                            <Icon
                              name="circle"
                              size="small"
                              color={
                                item.statusId === 1
                                  ? 'orange'
                                  : item.statusId === 2
                                    ? 'green'
                                    : item.statusId === 3
                                      ? 'blue'
                                      : 'red'
                              }
                            />

                            <label className="todo-info-description">
                              {item.statusId === 1
                                ? 'In Development'
                                : item.statusId === 2
                                  ? 'Active'
                                  : 'Completed'}
                            </label>
                          </div>

                          {item.criticality === 'HIGH' ? (
                            <div className="critical-info">Urgent</div>
                          ) : null}
                        </div>
                        <label className="todo-info">{item.label}</label>
                        <label className="todo-info-date">
                          Last Update : {item.date}
                        </label>
                      </div>
                    </div>

                    <div className="todo-action-container-custom">
                      <Icon name="angle right" color="black" />

                      {/* GO TO TASK */}
                    </div>
                  </Segment>
                ) : null;
              }
            )}

            {isVisible ? null : (
              <Segment className="see-more-todo">
                <div
                  className="see-more-todo-text"
                  onClick={() => handlePaginationChange(page)}
                >
                  See More
                </div>
              </Segment>
            )}
          </Segment.Group>
        )}
      </Segment>
    </>
  );
};

export default TodoSegment;
