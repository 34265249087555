/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import {
  Segment,
  Button,
  Modal,
  Icon,
  Popup,
  Tab,
  Form,
} from 'semantic-ui-react';
import { NotificationManager } from 'react-notifications';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { get, isEmpty } from '../../../utils/lodash';
import { CustomTable } from '../../../components';
import { CustomTooltip } from '../../../components';
import { toSqlDate, getPageDisable } from '../../../utils';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getDocuments,
  deleteDocument,
  postDocument,
  updateDocument,
} from '../../../store/actions';
import moment from 'moment';
import './activity.scss';
import { Formik } from 'formik';
import {
  CustomFileUpload,
  CustomInput,
  CustomTextArea,
} from '../../../components/form';
import {
  fileDocumentInitialValues,
  fileDocumentValidationSchema,
  linkDocumentInitialValues,
  linkDocumentValidationSchema,
} from '../../../validations/activityDocumentMediaValidation';

class DocumentAndMedia extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      uploaded: false,
      document: '',
      activityDate: moment().format('DD-MM-YYYY'),
      isUploadButtonClicked: false,
    };
  }

  setFile = async (file) => {
    this.setState({ file });
  };

  async handleValidSubmit(event, values, isUpdate, isFileEmpty, isDocument) {
    const { currentActivity } = this.props.activity;
    if (isUpdate) {
      await this.props.updateDocument(
        currentActivity.id,
        this.state.documentId,
        {
          projectId: currentActivity.id,
          description: values.documentDescription,
          // location: values.documentLocation,
          date: toSqlDate(this.state.activityDate),
          documentId: this.state.documentId,
          urlText: values.documentUrlText,
          title: values.documentTitle,
        }
      );

      this.setState({
        isUploadButtonClicked: true,
      });

      this.setState({
        values,
      });
      this.toggle();
    } else {
      if (!isFileEmpty || !isDocument) {
        await this.props.postDocument({
          upload: this.state.file ? this.state.file.file : '',
          projectId: currentActivity.id,
          description: values.documentDescription,
          urlText: values.documentUrlText,
          // location: values.documentLocation,
          date: toSqlDate(this.state.activityDate),
          title: values.documentTitle,
        });

        this.setState({
          values,
        });
        this.toggle();
      }

      this.setState({
        isUploadButtonClicked: true,
      });
    }
  }

  toggle() {
    this.setState((prevState) => ({
      isUpdate: false,
      documentDescription: null,
      documentLocation: null,
      documentUrlText: null,
      documentTitle: null,
      activityDate: moment().format('DD-MM-YYYY'),
      modal: !prevState.modal,
      isUploadButtonClicked: false,
    }));
  }

  onChange(value, type) {
    this.setState({
      [type]: value,
    });
  }

  onEditDocument(values, documentId) {
    this.setState({
      documentDescription: values.documentDescription,
      documentLocation: values.documentLocation,
      documentUrlText: values.documentUrlText,
      documentTitle: values.documentTitle,
      activityDate: moment(values.date).format('DD-MM-YYYY'),
      isUpdate: true,
      documentId: documentId,
      modal: true,
    });
  }

  render() {
    const { user, helpTips, activity, currentState } = this.props;
    return (
      <Segment
        loading={
          activity.getDocumentsLoading || activity.deleteDocumentsLoading
        }
        className="document-media-seg"
      >
        <div className="document-media-div">
          <p>
            You can upload records of the activity such as photos, short clips,
            and general documents. URLs are saved as text only and will need to
            be copied and pasted into a browser to view.
          </p>
          <CustomTooltip
            state={get(user, 'options.tips')}
            contentObject={{ data: helpTips, key: '57' }}
            position="bottom left"
          >
            <Button
              className="Primary-Button"
              onClick={() => this.toggle()}
              disabled={getPageDisable(
                activity.currentActivity,
                user,
                currentState,
                true
              )}
            >
              Upload Items
            </Button>
          </CustomTooltip>

          <CreateDocument
            handleValidSubmit={(
              event,
              values,
              isUpdate,
              isFileEmpty,
              isDocument
            ) =>
              this.handleValidSubmit(
                event,
                values,
                isUpdate,
                isFileEmpty,
                isDocument
              )
            }
            isUploadButtonClicked={this.state.isUploadButtonClicked}
            onChange={(value, type) => this.onChange(value, type)}
            setFile={this.setFile}
            modal={this.state.modal}
            toggle={() => this.toggle()}
            state={this.state}
            {...this.props}
          />
        </div>
        <ViewDocuments
          setState={(values, documentId) =>
            this.onEditDocument(values, documentId)
          }
          {...this.props}
        />
      </Segment>
    );
  }
}

class CreateDocument extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      value: {},
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal, toggle } = this.props;
    return (
      <div className="add-user">
        <Modal
          size="small"
          open={modal}
          onOpen={() => toggle()}
          closeIcon={
            <Icon onClick={() => toggle()} name="close" className="closeicon" />
          }
          className="upload-media"
        >
          <Modal.Header>
            <label className="upload-media-header">Upload Media</label>
          </Modal.Header>
          <Modal.Content>
            <DocumentForm toggle={() => this.toggle()} {...this.props} />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class DocumentForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDocument: true,
      isFileEmpty: true,
    };
  }

  componentDidMount() {
    const { state } = this.props;

    if (state.isUpdate) {
      this.setState({
        isDocument:
          state.isUpdate && isEmpty(state.documentUrlText) ? true : false,
      });
    }
  }

  menuItems(state) {
    this.setState({
      isDocument: state,
    });
  }

  isDocumentEmpty() {
    this.setState({
      isFileEmpty: false,
    });
  }

  renderForm(isUpdate = false, isDocument = true) {
    const { toggle, setFile, handleValidSubmit, activity, state } = this.props;

    let initialValues = fileDocumentInitialValues(state);
    let validationSchema = fileDocumentValidationSchema(state);

    if (!isDocument) {
      initialValues = linkDocumentInitialValues(state);
      validationSchema = linkDocumentValidationSchema;
    }

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleValidSubmit('', values, isUpdate, false, isDocument);
        }}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, errors }) => (
          <Form onSubmit={handleSubmit}>
            <CustomInput
              name="documentTitle"
              id="documentTitle"
              labelText="Title"
              placeholder="Enter Title"
            />

            {isDocument ? (
              <CustomFileUpload
                id="documentFile"
                name="documentFile"
                labelText="Document"
                accept="*"
                buttonText="Browse File"
                mediaUpload={true}
                infoMessage="Max file size - 50 MB"
                setFile={(file) => setFile(file)}
                showMsg={true}
                placeholder="Accepted file types: .pdf, .docx"
                restrictedSize={2}
                setFieldValue={setFieldValue}
                loading={
                  activity.postDocumentsLoading ||
                  activity.updateDocumentsLoading
                }
                disabled={
                  activity.postDocumentsLoading ||
                  activity.updateDocumentsLoading
                }
              />
            ) : (
              <CustomInput
                name="documentUrlText"
                id="documentUrlText"
                labelText="Link"
                placeholder="Enter Link"
              />
            )}

            <CustomTextArea
              name="documentDescription"
              id="documentDescription"
              placeholder="Enter Description"
              labelText="Description"
            />

            <div className="model-buttons">
              <Button
                className="Secondary-Button"
                type="button"
                onClick={() => toggle()}
                disabled={
                  activity.postDocumentsLoading ||
                  activity.updateDocumentsLoading
                }
              >
                Cancel
              </Button>

              <Button
                className="Primary-Button"
                loading={
                  activity.postDocumentsLoading ||
                  activity.updateDocumentsLoading
                }
                disabled={
                  activity.postDocumentsLoading ||
                  activity.updateDocumentsLoading
                }
                type="submit"
              >
                {!state.isUpdate ? 'Upload' : 'Save'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  render() {
    const { state } = this.props;

    const panes = (isUpdate = false, documentUrlText = null) => {
      const documentPane = [
        {
          menuItem: 'Document',
          render: () => (
            <Tab.Pane key="documentPane" attached={false}>
              {this.renderForm(isUpdate)}
            </Tab.Pane>
          ),
        },
      ];

      const linkPane = [
        {
          menuItem: 'Link',
          render: () => (
            <Tab.Pane key="linkPane" attached={false}>
              {this.renderForm(isUpdate, false)}
            </Tab.Pane>
          ),
        },
      ];

      let pane = [];
      if (!isUpdate) {
        pane = documentPane.concat(linkPane);
        return pane;
      } else if (documentUrlText) {
        return linkPane;
      } else {
        return documentPane;
      }
    };

    return (
      <div>
        <Tab
          className="document-custom-tab"
          menu={{ secondary: true, pointing: true }}
          panes={panes(state.isUpdate, state.documentUrlText)}
        />
      </div>
    );
  }
}

class ViewDocuments extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  editDocument(data) {
    const values = {
      documentDescription: data.description,
      documentLocation: data.location,
      date: data.date,
      documentUrlText: data.mediaUrl,
      documentTitle: data.mediaTitle,
    };
    this.props.setState(values, data.document.id);
  }

  componentDidMount() {
    const { getDocuments, activity } = this.props;
    getDocuments(activity.currentActivity.id);
  }

  getColumns(activity, user, currentState) {
    return [
      {
        title: 'Name',
        width: '4',
        render: (data) => {
          return (
            <div className="activity-users-container">
              <label>
                <Popup
                  content={
                    isEmpty(data.mediaUrl)
                      ? data.document.name
                        ? data.document.name.split('.')[0]
                        : data.mediaTitle
                      : data.mediaTitle
                        ? data.mediaTitle
                        : ''
                  }
                  trigger={<div>{data.mediaTitle}</div>}
                />
              </label>
              <br />
              <label className="activity-users-text document-description">
                {data ? data.description : ''}
              </label>
            </div>
          );
        },
      },
      {
        title: 'Format',
        width: '3',
        render: (data) => {
          return (
            <div>
              {data.document.name === ''
                ? 'Link'
                : data.document.name.split('.').at(-1)}
            </div>
          );
        },
      },
      {
        title: 'Date Added',
        width: '3',
        render: (data) => {
          return (
            <div>
              {data.date ? moment(data.date).format('DD-MM-YYYY') : '-'}
            </div>
          );
        },
      },
      {
        title: '',
        width: '2',
        render: (data) => {
          return (
            <Segment
              disabled={getPageDisable(
                activity.currentActivity,
                user,
                currentState,
                true
              )}
            >
              <label
                onClick={() => this.editDocument(data)}
                className="activity-actions-warn mb-0"
              >
                Edit
              </label>
            </Segment>
          );
        },
      },
      {
        title: '',
        width: '2',
        render: (data) => {
          return (
            <div>
              {isEmpty(data.mediaUrl) ? (
                <label className="activity-actions-warn mb-0">
                  <a
                    className="activity-actions-warn download-button"
                    href={`${data.document.documentUrl}`}
                    rel="noopener noreferrer"
                    download
                    target="_blank"
                  >
                    {`Download`}
                  </a>
                </label>
              ) : (
                <div className="link-button-document">
                  <CopyToClipboard
                    text={data.mediaUrl}
                    onCopy={() =>
                      NotificationManager.info(
                        'copied to clipboard',
                        'Document Link'
                      )
                    }
                  >
                    <div className="copy-link-warn">Copy Link</div>
                  </CopyToClipboard>
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: '',
        width: '2',
        render: (data) => {
          return (
            <div className="disabled-padding">
              <Segment
                disabled={getPageDisable(
                  activity.currentActivity,
                  user,
                  currentState,
                  true
                )}
              >
                <DocumentDelete document={data} {...this.props} />
              </Segment>
            </div>
          );
        },
      },
    ];
  }

  render() {
    const { activity, user, currentState } = this.props;
    return (
      <Segment className="program-activities">
        <div className="scroll-table">
          <CustomTable
            header
            columns={this.getColumns(activity, user, currentState)}
            data={activity.documents}
            customClass="program-activities-table"
            emptyMessage="No items have been added…"
            keyExtractor={(item, index) => item.id}
          />
        </div>
      </Segment>
    );
  }
}

class DocumentDelete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async deleteDocument(id) {
    try {
      const { deleteDocument, activity } = this.props;
      await deleteDocument(activity.currentActivity.id, id);
      this.toggle();
    } catch (error) {}
  }

  render() {
    const { modal } = this.state;
    const { document, activity } = this.props;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="small"
        trigger={
          <label className="activity-actions-error first-down mb-0">
            Remove
          </label>
        }
      >
        <Modal.Header className="delete-media-header">
          Delete Media -{' '}
          {document.document.name
            ? document.document.name
            : document.mediaTitle}
        </Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to remove this file from the activity?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button className="modal-cancel" onClick={() => this.toggle()}>
            No
          </Button>
          <Button
            className="modal-proceed"
            loading={activity.deleteDocumentsLoading}
            disabled={activity.deleteDocumentsLoading}
            onClick={() => this.deleteDocument(document.document.id)}
          >
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activity: state.activity,
    user: state.authentication.user,
    helpTips: state.extra.activityHelpTips,
    documents: state.activity.documents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDocuments,
      deleteDocument,
      postDocument,
      updateDocument,
    },
    dispatch
  );
};

DocumentAndMedia = connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentAndMedia);

export { DocumentAndMedia };
