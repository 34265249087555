import React, { Component } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { get } from '../../../../utils/lodash';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Paragraph,
  List,
  Heading,
} from 'ckeditor5';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getMethods, updateMethods } from '../../../../store/actions';
import { CustomTable } from '../../../../components';
import moment from 'moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';

class EvaluationMethods extends Component {
  componentDidMount() {
    const { getMethods } = this.props;
    getMethods();
  }

  renderColumns() {
    const columns = [
      {
        title: (
          <div>
            <strong>Name</strong>
          </div>
        ),
        render: (data) => {
          return <div>{data.details}</div>;
        },
        width: 8,
      },
      {
        title: (
          <div>
            <strong>Date Created</strong>
          </div>
        ),
        render: (data) => {
          return <div>{moment(data.createdAt).format('ll hh:mm A')}</div>;
        },
        width: 3,
      },
      {
        title: (
          <div>
            <strong>Date Updated</strong>
          </div>
        ),
        render: (data) => {
          return <div>{moment(data.updatedAt).format('ll hh:mm A')}</div>;
        },
        width: 3,
      },
      {
        title: '',
        render: (data) => {
          return <EditEvaluationModal {...this.props} item={data} />;
        },
        width: 1,
      },
    ];

    return columns;
  }

  render() {
    const { methods } = this.props;

    return (
      <div className="view-member-eval-qs ">
        <CustomTable
          header
          customClass="super-admin-table tbody-hover"
          columns={this.renderColumns()}
          data={methods}
          emptyMessage="No methods"
          keyExtractor={(item, index) => item.id}
        />
      </div>
    );
  }
}

class EditEvaluationModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      form: {
        details: '',
        method: '',
        response: '',
        preamble: '',
      },
    };
  }

  editSelectedQuestion(form) {
    this.setState({
      form,
    });
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  render() {
    const { modal, form } = this.state;
    const { item } = this.props;

    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="small"
        trigger={
          <label
            className="activity-actions-warn mb-0"
            onClick={() => this.editSelectedQuestion(item)}
          >
            Edit
          </label>
        }
        className="edit-evaluation-method"
      >
        <Modal.Header>Edit Evaluation Method</Modal.Header>
        <Modal.Content>
          <EditEvaluationForm
            toggle={() => this.toggle()}
            form={form}
            {...this.props}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

class EditEvaluationForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      preamble: '',
      facilitatorNote: '',
    };
  }

  async handleValidSubmit(event, values) {
    try {
      const { form, updateMethods, toggle } = this.props;
      const { preamble, facilitatorNote } = this.state;
      values.preamble = preamble;
      values.facilitatorNote = facilitatorNote;

      await updateMethods(form.id, values);
      toggle();
    } catch (error) {
      error.log('Edit Method error~', error);
    }
  }

  componentWillMount() {
    const { form } = this.props;
    this.setState({
      preamble: get(form, 'preamble', ''),
      facilitatorNote: get(form, 'facilitatorNote', ''),
    });
  }

  render() {
    const { toggle, form, methodLoading } = this.props;
    const { preamble, facilitatorNote } = this.state;

    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="venue-form"
        model={form}
      >
        <AvField
          label="Title"
          className="org-inputs"
          name="details"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: 'Title is required',
            },
          }}
        />

        <AvField
          label="Method"
          className="org-inputs"
          name="method"
          type="text"
          disabled
        />

        <AvField
          label="Respondent Type"
          className="org-inputs"
          name="response"
          type="text"
          disabled
        />

        <label>Preamble</label>
        <CKEditor
          name="preamble"
          editor={ClassicEditor}
          config={{
            plugins: [Essentials, Bold, Italic, List, Heading, Paragraph],
          }}
          data={preamble}
          onBlur={(event, editor) => {
            const preamble = editor.getData();
            this.setState({
              preamble,
            });
          }}
        />

        <div className="gap-between-ck">
          <label>Facilitator Notes</label>
          <CKEditor
            editor={ClassicEditor}
            config={{
              plugins: [Essentials, Bold, Italic, List, Heading, Paragraph],
            }}
            data={facilitatorNote}
            onBlur={(event, editor) => {
              const facilitatorNote = editor.getData();
              this.setState({
                facilitatorNote,
              });
            }}
          />
        </div>

        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            content="Save"
            type="submit"
            className="Primary-Button"
            loading={methodLoading}
            disabled={methodLoading}
          />
        </div>
      </AvForm>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    methods: state.extra.methods,
    methodLoading: state.admin.methodLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMethods,
      updateMethods,
    },
    dispatch
  );
};

EvaluationMethods = connect(
  mapStateToProps,
  mapDispatchToProps
)(EvaluationMethods);
export default EvaluationMethods;
