import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { filter, map, isEmpty, find, get } from '../../utils/lodash';
import { Container, Grid, Segment } from 'semantic-ui-react';
import './questionGenerator.scss';
import { CustomSegment } from '../../components';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import loginLogo from '../../assets/whitebox-login-logo.svg';
import {
  getPolicies,
  getOutcomes,
  getMethods,
  generateQuestion,
  getEvalAssesmentTypes,
  getEvalReasons,
} from '../../store/actions';

class QuestionGenerator extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      objectives: [],
      outcome: {},
      selectedPolicy: null,
      selectedMethods: null,
      selectedOutcomes: null,
      question: {},
    };
  }

  componentDidMount() {
    const {
      getPolicies,
      getOutcomes,
      getMethods,
      getEvalAssesmentTypes,
      getEvalReasons,
    } = this.props;

    getPolicies();
    getOutcomes();
    getMethods();
    getEvalAssesmentTypes();
    getEvalReasons();
  }

  async onChangeHandler(type, value) {
    switch (type) {
      case 'policy':
        await this.setState({ selectedPolicy: null });
        this.setPolicy(value);
        break;
      case 'outcome':
        const [outcome] = filter(this.state.objectives, {
          id: Number(value),
        });
        await this.setState({
          selectedOutcomes: Number(value),
          outcome,
        });
        this.generateQuestion();
        break;
      case 'method':
        await this.setState({ selectedMethods: Number(value) });
        this.generateQuestion();
        break;

      default:
        break;
    }
  }

  setPolicy(value) {
    const { outcomes } = this.props;

    const objectives = filter(outcomes, { policyId: Number(value) });
    this.setState({
      objectives: objectives,
      selectedPolicy: value,
      selectedMethods: null,
      selectedOutcomes: null,
      question: {},
    });
  }

  async generateQuestion() {
    const { selectedMethods, selectedOutcomes } = this.state;
    const { generateQuestion } = this.props;

    if (selectedMethods && selectedOutcomes) {
      await this.setState({ loading: true });
      await generateQuestion({
        methodId: selectedMethods,
        outcomeId: selectedOutcomes,
      });
      this.setState({ question: this.props.question, loading: false });
    }
  }

  renderEvaluationReasonsList() {
    const { evalReasons } = this.props;
    return map(evalReasons, (reason) => {
      return <li>{reason.name}</li>;
    });
  }

  renderEvaluationAssessmentTypes() {
    const { evalAssesmentTypes } = this.props;
    return map(evalAssesmentTypes, (type) => {
      return <li>{type.name}</li>;
    });
  }

  render() {
    const {
      objectives,
      selectedMethods,
      selectedOutcomes,
      selectedPolicy,
      question,
      loading,
      outcome,
    } = this.state;
    const { policies, methods } = this.props;
    const getMethod = find(methods, (i) => i.id === selectedMethods);

    return (
      <div>
        <Container fluid className="container-header">
          <Grid.Row>
            {/* <h2 className="whiteboxHeading">{APP_NAME}</h2> */}
            <Link className="whiteboxHeading" to={'/'}>
              <img alt="TaksoLogo" className="takso-logo" src={loginLogo} />
            </Link>
          </Grid.Row>

          <Grid.Row className="mainRow">
            <CustomSegment
              title="QUESTION GENERATOR"
              children={
                <Segment
                  className="description-segment"
                  textAlign="left"
                  loading={loading}
                >
                  <p>
                    This question generator has been created to support
                    practitioners when undertaking evaluation using the Cultural
                    Development Networks Outcome Schema.
                  </p>
                  <p>
                    Before you evaluate, you need to be clear{' '}
                    <span className="bold">what</span> you are wanting to find
                    out about your activity and{' '}
                    <span className="bold">who</span> you expect to have been
                    impacted by your activity. If you have followed the planning
                    process recommended in CDN’s Planning Framework or in Takso
                    you will have established at least two objectives for your
                    activity that your evaluation will need to measure. If not,
                    we recommend you select outcomes from the{' '}
                    <a
                      href="https://www.culturaldevelopment.net.au/outcomes/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link"
                    >
                      outcome schema
                    </a>{' '}
                    that your activity is intended to achieve. At least one of
                    these should be a cultural outcome, given that your activity
                    is a cultural activity. You might have an outcome or
                    outcomes from other domains, such as social or economic,
                    that you plan for your activity to address. Select one or
                    more of these from the outcome schema before you start. We
                    recommend no more than two outcomes be selected, as each one
                    requires work to collect data and analyse it.
                  </p>

                  <hr className="hr-padding" />

                  <AvForm
                    ref={(c) => (this.form = c)}
                    className="activity-form"
                  >
                    <Grid>
                      <Grid.Row className="row-center">
                        <Grid.Column
                          mobile={16}
                          tablet={5}
                          computer={5}
                          className="column-padding"
                        >
                          <div className="column-bar">
                            <AvField
                              className="generator-inputs"
                              type="select"
                              name="policy"
                              label="POLICY DOMAIN"
                              onChange={(e) =>
                                this.onChangeHandler('policy', e.target.value)
                              }
                            >
                              <option value="" disabled />
                              {map(policies, (item, i) => (
                                <option key={i} value={item.id}>
                                  {item.policyName}
                                </option>
                              ))}
                            </AvField>
                            <label className="field-label">
                              Select a policy domain that your activity belongs
                              to.
                            </label>
                          </div>
                        </Grid.Column>
                        <Grid.Column
                          mobile={16}
                          tablet={5}
                          computer={5}
                          className="column-padding"
                        >
                          {selectedPolicy ? (
                            <div className="column-bar">
                              <AvField
                                className="generator-inputs"
                                type="select"
                                name="outcome"
                                label="OBJECTIVE"
                                onChange={(e) =>
                                  this.onChangeHandler(
                                    'outcome',
                                    e.target.value
                                  )
                                }
                              >
                                <option value="" disabled />
                                {map(objectives, (item, i) => (
                                  <option key={i} value={item.id}>
                                    {item.title}
                                  </option>
                                ))}
                              </AvField>
                              <label className="field-label">
                                Select the objective you want to measure
                              </label>
                            </div>
                          ) : null}
                        </Grid.Column>
                        <Grid.Column
                          mobile={16}
                          tablet={5}
                          computer={5}
                          className="column-padding"
                        >
                          {selectedOutcomes ? (
                            <div className="column-bar">
                              <AvField
                                className="generator-inputs"
                                type="select"
                                name="method"
                                label="METHOD"
                                onChange={(e) =>
                                  this.onChangeHandler('method', e.target.value)
                                }
                              >
                                <option value="" disabled />
                                {map(methods, (item, i) => (
                                  <option key={i} value={item.id}>
                                    {item.details}
                                  </option>
                                ))}
                              </AvField>
                              <label className="field-label">
                                Select the method you will use to perform your
                                evaluation.
                              </label>
                            </div>
                          ) : null}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </AvForm>

                  {!isEmpty(question) ? (
                    <div>
                      <hr className="hr-padding" />

                      <div className="qs-title">Introduction</div>
                      <div
                        className="qs-body"
                        dangerouslySetInnerHTML={{
                          __html: question.method.preamble,
                        }}
                      />

                      <div className="qs-title">Outcome description</div>
                      <div
                        className="qs-body"
                        dangerouslySetInnerHTML={{
                          __html: question.preamble,
                        }}
                      />

                      <div className="qs-title">
                        Question(s) for quantitative data
                      </div>
                      <div className="qs-body">
                        {selectedMethods === '9' ? (
                          <div>
                            <strong>
                              Reason for this evaluation method
                              <em> (you can only choose one option)</em>
                            </strong>
                            <ul>{this.renderEvaluationReasonsList()}</ul>

                            <p>
                              The intended outcome was {outcome.title} through
                              their engagement in this activity.
                            </p>

                            <p>
                              By {outcome.title} we mean sparking of the
                              imagination, creativity or curiosity, resulting in
                              increased desire to participate more and/or create
                              new cultural works.
                            </p>

                            <strong>
                              How did you form the assessment to provide this
                              evaluation?
                              <em> (you can choose multiple options)</em>
                            </strong>
                            <ul>{this.renderEvaluationAssessmentTypes()}</ul>
                            <br />
                          </div>
                        ) : null}

                        <div
                          dangerouslySetInnerHTML={{
                            __html: question.question1,
                          }}
                        />
                        {question.question2 ? (
                          <div>
                            <br />
                            <div
                              dangerouslySetInnerHTML={{
                                __html: question.question2,
                              }}
                            />
                          </div>
                        ) : null}
                      </div>

                      {get(getMethod, 'method', null) === 'Intercept' ? null : (
                        <>
                          <div className="qs-title">
                            Question for qualitative data
                          </div>
                          <div className="qs-body">
                            <p>
                              Can you tell us more about <strong>why</strong>{' '}
                              you gave this rating? <em>(free text field)</em>
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  ) : null}
                </Segment>
              }
            />
          </Grid.Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    policies: state.extra.policies,
    methods: state.extra.methods,
    outcomes: state.extra.outcomes,
    question: state.extra.generatedQuestion,
    evalReasons: state.extra.evalReasons,
    evalAssesmentTypes: state.extra.evalAssesmentTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPolicies,
      getOutcomes,
      getMethods,
      generateQuestion,
      getEvalAssesmentTypes,
      getEvalReasons,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionGenerator);
