/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Segment, Loader } from 'semantic-ui-react';
import { find, isEmpty, map, get, compact } from '../../../utils/lodash';
import './report.scss';
import { CustomNotification } from '../../../components/customReportHeader';
import { GoogleMap } from '../../../components/googleMap';
import {
  BarChartComp,
  barOptions,
  stackedOptions,
} from '../../../components/barChartComp';
import { CustomSegment, EmptyContainer } from '../../../components/';
import { threeDotSeparator } from '../../../utils';
import {
  ORGANISATION_REPORT_LOADING,
  OUTCOME_ADDRESS_BAR_COLORS,
} from '../../../utils/constant';
import { getActivityReportLoadingAttributes } from '../../../utils/activity';

export const OrganizationActivitySummary = ({
  dateRangeChosenLabel,
  parent = false,
  superAdmin = false,
  activityStatsLoading = false,
}) => {
  const activityStat = useSelector((state) => state.report.activityStats);
  const industrySectorTypes = useSelector(
    (state) => state.extra.industrySectorTypes
  );
  const organization = useSelector((state) => state.organization.organization);

  const getOrganizationSectorType = () => {
    try {
      const type = find(industrySectorTypes, (o) => {
        return o.id == organization.industrySectorTypeId;
      });
      if (isEmpty(type)) {
        return '-';
      } else {
        return type.name;
      }
    } catch (error) {
      return '-';
    }
  };

  const renderHeaderItems = () => {
    return (
      <div className="left-align">
        <div className="activity-name">
          <div
            className={
              activityStat.totalDevelopmentCount == null
                ? 'circle circle-bottom-null activity-warning'
                : 'circle circle-bottom activity-warning'
            }
          />
          <div className="col-wise">
            <div>
              <label>In-development</label>
            </div>
            <div className="number-centre">
              {activityStat.totalDevelopmentCount}
            </div>
          </div>
        </div>

        <div className="activity-name">
          <div
            className={
              activityStat.totalActiveCount == null
                ? 'circle circle-bottom-null activity-success'
                : 'circle circle-bottom activity-success'
            }
          />
          <div className="col-wise">
            <div>
              <label>Active</label>
            </div>
            <div className="number-centre">{activityStat.totalActiveCount}</div>
          </div>
        </div>

        <div className="activity-name">
          <div
            className={
              activityStat.totalCompletedCount == null
                ? 'circle circle-bottom-null activity-completed'
                : 'circle circle-bottom activity-completed'
            }
          />
          <div className="col-wise">
            <div>
              <label>Completed</label>
            </div>
            <div className="number-centre">
              {activityStat.totalCompletedCount}
            </div>
          </div>
        </div>

        <div className="activity-name">
          <div className="col-wise">
            <div>
              <label>Total Activities</label>
            </div>
            <div className="number-centre">
              {activityStat.totalActivityCount}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <CustomSegment title="Activity Overview">
        <div className="activity-count-info">
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <div>
                  {dateRangeChosenLabel !== 'Since the Start' && (
                    <CustomNotification
                      messageBanner={
                        'In development and Active figures reflect status on final day of the search period. Completed figure reflects activities completed in this period.  See help page for more information.'
                      }
                    />
                  )}
                </div>
                <Grid className="activity-summary-grid" padded>
                  {activityStatsLoading ? (
                    <Loader active size="medium" />
                  ) : (
                    <>
                      {parent || superAdmin ? null : (
                        <div className="org-down">
                          <Grid.Row>
                            <label>Organisation Type</label>
                          </Grid.Row>
                          <Grid.Row>
                            <h6>{getOrganizationSectorType()}</h6>
                          </Grid.Row>
                        </div>
                      )}

                      <div className="left-align">{renderHeaderItems()}</div>
                    </>
                  )}
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </CustomSegment>
    </>
  );
};

export const Activity = ({ dateRangeChosenLabel }) => {
  const background = '#E0E0E0';
  const gray = '#757575';
  const black = '#000000';
  const white = '#ffff';

  const [activityType, setActivityType] = useState({
    mainTitle: [],
    dataset: [],
    development: [],
    active: [],
    completed: [],
    styles: {
      color: [],
      weight: [],
      highlight: [],
      size: [],
    },
  });

  const [venueType, setVenueType] = useState({
    mainTitle: [],
    dataset: [],
    development: [],
    active: [],
    completed: [],
    styles: {
      color: [],
      weight: [],
      highlight: [],
      size: [],
    },
  });

  const [participantsType, setParticipantsType] = useState({
    mainTitle: [],
    dataset: [],
    development: [],
    active: [],
    completed: [],
    styles: {
      color: [],
      weight: [],
      highlight: [],
      size: [],
    },
  });

  const participantSummary = useSelector(
    (state) => state.report.participantSummary
  );
  const venueChart = useSelector((state) => state.report.venueChart);
  const actSummary = useSelector((state) => state.report.actSummary);

  const reportInitialState = useSelector((state) => state.report);
  const loadingValues = getActivityReportLoadingAttributes(
    ORGANISATION_REPORT_LOADING,
    reportInitialState
  );

  const {
    activityStatsLoading,
    activityReportLoading,
    venueReportLoading,
    participantLoading,
    // organisationMapLoading, // commented out
    activityMapLoading,
    contractorMapLoading,
  } = loadingValues;

  const statuses = useSelector(
    (state) => state.report.reportFilterOptions.statuses
  );

  const usedDataOnly = useSelector(
    (state) => state.report.reportFilterOptions.usedDataOnly
  );

  const report = useSelector((state) => state.report);

  const outcomes = get(actSummary, 'outcomes', {});

  const appendByStatusValue = (value, type, setArrayType) => {
    value.data.byStatus[type].map((data, i) =>
      setArrayType((prevState) => ({
        ...prevState,
        [type]: [...prevState[type], data],
      }))
    );
  };

  const makeChartObject = (chartType, setArrayType) => {
    if (!isEmpty(chartType)) {
      chartType.map((chartTitle) => {
        setArrayType((prevState) => ({
          ...prevState,
          // totalElements: chartTitle.totalElements,
          mainTitle: [...prevState.mainTitle, chartTitle.mainTitle],
          dataset: [...prevState.dataset, null],
          styles: {
            size: [...prevState.styles.size, 16],
            color: [...prevState.styles.color, gray],
            weight: [...prevState.styles.weight, '500'],
            highlight: [...prevState.styles.highlight, background],
          },
        }));
        if (statuses.includes(1))
          setArrayType((prevState) => ({
            ...prevState,
            development: [...prevState.development, null],
          }));
        if (statuses.includes(2))
          setArrayType((prevState) => ({
            ...prevState,
            active: [...prevState.active, null],
          }));
        if (statuses.includes(3))
          setArrayType((prevState) => ({
            ...prevState,
            completed: [...prevState.completed, null],
          }));

        chartTitle.data.label.map((label, i) =>
          setArrayType((prevState) => ({
            ...prevState,
            mainTitle: [...prevState.mainTitle, label],
            styles: {
              size: [...prevState.styles.size, 14],
              color: [...prevState.styles.color, black],
              weight: [...prevState.styles.weight, '400'],
              highlight: [...prevState.styles.highlight, white],
            },
          }))
        );

        chartTitle.data.dataset.map((data, i) =>
          setArrayType((prevState) => ({
            ...prevState,
            dataset: [...prevState.dataset, data],
          }))
        );

        if (statuses.includes(1))
          appendByStatusValue(chartTitle, 'development', setArrayType);
        if (statuses.includes(2))
          appendByStatusValue(chartTitle, 'active', setArrayType);
        if (statuses.includes(3))
          appendByStatusValue(chartTitle, 'completed', setArrayType);
        return null;
      });
    }
  };

  const getHeight = (dataLength) => `${5 * dataLength.length}rem`;

  useEffect(() => {
    // if (!isEmpty(venueChart)) {
    setVenueType({
      mainTitle: [],
      dataset: [],
      development: [],
      active: [],
      completed: [],
      styles: {
        color: [],
        weight: [],
        highlight: [],
        size: [],
      },
    });
    makeChartObject(venueChart, setVenueType, usedDataOnly);
    // }
  }, [venueChart, statuses]);

  useEffect(() => {
    // if (!isEmpty(outcomes)) {
    setActivityType({
      mainTitle: [],
      dataset: [],
      development: [],
      active: [],
      completed: [],
      styles: {
        color: [],
        weight: [],
        highlight: [],
        size: [],
      },
    });
    makeChartObject(outcomes, setActivityType, usedDataOnly);
    // }
  }, [actSummary, usedDataOnly, statuses]);

  useEffect(() => {
    setParticipantsType({
      mainTitle: [],
      dataset: [],
      development: [],
      active: [],
      completed: [],
      styles: {
        color: [],
        weight: [],
        highlight: [],
        size: [],
      },
    });
    makeChartObject(participantSummary, setParticipantsType, usedDataOnly);
  }, [participantSummary, usedDataOnly, statuses]);

  const renderMaps = () => {
    const mapData = [
      // {
      //   data: report.orgMapData,
      //   title: 'Organisation',
      //   isEmptyData: isEmpty(get(report, 'orgMapData', [])),
      //   loading: organisationMapLoading,
      // },
      {
        data: report.actMapData,
        title: 'Activities',
        isEmptyData: isEmpty(get(report, 'actMapData', [])),
        loading: activityMapLoading,
      },
      {
        data: report.contractorMapData,
        title: 'Contractor',
        isEmptyData: isEmpty(get(report, 'contractorMapData', [])),
        loading: contractorMapLoading,
      },
    ];

    return map(mapData, (item, idx) => {
      return (
        <Fragment key={idx}>
          <br />
          <CustomSegment title={item.title}>
            <Segment loading={item.loading}>
              {!item.isEmptyData ? (
                <GoogleMap data={item.data} type={item.title} />
              ) : (
                <EmptyContainer msg="No Data Available" />
              )}
            </Segment>
          </CustomSegment>
        </Fragment>
      );
    });
  };

  const renderActivitySummary = () => {
    return (
      <>
        <OrganizationActivitySummary
          activityStatsLoading={activityStatsLoading}
          dateRangeChosenLabel={dateRangeChosenLabel}
        />
        <br />

        <CustomSegment title="Activity Type">
          <Segment className="chart-holder" loading={activityReportLoading}>
            {!isEmpty(activityType.dataset) ? (
              <div
                style={{
                  height: getHeight(get(activityType, 'dataset', 0)),
                  width: 'auto',
                  background: 'white',
                }}
              >
                <ChartComponent
                  toggle={usedDataOnly}
                  data={!isEmpty(activityType) && activityType}
                  chartType={'activitiesOnly'}
                  status={statuses}
                />
              </div>
            ) : activityReportLoading ? (
              <div className="no-goals-container"></div>
            ) : (
              <EmptyContainer msg="No Data Available" />
            )}
          </Segment>
        </CustomSegment>
      </>
    );
  };

  const renderActivityVenues = () => {
    return (
      <>
        <CustomSegment title="Activity Venues">
          <Segment className="chart-holder" loading={venueReportLoading}>
            {!isEmpty(venueType.dataset) ? (
              <div
                style={{
                  height: getHeight(get(venueType, 'dataset', 0)),
                  width: 'auto',
                  background: 'white',
                }}
              >
                <ChartComponent
                  toggle={usedDataOnly}
                  data={!isEmpty(venueType) && venueType}
                  chartType={'venuesOnly'}
                  status={statuses}
                />
              </div>
            ) : venueReportLoading ? (
              <div className="no-goals-container"></div>
            ) : (
              <EmptyContainer msg="No Data Available" />
            )}
          </Segment>
        </CustomSegment>
      </>
    );
  };

  const renderActivityParticipants = () => {
    return (
      <>
        <CustomSegment title="Activity Participants">
          <Segment className="chart-holder" loading={participantLoading}>
            {!isEmpty(participantsType.dataset) ? (
              <div
                style={{
                  height: getHeight(get(participantsType, 'dataset', 0)),
                  width: 'auto',
                  background: 'white',
                }}
              >
                <ChartComponent
                  toggle={usedDataOnly}
                  data={!isEmpty(participantsType) && participantsType}
                  chartType={'participantsOnly'}
                  status={statuses}
                />
              </div>
            ) : participantLoading ? (
              <div className="no-goals-container"></div>
            ) : (
              <EmptyContainer msg="No Data Available" />
            )}
          </Segment>
        </CustomSegment>
      </>
    );
  };

  return (
    <>
      <>
        {renderActivitySummary()}
        <br />
        {renderActivityVenues()}
        <br />
        {renderActivityParticipants()}
        <br />
        {renderMaps()}
      </>
    </>
  );
};

export const ChartComponent = ({
  data,
  chartType,
  dynamicBarColor = false,
  status,
  reportType,
  changeOutcomeInterface,
  viewOnly = false, // to check chart render on program report / only for view
  props,
}) => {
  const completed = '#003195';
  const active = '#339934';
  const inDevelopment = '#FF781E';
  const venue = '#46D200';
  const participants = '#D92B2C';
  const activities = '#0C95CD';

  const barDefaultColor = (type) => {
    switch (type) {
      case 'activitiesOnly':
        return activities;
      case 'venuesOnly':
        return venue;
      case 'participantsOnly':
        return participants;
      default:
        return null;
    }
  };

  const getSelectedStatusCharts = () => {
    const statusesDataSet = [];

    if (
      isEmpty(status) ||
      (status.includes(1) && !isEmpty(compact(data.development)))
    ) {
      statusesDataSet.push({
        label: 'In development',
        data: data.development,
        borderColor: inDevelopment,
        backgroundColor: inDevelopment,
      });
    }

    if (
      isEmpty(status) ||
      (status.includes(2) && !isEmpty(compact(data.active)))
    ) {
      statusesDataSet.push({
        label: 'Active',
        data: data.active,
        borderColor: active,
        backgroundColor: active,
      });
    }

    if (
      isEmpty(status) ||
      (status.includes(3) && !isEmpty(compact(data.completed)))
    ) {
      statusesDataSet.push({
        label: 'Completed',
        data: data.completed,
        borderColor: completed,
        backgroundColor: completed,
      });
    }

    return statusesDataSet;
  };

  if (isEmpty(data.mainTitle)) {
    return <EmptyContainer msg="No Data Available" />;
  }

  return (
    <>
      <BarChartComp
        viewOnly={viewOnly}
        options={
          status.length !== 0
            ? stackedOptions(
                data.styles.color,
                data.styles.weight,
                data.styles.highlight,
                data.styles.size
              )
            : barOptions(
                data.styles.color,
                data.styles.weight,
                data.styles.highlight,
                data.styles.size
              )
        }
        labels={data.mainTitle.map((data) => {
          return threeDotSeparator({
            name: data,
            length: 50,
            isPopUp: false,
          });
        })}
        dataLabels={data.mainTitle}
        datasets={
          status.length !== 0 && chartType === 'activitiesOnly'
            ? getSelectedStatusCharts()
            : [
                {
                  label: 'All',
                  data: data.dataset,
                  borderColor: dynamicBarColor
                    ? data.styles.barColor
                    : barDefaultColor(chartType),
                  backgroundColor: dynamicBarColor
                    ? data.styles.barColor
                    : barDefaultColor(chartType),
                },
              ]
        }
        changeOutcomeInterface={changeOutcomeInterface}
        reportType={reportType}
        className="bar-chart"
        props={props}
      />
    </>
  );
};

const barColorSelector = (type) => {
  switch (type) {
    case 'Cultural':
      return OUTCOME_ADDRESS_BAR_COLORS.CULTURAL;
    case 'Social':
      return OUTCOME_ADDRESS_BAR_COLORS.SOCIAL;
    case 'Economic':
      return OUTCOME_ADDRESS_BAR_COLORS.ECONOMIC;
    case 'Governance':
      return OUTCOME_ADDRESS_BAR_COLORS.GOVERNANCE;
    case 'Environmental':
      return OUTCOME_ADDRESS_BAR_COLORS.ENVIRONMENTAL;
    default:
      break;
  }
};

export const makeChartObject = (chartType, setArrayType, reportFilter) => {
  const background = '#E0E0E0';
  const gray = '#757575';
  const black = '#000000';
  const white = '#ffff';
  if (!isEmpty(chartType)) {
    for (const chartTitle of chartType) {
      setArrayType((prevState) => ({
        ...prevState,
        mainTitle: [...prevState.mainTitle, chartTitle.mainTitle],
        dataset: [...prevState.dataset, null],
        development: [...prevState.development, null],
        active: [...prevState.active, null],
        completed: [...prevState.completed, null],
        styles: {
          size: [...prevState.styles.size, 16],
          color: [...prevState.styles.color, gray],
          weight: [...prevState.styles.weight, '600'],
          highlight: [...prevState.styles.highlight, background],
          barColor: [...prevState.styles.barColor, white],
        },
      }));
      const coloredBar = barColorSelector(chartTitle.mainTitle);

      chartTitle.data.label.map((label, i) =>
        setArrayType((prevState) => ({
          ...prevState,
          mainTitle: [...prevState.mainTitle, label],
          styles: {
            size: [...prevState.styles.size, 14],
            color: [...prevState.styles.color, black],
            weight: [...prevState.styles.weight, '400'],
            highlight: [...prevState.styles.highlight, white],
            barColor: [...prevState.styles.barColor, coloredBar],
          },
        }))
      );

      chartTitle.data.dataset.map((data, i) =>
        setArrayType((prevState) => ({
          ...prevState,
          dataset: [...prevState.dataset, data],
        }))
      );

      if (reportFilter.statuses.includes(1))
        appendByStatusValue(chartTitle, 'development', setArrayType);
      if (reportFilter.statuses.includes(2))
        appendByStatusValue(chartTitle, 'active', setArrayType);
      if (reportFilter.statuses.includes(3))
        appendByStatusValue(chartTitle, 'completed', setArrayType);
      if (isEmpty(reportFilter.statuses)) {
        appendByStatusValue(chartTitle, 'development', setArrayType);
        appendByStatusValue(chartTitle, 'active', setArrayType);
        appendByStatusValue(chartTitle, 'completed', setArrayType);
      }
    }
  }
};

export const appendByStatusValue = (value, type, setArrayType) => {
  value.data.byStatus[type].map((data) =>
    setArrayType((prevState) => ({
      ...prevState,
      [type]: [...prevState[type], data],
    }))
  );
};
