import React from 'react';
import { Loader, Segment } from 'semantic-ui-react';
import { CustomSegment } from '../../../../components';
import { ActivityData } from './activityData';
import { ActivityInput } from './activityInput';
import { ActivityOutput } from '../activityPlan/activityOutput';
import { GroupParticipants } from './groupParticipants';
import { ActivityMemberSteps } from './activityMemberSteps';
import { GrantProgramData } from './grantProgramData';
import { getPageDisable } from '../../../../utils';
import {
  isFestivalProgramActivity,
  isGrantProgramActivity,
  isMapProgramActivity,
  isParentProgramActivity,
} from '../../../../utils/activity';
import LinkedActivities from './linkedActivity';

const ActivityPlan = ({ user, currentActivity, actPut, ...props }) => {
  const pageDisabled = getPageDisable(
    currentActivity,
    user,
    'project.edit.activityPlan'
  );

  return (
    <div>
      <CustomSegment
        title={`${
          isParentProgramActivity(currentActivity.activityPlanTypeId)
            ? 'Program'
            : 'Activity'
        } Description`}
        className="program-activity-description"
        children={<ActivityData currentState="project.edit.activityPlan" />}
        rightHeader={
          <Loader
            active={actPut.activityData ? actPut.activityData : false}
            inline
          />
        }
      />

      {user && currentActivity && (
        <CustomSegment
          //className="activity-members"
          title="Activity Members"
          subTitle="Invite organisation members to participate in the activity"
          children={<ActivityMemberSteps {...props} />}
          rightHeader={
            <Loader
              active={actPut.activityMembers ? actPut.activityMembers : false}
              inline
            />
          }
          extraMargin
        />
      )}

      <CustomSegment
        className="projected-inputs"
        title={
          currentActivity.statusId === 1 ? 'Projected Inputs' : 'Actual Inputs'
        }
        // 'Provide details for projected expected cost and requirements'
        children={
          <ActivityInput
            actual={currentActivity.statusId !== 1}
            currentState="project.edit.activityPlan"
          />
        }
        rightHeader={
          <Loader
            active={actPut.projectInput ? actPut.projectInput : false}
            inline
          />
        }
        extraMargin
      />
      <CustomSegment
        className="projected-outputs"
        title={
          currentActivity.statusId === 1
            ? 'Projected Outputs'
            : 'Actual Outputs'
        }
        children={
          actPut.activityData ? (
            <Segment loading className="loading-segment" />
          ) : (
            <ActivityOutput
              actual={currentActivity.statusId !== 1}
              currentState="project.edit.activityPlan"
            />
          )
        }
        rightHeader={
          <Loader
            active={actPut.projectedOutputs ? actPut.projectedOutputs : false}
            inline
          />
        }
        extraMargin
        disabled={pageDisabled}
      />

      {currentActivity.activityPlanTypeId === 1 && (
        <CustomSegment
          className="core-participant-group"
          title="Core Participant Group"
          children={
            actPut.activityData ? (
              <Segment loading className="loading-segment" />
            ) : (
              <GroupParticipants
                {...props}
                currentState="project.edit.activityPlan"
                pageDisabled={pageDisabled}
              />
            )
          }
          extraMargin
        />
      )}

      {/* Grant Program */}
      {currentActivity.activityPlanTypeId === 4 &&
        currentActivity.statusId > 1 && (
          <CustomSegment
            noPrint
            title="Grants Program"
            children={<GrantProgramData {...props} />}
            extraMargin
          />
        )}

      {/* Need to show linked activities table Festival and MAP for all the status
       * but grant linked activities need to show after green light
       */}
      {isFestivalProgramActivity(currentActivity.activityPlanTypeId) || // Festival & MAP
      isMapProgramActivity(currentActivity.activityPlanTypeId) ||
      (isGrantProgramActivity(currentActivity.activityPlanTypeId) &&
        currentActivity.statusId > 1) ? (
        <div className="mt-4">
          <LinkedActivities extraBottomMargin />
        </div>
      ) : null}
    </div>
  );
};

export default ActivityPlan;
