/* eslint-disable no-redeclare */
/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import {
  Segment,
  Icon,
  Table,
  Button,
  Grid,
  Tab,
  Popup,
  GridColumn,
  Modal,
} from 'semantic-ui-react';
import moment from 'moment';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { CSVLink } from 'react-csv';

import { BrowseFile, CustomTable, CustomTooltip } from '../../../../components';

import '../activity.scss';
import {
  isEmpty,
  map,
  cloneDeep,
  sum,
  flattenDeep,
  get,
  filter,
  compact,
  size,
} from '../../../../utils/lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EvaluationSurvey } from './activityEvaluation';
import {
  postProjectEvaluations,
  uploadActivityCsv,
  getEvalReasons,
  getEvalAssesmentTypes,
  updateSelfAssessed,
  evalPlanManage,
  exportEvaluationResponses,
  exportEvaluationResponsesByFile,
  getEvaluationComment,
  getEvaluationPlans,
} from '../../../../store/actions';
import {
  removeEditable,
  averageValue,
  getPageDisable,
} from '../../../../utils';
import CopyToClipboard from 'react-copy-to-clipboard';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import {
  TrendRateOfChangeChart,
  IndividualRateOfChangeChart,
  AggregateChart,
} from '../../../../components/chartsWidgets';

class UploadCsv extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      value: {},
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  uploadFileHandler = (file) => {
    this.setState({ loading: true }, async () => {
      try {
        const {
          uploadActivityCsv,
          toggle,
          evaluation,
          evalPlan,
          chart,
          resetData,
        } = this.props;
        let formData = new FormData();
        formData.set('upfile', file);
        if (evaluation) {
          formData.append('projectId', evaluation.projectId);
          formData.append('objectiveId', evaluation.objectiveId);
          formData.append('date', moment().format('YYYY-MM-DD'));
          if (
            get(evaluation, 'method.response', null) === 'Participant' &&
            get(evaluation, 'method.method', null) === 'Intercept' &&
            get(evalPlan, 'collectionMethod', null) === 'CULTURECOUNTS'
          ) {
            formData.append('isCultureCount', true);
          }
        }
        await uploadActivityCsv(evaluation, formData);
        await this.setState({ loading: false }, () => {
          resetData();
          chart();
        });
        toggle();
      } catch (error) {
        this.setState({ loading: false });
      }
    });
  };

  render() {
    const { modal, toggle } = this.props;
    return (
      <Modal
        size="small"
        open={modal}
        onOpen={() => toggle()}
        closeIcon={
          <Icon onClick={() => toggle()} name="close" className="closeicon" />
        }
      >
        <Modal.Header>Upload a CSV file</Modal.Header>
        <Modal.Content>
          <BrowseFile
            label="UPLOAD EVALUATION TEMPLATE"
            name="strategic"
            accept=".csv"
            buttonText="BROWSE"
            uploadFile={this.uploadFileHandler}
            loading={this.state.loading}
            csv
          />
        </Modal.Content>
      </Modal>
    );
  }
}

class EvaluationQuestion extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showQ: false,
      evaluation: null,
      loading: false,
      participantResponse: [],
      // checked: false
    };
  }

  async componentDidMount() {
    const {
      getEvaluationComment,
      evaluation,
      currentActivity,
      getEvaluationPlans,
    } = this.props;
    await getEvaluationPlans(currentActivity.id);
    await getEvaluationComment(currentActivity.id, evaluation.evaluationPlanId);
    this.setParticipantResponse();
  }

  setParticipantResponse() {
    let evaluation = cloneDeep(this.props.evaluation);
    this.setState({
      participantResponse: isEmpty(evaluation.participantResponse)
        ? []
        : evaluation.participantResponse,
    });
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  chartReRender() {
    this.chart && this.chart.renderChart();
  }

  onChange(value, arryNumber, i) {
    let { participantResponse } = this.state;
    let evaluation = cloneDeep(this.props.evaluation);
    const count = parseInt(value) ? parseInt(value) : 0;
    participantResponse[arryNumber][i] = count;
    this.setState(
      {
        participantResponse,
      },
      async () => {
        evaluation.participantResponse = participantResponse;
        try {
          await this.props.postProjectEvaluations(
            evaluation,
            `evaluationQuestion${evaluation.objectiveId}`
          );
          this.chartReRender();
        } catch (error) {
          //
        }
      }
    );
  }

  controlQuestion() {
    this.setState((prevState) => ({
      showQ: !prevState.showQ,
    }));
  }

  getRemainingParticipants(
    participantResponse,
    { actualParticipantsEvaluated },
    index
  ) {
    let count = 0;
    if (participantResponse.length) {
      if (index) {
        count = sum(participantResponse[index]);
      } else {
        count = sum(participantResponse[0]);
      }
    }

    if (actualParticipantsEvaluated - count >= 0) {
      return actualParticipantsEvaluated - count;
    }
    return (
      <span className="error-count">
        <Icon color="red" name="warning sign" />
        {`Please set correct values (${
          count - actualParticipantsEvaluated
        } participants exceeded)`}
      </span>
    );
  }

  async componentDidUpdate(prevProps) {
    const {
      isFileChanged,
      getEvaluationComment,
      evaluation,
      currentActivity,
      getEvaluationPlans,
    } = this.props;

    if (
      prevProps.evaluation.actualParticipantsEvaluated !==
      this.props.evaluation.actualParticipantsEvaluated
    ) {
    }

    // check file is uploaded if it's update charts, tables, and other eval-plan data
    if (isFileChanged) {
      await getEvaluationComment(
        currentActivity.id,
        evaluation.evaluationPlanId
      );
      await getEvaluationPlans(currentActivity.id);
      this.setParticipantResponse();
      this.chartReRender();
    }
  }

  // get the responses uploaded/ collected by file (CSV)
  async participantResponseExportByFile(evaluation) {
    const { exportEvaluationResponsesByFile } = this.props;
    await exportEvaluationResponsesByFile(`evaluationQuestion`, evaluation);
    const { evaluationResponsesCsvLink } = this.props;
    if (!isEmpty(evaluationResponsesCsvLink)) {
      window.open(evaluationResponsesCsvLink.download_link, '_blank');
    }
  }

  render() {
    const { showQ, participantResponse } = this.state;
    const {
      evaluation,
      evalPlan,
      index,
      user,
      helpTips,
      setChartToReport,
      checked,
      currentActivity,
      objectiveName,
    } = this.props;

    // formatting chart data for new chart formats
    const _participantResponse = get(evaluation, 'participantResponse', null);
    const methodId = get(evaluation, 'methodId', null);
    const chartData = {
      name: objectiveName,
      totalParticipants: get(evaluation, 'totalParticipants', null),
      totalRespondents: get(evaluation, 'actualParticipantsEvaluated', null),
      evalCount: 1, // no need to show
      activityCount: 1, // no need to show
      data: Boolean(get(evaluation, 'cumulative', 0)) // for post pre chart
        ? _participantResponse
        : // for post  chart
          _participantResponse && _participantResponse.length > 0
          ? _participantResponse[0]
          : null,
      results: get(evaluation, 'prePostSurveyResponse', null),
    };

    const [one = [], two = []] = participantResponse;
    const participantResponseCount = sum(flattenDeep(participantResponse)) || 0;
    const isDisabled =
      get(evalPlan, 'isDisabled', false) ||
      getPageDisable(currentActivity, user, 'project.edit.evaluationPlan');
    const activeCultureCount =
      get(evalPlan, 'method.response', null) === 'Participant' &&
      get(evalPlan, 'method.method', null) === 'Intercept' &&
      get(evalPlan, 'collectionMethod', null) === 'CULTURECOUNTS';

    const questioner = {
      __html: evaluation
        ? removeEditable(`          
                    ${index === 0 ? evaluation.methodHeading || '' : ''}
                    ${evaluation.heading || ''}<br/>
                    ${evaluation.question1 || ''}<br/>
                    ${evaluation.question2 || ''}<br/>`)
        : null,
    };

    /**
     *  This is used for evaluation charts withouts self assessment type plans
     */
    return (
      <Segment
        className="eval-que-seg"
        loading={
          // this.props.actPut[`evaluationQuestion${evaluation.objectiveId}`]
          //   ? this.props.actPut[`evaluationQuestion${evaluation.objectiveId}`]
          //   :
          this.state.loading || this.props.activity.evalPlanLoading
        }
      >
        <div>
          <div className="eval-que-div">
            <>
              <div className="participant-res">
                {evalPlan.collectionMethod === 'MANUAL' ? (
                  <p>
                    In the fields below please specify the number of evaluation
                    participants who selected the number on the scale from
                    not-at-all to most imaginable.
                  </p>
                ) : null}

                {/* // evaluation isNewCsvFeature: upload all objectives data in one CSV file */}
                {(evalPlan.collectionMethod === 'CSV' ||
                  evalPlan.collectionMethod === 'CULTURECOUNTS') &&
                !evalPlan.isNewCsvFeature ? (
                  <Segment
                    disabled={
                      this.props.currentActivity.statusId == 3
                        ? get(evalPlan, 'isSeriesActive', false)
                          ? true
                          : false
                        : false
                    }
                  >
                    <label className="participant-lbl">Import CSV Data</label>
                    <p>
                      {activeCultureCount
                        ? `Populate with your data
                      and use the upload button to load your participant
                      responses.`
                        : `Download the evaluation template, populate with your data
                      and use the upload button to load your participant
                      responses.`}
                    </p>
                    <div>
                      <CSVLink
                        className={
                          isDisabled
                            ? // || (!get(evalPlan, 'isSeriesActive', false) &&
                              //   get(evalPlan, 'seriesParentId', null))
                              'disabled-csv'
                            : ''
                        }
                        headers={
                          Boolean(evaluation.cumulative)
                            ? [
                                {
                                  label: 'response date',
                                  key: 'responseDate',
                                },
                                {
                                  label: 'after response (1-10)',
                                  key: 'afterResponse',
                                },
                                {
                                  label: 'before response (1-10)',
                                  key: 'beforeResponse',
                                },
                                {
                                  label: 'reason (free text)',
                                  key: 'reason',
                                },
                              ]
                            : [
                                {
                                  label: 'response date',
                                  key: 'responseDate',
                                },
                                {
                                  label: 'participant response (1-10)',
                                  key: 'participantResponse',
                                },
                                {
                                  label: 'reason (free text)',
                                  key: 'reason',
                                },
                              ]
                        }
                        data={[]}
                        filename={
                          evaluation && evaluation.outcome
                            ? `${evaluation.outcome.title}.csv`
                            : 'template.csv'
                        }
                      >
                        {activeCultureCount ? null : (
                          <Button
                            icon
                            labelPosition="left"
                            className="upload-btn"
                            disabled={isDisabled}
                          >
                            <Icon name="cloud download" />
                            Download CSV Template
                          </Button>
                        )}
                      </CSVLink>
                      <Button
                        icon
                        labelPosition="left"
                        className="upload-btn"
                        onClick={() => this.toggle()}
                        disabled={isDisabled}
                      >
                        <Icon name="upload" />
                        {activeCultureCount
                          ? 'Import Culture Counts Survey'
                          : 'Upload CSV'}
                      </Button>
                    </div>
                  </Segment>
                ) : null}
              </div>

              <UploadCsv
                setFile={this.setFile}
                modal={this.state.modal}
                toggle={() => this.toggle()}
                chart={() => this.chartReRender()}
                resetData={() => this.setParticipantResponse()}
                {...this.props}
              />

              <Segment>
                <div className="url-card">
                  <div className="obj-div inside-question">
                    <div className="eval-participants-div">
                      <CustomTooltip
                        state={get(user, 'options.tips')}
                        contentObject={{
                          data: helpTips,
                          key: '59',
                        }}
                        position="bottom left"
                      >
                        <label className="participants-top">
                          Number Of Participants Who Completed Evaluation :{' '}
                          {evaluation.actualParticipantsEvaluated}
                        </label>
                      </CustomTooltip>
                      {/* <CustomTooltip
                      state={get(user, 'options.tips')}
                      contentObject={{ data: helpTips, key: '59' }}
                      position="bottom left"
                    >
                      <Input
                        className="participants-input participants-disabled"
                        name="actualParticipantsEvaluated"
                        value={evaluation.actualParticipantsEvaluated}
                        disabled={true}
                        type="number"
                      />
                    </CustomTooltip> */}
                      <label className="participants-bottom-custom">
                        Recommended sample size is{' '}
                        {get(evalPlan, 'sampleSize', 0)} participants
                      </label>
                    </div>
                  </div>
                </div>
              </Segment>

              {Boolean(evaluation.cumulative) ? (
                <>
                  {one && one.length ? (
                    <Segment>
                      <div className="response-table-div">
                        <h4 className="afterBerforeHeading">After</h4>
                        <Table
                          className="response-table"
                          celled
                          padded
                          unstackable
                        >
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>NOT AT ALL</Table.HeaderCell>
                              <Table.HeaderCell>2</Table.HeaderCell>
                              <Table.HeaderCell>3</Table.HeaderCell>
                              <Table.HeaderCell>4</Table.HeaderCell>
                              <Table.HeaderCell>5</Table.HeaderCell>
                              <Table.HeaderCell>6</Table.HeaderCell>
                              <Table.HeaderCell>7</Table.HeaderCell>
                              <Table.HeaderCell>8</Table.HeaderCell>
                              <Table.HeaderCell>9</Table.HeaderCell>
                              <Table.HeaderCell>
                                MOST IMAGINABLE
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row>
                              {map(one, (data, i) => (
                                <Table.Cell key={i}>
                                  <AvForm ref={(c) => (this.data = c)}>
                                    <AvField
                                      type="number"
                                      name={`before-prepost-${i}`}
                                      defaultValue="0"
                                      min={0}
                                      value={data ? data : 0}
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage:
                                            'Number of new workers created is required',
                                        },
                                        pattern: {
                                          value: '^[0-9]+$',
                                          errorMessage:
                                            'Only numbers are accepted',
                                        },
                                      }}
                                      onBlur={(e) =>
                                        this.onChange(e.target.value, 0, i)
                                      }
                                      disabled={
                                        evalPlan.collectionMethod !== 'MANUAL'
                                      }
                                      style={{
                                        textAlign: 'center',
                                        paddingLeft: '1.5rem',
                                      }}
                                    />
                                  </AvForm>
                                </Table.Cell>
                              ))}
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </div>
                    </Segment>
                  ) : null}
                  {two && two.length ? (
                    <Segment>
                      <div className="response-table-div">
                        <h4 className="afterBerforeHeading">Before</h4>
                        <Table
                          className="response-table"
                          celled
                          padded
                          unstackable
                        >
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>NOT AT ALL</Table.HeaderCell>
                              <Table.HeaderCell>2</Table.HeaderCell>
                              <Table.HeaderCell>3</Table.HeaderCell>
                              <Table.HeaderCell>4</Table.HeaderCell>
                              <Table.HeaderCell>5</Table.HeaderCell>
                              <Table.HeaderCell>6</Table.HeaderCell>
                              <Table.HeaderCell>7</Table.HeaderCell>
                              <Table.HeaderCell>8</Table.HeaderCell>
                              <Table.HeaderCell>9</Table.HeaderCell>
                              <Table.HeaderCell>
                                MOST IMAGINABLE
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row>
                              {map(two, (data, i) => (
                                <Table.Cell key={i}>
                                  <AvForm ref={(c) => (this.data = c)}>
                                    <AvField
                                      type="number"
                                      name={`after-prepost-${i}`}
                                      defaultValue="0"
                                      min={0}
                                      value={data ? data : 0}
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage:
                                            'Number of new workers created is required',
                                        },
                                        pattern: {
                                          value: '^[0-9]+$',
                                          errorMessage:
                                            'Only numbers are accepted',
                                        },
                                      }}
                                      onBlur={(e) =>
                                        this.onChange(e.target.value, 1, i)
                                      }
                                      disabled={
                                        evalPlan.collectionMethod !== 'MANUAL'
                                      }
                                      style={{
                                        textAlign: 'center',
                                        paddingLeft: '1.5rem',
                                      }}
                                    />
                                  </AvForm>
                                </Table.Cell>
                              ))}
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </div>
                    </Segment>
                  ) : null}
                </>
              ) : one && one.length ? (
                <Segment>
                  <div className="response-table-div">
                    <Table className="response-table" celled padded unstackable>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>NOT AT ALL</Table.HeaderCell>
                          <Table.HeaderCell>2</Table.HeaderCell>
                          <Table.HeaderCell>3</Table.HeaderCell>
                          <Table.HeaderCell>4</Table.HeaderCell>
                          <Table.HeaderCell>5</Table.HeaderCell>
                          <Table.HeaderCell>6</Table.HeaderCell>
                          <Table.HeaderCell>7</Table.HeaderCell>
                          <Table.HeaderCell>8</Table.HeaderCell>
                          <Table.HeaderCell>9</Table.HeaderCell>
                          <Table.HeaderCell>MOST IMAGINABLE</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          {map(one, (data, i) => (
                            <Table.Cell key={i}>
                              <AvForm ref={(c) => (this.data = c)}>
                                <AvField
                                  type="number"
                                  name={`prepost-${i}`}
                                  defaultValue="0"
                                  value={data ? data : 0}
                                  min={0}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage:
                                        'Number of new workers created is required',
                                    },
                                    pattern: {
                                      value: '^[0-9]+$',
                                      errorMessage: 'Only numbers are accepted',
                                    },
                                  }}
                                  onBlur={(e) =>
                                    this.onChange(e.target.value, 0, i)
                                  }
                                  disabled={
                                    evalPlan.collectionMethod !== 'MANUAL'
                                  }
                                  style={{
                                    textAlign: 'center',
                                    paddingLeft: '1.5rem',
                                  }}
                                />
                              </AvForm>
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </Segment>
              ) : null}

              <div className="graph-div-evaluation">
                <Segment
                  disabled={
                    this.props.currentActivity.statusId == 3 ? true : false
                  }
                >
                  <Grid>
                    <GridColumn
                      className="avg-eval-plan"
                      mobile={16}
                      tablet={8}
                      computer={8}
                    >
                      <div>
                        <label className="title-lbl">
                          Results:{' '}
                          {evaluation && evaluation.outcome
                            ? evaluation.outcome.title
                            : '-'}
                        </label>
                        {evaluation.cumulative === 1 ? (
                          <ul className="align-left-avg-resp">
                            <li>
                              {`The evaluated participants had a pre average response of ${get(
                                averageValue(evaluation.participantResponse),
                                'preAverage',
                                '0.00'
                              )}`}
                            </li>
                            <li>
                              {`The evaluated participants had a post average response of ${get(
                                averageValue(evaluation.participantResponse),
                                'postAverage',
                                '0.00'
                              )}`}
                            </li>
                          </ul>
                        ) : (
                          <ul className="align-left-avg-resp">
                            <li>
                              {`The evaluated participants had an average response of ${get(
                                averageValue(evaluation.participantResponse),
                                'postAverage',
                                '0.00'
                              )}
                          `}
                            </li>
                          </ul>
                        )}
                      </div>
                    </GridColumn>
                    <GridColumn
                      className="include-in-report-eval-plan"
                      mobile={16}
                      tablet={8}
                      computer={8}
                    >
                      <div className="eval-plan-objectiveChartToggle">
                        <div className="checkbox-container-include-in-report">
                          <div className="include-in-report-label">
                            Include in report
                          </div>
                          <div className="button-include">
                            <Button.Group
                              className="btn-include-in-report"
                              onClick={() => {
                                !isDisabled && setChartToReport(!checked);
                              }}
                            >
                              <Button
                                active={checked && true}
                                disabled={isDisabled}
                              >
                                Yes
                              </Button>
                              <Button
                                active={!checked && true}
                                disabled={isDisabled}
                              >
                                No
                              </Button>
                            </Button.Group>
                          </div>
                        </div>
                      </div>
                    </GridColumn>
                  </Grid>
                </Segment>
                {participantResponseCount <= 0 ? null : Boolean(
                    evaluation.cumulative
                  ) ? (
                  <div className="graph-tabs-documentation">
                    <br />
                    <Tab
                      className="tabs-graph"
                      menu={{
                        secondary: true,
                        pointing: true,
                      }}
                      panes={[
                        {
                          menuItem: 'Trend - Rate of change',
                          render: () => (
                            <Tab.Pane>
                              <TrendRateOfChangeChart
                                isDocumentationLegends
                                method={methodId}
                                participantsChangePrePost={chartData}
                                avoidLabel={true}
                              />
                            </Tab.Pane>
                          ),
                        },
                        isEmpty(evaluation.prePostSurveyResponse)
                          ? null
                          : {
                              menuItem: 'Individual - Rate of change',
                              render: () => (
                                <Tab.Pane className="individual-rate-heatmap">
                                  <IndividualRateOfChangeChart
                                    fromDocumentation
                                    evaluationObject={evaluation}
                                    participantsChange={chartData}
                                    avoidLabel={true}
                                  />
                                </Tab.Pane>
                              ),
                            },
                      ]}
                    />
                  </div>
                ) : (
                  <AggregateChart
                    method={methodId}
                    isDocumentationLegends
                    participants={chartData}
                    avoidLabel={true}
                  />
                )}
              </div>
              <div className="question-div">
                <label
                  onClick={() => this.controlQuestion()}
                  className="question-lbl"
                >
                  {showQ
                    ? `Hide Evaluation Question`
                    : `Show Evaluation Question`}
                  {showQ ? (
                    <Icon name="angle up" />
                  ) : (
                    <Icon name="angle down" />
                  )}
                </label>
              </div>
              <div className={showQ ? 'new-show-q' : 'hide-q'}>
                <div dangerouslySetInnerHTML={questioner} />
              </div>

              {/* Only show "Download Response CSV"  button when the responses collected by file (CSV)*/}
              {(evalPlan.collectionMethod === 'CSV' ||
                evalPlan.collectionMethod === 'CULTURECOUNTS') &&
              !evalPlan.isNewCsvFeature &&
              participantResponseCount > 0 ? (
                <div className="individual-r mb-2">
                  <div className="participant-download">
                    <div>
                      <Button
                        icon
                        labelPosition="left"
                        className="Secondary-Button"
                        onClick={() =>
                          this.participantResponseExportByFile(evaluation)
                        }
                        loading={this.props.actPut[`evaluationQuestion`]}
                      >
                        <Icon name="download" />
                        <div className="dwn-csv">Download Response CSV</div>
                      </Button>
                    </div>
                    <div className="download-label">
                      Download to view individual evaluation responses and
                      comments
                    </div>
                  </div>
                </div>
              ) : (
                <EvaluationTableComments {...this.props} />
              )}
            </>
          </div>
        </div>
      </Segment>
    );
  }
}

class EvaluationTableComments extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  headerColumns(evalComments) {
    let objectives = filter(evalComments.objectives);
    let additionalQuestions = filter(evalComments.additionalQuestions);

    let [first, second] = objectives;

    let isAdditionalAvailable = isEmpty(additionalQuestions);

    const headers = [
      {
        title: get(first, 'outcomeTitle', null),
        colSpan: get(first, 'cumulative', null) ? '3' : '2',
        textAlign: 'left',
      },
      size(objectives) === 2
        ? {
            title: get(second, 'outcomeTitle', null),
            colSpan: get(second, 'cumulative', null) ? '3' : '2',
            textAlign: 'left',
          }
        : null,
      !isAdditionalAvailable
        ? {
            title: 'Additional Questions',
            colSpan: size(additionalQuestions),
            textAlign: 'left',
          }
        : null,
    ];

    return compact(headers);
  }

  getColumns(evalComments) {
    let objectives = filter(evalComments.objectives);

    let [first, second] = objectives;

    let columns = [
      {
        width: '2',
        title: <div className="preRat-small">Post Rate</div>,
        render: (data) => {
          return map(data.result, (item) =>
            first.objectiveId === item.objectiveId ? item.postResult : null
          );
        },
      },
      get(first, 'cumulative', null)
        ? {
            width: '2',
            title: <div className="preRat-small">Pre Rate</div>,
            render: (data) => {
              return map(data.result, (item) =>
                first.objectiveId === item.objectiveId ? item.preResult : null
              );
            },
          }
        : null,
      {
        width: '4',
        title: <div className="comment-section">Comments</div>,
        render: (data) => {
          return map(data.result, (item) => {
            return (
              <CopyToClipboard
                text={
                  first.objectiveId === item.objectiveId ? item.reason : null
                }
                onCopy={() =>
                  NotificationManager.info('Copied to clipboard', 'Comment')
                }
              >
                <div className="copy-comment">
                  {first.objectiveId === item.objectiveId ? item.reason : null}
                </div>
              </CopyToClipboard>
            );
          });
        },
      },
    ];

    const secondObjColumns = [
      {
        width: '2',
        title: <div className="preRat-small">Post Rate</div>,
        render: (data) => {
          return map(data.result, (item) =>
            second.objectiveId === item.objectiveId ? item.postResult : null
          );
        },
      },
      get(second, 'cumulative', null)
        ? {
            width: '2',
            title: <div className="preRat-small">Pre Rate</div>,
            render: (data) => {
              return map(data.result, (item) =>
                second.objectiveId === item.objectiveId ? item.preResult : null
              );
            },
          }
        : null,
      {
        width: '4',
        title: <div className="comment-section">Comments</div>,
        render: (data) => {
          return map(data.result, (item) => {
            return (
              <CopyToClipboard
                text={
                  second.objectiveId === item.objectiveId ? item.reason : null
                }
                onCopy={() =>
                  NotificationManager.info('Copied to clipboard', 'Comment')
                }
              >
                <div className="copy-comment">
                  {second.objectiveId === item.objectiveId ? item.reason : null}
                </div>
              </CopyToClipboard>
            );
          });
        },
      },
    ];

    if (size(objectives) === 2) {
      columns = columns.concat(...secondObjColumns);
    }

    let extraColumns = [];

    if (!isEmpty(evalComments.additionalQuestions)) {
      extraColumns.push(
        map(evalComments.additionalQuestions, (ques) => {
          let questionLength = ques.type.length;
          let question = ques.type;
          let lengthyQuestion = '';
          if (questionLength > 20) {
            lengthyQuestion = question.substring(0, 20) + '...';
          }
          let agePostcode = false;

          if (
            question === 'Age' ||
            question === 'Postcode' ||
            question === 'Email'
          ) {
            agePostcode = true;
          }
          return {
            width: agePostcode ? '2' : '4',
            title:
              questionLength > 20 ? (
                <Popup
                  content={question}
                  trigger={
                    <div
                      className={
                        agePostcode ? 'preRat-small' : 'comment-section'
                      }
                    >
                      {lengthyQuestion}
                    </div>
                  }
                />
              ) : (
                <div
                  className={agePostcode ? 'preRat-small' : 'comment-section'}
                >
                  {question}
                </div>
              ),
            render: (data) => {
              return map(data.additionalQuestionAnswer, (item) => {
                if (ques.id !== item.id) return null;
                return <div className="pre-line">{item.answer}</div>;
              });
            },
          };
        })
      );
    }

    let allColumns = columns.concat(...extraColumns);

    return compact(allColumns);
  }

  async participantResponseExport(evaluation) {
    const { exportEvaluationResponses } = this.props;
    await exportEvaluationResponses(`evaluationQuestion`, evaluation);
    const { evaluationResponsesCsvLink } = this.props;
    if (!isEmpty(evaluationResponsesCsvLink)) {
      window.open(evaluationResponsesCsvLink.download_link, '_blank');
    }
  }

  render() {
    const { evalComments } = this.props.activity;
    const { evalPlan, evaluation } = this.props;

    let evaluationComments = {};
    for (const [key, value] of Object.entries(evalComments)) {
      if (evalPlan.id === parseInt(key)) {
        evaluationComments = value;
      }
    }

    return (
      <div className="individual-r">
        {isEmpty(evaluationComments.form) ? null : (
          <>
            <div className="border-sep"></div>
            <div className="survey-results">Individual Responses</div>

            <div className="evaluation-comments-margin">
              {evalPlan.collectionMethod !== 'CULTURECOUNTS' && (
                <div className="comments-table">
                  <CustomTable
                    // structured={true}
                    parentHeaders={this.headerColumns(evaluationComments)}
                    header
                    columns={this.getColumns(evaluationComments)}
                    data={evaluationComments.form}
                    customClass="doc-evaluation-comments"
                    keyExtractor={(item, index) => item.id}
                  />
                </div>
              )}

              {evalPlan.collectionMethod !== 'MANUAL' ? (
                <div className="participant-download">
                  <div>
                    <Button
                      icon
                      labelPosition="left"
                      className="Secondary-Button"
                      onClick={() => this.participantResponseExport(evaluation)}
                      loading={this.props.actPut[`evaluationQuestion`]}
                    >
                      <Icon name="download" />
                      <div className="dwn-csv">Download Response CSV</div>
                    </Button>
                  </div>
                  <div className="download-label">
                    Download to view individual evaluation responses and
                    comments
                  </div>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activity: state.activity,
    extra: state.extra,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postProjectEvaluations,
      uploadActivityCsv,
      getEvalReasons,
      getEvalAssesmentTypes,
      updateSelfAssessed,
      evalPlanManage,
      exportEvaluationResponses,
      exportEvaluationResponsesByFile,
      getEvaluationComment,
      getEvaluationPlans,
    },
    dispatch
  );
};

EvaluationQuestion = connect(
  mapStateToProps,
  mapDispatchToProps
)(EvaluationQuestion);

export { EvaluationQuestion };
