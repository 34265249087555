import AvField from 'availity-reactstrap-validation/lib/AvField';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import React, { Component } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import '../../admin.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getDomains,
  postOutcomes,
  getOutcomes,
} from '../../../../store/actions';
import moment from 'moment';
import { map } from '../../../../utils/lodash';
import { CustomAccordion, CustomTable } from '../../../../components';
import EditModalOutcome from './editModalOutcome';
import { Label } from 'semantic-ui-react';

class ParentOutcomes extends Component {
  componentDidMount() {
    const { getOutcomes } = this.props;

    getOutcomes();
  }

  render() {
    return (
      <div className="no-color">
        <Outcomes {...this.props} />
        <OutcomePolicy {...this.props} />
      </div>
    );
  }
}

export class OutcomePolicy extends Component {
  render() {
    const { policies } = this.props;

    return (
      <div className="gap-between-windows">
        {map(policies, (policy, i) => {
          return (
            <div key={i} className="gap-between-windows">
              <CustomAccordion
                className="accordion-ev"
                title={policy.policyName}
                children={<OutcomeTypes policy={policy} {...this.props} />}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

export class OutcomeTypes extends Component {
  renderColumns() {
    const columns = [
      {
        title: (
          <div className="user-act-heading-container-custom-feature">
            <strong>Name</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className="blocked-badge-container">
              <div className={!data.isEnable && 'disabled'}>{data.title}</div>
              {!data.isEnable && (
                <Label className="blocked-badge" color="red" size="tiny">
                  Disabled
                </Label>
              )}
            </div>
          );
        },
        width: 9,
      },
      {
        title: (
          <div className="user-act-heading-container-custom-feature">
            <strong>Created Date</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className={!data.isEnable && 'disabled'}>
              {moment(data.createdAt).format('ll hh:mm A')}
            </div>
          );
        },
        width: 3,
      },
      {
        title: (
          <div className="user-act-heading-container-custom-feature">
            <strong>Updated Date</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className={!data.isEnable && 'disabled'}>
              {moment(data.updatedAt).format('ll hh:mm A')}
            </div>
          );
        },
        width: 3,
      },
      {
        title: '',
        render: (data) => {
          return <EditModalOutcome {...this.props} item={data} />;
        },
        width: 1,
      },
    ];

    return columns;
  }
  render() {
    const { outcomes, policy } = this.props;

    let selectedOutcomes = [];
    return (
      <div className="view-accordion-com">
        <div className="scroll-accordion">
          {map(outcomes, (item, i) => {
            if (item.policyId !== policy.id) return null;
            selectedOutcomes.push(item);
          })}
          <CustomTable
            header
            customClass="super-admin-table tbody-hover"
            columns={this.renderColumns()}
            data={selectedOutcomes}
            emptyMessage="No tooltips"
            keyExtractor={(item, index) => item.id}
          />
        </div>
      </div>
    );
  }
}
export class Outcomes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  componentDidMount() {
    const { getDomains } = this.props;
    getDomains();
  }
  render() {
    const { modal } = this.state;
    const { domains } = this.props;

    return (
      <div className="outcome-admin-custom">
        <div>
          <Modal
            open={modal}
            onOpen={() => this.toggle()}
            closeIcon={
              <Icon
                onClick={() => this.toggle()}
                name="close"
                className="closeicon"
              />
            }
            size="small"
            trigger={
              <Button className="Primary-Button">
                <div className="add-icon-activity-add">
                  <Icon name="plus circle" />
                  <div className="add-text">Add Outcome</div>
                </div>
              </Button>
            }
            className="create-outcome"
          >
            <Modal.Header>Create Outcome</Modal.Header>
            <Modal.Content>
              <CreateOutcome
                domains={domains}
                toggle={() => this.toggle()}
                {...this.props}
              />
            </Modal.Content>
          </Modal>
        </div>
      </div>
    );
  }
}

class CreateOutcome extends React.PureComponent {
  async handleValidSubmit(event, values) {
    try {
      const { postOutcomes, toggle } = this.props;

      await postOutcomes(values);
      toggle();
    } catch (error) {
      error.log('Error: CreateOutcome ~ handleValidSubmit', error);
    }
  }

  selectDomain() {
    const { domains } = this.props;
    return map(domains, (item, i) => {
      return (
        <option key={i} value={item.id}>
          {item.policyName}
        </option>
      );
    });
  }

  render() {
    const { toggle, outcomeLoading } = this.props;

    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="venue-form"
      >
        <AvField
          label="Title"
          className="org-inputs"
          name="title"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: 'Title is required',
            },
          }}
        />

        <AvField
          label="Description"
          className="org-inputs-textarea"
          name="description"
          type="textarea"
          validate={{
            required: {
              value: true,
              errorMessage: 'Description is required',
            },
          }}
        />

        <AvField
          label="Domain"
          className="org-inputs"
          name="policyId"
          type="select"
          validate={{
            required: {
              value: true,
              errorMessage: 'Domain type is required',
            },
          }}
        >
          <option value="" disabled defaultValue>
            Select a Domain
          </option>
          {this.selectDomain()}
        </AvField>

        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            content="Create"
            type="submit"
            className="Primary-Button"
            loading={outcomeLoading}
            disabled={outcomeLoading}
          />
        </div>
      </AvForm>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    domains: state.admin.domains,
    outcomeLoading: state.admin.outcomeLoading,
    outcomes: state.extra.outcomes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDomains,
      postOutcomes,
      getOutcomes,
    },
    dispatch
  );
};

ParentOutcomes = connect(mapStateToProps, mapDispatchToProps)(ParentOutcomes);

export { ParentOutcomes };
