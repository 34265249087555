import React from 'react';
import { Grid } from 'semantic-ui-react';
import { APP_NAME } from '../../utils/constant';
import { CustomSegment } from '../../components';
import ProfileDetails from './profileDetails';
import ChangePassword from './changePassword';
import './auth.scss';
import Settings from './settings';
class Profile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = `User Profile | ${APP_NAME}`;
  }

  render() {
    return (
      <Grid.Row>
        <CustomSegment title="PROFILE DETAILS" className="profile-details">
          <ProfileDetails />
        </CustomSegment>
        <CustomSegment
          title="CHANGE PASSWORD"
          extraMargin
          className="profile-details"
        >
          <ChangePassword />
        </CustomSegment>

        <Settings />
      </Grid.Row>
    );
  }
}

export default Profile;
