/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import '../../../activity.scss';
import React from 'react';
import { Button, Dropdown, Modal } from 'semantic-ui-react';

export class DeleteLinkedActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      deleteLinkedActivitiesLoading: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
    }));
  }

  async onDeleteLinkedActivity() {
    const { activity, deleteLinkedActivity } = this.props;
    await this.setState({ deleteLinkedActivitiesLoading: true });

    deleteLinkedActivity(activity.id);

    this.setState({ deleteLinkedActivitiesLoading: false, modalOpen: false });
  }

  render() {
    const { modalOpen, deleteLinkedActivitiesLoading } = this.state;
    return (
      <Modal
        open={modalOpen}
        onOpen={() => this.toggle()}
        closeIcon={
          <div
            className="new-activity-modal-close-icon"
            onClick={() => this.toggle()}
          >
            &times;
          </div>
        }
        size="tiny"
        trigger={
          <Dropdown.Item className="activity-actions-dropdown-delete">
            <label className="activity-actions-text-warning">
              Delete Activity
            </label>
          </Dropdown.Item>
        }
      >
        <Modal.Header>
          <div className="small-modal-header">Delete Linked Activity</div>
        </Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this linked activity?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => this.toggle()}
            className="activity-activation-cancel-btn"
          >
            No
          </Button>
          <Button
            loading={deleteLinkedActivitiesLoading}
            disabled={deleteLinkedActivitiesLoading}
            onClick={() => this.onDeleteLinkedActivity()}
            className="new-collective-save-btn"
            content="Yes"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
