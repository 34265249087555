import './styles.scss';

import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';
import { Icon, Radio, Segment } from 'semantic-ui-react';

import { get, isEmpty } from '../utils/lodash';
import { CustomTooltip } from './popup';
import PropTypes from 'prop-types';

class CustomSegment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      title,
      subTitle,
      icon,
      rightHeader,
      extraMargin,
      extraBottomMargin,
      style,
      toggle,
      headerStyle,
      noPrint,
      backButtonRoute,
      tooltip = null,
      objectiveModal = false,
      borderBottomRemoved,
      subChild = {},
      className,
      disabled,
    } = this.props;
    return (
      <Segment.Group
        raised
        style={{
          marginTop: extraMargin ? '1.714rem' : null,
          marginBottom: extraBottomMargin ? '1.714rem' : null,
          ...style,
        }}
        className={
          className
            ? className
            : `${title.replaceAll(' ', '_').replace('-', ' ')} ${
                noPrint ? 'no-print' : ''
              }`
        }
      >
        <Segment
          className={
            borderBottomRemoved
              ? `custom-segment-without-border-bottom${
                  objectiveModal ? ' objective-modal-header' : ''
                }`
              : `custom-segment${
                  objectiveModal ? ' objective-modal-header' : ''
                }`
          }
          disabled={disabled ? disabled : false}
        >
          <Fragment>
            {backButtonRoute ? (
              <Link to={backButtonRoute} className="back-button-segment">
                <Icon name="arrow left" />
              </Link>
            ) : null}
            {tooltip ? (
              <CustomTooltip
                state={get(tooltip, 'user.options.tips')}
                contentObject={{
                  data: tooltip.helpTips,
                  key: tooltip.id,
                }}
                position="top left"
                wide="very"
              >
                <label className="segment-heading" style={headerStyle}>
                  {title}
                </label>
              </CustomTooltip>
            ) : (
              <div className={subTitle ? '' : 'segment-title'}>
                {icon ? <Icon name={icon} className="segment-icon" /> : null}
                {title}
                <br></br>

                {subTitle ? (
                  <label className="segment-sub-heading" style={headerStyle}>
                    {subTitle}
                  </label>
                ) : null}
              </div>
            )}
          </Fragment>
          {rightHeader ? rightHeader : null}
          {toggle ? <Radio toggle /> : null}
        </Segment>
        {!isEmpty(subChild) && <Segment>{subChild}</Segment>}
        {this.props.children}
      </Segment.Group>
    );
  }
}

CustomSegment.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export { CustomSegment };
