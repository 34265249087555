import { AvForm, AvRadio, AvRadioGroup } from 'availity-reactstrap-validation';
import moment from 'moment';
import React, { Fragment } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Message, Modal } from 'semantic-ui-react';
import QRCode from 'qrcode.react';
import {
  EvalPlanDisable,
  ShowEvaluationQuestion,
} from '../../../../components';
import {
  evalPlanCollectionMethodManage,
  printableSurvey,
} from '../../../../store/actions';
import {
  ContentAndHeaderEvaluation,
  getSurveyLink,
  getPrintableSurveyToken,
  noteReplace,
} from '../../../../utils';
import { map, get } from '../../../../utils/lodash';
import '../activity.scss';
import '../../activities.scss';
import './surveyContainer.scss';
import html2canvas from 'html2canvas';

const SurveyContainer = ({
  evalPlan,
  currentActivity,
  pageDisabled,
  ...props
}) => {
  const dispatch = useDispatch();
  // const [evalVisibility, setEvalVisibility] = React.useState(false);
  const surveyValidation = moment(currentActivity.eventStartDate) > moment();

  const [facilityVisibility, setFacilityVisibility] = React.useState(false);
  const [questionVisibility, setQuestionVisibility] = React.useState(false);
  const [methodType, setMethodType] = React.useState(evalPlan.collectionMethod);
  const [qrCodeModal, setQrCodeModal] = React.useState(false);
  const [isSingleLink, setIsSingleLink] = React.useState(true);
  const downloadSurveyLoading = useSelector(
    (state) => state.survey.printableSurveyLoading
  );

  const surveyLink = getSurveyLink(
    currentActivity.id,
    evalPlan.id,
    map(
      get(evalPlan, 'evaluationQuestions', []),
      (evaluation) => evaluation.objectiveId
    ),
    true
  );
  const kioskLink = getSurveyLink(
    currentActivity.id,
    evalPlan.id,
    map(
      get(evalPlan, 'evaluationQuestions', []),
      (evaluation) => evaluation.objectiveId
    )
  );

  const isSelfAssesed =
    get(evalPlan, 'method.response', '') === 'Self-assessed';
  const isCultureCount =
    get(evalPlan, 'collectionMethod', '') === 'CULTURECOUNTS';
  const facilitatorNote = get(evalPlan, 'method.facilitatorNote', null);

  const evaluationQs = get(evalPlan, 'evaluationQuestions', null);
  const seriesParentId = get(evalPlan, 'seriesParentId', null);
  const isSeriesActive = get(evalPlan, 'isSeriesActive', null);
  const dateMeasured = get(evalPlan, 'dateMeasured', null);

  const RenderCollectionMethod = () => {
    const selfAssessed = evalPlan.methodId === 9;
    const isCultureCount = currentActivity.isCultureCount;
    // const seriesParentId = get(evalPlan, 'seriesParentId', null);
    const onlyCultureCount =
      get(evalPlan, 'method.response', '') === 'Participant' &&
      get(evalPlan, 'method.method', '') === 'Intercept';
    const dateMeasured = get(evalPlan, 'dateMeasured', null);
    if (!evalPlan.collectionMethod) return null;

    const handleCollectionMethod = (type) => {
      const form = {
        collectionMethod: type,
      };
      const isMethodChanged = dispatch(
        evalPlanCollectionMethodManage(currentActivity.id, evalPlan.id, form)
      );

      if (isMethodChanged) {
        setMethodType(type);
      }
    };

    return (
      <div className="evaluation-seg collection-seg">
        <div>
          <div>
            {dateMeasured || selfAssessed || evalPlan.haveResponses ? (
              <>
                <div className="row greenlight-evaluation-plan-extra-margin">
                  <div className="radio-holder col-lg-12 ">
                    <label className="eval-plan-label">
                      Data Collection Method
                    </label>
                    <div>
                      {selfAssessed
                        ? 'Self-assessed'
                        : evalPlan.collectionMethod}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <AvForm
                // ref={c => (this.form = c)}
                model={{
                  [evalPlan.id + '_dataCollectionMethod']: get(
                    evalPlan,
                    'collectionMethod',
                    'LINK'
                  ),
                }}
              >
                <div className="row greenlight-evaluation-plan-extra-margin">
                  <div className="radio-holder col-lg-12 ">
                    <label className="eval-plan-label">
                      Select Data Collection Method
                    </label>
                    <div className="eval-plan-radio-group-row">
                      <AvRadioGroup
                        inline
                        name={`${evalPlan.id}_dataCollectionMethod`}
                        required
                        errorMessage="Data collection method is required"
                      >
                        {selfAssessed ? (
                          <AvRadio
                            label="Self-assessed"
                            value="LINK"
                            disabled={pageDisabled}
                          />
                        ) : (
                          <Fragment>
                            {!onlyCultureCount
                              ? [
                                  <AvRadio
                                    label="Takso Link"
                                    value="LINK"
                                    onChange={(e) => {
                                      handleCollectionMethod(
                                        'LINK',
                                        evalPlan,
                                        currentActivity
                                      );
                                      setMethodType('LINK');
                                    }}
                                    disabled={pageDisabled}
                                  />,
                                  <AvRadio
                                    label="CSV Upload"
                                    value="CSV"
                                    onChange={(e) => {
                                      handleCollectionMethod(
                                        'CSV',
                                        evalPlan,
                                        currentActivity
                                      );
                                      setMethodType('CSV');
                                    }}
                                    disabled={pageDisabled}
                                  />,
                                ]
                              : null}

                            {isCultureCount ? (
                              <AvRadio
                                label="Culture Counts"
                                value="CULTURECOUNTS"
                                onChange={(e) => {
                                  handleCollectionMethod(
                                    'CULTURECOUNTS',
                                    evalPlan,
                                    currentActivity
                                  );
                                  setMethodType('CULTURECOUNTS');
                                }}
                                disabled={pageDisabled}
                              />
                            ) : null}
                          </Fragment>
                        )}
                      </AvRadioGroup>
                    </div>

                    {evalPlan.collectionMethod === 'CULTURECOUNTS' ? (
                      <div className="culture-count-container">
                        <a
                          href={'https://culturecounts.cc/sign-in'}
                          rel="noopener noreferrer"
                          target="_blank"
                          className="culture-count-a"
                        >
                          <div className="culture-count">
                            <span>
                              Go to Culture Counts
                              <Icon
                                name="external alternate"
                                className="ml-3 mb-1"
                              />
                            </span>
                          </div>
                        </a>
                      </div>
                    ) : null}
                  </div>
                </div>
              </AvForm>
            )}
          </div>
        </div>
      </div>
    );
  };

  const downloadQRCode = async () => {
    html2canvas(document.getElementById('qrCodeContainer'), { scale: 2 }).then(
      (canvas) => {
        const qrCodeURL = canvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream');
        let aEl = document.createElement('a');
        aEl.href = qrCodeURL;
        aEl.download = 'QR_Code.png';
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);
      }
    );
  };

  const downloadSurvey = async () => {
    const token = await getPrintableSurveyToken(
      currentActivity.id,
      evalPlan.id,
      map(
        get(evalPlan, 'evaluationQuestions', []),
        (evaluation) => evaluation.objectiveId
      ),
      true
    );

    return new Promise((resolve, reject) => {
      dispatch(printableSurvey(token))
        .then((result) => {
          var link = document.createElement('a');
          link.href = result.s3Url;
          link.download = result.fileName;
          link.click();
          resolve(result);
        })
        .catch((error) => {
          console.error('error: ', error);
          reject(error);
        });
    });
  };

  const GenerateQrModal = (props) => {
    const name = get(props.evalPlan, 'name', 'Evaluation plan');
    return (
      <div className="survey-modal">
        <Modal
          size="mini"
          open={qrCodeModal}
          closeIcon={
            <Icon
              onClick={() => setQrCodeModal(false)}
              name="close"
              className="closeicon"
            />
          }
        >
          <Modal.Header>Survey QR Code</Modal.Header>
          <Modal.Content id="qrCodeContainer">
            <div className="w-100 text-center justify-content-center py-1">
              <div>
                <p className="qr-planName">{name}</p>
                <QRCode
                  id="qrCode"
                  size={270}
                  value={isSingleLink ? surveyLink : kioskLink}
                />
                <span className="qr-activityId">
                  <p>#{props.id}</p>
                </span>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <div className="model-buttons">
              <Button
                className="modal-cancel"
                type="button"
                onClick={() => setQrCodeModal(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={() => downloadQRCode()}
                content="Download"
                className="modal-proceed"
              />
            </div>
          </Modal.Actions>
        </Modal>
      </div>
    );
  };

  return (
    <>
      <div className="link-row">
        {get(evalPlan, 'isDisabled', false) ? (
          <EvalPlanDisable {...evalPlan} />
        ) : (
          <Fragment>
            {dateMeasured ? (
              <></>
            ) : (seriesParentId && isSeriesActive) || seriesParentId == null ? (
              isSelfAssesed ? null : isCultureCount ? null : (
                <Fragment>
                  {methodType === 'LINK' ? (
                    <div className="url-card-links row">
                      <div className="link-div">
                        <div className="url-link-container">
                          <div className="link-title">Single Use</div>
                          <div className="link-button">
                            <CopyToClipboard
                              text={surveyLink}
                              onCopy={() =>
                                NotificationManager.info(
                                  'copied to clipboard',
                                  'Survey Link'
                                )
                              }
                            >
                              <Button className="copy-link-btn" size="small">
                                <Icon size="large" name="clone outline" />
                                Copy Survey Question Link
                              </Button>
                            </CopyToClipboard>
                            <a
                              rel="noopener noreferrer"
                              href={surveyLink}
                              target="_blank"
                            >
                              <Button className="survey-button" size="small">
                                <Icon
                                  size="large"
                                  name="file alternate outline"
                                />
                                Go to Survey
                              </Button>
                            </a>

                            {/* QR code generate */}
                            <Button
                              className="survey-button"
                              size="small"
                              onClick={() => {
                                setIsSingleLink(true);
                                setQrCodeModal(true);
                              }}
                            >
                              <Icon size="large" name="qrcode" />
                              Generate QR Code
                            </Button>
                          </div>
                        </div>
                        <div className="url-link-container">
                          <div className="link-title">Multiple Use</div>
                          <div className="link-button">
                            <CopyToClipboard
                              text={kioskLink}
                              onCopy={() =>
                                NotificationManager.info(
                                  'copied to clipboard',
                                  'Survey Link'
                                )
                              }
                            >
                              <Button className="copy-link-btn" size="small">
                                <Icon
                                  size="large"
                                  name="clone outline"
                                  className="copy-icon"
                                />
                                Copy Survey Question Link
                              </Button>
                            </CopyToClipboard>
                            <a
                              rel="noopener noreferrer"
                              href={kioskLink}
                              target="_blank"
                            >
                              <Button className="survey-button" size="small">
                                <Icon
                                  size="large"
                                  name="file alternate outline"
                                  className="copy-icon"
                                />
                                Go to Survey
                              </Button>
                            </a>

                            {/* QR code generate */}

                            <Button
                              className="survey-button"
                              size="small"
                              onClick={() => {
                                setIsSingleLink(false);
                                setQrCodeModal(true);
                              }}
                            >
                              <Icon size="large" name="qrcode" />
                              Generate QR Code
                            </Button>
                          </div>
                        </div>

                        <div className="url-link-container">
                          <div className="link-title">Printable Survey</div>
                          <div className="link-button">
                            {/* PDF generate Printable Survey for LINK */}

                            <Button
                              className="copy-link-btn"
                              loading={downloadSurveyLoading}
                              size="small"
                              onClick={() => {
                                downloadSurvey();
                              }}
                            >
                              <Icon
                                size="large"
                                name="file alternate outline"
                                className="copy-icon"
                              />
                              Download Survey PDF
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="obj-div col-lg-6"></div>
                    </div>
                  ) : methodType === 'CSV' ? (
                    <div className="url-card-links row">
                      <div className="link-div">
                        <div className="url-link-container">
                          <div className="link-title">Printable Survey</div>
                          <div className="link-button">
                            {/* PDF generate Printable Survey for CSV */}

                            <Button
                              className="copy-link-btn"
                              loading={downloadSurveyLoading}
                              size="small"
                              onClick={() => {
                                downloadSurvey();
                              }}
                            >
                              <Icon
                                size="large"
                                name="file alternate outline"
                                className="copy-icon"
                              />
                              Download Survey PDF
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="obj-div col-lg-6"></div>
                    </div>
                  ) : null}
                </Fragment>
              )
            ) : methodType === 'LINK' && !isSelfAssesed ? (
              <div className="m-4">
                <div className="msg-series-evaluation">
                  <Message
                    color="yellow"
                    icon="exclamation circle"
                    content={
                      'Links will be active once the previous plan has been closed'
                    }
                  />
                </div>
              </div>
            ) : (
              <div className="m-4">
                <div className="msg-series-evaluation">
                  <Message
                    color="yellow"
                    icon="exclamation circle"
                    content={
                      'Please ensure that the results from the previous evaluation in this series has been closed'
                      // have been uploaded on the documentation page'
                    } // TODO: Need to check ?? previous evaluation one and show the message according to previous evaluation collection method
                  />
                </div>
              </div>
            )}
            <div className="normal-card">
              <div className="obj-div">
                <RenderCollectionMethod
                  evalPlan={evalPlan}
                  setMethodType={setMethodType}
                  currentActivity={currentActivity}
                />
              </div>
            </div>
            {isSelfAssesed ? null : isCultureCount ? null : methodType ===
              'LINK' ? (
              <div className="refer-target-guideline-row row">
                <ContentAndHeaderEvaluation>
                  You can use the links above to refer your target groups to
                  your survey question(s). The link can also be inserted into an
                  existing Survey Monkey, or used on its own on a tablet or
                  mobile devise for interviews or focus groups.
                </ContentAndHeaderEvaluation>
                <br />
                <p
                  className={
                    surveyValidation ? 'c-content-eval-bold' : 'c-content-eval'
                  }
                >
                  Survey links will be operational from the first day of your
                  activity. Results will be automatically compiled in Takso and
                  visible on the documentation page.
                </p>
              </div>
            ) : (
              <div className="refer-target-guideline-row row">
                <ContentAndHeaderEvaluation>
                  You will find survey questions below. When adding questions to
                  your survey, ensure to add a 1-10 scale at the appropriate
                  stage.
                </ContentAndHeaderEvaluation>
                <br />
                <p
                  className={
                    surveyValidation ? 'c-content-eval-bold' : 'c-content-eval'
                  }
                >
                  When you are ready to enter survey data into Takso, select
                  “Show Evaluation Details,” where you can download a CSV
                  template to enter your results and upload to Takso.
                </p>
              </div>
            )}
          </Fragment>
        )}
        <div className="greenlight-evaluation-plan-objective row">
          <div className="col-lg-6 greenlight-evaluation-plan-objective-extra-left-padding">
            <ContentAndHeaderEvaluation title="Objective">
              {map(
                get(evalPlan, 'evaluationQuestions', []),
                (evaluation, i) => (
                  <span
                    className="greenlight-objective-eval-group-list"
                    key={i}
                  >
                    {get(evaluation, 'outcome.title', '-')}
                  </span>
                )
              )}
            </ContentAndHeaderEvaluation>
          </div>
          <div className="col-lg-6 greenlight-evaluation-plan-objective-extra-left-padding">
            <ContentAndHeaderEvaluation title="Evaluation Groups">
              {map(get(evalPlan, 'participantGroups', []), (evaluation, i) => (
                <span className="greenlight-objective-eval-group-list" key={i}>
                  {get(evaluation, 'targetGroup.name', '-')}
                </span>
              ))}
            </ContentAndHeaderEvaluation>
          </div>
        </div>
        <div className="evaluation-respondent-type-row row">
          <div className="obj-div col-lg-6">
            <ContentAndHeaderEvaluation title="Evaluation Respondent Type">
              {get(evalPlan, 'method.response', '-')}
            </ContentAndHeaderEvaluation>
          </div>
          {isSelfAssesed ? null : (
            <div className="goal-div col-lg-6">
              <ContentAndHeaderEvaluation title="Evaluation Method">
                {get(evalPlan, 'method.method', '-')}
              </ContentAndHeaderEvaluation>
            </div>
          )}
        </div>
        {isSelfAssesed ? null : facilitatorNote ? (
          <Fragment>
            <div className="green-qs">
              <div
                onClick={() => setFacilityVisibility(!facilityVisibility)}
                className="show-color"
              >
                {facilityVisibility ? (
                  <Icon name="angle up" />
                ) : (
                  <Icon name="angle down" />
                )}
                Facilitator Notes
              </div>
            </div>
            {facilityVisibility ? (
              <div className="question-and-objective-card row">
                <div
                  className="show-facilitiator-notes"
                  dangerouslySetInnerHTML={{
                    __html: noteReplace(
                      currentActivity,
                      get(evalPlan, 'method.facilitatorNote', '')
                    ),
                  }}
                />
              </div>
            ) : null}
          </Fragment>
        ) : null}
        {methodType === 'CSV' || methodType === 'CULTURECOUNTS' ? (
          <Fragment>
            <div className="green-qs">
              <div
                onClick={() => setQuestionVisibility(!questionVisibility)}
                className="show-color"
              >
                {questionVisibility ? (
                  <Icon name="angle up" />
                ) : (
                  <Icon name="angle down" />
                )}
                Show Evaluation Question
              </div>
            </div>
            {questionVisibility ? (
              <div className="question-and-objective-card row">
                {map(evaluationQs, (question, i) => {
                  return (
                    <ShowEvaluationQuestion question={question} index={i} />
                  );
                })}
              </div>
            ) : null}
          </Fragment>
        ) : null}
      </div>
      <br />

      <GenerateQrModal {...currentActivity} evalPlan={evalPlan} />
    </>
  );
};

export default SurveyContainer;
