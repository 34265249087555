/*
 * This file contains valid file types which is used for validating formik fields (Yup)
 */

export const SUPPORT_DOCUMENT_TYPES = [
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const SUPPORT_CSV_TYPES = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

export const SUPPORT_IMAGE_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
];
