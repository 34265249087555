import { Button, Dropdown, Grid, Segment } from 'semantic-ui-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DateRangeChart from '../../../components/dateRangeChart';
import { Activity, OutcomesSummary, InputOutputReport } from '../reportHome';
import { OutcomeChart } from '../../../components/chartsWidgets';
import { FeatureWrapper, UnAuthorizedAccess } from '../../../components';
import { isEmpty } from '../../../utils/lodash';
import {
  strategicPlanExport,
  getOutcomeSummaryReferences,
  getOrganizationReportData,
  advancedFilterAllReset,
} from '../../../store/actions';
import './report.scss';
import { cloneDeep, compact, get } from 'lodash';
import { ManagementSummary } from '../../../components/managementSummary';
import { ORGANISATION_REPORT_TYPES } from '../../../utils/constant';
import { OutcomeAddressed } from '../outcomeAddressed';
import { isFeature } from '../../../utils/activity/userPermission';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ReportSelector = ({ match, ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authentication);
  const report = useSelector((state) => state.report);

  const { reportFilterOptions, exportLoading } = report;

  const [state, setState] = useState({
    reportType: 'activities',
    outcomeType: undefined,
    methodType: 'all',
    startDate:
      reportFilterOptions && reportFilterOptions.startDate
        ? moment(reportFilterOptions.startDate)
        : moment().month('January').startOf('month'),
    endDate:
      reportFilterOptions && reportFilterOptions.endDate
        ? moment(reportFilterOptions.endDate)
        : moment(),
    chartType: undefined,
    reportingYearsOptions: [],
    reportingYearTitle: '',
    reportingYearStart: null,
    reportingYearEnd: null,
    currentPage: 1,
    perPage: 10,
    dateRangeChosenLabel: 'Since the Start',
    references: [],
    outcomeError: false,
    reportTypes: ORGANISATION_REPORT_TYPES,
    order: 'asc',
  });

  // const year = new Date().getFullYear();

  useEffect(() => {
    const { renderOutcomeChart } = reportFilterOptions;

    let {
      user: {
        organisation: { createdDateTime: organisationStartDate = '2018-01-01' },
      },
    } = auth;

    organisationStartDate = moment(organisationStartDate);

    setState({
      ...state,
      startDate:
        reportFilterOptions && reportFilterOptions.startDate
          ? moment(reportFilterOptions.startDate)
          : moment().month('January').startOf('month'),
      endDate:
        reportFilterOptions && reportFilterOptions.endDate
          ? moment(reportFilterOptions.endDate)
          : moment(),
    });

    const reportParamType = match.params.type;
    const REPORT_TYPE =
      reportParamType === 'outcomes-addressed' && renderOutcomeChart
        ? 'outcomes-reported'
        : reportParamType;

    const updatedReportTypes = checkFeatureAvailable(auth);
    setState({
      ...state,
      reportTypes: updatedReportTypes,
      reportType: REPORT_TYPE,
    });

    const availableReportType = updatedReportTypes.find(
      (item) => item.value === match.params.type
    );

    if (availableReportType !== undefined) {
      dispatch(
        getOrganizationReportData(REPORT_TYPE, {
          startDate: state.startDate.format('YYYY-M-D'),
          endDate: state.endDate.format('YYYY-M-D'),
        })
      );
    }
  }, []);

  async function datePicker(event, picker) {
    const { renderOutcomeChart } = reportFilterOptions;

    const reportParamType = state.reportType;
    const REPORT_TYPE =
      reportParamType === 'outcomes-addressed' && renderOutcomeChart
        ? 'outcomes-reported'
        : reportParamType;

    setState({
      ...state,
      startDate: picker.startDate,
      endDate: picker.endDate,
      dateRangeChosenLabel: picker.chosenLabel,
      goalType: 'all',
    });

    dispatch(
      getOrganizationReportData(REPORT_TYPE, {
        startDate: picker.startDate.format('YYYY-M-D'),
        endDate: picker.endDate.format('YYYY-M-D'),
      })
    );
  }

  async function onClickExport() {
    try {
      await dispatch(strategicPlanExport()).then((data) => {
        if (data && data.download_link) {
          window.open(data.download_link, '_blank');
        }
      });
    } catch (error) {
      console.error('error :', error);
    }
  }

  const checkFeatureAvailable = (auth) => {
    const featureMapping = {
      activities: 'report_activity_summary',
      'outcomes-addressed': 'report_outcome_summary',
      'outcomes-reported': 'report_outcome_summary',
      'management-summary': 'report_account_summary',
      'input-output-data': 'report_headline_data',
    };

    const updatedFeature = ORGANISATION_REPORT_TYPES.filter((item) => {
      const featureKey = featureMapping[item.value];
      return featureKey && isFeature(auth.user, featureKey);
    });

    return updatedFeature;
  };

  const onTypeChange = async (e, { value }) => {
    const option = cloneDeep(state.reportTypes);

    // const selectedIndex = option.findIndex((option) => option.value === value);

    // if (selectedIndex === -1) return;

    // for (let i = 0; i < option.length; i++) {
    //   if (i === selectedIndex) {
    //     option[i].disabled = true;
    //   } else {
    //     option[i].disabled = false;
    //   }
    // }

    setState({
      ...state,
      reportType: value,
      outcomeType: undefined,
      methodType: 'all',
      chartType: undefined,
      goalType: 'all',
      referenceType: 'all',
      reportTypes: option,
    });

    navigate(`/reports/${value}`);

    dispatch(
      getOutcomeSummaryReferences(
        state.startDate.format('YYYY-M-D'),
        state.endDate.format('YYYY-M-D'),
        'all', // replace with appropriate value for activityType
        'all',
        'all' // goal type
      )
    );

    dispatch(
      getOrganizationReportData(value, {
        startDate: state.startDate.format('YYYY-M-D'),
        endDate: state.endDate.format('YYYY-M-D'),
      })
    );
  };

  function renderReportType(reportType) {
    const {
      outcomeType,
      methodType,
      chartType,
      startDate,
      endDate,
      order,
      sort,
      activityType,
      dateRangeChosenLabel,
      referenceType,
      outcomeSummaryGoalTitle,
    } = state;
    const { outcomeSummaryReferences, actLoading } = report;

    const onChangeOrder = (order) => {
      setState({ ...state, order });
    };

    const paginationAPI = (page) => {
      const { startDate, endDate, order, sort } = state;
      dispatch(
        getOrganizationReportData(state.reportType, {
          startDate: startDate.format('YYYY-M-D'),
          endDate: endDate.format('YYYY-M-D'),
          sort,
          order,
          page,
        })
      );
    };

    const onChangeSort = (sort) => {
      setState({
        ...state,
        sort,
      });
    };

    switch (reportType) {
      case 'activities':
        return (
          <FeatureWrapper
            featureName="report_activity_summary"
            children={<Activity dateRangeChosenLabel={dateRangeChosenLabel} />}
          />
        );
      case 'input-output-data':
        return (
          <Segment.Group className="report-objective-table margin-down">
            <Segment className="chart-holder" loading={actLoading}>
              <FeatureWrapper
                featureName="report_headline_data"
                children={
                  <InputOutputReport
                    user={auth.user}
                    currencyType={get(auth, 'user.currencyType')}
                  />
                }
                fallbackComponent={<UnAuthorizedAccess />}
              />
            </Segment>
          </Segment.Group>
        );

      case 'outcome-summary':
        return (
          <Segment.Group className="report-objective-table margin-down">
            <Segment className="chart-holder" loading={actLoading}>
              <FeatureWrapper
                featureName="report_outcome_summary"
                children={
                  <OutcomesSummary
                    type={outcomeType}
                    method={methodType.toString()}
                    date={`${moment(startDate).format(
                      'DD MMM YYYY'
                    )} to ${moment(endDate).format('DD MMM YYYY')}`}
                    outcome={outcomeType}
                    chartType={chartType}
                    setChartType={(value) => this.setChartType(value)}
                    dateRangeChosenLabel={dateRangeChosenLabel}
                    referenceType={referenceType}
                    // setGoalTypes={this.setGoalTypes}
                    // setReferenceTypes={this.setReferenceTypes}
                    outcomeSummaryGoalTitle={outcomeSummaryGoalTitle}
                    outcomeSummaryReferences={outcomeSummaryReferences}
                  />
                }
                fallbackComponent={<UnAuthorizedAccess />}
              />
            </Segment>
          </Segment.Group>
        );
      case 'management-summary':
        return (
          <Segment.Group className="report-objective-table margin-down">
            <Segment className="chart-holder" loading={actLoading}>
              <FeatureWrapper
                featureName="report_account_summary"
                children={
                  <ManagementSummary
                    activityType={activityType}
                    order={order}
                    sort={sort}
                    onChangeOrder={(value) => onChangeOrder(value)}
                    onChangeSort={(value) => onChangeSort(value)}
                    paginationAPI={(value) => paginationAPI(value)}
                  />
                }
                fallbackComponent={<UnAuthorizedAccess />}
              />
            </Segment>
          </Segment.Group>
        );
      case 'outcomes-reported':
        return (
          <>
            <OutcomeChart
              type={outcomeType}
              method={methodType.toString()}
              date={`${moment(startDate).format('DD MMM YYYY')} to ${moment(
                endDate
              ).format('DD MMM YYYY')}`}
              outcome={outcomeType}
              chartType={chartType}
              setChartType={(value) => this.setChartType(value)}
              dateRangeChosenLabel={dateRangeChosenLabel}
              referenceType={referenceType}
              // setGoalTypes={this.setGoalTypes}
              // setReferenceTypes={this.setReferenceTypes}
              outcomeSummaryGoalTitle={outcomeSummaryGoalTitle}
              outcomeSummaryReferences={outcomeSummaryReferences}
              isPrePost={state.isPrePost}
            />
          </>
        );

      case 'outcomes-addressed':
        return (
          <FeatureWrapper
            featureName="report_outcome_summary"
            children={
              <OutcomeAddressed
                setOutcomeValue={(value) => this.setOutcomeValue(value)}
                dateRangeChosenLabel={dateRangeChosenLabel}
                reportType={reportType}
                activityId={null}
              />
            }
            fallbackComponent={<UnAuthorizedAccess />}
          />
        );
      default:
        return (
          <div className="blank-report-div">
            Your report will be previewed here
          </div>
        );
    }
  }

  const reportTypeOptions = compact(
    state.reportTypes.map((item) => {
      if (
        state.reportType !== 'outcomes-reported' &&
        item.value === 'outcomes-reported'
      ) {
        return null;
      }

      return item;
    })
  );

  return (
    <>
      <Segment.Group className="report-preview">
        <Segment className="control-holder">
          <div>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <label className="title">Preview Report</label>
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={8}
                  textAlign="right"
                  className="export-dropdown-btn"
                >
                  {state.reportType === 'management-summary' ? (
                    <Button
                      size="small"
                      className="Primary-Button"
                      icon
                      labelPosition="left"
                    >
                      <Dropdown
                        text="Export"
                        direction="left"
                        loading={exportLoading}
                        disabled={exportLoading}
                        // icon="angle down"
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            text="DOWNLOAD"
                            onClick={() => onClickExport()}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Button>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={5}>
                  <div className="fin-head">
                    <label className="input-label">Report Type</label>

                    <Dropdown
                      className="report-type"
                      name="reportType"
                      onChange={onTypeChange}
                      options={reportTypeOptions}
                      placeholder="Choose an option"
                      selection
                      value={state.reportType}
                    />
                  </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={5}>
                  <div className="date-range-container">
                    <div>
                      <label className="input-label">Date Range</label>
                    </div>
                    <DateRangeChart
                      startDate={state.startDate}
                      endDate={state.endDate}
                      datePicker={datePicker}
                      preDefinedDateRanges
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Segment>
      </Segment.Group>

      <div className="report-objective-table margin-down">
        <div className="chart-holder">{renderReportType(state.reportType)}</div>
      </div>
    </>
  );
};

export { ReportSelector };
