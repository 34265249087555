import React, { useState, useRef, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';
import Active from '../assets/map/Active.svg';
import Completed from '../assets/map/Completed.svg';
import InDevelopment from '../assets//map/In-development.svg';
import Group from '../assets//map/Group.svg';
import ContractorsGroup from '../assets//map/ContractorsGroup.svg';
import Organisation from '../assets//map/Organisation.svg';
import { isEmpty, isArray, get } from '../utils/lodash';
import { GOOGLE_API_KEY } from '../utils/constant';

export const GoogleMap = ({ data, type }) => {
  const points =
    isArray(data) && data.length > 0
      ? data.filter((item) => item.longitude !== null && item.latitude !== null)
      : [];

  const [zoom, setZoom] = useState(10);
  const [bounds, setBounds] = useState(null);
  const [panTovalue, setPanTovalue] = useState(null);
  const [toggle, setToggle] = useState(false);

  const defaultProps = {
    center: {
      lat: -25.2744, // Latitude of Australia's center
      lng: 133.7751, // longitude of Australia's center
    },
    zoom: 11,
  };

  const mapOptions = {
    radius: 75,
    maxZoom: 20,
    map: (props) => ({
      cost: props.cost,
      severity: props.severity,
      count: 1,
    }),
    reduce: (acc, props) => {
      acc.count += 1;
      acc.cost += props.cost;
      acc.severity = Math.max(acc.severity, props.severity);
      return acc;
    },
  };

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    mapOptions,
  });

  const getMapBounds = (map, maps, sensors) => {
    const bounds = new maps.LatLngBounds();

    sensors.forEach((sensor) => {
      bounds.extend(new maps.LatLng(sensor.latitude, sensor.longitude));
    });
    return bounds;
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

  const apiIsLoaded = (map, maps) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps, points);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };

  const zoomIn = (cluster, latitude, longitude) => {
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(cluster.id),
      20
    );
    setZoom(expansionZoom);
    setPanTovalue({ lat: latitude, lng: longitude });
  };

  const handleMarkerClick = () => {
    // Handle marker click event, e.g., display additional information
  };

  const mapAdvancedOptions = {
    streetViewControl: false,
    scaleControl: true,
    fullscreenControl: true,
    styles: [
      {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [{ visibility: 'off' }], // administrative boundaries
      },
      {
        featureType: 'poi',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'off', //  business locations
          },
        ],
      },

      {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }], //  poi icons and labels
      },
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }], //  public places
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ visibility: 'off' }], //  road geometry
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }], //  road icons and labels
      },

      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }], //  bus station icons and labels
      },
    ],
    gestureHandling: 'cooperative',
    disableDoubleClickZoom: false,
    mapTypeControl: false,
    mapTypeId: 'roadmap', //  hybrid, roadmap, satellite, terrain

    zoomControl: true,
    clickableIcons: false,
  };

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        options={mapAdvancedOptions}
        bootstrapURLKeys={{
          key: GOOGLE_API_KEY,
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        minZoom={defaultProps.zoom - 3}
        maxZoom={defaultProps.zoom + 3}
        minZoomOverride={true}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps, zoom }) => apiIsLoaded(map, maps)}
        zoom={zoom}
        center={panTovalue}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
      >
        {clusters.map((cluster, key) => {
          const [longitude, latitude] = cluster.geometry.coordinates;

          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties;

          if (isCluster) {
            return (
              <div
                key={`${type}_google_map_cluster_${key}`}
                lat={latitude}
                lng={longitude}
                className="clustericon"
                onClick={() => {
                  zoomIn(cluster, latitude, longitude);
                }}
              >
                {pointCount}
              </div>
            );
          }

          return (
            <Marker
              key={`${type}_google_map_cluster_${key}`}
              lat={latitude}
              lng={longitude}
              onClick={handleMarkerClick}
              activityDetails={cluster.activityDetails}
              orgDetails={get(cluster, 'organisationName', null)}
              setToggle={setToggle}
              toggle={toggle}
              type={type}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

export const Marker = ({
  activityDetails,
  orgDetails = null,
  setToggle,
  toggle,
  type,
  onClick,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  let imgSrc = null;
  let imgAlt = '';

  switch (type) {
    case 'completed':
      imgSrc = Completed;
      imgAlt = 'completed';
      break;
    case 'active':
      imgSrc = Active;
      imgAlt = 'active';
      break;
    case 'development':
      imgSrc = InDevelopment;
      imgAlt = 'development';
      break;
    case 'Contractor':
      imgSrc = ContractorsGroup;
      imgAlt = 'group';
      break;
    case 'Activities':
      imgSrc = Group;
      imgAlt = 'group';
      break;
    case 'Organisation':
      imgSrc = Organisation;
      imgAlt = 'Organisation';
      break;
    default:
      break;
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const markerElement = document.getElementById('marker_id');
      const tooltipElement = tooltipRef.current;

      if (
        markerElement &&
        !markerElement.contains(event.target) &&
        tooltipElement &&
        !tooltipElement.contains(event.target)
      ) {
        setShowTooltip(false);
      }
    };

    if (showTooltip) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showTooltip]);

  const handleMarkerClick = (event) => {
    event.stopPropagation();
    const nonHidingClasses = new Set([
      'google-map-popup',
      'heading',
      'development',
      'label-text',
      'active',
      'text',
      'completed',
      'view-more',
      'activity-count',
      'activity-list',
      'activity-name',
      'min-point',
      'view-details',
    ]);

    const isNonHidingClick = nonHidingClasses.has(event.target.classList[0]);

    // Hide all tooltips and reset state unless it's a non-hiding click
    if (!isNonHidingClick) {
      document.querySelectorAll('.google-map-popup').forEach((tooltip) => {
        tooltip.style.display = 'none';
      });

      setShowTooltip(true);
      onClick();
      setToggle(!toggle);
    }
  };

  return (
    <div
      id="marker_id"
      style={{
        position: 'relative',
        cursor: 'pointer',
      }}
      onClick={handleMarkerClick}
    >
      <img src={imgSrc} alt={imgAlt} />
      {showTooltip && (
        <div ref={tooltipRef} className="google-map-popup">
          {activityDetails && !isEmpty(activityDetails) ? (
            <>
              {(!isEmpty(activityDetails.development) &&
                activityDetails.development.length === 1) ||
              (!isEmpty(activityDetails.active) &&
                activityDetails.active.length === 1) ||
              (!isEmpty(activityDetails.completed) &&
                activityDetails.completed.length === 1) ? (
                <>
                  {!isEmpty(activityDetails.development) &&
                    activityDetails.development.length === 1 && (
                      <div className="heading">
                        <div className="development"></div>
                        <div className="label-text">
                          {activityDetails.development[0]}
                        </div>
                      </div>
                    )}
                  {!isEmpty(activityDetails.active) &&
                    activityDetails.active.length === 1 && (
                      <div className="heading">
                        <div className="active"></div>
                        <div className="text">
                          <div className="label-text">
                            {activityDetails.active[0]}
                          </div>
                        </div>
                      </div>
                    )}
                  {!isEmpty(activityDetails.completed) &&
                    activityDetails.completed.length === 1 && (
                      <div className="heading">
                        <div className="completed"></div>
                        <div className="label-text">
                          {activityDetails.completed[0]}
                        </div>
                      </div>
                    )}
                </>
              ) : (
                <div className="view-more">
                  {activityDetails.development.length >= 1 && (
                    <>
                      <div className="heading">
                        <div className="development"></div>
                        <div className="label-text">In-development :&nbsp;</div>
                        <div className="activity-count">
                          {String(activityDetails.development.length).padStart(
                            2,
                            '0'
                          )}
                        </div>
                      </div>
                      {toggle && (
                        <div className="activity-list">
                          {activityDetails.development.map((item) => {
                            return (
                              <div className="activity-name">
                                <div className="min-point"></div>
                                {item}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </>
                  )}

                  {activityDetails.active.length >= 1 && (
                    <>
                      <div className="heading">
                        <div className="active"></div>
                        <div className="label-text">Active :&nbsp;</div>
                        <div className="activity-count">
                          {String(activityDetails.active.length).padStart(
                            2,
                            '0'
                          )}
                        </div>
                      </div>

                      {toggle && (
                        <div className="activity-list">
                          {activityDetails.active.map((item) => {
                            return (
                              <div className="activity-name">
                                <div className="min-point"></div>
                                {item}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </>
                  )}

                  {activityDetails.completed.length >= 1 && (
                    <>
                      <div className="heading">
                        <div className="completed"></div>
                        <div className="label-text">Completed :&nbsp;</div>
                        <div className="activity-count">
                          {String(activityDetails.completed.length).padStart(
                            2,
                            '0'
                          )}
                        </div>
                      </div>

                      {toggle && (
                        <div className="activity-list">
                          {activityDetails.completed.map((item) => {
                            return (
                              <div className="activity-name">
                                <div className="min-point"></div>
                                {item}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </>
                  )}

                  <div
                    className="view-details"
                    onClick={(e) => {
                      e.stopPropagation();
                      setToggle(!toggle);
                    }}
                  >
                    {toggle ? 'View Less' : 'View More'}
                  </div>
                </div>
              )}
            </>
          ) : orgDetails ? (
            <div className="heading">
              <div className="label-text">{orgDetails}</div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};
