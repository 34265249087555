import './auth.scss';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SuccessContainer } from '../../components';
import { Container, Grid } from 'semantic-ui-react';

class PasswordChangeSuccess extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Grid.Row>
        {/* <Grid.Column mobile={16} tablet={5} computer={5} />
        <Grid.Column mobile={16} tablet={6} computer={6}> */}
        <Container className="email-verification-container">
          <Grid>
            <Grid.Row>
              <SuccessContainer
                msg={'Password Changed!'}
                description={[
                  <span>
                    Your password was reset successfully. You can now sign in
                    with
                  </span>,
                  <br />,
                  <span>your new password.</span>,
                ]}
                success={true}
                button="Go back to Sign In"
                buttonRoute="/signin"
              />
            </Grid.Row>
          </Grid>
        </Container>
        {/* </Grid.Column>
        <Grid.Column mobile={16} tablet={5} computer={5} /> */}
      </Grid.Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordChangeSuccess);
