import * as Yup from 'yup';

export const initialValues = (form) => {
  return {
    message: form && form.message ? form.message : '',
    permissions: form && form.permissions ? form.permissions : [],
  };
};

export const activityInviteTypeValidation = () =>
  Yup.object().shape({
    message: Yup.string().required('Message is required!'),
    permissions: Yup.array().optional(),
    permissionType: Yup.string().optional(),
  });
