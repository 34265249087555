import * as Yup from 'yup';

export const activityPlanEventDetailInitialValues = (eventDetail) => {
  return {
    eventDetail: eventDetail ? eventDetail : '',
  };
};

export const activityPlanDetailValidationSchema = Yup.object().shape({
  eventDetail: Yup.string().required('Select Activity type is required'),
});
