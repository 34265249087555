/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useField } from 'formik';
import { Button, Icon, Input } from 'semantic-ui-react';

export const CustomFileUpload = ({
  labelText,
  getValue,
  requiredStar,
  buttonText,
  loading,
  restrictedSize,
  accept,
  disabled,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [upload, setUpload] = useState({
    fileName: null,
    file: null,
    uploadURL: null,
  });

  const getFile = (event) => {
    if (event.target.files && event.target.files.length) {
      setUpload({
        fileName: event.target.files[0].name,
        file: event.target.files[0],
      });
      props.setFile({
        fileName: event.target.files[0].name,
        file: event.target.files[0],
      });
    }
  };

  return (
    <div className="formik-custom-field">
      <label
        className={requiredStar ? 'required-stars' : ''}
        htmlFor={props.id || props.name}
      >
        {labelText}
      </label>
      <div>
        <Input
          id={props.id}
          name={props.name}
          type="file"
          accept={accept}
          hidden
          onChange={(e, data) => {
            getFile(e);
            props.setFieldValue(props.name, e.target.files[0]);
          }}
          error={meta.touched && meta.error}
        />

        <Input
          label={
            <Button
              className="ui white button custom"
              as="label"
              htmlFor={props.id}
              type="button"
              loading={loading}
              disabled={disabled}
            >
              <Icon name="upload" />
              {buttonText}
            </Button>
          }
          labelPosition="right"
          fluid
          placeholder={props.placeholder}
          readOnly
          {...field}
          value={upload.fileName ? upload.fileName : ''}
        />
      </div>
      {props.infoMessage && (
        <label className="info-text">{props.infoMessage}</label>
      )}

      {meta.touched && meta.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
    </div>
  );
};
