/* eslint-disable eqeqeq */
import React from 'react';
import { Icon } from 'semantic-ui-react';
import { CustomTooltip } from '../../../components';
import { findIndex, get, sortBy } from '../../../utils/lodash';
import {
  CompletionProgress,
  CompletionProgressGrant,
} from '../../../utils/constant';
import { getActivityType } from '../../../utils';

const CustomItem = (props) => {
  const {
    title,
    statusList,
    statusId,
    activityPlanTypeId,
    currentActivity,
    popupState,
    popupContent,
    popupContentObject,
    popupPosition,
    smallText,
  } = props;

  const isDevelopmentActivity = statusId === 1;
  const isActiveActivity = statusId === 2;

  const RenderIcon = () => {
    let status = 0;
    const actType = getActivityType({ activityPlanTypeId });

    let _completionProgress = [];
    const _statusList = sortBy(statusList, 'section').filter(
      (status) => status.section.toUpperCase() !== 'GREEN LIGHT'
    );

    switch (actType.type) {
      case 'Single':
        _completionProgress = sortBy(CompletionProgress, 'section');
        const singleIndex = findIndex(
          _completionProgress,
          (o) => o.section.toUpperCase() === title
        );
        status = _statusList[singleIndex].status || 0;
        break;
      default:
        _completionProgress = sortBy(CompletionProgressGrant, 'section');

        const grantIndex = findIndex(
          _completionProgress,
          (o) => o.section.toUpperCase() === title
        );

        status = _statusList[grantIndex].status || 0;
        break;
    }

    const goalsData = statusList.find((item) => item.section === 'Goal');

    /*
     * IF NO GOAL SET YET
     */
    if (goalsData.status < 2 && title !== 'GOAL') {
      return null;
    }

    /*
     * check whether all the green light rules are met
     */

    if (statusId === 3) {
      return (
        <Icon circular inverted className="blue" name="check" size="small" />
      );
    }

    let iconProp = {
      className: '',
      name: '',
    };

    /*
     * *********************** Not started yet and unable to edit (some requirement not met yet in previous tabs or need validations to completed) ***********************
     */
    if (status === 0) {
      if (title === 'GOAL') {
        // if goal are set need to show check icon
        iconProp = {
          name: currentActivity.goal ? 'check' : '',
          className: 'whitebox',
        };
      } else if (title === 'OBJECTIVE' && actType.type === 'Single') {
        // iconProp = { ...iconProp, className: '' };
        return null;
      } else if (title === 'EVIDENCE') {
        // iconProp = { ...iconProp, className: 'whitebox' };
        return null;
      } else if (title === 'THEORY OF CHANGE') {
        // iconProp = { ...iconProp, className: 'whitebox' };
        return null;
      } else if (title === 'ACTIVITY PLAN') {
        // iconProp = { ...iconProp, className: 'whitebox' };
        return null;
      } else if (title === 'EVALUATION') {
        // iconProp = { ...iconProp, className: 'whitebox' };
        return null;
      } else if (title === 'DOCUMENTATION') {
        // iconProp = { ...iconProp, className: 'whitebox' };
        return null;
      } else if (title === 'REPORT') {
        if (statusId === 1) {
          // iconProp = { ...iconProp, className: 'whitebox' };
          return null;
        }
        // in active
        if (statusId === 2) {
          iconProp = { name: '', className: 'green' };
        }
      } else return null;

      /*
       *********************** all the required fields are met  status ***********************
       */
    } else if (status === 1) {
      if (title === 'GOAL') {
        iconProp = {
          ...iconProp,
          className: 'whitebox',
        };
      } else if (title === 'OBJECTIVE') {
        iconProp = {
          ...iconProp,
          className: 'whitebox',
        };
      } else if (title === 'EVIDENCE') {
        iconProp = {
          ...iconProp,
          className: 'whitebox',
        };
      } else if (title === 'THEORY OF CHANGE') {
        iconProp = {
          ...iconProp,
          className: 'whitebox',
        };
      } else if (title === 'ACTIVITY PLAN') {
        iconProp = {
          ...iconProp,
          className: isActiveActivity ? 'green' : 'whitebox',
        };
      } else if (title === 'EVALUATION') {
        iconProp = {
          ...iconProp,
          className: isDevelopmentActivity
            ? 'whitebox'
            : isActiveActivity
              ? 'green'
              : 'blue',
        };
      } else if (title === 'DOCUMENTATION') {
        // if()
        iconProp = {
          // name: isArchivedValid ? 'check' : '',
          ...iconProp,
          className: 'green',
        };
      } else if (title === 'REPORT') {
        iconProp = {
          // name: isArchivedValid ? 'check' : '',
          ...iconProp,
          className: isDevelopmentActivity ? 'whitebox' : 'green',
        };
      } else return null;

      /*
       * *********************** completed  status [NOTE: When all requirements met ***********************
       */
    } else if (status === 2) {
      if (title === 'GOAL') {
        iconProp = {
          name: 'check',
          className: 'blue',
        };
      } else if (title === 'OBJECTIVE') {
        iconProp = {
          name: isDevelopmentActivity ? 'check' : '',
          className:
            isDevelopmentActivity &&
            !get(currentActivity, 'isLinkedActivity', false)
              ? 'whitebox'
              : 'blue',
        };
      } else if (title === 'EVIDENCE') {
        iconProp = {
          name: isDevelopmentActivity ? 'check' : '',
          className: isDevelopmentActivity ? 'whitebox' : 'blue',
        };
      } else if (title === 'THEORY OF CHANGE') {
        iconProp = {
          name: isDevelopmentActivity ? 'check' : '',
          className: isDevelopmentActivity ? 'whitebox' : 'blue',
        };
      } else if (title === 'ACTIVITY PLAN') {
        iconProp = {
          name: 'check',
          className: isDevelopmentActivity
            ? 'whitebox'
            : isActiveActivity
              ? 'green'
              : 'blue',
        };
      } else if (title === 'EVALUATION') {
        iconProp = {
          name: 'check',
          className: isDevelopmentActivity
            ? 'whitebox'
            : isActiveActivity
              ? 'green'
              : 'blue',
        };
      } else if (title === 'DOCUMENTATION') {
        iconProp = {
          name: 'check',
          className: isDevelopmentActivity
            ? 'whitebox'
            : isActiveActivity
              ? 'green'
              : 'blue',
        };
        // iconProp = { name: isArchivedValid ? 'check' : '', className: 'blue' };
      } else if (title === 'REPORT') {
        // iconProp = { name: isArchivedValid ? 'check' : '', className: 'blue' };
        if (isDevelopmentActivity) {
          iconProp = { name: 'check', className: 'whitebox' };
        }
        // in active
        if (isActiveActivity) {
          iconProp = {
            name: 'check',
            className: 'green',
          };
        }
      } else return null;
    } else {
    }

    return (
      <Icon
        circular
        inverted
        className={iconProp.className}
        name={iconProp.name}
        size="small"
      />
    );
  };

  return (
    <CustomTooltip
      state={popupState}
      content={popupContent}
      contentObject={popupContentObject}
      position={popupPosition}
    >
      <div className="customSteps">
        <RenderIcon />
        <label className={`customStep-label ${smallText ? 'small-text' : ''}`}>
          {title}
        </label>
      </div>
    </CustomTooltip>
  );
};

export { CustomItem };
