import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { CustomInput } from '../../../../components/form/inputs/Input';
import { Button, Icon, Input, Radio } from 'semantic-ui-react';
import { CustomDropdown } from '../../../../components/form';
import plusOutline from '../../../../assets/add-icon-white-bg.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getOutputFormatTypes } from '../../../../store/actions';
import { isEmpty } from 'lodash';
import { authMsg } from '../../../../utils';

export const OutputFormatForm = ({
  outputFormat,
  postOutputFormat,
  updateOutputFormat,
  toggle,
  pageChange,
  outLoading,
  isEdit,
  form,
}) => {
  const dispatch = useDispatch();
  const [isFormula, setIsFormula] = useState(
    outputFormat ? outputFormat.isFormula : false
  );

  const [formatOptions, setFormatOptions] = useState(
    !isEdit
      ? [
          {
            key: 1,
            name: '',
          },
        ]
      : []
  );

  const [formatOptionsLength, setFormatOptionsLength] = useState(
    !isEdit ? 1 : 0
  );

  const [selectedValue, setSelectedValue] = useState(false);
  const outputFormatTypes = useSelector(
    (state) => state.admin.outputFormatTypes
  );

  useEffect(() => {
    if (outputFormat) {
      setIsFormula(outputFormat.isFormula);
    }
  }, [outputFormat]);

  useEffect(() => {
    if (outputFormat) {
      const options = outputFormat.formatOptions.map((item, key) => {
        return {
          key,
          id: item.id,
          name: item.name,
        };
      });
      setFormatOptions(
        !isEdit
          ? [
              {
                key: 1,
                name: '',
              },
            ]
          : isEmpty(options)
            ? [
                {
                  key: 1,
                  name: '',
                },
              ]
            : options
      );
    }
    if (outputFormatTypes && outputFormat) {
      const dropdownValue = outputFormatTypes.find(
        (item) => item.id === outputFormat.typeId && item.isMultiOption === true
      );
      if (dropdownValue) {
        setSelectedValue(true);
      }
    }
  }, [isEdit, outputFormat, outputFormatTypes]);

  useEffect(() => {
    dispatch(getOutputFormatTypes());
  }, [dispatch]);

  const handleValidSubmit = async (values) => {
    const payload = {
      name: values.name,
      typeId: values.typeId.toString(),
      label: values.label,
      isFormula: isFormula,
    };
    try {
      if (isEdit) {
        let emptyNameInItems;

        formatOptions.forEach((item) => {
          if (item.name === '') {
            emptyNameInItems = true;
          }
        });
        // FIXME: why used like below [Need to check]
        // if (emptyNameInItems) {
        //   return authMsg('error', 'Option cannot be empty.');
        // } else {
        payload.formatOptions = formatOptions;
        await updateOutputFormat(outputFormat.id, payload);
        // }
      } else {
        const options = formatOptions.map((item) => item.name.trim());
        if (options[0] !== '') {
          payload.formatOptions = options;
        }
        await postOutputFormat(payload);
      }
      toggle();
      pageChange();
    } catch (error) {
      console.error('outputFormatter ~ handleValidSubmit ~ error', error);
    }
  };
  const handleAddNewSubOption = () => {
    setFormatOptions([
      ...formatOptions,
      {
        key: Math.random().toString(36).substr(2, 9),
        name: '',
      },
    ]);
    setFormatOptionsLength(formatOptionsLength + 1);
  };

  const renderTypes =
    outputFormatTypes &&
    outputFormatTypes.map((type) => ({
      key: type.id,
      text: type.name,
      value: type.id,
      isMultiOption: type.isMultiOption,
      isDisabled: type.isDisabled,
    }));

  const initialValues = {
    label: outputFormat ? outputFormat.label : '',
    name: outputFormat ? outputFormat.name : '',
    typeId: outputFormat ? outputFormat.typeId : '',
    formatOptions: outputFormat ? outputFormat.formatOptions : '',
    isFormula: isFormula,
  };
  const validationSchema = Yup.object().shape({
    label: Yup.string().required('Label is required'),
    name: Yup.string()
      .matches(/^\S*$/, 'Field Name should not include any white spaces.')
      .required('Field Name is required'),
    typeId: Yup.string().required('Type is required'),
  });
  const onDeleteOption = (key) => {
    setFormatOptionsLength(formatOptionsLength - 1);
    const updatedFormatOptions = formatOptions.filter(
      (item) => item.key !== key
    );
    setFormatOptions(updatedFormatOptions);
  };

  const onChangeOptions = (data, key) => {
    const updatedValue = formatOptions.map((item) => {
      if (item.key === key) {
        return {
          key: key,
          id: item.id,
          name: data.trim(),
        };
      }
      return item;
    });
    setFormatOptions(updatedValue);
  };
  const deletingArrLength = !isEdit
    ? formatOptions.length
    : formatOptions.length;

  const onChangeMultiOption = (e) => {
    const isMultiOptions = outputFormatTypes.find(
      (item) => item.id === e && item.isMultiOption === true
    );
    if (isMultiOptions !== undefined) {
      setSelectedValue(true);
    } else {
      setSelectedValue(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleValidSubmit(values)}
    >
      {({ handleSubmit, isSubmitting, setFieldValue, errors }) => (
        <Form className="venue-form" onSubmit={handleSubmit}>
          <CustomInput
            labelText="Label Name"
            name="label"
            type="text"
            placeholder="Label Name"
          />
          <CustomInput
            labelText="Field Name"
            name="name"
            type="text"
            placeholder="Field Name"
          />
          <CustomDropdown
            labelText="Type"
            name="typeId"
            id="type"
            getValue={(e) => onChangeMultiOption(e)}
            options={renderTypes}
            placeholder="Select Type"
          />
          {selectedValue ? (
            <>
              {isEdit ? (
                <div>
                  <label>Checkbox Names</label>
                </div>
              ) : (
                <div>
                  <label>Checkbox Options</label>
                </div>
              )}
              {formatOptions.map((item, index) => {
                const key = `formatOptions${item.key}`;
                const isExist = item.id > 0 ? true : false;
                return (
                  <div className="d-flex add-venue-sub-label" key={key}>
                    {isEdit ? (
                      <div className="sub-options-container">
                        <Input
                          id={`formatOptions${item.key}`}
                          name={`formatOptions${item.key}`}
                          className="edit-venue-type-input"
                          placeholder=""
                          onChange={(e) =>
                            onChangeOptions(e.target.value, item.key)
                          }
                          value={item.name}
                          icon={
                            !isExist && deletingArrLength > 0 ? (
                              <div className="delete-btn">
                                <Icon
                                  onClick={() => onDeleteOption(item.key)}
                                  name="trash"
                                />
                              </div>
                            ) : null
                          }
                        />
                      </div>
                    ) : (
                      <CustomInput
                        id={`formatOptions${item.key}`}
                        name={`formatOptions${item.key}`}
                        placeholder=""
                        getValue={(e) => onChangeOptions(e, item.key)}
                        icon={
                          index !== 0 && deletingArrLength > 1 ? (
                            <div className="delete-btn">
                              <Icon
                                onClick={() => onDeleteOption(item.key)}
                                name="trash"
                              />
                            </div>
                          ) : null
                        }
                      />
                    )}
                  </div>
                );
              })}
              <div className="d-flex align-items-end flex-column">
                <div>
                  <Button
                    onClick={handleAddNewSubOption}
                    className="btn-add-ref"
                    type="button"
                  >
                    <span>
                      <img className="plus-icon" alt="plus" src={plusOutline} />
                      Add More Options
                    </span>
                  </Button>
                </div>
              </div>
            </>
          ) : null}

          <div className="activate-notify-row">
            <div className="evaluation-activity-toggel-group mt-4">
              <label className="lbl-margin-left mb-0">Include Formula</label>
              <Radio
                toggle
                name="isFormula"
                type="checkbox"
                checked={isFormula}
                onChange={() => setIsFormula(!isFormula)}
              />
            </div>
          </div>

          <div className="model-button mt-5">
            <Button
              content="Save"
              type="submit"
              className="modal-proceed"
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
