import axios from 'axios';
import { get } from '../../utils/lodash';
import { getCommonError } from '../../utils';
import { NotificationManager } from 'react-notifications';
import {
  getCachedSurveyToken,
  setCachedSurveyToken,
} from '../../utils/cacheStore';

/*** TYPES ***/
const GET_SURVEY = 'GET_SURVEY';
const SURVEY_GET_LOADING = 'SURVEY_GET_LOADING';
const SURVEY_POST_LOADING = 'SURVEY_POST_LOADING';
const SURVEY_ERROR_MESSAGE = 'SURVEY_ERROR_MESSAGE';
const PRINTABLE_SURVEY = 'PRINTABLE_SURVEY';
const PRINTABLE_SURVEY_LOADING = 'PRINTABLE_SURVEY_LOADING';
/*** REDUCERS ***/
const initialState = {
  surveyData: {},
  surveyGetLoading: true,
  surveyPostLoading: false,
  errorMessage: '',
  printableSurvey: {},
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SURVEY:
      return {
        ...state,
        surveyData: action.payload ? action.payload : {},
      };

    case SURVEY_GET_LOADING:
      return { ...state, surveyGetLoading: action.payload };

    case SURVEY_POST_LOADING:
      return { ...state, surveyPostLoading: action.payload };

    case SURVEY_ERROR_MESSAGE:
      return { ...state, errorMessage: action.payload };

    case PRINTABLE_SURVEY:
      return { ...state, printableSurvey: action.payload };

    case PRINTABLE_SURVEY_LOADING:
      return { ...state, printableSurveyLoading: action.payload };

    default:
      return state;
  }
}

/*** ACTIONS ***/

export function printableSurvey(token) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: PRINTABLE_SURVEY_LOADING, payload: true });
        const response = await axios.get(`/printable-survey/${token}`);
        dispatch({ type: PRINTABLE_SURVEY, payload: response.data });
        dispatch({ type: PRINTABLE_SURVEY_LOADING, payload: false });
        resolve(response.data ? response.data : {});
      } catch (error) {
        dispatch({ type: PRINTABLE_SURVEY, payload: {} });
        dispatch({ type: PRINTABLE_SURVEY_LOADING, payload: false });
        reject({});
      }
    });
  };
}

export function getSurvey(alert, surveyId) {
  return async (dispatch) => {
    try {
      dispatch({ type: SURVEY_GET_LOADING, payload: true });
      let cachedSurveyToken = await getCachedSurveyToken(surveyId);
      if (!cachedSurveyToken) {
        await setCachedSurveyToken(surveyId);
        cachedSurveyToken = await getCachedSurveyToken(surveyId);
      }
      const response = await axios.get(`/survey/${surveyId}`, {
        headers: { Session: cachedSurveyToken },
      });
      dispatch({ type: GET_SURVEY, payload: response.data });
      dispatch({ type: SURVEY_GET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SURVEY_GET_LOADING, payload: false });
      const message = get(error, 'response.data.message', '');
      dispatch({ type: SURVEY_ERROR_MESSAGE, payload: message });
    }
  };
}

export function postSurvey(alert, data, surveyId) {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const state = getState();
        data.projectId = state.survey.surveyData.projectId;
        dispatch({ type: SURVEY_POST_LOADING, payload: true });

        let cachedSurveyToken = await getCachedSurveyToken(surveyId);
        if (!cachedSurveyToken) {
          await setCachedSurveyToken(surveyId);
          cachedSurveyToken = await getCachedSurveyToken(surveyId);
        }

        await axios.post(`/survey`, data, {
          headers: { Session: cachedSurveyToken },
        });
        dispatch({ type: SURVEY_POST_LOADING, payload: false });
        NotificationManager.success('Successfully Completed ', 'Success');
        resolve();
      } catch (error) {
        alert.error(getCommonError(error));
        dispatch({ type: SURVEY_POST_LOADING, payload: false });
        reject();
      }
    });
  };
}
