import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Grid,
  Segment,
  Modal,
  Button,
  Icon,
  Dropdown,
  Search,
} from 'semantic-ui-react';
import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from 'availity-reactstrap-validation';
import {
  LocationSearchInput,
  EmptyContainer,
  CustomTable,
} from '../../../../components';
import {
  getOrgContractorsList,
  getOccupationTypes,
  getOccupation,
  createOrgContractor,
  updateOrgContractor,
  deleteOrgContractor,
} from '../../../../store/actions';
import {
  map,
  get,
  groupBy,
  filter,
  uniqBy,
  isEmpty,
} from '../../../../utils/lodash';
import '../organization.scss';
import { getABN } from '../../../../utils';
import { countries } from 'country-list-json';
import { MAP_DEFAULT_COUNTRY_NAME } from '../../../../utils/constant';

class ContractorForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        country: get(
          props,
          'selectedContractor.country',
          MAP_DEFAULT_COUNTRY_NAME
        ),
      },
      isLoading: false,
      results: [],
      searchValue: '',
      searchId: '',
      searchError: false,
      abnList: [],
      selectedAbn: '',
      companyName: '',
      searchLoading: false,
      reportOpen: false,
      searchValidation: false,
      contractorCategory: 'confirmed',
    };
  }

  componentWillMount() {
    this.resetComponent();
  }

  componentDidMount() {
    const { selectedContractor, isNew } = this.props;
    if (!isNew) {
      this.setState({
        searchValue: selectedContractor.occupation
          ? selectedContractor.occupation.code
          : '',
        searchId: selectedContractor.occupation
          ? selectedContractor.occupation.code
          : '',
        companyName: selectedContractor.companyName
          ? selectedContractor.companyName
          : '',
      });
      this.occupationGroupChange(
        get(selectedContractor, 'occupationMinorGroup.code', null)
      );
    }
  }

  async handleValidSubmit(event, values) {
    try {
      const {
        auth,
        isNew,
        toggle,
        createOrgContractor,
        updateOrgContractor,
        projectId,
        resetPage,
        activity = {},
      } = this.props;

      const { searchId, searchError, companyName } = this.state;

      if (searchError) return;

      values.occupationCode = searchId;
      values.companyName = companyName;
      values.activityId = projectId;

      values.organisationId && delete values.organisationId;
      values.createdBy && delete values.createdBy;

      const ownerOrgId = get(
        activity,
        'currentActivity.owner.organisation.id',
        null
      );

      values.occupationCode = searchId;
      values.companyName = companyName;

      if (isNew) {
        const isSubmitted = await createOrgContractor(values, projectId);

        if (isSubmitted) {
          this.form && this.form.reset();
          toggle();
          resetPage();
        }
      } else {
        const { selectedContractor } = this.props;
        const isSubmitted = await updateOrgContractor(
          selectedContractor.id,
          values,
          projectId
        );

        if (isSubmitted) {
          toggle();
          this.form && this.form.reset();
          resetPage();
        }
      }
    } catch (error) {
      console.error('ContractorForm ~ handleValidSubmit ~ error: ', error);
    }
  }

  handleSubmit() {
    this.setState({ searchError: this.state.searchId ? false : true });
  }

  async setLocations(data) {
    const addressNo = get(data, 'streetNumber');
    const addressLine = get(data, 'streetAddress');
    let values = {
      streetAddress:
        addressNo && addressLine
          ? addressNo.concat(', ', addressLine)
          : addressNo || addressLine,
      streetAddress2: '',
      suburb: data.suburb ? data.suburb : '',
      state: data.state ? data.state : '',
      postcode: data.postcode ? data.postcode : '',
      country: data.country ? data.country : '',
    };
    this.setState({ values });
  }

  renderServiceOption(types) {
    return map(types, (item, i) => (
      <option key={i} value={item.id}>
        {item.name}
      </option>
    ));
  }

  renderServiceGroup() {
    const { occupationTypes } = this.props.extra;
    const occupationTypesList = groupBy(occupationTypes, (i) => {
      return i.parentName;
    });

    return map(occupationTypesList, (item, i) => (
      <optgroup key={i} label={i}>
        {this.renderServiceOption(item)}
      </optgroup>
    ));
  }

  renderCountries() {
    return map(countries, (country, i) => (
      <option key={i} value={country.name} name={country.name}>
        {country.name}
      </option>
    ));
  }

  resetComponent() {
    this.setState({
      isLoading: false,
      results: [],
      searchValue: '',
      searchId: '',
    });
  }

  handleResultSelect = (e, result) => {
    this.setState({
      searchId: result.value,
      searchValue: result.value,
      searchError: false,
    });
  };

  occupationGroupChange(value, isNew) {
    let { occupations } = this.props.extra;
    occupations = filter(occupations, (o) => {
      // eslint-disable-next-line
      return value == String(o.code).slice(0, 2);
    });
    occupations = uniqBy(occupations, (o) => {
      return o.title;
    });

    occupations = map(occupations, (item, i) => ({
      key: item.code,
      value: item.code,
      text: item.title,
    }));

    this.setState({
      occupationGroupCode: value,
      occupations: occupations,
      results: occupations,
    });

    if (isNew) {
      this.setState({
        searchValue: '',
        searchId: '',
      });
    }
  }

  handleResult = (e, { result }) => {
    this.setState({
      selectedAbn: result.Abn,
      companyName: result.Name,
      reportOpen: false,
    });
  };

  handleOnChange = async (e, { value }) => {
    try {
      await this.setState({ companyName: value });
    } catch (error) {}
  };

  handleSearchChange = async () => {
    try {
      const { companyName } = this.state;
      await this.setState({ searchLoading: true });
      let abnList = await getABN(companyName);
      await this.setState({
        abnList: abnList.Names,
        reportOpen: true,
        searchLoading: false,
      });
    } catch (error) {
      await this.setState({ searchLoading: false });
    }
  };

  closeResults = () => {
    this.setState({ reportOpen: false });
  };

  handleContractorCategory = (category) => {
    this.setState({ contractorCategory: category });
  };

  render() {
    const {
      selectedContractor,
      isNew,
      viewOnly,
      organization: { loading = false },
    } = this.props;

    const {
      values,
      isLoading,
      searchValue,
      results,
      occupationGroupCode,
      searchError,
      abnList,
      companyName,
      selectedAbn,
      searchLoading,
      reportOpen,
      contractorCategory,
    } = this.state;

    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        onSubmit={(event, values) => this.handleSubmit(event, values)}
        model={selectedContractor}
        className="venue-form"
      >
        {isNew && (
          <div className="contact-flex">
            <div className="col-8 inputs-left-align-flex">
              <Grid>
                <Grid.Row>
                  <div className="contact-category-container">
                    <label className="contact-category-header">Status</label>
                    <div>
                      <AvRadioGroup
                        name="contractorCategory"
                        className="contact-category-checkBox"
                        value={contractorCategory}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Contractor category is required',
                          },
                        }}
                      >
                        <Grid.Column
                          className="contact-category-row"
                          mobile={16}
                          tablet={8}
                          computer={5}
                        >
                          <div className="contact-category-radio">
                            <AvRadio
                              name="category"
                              type="radio"
                              label="Confirmed"
                              value="confirmed"
                              onChange={() =>
                                this.handleContractorCategory('confirmed')
                              }
                            />
                          </div>
                        </Grid.Column>
                        <Grid.Column
                          className="contact-category-row"
                          mobile={16}
                          tablet={8}
                          computer={5}
                        >
                          <div className="contact-category-radio">
                            <AvRadio
                              name="category"
                              type="radio"
                              label="Projected"
                              value="projected"
                              onChange={() =>
                                this.handleContractorCategory('projected')
                              }
                            />
                          </div>
                        </Grid.Column>
                      </AvRadioGroup>
                    </div>
                  </div>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        )}
        <div className="contact-flex">
          <div className="col inputs-left-align-flex">
            {viewOnly ? (
              <AvField
                label="Company/ Business Name"
                className="venue-inputs-new"
                placeholder="Company/ Business Name"
                name="acn"
                type="text"
                value={companyName}
                disabled={viewOnly}
              ></AvField>
            ) : (
              <>
                <label>
                  Company/ Business Name
                  {contractorCategory === 'confirmed' && (
                    <span className="required-asterisk">*</span>
                  )}
                </label>
                <div className="search-fl">
                  <Search
                    name="companyName"
                    onResultSelect={this.handleResult}
                    onSearchChange={this.handleOnChange}
                    open={reportOpen}
                    onBlur={this.closeResults}
                    icon="none"
                    results={abnList}
                    value={companyName}
                    placeholder="Search"
                    resultRenderer={({ Abn, Name, Postcode, State }) => (
                      <div>
                        <label>
                          {Name.charAt(0).toUpperCase() + Name.slice(1)}
                        </label>
                        <div className="contact-search-result">
                          <label>
                            <label className="sub-info">State:{'  '}</label>
                            <label className="info"> {State}</label>
                          </label>
                          <label className="leftInfo">
                            <label className="sub-info">Postcode: </label>
                            <label className="info"> {Postcode}</label>
                          </label>
                          <label className="leftInfo">
                            <label className="sub-info">ABN: </label>
                            <label className="info"> {Abn}</label>
                          </label>
                        </div>
                      </div>
                    )}
                    className="contactSearch search-custom-height"
                  />

                  <div className="icon-button-partner">
                    <Button.Group>
                      <Button
                        type="button"
                        labelPosition="left"
                        icon="search"
                        content="Search"
                        className="search-button-contact"
                        onClick={this.handleSearchChange}
                        loading={searchLoading}
                        disabled={searchLoading}
                      ></Button>
                    </Button.Group>
                  </div>
                </div>
                {this.state.searchValidation &&
                contractorCategory === 'confirmed' ? (
                  <div className="invalid-dropdown-input">
                    Company/ Business Name is required
                  </div>
                ) : null}
              </>
            )}
          </div>

          <div className="col inputs-right-align-flex">
            <AvField
              label={
                <div>
                  ABN / ACN{' '}
                  {contractorCategory === 'confirmed' && (
                    <span className="required-asterisk">*</span>
                  )}
                </div>
              }
              className="venue-inputs-new"
              placeholder="Enter ABN/ ACN"
              name="acn"
              type="text"
              value={selectedAbn}
              // onChange={v => this.occupationGroupChange(v.target.value, true)}
              validate={{
                required: {
                  value: contractorCategory === 'confirmed' ? true : false,
                  errorMessage: 'ABN / ACN is required',
                },
              }}
              disabled={viewOnly}
            ></AvField>
          </div>
        </div>

        <div className="contact-flex">
          <div className="col inputs-left-align-flex">
            <AvField
              label={
                <>
                  ANZSCO Contractor Service Group{' '}
                  <span className="required-asterisk">*</span>
                </>
              }
              className="venue-inputs-new"
              name="occupationMinorGroupCode"
              type="select"
              onChange={(v) => this.occupationGroupChange(v.target.value, true)}
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Contractor Service Group is required',
                },
              }}
              disabled={viewOnly}
            >
              <option value="" defaultValue disabled>
                Select a contractor service group
              </option>
              {this.renderServiceGroup()}
            </AvField>
          </div>

          <div className="col inputs-right-align-flex">
            {!viewOnly ? (
              <div className="contact-drop-search">
                <label>
                  ANZSCO Occupation / Service Type{' '}
                  {<span className="required-asterisk">*</span>}
                </label>
                <Dropdown
                  loading={isLoading}
                  placeholder="Search"
                  fluid
                  search
                  selection
                  className={
                    searchError
                      ? 'venue-inputs-new error-contractor'
                      : 'venue-inputs-new'
                  }
                  onChange={(event, data) =>
                    this.handleResultSelect(event, data)
                  }
                  options={results}
                  value={searchValue}
                  disabled={
                    viewOnly
                      ? true
                      : isNew
                        ? occupationGroupCode
                          ? false
                          : true
                        : false
                  }
                />
                {searchError ? (
                  <div className="custom-error-label">
                    Occupation / Service Type is required
                  </div>
                ) : null}
              </div>
            ) : (
              <AvField
                label="Occupation / Service Type"
                className="venue-inputs-new"
                name="occupation"
                type="text"
                value={searchValue}
                disabled={true}
              />
            )}
          </div>
        </div>

        <div className="contact-flex">
          <div className="col inputs-left-align-flex">
            <AvField
              label={
                <>
                  First Name{' '}
                  {contractorCategory === 'confirmed' && (
                    <span className="required-asterisk">*</span>
                  )}
                </>
              }
              className="venue-inputs-new"
              placeholder="Enter first name"
              name="firstName"
              type="text"
              validate={{
                required: {
                  value: contractorCategory === 'confirmed' ? true : false,
                  errorMessage: 'First Name is required',
                },
              }}
              disabled={viewOnly}
            />
          </div>
          <div className="col inputs-right-align-flex">
            <AvField
              label={
                <>
                  Surname{' '}
                  {contractorCategory === 'confirmed' && (
                    <span className="required-asterisk">*</span>
                  )}
                </>
              }
              className="venue-inputs-new"
              placeholder="Enter surname"
              name="secondName"
              type="text"
              validate={{
                required: {
                  value: contractorCategory === 'confirmed' ? true : false,
                  errorMessage: 'Surname is required',
                },
              }}
              disabled={viewOnly}
            />
          </div>
        </div>

        <div className="contact-flex">
          <div className="col inputs-left-align-flex">
            <AvField
              label={
                <>
                  Phone{' '}
                  {contractorCategory === 'confirmed' && (
                    <span className="required-asterisk">*</span>
                  )}
                </>
              }
              className="venue-inputs-new"
              placeholder="Enter phone number"
              name="phone"
              type="number"
              validate={{
                minLength: {
                  value: 10,
                  errorMessage: 'Must be a minimum of 10 digits',
                },
                required: {
                  value: contractorCategory === 'confirmed' ? true : false,
                  errorMessage: 'Phone is required',
                },
              }}
              disabled={viewOnly}
            />
          </div>
          <div className="col inputs-right-align-flex">
            <AvField
              label={
                <>
                  Email{' '}
                  {contractorCategory === 'confirmed' && (
                    <span className="required-asterisk">*</span>
                  )}
                </>
              }
              className="venue-inputs-new"
              placeholder="Enter email"
              name="email"
              type="email"
              errorMessage="Please enter valid email address"
              validate={{
                required: {
                  value: contractorCategory === 'confirmed' ? true : false,
                  errorMessage: 'Email is required',
                },
              }}
              disabled={viewOnly}
            />
          </div>
        </div>
        <AvField
          label="Website"
          className="venue-inputs-new"
          placeholder="Enter website"
          name="website"
          type="text"
          disabled={viewOnly}
        />

        <div className="col-6 inputs-left-align-flex">
          <AvField
            label={
              <>
                Country
                <span className="required-asterisk">*</span>
              </>
            }
            className="venue-inputs-new"
            name="country"
            type="select"
            onChange={(e) =>
              this.setState({ values: { ...values, country: e.target.value } })
            }
            validate={{
              required: {
                value: true,
                errorMessage: 'Country is required',
              },
            }}
            disabled={viewOnly}
            value={values['country']}
          >
            <option value="" defaultValue disabled>
              Select country
            </option>
            {this.renderCountries()}
          </AvField>
        </div>

        {!viewOnly ? (
          <LocationSearchInput
            customClass="org-inputs-location-search-contact"
            getLocation={(data) => this.setLocations(data)}
            value={companyName}
            label={'Search address with Google'}
            country={values['country']}
          />
        ) : null}

        <div className="contact-flex">
          <div className="col inputs-left-align-flex">
            <AvField
              label={
                <>
                  Address Line 1{' '}
                  {contractorCategory === 'confirmed' && (
                    <span className="required-asterisk">*</span>
                  )}
                </>
              }
              className="venue-inputs-new"
              name="streetAddress"
              type="text"
              validate={{
                required: {
                  value: contractorCategory === 'confirmed' ? true : false,
                  errorMessage: 'Street Address is required',
                },
              }}
              value={values['streetAddress']}
              disabled={viewOnly}
            />
          </div>
          <div className="col inputs-right-align-flex">
            <AvField
              label="Address Line 2"
              className="venue-inputs-new"
              name="streetAddress2"
              type="text"
              value={values['streetAddress2']}
              disabled={viewOnly}
            />
          </div>
        </div>

        <div className="contact-flex">
          <div className="col inputs-left-align-flex">
            <AvField
              label={
                <>
                  Suburb{' '}
                  {contractorCategory === 'confirmed' && (
                    <span className="required-asterisk">*</span>
                  )}
                </>
              }
              className="venue-inputs-new"
              name="suburb"
              type="text"
              validate={{
                required: {
                  value: contractorCategory === 'confirmed' ? true : false,
                  errorMessage: 'Suburb is required',
                },
              }}
              value={values['suburb']}
              disabled={viewOnly}
            />
          </div>
          <div className="col">
            <AvField
              label={
                <>
                  State / Province{' '}
                  {contractorCategory === 'confirmed' && (
                    <span className="required-asterisk">*</span>
                  )}
                </>
              }
              className="venue-inputs-new"
              name="state"
              type="text"
              validate={{
                required: {
                  value: contractorCategory === 'confirmed' ? true : false,
                  errorMessage: 'State is required',
                },
              }}
              value={values['state']}
              disabled={viewOnly}
            />
          </div>
          <div className="col inputs-right-align-flex">
            <AvField
              label={
                <>
                  Postcode{' '}
                  {contractorCategory === 'confirmed' && (
                    <span className="required-asterisk">*</span>
                  )}
                </>
              }
              className="venue-inputs-new"
              name="postCode"
              type="text"
              validate={{
                required: {
                  value: contractorCategory === 'confirmed' ? true : false,
                  errorMessage: 'Postcode is required',
                },
              }}
              value={values['postcode']}
              disabled={viewOnly}
            />
          </div>
        </div>

        {!viewOnly ? (
          <div className="contact-modal-buttons">
            <Button
              disabled={loading}
              loading={loading}
              content={isNew ? 'Add Contractor' : 'Save Changes'}
              size="small"
              type="submit"
              className="Primary-Button"
            />
          </div>
        ) : null}
      </AvForm>
    );
  }
}

class ContractorDelete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  deleteOrgContractorHandle() {
    const { deleteOrgContractor, selectedContractor, resetPage } = this.props;
    deleteOrgContractor(selectedContractor.id);
    if (resetPage) resetPage();
    this.toggle();
  }

  render() {
    const { modal } = this.state;
    const { selectedContractor } = this.props;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        onClose={() => this.toggle()}
        size="mini"
        trigger={
          <label className="org-users-actions-error first-down">Remove</label>
        }
        className="delete-contractor"
      >
        <Modal.Header className="modal-header-font">
          Delete Contractor - {selectedContractor.name}
        </Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this contractor?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.toggle()} className="Secondary-Button">
            No
          </Button>
          <Button
            onClick={() => this.deleteOrgContractorHandle()}
            className="Primary-Button"
            content="Yes"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export class ContractorUpdate extends React.PureComponent {
  //-------------------------this component is used in Activity Plan UI--------------
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { selectedContractor } = this.props;
    return (
      <div className="add-user">
        <Modal
          trigger={
            <label className="org-users-actions-warn first-down default-color ">
              Edit
            </label>
          }
          size="small"
          open={modal}
          onOpen={() => this.toggle()}
          closeIcon={
            <div
              className="new-activity-modal-close-icon"
              onClick={() => this.toggle()}
            >
              &times;
            </div>
          }
          className="edit-new-contractor"
        >
          <Modal.Header className="modal-header-font">
            Update Contractor{' '}
            {!isEmpty(selectedContractor.name.trim()) &&
              `- ${selectedContractor.name}`}
          </Modal.Header>
          <Modal.Content>
            <ContractorForm
              toggle={() => this.toggle()}
              isNew={false}
              {...this.props}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class ContractorView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { selectedContractor } = this.props;
    return (
      <div className="add-user">
        <Modal
          trigger={<label className="org-users-actions-warn">View</label>}
          size="small"
          open={modal}
          onOpen={() => this.toggle()}
          closeIcon={
            <div
              className="new-activity-modal-close-icon"
              onClick={() => this.toggle()}
            >
              &times;
            </div>
          }
          className="view-contractor"
        >
          <Modal.Header className="modal-header-font">
            View Contractor - {selectedContractor.name}
          </Modal.Header>
          <Modal.Content>
            <ContractorForm
              toggle={() => this.toggle()}
              isNew={false}
              viewOnly={true}
              {...this.props}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export class ContractorCreate extends React.PureComponent {
  //-------------------------this component is used in Activity Plan UI--------------
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { projectId, disabled } = this.props;
    return (
      <div className="add-contact-container">
        <Modal
          trigger={
            <Button
              disabled={disabled}
              className={
                projectId ? 'customColor-wrapper-contact' : 'Primary-Button'
              }
            >
              <Icon name="plus" />
              Add New Contractor
            </Button>
          }
          size="small"
          open={modal}
          onOpen={() => this.toggle()}
          closeIcon={
            <div
              className="new-activity-modal-close-icon"
              onClick={() => this.toggle()}
            >
              &times;
            </div>
          }
          className="add-new-contractor"
        >
          <Modal.Header className="modal-header-font">
            Add New Contractor
          </Modal.Header>
          <Modal.Content>
            <ContractorForm
              toggle={() => this.toggle()}
              isNew={true}
              {...this.props}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class OrganizationContractors extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
    };
  }

  resetPage() {
    this.setState({
      currentPage: 1,
    });
  }

  columns() {
    return [
      {
        title: '',
        render: (data) => {
          return (
            <div className="org-users-container">
              <label className="org-users-mainText">
                {data.companyName || '-'}
              </label>
              <label className="org-users-text">
                {data.occupationMinorGroup
                  ? data.occupationMinorGroup.name || '-'
                  : '-'}
              </label>
            </div>
          );
        },
      },
      {
        title: '',
        render: (data) => {
          return <label>{data.email || '-'}</label>;
        },
      },
      {
        title: '',
        render: (data) => {
          return <label>{data.phone || '-'}</label>;
        },
      },
      {
        title: '',
        render: (data) => {
          return (
            <div className="org-users-actions">
              <ContractorView selectedContractor={data} {...this.props} />
              <ContractorUpdate
                selectedContractor={data}
                resetPage={() => this.resetPage()}
                {...this.props}
              />
              <ContractorDelete
                selectedContractor={data}
                resetPage={() => this.resetPage()}
                {...this.props}
              />
            </div>
          );
        },
      },
    ];
  }

  async componentDidMount() {
    const { currentPage } = this.state;
    const { auth, getOrgContractorsList, getOccupationTypes, getOccupation } =
      this.props;
    if (!isEmpty(auth.user) && auth.user.organisation) {
      await getOrgContractorsList(currentPage);
    }
    getOccupationTypes();
    getOccupation();
  }

  pageChange(page) {
    const { auth, getOrgContractorsList } = this.props;
    this.setState(
      {
        currentPage: page,
      },
      () => {
        if (!isEmpty(auth.user) && auth.user.organisation) {
          getOrgContractorsList(page);
        }
      }
    );
  }

  render() {
    const { currentPage } = this.state;
    const { orgContractors, loading } = this.props.organization;
    return (
      <Segment className="content-segment org-users" loading={loading}>
        <Grid>
          <Grid.Row className="add-contact-org-row">
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={16}
              textAlign="right"
            >
              <ContractorCreate
                {...this.props}
                resetPage={() => this.resetPage()}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {orgContractors.docs && orgContractors.docs.length ? (
          <div className="org-table-container">
            <CustomTable
              columns={this.columns()}
              data={orgContractors.docs}
              customClass="org-users-table"
              pagination
              handlePaginationChange={(page) => this.pageChange(page)}
              page={currentPage}
              noOfPages={orgContractors.pages}
              keyExtractor={(item, index) => item.id}
            />
          </div>
        ) : (
          <EmptyContainer msg="No contractors have been added…" />
        )}
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    organization: state.organization,
    extra: state.extra,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrgContractorsList,
      getOccupationTypes,
      getOccupation,
      createOrgContractor,
      updateOrgContractor,
      deleteOrgContractor,
    },
    dispatch
  );
};

OrganizationContractors = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationContractors);

export { OrganizationContractors };
