import React, { PureComponent } from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import { isEmpty } from '../../../utils/lodash';

import './activity.scss';

export class Notes extends PureComponent {
  render() {
    const { isAnalysisField, analysis, reflection, evaluationPlans, isParent } =
      this.props;
    const normalEvaluation = evaluationPlans.filter((item) => {
      return item.methodId !== 9;
    });

    return (
      <Segment className="report-activity-seg">
        <div className="report-activity-div">
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={12}>
                {isAnalysisField &&
                analysis &&
                // no need to change is parent
                (isParent || !isEmpty(normalEvaluation)) ? (
                  <>
                    <div className="segment-custom-heading-evidence">
                      Evaluation Results Analysis{' '}
                    </div>
                    <p className="info-desc info-desc-last">{analysis}</p>
                  </>
                ) : null}

                <div className="segment-custom-heading-evidence">
                  Reflection{' '}
                </div>

                <p className="info-desc info-desc-last">
                  {reflection ? reflection : '-'}
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Segment>
    );
  }
}
