import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Segment, Grid } from 'semantic-ui-react';
import {
  map,
  find,
  findIndex,
  capitalize,
  isEmpty,
} from '../../../utils/lodash';
import './activity.scss';
import { ContentAndHeader, setParentDomains } from '../../../utils';
import { isParentProgramActivity } from '../../../utils/activity';

class TheoryOfChange extends PureComponent {
  render() {
    const {
      grant = false,
      description,
      aim,
      knowing,
      willDo,
      currentActivity,
      outcomes,
      actSummary,
    } = this.props;
    let parentDomains = [],
      usedOutcomes = [];

    if (
      !isEmpty(currentActivity) &&
      isParentProgramActivity(currentActivity.activityPlanTypeId)
    ) {
      if (!isEmpty(actSummary)) {
        if (actSummary['completed'] !== undefined) {
          for (const data in actSummary.completed.outcomes) {
            usedOutcomes.push(data);
          }
        }
      }
      parentDomains = map(setParentDomains(currentActivity), (domains) => {
        const getOutcomes = [];
        if (outcomes) {
          for (const outcome of currentActivity.parentOutcomes) {
            if (outcome.policyValue === domains.policyId) {
              const getOutcome = find(outcomes, (o) => {
                return o.id === outcome.outcomeId;
              });
              if (getOutcome) {
                getOutcomes.push(getOutcome.title);
              }
            }
          }
        }
        return {
          domain: domains.policy.policyName,
          outcomes: getOutcomes,
        };
      });
    }
    const [firstDomain = {}, ...restDomains] = parentDomains;

    return (
      <Segment className="report-activity-seg">
        <div className="report-activity-div">
          {grant ? (
            <Fragment>
              <Grid className="report-theory-grid">
                <Grid.Row className="row-spacing">
                  <Grid.Column
                    className="col-spacing"
                    mobile={8}
                    tablet={8}
                    computer={16}
                  >
                    <div className="primary-title">Primary Objective</div>
                  </Grid.Column>
                  <Grid.Column
                    className="col-spacing"
                    mobile={8}
                    tablet={8}
                    computer={6}
                  >
                    <div className="header-spacing">{firstDomain.domain}</div>
                    {map(firstDomain.outcomes, (outcome, i) => {
                      return (
                        <p key={i} className="report-objective-list">
                          {outcome}
                          {/* {usedOutcomes.includes(outcome) ? (
                            <i
                              aria-hidden='true'
                              className='check tiny circular inverted icon green'
                            />
                          ) : null} */}
                        </p>
                      );
                    })}
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row className="row-spacing">
                  <Grid.Column
                    className="col-spacing"
                    mobile={8}
                    tablet={8}
                    computer={16}
                  >
                    <div className="secondary-title">Secondary Objectives</div>
                  </Grid.Column>
                  {map(restDomains, (domains, d) => {
                    if (isEmpty(domains.outcomes)) return null;
                    return (
                      <Grid.Column
                        className="col-spacing"
                        key={d}
                        mobile={8}
                        tablet={8}
                        computer={8}
                      >
                        <div className="header-spacing">{domains.domain} </div>
                        {map(domains.outcomes, (outcome, i) => {
                          return (
                            <p key={i} className="report-objective-list">
                              {outcome}
                            </p>
                            // <span key={i} className='c-content-outcome'>
                            //   {usedOutcomes.includes(outcome) ? (
                            //     <i
                            //       aria-hidden='true'
                            //       className='check tiny circular inverted icon green'
                            //     />
                            //   ) : null}
                            // </span>
                          );
                        })}
                      </Grid.Column>
                    );
                  })}
                </Grid.Row>
                <Grid.Row className="row-spacing">
                  <Grid.Column
                    className="theory-col-spacing"
                    mobile={16}
                    tablet={16}
                    computer={12}
                  >
                    <div className="header-spacing-no-margin">
                      Theory of Change
                    </div>
                    <div>
                      <strong>
                        {grant ? 'Aiming for outcomes in the ' : 'Aiming for '}
                      </strong>
                      {aim ? aim : '-'}
                      <strong>
                        {grant ? ' domains and knowing ' : ' and knowing '}
                      </strong>
                      {knowing
                        ? knowing.charAt(0).toLowerCase() +
                          knowing.substring(1).replace(/\.$/g, '')
                        : '-'}
                      <strong> we will </strong>
                      {willDo
                        ? willDo.charAt(0).toLowerCase() +
                          willDo.substring(1).replace(/\.$/g, '')
                        : '-'}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Fragment>
          ) : (
            <Fragment>
              <Grid>
                <Grid.Row>
                  <p className="info-desc-sixteen">
                    {description ? capitalize(description) : '-'}
                  </p>
                </Grid.Row>

                <Grid.Row className="intended-outcomes-row-spacing">
                  <Grid.Column
                    mobile={16}
                    tablet={14}
                    computer={12}
                    className="intended-outcomes-col-spacing"
                  >
                    <ContentAndHeader title="Theory of Change">
                      <div className="theory-of-change-report-objective">
                        <strong>Aiming for </strong>
                        {aim ? aim : '-'}
                        <strong> and knowing </strong>
                        {knowing
                          ? knowing.charAt(0).toLowerCase() +
                            knowing.substring(1).replace(/\.$/g, '')
                          : '-'}
                        <strong> we will </strong>
                        {willDo
                          ? willDo.charAt(0).toLowerCase() +
                            willDo.substring(1).replace(/\.$/g, '')
                          : '-'}
                      </div>
                    </ContentAndHeader>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Fragment>
          )}

          <Grid>
            <Grid.Row>
              <Grid.Column
                className={!grant ? '' : 'evidence-types-used-grants-col'}
              >
                <ContentAndHeader title="Evidence Types Used" />
                {this.getEvidence()}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Segment>
    );
  }

  getEvidence() {
    let types = this.props.evidenceTypes;
    let evidence = this.props.evidence;
    let usedTypes = [];

    types.forEach((element) => {
      if (findIndex(evidence, { evidenceType: parseInt(element.id) }) >= 0) {
        if (findIndex(usedTypes, { type: element.evidenceType }) === -1) {
          usedTypes.push({
            type: element.evidenceType,
            status: true,
          });
        }
      } else {
        if (findIndex(usedTypes, { type: element.evidenceType }) === -1) {
          usedTypes.push({
            type: element.evidenceType,
            status: false,
          });
        }
      }
    });

    return map(usedTypes, (item, index) =>
      usedTypes.length - 1 === index ? (
        <div className="info-desc evidence-types-no-margin" key={index}>
          {item.status ? (
            <i
              aria-hidden="true"
              className="check tiny circular inverted icon green"
            />
          ) : (
            <i
              aria-hidden="true"
              className="close tiny circular inverted icon red"
            />
          )}
          <div className="ml-1">{item.type}</div>
        </div>
      ) : (
        <div
          className="info-desc info-desc-last evidence-types-no-margin"
          key={index}
        >
          {item.status ? (
            <i
              aria-hidden="true"
              className="check tiny circular inverted icon green"
            />
          ) : (
            <i
              aria-hidden="true"
              className="close tiny circular inverted icon red"
            />
          )}
          <div className="ml-1">{item.type}</div>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  const { report } = state;
  return {
    actSummary: report.actSummary,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

TheoryOfChange = connect(mapStateToProps, mapDispatchToProps)(TheoryOfChange);

export { TheoryOfChange };
