import moment from 'moment';
import React from 'react';
import { Segment, Grid, Icon } from 'semantic-ui-react';
import { isEmpty, map, get } from '../../utils/lodash';

import { EmptyContainer } from '../../components';
import { ViewNotification } from '../organization/forms';

export class RecentActivitiesSegment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      notifications: null,
      page: 1,
    };
  }

  handlePaginationChange(activePage) {
    const { getNotificationsAll } = this.props;
    activePage = activePage + 1;
    this.setState(
      {
        page: activePage,
      },

      () => {
        getNotificationsAll(activePage);
      }
    );
  }

  async deleteNotification(id) {
    const { deleteUserNotification } = this.props;
    await deleteUserNotification(id);
  }

  render() {
    const {
      userNotificationsAll = {},
      loading,
      updateNotificationReadStatus,
    } = this.props;

    const { page = 1 } = this.state;

    return (
      <>
        <Segment
          className="content-segment-dashboard-recent-activity"
          loading={loading}
        >
          {isEmpty(userNotificationsAll.docs) ? (
            <EmptyContainer msg="No notification found…" />
          ) : (
            <Segment.Group className="recent-activity-segments">
              {map(userNotificationsAll.docs, (item, i) => {
                const senderName = `${get(
                  item,
                  'notification.sender.firstName',
                  ''
                )} ${get(item, 'notification.sender.surname', '')}`;
                return (
                  <Segment
                    key={i}
                    className={
                      item.isRead
                        ? 'notify-info-custom'
                        : 'ui-segment-custom notify-info-custom'
                    }
                  >
                    {' '}
                    <ViewNotification
                      records={item}
                      trigger={
                        <Grid.Column mobile={14} tablet={11} computer={10}>
                          <div className="notify-info-main">
                            <div className="notify-info-main-icon-wrap">
                              {!item.isRead && (
                                <Icon
                                  name="circle"
                                  size="tiny"
                                  color="blue"
                                  className="notify-info-main-icon"
                                />
                              )}
                            </div>
                            <div>
                              <label className="notify-info">
                                <strong className="Body-Large-Semibold">
                                  {senderName}
                                </strong>{' '}
                                {get(item, 'notification.title', '-')}{' '}
                              </label>
                              <br></br>
                              <label className="recent-activity-date">
                                {moment(item.createdAt).format(
                                  'DD MMM YYYY, hh:mm a'
                                )}
                              </label>
                            </div>
                          </div>
                        </Grid.Column>
                      }
                      updateNotificationReadStatus={(id) =>
                        updateNotificationReadStatus(id, true)
                      }
                    />
                    {item.isRead && (
                      <Grid.Column mobile={2} tablet={5} computer={6}>
                        <div className="dashboard-notification">
                          <Icon
                            circular
                            name="close"
                            size="small"
                            className="notify-dashboard-main-icon"
                            onClick={() => this.deleteNotification(item.id)}
                          />
                          {/*  */}
                        </div>
                      </Grid.Column>
                    )}
                  </Segment>
                );
              })}
              {page >= userNotificationsAll.pages ? null : (
                <Segment className="see-more-todo">
                  <div
                    className="see-more-todo-text"
                    onClick={() => this.handlePaginationChange(page)}
                  >
                    See More
                  </div>
                </Segment>
              )}
            </Segment.Group>
          )}
        </Segment>
      </>
    );
  }
}

export default RecentActivitiesSegment;
