import './report.scss';

import React, { Fragment } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Segment, Table } from 'semantic-ui-react';

import { EmptyContainer } from '../../../components';
import { get, isEmpty, map } from '../../../utils/lodash';
import { setValue } from '../../../utils';
import {
  ORGANISATION_REPORT_LOADING,
  PROGRAM_REPORT_LOADING,
} from '../../../utils/constant';

class InputOutputReport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setTableColumns(dataset) {
    if (isEmpty(dataset)) return null;

    const { currencyType } = this.props;
    return map(dataset, (item, i) => {
      // if (item.data.value === 0) return null;
      if (isEmpty(dataset)) return null;
      return (
        <Fragment key={`headline_data_${i}`}>
          <Table.Row
            key={i}
            className={isEmpty(item.subItems) ? 'border-sep' : 'no-border'}
          >
            <Table.Cell>
              <div className="headline-input-row">
                <div className="height-col">
                  {item.input || item.output || '-'}
                </div>
              </div>
            </Table.Cell>

            <Table.Cell>
              <div className="headline-input-row-data">
                <div className="height-col">
                  {' '}
                  {setValue(item.data, get(currencyType, 'sign', '$')) || '-'}
                </div>
              </div>
            </Table.Cell>
            <Table.Cell>
              <div className="height-col">{item.description || '-'}</div>
            </Table.Cell>
          </Table.Row>
          {!isEmpty(item.subItems) &&
            map(item.subItems, (subItem, k) => (
              <Table.Row key={k} className="no-border">
                <Table.Cell>
                  <div className="sub-inp-new-names">{subItem.input}</div>
                </Table.Cell>
                <Table.Cell>
                  <div className="headline-input-row-data">
                    <div className="height-col">
                      {' '}
                      {setValue(subItem.data, get(currencyType, 'sign', '$')) ||
                        '-'}
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div>{subItem.description}</div>
                </Table.Cell>
              </Table.Row>
            ))}
        </Fragment>
      );
    });
  }

  render() {
    const { inputOutputData, inputOutputReportLoading, parent } = this.props;
    const loaderValue = parent
      ? PROGRAM_REPORT_LOADING.INPUT_OUTPUT_SUMMARY_LOADING
      : ORGANISATION_REPORT_LOADING.INPUT_OUTPUT_SUMMARY_LOADING;
    const loading = get(inputOutputReportLoading, `${loaderValue}`, false);
    return (
      <Segment loading={loading}>
        <Grid className="headline-data-grid" padded>
          <Grid.Row>
            <Grid.Column>
              <label className="headline-holder">Inputs</label>
            </Grid.Column>
          </Grid.Row>
          {/* Inputs Table */}
          <div className="table-holder">
            <Table stackable basic="very" className="inputs-table">
              <Table.Header className="inputs-table-head">
                <Table.Row>
                  <Table.HeaderCell width={6}>Name</Table.HeaderCell>
                  <Table.HeaderCell width={4}>
                    <div className="header-data-outcome">Data</div>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={6}>Note</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {isEmpty(inputOutputData) ? null : isEmpty(
                    inputOutputData.inputs ? inputOutputData.inputs : {}
                  ) ? (
                  <Table.Row>
                    <Table.Cell colSpan={3}>
                      <EmptyContainer msg="No Inputs found..." />
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  this.setTableColumns(inputOutputData.inputs)
                )}
              </Table.Body>
            </Table>
          </div>
        </Grid>
        <Grid className="headline-data-grid" padded>
          <Grid.Row>
            <Grid.Column>
              <label className="headline-holder">Outputs</label>
            </Grid.Column>
          </Grid.Row>
          {/* Outputs Table */}
          <div className="table-holder">
            <Table stackable basic="very" className="outputs-table">
              <Table.Header className="outputs-table-head">
                <Table.Row>
                  <Table.HeaderCell width={6}>Name</Table.HeaderCell>
                  <Table.HeaderCell width={4}>
                    <div className="header-data-outcome">Data</div>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={6}>Note</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {isEmpty(inputOutputData) ? null : isEmpty(
                    inputOutputData.outputs ? inputOutputData.outputs : {}
                  ) ? (
                  <Table.Row>
                    <Table.Cell colSpan={3}>
                      <EmptyContainer msg="No Outputs found..." />
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  this.setTableColumns(inputOutputData.outputs)
                )}
              </Table.Body>
            </Table>
          </div>
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inputOutputData: state.report.inputOutputData,
    inputOutputReportLoading: state.report.inputOutputReportLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

InputOutputReport = connect(
  mapStateToProps,
  mapDispatchToProps
)(InputOutputReport);

export { InputOutputReport };
