import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  isEmpty,
  cloneDeep,
  debounce,
  isString,
  get,
  includes,
} from '../../../../utils/lodash';
import '../activity.scss';
import {
  AvForm,
  AvField,
  AvCheckbox,
  AvCheckboxGroup,
} from 'availity-reactstrap-validation';
import { NotificationManager } from 'react-notifications';
import { Grid, Search, Table, Button, Divider } from 'semantic-ui-react';
import { CustomTooltip } from '../../../../components';
import { RESTRICTION_TYPES, permissionTypes } from '../../../../utils/constant';
import {
  addActivityMember,
  updateActivityMember,
  getOrgMembers,
} from '../../../../store/actions';
import { getPermissionType } from '../../../../utils/activity/userPermission';

class ActivityMember extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: [],
      searchQuery: '',
      form: {
        firstName: '',
        surName: '',
        email: '',
        message: '',
      },
      permissionType: undefined,
      radioType: undefined,
      collaborator: false,
      documentor: false,
      reportOnly: false,
      permissions: [],
      permissionError: false,
      dropdownValuePermission: '',
      isCustomNotEmpty: true, //false means empty
      isPermissionLabelVisible: false,
    };
  }

  componentDidMount() {
    const { editPermission, participant, inviteUser } = this.props;

    let permissionArray = [];
    if (editPermission) {
      if (isString(participant.permissions)) {
        participant.permissions = JSON.parse(participant.permissions);
      }
      permissionArray = participant.permissions;
    } else {
      permissionArray = permissionTypes.map((permissionType) => {
        return {
          name: permissionType.title,
          permission: {
            read: false,
            write: false,
          },
          state: permissionType.state,
        };
      });
    }
    this.setState({
      permissionArray,
      dropdownValuePermission: inviteUser
        ? ''
        : getPermissionType(permissionArray),
    });
  }

  componentWillMount() {
    this.resetComponent();
  }

  resetComponent = () =>
    this.setState({
      isLoading: false,
      data: [],
      searchQuery: '',
    });

  handleResultSelect = (user) => {
    if (isEmpty(user)) {
      return false;
    } else {
      this.setState(
        {
          form: {
            firstName: user.firstName,
            surname: user.surname,
            email: user.email,
          },
        },
        () => {
          this.form && this.form.reset();
        }
      );
    }
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, searchQuery: value }, async () => {
      try {
        const { getOrgMembers, currentActivity } = this.props;
        await getOrgMembers(value);
        let { orgMembers } = this.props;
        setTimeout(async () => {
          if (this.state.searchQuery.length < 1) return this.resetComponent();
          // orgMembers = map(orgMembers, member => {
          //   const exist = find(
          //     actMembers,
          //     actMember => member.email === actMember.user.email
          //   );
          //   if (!exist) return member;
          // });
          this.setState({
            isLoading: false,
            data: orgMembers || [],
          });
        }, 300);
      } catch (error) {
        // console.error('handleSearchChange -> error', error);
      }
    });
  };

  async handlePermissionSubmit() {
    try {
      const { permissionArray, isCustomNotEmpty } = this.state;
      const { updateActivityMember, participant } = this.props;

      if (isCustomNotEmpty) {
        await updateActivityMember(participant, permissionArray);
        this.setState(
          {
            isLoading: false,
            data: [],
            searchQuery: '',
            form: {
              firstName: '',
              surName: '',
              email: '',
              message: '',
            },
            isCustomNotEmpty: true, //false means empty
            isPermissionLabelVisible: false,
          },
          () => {
            NotificationManager.success(
              'Participant permission update success',
              'Permission Changed'
            );
            this.props.toggle();
          }
        );
      } else {
        this.setState({
          isPermissionLabelVisible: true,
        });
      }
    } catch (error) {
      NotificationManager.error(
        'We couldnt change the permissions',
        'Something went wrong'
      );
    }
  }

  handleInvalidSubmit() {
    if (!this.state.permissionType) {
      this.setState({ permissionError: true });
    }
  }

  async handleValidSubmit(event, values) {
    const { addActivityMember, currentActivity } = this.props;
    try {
      if (!this.state.permissionType) {
        this.setState({ permissionError: true });
        return false;
      }
      values.projectId = currentActivity.id;
      values.permissions = this.state.permissionArray;

      await addActivityMember(values);
      this.setState(
        {
          isLoading: false,
          data: [],
          searchQuery: '',
          form: {
            firstName: '',
            surName: '',
            email: '',
            message: '',
          },
        },
        () => {
          this.form && this.form.reset();
          NotificationManager.success(
            'User added to the activity',
            'Participant adding success'
          );
          this.props.toggle();
        }
      );
    } catch (error) {
      NotificationManager.error(
        'We couldnt add the participant',
        'Something went wrong'
      );
    }
  }

  selectPermissionType(value, name, checked) {
    if (value === 'collaborator') {
      let permissionArray = permissionTypes.map((permissionType) => {
        return {
          name: permissionType.title,
          permission: {
            read: true,
            write: RESTRICTION_TYPES.includes(permissionType.state)
              ? false
              : true,
          },
          state: permissionType.state,
        };
      });
      this.setState({
        permissionArray,
        permissionType: 'Collaborator',
        radioType: value,
        permissionError: false,
        dropdownValuePermission: 'collaborator',
        isCustomNotEmpty: true,
        isPermissionLabelVisible: false,
      });
    } else if (value === 'documentor') {
      let permissionArray = permissionTypes.map((permissionType) => {
        return {
          name: permissionType.title,
          permission: {
            read: permissionType.documentor ? true : false,
            write: permissionType.documentor
              ? RESTRICTION_TYPES.includes(permissionType.state)
                ? false
                : true
              : false,
          },
          state: permissionType.state,
        };
      });
      this.setState({
        permissionArray,
        permissionType: 'Documentor',
        radioType: value,
        permissionError: false,
        dropdownValuePermission: 'documentor',
        isCustomNotEmpty: true,
        isPermissionLabelVisible: false,
      });
    } else if (value === 'reportOnly') {
      let permissionArray = permissionTypes.map((permissionType) => {
        return {
          name: permissionType.title,
          permission: {
            read: permissionType.reportOnly ? true : false,
            write: permissionType.reportOnly
              ? RESTRICTION_TYPES.includes(permissionType.state)
                ? false
                : true
              : false,
          },
          state: permissionType.state,
        };
      });
      this.setState({
        permissionArray,
        permissionType: 'Report Only',
        radioType: value,
        permissionError: false,
        dropdownValuePermission: 'reportOnly',
        isCustomNotEmpty: true,
        isPermissionLabelVisible: false,
      });
    } else if (value === 'custom') {
      let permissionArray = permissionTypes.map((permissionType) => {
        return {
          name: permissionType.title,
          permission: {
            read: false,
            write: false,
          },
          state: permissionType.state,
        };
      });

      let isCustomEmpty = permissionArray.map((item) => {
        return item.permission.read === false && item.permission.write === false
          ? true
          : false;
      });

      this.setState({
        permissionArray,
        permissionType: 'Custom',
        radioType: value,
        permissionError: false,
        dropdownValuePermission: 'custom',
        isCustomNotEmpty: includes(isCustomEmpty, false), //false means empty
        isPermissionLabelVisible: false,
      });
    }
  }

  selectPermissionOption(checked, value, id, type) {
    this.setState((preState) => {
      let permissionArray = cloneDeep(preState.permissionArray);
      if (!checked && type === 'read') {
        permissionArray[id].permission.write = false;
      }
      permissionArray[id].permission[type] =
        !preState.permissionArray[id].permission[type];

      let dropdownValue = getPermissionType(permissionArray);

      let isCustomEmpty = permissionArray.map((item) => {
        return item.permission.read === false && item.permission.write === false
          ? true
          : false;
      });

      return {
        ...preState,
        permissionArray: permissionArray,
        permissionType: 'Custom',
        radioType: undefined,
        permissionError: false,
        dropdownValuePermission: dropdownValue,
        isCustomNotEmpty: includes(isCustomEmpty, false),
        isPermissionLabelVisible: false,
      };
    });
  }

  renderDropdown = () => {
    const {
      permissionArray,
      permissionError,
      dropdownValuePermission,
      isPermissionLabelVisible,
    } = this.state;

    const { editPermission, actPut, user, helpTips, participant } = this.props;
    return (
      <AvForm
        className="permission-form invite-user-modal-custom"
        onValidSubmit={() => this.handlePermissionSubmit()}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="new-member-segment-custom">
                <CustomTooltip
                  state={get(user, 'options.tips')}
                  contentObject={{
                    data: helpTips,
                    key: '125',
                  }}
                  position="top left"
                  wide="very"
                >
                  <label>Permission</label>
                </CustomTooltip>
                <AvField
                  type="select"
                  name="permission" //this will be deleted in handle valid submit
                  // label="REPORT TYPE"
                  // className="report-selector"
                  className={`permission-dropdown-custom ${
                    permissionError ? 'invalid-input ' : ' '
                  }`}
                  // required
                  onChange={(e) =>
                    this.selectPermissionType(
                      e.target.value,
                      e.target.name,
                      e.target.checked
                    )
                  }
                  value={dropdownValuePermission}
                  disabled={get(participant, 'isProgramCollaborator', false)}
                >
                  <option value="" defaultValue disabled>
                    Select permission type
                  </option>
                  <option value="collaborator" name="collaborator">
                    Collaborator
                  </option>
                  <option value="documentor" name="documentor">
                    Documentor
                  </option>
                  <option value="reportOnly" name="reportOnly">
                    Report Only
                  </option>
                  <option value="custom" name="custom">
                    Custom
                  </option>
                </AvField>
              </div>
            </Grid.Column>

            <Table className="table-edit-custom-participant">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <span className="activity-section-cell-custom">
                      Activity Section
                    </span>{' '}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <span className="empty-cell-space-outer-custom">
                      Read
                      <label className="empty-cell-space-custom"></label>
                      Write
                    </span>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {permissionTypes.map((permissionType, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>{permissionType.title}</Table.Cell>
                      <Table.Cell>
                        <AvCheckboxGroup inline name={permissionType.title}>
                          <AvCheckbox
                            onChange={(e) =>
                              this.selectPermissionOption(
                                e.target.checked,
                                e.target.value,
                                permissionType.id - 1,
                                'read'
                              )
                            }
                            // label="Read"
                            value={permissionType.title}
                            checked={
                              permissionArray
                                ? permissionArray[permissionType.id - 1]
                                    .permission.read
                                : false
                            }
                            disabled={get(
                              participant,
                              'isProgramCollaborator',
                              false
                            )}
                          />
                          {RESTRICTION_TYPES.includes(
                            permissionType.state
                          ) ? null : (
                            <AvCheckbox
                              onChange={(e) =>
                                this.selectPermissionOption(
                                  e.target.checked,
                                  e.target.value,
                                  permissionType.id - 1,
                                  'write'
                                )
                              }
                              // label="Write"
                              value={permissionType.title}
                              checked={
                                permissionArray
                                  ? permissionArray[permissionType.id - 1]
                                      .permission.write
                                  : false
                              }
                              disabled={
                                permissionArray
                                  ? !permissionArray[permissionType.id - 1]
                                      .permission.read
                                  : false
                              }
                            />
                          )}
                        </AvCheckboxGroup>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            {isPermissionLabelVisible ? (
              <div className="invalid-permission-custom">
                Please set permissions for the activity member
              </div>
            ) : null}
          </Grid.Row>
          <Grid.Column
            // mobile={16}
            // tablet={16}
            // computer={16}
            width={16}
            floated="right"
          >
            {editPermission ? (
              <Button
                type="submit"
                className="Primary-Button"
                disabled={actPut.activityMembers ? true : false}
                loading={actPut.activityMembers ? true : false}
              >
                <div>Update Permissions</div>
              </Button>
            ) : null}
          </Grid.Column>
        </Grid>
      </AvForm>
    );
  };

  renderParticipantDropdown = () => {
    const {
      permissionArray,
      permissionError,
      dropdownValuePermission,
      isPermissionLabelVisible,
    } = this.state;

    const { editPermission, actPut, user, helpTips } = this.props;
    return (
      <AvForm
        className="permission-form-custom"
        onValidSubmit={() => this.handlePermissionSubmit()}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="new-member-segment-custom-participant">
                <CustomTooltip
                  state={get(user, 'options.tips')}
                  contentObject={{
                    data: helpTips,
                    key: '125',
                  }}
                  position="top left"
                  wide="very"
                >
                  <label className="permission-edit">Permission</label>
                </CustomTooltip>
                <AvField
                  type="select"
                  name="permission" //this will be deleted in handle valid submit
                  // label="REPORT TYPE"
                  // className="report-selector"
                  className={`permission-dropdown-custom ${
                    permissionError ? 'invalid-input ' : ' '
                  }`}
                  // required
                  onChange={(e) =>
                    this.selectPermissionType(
                      e.target.value,
                      e.target.name,
                      e.target.checked
                    )
                  }
                  value={dropdownValuePermission}
                >
                  <option value="" defaultValue disabled>
                    Select permission type
                  </option>
                  <option value="collaborator" name="collaborator">
                    Collaborator
                  </option>
                  <option value="documentor" name="documentor">
                    Documentor
                  </option>
                  <option value="reportOnly" name="reportOnly">
                    Report Only
                  </option>
                  <option value="custom" name="custom">
                    Custom
                  </option>
                </AvField>
              </div>
            </Grid.Column>

            <Table className="table-edit-custom-participant">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <span className="activity-section-cell-custom">
                      Activity Section
                    </span>{' '}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <span className="empty-cell-space-outer-custom">
                      Read
                      <label className="empty-cell-space-custom"></label>
                      Write
                    </span>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {permissionTypes.map((permissionType, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>{permissionType.title}</Table.Cell>
                      <Table.Cell>
                        <AvCheckboxGroup inline name={permissionType.title}>
                          <AvCheckbox
                            onChange={(e) =>
                              this.selectPermissionOption(
                                e.target.checked,
                                e.target.value,
                                permissionType.id - 1,
                                'read'
                              )
                            }
                            // label="Read"
                            value={permissionType.title}
                            checked={
                              permissionArray
                                ? permissionArray[permissionType.id - 1]
                                    .permission.read
                                : false
                            }
                          />
                          {RESTRICTION_TYPES.includes(
                            permissionType.state
                          ) ? null : (
                            <AvCheckbox
                              onChange={(e) =>
                                this.selectPermissionOption(
                                  e.target.checked,
                                  e.target.value,
                                  permissionType.id - 1,
                                  'write'
                                )
                              }
                              // label="Write"
                              value={permissionType.title}
                              checked={
                                permissionArray
                                  ? permissionArray[permissionType.id - 1]
                                      .permission.write
                                  : false
                              }
                              disabled={
                                permissionArray
                                  ? !permissionArray[permissionType.id - 1]
                                      .permission.read
                                  : false
                              }
                            />
                          )}
                        </AvCheckboxGroup>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            {isPermissionLabelVisible ? (
              <div className="invalid-permission-custom">
                Please set permissions for the activity member
              </div>
            ) : null}
          </Grid.Row>
          <Grid.Column
            // mobile={16}
            // tablet={16}
            // computer={16}
            width={7}
            floated="right"
          >
            {editPermission ? (
              <Button
                type="submit"
                className="permission-edit-button"
                disabled={actPut.activityMembers ? true : false}
                loading={actPut.activityMembers ? true : false}
              >
                <div>Update Permissions</div>
              </Button>
            ) : null}
          </Grid.Column>
        </Grid>
      </AvForm>
    );
  };

  render() {
    const {
      isLoading,
      data,
      searchQuery,
      form,
      permissionError,
      isPermissionLabelVisible,
    } = this.state;
    const { user, helpTips, editPermission, actPut, currentActivity } =
      this.props;
    return (
      <div className="act-member-segment">
        <Grid>
          <Grid.Row className="act-member-row-custom">
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={16}
              className="add-member-column"
            >
              {editPermission ? (
                <div className={editPermission ? null : 'permission-pop-up'}>
                  {this.renderDropdown()}
                </div>
              ) : (
                <>
                  <div className="custom-segment">
                    <CustomTooltip
                      state={get(user, 'options.tips')}
                      contentObject={{
                        data: helpTips,
                        key: '90',
                      }}
                      position="top left"
                      wide="very"
                    >
                      <label className="participants-title-label">
                        Invite organisation members to participate in the
                        activity
                      </label>
                    </CustomTooltip>
                    <label className="search-label-participants">
                      Search for existing users in the organisation{' '}
                    </label>
                    <Search
                      className="search-bar-invite-user"
                      loading={isLoading}
                      placeholder="Search"
                      onResultSelect={(e, { result }) =>
                        this.handleResultSelect(result)
                      }
                      onSearchChange={debounce(this.handleSearchChange, 300, {
                        leading: true,
                      })}
                      results={data}
                      value={searchQuery}
                      resultRenderer={({ name }) => <label>{name}</label>}
                      disabled={currentActivity.statusId >= 2 ? true : false}
                      // {...this.props}
                      noResultsMessage={
                        <label>
                          No contractor found.
                          <span> Add New Contractor?</span>
                        </label>
                      }
                    />
                  </div>

                  <Divider section />
                  {/* <div className="new-member-segment-custom-participant"> */}
                  <AvForm
                    ref={(c) => (this.form = c)}
                    onValidSubmit={(event, values) =>
                      this.handleValidSubmit(event, values)
                    }
                    onInvalidSubmit={() => this.handleInvalidSubmit()}
                    model={form}
                    autoComplete="off"
                    className="invite-user-modal-custom"
                  >
                    <Grid>
                      <Grid.Row className="column-space">
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                          <AvField
                            name="firstName"
                            type="text"
                            label={
                              <div className="participant-input-label">
                                First Name
                              </div>
                            }
                            className="input-fields-radius"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'First name is required',
                              },
                            }}
                            autoComplete="off"
                          />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                          <AvField
                            name="surname"
                            type="text"
                            label={
                              <div className="participant-input-label">
                                Surname
                              </div>
                            }
                            className="input-fields-radius"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'Surname is required',
                              },
                            }}
                            autoComplete="off"
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <AvField
                      name="email"
                      type="email"
                      label={
                        <div className="participant-input-label">Email</div>
                      }
                      className="input-fields-radius"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Email is required',
                        },
                      }}
                      autoComplete="off"
                    />
                    <AvField
                      name="message"
                      type="textarea"
                      className="input-fields-radius-msg"
                      label={
                        <CustomTooltip
                          state={get(user, 'options.tips')}
                          contentObject={{
                            data: helpTips,
                            key: '88',
                          }}
                          position="top left"
                          wide="very"
                        >
                          <div className="participant-input-label">
                            Message for guest user
                          </div>
                        </CustomTooltip>
                      }
                      rows="4"
                      validate={{
                        required: {
                          value: false,
                        },
                      }}
                    />

                    {this.renderParticipantDropdown()}

                    {permissionError || isPermissionLabelVisible ? (
                      <div className="invalid-permission-activity-participant ">
                        Please set permissions for new activity member
                      </div>
                    ) : null}
                    <div className="submit-button-right-align">
                      <Button
                        type="submit"
                        className="Primary-Button"
                        disabled={actPut.activityMembers ? true : false}
                        loading={actPut.activityMembers ? true : false}
                      >
                        <CustomTooltip
                          state={get(user, 'options.tips')}
                          contentObject={{
                            data: helpTips,
                            key: '89',
                          }}
                          position="top left"
                          wide="very"
                        >
                          <div>Invite</div>
                        </CustomTooltip>
                      </Button>
                    </div>
                  </AvForm>
                  {/* </div> */}
                </>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    user: state.authentication.user,
    helpTips: state.extra.activityHelpTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addActivityMember,
      updateActivityMember,
      getOrgMembers,
    },
    dispatch
  );
};

ActivityMember = connect(mapStateToProps, mapDispatchToProps)(ActivityMember);

export { ActivityMember };
