/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState } from 'react';

import {
  Grid,
  Segment,
  Dropdown,
  Modal,
  Icon,
  Message,
} from 'semantic-ui-react';
import moment from 'moment';
import { ContentAndHeader } from '../../../../../../utils';
import { get, find, capitalize } from '../../../../../../utils/lodash';

const ObjectiveView = (props) => {
  const { activity, index, outcomes, policies, outcomeId } = props;
  const [toggle, setToggle] = useState(false);
  const objective = get(activity, `objective${index}`, null);
  const knowing = get(activity, `knowing${index}`, null);
  const willDo = get(activity, `weWill${index}`, null);
  const outcome = find(
    outcomes,
    (o) => o.id === get(activity, outcomeId, null)
  );

  if (!outcome) {
    return (
      <Fragment>
        <Message negative>
          <Message.Header>
            We're sorry we can't find the outcome provided for "{objective}".
          </Message.Header>
          <p>Please contact support.</p>
        </Message>
      </Fragment>
    );
  }

  const getOutcome = () => {
    const getPolicy = find(policies, (o) => o.id === outcome.policyId);
    return get(getPolicy, 'policyName', '-');
  };

  return (
    <Segment.Group
      raised
      style={{
        marginTop: '2.857rem',
      }}
    >
      <Segment
        className="custom-segment projected-activity-modal-header pointer"
        onClick={() => setToggle(!toggle)}
      >
        <div>
          <label className="projected-activity-modal-heading pointer">
            {`Objective - Intended Outcomes (${getOutcome()})`}
          </label>
        </div>
        <div className="projected-activity-modal-toggle">
          {toggle ? (
            <Icon name="angle up" size="large" />
          ) : (
            <Icon name="angle down" size="large" />
          )}
        </div>
      </Segment>
      {toggle ? (
        <Segment className="report-activity-seg">
          <Grid>
            <Grid.Row>
              <Grid.Column
                computer={16}
                className="projected-activity-modal-margins"
              >
                <div className="report-activity-div projected-info-text">
                  <div className="info-desc">
                    <p className="projected-info-text">
                      {capitalize(objective ? objective : '-')}
                    </p>
                  </div>
                  <ContentAndHeader title="Theory of Change">
                    <strong style={{ fontWeight: 'bold' }}>
                      {get(outcome, 'aiming', 'Aiming for ')}
                    </strong>
                    {get(outcome, 'contentFormat', '-')}
                    <strong style={{ fontWeight: 'bold' }}>
                      {get(outcome, 'knowing', ' and knowing ')}
                    </strong>
                    {knowing
                      ? knowing.charAt(0).toLowerCase() +
                        knowing.substring(1).replace(/\.$/g, '')
                      : '-'}
                    <strong style={{ fontWeight: 'bold' }}> we will </strong>
                    {willDo
                      ? willDo.charAt(0).toLowerCase() +
                        willDo.substring(1).replace(/\.$/g, '')
                      : '-'}
                  </ContentAndHeader>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      ) : null}
    </Segment.Group>
  );
};

export default class ProjectedActivityView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      toggle: true,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  getPeriod() {
    const {
      activity: { activityStartDate, activityEndDate },
    } = this.props;
    let start = moment(activityStartDate).format('DD MMM YYYY');
    let end = moment(activityEndDate).format('DD MMM YYYY');
    if (!activityStartDate) {
      start = '-';
    }
    if (!activityEndDate) {
      end = '-';
    }
    let period = start + ' to ' + end;
    if (!activityStartDate && !activityEndDate) {
      period = '-';
    }
    return period;
  }

  render() {
    const { modal, toggle } = this.state;
    const { activity, currentActivity } = this.props;
    return (
      <Modal
        className="projected-linked-activity"
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <div
            className="new-activity-modal-close-icon"
            onClick={() => this.toggle()}
          >
            &times;
          </div>
        }
        size="small"
        trigger={
          <Dropdown.Item className="activity-actions-dropdown-view">
            <label className="activity-actions-success">View</label>
          </Dropdown.Item>
        }
      >
        <Modal.Header className="new-activity-modal-header">
          Projected Linked Activity
        </Modal.Header>
        <Modal.Content>
          <Segment.Group raised>
            <Segment
              className="custom-segment projected-activity-modal-header pointer"
              onClick={() =>
                toggle
                  ? this.setState({ toggle: false })
                  : this.setState({ toggle: true })
              }
            >
              <div>
                <label className="projected-activity-modal-heading pointer">
                  Activity
                </label>
              </div>
              <div className="projected-activity-modal-toggle">
                {toggle ? (
                  <Icon name="angle up" size="large" />
                ) : (
                  <Icon name="angle down" size="large" />
                )}
              </div>
            </Segment>
            {toggle ? (
              <Segment className="report-activity-seg">
                <div className="report-activity-div projected-info-text">
                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column
                        className="projected-activity-modal-margins"
                        mobile={8}
                        computer={8}
                        tablet={8}
                      >
                        <label className="projected-info-text">
                          Activity Name
                        </label>
                        <p className="projected-info-text">
                          {get(activity, 'name', '-')}
                        </p>
                      </Grid.Column>
                      <Grid.Column
                        className="projected-activity-modal-margins"
                        mobile={8}
                        computer={8}
                        tablet={8}
                      >
                        {activity.activityPlanTypeId === 4 ? (
                          <>
                            {' '}
                            <label className="projected-info-text">
                              Grant ID
                            </label>
                            <p className="projected-info-text">
                              {get(activity, 'grantId', '-')}
                            </p>
                          </>
                        ) : (
                          ''
                        )}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column
                        className="projected-activity-modal-margins"
                        mobile={16}
                        computer={16}
                        tablet={16}
                      >
                        <label className="projected-info-text">
                          Activity Period
                        </label>
                        <p className="projected-info-text">
                          {this.getPeriod()}
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column
                        className="projected-activity-modal-margins"
                        mobile={16}
                        computer={16}
                        tablet={16}
                      >
                        <label className="projected-info-text">
                          Activity Description
                        </label>
                        <p className="projected-info-text pre-line">
                          {get(activity, 'activitySummary', '-')}
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column
                        className="projected-activity-modal-margins"
                        mobile={16}
                        computer={16}
                        tablet={16}
                      >
                        <label className="projected-info-text">
                          Organisation Goal
                        </label>
                        <p className="projected-info-text">
                          {get(currentActivity, 'goal', '-')}
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column
                        className="projected-activity-modal-margins"
                        mobile={16}
                        computer={16}
                        tablet={16}
                      >
                        <label className="projected-info-text">
                          Goal Description
                        </label>
                        <p className="projected-info-text">
                          {get(currentActivity, 'goalDescription', '-')}
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
              </Segment>
            ) : null}
          </Segment.Group>
          {activity.objective1 ? (
            <ObjectiveView
              index={1}
              outcomeId="primaryOutcomeId"
              {...this.props}
            />
          ) : null}
          {activity.objective2 ? (
            <ObjectiveView
              index={2}
              outcomeId="secondaryOutcomeId"
              {...this.props}
            />
          ) : null}
        </Modal.Content>
      </Modal>
    );
  }
}
