import './activity.scss';

import React, { Fragment } from 'react';

import { connect } from 'react-redux';
import Alert from 'reactstrap/lib/Alert';
import { bindActionCreators } from 'redux';
import { Grid, Segment, Button } from 'semantic-ui-react';
import { find, get, isEmpty, map, take, size } from '../../../utils/lodash';
import ShowMoreText from 'react-show-more-text';
class ActivityGoalAndObjective extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  noGoal() {
    return <Alert color="warning">Please select an goal</Alert>;
  }

  noObjective() {
    return <Alert color="warning">Please select an objective</Alert>;
  }

  render() {
    const { currentActivity } = this.props;
    let objective = currentActivity.objective || [];
    const form = {
      goal: currentActivity.goal ? currentActivity.goal : this.noGoal(),
      desctiption: currentActivity.goalDescription
        ? currentActivity.goalDescription
        : '-',
      objectiveOne: objective[0]
        ? objective[0].outcome
          ? objective[0].outcome.title
          : this.noObjective()
        : this.noObjective(),
      objectiveTwo: objective[1]
        ? objective[1].outcome
          ? objective[1].outcome.title
          : this.noObjective()
        : this.noObjective(),
    };
    return (
      <Segment className="content-segment content-bg">
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <div className="theory-container">
                <label className="goal-label">Goal</label>
                {/* <br /> */}
                <label className="objectiveGoal-title">{form.goal}</label>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <div className="theory-container">
                <label className="goal-label">Goal Description</label>
                {/* <br /> */}
                <label className="objectiveGoal-description">
                  {form.desctiption}
                </label>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <div className="theory-container">
                <label className="goal-label">
                  Primary objective -{' '}
                  {`${
                    currentActivity.primaryPolicy
                      ? currentActivity.primaryPolicy.policyName
                      : ''
                  }`}
                </label>
                {/* <br /> */}
                <label className="objectiveGoal-title">
                  {form.objectiveOne}
                </label>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              {!currentActivity.secondaryPolicyId ? null : (
                <div className="theory-container">
                  <label className="goal-label">
                    Secondary objective -{' '}
                    {`${
                      currentActivity.secondaryPolicy
                        ? currentActivity.secondaryPolicy.policyName
                        : ''
                    }`}
                  </label>
                  {/* <br /> */}
                  <label className="objectiveGoal-title">
                    {form.objectiveTwo}
                  </label>
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
      </Segment>
    );
  }
}

class ActivityParentGoalAndObjective extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      less: true,
    };
  }

  noGoal() {
    return <Alert color="warning">Please select an goal</Alert>;
  }

  render() {
    const { theory, currentActivity, extra, noObjectives = false } = this.props;

    const form = {
      goal: currentActivity.goal ? currentActivity.goal : this.noGoal(),
      desctiption: currentActivity.goalDescription
        ? currentActivity.goalDescription
        : '-',
    };
    let objectives = [];
    if (extra.outcomes) {
      objectives = map(currentActivity.parentOutcomes, (outcome) => {
        outcome.main = find(extra.outcomes, (o) => {
          return o.id === outcome.outcomeId;
        });
        return outcome;
      });
    }

    return (
      <Segment className="content-segment parent-objectives">
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <div className="form-group-evidence-margin-left extra-top-margin-theory">
                <label className="goal-label">Goal</label>
                {/* <br /> */}
                <label className="objectiveGoal-title-new">{form.goal}</label>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <div className="form-group-evidence-margin-right extra-top-margin-theory">
                <label className="goal-label">Goal Description</label>
                {/* <br /> */}
                <ShowMoreText
                  lines={3}
                  more="Read More"
                  less="Read Less"
                  anchorClass="show-more-less-clickable"
                  expanded={false}
                  truncatedEndingComponent={'... '}
                  className="pre-line"
                >
                  <label className="objectiveGoal-title-new">
                    {form.desctiption}
                  </label>
                </ShowMoreText>
              </div>
            </Grid.Column>
            {noObjectives ? null : (
              <Fragment>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <div>
                    <label className="goal-label">Intended Outcomes</label>
                  </div>
                </Grid.Column>
                {isEmpty(objectives) ? (
                  <Grid.Column mobile={16} tablet={16} computer={8}>
                    <div className="theory-container">
                      <Alert color="warning">Please select outcomes</Alert>
                    </div>
                  </Grid.Column>
                ) : (
                  map(
                    take(objectives, this.state.less ? 6 : size(objectives)),
                    (objective, i) => {
                      return (
                        <Grid.Column
                          key={i}
                          mobile={16}
                          tablet={16}
                          computer={8}
                        >
                          <div
                            className={
                              i % 2 === 0
                                ? 'intended-outcomes-margin-left'
                                : 'intended-outcomes-margin-right'
                            }
                          >
                            <label className="objectiveGoal-description">
                              <li>{get(objective, 'main.title', '-')}</li>
                            </label>
                          </div>
                        </Grid.Column>
                      );
                    }
                  )
                )}
              </Fragment>
            )}
            <Grid.Column mobile={16} tablet={16} computer={16}>
              {theory && size(objectives) > 6 ? (
                <div className="see-more-see-less-btn-container">
                  <Button
                    className="see-more-see-less-btn"
                    onClick={() =>
                      this.setState({
                        less: this.state.less !== true ? true : false,
                      })
                    }
                  >
                    {this.state.less === true ? 'See All ' : 'See Less '}
                    <i
                      aria-hidden="true"
                      className={`chevron ${
                        this.state.less !== true ? 'up' : 'down'
                      } icon`}
                    ></i>
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {/* <br /> */}
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    extra: state.extra,
    evaluationPlanList: state.report.evaluationPlanList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

ActivityGoalAndObjective = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityGoalAndObjective);

ActivityParentGoalAndObjective = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityParentGoalAndObjective);

export { ActivityGoalAndObjective, ActivityParentGoalAndObjective };
