/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Icon } from 'semantic-ui-react';
import {
  getPreviousNetworkStatus,
  setPreviousNetworkStatus,
} from '../utils/cacheStore';

const NetworkDetectorHoc = ({ children }) => {
  const [isDisconnected, setIsDisconnected] = React.useState(false);
  const [isSlowNetwork, setIsSlowNetwork] = React.useState(false);
  const [isBackOnlineNetwork, setIsBackOnlineNetwork] = React.useState(false);

  const connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection;

  React.useEffect(() => {
    connection && handleConnectionChange({}, true);
    connection && connection.addEventListener('change', handleConnectionChange);

    return () => {
      connection &&
        connection.removeEventListener('change', handleConnectionChange);
    };
  }, []);

  React.useEffect(() => {
    if (isBackOnlineNetwork) {
      setTimeout(() => {
        setIsBackOnlineNetwork(false);
        setPreviousNetworkStatus('ONLINE');
      }, 5000);
    }
  }, [isBackOnlineNetwork]);

  const handleConnectionChange = async (event = null, isInitRender = false) => {
    const condition = navigator.onLine ? 'online' : 'offline';
    const previousNetworkStatus = await getPreviousNetworkStatus();

    if (condition === 'online') {
      // when downlink is less than 1 mega bits per second (Mbps)
      if (!connection || connection.downlink < 1) {
        setIsSlowNetwork(true);
        setIsDisconnected(false);
        setPreviousNetworkStatus('SLOW');
      } else {
        !isInitRender &&
          previousNetworkStatus !== 'ONLINE' &&
          setIsBackOnlineNetwork(true);
        setIsSlowNetwork(false);
        setIsDisconnected(false);
      }

      return;
    }

    setPreviousNetworkStatus('OFFLINE');
    setIsSlowNetwork(false);
    return setIsDisconnected(true);
  };

  let networkStyle = {
    fontSize: '1rem',
    padding: '5px',
    color: 'white',
    textAlign: 'center',
    position: 'fixed',
    left: '0',
    bottom: '0',
    height: '30px',
    width: '100%',
    zIndex: 9999,
  };

  const connectionNow = isSlowNetwork
    ? 'SLOW'
    : isDisconnected
      ? 'OFFLINE'
      : isBackOnlineNetwork
        ? 'BACK_ONLINE'
        : 'ONLINE';

  switch (connectionNow) {
    case 'SLOW': {
      networkStyle = {
        ...networkStyle,
        backgroundColor: '#FFC043',
        color: 'black',
      };
      break;
    }
    case 'OFFLINE': {
      networkStyle = { ...networkStyle, backgroundColor: '#424242' };
      break;
    }
    case 'BACK_ONLINE': {
      networkStyle = { ...networkStyle, backgroundColor: '#05944F' };
      break;
    }
    default: {
      networkStyle = {};
      break;
    }
  }

  return connection ? (
    <>
      {children}
      <div style={networkStyle}>
        {' '}
        {connectionNow === 'SLOW' ? (
          <div>
            <span className="mr-2">
              <Icon name="warning sign" />
            </span>
            Your connection is slow. There may be a delay in saving your work.
          </div>
        ) : connectionNow === 'OFFLINE' ? (
          <div>
            <span className="mr-2">
              <Icon name="warning sign" />
            </span>
            No internet connection
          </div>
        ) : connectionNow === 'BACK_ONLINE' ? (
          <div>
            <span className="mr-2">
              <Icon name="circle" />
            </span>
            Back online
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  ) : (
    <>{children}</>
  );
};

export default NetworkDetectorHoc;
