import moment from 'moment';
import React from 'react';
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from 'availity-reactstrap-validation';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Grid,
  Segment,
  Modal,
  Button,
  Icon,
  Label,
  Dropdown,
} from 'semantic-ui-react';
import { map, get } from '../../../utils/lodash';
import { EmptyContainer, CustomTable } from '../../../components';
import {
  getBroadcastNotifications,
  getAllOrganisationList,
  createBroadcastNotifications,
} from '../../../store/actions';
import './organization.scss';

class NotificationForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: true,
      organisationIds: [],
      organisationError: false,
    };
  }

  handleValidSubmit(event, values) {
    const { isChecked, organisationIds } = this.state;
    const { toggle, createBroadcastNotifications, resetPage } = this.props;
    if (!isChecked && !organisationIds.length) {
      this.setState({ organisationError: true });
    } else {
      values.isAllOrganisation = isChecked;
      values.organisationIds = organisationIds;
      delete values.organisationId;

      createBroadcastNotifications(values);
      this.form && this.form.reset();
      if (resetPage) resetPage();
      toggle();
    }
  }

  getOrganisationOptions() {
    const { allOrganisation } = this.props;
    return map(allOrganisation, (item, i) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
  }

  async onChangeCheckBox() {
    await this.setState((prevState) => ({
      isChecked: !prevState.isChecked,
    }));
  }

  async onChangeOrganisation(event, data) {
    this.setState({
      organisationIds: data.value,
      organisationError: !data.value.length,
    });
  }

  render() {
    const { isChecked, organisationError } = this.state;
    const { toggle } = this.props;
    const options = this.getOrganisationOptions();
    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="broadcast-form"
      >
        <AvField
          label="Title"
          className="org-inputs"
          name="title"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: 'Title is required',
            },
            maxLength: {
              value: 100,
            },
          }}
        />
        <AvField
          label="Message"
          className="org-inputs-textarea"
          name="message"
          type="textarea"
          validate={{
            required: {
              value: true,
              errorMessage: 'Message is required',
            },
          }}
        />
        <AvCheckboxGroup name="isAllOrganisation">
          <AvCheckbox
            label="Send to All Organisation"
            checked={isChecked}
            onChange={(e) => this.onChangeCheckBox()}
          />
        </AvCheckboxGroup>
        {!isChecked ? (
          <div>
            <label>Select Organisation</label>
            <Dropdown
              fluid
              multiple
              search
              selection
              options={options}
              onChange={(event, data) => this.onChangeOrganisation(event, data)}
              error={organisationError}
              className="broadcast-dropdown"
            />
            {organisationError ? (
              <div className="invalid-dropdown-input">
                Select organisation is required
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button content="Send" type="submit" className="Primary-Button" />
        </div>
      </AvForm>
    );
  }
}

class CreateNotification extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    return (
      <div className="add-goal">
        <Modal
          trigger={
            <Button
              onClick={() => this.toggle()}
              className="customColor custom-margin-goals"
            >
              <Icon name="plus circle" />
              Broadcast Message
            </Button>
          }
          size="small"
          open={modal}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
        >
          <Modal.Header>Broadcast New Message</Modal.Header>
          <Modal.Content>
            <NotificationForm {...this.props} toggle={() => this.toggle()} />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class ViewNotification extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { records } = this.props;
    return (
      <Modal
        trigger={<label className="org-checklist-actions-warn">View</label>}
        size="small"
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        className="view-broadcast-message"
      >
        <Modal.Header>View Broadcast Message</Modal.Header>
        <Modal.Content className="pre-line">
          {get(records, 'message')}
        </Modal.Content>
      </Modal>
    );
  }
}

class Broadcast extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
    };
  }

  resetPage() {
    this.setState({
      currentPage: 1,
    });
  }

  componentDidMount() {
    const { getBroadcastNotifications, getAllOrganisationList } = this.props;
    getBroadcastNotifications();
    getAllOrganisationList();
  }

  columns() {
    return [
      {
        title: 'Title',
        render: (data) => {
          return (
            <label className="org-checklist-text">
              {get(data, 'title', '-')}
            </label>
          );
        },
      },
      {
        title: 'Organisation',
        width: 5,
        render: (data) => {
          return get(data, 'isAllOrganisation') ? (
            <Label color="orange" horizontal className="label-padding">
              All Organisations
            </Label>
          ) : (
            map(get(data, 'organisationMessages'), (item, i) => {
              const orgName = get(item, 'organisation.name');
              return (
                <Label key={i} horizontal className="label-padding">
                  {orgName}
                </Label>
              );
            })
          );
        },
      },
      {
        title: 'Sender',
        render: (data) => {
          return (
            <label className="org-checklist-text">
              {get(data, 'sender.firstName', '-')} {get(data, 'sender.surname')}
            </label>
          );
        },
      },
      {
        title: 'Created Date',
        render: (data) => {
          return (
            <label className="org-checklist-text">
              {get(data, 'createdAt')
                ? moment(get(data, 'createdAt')).format('D MMMM YYYY, hh:mm A')
                : '-'}
            </label>
          );
        },
      },
      {
        title: '',
        render: (data) => {
          return (
            <div className="org-checklist-actions">
              <ViewNotification {...this.props} records={data} />
            </div>
          );
        },
      },
    ];
  }

  async broadcastPageChange(page) {
    const { getBroadcastNotifications } = this.props;
    await this.setState({ currentPage: page });
    getBroadcastNotifications(page);
  }

  render() {
    const { currentPage } = this.state;
    const { notificationsLoading, broadcastMessage } = this.props;
    return (
      <Segment
        className="content-segment org-checklist"
        loading={notificationsLoading.broadcast}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              textAlign="left"
              verticalAlign="bottom"
            ></Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8} textAlign="right">
              <CreateNotification
                {...this.props}
                resetPage={() => this.resetPage()}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {get(broadcastMessage, 'docs') && broadcastMessage.docs.length ? (
              <Grid.Column>
                <CustomTable
                  header
                  columns={this.columns()}
                  data={broadcastMessage.docs}
                  customClass="org-checklist-table"
                  pagination
                  handlePaginationChange={(page) =>
                    this.broadcastPageChange(page)
                  }
                  page={currentPage}
                  noOfPages={broadcastMessage.pages}
                  keyExtractor={(item, index) => item.id}
                />
              </Grid.Column>
            ) : (
              <EmptyContainer msg="No notification found…" />
            )}
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  const { organization, authentication, user } = state;
  return {
    user: authentication.user,
    notificationsLoading: user.notificationsLoading,
    broadcastMessage: user.broadcastMessage,
    organization: organization.organization,
    allOrganisation: organization.allOrganizations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getBroadcastNotifications,
      getAllOrganisationList,
      createBroadcastNotifications,
    },
    dispatch
  );
};

Broadcast = connect(mapStateToProps, mapDispatchToProps)(Broadcast);

export { Broadcast };
