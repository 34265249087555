import './activity.scss';

/* eslint-disable eqeqeq */
import React, { Fragment } from 'react';

import { AvField, AvForm } from 'availity-reactstrap-validation';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  Checkbox,
  Divider,
  Grid,
  Icon,
  Modal,
  Segment,
} from 'semantic-ui-react';

import { CustomTooltip, EmptyContainer } from '../../../components';
import {
  filter,
  find,
  get,
  isEmpty,
  isObject,
  map,
} from '../../../utils/lodash';
import { putActivity, putParentDomain } from '../../../store/actions';
import { getPageDisable } from '../../../utils';
import ShowMoreText from 'react-show-more-text';
import ProjectText from '../../../components/projectText';
import { isParentProgramActivity } from '../../../utils/activity';

class ActivityGoal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedGoalToolTip: null,
      crossRefCurrentDoc: null,
      goalCrossReferenceId: null,
    };
  }

  async componentDidMount() {
    const { currentActivity } = this.props;

    if (document.location.search === '?duplicate=true') {
      this.setState({
        modal: true,
      });
    }
    this.setState({ selectedGoalToolTip: currentActivity.goal });
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async setGoal({ target }) {
    try {
      const { putActivity, currentActivity, policies } = this.props;

      const policy = await find(
        currentActivity.owner.organisation.goals,
        (o) => {
          return o.id == target.value;
        }
      );

      const primaryPolicy = await find(policies, (o) => {
        return o.id == policy.primaryPolicyId;
      });
      const secondaryPolicy = await find(policies, (o) => {
        return o.id == 1;
      });

      let setPrimary = {
        primaryPolicy,
        primaryPolicyId: primaryPolicy.id,
        secondaryPolicy: {},
        secondaryPolicyId: null,
        goal: policy.goalTitle,
        goalDescription: policy.goalDescription,
        selectedPrimaryGoalId: target.value,
      };

      if (primaryPolicy.id != 1) {
        setPrimary.secondaryPolicy = secondaryPolicy;
        setPrimary.secondaryPolicyId = secondaryPolicy.id;
      } else {
        setPrimary.secondaryPolicy = {};
        setPrimary.secondaryPolicyId = null;
      }

      await putActivity('goal', setPrimary);

      this.setState({ selectedGoalToolTip: policy.goalTitle });
    } catch (error) {
      // console.error('ActivityGoal -> setGoal -> error', error);
      NotificationManager.error('Something went wrong');
    }
  }

  async setSecondary({ target }) {
    try {
      const { putActivity, policies, currentActivity } = this.props;
      const goalStatus = find(
        currentActivity.completionProgress,
        (o) => o.section === 'Goal'
      );
      if (goalStatus.status != 0) {
        // return false if the goals are disabled
        return;
      } else {
        const secondaryPolicy = await find(policies, (o) => {
          return o.id == target.value;
        });
        let setSecondary = {
          secondaryPolicy: secondaryPolicy || {},
          secondaryPolicyId: target.value || null,
        };
        await putActivity('goal', setSecondary);
      }
    } catch (error) {
      // console.error('ActivityGoal -> setSecondary -> error', error);
      NotificationManager.error('Something went wrong');
    }
  }

  renderObjectiveDomainOne() {
    let { outcomes, currentActivity } = this.props;
    const objectives = filter(outcomes, (o) => {
      return o.policyId == currentActivity.primaryPolicyId;
    });
    if (isEmpty(objectives) || currentActivity.goal === '') return null;
    return (
      <div className="goal-primary-policy-domain">
        <label className="objective-list-goal-header">
          {currentActivity.primaryPolicy.policyName || ''} Domain Objectives -
          Primary Policy Domain
        </label>
        {map(objectives, (item, i) => {
          if (!item.isEnable) return null;
          return (
            <label className="objective-list-goal-list" key={i}>
              &#8226; {item.title}
            </label>
          );
        })}
      </div>
    );
  }

  renderObjectiveDomainTwo() {
    let { outcomes, currentActivity } = this.props;
    const objectives = filter(outcomes, (o) => {
      return o.policyId == currentActivity.secondaryPolicyId;
    });
    if (isEmpty(objectives)) return null;
    return (
      <div className="goal-secondary-policy-domain">
        <label className="objective-list-goal-header">
          {currentActivity.secondaryPolicy.policyName || ''} Domain Objectives -
          Secondary Policy Domain
        </label>
        {map(objectives, (item, i) => {
          if (!item.isEnable) return null;
          return (
            <label className="objective-list-goal-list" key={i}>
              &#8226; {item.title}
            </label>
          );
        })}
      </div>
    );
  }

  getObjectives(policy) {
    let { outcomes } = this.props;
    const objectives = filter(outcomes, (o) => {
      return o.policyId == policy;
    });
    if (isEmpty(objectives)) return null;
    return (
      <div className="domain-list-goal-container">
        {map(objectives, (item, i) => {
          if (!item.isEnable) return null;
          return (
            <label className="domain-list-goal-list" key={i}>
              &#8226; {item.title}
            </label>
          );
        })}
      </div>
    );
  }

  async handleChange(policy) {
    try {
      const { currentActivity, putParentDomain } = this.props;
      const goalStatus = find(
        currentActivity.completionProgress,
        (o) => o.section === 'Goal'
      );
      const setDisablePrimary = goalStatus.status != 0 ? true : false;
      if (setDisablePrimary) return;
      await putParentDomain(currentActivity.id, {
        policy,
      });
    } catch (error) {
      // console.error('ActivityObjective -> handleChange -> error', error);
    }
  }

  render() {
    let { currentActivity, user, helpTips, currentState } = this.props;
    const { modal, selectedGoalToolTip } = this.state;

    const ownerOrg = get(currentActivity, 'owner.organisation', null);
    if (isEmpty(ownerOrg)) return <EmptyContainer />;

    const primaryPolicy =
      find(ownerOrg.goals, (o) => {
        return o.id == currentActivity.selectedPrimaryGoalId;
      }) || {};

    const goalStatus = find(
      currentActivity.completionProgress,
      (o) => o.section === 'Goal'
    );

    const defaultForm = {
      primary:
        primaryPolicy.id && currentActivity.goal != '' ? primaryPolicy.id : '',
      secondary: currentActivity.secondaryPolicyId
        ? currentActivity.secondaryPolicyId
        : '',
    };

    const setDisable = isEmpty(currentActivity.objective) ? false : true;

    const setDisablePrimary = goalStatus.status != 0 ? true : false;
    const noSecondary = setDisablePrimary && defaultForm.secondary === '';

    const domainListOptions = setDisablePrimary
      ? currentActivity.parentDomains
      : primaryPolicy.secondaryPolicies;

    const goalOptions = filter(
      currentActivity.owner.organisation.goals,
      (f) => {
        return !f.isArchived && !f.isArchived;
      }
    );

    return (
      <AvForm
        ref={(c) => (this.form = c)}
        className="activity-form"
        model={defaultForm}
      >
        <Segment
          className="content-segment-goal"
          disabled={getPageDisable(currentActivity, user, currentState)}
        >
          <RemainderDuplicate modal={modal} toggle={() => this.toggle()} />
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                {currentActivity.completionProgress[0].status >= 1 ? (
                  <div>
                    <label className="select-goal-label">
                      {' '}
                      <ProjectText defaultText="Select Goal" textId={1} />
                    </label>
                    <label className="act-goal-label form-control primary-goal-container">
                      {currentActivity.goal || ''}
                    </label>
                  </div>
                ) : (
                  <CustomTooltip
                    state={get(user, 'options.tips')}
                    content={selectedGoalToolTip}
                    position="bottom left"
                    wide="very"
                  >
                    <AvField
                      className="act-goal-inputs"
                      type="select"
                      name="primary"
                      label={
                        <CustomTooltip
                          state={get(user, 'options.tips')}
                          contentObject={{
                            data: helpTips,
                            key: '20',
                          }}
                          position="bottom left"
                          wide="very"
                        >
                          <div className="required-star-label">Select Goal</div>
                        </CustomTooltip>
                      }
                      onChange={(e) => this.setGoal(e)}
                      disabled={
                        setDisablePrimary ? setDisablePrimary : setDisable
                      }
                    >
                      <option value="" disabled>
                        Select your goal
                      </option>
                      {map(goalOptions, (item, i) => (
                        <option key={i} value={item.id}>
                          {item.goalTitle}
                        </option>
                      ))}
                    </AvField>
                  </CustomTooltip>
                )}
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <div className="objective-list-goal-container-extra-margin">
                  <label className="objective-list-goal-header">
                    Goal Description
                  </label>

                  <ShowMoreText
                    lines={3}
                    more="Read More"
                    less="Read Less"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    truncatedEndingComponent={'... '}
                    className="pre-line"
                  >
                    <label className="objective-description-goal">
                      {currentActivity.goalDescription
                        ? currentActivity.goalDescription
                        : '-'}
                    </label>
                  </ShowMoreText>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Divider section />
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                {/* {currentActivity.goal !== '' ? ( */}
                <CustomTooltip
                  state={get(user, 'options.tips')}
                  contentObject={{
                    data: helpTips,
                    key: '31',
                  }}
                  position="bottom left"
                  wide="very"
                >
                  <div>
                    <label className="required-star-label">
                      Primary Policy domain for activity
                    </label>
                    <label className="act-goal-inputs form-control primary-goal-container">
                      {currentActivity.goal !== ''
                        ? currentActivity.primaryPolicy.policyName || ''
                        : ''}
                    </label>
                    <small className="form-text text-muted">
                      <ProjectText
                        defaultText="Your primary policy domain will be populated based on the selected goal"
                        textId={2}
                      />
                    </small>
                  </div>
                </CustomTooltip>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                {this.renderObjectiveDomainOne()}
              </Grid.Column>
            </Grid.Row>
            <Divider section />
            {isParentProgramActivity(currentActivity.activityPlanTypeId) ? (
              currentActivity.primaryPolicy.id != 1 ? (
                <Grid.Row className="gold-policy">
                  <Grid.Column mobile={16} tablet={16} computer={8}>
                    {isObject(currentActivity.secondaryPolicy) ? (
                      <div>
                        <label className="required-star-label">
                          Secondary policy domain for activity
                        </label>
                        <label className="act-goal-inputs form-control primary-goal-container">
                          {currentActivity.secondaryPolicy
                            ? currentActivity.secondaryPolicy.policyName
                            : '-'}
                        </label>
                        <small className="form-text text-muted">
                          Your secondary policy domain will be populated based
                          on the selected goal
                        </small>
                      </div>
                    ) : null}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} computer={8}>
                    {this.renderObjectiveDomainTwo()}
                  </Grid.Column>
                </Grid.Row>
              ) : setDisablePrimary &&
                isEmpty(currentActivity.parentDomains) ? null : (
                <>
                  {!isEmpty(domainListOptions) ? (
                    <Grid.Row>
                      <Grid.Column mobile={16} tablet={16} computer={16}>
                        <div>
                          <CustomTooltip
                            state={get(user, 'options.tips')}
                            contentObject={{
                              data: helpTips,
                              key: '176',
                            }}
                            position="top left"
                            wide="very"
                          >
                            <div className="form-group-goal-heading">
                              Secondary Policy Domains for activity (optional)
                            </div>
                          </CustomTooltip>
                          <div className="form-group-goal-subheading text-muted">
                            Your secondary policy domain will be populated based
                            on the selected goal
                          </div>
                        </div>
                      </Grid.Column>
                      {map(domainListOptions, (item, i) => {
                        if (isEmpty(item.policy)) return;
                        const checked = find(
                          currentActivity.parentDomains,
                          (o) => {
                            return o.policyId === item.policy.id;
                          }
                        );
                        if (setDisablePrimary && isEmpty(checked)) return;
                        return (
                          <Fragment key={i}>
                            <Grid.Column mobile={16} tablet={16} computer={8}>
                              <div className="domain-select-check">
                                <Checkbox
                                  className="check-box"
                                  label={`${item.policy.policyName}`}
                                  key={2}
                                  value={item.policy.id}
                                  onChange={() =>
                                    this.handleChange(item.policy.id)
                                  }
                                  checked={isEmpty(checked) ? false : true}
                                  disabled={setDisablePrimary}
                                />
                              </div>
                              {this.getObjectives(item.policy.id)}
                            </Grid.Column>
                          </Fragment>
                        );
                      })}
                    </Grid.Row>
                  ) : null}
                </>
              )
            ) : noSecondary ? null : (
              <Grid.Row className="gold-policy">
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {currentActivity.primaryPolicy.id != 1 ? (
                    <>
                      {isObject(currentActivity.secondaryPolicy) ? (
                        <div className="form-group">
                          <CustomTooltip
                            state={get(user, 'options.tips')}
                            contentObject={{
                              data: helpTips,
                              key: '176',
                            }}
                            position="top left"
                            wide="very"
                          >
                            <label className="required-star-label">
                              Secondary policy domain for activity
                            </label>
                          </CustomTooltip>
                          <label className="act-goal-inputs form-control primary-goal-container">
                            {currentActivity.secondaryPolicy
                              ? currentActivity.secondaryPolicy.policyName
                              : '-'}
                          </label>
                          <small className="form-text text-muted">
                            Your secondary policy domain will be populated based
                            on the selected goal
                          </small>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {isEmpty(primaryPolicy.secondaryPolicies) ? (
                        <>
                          {isObject(currentActivity.secondaryPolicy) ? (
                            <div className="form-group">
                              <label className="">
                                Secondary policy domain for activity
                              </label>
                              <label className="act-inputs-test form-control primary-goal-container">
                                {currentActivity.secondaryPolicy
                                  ? currentActivity.secondaryPolicy.policyName
                                  : '-'}
                              </label>
                              <small className="form-text text-muted">
                                Your secondary policy domain will be populated
                                based on the selected goal
                              </small>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <CustomTooltip
                          state={get(user, 'options.tips')}
                          contentObject={{
                            data: helpTips,
                            key: '176',
                          }}
                          position="top left"
                          wide="very"
                        >
                          <AvField
                            className="act-goal-inputs"
                            type="select"
                            name="secondary"
                            label="Secondary policy domain for activity"
                            helpMessage={
                              <ProjectText
                                defaultText="Your secondary policy domain will be populated based on the selected goal"
                                textId={3}
                              />
                            }
                            onChange={(e) => this.setSecondary(e)}
                            disabled={
                              setDisablePrimary ? setDisablePrimary : setDisable
                            }
                          >
                            {isEmpty(primaryPolicy.secondaryPolicies) ? (
                              <option value="1" disabled>
                                Cultural (an activity must have a cultural
                                outcome)
                              </option>
                            ) : (
                              <>
                                <option value="" disabled>
                                  Select secondary policy domain
                                </option>
                                <option value={0}>
                                  No second domain for this activity
                                </option>
                                {map(
                                  primaryPolicy.secondaryPolicies,
                                  (item, i) => {
                                    if (isEmpty(item.policy)) return;
                                    return (
                                      <option key={i} value={item.policy.id}>
                                        {item.policy.policyName}
                                      </option>
                                    );
                                  }
                                )}
                              </>
                            )}
                          </AvField>
                        </CustomTooltip>
                      )}
                    </>
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.renderObjectiveDomainTwo()}
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Segment>
      </AvForm>
    );
  }
}

class RemainderDuplicate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { modal, toggle } = this.props;

    return (
      <Modal
        open={modal}
        onOpen={() => toggle()}
        closeIcon={
          <Icon onClick={() => toggle()} name="close" className="closeicon" />
        }
        size="small"
      >
        <Modal.Header>Activity Successfully Duplicated</Modal.Header>
        <Modal.Content>
          <div>
            Your activity has been successfully duplicated. Please review
            Evidence, Theory of Change, Plan and Evaluation pages to ensure all
            information is up to date and relevant to the activity as you plan
            to implement it now
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div className="model-buttons">
            <Button
              content="Got it"
              className="goal-create-button"
              onClick={() => toggle()}
            />
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    policies: state.extra.policies,
    user: state.authentication.user,
    outcomes: state.extra.outcomes,
    helpTips: state.extra.activityHelpTips,
    crossRefDocuments: state.organization.crossRefDocuments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      putActivity,
      putParentDomain,
    },
    dispatch
  );
};

ActivityGoal = connect(mapStateToProps, mapDispatchToProps)(ActivityGoal);

export { ActivityGoal };
