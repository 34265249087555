import { find, get, isEmpty } from '../lodash';

/**
 * @param  {} userId
 * @param  {} usersPermissions
 * @description This function check given user is only have parent project permission
 *              (no access to create linked activity and other specific permission in program linked activities table)
 */
export const isOnlyProgramCollaborator = (userId, members) => {
  if (!userId && !members) {
    return true;
  }

  const userPermission = members.find((member) => member.userId === userId);

  if (!userPermission) {
    return true;
  }

  return get(userPermission, 'isOnlyProgramCollaborator', true);
};

/**
 * Check if individual user
 * @param {object} user
 * @returns
 */
export const isIndividualUser = (user) => {
  const orgType = get(user, 'organisation.type', null);
  return orgType && orgType === 'INDIVIDUAL';
};

/**
 * Check if user feature
 * @param {string} user
 * @param {string} featureName
 * @param {string} currentActivity
 *
 * @returns boolean
 */
export const isFeature = (user, featureName, currentActivity = {}) => {
  // skip permission check if user 'super admin'
  if (user.role === 'admin') return true;

  // skip permission check if invited activity
  if (isInvitedActivityMember(user, currentActivity)) {
    return true;
  }

  // check features permission
  const features = get(user, 'features', []);
  return features.includes(featureName);
};

export const getPermissionType = (permissionArray) => {
  let PermissionType = 'custom';

  const isReporter = isReporterPermission(permissionArray);

  const isDocumentor = isDocumentorPermission(permissionArray);

  const isCollaborator = isCollaboratorPermission(permissionArray);

  if (isReporter) {
    PermissionType = 'reportOnly';
  } else if (isDocumentor) {
    PermissionType = 'documentor';
  } else if (isCollaborator) {
    PermissionType = 'collaborator';
  } else {
    PermissionType = 'custom';
  }

  return PermissionType;
};
/**
 * check whether reporter permission type or not
 * @param  {array} permissionArray
 */
const isReporterPermission = (permissionArray) => {
  for (const page of permissionArray) {
    const permission = get(page, 'permission');
    if (page.name === 'Report') {
      if (permission.read) {
        return true;
      } else {
        return false;
      }
    } else {
      if (!(!permission.write && !permission.read)) {
        return false;
      }
    }
  }
};
/**
 * check whether documentor permission type or not
 * @param  {array} permissionArray
 */
const isDocumentorPermission = (permissionArray) => {
  for (const page of permissionArray) {
    const permission = get(page, 'permission');
    if (page.name === 'Report' && !permission.read) {
      return false;
    } else if (
      page.name === 'Documentation' &&
      (!permission.write || !permission.read)
    ) {
      return false;
    } else if (page.name === 'Green Light' && !permission.read) {
      return false;
    } else {
      if (
        !['Report', 'Green Light', 'Documentation'].includes(page.name) &&
        !(!permission.write && !permission.read)
      ) {
        return false;
      }
    }
  }
  return true;
};
/**
 * check whether collaborator permission type or not
 * @param  {array} permissionArray
 */
const isCollaboratorPermission = (permissionArray) => {
  for (const page of permissionArray) {
    const permission = get(page, 'permission');
    if (page.name === 'Report' && !permission.read) {
      return false;
    } else if (page.name === 'Green Light' && !permission.read) {
      return false;
    } else {
      if (
        !['Report', 'Green Light'].includes(page.name) &&
        (!permission.write || !permission.read)
      ) {
        return false;
      }
    }
  }
  return true;
};

/**
 * check user is activity member or not
 * @param  {object} user
 * @param  {array} members
 *
 * @returns boolean
 */
export const isActivityMember = (user, members = []) => {
  const member = find(members, { userId: user.id });
  return Boolean(member);
};

/**
 * check user is invited activity member or not
 * @param  {object} user
 * @param  {object} currentActivity
 *
 * @returns boolean
 */
export const isInvitedActivityMember = (user, currentActivity = {}) => {
  if (isEmpty(currentActivity)) return false;

  const isMember = isActivityMember(user, currentActivity.members);
  if (user.id !== currentActivity.ownerId && isMember) {
    return true;
  }

  return false;
};

/**
 * check Member Permission
 * @param  {object} user
 * @param  {array} members
 * @param  {string} type
 *
 * @returns
 */
export const checkMemberPermission = (user, members, type) => {
  const member = find(members, { userId: user.id });

  if (member) {
    const typePermission = find(member.permissions, { name: type });
    return typePermission.permission;
  } else {
    // if not member only grant read permissions but if user hasn't any permission it will check in protected upper lever (restrict unauthorized access)
    return { read: true, write: false };
  }
};

/**
 * Check activity view permission
 * @param {object} user
 * @param {object} currentActivity
 * @returns
 */
export const checkPermissionDenied = (user, currentActivity) => {
  // skip permission check if invited activity
  if (isInvitedActivityMember(user, currentActivity)) {
    return false;
  }

  if (currentActivity) {
    if (currentActivity.activityPlanTypeId === 1) {
      if (!isFeature(user, 'single_program')) return true;
    }
    if (currentActivity.activityPlanTypeId === 3) {
      if (!isFeature(user, 'festival_program')) return true;
    }
    if (currentActivity.activityPlanTypeId === 4) {
      if (!isFeature(user, 'grant_program')) return true;
    }
    if (currentActivity.activityPlanTypeId === 6) {
      if (!isFeature(user, 'multi_activity_program')) return true;
    }
  }
  return false;
};

export const checkGuestCollaborator = (activity, auth) => {
  const getCurrentMember = find(
    activity.currentActivity.members,
    (mem) => mem.userId === auth.user.id
  );
  let isGuestCollaborator =
    getPermissionType(getCurrentMember.permissions) === 'collaborator';

  return isGuestCollaborator;
};
