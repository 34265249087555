import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Grid,
  Modal,
  Button,
  Icon,
  Dimmer,
  Loader,
  Segment,
} from 'semantic-ui-react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { NotificationManager } from 'react-notifications';
import { APP_NAME } from '../../utils/constant';
import {
  getActParticipantData,
  deleteActivityMember,
} from '../../store/actions';
import { CustomSegment, CustomTable, EmptyContainer } from '../../components';
import { ActivityMember } from './form';
import './activities.scss';
import { PermissionDenied } from '../../components/errorStatus';
import { checkPermissionDenied } from '../../utils/activity/userPermission';

export class ParticipantPermissionUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { participant, triggerComp } = this.props;
    const { modal } = this.state;
    return (
      <div className="add-goal-custom">
        <Modal
          onOpen={() => this.toggle()}
          trigger={triggerComp}
          size="tiny"
          open={modal}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
          className="edit-guest-user-modal"
        >
          <Modal.Header>
            Edit Permission of {participant.user ? participant.user.name : '-'}
          </Modal.Header>
          <Modal.Content>
            <ActivityMember
              className="permission-pop-up"
              editPermission
              toggle={() => this.toggle()}
              {...this.props}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class ActivityParticipantCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    return (
      <div className="add-goal">
        <Modal
          onOpen={() => this.toggle()}
          trigger={
            <Button className=" Primary-Button">
              <Icon name="plus circle" />
              Invite User
            </Button>
          }
          size="tiny"
          open={modal}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
          className="add-guest-user-modal"
        >
          <Modal.Header>Add guest user</Modal.Header>
          <Modal.Content>
            <ActivityMember
              toggle={() => this.toggle()}
              {...this.props}
              inviteUser={true}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export class ParticipantDelete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async handleValidSubmit(event, values) {
    try {
      const { deleteActivityMember, participant } = this.props;
      this.setState(
        {
          loading: true,
        },
        async () => {
          await deleteActivityMember(participant, values);
          this.setState({ loading: false }, () => {
            NotificationManager.success(
              'User was removed from the activity',
              'Participant remove success'
            );
            this.toggle();
          });
        }
      );
    } catch (error) {
      NotificationManager.error(
        'We couldnt delete the activity',
        'Something went wrong'
      );
    }
  }

  render() {
    const { modal, loading } = this.state;
    const { participant, triggerComp } = this.props;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="small"
        trigger={triggerComp}
      >
        <Modal.Header>
          Delete Participant - {participant.user ? participant.user.name : '-'}
        </Modal.Header>
        <Modal.Content>
          <AvForm
            ref={(c) => (this.form = c)}
            onValidSubmit={(event, values) =>
              this.handleValidSubmit(event, values)
            }
          >
            <p>Are you sure you want to delete this participant?</p>
            <AvField
              label="Please enter message to notify participant"
              className="org-inputs-textarea"
              name="message"
              type="textarea"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Message is required',
                },
              }}
            />
            <div className="participant-delete-button-container">
              <Button onClick={() => this.toggle()} className="modal-cancel">
                No
              </Button>
              <Button
                // onClick={() => this.deleteParticipant(participant)}
                className="modal-proceed"
                content="Yes"
                type="submit"
                loading={loading}
                disabled={loading}
              />
            </div>
          </AvForm>
        </Modal.Content>
      </Modal>
    );
  }
}

class Participants extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: '',
          render: (data) => {
            return (
              <div className="participant-users-container">
                <label className="participant-users-mainText">
                  {data.user ? data.user.name : '-'}
                </label>
                <label className="participant-users-text">
                  <b>Type:</b>
                  {this.props.currentActivity.ownerId === data.userId
                    ? 'Owner'
                    : this.renderUserType(data.user)}
                </label>
              </div>
            );
          },
        },
        {
          title: '',
          render: (data) => {
            return (
              <label className="participant-users-text">
                <b>Email:</b>
                {data.user ? data.user.email : '-'}
              </label>
            );
          },
        },
        {
          title: '',
          render: (data) => {
            if (this.props.currentActivity.ownerId === data.userId) return null;
            return (
              <label className="participant-users-text">
                <b>Invitation Status:</b>
                <span className="invitation-status">
                  {data.status ? data.status : '-'}
                </span>
              </label>
            );
          },
        },
        {
          title: '',
          render: (data) => {
            if (this.props.currentActivity.ownerId === data.userId) return null;
            if (data.status === 'withdrawn') return null;
            return (
              <div className="activity-actions">
                <ParticipantPermissionUpdate
                  triggerComp={
                    <label className="activity-actions-warn">
                      Update Permission
                    </label>
                  }
                  participant={data}
                  {...this.props}
                />
                <ParticipantDelete
                  participant={data}
                  triggerComp={
                    <label className="activity-actions-error first-down">
                      Remove Participant
                    </label>
                  }
                  {...this.props}
                />
              </div>
            );
          },
        },
      ],
    };
  }

  renderUserType(user) {
    switch (user.role) {
      case 'admin':
        return 'Super Admin';
      case 'organisationAdmin':
        return 'Organisation Admin';
      case 'user':
        return 'User';
      case 'guest':
        return 'Guest';
      default:
        return '-';
    }
  }

  getColumns(currentActivity, user) {
    return [
      {
        width: 4,
        title: 'Member Name',
        render: (data) => {
          return (
            <div className="current-member-wrapper">
              <div className="current-member-text">
                {data.user ? (data.user.name ? data.user.name : null) : null}
              </div>
              {data.isOwner ? (
                <div className="activity-owner">Activity Manager</div>
              ) : null}
            </div>
          );
        },
      },
      {
        width: 4,
        title: 'Email',
        render: (data) => {
          return (
            <>{data.user ? (data.user.email ? data.user.email : null) : null}</>
          );
        },
      },
      {
        width: 3,
        title: 'Permission',
        render: (data) => {
          return (
            <>
              {data.permissions[0].permission.read === true &&
              data.permissions[0].permission.write === true &&
              data.permissions[1].permission.read === true &&
              data.permissions[1].permission.write === true &&
              data.permissions[2].permission.read === true &&
              data.permissions[2].permission.write === true &&
              data.permissions[3].permission.read === true &&
              data.permissions[3].permission.write === true &&
              data.permissions[4].permission.read === true &&
              data.permissions[4].permission.write === true &&
              data.permissions[5].permission.read === true &&
              data.permissions[5].permission.write === true &&
              data.permissions[6].permission.read === true &&
              data.permissions[6].permission.write === false &&
              data.permissions[7].permission.read === true &&
              data.permissions[7].permission.write === true &&
              data.permissions[8].permission.read === true &&
              data.permissions[8].permission.write === false
                ? 'Collaborator'
                : data.permissions[0].permission.read === false &&
                    data.permissions[0].permission.write === false &&
                    data.permissions[1].permission.read === false &&
                    data.permissions[1].permission.write === false &&
                    data.permissions[2].permission.read === false &&
                    data.permissions[2].permission.write === false &&
                    data.permissions[3].permission.read === false &&
                    data.permissions[3].permission.write === false &&
                    data.permissions[4].permission.read === false &&
                    data.permissions[4].permission.write === false &&
                    data.permissions[5].permission.read === false &&
                    data.permissions[5].permission.write === false &&
                    data.permissions[6].permission.read === true &&
                    data.permissions[6].permission.write === false &&
                    data.permissions[7].permission.read === true &&
                    data.permissions[7].permission.write === true &&
                    data.permissions[8].permission.read === true &&
                    data.permissions[8].permission.write === false
                  ? 'Documentor'
                  : data.permissions[0].permission.read === false &&
                      data.permissions[0].permission.write === false &&
                      data.permissions[1].permission.read === false &&
                      data.permissions[1].permission.write === false &&
                      data.permissions[2].permission.read === false &&
                      data.permissions[2].permission.write === false &&
                      data.permissions[3].permission.read === false &&
                      data.permissions[3].permission.write === false &&
                      data.permissions[4].permission.read === false &&
                      data.permissions[4].permission.write === false &&
                      data.permissions[5].permission.read === false &&
                      data.permissions[5].permission.write === false &&
                      data.permissions[6].permission.read === false &&
                      data.permissions[6].permission.write === false &&
                      data.permissions[7].permission.read === false &&
                      data.permissions[7].permission.write === false &&
                      data.permissions[8].permission.read === true &&
                      data.permissions[8].permission.write === false
                    ? 'Report Only'
                    : 'Custom'}
            </>
          );
        },
      },
      {
        width: 2,
        title: 'Status',
        render: (data) => {
          return (
            <div>
              {currentActivity.ownerId === data.userId ? (
                <div className="member-role">Activity Manager</div>
              ) : data.status.charAt(0).toUpperCase() + data.status.slice(1) ? (
                <div className="member-role">
                  {data.status.charAt(0).toUpperCase() + data.status.slice(1)}
                </div>
              ) : null}
            </div>
          );
        },
      },
      {
        width: 3,
        title: '',
        render: (data) => {
          return (
            <>
              {currentActivity.ownerId !== data.userId &&
              data.status !== 'withdrawn' &&
              user.id === currentActivity.ownerId ? (
                <ParticipantPermissionUpdate
                  triggerComp={
                    <label className="default-color mb-0">
                      Update Permission
                    </label>
                  }
                  participant={data}
                  {...this.props}
                />
              ) : null}
            </>
          );
        },
      },
      {
        width: 2,
        title: '',
        render: (data) => {
          return (
            <>
              {currentActivity.ownerId !== data.userId &&
              data.status !== 'withdrawn' &&
              user.id === currentActivity.ownerId ? (
                <ParticipantDelete
                  participant={data}
                  triggerComp={
                    <label className="activity-actions-error first-down mb-0">
                      Remove
                    </label>
                  }
                  {...this.props}
                />
              ) : null}
            </>
          );
        },
      },
    ];
  }

  render() {
    const { data, actGetLoading, actPut, currentActivity, user } = this.props;
    if (actGetLoading) return null;
    return (
      <Segment
        className="content-segment-custom"
        loading={actPut.activityMembers ? true : false}
      >
        <CustomTable
          header
          columns={this.getColumns(currentActivity, user)}
          data={data}
          customClass="evidence-table-grant"
          keyExtractor={(item, index) => item.id}
        />
      </Segment>
    );
  }
}

class ActivityParticipants extends React.PureComponent {
  async componentDidMount() {
    document.title = `Activities | ${APP_NAME}`;

    try {
      const { match, getActParticipantData } = this.props;

      if (match.params) {
        await getActParticipantData(match.params.id);
      }
    } catch (error) {
      // console.error('ActivitySteps -> componentDidMount -> error', error);
    }
  }

  render() {
    const { currentActivity, actGetLoading, actMembers, user } = this.props;
    if (checkPermissionDenied(user, currentActivity))
      return <PermissionDenied />;

    return (
      <div className="content-layer">
        <div className="content-container">
          <Grid>
            <Grid.Row className="act-row">
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={8}
                // className="activity-sub-column"
              >
                {/* <div className="act-participant-container">
                  <label className="act-participant-title">
                    <strong>Activity:</strong>{' '}
                    {currentActivity ? currentActivity.name : '-'}
                  </label>
                </div> */}
              </Grid.Column>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={8}
                className="activity-sub-column"
                f
              >
                <ActivityParticipantCreate />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="act-row">
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                className="activity-sub-column"
              >
                <CustomSegment
                  title="Manage Activity Members"
                  extraMargin
                  children={
                    actMembers && actMembers.length ? (
                      <Participants data={actMembers} {...this.props} />
                    ) : (
                      <EmptyContainer msg="You have not created any participants yet…" />
                    )
                  }
                  toggle={false}
                  className="manage-activity-members"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <Dimmer active={actGetLoading} inverted>
          <Loader size="massive" />
        </Dimmer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    user: state.authentication.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActParticipantData,
      deleteActivityMember,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityParticipants);
