import { isEqual } from '../lodash';
import { REPORT_FILTER_OPTIONS } from '../constant';

export const isFiltersInitState = (filters, excludeChecking = []) => {
  if (!filters) {
    return false;
  }

  const keyValues = Object.entries(filters);

  for (const item of keyValues) {
    const [key, value] = item;
    if (
      !excludeChecking.includes(key) &&
      !isEqual(REPORT_FILTER_OPTIONS[key], value)
    ) {
      return false;
    }
  }
  return true;
};
