import React from 'react';
import { Button, Icon, Input } from 'semantic-ui-react';
import { get, isEmpty } from '../utils/lodash';
import './styles.scss';
import { CustomTooltip } from '../components';

export class BrowseFile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fileName: null,
      file: null,
      uploadURL: null,
    };
  }

  getFile(event) {
    if (event.target.files && event.target.files.length) {
      this.setState({
        fileName: event.target.files[0].name,
        file: event.target.files[0],
      });
      if (!this.props.uploadFile) {
        this.props.setFile({
          fileName: event.target.files[0].name,
          file: event.target.files[0],
        });
      }
    } else {
      return false;
    }
  }

  resetBrowse() {
    this.setState({
      fileName: null,
      file: null,
      uploadURL: null,
    });
  }

  async uploadFile() {
    const { file } = this.state;
    await this.props.uploadFile(file);
    this.resetBrowse();
  }

  getCurrentFile() {
    const {
      file,
      // uploadURL
    } = this.state;
    return file;
  }

  render() {
    const { fileName, file } = this.state;
    const { isDocumentEmpty } = this.props;

    if (!isEmpty(fileName)) {
      isDocumentEmpty && isDocumentEmpty();
    }

    const {
      label,
      name,
      accept,
      placeholder,
      buttonText,
      helpMessage,
      infoMessage,
      linkLabel,
      link,
      csv,
      showMsg,
      tooltip = null,
      mediaUpload,
      isRequiredMessage,
      loading,
      uploadFile,
    } = this.props;

    return (
      <div className="browse-area">
        {tooltip ? (
          <CustomTooltip
            state={get(tooltip, 'user.options.tips')}
            contentObject={{
              data: tooltip.helpTips,
              key: tooltip.id,
            }}
            position="top left"
            wide="very"
          >
            <label className="browse-header">{label}</label>
          </CustomTooltip>
        ) : (
          <label className="browse-header">{label}</label>
        )}
        <div>
          <div>
            <Input
              name={name}
              id={name}
              type="file"
              hidden
              accept={accept}
              multiple={false}
              onChange={(e) => this.getFile(e)}
            />
            <Input
              label={
                this.props.loading ? (
                  <Button className="ui white button" loading={true} />
                ) : !file || !uploadFile ? (
                  <Button
                    className="ui white button custom"
                    as="label"
                    htmlFor={name}
                    type="button"
                    loading={loading}
                  >
                    <Icon name="upload" />
                    {buttonText}
                  </Button>
                ) : (
                  <Button
                    className="ui white button custom"
                    as="label"
                    type="button"
                    loading={loading}
                    onClick={() => this.uploadFile()}
                  >
                    <Icon name="upload" />
                    Upload
                  </Button>
                )
              }
              readOnly
              labelPosition="right"
              placeholder={placeholder}
              fluid
              value={fileName ? fileName : ''}
            />
          </div>
        </div>
        {infoMessage ? (
          <>
            <label className="form-text text-muted">{infoMessage}</label>
            {isRequiredMessage ? (
              <label className="form-text text-error">
                Document is required
              </label>
            ) : null}
            {mediaUpload ? null : <br />}
          </>
        ) : null}

        {mediaUpload ? null : (
          <div>
            {link ? (
              <a className="browse-info" target="_target" href={link}>
                {linkLabel}
              </a>
            ) : (
              <em className="form-text text-muted">
                {showMsg ? null : (
                  <>{csv ? null : 'There is no attached document.'}</>
                )}
              </em>
            )}

            <small className="form-text text-muted">{helpMessage}</small>
          </div>
        )}
      </div>
    );
  }
}
