import { bindActionCreators } from 'redux';
import { Button, Icon, Radio, Modal } from 'semantic-ui-react';

import { connect } from 'react-redux';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Paragraph,
  List,
  Heading,
} from 'ckeditor5';
import React, { Component } from 'react';

import { CustomAccordion, CustomTable } from '../../../../components';
import { find, isEmpty, map, get } from '../../../../utils/lodash';
import moment from 'moment';
import {
  postActivityType,
  getEventTypes,
  getOutputFormatGroups,
  getEventDetails,
  updateActivityType,
} from '../../../../store/actions';
import '../../admin.scss';
import { CKEditor } from '@ckeditor/ckeditor5-react';

class ParentEventDetails extends Component {
  componentDidMount() {
    const { getEventDetails, getOutputFormatGroups } = this.props;
    getEventDetails({ all: true });
    getOutputFormatGroups('all');
  }

  render() {
    return (
      <div className="event-details-segment pb-3">
        <EventDetailsCreate {...this.props} />

        <EventDetailsList {...this.props} />
      </div>
    );
  }
}

export class EventDetailsList extends Component {
  render() {
    const { eventTypes } = this.props;
    return (
      <div className="accordion-container">
        {map(eventTypes, (eventType, i) => {
          return (
            <div key={i} className="gap-between-windows-help">
              <CustomAccordion
                title={eventType.type}
                children={
                  <ActivityDetails eventType={eventType} {...this.props} />
                }
              />
            </div>
          );
        })}
      </div>
    );
  }
}

export class ActivityDetails extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: (
          <div className="user-act-heading-container-custom-feature">
            <strong>Type</strong>
          </div>
        ),
        render: (data) => {
          return <div>{data.detail}</div>;
        },
        width: 9,
      },
      {
        title: (
          <div className="user-act-heading-container-custom-feature">
            <strong>Created Date</strong>
          </div>
        ),
        render: (data) => {
          return <div>{moment(data.createdAt).format('ll hh:mm A')}</div>;
        },
        width: 3,
      },
      {
        title: (
          <div className="user-act-heading-container-custom-feature">
            <strong>Updated Date</strong>
          </div>
        ),
        render: (data) => {
          return <div>{moment(data.updatedAt).format('ll hh:mm A')}</div>;
        },
        width: 3,
      },
      {
        title: '',
        render: (data) => {
          return <EditActivityDetails data={data} {...this.props} />;
        },
        width: 1,
      },
    ];
  }

  render() {
    const { eventDetails, eventType } = this.props;
    let activityTypes = [];
    return (
      <div className="view-accordion-com">
        <div className="scroll-accordion">
          {map(eventDetails, (item, i) => {
            if (item.eventType !== eventType.id) return null;
            activityTypes.push(item);
          })}
          <CustomTable
            header
            customClass="super-admin-table tbody-hover"
            columns={this.columns}
            data={activityTypes}
            emptyMessage="No tooltips"
            keyExtractor={(item, index) => item.id}
          />
        </div>
      </div>
    );
  }
}

class EditActivityDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      form: {
        detail: '',
        definition: '',
        eventType: '',
        outputFormatId: '',
      },
    };
  }

  editSelected(form) {
    this.setState({
      form,
    });
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal, form } = this.state;
    const { data } = this.props;

    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="small"
        trigger={
          <label
            className="activity-actions-warn mb-0"
            onClick={() => this.editSelected(data)}
          >
            Edit
          </label>
        }
        className="edit-activity-type"
      >
        <Modal.Header>Edit Activity Type</Modal.Header>
        <Modal.Content>
          <ActivityForm
            toggle={() => this.toggle()}
            isEdit={true}
            form={form}
            {...this.props}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

export class EventDetailsCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      format: [],
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;

    return (
      <div className="outcome-admin-types">
        <div>
          <Modal
            open={modal}
            onOpen={() => this.toggle()}
            closeIcon={
              <Icon
                onClick={() => this.toggle()}
                name="close"
                className="closeicon"
              />
            }
            size="small"
            trigger={
              <Button className="Primary-Button">
                <div className="add-icon-activity-add">
                  <Icon name="plus circle" />
                  <div className="add-text">Add Activity Type</div>
                </div>
              </Button>
            }
            className="create-activity-type"
          >
            <Modal.Header>Create Activity Type</Modal.Header>
            <Modal.Content>
              <ActivityForm
                isEdit={false}
                toggle={() => this.toggle()}
                {...this.props}
              />
            </Modal.Content>
          </Modal>
        </div>
      </div>
    );
  }
}

class ActivityForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      definition: '',
      definitionError: false,
      format: [],
      outputFormatId: '',
      isActive: true,
    };
  }

  componentWillMount() {
    const { form } = this.props;

    this.setState({
      definition: get(form, 'definition', ''),
    });
  }

  componentDidMount() {
    const { outputFormatGroupsList, form, isEdit } = this.props;

    if (!isEdit) return null;

    const formatObj = find(outputFormatGroupsList.docs, {
      id: form.outputFormatId,
    });

    const formatList = [];
    formatObj.outputFormat.forEach((format) => {
      formatList.push(format.formatDetails);
    });

    this.setState({
      isActive: form.isActive,
    });

    if (!isEmpty(formatObj)) {
      this.setState({
        format: formatList,
      });
    }
  }

  selectActivityType() {
    const { eventTypes } = this.props;
    return map(eventTypes, (item, i) => {
      return (
        <option key={i} value={item.id}>
          {item.type}
        </option>
      );
    });
  }
  selectOutputFormatters() {
    const { outputFormatGroupsList } = this.props;
    return map(outputFormatGroupsList.docs, (item, i) => {
      return (
        <option key={i} value={item.id}>
          {item.name}
        </option>
      );
    });
  }

  onChangeOutputFormatter(value) {
    const { outputFormatGroupsList } = this.props;

    let formatId = parseInt(value);

    const formatObj = find(outputFormatGroupsList.docs, { id: formatId });

    const formatList = [];
    formatObj.outputFormat.forEach((format) => {
      formatList.push(format.formatDetails);
    });

    if (!isEmpty(formatObj)) {
      this.setState({
        format: formatList,
      });
    }
  }

  renderOutputFormatter() {
    const { format } = this.state;
    return (
      <ul className="ul-col">
        {map(format, (item, i) => {
          return <li key={i}>{item.label}</li>;
        })}
      </ul>
    );
  }

  async handleValidSubmit(event, values) {
    try {
      const { definition, isActive } = this.state;
      const { postActivityType, toggle, isEdit, updateActivityType, form } =
        this.props;

      if (isEmpty(definition)) {
        this.setState({
          definitionError: true,
        });
      } else {
        values.isActive = isActive;
        const data = { ...values, definition };

        if (isEdit) {
          values.definition = definition;
          await updateActivityType(form.id, values);
          toggle();
        } else {
          await postActivityType(data);
          toggle();
        }
      }
    } catch (error) {
      error.log('Handle Submit Activity Type :', error);
    }
  }

  render() {
    const { toggle, activityLoading, isEdit, form, activityUpdateLoading } =
      this.props;

    const { definition, definitionError, format, isActive } = this.state;

    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="venue-form"
        model={isEdit ? form : null}
      >
        <AvField
          label="Title"
          className="org-inputs"
          name="detail"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: 'Title is required',
            },
          }}
        />
        <div className="gap-activity">
          <label>Description</label>

          <CKEditor
            editor={ClassicEditor}
            config={{
              plugins: [Essentials, Bold, Italic, List, Heading, Paragraph],
            }}
            data={definition}
            onBlur={(event, editor) => {
              const definition = editor.getData();
              this.setState({
                definition,
              });
            }}
          />
        </div>
        {definitionError ? (
          <div className="custom-error-label">Description is required</div>
        ) : null}

        <div className="gap-activity">
          <AvField
            label="Activity Type"
            className="org-inputs"
            name="eventType"
            type="select"
            validate={{
              required: {
                value: true,
                errorMessage: 'Activity type is required',
              },
            }}
          >
            <option value="" disabled defaultValue>
              Select a Activity Type
            </option>
            {this.selectActivityType()}
          </AvField>
        </div>

        <div className="gap-activity">
          <AvField
            label="Output Formatter"
            className="org-inputs"
            name="outputFormatId"
            type="select"
            disabled={isEdit}
            onChange={(e) => this.onChangeOutputFormatter(e.target.value)}
            validate={{
              required: {
                value: true,
                errorMessage: 'Output formatter is required',
              },
            }}
          >
            <option value="" disabled defaultValue>
              Select a Output Formatter
            </option>
            {this.selectOutputFormatters()}
          </AvField>
        </div>

        {format && format.length ? (
          <div>
            <label>Output Fields</label>
            {this.renderOutputFormatter()}
          </div>
        ) : null}

        <div className="activate-notify-row">
          <div className="evaluation-activity-toggel-group mt-4">
            <label className="lbl-margin-left mb-0">Active </label>
            <Radio
              toggle
              name="isActive"
              checked={isActive}
              onChange={() => {
                this.setState({ isActive: !isActive });
              }}
            />
          </div>
        </div>

        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            content={isEdit ? 'Save' : 'Create'}
            type="submit"
            className="Primary-Button"
            loading={isEdit ? activityUpdateLoading : activityLoading}
            disabled={isEdit ? activityUpdateLoading : activityLoading}
          />
        </div>
      </AvForm>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activityType: state.admin.activityType,
    eventTypes: state.extra.eventTypes,
    outputFormatGroupsList: state.admin.outputFormatGroupsList,
    activityLoading: state.admin.activityLoading,
    activityUpdateLoading: state.admin.activityUpdateLoading,
    eventDetails: state.extra.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getEventTypes,
      getOutputFormatGroups,
      postActivityType,
      getEventDetails,
      updateActivityType,
    },
    dispatch
  );
};

ParentEventDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(ParentEventDetails);

export { ParentEventDetails };
