/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { FieldArray, useField } from 'formik';
import {
  find,
  flatten,
  isEmpty,
  map,
  size,
  // get
} from 'lodash';
import { Button, Dropdown } from 'semantic-ui-react';

import { SeeMore } from '../../customToggle';
import getMinifiedText from '../../../utils/minifiedText';

export const CustomMultipleSelectionGroupDropDown = ({
  labelText,
  renderHeaders,
  renderItems,
  onChange = null,
  requiredStar,
  values,
  defaultValuesTexts,
  setDisabledOtherFields,
  disabled,
  isView = false, // if this true only show default values in list
  ...props
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [, meta, helpers] = useField({ ...props });

  const { setTouched } = helpers;
  const [text, setText] = useState();
  const { value } = meta;
  const [selectedList, setSelectedList] = React.useState([]);

  useEffect(() => {
    getDefaultValue();
    defaultValuesTexts && setDefaultValuesTexts();

    return () => {
      setSelectedList([]);
    };
  }, []);

  const setDefaultValuesTexts = () => {
    if (defaultValuesTexts && !isEmpty(defaultValuesTexts)) {
      setSelectedList(defaultValuesTexts);
    }
  };

  const getDefaultValue = () => {
    map(renderItems, (items) => {
      map(items, (item) => {
        if (item.value === value) {
          setText(item.text);
        }
      });
    });
  };

  renderHeaders = renderHeaders.filter((renderHeadersItem) =>
    flatten(renderItems).find(
      (renderItem) =>
        Number(renderItem.rest.key) === Number(renderHeadersItem.id)
    )
  );

  const optionList = (id) => {
    return find(renderItems, function (items) {
      return items.length > 0 && items[0].rest && items[0].rest.key === id;
    });
  };

  // const onChangeHandler = value => {
  //   onChange && onChange(value);
  // };

  const onChangeHandler = (e, item, arrayHelpers) => {
    let _selectedList = selectedList;
    if (e.target.checked) {
      arrayHelpers.push(item.value);
      _selectedList.push(item.text);
    } else {
      const idx = values[props.name].indexOf(item.value);
      arrayHelpers.remove(idx);
      _selectedList = _selectedList.filter((obj) => {
        return obj.trim() !== item.text.trim();
      });
    }
    setSelectedList(_selectedList);
  };

  return (
    <div className="formik-custom-multiple-field act-inputs-new">
      <label
        className={requiredStar ? 'required-stars' : ''}
        htmlFor={props.id || props.name}
      >
        {labelText}
      </label>
      {!isView && (
        <Dropdown
          className="dropdown-outline"
          open={isDropdownOpen}
          // allowAdditions
          // selection
          multiple
          // autoClose={false}
          closeOnChange={false}
          closeOnBlur={false}
          fluid
          text={
            text
              ? getMinifiedText(text)
              : isEmpty(selectedList)
                ? props.placeholder
                : `${size(selectedList)} selected`
          }
          error={meta.touched && meta.error}
          onOpen={() => {
            setIsDropdownOpen(true);
            setDisabledOtherFields && setDisabledOtherFields(true);
          }}
          disabled={isDropdownOpen ? false : disabled}
          {...props}
        >
          <Dropdown.Menu className="scrolling">
            {map(renderHeaders, (headers, index) => {
              const optionListItems = optionList(headers.id);
              const isOptionListAvailable = !isEmpty(optionListItems);

              if (isOptionListAvailable)
                return (
                  <Fragment key={index}>
                    {index > 0 && <Dropdown.Divider />}
                    <Dropdown.Header
                      className="dropdown-header"
                      content={headers.content}
                    />
                    <FieldArray
                      name={props.name}
                      render={(arrayHelpers) => (
                        <div>
                          {isOptionListAvailable ? (
                            optionListItems.map((item, optionListItemIdx) => (
                              <Dropdown.Item
                                key={optionListItemIdx}
                                className="sub-header-checkbox"
                              >
                                <label key={item.value}>
                                  <input
                                    name={props.name}
                                    type="checkbox"
                                    value={item.value}
                                    checked={
                                      values[props.name] &&
                                      values[props.name].length > 0 &&
                                      values[props.name].includes(item.value)
                                    }
                                    onChange={(e) =>
                                      onChangeHandler(e, item, arrayHelpers)
                                    }
                                  />
                                  <span className="ml-2">{item.text}</span>
                                </label>
                              </Dropdown.Item>
                            ))
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    />
                  </Fragment>
                );
              // else {
              //   return (
              //     <span className=" text-empty-info">
              //       No results available for {labelText}
              //     </span>
              //   );
              // }
            })}
            <div className="d-flex">
              <div className="ml-auto p-2">
                <Button
                  type="button"
                  className="Primary-Button"
                  onClick={() => {
                    setIsDropdownOpen(false);
                    setTouched(true);
                    setDisabledOtherFields && setDisabledOtherFields(false);
                  }}
                >
                  {'Save'}
                </Button>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
      {meta.touched && meta.error && !isView ? (
        <div className="formik-multiple-dropdown-error">{meta.error}</div>
      ) : !isEmpty(selectedList) ? (
        <ul className="custom-multiple-checkbox-ul">
          {selectedList.length < 4 ? (
            selectedList.map((item, idx) => <li key={idx}>{item}</li>)
          ) : (
            <SeeMore
              minCount={3}
              dataSet={selectedList}
              CustomComponent={(props) => <li>{props.item}</li>}
              isView={false}
            />
          )}
        </ul>
      ) : null}
    </div>
  );
};
