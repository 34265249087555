import * as Yup from 'yup';

import {
  UPPERCASE_REG_EX,
  NUMERIC_REG_EX,
  EMAIL_REG_EX,
  LOWERCASE_REG_EX,
  PHONE_REG_EX,
  SPECIAL_CHARACTER_REG_EX,
} from '../utils/regex';

export const addNewUserByAdminInitialValues = () => {
  return {
    organisationId: '',
    firstName: '',
    surname: '',
    email: '',
    password: '',
    confirmPassword: '',
    positionTitle: '',
    phone: '',
    isTaksoAdminEmail: false,
  };
};

export const addNewUserByAdminValidationSchema = (isTaksoAdminUser = false) => {
  let validationSchema = {
    organisationId: Yup.string().required('Organisation Id is required!'),
    firstName: Yup.string().required('First name is required!'),
    surname: Yup.string().required('Surname is required!'),
    phone: Yup.string()
      .required('Phone number is required')
      .matches(PHONE_REG_EX, 'Please enter the valid format')
      .min(10, 'Must be a minimum of 10 digits!'),
    email: Yup.string()
      .required('Email is required')
      .matches(EMAIL_REG_EX, 'Please enter the valid format'),

    positionTitle: Yup.string().optional(),
  };

  if (isTaksoAdminUser) {
    validationSchema = {
      ...validationSchema,
      password: Yup.string()
        .min(8, 'LENGTH_ERROR')
        .required('LENGTH_ERROR')
        .matches(LOWERCASE_REG_EX, 'LOWERCASE_ERROR')
        .matches(UPPERCASE_REG_EX, 'UPPERCASE_ERROR')
        .matches(NUMERIC_REG_EX, 'NUMERIC_ERROR')
        .matches(SPECIAL_CHARACTER_REG_EX, 'SPECIAL_CHARACTER_ERROR'),
      confirmPassword: Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('password'), null], "Doesn't match the new password"),
    };
  }

  return Yup.object().shape(validationSchema);
};
