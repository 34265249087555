import React, { Fragment } from 'react';
import moment from 'moment';
import Joyride from 'react-joyride';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useState, useRef } from 'react';
// import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Dropdown,
  Modal,
  Button,
  Icon,
  Segment,
  Search,
  Form,
} from 'semantic-ui-react';

import {
  getInvitations,
  getOwnActivities,
  getInvitedActivities,
  clearCurrentActivity,
  acceptInvitation,
  postActivity,
  deleteActivity,
  getUserActivityGroups,
  helpTips,
  postDuplicateActivity,
  getAllGoals,
  getPolicies,
  getOutcomes,
  postActivityGroup,
  deleteProjectGroup,
  updateProjectGroupRename,
  setUserOnboardingOption,
  setVisibleTips,
  setSidebarType,
} from '../../../store/actions';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import {
  CustomSegment,
  EmptyContainer,
  CustomTable,
  Tooltip,
  BeaconComponent,
  FeatureWrapper,
} from '../../../components';
import addIcon from '../../../assets/add-icon.svg';

import {
  isEmpty,
  map,
  compact,
  find,
  filter,
  uniq,
  includes,
  size,
  debounce,
} from '../../../utils/lodash';

import {
  getActivityDescription,
  getActivityStatus,
  getActivityType,
  stopPropagation,
  threeDotSeparator,
} from '../../../utils';
import { NotificationManager } from 'react-notifications';

import './activity.scss';
import { isBrowser, isTablet } from 'react-device-detect';

import MessageComponent from '../../../components/message';
import AvRadioGroup from 'availity-reactstrap-validation/lib/AvRadioGroup';
import AvRadio from 'availity-reactstrap-validation/lib/AvRadio';
import { isFeature } from '../../../utils/activity/userPermission';
import {
  newActivityInitialValues,
  NewActivityValidationSchema,
} from '../../../validations/newActivityRecordValidation.js';
import { CustomDropdown, CustomInput } from '../../../components/form';
import { SIDEBAR_TYPES } from '../../../utils/constant.js';

export const ActivityForm = (props) => {
  const [planType, setPlanType] = useState(null);
  const navigate = useNavigate();

  const handleValidSubmit = async (values, setSubmitting) => {
    try {
      const {
        postActivity,
        user: {
          organisation: { settings = [] },
        },
      } = props;
      const [config = {}] = settings;
      const { crossReferenceLabel, crossReferenceDocument, cultureCounts } =
        config;

      if (!isSingleActivity()) {
        values.activityPlanTypeId = 1;
      }

      if (crossReferenceDocument && crossReferenceLabel) {
        values.referenceCodeLabel = crossReferenceLabel;
        values.referenceDocument = crossReferenceDocument;
      }
      values.isCultureCount = cultureCounts ? true : false;
      const actID = await postActivity(values);
      setSubmitting(false);
      navigate(`/activities/${actID}/goal`);
    } catch (error) {
      console.error('ActivityForm ~ handleValidSubmit ~ error', error);
      setSubmitting(false);
    }
  };

  const onChangePlayType = (value) => {
    setPlanType(value);
  };

  const isSingleActivity = () => {
    const getTypes = getPlanTypes();
    let result = getTypes.filter((type) => type.name === 'Single Activity');
    const typeLength = getTypes.length <= 1;
    return typeLength === true && !isEmpty(result) === true ? false : true;
  };

  const getPlanTypes = () => {
    const { actPlanTypes, user } = props;
    const types = map(actPlanTypes, (plan, i) => {
      if (plan.name === 'Single Activity' && !isFeature(user, 'single_program'))
        return null;
      if (plan.name === 'Grants Program' && !isFeature(user, 'grant_program'))
        return null;
      if (
        plan.name === 'Festival Program' &&
        !isFeature(user, 'festival_program')
      )
        return null;
      if (
        plan.name === 'Multi Activity Program' &&
        !isFeature(user, 'multi_activity_program')
      )
        return null;
      return {
        key: i,
        value: plan.id,
        text: plan.name,
      };
    });
    return compact(types);
  };

  const { actPostLoading } = props;
  const getTypes = getPlanTypes();

  return (
    <Formik
      initialValues={newActivityInitialValues}
      validationSchema={NewActivityValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleValidSubmit(values, setSubmitting);
      }}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} className="new-activity-record">
          <CustomInput
            name="name"
            id="name"
            labelText="Activity Title or Temporarily Title"
            placeholder="Title"
            autoComplete="off"
          />

          {isSingleActivity() && (
            <CustomDropdown
              search
              selection
              labelText="Record"
              name="activityPlanTypeId"
              id="activityPlanTypeId"
              getValue={(value) => {
                onChangePlayType(value);
              }}
              options={getPlanTypes()}
              placeholder="Select Activity Type"
            />
          )}

          {!isEmpty(`${planType}`) && (
            <div className="sub-label add-bottom">
              {getActivityDescription(`${planType}`)}
              {''}
            </div>
          )}
          <div className="model-btn-align-right">
            <Button
              loading={actPostLoading}
              disabled={isEmpty(getTypes) ? true : actPostLoading}
              content="Save"
              type="submit"
              className="Primary-Button"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

class ActivityCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    return (
      <div className="add-goal">
        <Modal
          trigger={
            /*
             * Note:  add-activity class name used for help tip showing class name (don't change it or remove it)
             */
            <Button
              onClick={() => this.toggle()}
              className="Primary-Button add-activity"
            >
              <div className="add-icon-activity-add">
                <img className="icons-add" src={addIcon} alt="icon" />
                <div className="add-text">Add Activity Record</div>
              </div>
            </Button>
          }
          size="tiny"
          open={modal}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
          className="new-activity-record"
        >
          <Modal.Header>New Activity Record</Modal.Header>
          <Modal.Content>
            <ActivityForm toggle={() => this.toggle()} {...this.props} />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class InvitedActivities extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderColumns() {
    const { attributeName } = this.props;
    let name = attributeName.invited;
    return [
      {
        width: 5,
        title: (
          <div className="sort-icon">
            <div className="lib-header">Activity Name</div>
            <Icon
              className="icon-adjustment"
              name={name === 'name' ? this.getIcon() : 'sort'}
              onClick={(value) => this.props.sortInvitedActivities('name')}
            />
          </div>
        ),
        render: (data) => {
          return (
            <div className="activity-name">
              <div
                className={`circle circle-bottom activity-${getActivityStatus(
                  data.statusId
                )}`}
              />
              <label>
                <Link
                  to={`/activities/${data.id}`}
                  className="enter-activity-link"
                >
                  {data.name ? data.name : ''}
                </Link>
              </label>
            </div>
          );
        },
      },
      {
        width: 2,
        title: 'Type',
        render: (data) => {
          const actType = getActivityType(data);
          return actType.type;
        },
      },
      {
        width: 2,
        title: 'Owner',
        render: (data) => {
          return data.owner && data.owner.name ? data.owner.name : '-';
        },
      },
      {
        width: 2,
        title: (
          <div className="sort-icon">
            <div>Date Created</div>
            <Icon
              className="icon-adjustment"
              name={name === 'createdDateTime' ? this.getIcon() : 'sort'}
              onClick={(value) =>
                this.props.sortInvitedActivities('createdDateTime')
              }
            />
          </div>
        ),
        render: (data) => {
          return moment(data.createdDateTime).format('DD MMMM YYYY');
        },
      },
      {
        width: 2,
        title: (
          <div className="sort-icon">
            <div>Last Updated</div>
            <Icon
              className="icon-adjustment"
              name={name === 'lastEditedDateTime' ? this.getIcon() : 'sort'}
              onClick={(value) =>
                this.props.sortInvitedActivities('lastEditedDateTime')
              }
            />
          </div>
        ),
        render: (data) => {
          return moment(data.lastEditedDateTime).format('DD MMMM YYYY');
        },
      },
      {
        width: 3,
        title: '',
        render: (data) => {
          return (
            <div className="activity-actions">
              <Link to={`/activities/${data.id}`}>
                <label className="activity-actions-enter-activity">
                  Enter Activity
                </label>
              </Link>
            </div>
          );
        },
      },
      {
        width: 1,
        render: (data) => {
          return (
            <div className="invited">
              <Dropdown
                icon="ellipsis vertical"
                direction="left"
                inline
                selectOnBlur={false}
              >
                <Dropdown.Menu>
                  {this.props.user.role !== 'guest' ? (
                    <Dropdown.Item
                      as="a"
                      href={`/activity/${data.id}/preview`}
                      target="_blank"
                    >
                      <label className="black-text">View Report</label>
                    </Dropdown.Item>
                  ) : null}
                  <Dropdown.Item
                    as="a"
                    href={`mailto:${data.owner.email}?subject=Request withdrawal from activity - ${data.name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <label className="remove-activity ">
                      Request Withdrawal
                    </label>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ];
  }

  getIcon() {
    const { order } = this.props;
    if (order.invited === 'asc') {
      return 'sort up';
    } else {
      return 'sort down';
    }
  }

  pageChange(page) {
    const {
      getInvitedActivities,
      filter,
      actType,
      setPage,
      attributeName,
      order,
      searchQuery,
      perPage,
    } = this.props;
    getInvitedActivities(
      filter.invited,
      actType.invited,
      page,
      perPage,
      attributeName.invited,
      order.invited,
      searchQuery.invited
    );
    setPage(page);
  }

  render() {
    const { data, currentPage } = this.props;
    return (
      <CustomTable
        header
        columns={compact(this.renderColumns())}
        data={data.docs}
        customClass="home-activity tbody-hover"
        pagination
        handlePaginationChange={(page) => this.pageChange(page)}
        page={currentPage}
        noOfPages={data.pages}
        keyExtractor={(item, index) => item.id}
      />
    );
  }
}

class ActivityDelete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async deleteActivity(id) {
    try {
      const { filter, actType, deleteActivity, page, group } = this.props;
      await deleteActivity(id, filter.owned, actType.owned, group.owned, page);
      this.toggle();
    } catch (error) {
      NotificationManager.error(
        "We couldn't delete the activity",
        'Something went wrong'
      );
    }
  }

  render() {
    const { modal } = this.state;
    const { activity, trigger } = this.props;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        onClick={(e) => stopPropagation(e)}
        onFocus={(e) => stopPropagation(e)}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="mini"
        trigger={trigger}
      >
        <Modal.Header>
          {threeDotSeparator({
            name: activity.name,
            length: 33,
            prefix: 'Delete Activity',
            separator: '-',
            isPopUp: true,
          })}
        </Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this activity?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button className="modal-cancel" onClick={() => this.toggle()}>
            No
          </Button>
          <Button
            className="modal-proceed"
            onClick={() => this.deleteActivity(activity.id)}
          >
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class GroupUnAssign extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async unAssignGroup(id) {
    try {
      const { filter, actType, postActivityGroup, page, group, user } =
        this.props;
      const values = { unassign: true };

      await postActivityGroup(
        id,
        values,
        user.id,
        filter,
        actType,
        group,
        page
      );
      this.toggle();
    } catch (error) {
      NotificationManager.error(
        "We couldn't unassign the group",
        'Something went wrong'
      );
    }
  }

  render() {
    const { modal } = this.state;
    const { data, trigger } = this.props;

    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        onClick={(e) => stopPropagation(e)}
        onFocus={(e) => stopPropagation(e)}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="mini"
        trigger={trigger}
      >
        <Modal.Header>Unassign Group</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to unassign this group from this activity?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.toggle()} className="modal-cancel">
            No
          </Button>
          <Button
            onClick={() => this.unAssignGroup(data.id)}
            className="modal-proceed"
            content="Yes"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

class AddToGroup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { data, trigger } = this.props;
    return (
      <Modal
        open={modal}
        onClick={(e) => stopPropagation(e)}
        onFocus={(e) => stopPropagation(e)}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={(e) => this.toggle(e)}
            name="close"
            className="closeicon"
          />
        }
        size="tiny"
        trigger={trigger}
      >
        <Modal.Header>
          {data.projectGroupId === null ? 'Add to group' : 'Change group'}
        </Modal.Header>
        <Modal.Content>
          <GroupForm toggle={() => this.toggle()} {...this.props} />
        </Modal.Content>
      </Modal>
    );
  }
}

class GroupForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isExistingGroup: true,
      edit: false,
    };
  }

  async handleValidSubmit(event, values) {
    try {
      const {
        postActivityGroup,
        data,
        toggle,
        user,
        filter,
        actType,
        group,
        currentPage,
      } = this.props;

      const { edit } = this.state;

      delete values.groupSelect;

      if (edit) {
        values.name = values.newName;
        delete values.newName;
      }

      await postActivityGroup(
        data.id,
        values,
        user.id,
        filter,
        actType,
        group,
        currentPage
      );

      toggle();
    } catch (error) {
      NotificationManager.error(
        "We couldn't submit the activity",
        'Something went wrong'
      );
    }
  }
  renderGroups() {
    return map(this.props.projectGroup, (item, i) => (
      <option key={i} value={item.id}>
        {item.name}
      </option>
    ));
  }

  selectType(value) {
    this.setState({
      isExistingGroup: value === 'existingGroup' ? true : false,
    });
  }

  componentWillMount() {
    const { data } = this.props;

    if (data.projectGroupId !== null) {
      this.setState({
        edit: true,
      });
    }
  }

  render() {
    const { toggle, data, groupAddLoading } = this.props;
    const { isExistingGroup, edit } = this.state;

    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        autoComplete="off"
        model={edit ? data : null}
      >
        <AvRadioGroup
          inline
          name="groupSelect"
          required
          className="group-bg"
          value={isExistingGroup ? 'existingGroup' : 'newGroup'}
        >
          <AvRadio
            label="Existing Group"
            value="existingGroup"
            onChange={(e) => this.selectType(e.target.value)}
          />
          <AvRadio
            label="New Group"
            value="newGroup"
            onChange={(e) => this.selectType(e.target.value)}
          />
        </AvRadioGroup>

        {isExistingGroup ? (
          <AvField
            label="Select an Existing Group"
            className="org-inputs"
            name="projectGroupId"
            type="select"
            validate={{
              required: {
                value: true,
                errorMessage: 'Existing Group is required',
              },
            }}
          >
            <option value="" disabled defaultValue>
              Select a group
            </option>
            {this.renderGroups()}
          </AvField>
        ) : null}

        {!isExistingGroup ? (
          <AvField
            label="New Group Name"
            className="org-inputs"
            name={edit ? 'newName' : 'name'}
            type="text"
            validate={{
              required: {
                value: true,
                errorMessage: 'New Group name is required',
              },
            }}
            autoComplete="off"
          />
        ) : null}

        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            content={'Save'}
            type="submit"
            className="Primary-Button"
            loading={groupAddLoading}
            disabled={groupAddLoading}
          />
        </div>
      </AvForm>
    );
  }
}

class DuplicateModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { trigger, available } = this.props;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size={available ? 'tiny' : 'small'}
        trigger={trigger}
      >
        <Modal.Header>
          <div className="header-goal-duplicate">
            {available
              ? 'Duplicate Activity'
              : 'Organisation Goal to be Addressed'}
          </div>
        </Modal.Header>
        <Modal.Content>
          <DuplicateForm toggle={() => this.toggle()} {...this.props} />
        </Modal.Content>
      </Modal>
    );
  }
}

export const DuplicateForm = (props) => {
  const [selectedGoal, setSelectedGoal] = useState('');
  const [secondaryPolicyId, setSecondaryPolicyId] = useState('');
  const [secondaryPolicyName, setSecondaryPolicyName] = useState('');
  const [primaryPolicyId, setPrimaryPolicyId] = useState('');
  const [primaryPolicyName, setPrimaryPolicyName] = useState('');
  const [goalDescription, setGoalDescription] = useState('');

  const navigate = useNavigate();
  const formRef = useRef(null);

  //fix
  const handleValidSubmit = async (event, values) => {
    try {
      const { postDuplicateActivity, data } = props;

      const { goalsPolicyMatching, available } = props;

      const goalName = find(goalsPolicyMatching, {
        id: parseInt(selectedGoal),
      });

      let allData = {};

      if (available) {
        allData.name = values.name;
      } else {
        allData.name = values.name;
        allData.primaryPolicyId = primaryPolicyId;
        allData.secondaryPolicyId = secondaryPolicyId;
        allData.goal = goalName.goalTitle;
        allData.goalDescription = goalDescription;
      }

      const actID = await postDuplicateActivity(data.id, allData);

      navigate(`/activities/${actID}/goal?duplicate=true`);
    } catch (error) {
      console.error('DuplicateForm ~ handleValidSubmit ~ error', error);
    }
  };

  const renderGoals = () => {
    const { goalsPolicyMatching } = props;
    return map(goalsPolicyMatching, (item, i) => (
      <option key={i} value={item.id}>
        {item.goalTitle}
      </option>
    ));
  };

  const setGoal = (value) => {
    setSelectedGoal(value);
  };

  const renderPrimaryPolicy = () => {
    const { goals, policies } = props;

    const getGoal = find(goals, { id: parseInt(selectedGoal) });
    const policy = find(policies, { id: getGoal.primaryPolicyId });
    if (isEmpty(policy)) return null;

    setPrimaryPolicyId(policy.id);
    setPrimaryPolicyName(policy.policyName);

    return <div className="policy-duplicate">{policy.policyName}</div>;
  };

  const secondaryPolicy = () => {
    const { goals, policies, data } = props;

    const getGoal = find(goals, { id: parseInt(selectedGoal) });

    let secondary = {};
    for (const item of getGoal.secondaryPolicies) {
      if (data.primaryPolicyId !== getGoal.primaryPolicyId) {
        if (item.policyId === data.primaryPolicyId) {
          secondary = { ...item };
        }
      } else {
        if (item.policyId === data.secondaryPolicyId) {
          secondary = { ...item };
        }
      }
    }

    const policy = find(policies, { id: secondary.policyId });

    if (isEmpty(policy)) return null;

    setSecondaryPolicyId(policy.id);
    setSecondaryPolicyName(policy.policyName);

    return <div className="policy-duplicate">{policy.policyName}</div>;
  };

  const renderGoalDescription = () => {
    const { goals } = props;
    const description = find(goals, { id: parseInt(selectedGoal) });

    if (isEmpty(description)) return null;

    setGoalDescription(description.goalDescription);

    return description.goalDescription;
  };

  const renderObjectiveDomainOne = () => {
    const { outcomes } = props;

    const objectives = filter(outcomes, { policyId: primaryPolicyId });

    if (isEmpty(objectives)) return null;

    return (
      <div className="objective-list-goal-container">
        <label className="objective-list-goal-header">
          {primaryPolicyName || ''} Domain Objectives - Secondary Policy Domain
        </label>
        {map(objectives, (item, i) => {
          if (!item.isEnable) return null;
          return (
            <label className="objective-list-goal-list" key={i}>
              &#8226; {item.title}
            </label>
          );
        })}
      </div>
    );
  };

  const renderObjectiveDomainTwo = () => {
    const { outcomes } = props;
    const objectives = filter(outcomes, { policyId: secondaryPolicyId });

    if (isEmpty(objectives)) return null;

    return (
      <div className="objective-list-goal-container">
        <label className="objective-list-goal-header">
          {secondaryPolicyName || ''} Domain Objectives - Secondary Policy
          Domain
        </label>
        {map(objectives, (item, i) => {
          if (!item.isEnable) return null;
          return (
            <label className="objective-list-goal-list" key={i}>
              &#8226; {item.title}
            </label>
          );
        })}
      </div>
    );
  };

  const {
    toggle,
    notMatchingDomains,
    matchingDomains,
    available,
    duplicateActLoading,
  } = props;

  return (
    <div>
      {notMatchingDomains ? (
        <MessageComponent
          msg="Unable to duplicate. The activity you are attempting to duplicate was created using a previous goal. There are no current goals with matching domains. Please recreate this activity as a new record"
          color="red"
        />
      ) : (
        <AvForm
          ref={formRef}
          onValidSubmit={(event, values) => handleValidSubmit(event, values)}
        >
          {matchingDomains ? (
            <React.Fragment>
              <MessageComponent
                msg="This activity was created using a previous goal. To duplicate this activity, you need to select a current goal with matching domains"
                color="red"
              />
              <br />
            </React.Fragment>
          ) : null}

          {available ? (
            <AvField
              type="text"
              name="name"
              label="New Activity Name"
              className="org-inputs"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'New activity name is required',
                },
              }}
            ></AvField>
          ) : null}

          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                {matchingDomains ? (
                  <AvField
                    type="text"
                    name="name"
                    label="New Activity Name"
                    className="org-inputs"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'New activity name is required',
                      },
                    }}
                  ></AvField>
                ) : null}

                {matchingDomains ? (
                  <AvField
                    className="act-inputs"
                    type="select"
                    name="primary"
                    label={<div>Select Goal</div>}
                    onChange={(e) => setGoal(e.target.value)}
                  >
                    <option value="" disabled>
                      Select a Goal
                    </option>
                    {renderGoals()}
                  </AvField>
                ) : null}
              </Grid.Column>
              {selectedGoal ? (
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <div className="objective-list-goal-container">
                    <label className="objective-list-goal-header">
                      Goal Description
                    </label>
                    <label className="objective-description">
                      {renderGoalDescription()}
                    </label>
                  </div>
                </Grid.Column>
              ) : null}
            </Grid.Row>
            {selectedGoal ? (
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <div className="form-group">
                    <label className="">
                      Primary Policy Domain for Activity
                    </label>
                    <label className="act-inputs form-control primary-goal-container">
                      {renderPrimaryPolicy()}
                    </label>
                    <small className="form-text text-muted">
                      Your primary policy domain will be populated based on the
                      selected goal
                    </small>
                  </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {renderObjectiveDomainOne()}
                </Grid.Column>
              </Grid.Row>
            ) : null}

            {selectedGoal ? (
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <div className="form-group">
                    <label className="">
                      Secondary Policy Domain for Activity
                    </label>
                    <label className="act-inputs form-control primary-goal-container">
                      {secondaryPolicy()}
                    </label>
                    <small className="form-text text-muted">
                      Your secondary policy domain will be populated based on
                      the selected goal
                    </small>
                  </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {renderObjectiveDomainTwo()}
                </Grid.Column>
              </Grid.Row>
            ) : null}
          </Grid>

          <div className="model-buttons">
            <Button
              className="modal-cancel"
              type="button"
              onClick={() => toggle()}
            >
              Cancel
            </Button>
            <Button
              content="Create"
              type="submit"
              className="modal-proceed"
              loading={duplicateActLoading}
              disabled={duplicateActLoading}
            />
          </div>
        </AvForm>
      )}
    </div>
  );
};

class OwnedActivities extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      available: false,
      goalsState: '',
      matchingDomains: false,
      notMatchingDomains: false,
      goalsPolicyMatching: [],
      duplicate: true,
      singleData: '',
    };
  }

  getIcon() {
    const { order } = this.props;
    if (order.owned === 'asc') {
      return 'sort up';
    } else {
      return 'sort down';
    }
  }

  renderColumns() {
    const { user, sortOwnedActivities, attributeName } = this.props;
    let name = attributeName.owned;
    const {
      notMatchingDomains,
      matchingDomains,
      goalsPolicyMatching,
      singleData,
      available,
      currentPage,
    } = this.state;
    return [
      {
        width: 5,
        title: (
          <div className="sort-icon">
            <div className="lib-header">Activity Name</div>
            <Icon
              className="icon-adjustment"
              name={name === 'name' ? this.getIcon() : 'sort'}
              onClick={(value) => sortOwnedActivities('name')}
            />
          </div>
        ),
        render: (data) => {
          return (
            <div className="activity-name">
              <div
                className={`circle circle-bottom activity-${getActivityStatus(
                  data.statusId
                )}`}
              />
              <label>
                <Link
                  to={`/activities/${data.id}/goal`}
                  className="enter-activity-link"
                >
                  {data.name ? data.name : ''}
                </Link>
              </label>
            </div>
          );
        },
      },
      {
        width: 2,
        title: 'Type',
        render: (data) => {
          const actType = getActivityType(data);
          return actType.type;
        },
      },
      {
        width: 3,
        title: (
          <div className="sort-icon">
            <div>Date Created</div>
            <Icon
              name={name === 'createdDateTime' ? this.getIcon() : 'sort'}
              className="icon-adjustment"
              onClick={(value) => sortOwnedActivities('createdDateTime')}
            />
          </div>
        ),
        render: (data) => {
          return moment(data.createdDateTime).format('DD MMMM YYYY');
        },
      },
      {
        width: 3,
        title: (
          <div className="sort-icon">
            <div>Last Updated</div>
            <Icon
              className="icon-adjustment"
              name={name === 'lastEditedDateTime' ? this.getIcon() : 'sort'}
              onClick={(value) => sortOwnedActivities('lastEditedDateTime')}
            />
          </div>
        ),
        render: (data) => {
          return moment(data.lastEditedDateTime).format('DD MMMM YYYY');
        },
      },
      {
        width: 3,
        title: '',
        render: (data) => {
          return (
            <div className="activity-actions">
              <Link to={`/activities/${data.id}/goal`}>
                <label className="activity-actions-enter-activity">
                  Enter Activity
                </label>
              </Link>
            </div>
          );
        },
      },
      {
        title: '',
        render: (data) => {
          return (
            <div className="owned">
              <Dropdown
                icon="ellipsis vertical"
                direction="left"
                inline
                selectOnBlur={false}
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    as="a"
                    href={`/activity/${data.id}/preview`}
                    target="_blank"
                  >
                    <div className="black-text">View Report</div>
                  </Dropdown.Item>
                  {data.statusId === 3 && user.role === 'guest' ? null : (
                    <FeatureWrapper
                      featureName="activity_participants"
                      children={
                        <Dropdown.Item
                          as="a"
                          href={`/activity/${data.id}/participants`}
                        >
                          <div className="black-text">View Team</div>
                        </Dropdown.Item>
                      }
                    />
                  )}

                  <FeatureWrapper
                    featureName="duplicate_activity"
                    children={
                      data.statusId === 2 || data.statusId === 3 ? (
                        <>
                          {data.activityPlanTypeId === 1 ? (
                            <DuplicateModal
                              notMatchingDomains={notMatchingDomains}
                              matchingDomains={matchingDomains}
                              goalsPolicyMatching={goalsPolicyMatching}
                              {...this.props}
                              data={singleData}
                              available={available}
                              trigger={
                                <Dropdown.Item
                                  className="dropdown-customItem"
                                  onClick={() => this.checkGoalAvailable(data)}
                                >
                                  <div className="black-text">Duplicate</div>
                                </Dropdown.Item>
                              }
                            />
                          ) : null}
                        </>
                      ) : null
                    }
                  />

                  <FeatureWrapper
                    featureName="group_activities"
                    children={
                      <AddToGroup
                        {...this.props}
                        data={data}
                        currentPage={currentPage}
                        trigger={
                          <Dropdown.Item
                            onClick={(e) => stopPropagation(e)}
                            onFocus={(e) => stopPropagation(e)}
                          >
                            <div className="black-text">
                              {data.projectGroupId === null
                                ? 'Add to group'
                                : 'Change group'}
                            </div>
                          </Dropdown.Item>
                        }
                      />
                    }
                  />

                  {data.projectGroupId !== null ? (
                    <GroupUnAssign
                      {...this.props}
                      data={data}
                      page={currentPage}
                      trigger={
                        <Dropdown.Item>
                          <div className="black-text">Remove from group</div>
                        </Dropdown.Item>
                      }
                    />
                  ) : null}

                  {data.statusId === 1 ? (
                    data.parentId ? null : (
                      <ActivityDelete
                        activity={data}
                        page={currentPage}
                        {...this.props}
                        trigger={
                          <Dropdown.Item className="dropdown-customItem">
                            <div className="remove-activity">Remove</div>
                          </Dropdown.Item>
                        }
                      />
                    )
                  ) : null}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ];
  }

  async componentDidMount() {
    const { getAllGoals, getPolicies, getOutcomes } = this.props;

    await Promise.all([getAllGoals(), getPolicies(), getOutcomes()]);
  }

  async checkGoalAvailable(data) {
    const { goals } = this.props;
    //goals- all the goals from one particular org
    const available = find(goals, { goalTitle: data.goal });

    let goalsPolicyMatching = [];

    for (const policy of goals) {
      if (policy.primaryPolicyId === data.primaryPolicyId) {
        const findItem = find(policy.secondaryPolicies, {
          policyId: data.secondaryPolicyId,
        });
        if (includes(policy.secondaryPolicies, findItem)) {
          goalsPolicyMatching.push(policy);
        }
      } else if (policy.primaryPolicyId === data.secondaryPolicyId) {
        const findItem = find(policy.secondaryPolicies, {
          policyId: data.primaryPolicyId,
        });
        if (includes(policy.secondaryPolicies, findItem)) {
          goalsPolicyMatching.push(policy);
        }
      }
    }

    this.setState({
      singleData: data,
    });

    if (isEmpty(available)) {
      if (isEmpty(goalsPolicyMatching)) {
        //no longer used by org and not same policy domains
        this.setState({
          notMatchingDomains: true,
          available: false,
        });
      } else {
        //no longer used by org and same policy domains
        this.setState({
          matchingDomains: true,
          notMatchingDomains: false,
          goalsPolicyMatching: uniq(goalsPolicyMatching),
          available: false,
        });
      }
    } else {
      const checkSecondary = find(available.secondaryPolicies, {
        policyId: data.secondaryPolicies,
      });
      if (
        available.primaryPolicyId !== data.primaryPolicyId &&
        isEmpty(checkSecondary)
      ) {
        this.setState({
          notMatchingDomains: true,
          available: false,
          matchingDomains: false,
        });
      } else {
        this.setState({
          available: true,
          notMatchingDomains: false,
          matchingDomains: false,
        });
      }
    }
  }

  pageChange(page) {
    const {
      getOwnActivities,
      filter,
      actType,
      group,
      setPage,
      attributeName,
      order,
      searchQuery,
      perPage,
    } = this.props;
    setPage(page);
    getOwnActivities(
      filter.owned,
      actType.owned,
      group.owned,
      page,
      perPage,
      attributeName.owned,
      order.owned,
      searchQuery.owned
    );
  }

  render() {
    const { data, currentPage } = this.props;

    return (
      <CustomTable
        header
        columns={compact(this.renderColumns())}
        data={data.docs}
        customClass="home-activity tbody-hover"
        pagination
        handlePaginationChange={(page) => this.pageChange(page)}
        page={currentPage}
        noOfPages={data.pages}
        keyExtractor={(item, index) => item.id}
      />
    );
  }
}

class NewActivities extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          width: 5,
          title: <div className="lib-header">Activity Name</div>,
          render: (data) => {
            return (
              <div className="activity-name">
                <div
                  className={`circle circle-bottom activity-${getActivityStatus(
                    data.statusId
                  )}`}
                />
                <label>{data.name ? data.name : ''}</label>
              </div>
            );
          },
        },
        {
          width: 1,
          title: 'Type',
          render: (data) => {
            const actType = getActivityType(data);
            return actType.type;
          },
        },
        {
          width: 2,
          title: 'Owner',
          render: (data) => {
            return data.owner ? data.owner.name : '';
          },
        },
        {
          width: 2,
          title: 'Date Created',
          render: (data) => {
            return moment(data.createdDateTime).format('DD MMMM YYYY');
          },
        },
        {
          width: 2,
          title: 'Last Updated',
          render: (data) => {
            return moment(data.lastEditedDateTime).format('DD MMMM YYYY');
          },
        },
        {
          width: 4,
          title: '',
          render: (data) => {
            return (
              <div className="activity-actions">
                <Button
                  content="Accept"
                  type="submit"
                  className="Primary-Button"
                  onClick={() => this.acceptAct(data.id, true)}
                />
                <label
                  onClick={() => this.acceptAct(data.id, false)}
                  className="reject-text reject"
                >
                  Reject
                </label>
              </div>
            );
          },
          textAlign: 'center',
        },
      ],
      currentPage: 1,
    };
  }

  acceptAct(projectId, accept) {
    const { acceptInvitation } = this.props;
    acceptInvitation(projectId, accept);
  }

  pageChange(page) {
    const { getInvitations } = this.props;
    this.setState(
      {
        currentPage: page,
      },
      () => {
        getInvitations(page);
      }
    );
  }

  render() {
    const { currentPage, columns } = this.state;
    const { data } = this.props;

    return (
      <CustomTable
        header
        columns={compact(columns)}
        data={data.docs}
        customClass="home-activity tbody-hover"
        pagination
        handlePaginationChange={(page) => this.pageChange(page)}
        page={currentPage}
        noOfPages={data.pages}
        keyExtractor={(item, index) => item.id}
      />
    );
  }
}

const steps = [
  {
    target: '.header-title-text',
    content: 'This page lists all of the activities you own or are invited to.',
    placement: 'bottom',
    disableBeacon: true,
  },
  {
    target: '.add-activity',
    content: 'Use this to start a new activity',
    placement: 'left',
  },
  {
    target: '.owned-activities-container',
    content:
      'All activities that you own will appear in this box. The coloured tab will tell you the status of the activity. Activities that you have been invited to contribute to will appear in a separate section under this.',
    placement: 'top',
  },
  {
    target: '.activities-filter-container',
    content: 'You can filter the activities that appear in the box here.',
    placement: 'left',
  },
];

const options = [
  {
    key: 'Ongoing',
    text: <>Ongoing Activities</>,
    value: 'ongoing',
  },
  {
    key: 'Completed',
    text: <>Completed Activities</>,
    value: 'completed',
  },
  {
    key: 'All Activities',
    text: <>All activities</>,
    value: 'all',
  },
];

const act_type_options = [
  {
    key: 'All',
    text: <>All types</>,
    value: 'all',
  },
  {
    key: 'Single',
    text: <>Single</>,
    value: 'single',
  },
  {
    key: 'Grant',
    text: <>Grant</>,
    value: 'grant',
  },
  {
    key: 'Festival',
    text: <>Festival</>,
    value: 'festival',
  },
  {
    key: 'Multi Activity',
    text: <>Multi Activity</>,
    value: 'multi-activity',
  },
  {
    key: 'Linked',
    text: <>Linked</>,
    value: 'linked',
  },
];

class ActivityHome extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        owned: 'ongoing',
        invited: 'ongoing',
      },
      actType: {
        owned: 'all',
        invited: 'all',
      },
      infoSteps: false,
      group: {
        owned: 'null',
      },
      perPage: 10,
      ownedActivitiesCurrentPage: 1,
      invitedActivitiesCurrentPage: 1,
      order: {
        owned: 'desc',
        invited: 'desc',
      },
      attributeName: {
        owned: 'createdDateTime',
        invited: 'createdDateTime',
      },
      searchQuery: {
        owned: null,
        invited: null,
      },
      defaultPage: 1,
    };
  }

  async componentDidMount() {
    const {
      clearCurrentActivity,
      getInvitations,
      getUserActivityGroups,
      user: { options },
      user,
    } = this.props;

    try {
      if (!user.onboardingOption.activityTips) {
        this.setState({
          infoSteps: true,
        });
      }
      getInvitations(this.state.defaultPage);
      getUserActivityGroups();

      clearCurrentActivity();
      if (
        !isEmpty(options['activity-filter']) &&
        !isEmpty(options['activity-type-filter']) &&
        !isEmpty(options['activity-group-filter'])
      ) {
        this.setFilterOnUser(
          'owned',
          options['activity-filter'].owned,
          options['activity-type-filter'].owned,
          options['activity-group-filter'].owned
        );
        this.setFilterOnUser(
          'invited',
          options['activity-filter'].invited,
          options['activity-type-filter'].invited
        );
      } else {
        const { filter, actType, group } = this.state;
        this.setFilterOnUser('owned', filter.owned, actType.owned, group.owned);
        this.setFilterOnUser('invited', filter.invited, actType.invited);
      }
    } catch (error) {
      // console.error('ActivityHome -> componentDidMount -> error', error);
    }
  }

  componentDidUpdate() {
    const { visibleTips, setSidebarType, setVisibleTips } = this.props;

    if (visibleTips) {
      setSidebarType(SIDEBAR_TYPES[0], false);
      setVisibleTips(false);
      // check help panel "show me around" btn clicked <- block in other updating statuses
      this.setState({ infoSteps: visibleTips });
    }
  }

  setFilterOnUser(type, filter, actType, group) {
    let {
      helpTips,
      user: { options },
      getOwnActivities,
      getInvitedActivities,
    } = this.props;

    if (isEmpty(options['activity-filter'])) {
      options['activity-filter'] = {};
    }
    if (isEmpty(options['activity-type-filter'])) {
      options['activity-type-filter'] = {};
    }
    if (isEmpty(options['activity-group-filter'])) {
      options['activity-group-filter'] = {};
    }

    options['activity-filter'][type] = filter;
    options['activity-type-filter'][type] = actType;
    options['activity-group-filter'][type] = group;

    this.setState(
      {
        filter: options['activity-filter'],
        actType: options['activity-type-filter'],
        group: options['activity-group-filter'],
      },
      () => {
        switch (type) {
          case 'owned':
            getOwnActivities(
              filter,
              actType,
              group,
              this.state.defaultPage,
              this.state.perPage,
              this.state.attributeName.owned,
              this.state.order.owned,
              this.state.searchQuery.owned
            );
            this.ownedActivitiesSetPage(this.state.defaultPage);
            break;
          case 'invited':
            getInvitedActivities(
              filter,
              actType,
              this.state.defaultPage,
              this.state.perPage,
              this.state.attributeName.invited,
              this.state.order.invited,
              this.state.searchQuery.invited
            );
            this.invitedActivitiesSetPage(this.state.defaultPage);
            break;
          default:
            break;
        }
        helpTips(options);
      }
    );
  }

  getJoyRide(data) {
    const { user, setUserOnboardingOption, setVisibleTips } = this.props;
    const { type } = data;

    if (type === 'tour:end') {
      this.setState(
        {
          infoSteps: false,
        },
        () => {
          if (!user.onboardingOption.activityTips) {
            // only trigger for new user/ users not toured whole trip
            user.onboardingOption.activityTips = true;
            setUserOnboardingOption(user); // save in db
          }
          setVisibleTips(false); // hide help panel and off the onboarding flow in sidebar
        }
      );
    }
  }

  orderBy(value) {
    if (value === 'asc') {
      return 'desc';
    } else {
      return 'asc';
    }
  }

  orderByInvited(value) {
    if (value === 'asc') {
      return 'desc';
    } else {
      return 'asc';
    }
  }

  getOrder(value, name) {
    if (name !== this.state.attributeName.owned) {
      return this.orderBy('desc');
    } else {
      return this.orderBy(value);
    }
  }

  getInvitedOrder(value, name) {
    if (name !== this.state.attributeName.invited) {
      return this.orderByInvited('desc');
    } else {
      return this.orderByInvited(value);
    }
  }

  async sortInvitedActivities(value) {
    const { actType, defaultPage, filter, order, attributeName } = this.state;
    let { getInvitedActivities } = this.props;
    let invitedOrder = this.getInvitedOrder(order.invited, value);

    await this.setState(
      {
        order: {
          ...order,
          invited: invitedOrder,
        },
        attributeName: { ...attributeName, invited: value },
      },
      async () => {
        await getInvitedActivities(
          filter.invited,
          actType.invited,
          defaultPage,
          this.state.perPage,
          this.state.attributeName.invited,
          this.state.order.invited,
          this.state.searchQuery.invited
        );
        //pagination
        this.invitedActivitiesSetPage(this.state.defaultPage);
      }
    );
  }

  async sortOwnedActivities(value) {
    const { actType, group, defaultPage, filter, order, attributeName } =
      this.state;
    let { getOwnActivities } = this.props;
    let getOrder = this.getOrder(order.owned, value);

    await this.setState(
      {
        order: {
          ...order,
          owned: getOrder,
        },
        attributeName: {
          ...attributeName,
          owned: value,
        },
      },
      async () => {
        await getOwnActivities(
          filter.owned,
          actType.owned,
          group.owned,
          defaultPage,
          this.state.perPage,
          this.state.attributeName.owned,
          this.state.order.owned,
          this.state.searchQuery.owned
        );
        this.ownedActivitiesSetPage(this.state.defaultPage);
      }
    );
  }

  renderGroups() {
    const { projectGroup } = this.props;
    const groups = map(projectGroup, (item, i) => ({
      key: item.id,
      value: String(item.id),
      text: item.name,
    }));
    groups.unshift({ key: 'null', value: 'null', text: 'No groups' });
    return groups;
  }

  ownedActivitiesSetPage = (page) =>
    this.setState({ ownedActivitiesCurrentPage: page });

  invitedActivitiesSetPage = (page) =>
    this.setState({ invitedActivitiesCurrentPage: page });

  resetComponent = (type) =>
    this.setState({
      searchQuery: {
        owned: type === 'owned' && '',
        invited: type === 'invited' && '',
      },
    });

  handleSearchChange = async (e, { value }) => {
    const { actType, group, defaultPage, filter } = this.state;
    let { getOwnActivities } = this.props;

    this.setState(
      {
        searchQuery: {
          owned: value,
        },
      },
      async () => {
        if (this.state.searchQuery.owned.length < 1) {
          this.resetComponent('owned');
        }
        await getOwnActivities(
          filter.owned,
          actType.owned,
          group.owned,
          defaultPage,
          this.state.perPage,
          this.state.attributeName.owned,
          this.state.order.owned,
          this.state.searchQuery.owned
        );
        //pagination number
        this.ownedActivitiesSetPage(this.state.defaultPage);
      }
    );
  };

  handleSearchInviteChange = async (e, { value }) => {
    const { actType, defaultPage, filter } = this.state;
    let { getInvitedActivities } = this.props;
    this.setState(
      {
        searchQuery: {
          invited: value,
        },
      },
      async () => {
        if (this.state.searchQuery.invited.length < 1) {
          this.resetComponent('invited');
        }
        await getInvitedActivities(
          filter.invited,
          actType.invited,
          defaultPage,
          this.state.perPage,
          this.state.attributeName.invited,
          this.state.order.invited,
          this.state.searchQuery.invited
        );
        this.invitedActivitiesSetPage(this.state.defaultPage);
      }
    );
  };

  render() {
    const {
      ownLoading,
      ownActivities,
      invLoading,
      invitedActivities,
      newInvLoading,
      invitations,
      user,
    } = this.props;

    const {
      filter,
      actType,
      infoSteps,
      group,
      ownedActivitiesCurrentPage,
      invitedActivitiesCurrentPage,
    } = this.state;

    return (
      <Grid.Row>
        {user.organisationId ? (
          <Grid.Column width={16} className="p-0">
            <FeatureWrapper
              featureName="create_activity"
              children={<ActivityCreate {...this.props} />}
            />
          </Grid.Column>
        ) : null}
        <Grid.Column width={16} className="p-0">
          {invitations.docs && invitations.docs.length ? (
            <CustomSegment title="New Invitations" extraMargin>
              <Segment
                className="content-segment content-segment-bg"
                loading={newInvLoading}
              >
                <NewActivities data={invitations} {...this.props} />
              </Segment>
            </CustomSegment>
          ) : null}
          <FeatureWrapper
            featureName="owned_activities"
            children={
              <CustomSegment
                title="Managed Activities"
                subChild={
                  <div className="sub-child-segment">
                    <Search
                      className="search-bar-custom"
                      placeholder="Search by Activity Name"
                      showNoResults={false}
                      onSearchChange={debounce(this.handleSearchChange, 500, {
                        leading: true,
                      })}
                    />
                    {/*
                     * Note: activities-filter-container class name used for help tip showing
                     * (don't change it or remove it)
                     */}
                    <div className="activities-filter-container manage-activity-drop">
                      <span className="activities-filter">
                        <Dropdown
                          direction="left"
                          inline
                          selectOnBlur={false}
                          options={this.renderGroups()}
                          value={group.owned}
                          onChange={(e, { value }) =>
                            this.setFilterOnUser(
                              'owned',
                              filter.owned,
                              actType.owned,
                              value
                            )
                          }
                        />
                      </span>
                      <span className="activities-filter">
                        <Dropdown
                          direction="left"
                          inline
                          selectOnBlur={false}
                          options={act_type_options}
                          value={actType.owned}
                          onChange={(e, { value }) =>
                            this.setFilterOnUser(
                              'owned',
                              filter.owned,
                              value,
                              group.owned
                            )
                          }
                        />
                      </span>
                      <span className="activities-filter">
                        <Dropdown
                          direction="left"
                          inline
                          selectOnBlur={false}
                          options={options}
                          value={filter.owned}
                          onChange={(e, { value }) =>
                            this.setFilterOnUser(
                              'owned',
                              value,
                              actType.owned,
                              group.owned
                            )
                          }
                        />
                      </span>
                      <div className="menu-dropdown">
                        <Dropdown
                          icon="ellipsis vertical"
                          direction="left"
                          inline
                          selectOnBlur={false}
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item className="dropdown-customItem">
                              <ManageGroup
                                {...this.props}
                                {...this.state}
                                setActivityGroupFilter={(value) =>
                                  this.setFilterOnUser(
                                    'owned',
                                    filter.owned,
                                    actType.invited,
                                    value
                                  )
                                }
                              />
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                }
                extraMargin
              >
                <Segment
                  /*
                   * Note:  owned-activities-container class name used for help tip showing class name (don't change it or remove it)
                   */
                  className="content-segment content-segment-bg owned-activities-container"
                  loading={ownLoading}
                >
                  {ownActivities.docs && ownActivities.docs.length ? (
                    <OwnedActivities
                      data={ownActivities}
                      currentPage={ownedActivitiesCurrentPage}
                      setPage={this.ownedActivitiesSetPage}
                      sortOwnedActivities={(value) =>
                        this.sortOwnedActivities(value)
                      }
                      {...this.state}
                      {...this.props}
                    />
                  ) : (
                    <EmptyContainer msg="No activities…" />
                  )}
                </Segment>
              </CustomSegment>
            }
          />
          <CustomSegment
            title="Invited Activities"
            subChild={
              <div className="sub-child-segment">
                <Search
                  className="search-bar-custom"
                  placeholder="Search"
                  showNoResults={false}
                  onSearchChange={debounce(this.handleSearchInviteChange, 500, {
                    leading: true,
                  })}
                />
                <div className="activities-filter-container manage-activity-drop">
                  <span className="activities-filter">
                    <Dropdown
                      direction="left"
                      inline
                      selectOnBlur={false}
                      options={act_type_options}
                      value={actType.invited}
                      onChange={(e, { value }) =>
                        this.setFilterOnUser('invited', filter.invited, value)
                      }
                    />
                  </span>
                  <span className="activities-filter">
                    <Dropdown
                      direction="left"
                      inline
                      selectOnBlur={false}
                      options={options}
                      value={filter.invited}
                      onChange={(e, { value }) =>
                        this.setFilterOnUser('invited', value, actType.invited)
                      }
                    />
                  </span>
                </div>
              </div>
            }
            extraMargin
          >
            <Segment
              className="content-segment content-segment-bg"
              loading={invLoading}
            >
              {invitedActivities.docs && invitedActivities.docs.length ? (
                <InvitedActivities
                  currentPage={invitedActivitiesCurrentPage}
                  setPage={this.invitedActivitiesSetPage}
                  sortInvitedActivities={(value) =>
                    this.sortInvitedActivities(value)
                  }
                  data={invitedActivities}
                  {...this.state}
                  {...this.props}
                />
              ) : (
                <EmptyContainer msg="No activities..." />
              )}
            </Segment>
          </CustomSegment>
        </Grid.Column>
        <Joyride
          autoStart
          callback={(data) => this.getJoyRide(data)}
          run={infoSteps}
          steps={steps}
          continuous
          disableOverlayClose={true} // disable overlay close <- need to go whole trip
          disableCloseOnEsc={true}
          tooltipComponent={Tooltip}
          beaconComponent={BeaconComponent}
          scrollToFirstStep
          disableScrolling={!isMobile} // disable scroll in web view <- to resolve wrong target at help panel in web view
        />
      </Grid.Row>
    );
  }
}
class ManageGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;

    return (
      <div className="add-goal">
        <Modal
          onOpen={() => this.toggle()}
          onClick={(e) => stopPropagation(e)}
          onFocus={(e) => stopPropagation(e)}
          trigger={<div>Manage Groups</div>}
          size="large"
          open={modal}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
          className="group-management"
        >
          <Modal.Header>
            <div className="header-goal-duplicate">Group Management</div>
          </Modal.Header>
          <Modal.Content>
            <ManageGroupForm
              manageModalToggle={() => this.toggle()}
              {...this.props}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class ManageGroupForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          width: 5,
          title: <div>Group Name</div>,
          render: (data) => {
            return <div>{data.name}</div>;
          },
          textAlign: isBrowser || isTablet ? 'centre' : null,
        },
        {
          width: 5,
          title: <div>Activity Count</div>,
          render: (data) => {
            const activityCount = size(data.projectGroup);

            return <div>{activityCount}</div>;
          },
          textAlign: isBrowser || isTablet ? 'centre' : null,
        },
        {
          width: 5,
          title: <div>Date Created</div>,
          render: (data) => {
            return (
              <div>
                {' '}
                {data.createdAt
                  ? moment(data.createdAt).format('ddd DD MMM YYYY')
                  : '-'}
              </div>
            );
          },
          textAlign: isBrowser || isTablet ? 'centre' : null,
        },
        {
          width: 1,
          title: <div>Actions</div>,
          render: (data) => {
            return (
              <div className="actions-manage-group">
                <RenameGroup {...this.props} data={data} />

                <RemoveGroup {...this.props} data={data} />
              </div>
            );
          },
          textAlign: isBrowser || isTablet ? 'centre' : null,
        },
      ],
    };
  }

  render() {
    const { projectGroup, projectGroupLoading } = this.props;
    const { columns } = this.state;

    return (
      <div loading={projectGroupLoading} disabled={projectGroupLoading}>
        {projectGroup.length ? (
          <CustomTable
            header
            columns={columns}
            data={projectGroup}
            keyExtractor={(item, index) => item.id}
            customClass="group-management"
          />
        ) : (
          <EmptyContainer msg="You have not created any project groups yet…" />
        )}
      </div>
    );
  }
}

class RenameGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      getUserActivityGroups,
    }));
  }

  async handleValidSubmit(event, values) {
    try {
      const { updateProjectGroupRename, data, user } = this.props;

      await updateProjectGroupRename(data.id, values, user.id);
      this.toggle();
    } catch (error) {
      NotificationManager.error(
        "We couldn't submit the activity",
        'Something went wrong'
      );
    }
  }

  render() {
    const { modal } = this.state;
    const { data } = this.props;

    return (
      <div className="add-goal">
        <Modal
          onOpen={() => this.toggle()}
          trigger={<div className="rename-title">Rename</div>}
          size="tiny"
          open={modal}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
        >
          <Modal.Header>
            <div className="header-goal-duplicate">Rename Group</div>
          </Modal.Header>

          <Modal.Content>
            <AvForm
              ref={(c) => (this.form = c)}
              onValidSubmit={(event, values) =>
                this.handleValidSubmit(event, values)
              }
              autoComplete="off"
              model={data}
            >
              <AvField
                label="Group Name"
                className="org-inputs"
                name="name"
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'New group name is required',
                  },
                }}
                autoComplete="off"
              />

              <div className="model-buttons">
                <Button
                  className="modal-cancel"
                  type="button"
                  onClick={() => this.toggle()}
                >
                  Cancel
                </Button>
                <Button
                  content="Save"
                  type="submit"
                  className="modal-proceed"
                  // loading={groupRenameLoading}
                  // disabled={groupRenameLoading}
                />
              </div>
            </AvForm>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
class RemoveGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async deleteProjectGroup(id) {
    try {
      const {
        deleteProjectGroup,
        user,
        filter,
        actType,
        manageModalToggle,
        setActivityGroupFilter,
      } = this.props;
      let page = 1;

      const currentSelectedGroupId = Number(
        user.options['activity-group-filter']['owned']
      );
      if (
        user &&
        user.options &&
        user.options['activity-group-filter'] &&
        user.options['activity-group-filter']['owned'] &&
        currentSelectedGroupId === id
      ) {
        setActivityGroupFilter('null');
      }

      await deleteProjectGroup(
        id,
        user.id,
        filter,
        actType,
        page,
        currentSelectedGroupId
      );
      this.toggle();
      manageModalToggle();
    } catch (error) {
      NotificationManager.error(
        "We couldn't delete the project group",
        'Something went wrong'
      );
    }
  }

  render() {
    const { modal } = this.state;
    const { data } = this.props;

    return (
      <div className="add-goal">
        <Modal
          onOpen={() => this.toggle()}
          trigger={<div className="remove-title">Ungroup</div>}
          size="tiny"
          open={modal}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
        >
          <Modal.Header>
            <div className="header-goal-duplicate">
              Delete Group - {data.name}
            </div>
          </Modal.Header>
          <Modal.Content>
            Are you sure you want to ungroup these activities?
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.toggle()} className="modal-cancel">
              No
            </Button>
            <Button
              onClick={() => this.deleteProjectGroup(data.id)}
              className="modal-proceed"
              content="Yes"
              // loading={deleteProjectGroupLoading}
              // disabled={deleteProjectGroupLoading}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // set organisation parent activity status

  return {
    ...state.activity,
    user: state.authentication.user,
    actPlanTypes: state.extra.actPlanTypes,
    projectGroup: state.activity.projectGroup,
    goals: state.activity.goals,
    groupRenameLoading: state.activity.groupRenameLoading,
    groupAddLoading: state.activity.groupAddLoading,
    deleteProjectGroupLoading: state.activity.deleteProjectGroupLoading,
    showModal: state.activity.showModal,
    duplicateActLoading: state.activity.duplicateActLoading,
    policies: state.extra.policies,
    outcomes: state.extra.outcomes,
    visibleTips: state.sidebar.isVisibleTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getInvitations,
      getOwnActivities,
      getInvitedActivities,
      clearCurrentActivity,
      acceptInvitation,
      postActivity,
      deleteActivity,
      helpTips,
      getUserActivityGroups,
      postDuplicateActivity,
      getAllGoals,
      getPolicies,
      getOutcomes,
      postActivityGroup,
      deleteProjectGroup,
      updateProjectGroupRename,
      setUserOnboardingOption,
      setVisibleTips,
      setSidebarType,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityHome);
