import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { deleteOrganizationUser, deleteUser } from '../../../../store/actions';
import { trimText } from '../../../../utils/';

const WithdrawUserConfirmationModal = ({
  user,
  userActivities,
  modal,
  handleModal,
  isAdmin = null,
  ...props
}) => {
  const dispatch = useDispatch();

  const handleDeleteOrgUser = () => {
    const { resetPage } = props;
    const isWithdrawnActivities = true;

    if (isAdmin) {
      dispatch(deleteUser(user.id, isWithdrawnActivities));
    } else {
      dispatch(deleteOrganizationUser(user.id, isWithdrawnActivities));
    }
    if (resetPage) resetPage();
  };

  const developmentAndActiveActivities =
    (userActivities.inDevelopmentInvited
      ? userActivities.inDevelopmentInvited
      : 0) +
    (userActivities.inActiveInvited ? userActivities.inActiveInvited : 0);

  return (
    <>
      <Modal
        open={modal}
        closeIcon={
          <Icon
            onClick={() => handleModal(false)}
            name="close"
            className="closeicon"
          />
        }
        size="tiny"
      >
        <Modal.Header>
          Remove User Invited Activities - {user && trimText(user.name, 15)}
        </Modal.Header>
        <Modal.Content>
          <>
            <p>
              {developmentAndActiveActivities &&
                `This user is invited to ${developmentAndActiveActivities} in development / active activities. On confirming removal, the user will be withdrawn from all invited activities.`}
            </p>
          </>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              handleModal(false);
            }}
            className="modal-cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleModal(false);
              handleDeleteOrgUser(true);
            }}
            className="modal-proceed"
            content="Confirm"
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default WithdrawUserConfirmationModal;
