/*** TYPES ***/

const SET_SIDEBAR_TYPE = 'SET_SIDEBAR_TYPE';
const SET_SIDEBAR_VISIBILITY = 'SET_SIDEBAR_VISIBILITY';
const SET_TIPS_VISIBILITY = 'SET_TIPS_VISIBILITY';

/*** REDUCERS ***/

const initialState = {
  sideBarType: null, // SIDEBAR_TYPES
  isVisibleSidebar: false,
  isVisibleTips: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SIDEBAR_TYPE:
      return { ...state, sideBarType: action.payload };
    case SET_SIDEBAR_VISIBILITY:
      return { ...state, isVisibleSidebar: action.payload };
    case SET_TIPS_VISIBILITY:
      return { ...state, isVisibleTips: action.payload };
    default:
      return state;
  }
}

export function setSidebarType(type, visible) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_SIDEBAR_TYPE, payload: type });
      dispatch({ type: SET_SIDEBAR_VISIBILITY, payload: visible });
    } catch (error) {
      dispatch({ type: SET_SIDEBAR_TYPE, payload: null });
      dispatch({ type: SET_SIDEBAR_VISIBILITY, payload: false });
    }
  };
}

export function setVisibleTips(visible) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_TIPS_VISIBILITY, payload: visible });
    } catch (error) {
      dispatch({ type: SET_TIPS_VISIBILITY, payload: false });
    }
  };
}
