import { get, isEmpty, map } from 'lodash';
import * as Yup from 'yup';

export const initialValues = (form) => {
  let venueOptions = get(form, 'venueSubOptions', []);
  return {
    name: form && form.name ? form.name : '',
    description: form && form.description ? form.description : '',
    venueOptions: venueOptions,
    isOnline: form ? form.isOnline : false,
  };
};

export const venueTypeValidation = () =>
  Yup.object().shape({
    name: Yup.string().required('Title is required!'),
    venueOptions: Yup.array().optional(),
    description: Yup.string().required('Description is required!'),
  });

export const addVenueInitialValues = (data) => {
  let venueOptions = get(data, 'venueType.option', []);
  if (!isEmpty(venueOptions)) {
    venueOptions = map(venueOptions, (item) => item.id);
  }
  return {
    venueTypeId: data && data.venueType ? data.venueType.id : '',
    subVenueOptions: venueOptions,
    name: data && data.name ? data.name : '',
    country: data && data.country ? data.country : '',
    streetAddress: data && data.streetAddress ? data.streetAddress : '',
    streetAddress2: data && data.streetAddress2 ? data.streetAddress2 : '',
    suburb: data && data.suburb ? data.suburb : '',
    state: data && data.state ? data.state : '',
    postCode: data && data.postCode ? data.postCode : '',
    isOwned: data ? Boolean(data.owned) : false,
  };
};

export const addVenueValidation = (optional) =>
  Yup.object().shape({
    venueTypeId: Yup.string().required('Venue type is required!'),
    name: Yup.string().required('Venue name is required!'),
    country: optional
      ? Yup.string().optional()
      : Yup.string().required('Country is required!'),
    streetAddress: optional
      ? Yup.string().optional()
      : Yup.string().required('Street address is required!'),
    streetAddress2: optional
      ? Yup.string().optional()
      : Yup.string().optional().nullable(),
    suburb: optional
      ? Yup.string().optional()
      : Yup.string().required('Suburb is required!'),
    state: optional
      ? Yup.string().optional()
      : Yup.string().required('State is required!'),
    postCode: optional
      ? Yup.string().optional()
      : Yup.string().required('Postcode is required!'),
    isOwned: Yup.boolean().optional(),
  });
