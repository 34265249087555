import React from 'react';
import { Icon } from 'semantic-ui-react';
import { map, take, size } from '../utils/lodash';
import './styles.scss';

export class SeeMore extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      toggle: true,
    };
  }

  render() {
    const {
      minCount,
      CustomComponent,
      dataSet,
      isView,
      isShowAll = false,
      rest = null,
    } = this.props;

    return (
      <div>
        {map(
          take(dataSet, this.state.toggle ? minCount : size(dataSet)),
          (group, i) => (
            <CustomComponent key={i} item={group} rest={rest} />
          )
        )}
        <div
          className="see-more-see-less-toggle"
          onClick={() => this.setState({ toggle: !this.state.toggle })}
        >
          {this.state.toggle ? (
            <div className="view-more-btn">
              {' '}
              {isView ? 'View More' : isShowAll ? 'Show All' : 'See More'}
              <Icon className="ml-1" name="chevron down" />
            </div>
          ) : (
            <div className="view-more-btn">
              {' '}
              {isView ? 'View Less' : isShowAll ? 'Show Less' : 'See Less'}
              <Icon className="ml-1" name="chevron up" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export class ViewMoreText extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      toggle: true,
    };
  }

  render() {
    const {
      text,
      length = 100,
      prefix = '',
      separator = '',
      isView = true,
    } = this.props;
    const { toggle } = this.state;

    const originalText = prefix
      ? prefix.concat(' ', separator, ' ', text)
      : text;

    let minifiedText = originalText;
    if (toggle && originalText.length > length) {
      minifiedText = originalText.substring(0, length) + '...';
    }

    return (
      <div>
        {minifiedText}
        <span
          className="project-partner-minified-txt"
          onClick={() => this.setState({ toggle: !this.state.toggle })}
        >
          {originalText.length > length &&
            (this.state.toggle ? (
              <span> {isView ? 'View More' : 'See More'}</span>
            ) : (
              <span> {isView ? 'View Less' : 'See Less'}</span>
            ))}
        </span>
      </div>
    );
  }
}
