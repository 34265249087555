import React, { useState } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { CustomInput } from './customInput';
import { findInvitedUser } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { EMAIL_REG_EX } from '../../../utils/regex';
import { get, isEmpty } from '../../../utils/lodash';
import { ErrorMessage, useField } from 'formik';

export const EmailVerification = ({ setFieldValue = null, ...props }) => {
  const dispatch = useDispatch();
  const verifyLoading = useSelector(
    (state) => state.activity.findSystemUserLoading
  );

  const [userData, setUserData] = useState(null);
  const [confirmedUser, setConfirmedUser] = useState(null);
  const [values, errors] = useField(props);
  const [email, setEmail] = useState(get(values, 'value', null));

  const { getData } = props;

  const isAlreadyExistingError = get(errors, 'error', false);
  const fetchData = async (email) => {
    return new Promise((resolve, reject) => {
      dispatch(findInvitedUser(email))
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          console.error('error: ', error);
          reject(error);
        });
    });
  };

  const setConfirmData = (data) => {
    setConfirmedUser(data);
    setFieldValue('emailConfirmError', data ? 'confirmedUser' : '');
  };

  const handleVerifyEmail = async () => {
    setConfirmData(null);
    try {
      getData && getData(null);
      if (email.match(EMAIL_REG_EX)) {
        const data = await fetchData(email);
        if (!isEmpty(data)) {
          setUserData({ isExitingUser: true, data: data });
          getData && getData(null);
        } else {
          const payload = { isExitingUser: false, data: {} };
          setUserData(payload);
          getData && getData(payload);
        }
      }
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const EmailResponsePanel = () => {
    if (confirmedUser || !userData || (userData && !userData.isExitingUser))
      return null;
    if (userData && userData.isExitingUser) {
      const { data = null } = userData;

      const fullName = `${data.firstName} ${data.surname}`;
      const organisationName = data.entityName
        ? data.entityName
        : get(data, 'organisation.name', null);
      if (data) {
        return (
          <div className="email-response-details">
            <Grid className="activity-summary-grid" padded>
              <Grid.Column>
                <p>The details entered match an existing Takso user.</p>
                <p>
                  Name: <strong>{fullName}</strong>
                </p>
                <p>
                  Business/Organisation: <strong>{organisationName}</strong>
                </p>
                <p className="confirm-btn" onClick={clickConfirm}>
                  Confirm
                </p>
              </Grid.Column>
            </Grid>
          </div>
        );
      } else {
        return <></>;
      }
    }
  };

  const resetUserResponse = async () => {
    setUserData(null);
    setConfirmData(null);
    getData && getData(null);
  };

  const handleClear = async (values) => {
    try {
      resetUserResponse();
      setEmail(false);
    } catch (error) {
      console.error('error', error);
    }
  };

  const clickConfirm = () => {
    const { getData } = props;
    setConfirmData(userData);
    getData && getData(userData);
  };

  return (
    <div>
      <Grid>
        <Grid.Column mobile={16} tablet={10} computer={12}>
          <CustomInput
            id="email"
            name="email"
            placeholder="Enter email address"
            getValue={(value) => {
              setEmail(value);
              resetUserResponse();
            }}
            labelText="Email"
            disabled={false}
            requiredStar
          />
        </Grid.Column>
        <Grid.Column
          className="center-verification-new"
          mobile={16}
          tablet={3}
          computer={2}
        >
          <Button
            content="Verify"
            type="button"
            size="small"
            className="Verify-Primary-Button"
            onClick={handleVerifyEmail}
            loading={verifyLoading}
          />
        </Grid.Column>
        <Grid.Column
          className="center-verification-new"
          mobile={16}
          tablet={3}
          computer={2}
        >
          <Button
            content="Clear"
            type="button"
            className="Verify-Secondary-Button"
            onClick={() => {
              handleClear();
              setFieldValue('email', '');
            }}
          />
        </Grid.Column>
      </Grid>

      <EmailResponsePanel />
      {!confirmedUser && !isAlreadyExistingError ? (
        <ErrorMessage
          name="emailConfirmError"
          component="div"
          className="error-msg"
        />
      ) : null}
    </div>
  );
};
