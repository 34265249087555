import * as Yup from 'yup';
import {
  UPPERCASE_REG_EX,
  NUMERIC_REG_EX,
  EMAIL_REG_EX,
  LOWERCASE_REG_EX,
  SPECIAL_CHARACTER_REG_EX,
} from '../utils/regex';

export const addNewUserByOrgInitialValues = (isDepartmentOn = false) => {
  let initialValues = {
    firstName: '',
    surname: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  if (isDepartmentOn) {
    initialValues = {
      ...initialValues,
      departmentId: '',
      unitId: '',
    };
  }
  return initialValues;
};
export const assignDepartmentValues = (department, unit = null) => {
  return {
    departmentId: department ? department.id : null,
    unitId: unit ? unit.id : null,
  };
};

export const assignDepartmentValidationSchema = Yup.object().shape({
  departmentId: Yup.number().nullable(),
  unitId: Yup.number().nullable(),
});

export const addNewUserByOrgValidationSchema = (isDepartmentOn = false) => {
  let validationObj = {
    firstName: Yup.string().required('First name is required!'),
    surname: Yup.string().required('Surname is required!'),

    email: Yup.string()
      .required('Email is required')
      .matches(EMAIL_REG_EX, 'Please enter the valid format'),
    password: Yup.string()
      .min(8, 'LENGTH_ERROR')
      .required('LENGTH_ERROR')
      .matches(LOWERCASE_REG_EX, 'LOWERCASE_ERROR')
      .matches(UPPERCASE_REG_EX, 'UPPERCASE_ERROR')
      .matches(NUMERIC_REG_EX, 'NUMERIC_ERROR')
      .matches(SPECIAL_CHARACTER_REG_EX, 'SPECIAL_CHARACTER_ERROR'),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password'), null], "Doesn't match the new password"),
  };

  if (isDepartmentOn) {
    validationObj = {
      ...validationObj,
      departmentId: Yup.number().nullable().optional(),
    };
  }

  return Yup.object().shape(validationObj);
};
