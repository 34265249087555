import './auth.scss';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Grid } from 'semantic-ui-react';
import { SuccessContainer } from '../../components';
import { APP_NAME } from '../../utils/constant';

class PasswordLinkExpired extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = `Invitation Verification | ${APP_NAME}`;
  }

  render() {
    return (
      <Grid.Row>
        {/* <Grid.Column mobile={16} tablet={5} computer={5} />
        <Grid.Column mobile={16} tablet={6} computer={6}> */}
        <Container className="email-verification-container">
          {/* <div > */}
          <SuccessContainer
            msg={'This password reset link is no longer active.'}
            description={[
              <span>
                Your request to reset your password has expired or the link has
              </span>,
              <br />,
              <span>already been used.</span>,
            ]}
            linkExpired={true}
          />
          {/* </div> */}
        </Container>
        {/* </Grid.Column>
        <Grid.Column mobile={16} tablet={5} computer={5} /> */}
      </Grid.Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordLinkExpired);
