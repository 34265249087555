import { Segment, Radio } from 'semantic-ui-react';
import React from 'react';

import './styles.scss';

export class CustomAccordion extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  componentDidMount() {
    const { collapse } = this.props;
    this.setState({ collapse });
  }

  render() {
    const {
      title,
      rightHeader,
      extraMargin,
      extraBottomMargin,
      style,
      toggle,
      headerStyle,
      noPrint,
      objectiveModal = false,
      evalPlan,
      showQuestion,
      titleAvailable = true,
      open,
      close,
      subtitleStyle,
      subtitle,
    } = this.props;
    const { collapse } = this.state;
    return (
      <Segment.Group
        raised
        style={{
          marginTop: extraMargin ? '1.25rem' : null,
          marginBottom: extraBottomMargin ? '2.857rem' : null,
          ...style,
        }}
        className={`${title.replace(' ', '_')} ${noPrint ? 'no-print' : ''}`}
      >
        <Segment
          className={`custom-segment cursor-pointer${
            objectiveModal ? 'objective-modal-header' : ''
          }${evalPlan ? ' accordion-ev' : ''}${
            showQuestion ? ' accordion-ev' : ''
          }`}
          onClick={() => this.setState({ collapse: !collapse })}
        >
          <div className="segment-heading-custom-new" style={headerStyle}>
            {titleAvailable ? title : !collapse ? open : close}
            {subtitle ? <span style={subtitleStyle}>{subtitle}</span> : <></>}
          </div>

          {rightHeader ? rightHeader : null}
          {toggle ? <Radio toggle /> : null}
        </Segment>

        {collapse ? <div>{this.props.children}</div> : null}
      </Segment.Group>
    );
  }
}
