import { PARTNER_RELATIONSHIP_VALUES } from '../constant';
import { get } from '../lodash';

/**
 * @param  {} parentActivity
 * @param  {} partners
 * @description This function check if the linked activity partner is activity manager
 */
export const isExternalLinkedActivity = (partner) => {
  return partner.isFromProgramLevel && partner.partnerUserId !== null
    ? true
    : false;
};

export const isInternalLinkedActivity = (currentActivity) => {
  const parentOrg = get(
    currentActivity,
    'parentActivity.owner.organisation.id',
    null
  );
  if (parentOrg !== null) {
    return parentOrg === currentActivity.owner.organisationId;
  }
  return false;
};

/**
 * @param  {} activityPlanTypeId
 * @description This function check given activity plan is of one of Grant, Multi, and Festival
 */
export const isParentProgramActivity = (activityPlanTypeId) => {
  const programIds = [3, 4, 6];
  return programIds.includes(activityPlanTypeId);
};

/**
 * @param  {} activityPlanTypeId
 * @param  {} isLinkedActivity
 * @description This function check given activity plan is single activity (not Single Linked)
 */
export const isSingleActivity = (activityPlanTypeId, isLinkedActivity) => {
  return activityPlanTypeId === 1 && !isLinkedActivity;
};

/**
 * @param  {} activityPlanTypeId
 * @description This function check given activity plan is single activity or linked activity ( Single + Linked)
 */
export const isSingleAndLinkedActivity = (activityPlanTypeId) => {
  return activityPlanTypeId === 1;
};

/**
 * @param  {} activityPlanTypeId
 * @param  {} isLinkedActivity
 * @description This function check given activity plan is linked activity (Only Single linked)
 */
export const isSingleLinkedActivity = (
  activityPlanTypeId,
  isLinkedActivity
) => {
  return activityPlanTypeId === 1 && isLinkedActivity;
};

/**
 * @param  {} activityPlanTypeId
 * @description This function check given activity plan is festival activity (Only Festival program)
 */
export const isFestivalProgramActivity = (activityPlanTypeId) => {
  return activityPlanTypeId === 3;
};

/**
 * @param  {} activityPlanTypeId
 * @description This function check given activity plan is grant activity (Only Grant program)
 */
export const isGrantProgramActivity = (activityPlanTypeId) => {
  return activityPlanTypeId === 4;
};

/**
 * @param  {} activityPlanTypeId
 * @description This function check given activity plan is MAP activity (Only MAP program)
 */
export const isMapProgramActivity = (activityPlanTypeId) => {
  return activityPlanTypeId === 6;
};

/**
 * @param  {} activityPlanTypeId
 * @param  {} isLinkedActivity
 * @description This function check given activity plan is festival linked activity (Only Festival program which created as linked)
 */
export const isFestivalLinkedActivity = (
  parentActivityPlanTypeId,
  isLinkedActivity
) => {
  return parentActivityPlanTypeId === 3 && isLinkedActivity;
};

/**
 * @param  {} activityPlanTypeId
 * @param  {} isLinkedActivity
 * @description This function check given activity plan is grant linked activity (Only Grant program which created as linked)
 */
export const isGrantLinkedActivity = (
  parentActivityPlanTypeId,
  isLinkedActivity
) => {
  return parentActivityPlanTypeId === 4 && isLinkedActivity;
};

/**
 * @param  {} activityPlanTypeId
 * @param  {} isLinkedActivity
 * @description This function check given activity plan is MAP linked activity (Only MAP program which created as linked)
 */
export const isMapLinkedActivity = (
  parentActivityPlanTypeId,
  isLinkedActivity
) => {
  return parentActivityPlanTypeId === 6 && isLinkedActivity;
};

/**
 * @param  {} guestUserOrganisation
 * @param  {boolean}   isAllowPermission,
 * @param  {}   currentUserOrganisationId,
 * @description This function to check whether the linked owner has permission to own the activity
 */
export const isPermanentlyDeleteActivity = (
  guestUserOrganisation,
  currentUserOrganisationId
) => {
  if (
    guestUserOrganisation !== null &&
    !isCurrentOrganisationUser(guestUserOrganisation, currentUserOrganisationId)
  ) {
    return true;
  }
  return false;
};

/**
 * @param  {} guestUserOrganisation
 * @param  {} currentUser
 * @description This function to check whether the user is from current org
 */

export const isCurrentOrganisationUser = (
  guestUserOrganisation,
  currentUserOrganisationId
) => {
  if (
    guestUserOrganisation !== null &&
    guestUserOrganisation.id === currentUserOrganisationId
  ) {
    return true;
  }
  return false;
};

export const isNetworkRelationship = (supportType) => {
  return supportType === PARTNER_RELATIONSHIP_VALUES.NETWORK;
};

export const getActivityReportLoadingAttributes = (
  data,
  reportInitialState
) => {
  const getLoading = (property) => get(reportInitialState, property, false);
  const loadingProperties = {
    activityStatsLoading: `activityStatsLoading.${data.ACTIVITY_STATS_LOADING}`,
    activityReportLoading: `activityReportLoading.${data.ACTIVITY_REPORT_LOADING}`,
    venueReportLoading: `venueReportLoading.${data.VENUE_REPORT_LOADING}`,
    participantLoading: `participantReportLoading.${data.PARTICIPANT_REPORT_LOADING}`,
    // organisationMapLoading: `orgMapLoading.${data.ORGANISATION_MAP_LOADING}`, //
    activityMapLoading: `actVenueMapLoading.${data.ACTIVITY_VENUE_MAP_LOADING}`,
    contractorMapLoading: `contractorMapLoading.${data.CONTRACTOR_MAP_LOADING}`,
  };
  const loadingValues = Object.keys(loadingProperties).reduce((acc, key) => {
    acc[key] = getLoading(loadingProperties[key]);
    return acc;
  }, {});
  return loadingValues;
};
