import React from 'react';

/* eslint-disable eqeqeq */
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import Alert from 'reactstrap/lib/Alert';
import { bindActionCreators } from 'redux';
import { Confirm, Grid, Segment } from 'semantic-ui-react';

import { CustomTooltip } from '../../../components';
import {
  filter,
  find,
  flattenDeep,
  get,
  isEmpty,
  map,
  uniq,
} from '../../../utils/lodash';
import { putObjective } from '../../../store/actions';
import { getPageDisable } from '../../../utils';
import './activity.scss';
import ShowMoreText from 'react-show-more-text';
import ProjectText from '../../../components/projectText';
import WHITELIST_USERS from '../../../utils/activity/specialPermissionUsers';

class ActivityObjective extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      evalPlans: false,
      objectiveOne: null,
      objectiveTwo: null,
    };
  }

  renderObjectiveDomainOne() {
    const { objectiveOne, objectiveTwo } = this.state;
    let { outcomes, currentActivity } = this.props;

    const objectives = filter(outcomes, (o) => {
      if (
        (o.id == this.state.objectiveOne || o.isEnable) &&
        ((objectiveOne && objectiveOne === o.id) || objectiveTwo !== o.id) // if objective 1 eq objective 2 need to show
      ) {
        return o.policyId == currentActivity.primaryPolicyId;
      }
    });

    if (isEmpty(objectives)) return null;
    return map(objectives, (item, i) => (
      <option key={i} value={item.id}>
        {item.title}
      </option>
    ));
  }

  renderObjectiveDomainTwo() {
    const { objectiveOne, objectiveTwo } = this.state;
    let { outcomes, currentActivity } = this.props;
    const objectives = filter(outcomes, (o) => {
      if (
        (o.id == this.state.objectiveTwo || o.isEnable) &&
        ((objectiveTwo && objectiveTwo === o.id) || objectiveOne !== o.id) // if objective 1 eq objective 1 need to show
      ) {
        return o.policyId == currentActivity.secondaryPolicyId;
      }
    });
    if (isEmpty(objectives)) return null;
    return map(objectives, (item, i) => (
      <option key={i} value={item.id}>
        {item.title}
      </option>
    ));
  }

  async setObjectiveOne({ target }) {
    try {
      const { currentObjective, currentActivity, user } = this.props;
      // const isWhitelistOwner =
      //   currentActivity.ownerId === user.id &&
      //   WHITELIST_USERS.includes(get(currentActivity, 'owner.email'));

      const isWhitelistOwner = WHITELIST_USERS.includes(user.email);

      if (currentActivity.isLinkedActivity && !isWhitelistOwner) return null;

      const objectiveSelected = get(
        currentActivity,
        'objective[0].outcomeId',
        null
      );
      let getEvalPlans = uniq(
        flattenDeep(
          map(get(currentActivity, 'evaluationPlans', []), (plan) => {
            return map(plan.evaluationQuestions, (e) => e.outcomeId);
          })
        )
      );

      if (getEvalPlans.includes(objectiveSelected)) {
        this.toggleError();
      } else {
        if (this.isNewObjective(currentObjective[0])) {
          this.putObjective(target.value, 0);
        } else {
          this.setState(
            {
              confirm: true,
            },
            () => {
              this.objectiveIndex = 0;
              this.targetValue = target.value;
            }
          );
        }
        this.setState({ objectiveOne: target.value });
      }
    } catch (error) {
      // console.error('ActivityObjective -> setObjectiveOne -> error', error);
    }
  }

  async setObjectiveTwo({ target }) {
    try {
      const { currentObjective, currentActivity, user } = this.props;
      const isWhitelistOwner = WHITELIST_USERS.includes(user.email);
      if (currentActivity.isLinkedActivity && !isWhitelistOwner) return null;

      const objectiveSelected = get(
        currentActivity,
        'objective[1].outcomeId',
        null
      );
      let getEvalPlans = uniq(
        flattenDeep(
          map(get(currentActivity, 'evaluationPlans', []), (plan) => {
            return map(plan.evaluationQuestions, (e) => e.outcomeId);
          })
        )
      );

      if (getEvalPlans.includes(objectiveSelected)) {
        this.toggleError();
      } else {
        if (this.isNewObjective(currentObjective[1])) {
          this.putObjective(target.value, 1);
        } else {
          this.setState(
            {
              confirm: true,
            },
            () => {
              this.objectiveIndex = 1;
              this.targetValue = target.value;
            }
          );
        }
        this.setState({ objectiveTwo: target.value });
      }
    } catch (error) {
      // console.error('setObjectiveTwo -> error', error);
    }
  }

  toggleError() {
    this.setState(
      (prevState) => {
        return {
          evalPlans: !prevState.evalPlans,
        };
      },
      () => {
        this.form && this.form.reset();
      }
    );
  }

  isNewObjective(currentObjective) {
    try {
      if (isEmpty(currentObjective)) return true;
      if (currentObjective.tocKnown) return false;
      if (currentObjective.tocDo) return false;
      if (!isEmpty(currentObjective.evidence)) return false;

      return true;
    } catch (error) {
      // console.error('ValidateObjective -> error', error);
      throw error;
    }
  }

  getDescription(value) {
    if (isEmpty(value.outcome)) {
      return '-';
    } else {
      return value.outcome.description || '-';
    }
  }

  close = () => {
    this.setState({ confirm: false });
  };

  onConfirm = async () => {
    this.setState({ confirm: false }, () => {
      this.putObjective(this.targetValue, this.objectiveIndex);
    });
  };

  putObjective = async (targetValue, objectiveIndex) => {
    let { outcomes, putObjective } = this.props;
    const outcome = find(outcomes, (o) => {
      return o.id == targetValue;
    });
    if (isEmpty(outcome)) return null;
    await putObjective(
      'objective',
      {
        outcome,
        outcomeId: outcome.id,
      },
      objectiveIndex
    );
  };

  render() {
    let { user, helpTips, currentActivity, currentObjective, currentState } =
      this.props;

    const { confirm, evalPlans } = this.state;
    const form = { descOne: '-', descTwo: '-' };
    const model = { objectiveOne: undefined, objectiveTwo: undefined };
    const isWhitelistOwner = WHITELIST_USERS.includes(user.email);
    const isSuperPermissionEdit =
      isWhitelistOwner &&
      currentActivity.statusId === 1 &&
      currentActivity.isLinkedActivity;

    try {
      const [objOne, objTwo] = currentObjective;
      if (!isEmpty(objOne)) {
        if (objOne.outcome.policyId == currentActivity.primaryPolicyId) {
          form.descOne = objOne.outcome.description;
          model.objectiveOne = objOne.outcomeId;
          this.setState({ objectiveOne: model.objectiveOne });
        } else {
          form.descTwo = objOne.outcome.description;
          model.objectiveTwo = objOne.outcomeId;
          this.setState({ objectiveTwo: model.objectiveTwo });
        }
      }
      if (!isEmpty(objTwo)) {
        if (objTwo.outcome.policyId == currentActivity.secondaryPolicyId) {
          form.descTwo = objTwo.outcome.description;
          model.objectiveTwo = objTwo.outcomeId;
          this.setState({ objectiveTwo: model.objectiveTwo });
        } else {
          form.descOne = objTwo.outcome.description;
          model.objectiveOne = objTwo.outcomeId;
          this.setState({ objectiveOne: model.objectiveOne });
        }
      }
    } catch (error) {
      // console.error('ActivityObjective -> render -> error', error);
    }

    const pageDisable = getPageDisable(currentActivity, user, currentState);

    const isSingleLinkedActivity = get(
      currentActivity,
      'isLinkedActivity',
      false
    );

    return (
      <AvForm
        ref={(c) => (this.form = c)}
        model={model}
        className="activity-form"
      >
        <Confirm
          open={evalPlans}
          confirmButton={null}
          onCancel={() => this.toggleError()}
          className="objective-warning"
          header="Error"
          content="Objectives cannot be changed as there are saved evaluation plans. If you want to change any objectives, you will need to delete all saved evaluation plans associated with the outcome."
          size="tiny"
        />
        <Confirm
          open={confirm}
          onCancel={this.close}
          onConfirm={() => this.onConfirm()}
          className="objective-warning"
          header="Warning!"
          content="You are about to change an objective of this activity, this may mean that any evidence and theory of change attached to the outcome is no longer relevant. If you proceed, review any evidence and theory of change."
          confirmButton="Proceed"
          size="tiny"
        />
        <Segment
          className="content-segment parent-objectives"
          disabled={pageDisable && !isSuperPermissionEdit}
        >
          <Grid>
            <Grid.Row>
              {/* goal */}
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <div className="form-group-objective-margin-left">
                  <label className="goal-label">Goal</label>
                  <br />
                  <label className="objectiveGoal-title-new">
                    {currentActivity.goal ? (
                      currentActivity.goal
                    ) : (
                      <Alert color="warning">Please select an goal</Alert>
                    )}
                  </label>
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <div className="form-group-objective-margin-right">
                  <label className="goal-label">Goal Description</label>
                  <br />
                  <ShowMoreText
                    lines={3}
                    more="Read More"
                    less="Read Less"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    truncatedEndingComponent={'... '}
                    className="pre-line"
                  >
                    <label className="objective-description-new">
                      {currentActivity.goalDescription
                        ? currentActivity.goalDescription
                        : '-'}
                    </label>
                  </ShowMoreText>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment
          className="content-segment"
          disabled={pageDisable && !isSuperPermissionEdit}
        >
          <Grid>
            <Grid.Row>
              {/* domain one */}
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <div className="form-group-select-objective-domain extra-top-bottom-margin">
                  <AvField
                    className="obj-inputs"
                    label={
                      <CustomTooltip
                        state={get(user, 'options.tips')}
                        contentObject={{
                          data: helpTips,
                          key: '21',
                        }}
                        position="bottom left"
                        wide="very"
                      >
                        <div className="required-star-label">
                          <ProjectText
                            defaultText={`Select objective for ${
                              currentActivity.primaryPolicy
                                ? currentActivity.primaryPolicy.policyName
                                : ''
                            } domain`}
                            textId={4}
                            additionalTextValue={
                              currentActivity.primaryPolicy
                                ? currentActivity.primaryPolicy.policyName
                                : ''
                            }
                          />
                          {}
                        </div>
                      </CustomTooltip>
                    }
                    name="objectiveOne"
                    type="select"
                    onChange={(e) => this.setObjectiveOne(e)}
                    disabled={
                      isSingleLinkedActivity && !isWhitelistOwner
                        ? true
                        : currentActivity.goal
                          ? currentActivity.isLinkedActivity &&
                            !isWhitelistOwner
                            ? true
                            : false
                          : true
                    }
                    value={this.state.objectiveOne}
                  >
                    <option value="" disabled defaultValue>
                      Select an Objective
                    </option>
                    {this.renderObjectiveDomainOne()}
                  </AvField>
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <div className="objective-description-domain extra-top-bottom-margin">
                  <label>Objective Description</label>

                  <label className="objective-description">
                    {form.descOne}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <div className="bottom-divider"></div>
            {/*domain two */}
            {!currentActivity.secondaryPolicyId ? null : (
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <div className="form-group-select-objective-domain extra-top-margin">
                    <AvField
                      className="obj-inputs"
                      label={
                        <CustomTooltip
                          state={get(user, 'options.tips')}
                          contentObject={{
                            data: helpTips,
                            key: '22',
                          }}
                          position="bottom left"
                          wide="very"
                        >
                          <div className="required-star-label">
                            <ProjectText
                              defaultText={`Select objective for ${
                                currentActivity.secondaryPolicy
                                  ? currentActivity.secondaryPolicy.policyName
                                  : ''
                              } domain`}
                              textId={4}
                              additionalTextValue={
                                currentActivity.secondaryPolicy
                                  ? currentActivity.secondaryPolicy.policyName
                                  : ''
                              }
                            />
                          </div>
                        </CustomTooltip>
                      }
                      name="objectiveTwo"
                      type="select"
                      onChange={(e) => this.setObjectiveTwo(e)}
                      disabled={
                        isSingleLinkedActivity && !isWhitelistOwner
                          ? true
                          : currentActivity.goal
                            ? currentActivity.isLinkedActivity &&
                              !isWhitelistOwner
                              ? true
                              : currentObjective && currentObjective.length
                                ? false
                                : true
                            : true
                      }
                      value={this.state.objectiveTwo}
                    >
                      <option value="" disabled defaultValue>
                        Select an Objective
                      </option>
                      {this.renderObjectiveDomainTwo()}
                    </AvField>
                  </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <div className="objective-description-domain extra-top-margin">
                    <label>Objective Description</label>

                    <label className="objective-description">
                      {form.descTwo}
                    </label>
                  </div>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Segment>
      </AvForm>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    outcomes: state.extra.outcomes,
    user: state.authentication.user,
    helpTips: state.extra.activityHelpTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      putObjective,
    },
    dispatch
  );
};

ActivityObjective = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityObjective);

export { ActivityObjective };
