import * as Yup from 'yup';
import { get, isEmpty, map } from '../utils/lodash';

export const evidenceInitialValues = (values, isParent = false) => {
  let initialObj = {
    content: get(values, 'content', ''),
    source: get(values, 'source', ''),
    evidenceType: get(values, 'evidenceType', ''),
  };

  let linkedObjectives = get(values, 'objective', []);
  if (!isEmpty(linkedObjectives)) {
    linkedObjectives = map(linkedObjectives, (item) => item.id);
  }

  if (!isParent) {
    initialObj = { ...initialObj, linkedObjectives };
  }

  return initialObj;
};

export const evidenceValidationSchema = (isParent = false) => {
  let validationObj = {
    content: Yup.string().required('Content is required'),
    source: Yup.string().required('Source is required'),
    evidenceType: Yup.number().required('Evidence Type is required'),
  };

  if (!isParent) {
    validationObj = {
      ...validationObj,
      linkedObjectives: Yup.array()
        .required('Linked objectives is required')
        .min(1, 'Linked objectives is required'),
    };
  }

  return Yup.object().shape(validationObj);
};
