/* eslint-disable eqeqeq */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Tab } from 'semantic-ui-react';
import {
  PostSurveyChart,
  PostPreTrendSurvey,
} from '../../../reports/reportHome/outcomesSummary';
import { getChartType } from '../../../../utils';
import './systemReport.scss';
import thousands from 'thousands';

class SystemOverview extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let { type, setChartType } = this.props;
    const chartType = getChartType({
      title: type,
      ...this.props,
    });
    setChartType(chartType);
  }

  componentDidUpdate(prevState) {
    let { type, setChartType } = this.props;
    const chartType = getChartType({
      title: type,
      ...this.props,
    });
    setChartType(chartType);
  }

  render() {
    const { systemOverviewSummary, chartType, type } = this.props;
    return (
      <div>
        <div className="system-report-admin">
          <div className="com-title">Completed Activities</div>
        </div>

        <div className="completion">
          <Grid className="system-summary-grid" padded>
            <div className="org-count">
              <div>
                <div className="title-org">Account count</div>
                <div className="count-value">
                  {systemOverviewSummary.completed
                    ? thousands(systemOverviewSummary.completed.orgCount)
                    : '-'}
                </div>
              </div>

              <div className="adjust-left">
                <div className="title-org">Activity count</div>
                <div className="count-value">
                  {systemOverviewSummary.completed
                    ? thousands(systemOverviewSummary.completed.activityCount)
                    : '-'}
                </div>
              </div>

              <div className="adjust-left">
                <div className="title-org">Actual Expenditures</div>
                <div className="count-value">
                  {systemOverviewSummary.completed
                    ? `$${thousands(
                        systemOverviewSummary.completed.actualExpenditure
                      )}`
                    : '-'}
                </div>
              </div>
            </div>
          </Grid>
        </div>

        <Grid className="outcomes-summary-grid" padded>
          <Grid.Row className="outcomes-chart-row">
            <div className="outcomes-summary-chart-div">
              {type === 'All Outcomes' ? (
                <Tab
                  className="tabs-graph"
                  menu={{ secondary: true, pointing: true }}
                  panes={[
                    {
                      menuItem: 'Aggregrate',
                      render: () => (
                        <Tab.Pane>
                          <PostSurveyChart {...this.props} />
                        </Tab.Pane>
                      ),
                    },
                    {
                      menuItem: 'Trend - Rate of change',
                      render: () => (
                        <Tab.Pane>
                          <PostPreTrendSurvey method="all" {...this.props} />
                        </Tab.Pane>
                      ),
                    },
                  ]}
                />
              ) : (
                <Fragment>
                  {chartType === 'postChart' ? (
                    <PostSurveyChart {...this.props} />
                  ) : null}
                  {chartType === 'prePostChart' ? (
                    <PostPreTrendSurvey method="all" {...this.props} />
                  ) : null}
                </Fragment>
              )}
            </div>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    participantsChangePrePost: state.report.participantsChangePrePost,
    participants: state.report.participants,
    systemOverviewSummary: state.report.systemOverviewSummary,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

SystemOverview = connect(mapStateToProps, mapDispatchToProps)(SystemOverview);

export { SystemOverview };
