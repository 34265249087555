/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import '../../../activity.scss';
import React from 'react';
import { Grid } from 'semantic-ui-react';

import { get } from '../../../../../../utils/lodash';

export default function EmailResponseFormExistingUser({
  data = {},
  isShowEntityName = false,
  projected = false,
}) {
  return (
    <div>
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <div className="formik-custom-field act-inputs-new">
            <label>First Name</label>
            <br />
            <label>{get(data, 'firstName', '-')}</label>
          </div>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <div className="formik-custom-field act-inputs-new">
            <label>Last Name</label>
            <br />
            <label>{get(data, 'surname', '-')}</label>
          </div>
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <div className="formik-custom-field act-inputs-new">
            <label>Email</label>
            <br />
            <label>{get(data, 'email', '-')}</label>
          </div>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <div className="formik-custom-field act-inputs-new">
            <label>Phone</label>
            <br />
            <label>{get(data, 'phone', '-')}</label>
          </div>
        </Grid.Column>
      </Grid>
      {(isShowEntityName || projected) && (
        <>
          <Grid>
            <Grid.Column>
              <div className="formik-custom-field act-inputs-new">
                <label>Business/ Organization Name</label>
                <br />
                <label>{get(data, 'organisation.name', '-')}</label>
              </div>
            </Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="formik-custom-field act-inputs-new">
                <label>Address Line 1</label>
                <br />
                <label>{get(data, 'organisation.address1', '-')}</label>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="formik-custom-field act-inputs-new">
                <label>Address Line 2</label>
                <br />
                <label>{get(data, 'organisation.address2', '-')}</label>
              </div>
            </Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column mobile={16} tablet={6} computer={6}>
              <div className="formik-custom-field act-inputs-new">
                <label>Suburb</label>
                <br />
                <label>{get(data, 'suburb', '-')}</label>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <div className="formik-custom-field act-inputs-new">
                <label>State</label>
                <br />
                <label>{get(data, 'state', '-')}</label>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <div className="formik-custom-field act-inputs-new">
                <label>Postcode</label>
                <br />
                <label>{get(data, 'postcode', '-')}</label>
              </div>
            </Grid.Column>
          </Grid>
        </>
      )}
    </div>
  );
}
