/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  Button,
  Card,
  Container,
  Dropdown,
  Grid,
  Icon,
  Modal,
  Popup,
  TextArea,
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteProjectNote,
  postProjectNote,
  putProjectNote,
} from '../../../store/actions';
import { isEmpty, get, orderBy } from '../../../utils/lodash';
import moment from 'moment';
import { getPageDisable } from '../../../utils';
import ShowMoreText from 'react-show-more-text';

import '../../activities/activities.scss';

const NoteCard = ({
  isNew,
  create,
  deleteNewNoteCard,
  currentActivity,
  toggleDeleteModal = null,
  onChangeAddNewNote = null,
  pageDisable,
  setUnSavedNote = null,
  visiblePanel,
  ...props
}) => {
  const noteId = get(props, 'data.id', null);
  const _note = get(props, 'data.note', null);
  const date = get(props, 'data.updatedAt', null);
  const userName = get(props, 'data.userName', null);
  const _date = date ? moment(date).format('YYYY-MM-DD HH:mm A') : null;

  const [isEditing, setIsEditing] = React.useState(false);
  const [isError, setIsError] = React.useState(_note ? false : true);
  const [note, setNote] = React.useState(!isEmpty(_note) ? _note : '');

  const dispatch = useDispatch();
  const projectNoteLoading = useSelector(
    (state) => state.activity.projectNoteLoading
  );

  useEffect(() => {
    if (!visiblePanel) {
      setNote(_note);
      setIsEditing(false);
    }
  }, [visiblePanel]);

  const saveNote = async () => {
    const isSubmitted = await dispatch(
      postProjectNote(currentActivity.id, {
        note,
      })
    );

    if (isSubmitted) {
      deleteNewNoteCard && deleteNewNoteCard();
      setIsEditing(false);
    }
  };

  const updateNote = async (noteId) => {
    const isSubmitted = await dispatch(
      putProjectNote(currentActivity.id, { noteId, note })
    );

    if (isSubmitted) {
      deleteNewNoteCard && deleteNewNoteCard();
      setIsEditing(false);
    }
  };

  const toggleCancel = (noteId) => {
    setNote(_note);
    if (isEditing) {
      setIsEditing(false);
      setUnSavedNote && setUnSavedNote(false);
    } else {
      deleteNewNoteCard && deleteNewNoteCard();
    }
  };

  const onChangeTxt = (value) => {
    if (isEmpty(value)) {
      setIsError(true);

      setNote(value);
      setUnSavedNote && setUnSavedNote(false);
    } else {
      setIsError(false);

      setNote(value);
      setUnSavedNote && setUnSavedNote(true);
    }
  };

  return (
    <>
      {!isNew && !isEditing ? (
        <Card className="note-card">
          <div className="note-container">
            <Card.Content className="note-card-view-text-area" disabled={isNew}>
              <div className="note-show-more">
                <ShowMoreText
                  lines={3}
                  more="Read More"
                  less="Read Less"
                  anchorClass="show-more-less-clickable"
                  expanded={false}
                  truncatedEndingComponent={'... '}
                >
                  <p className="info-fs-1 pre-line">{note}</p>
                </ShowMoreText>
              </div>
            </Card.Content>

            <div className="note-card-view-user-area">
              <p className="text-muted info-fs-1">- {userName} </p>
            </div>
          </div>
          <Card.Content extra className="note-card-create-extra">
            <div className="d-flex text-muted py-0 border-top">
              <div className="note-updated-time ">
                {_date && !isEditing ? <>{`Last updated: ${_date}`}</> : null}
              </div>
              <div className="ml-auto px-0">
                {!pageDisable ? (
                  <Dropdown
                    icon="ellipsis vertical"
                    direction="left"
                    inline
                    selectOnBlur={false}
                    className="note-card-ellipse"
                  >
                    <Dropdown.Menu className="note-card-dropdown-items">
                      <Dropdown.Item
                        onClick={() => {
                          setIsEditing(true);
                        }}
                      >
                        <label className="black-text">Edit Note</label>
                      </Dropdown.Item>
                      <Dropdown.Divider className="m-0" />
                      <Dropdown.Item
                        onClick={() => {
                          if (toggleDeleteModal) toggleDeleteModal(noteId);
                        }}
                      >
                        <label className="remove-activity ">Delete</label>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : null}
              </div>
            </div>
          </Card.Content>
        </Card>
      ) : (
        <Card className="note-card">
          <Card.Content className="note-card-text-area" disabled={isNew}>
            <TextArea
              placeholder="Enter note"
              className="info-fs-1"
              style={{ minHeight: 130 }}
              disabled={!isNew && !isEditing}
              value={note}
              onChange={(e, data) => {
                onChangeTxt(data.value);
              }}
            />
          </Card.Content>
          <Card.Content extra className="note-card-create-extra">
            <div className="d-flex text-muted py-0">
              <div className="ml-auto px-0">
                {isNew || isEditing ? (
                  <>
                    <Popup
                      position="bottom left"
                      content="Note is required to save."
                      trigger={
                        <Button
                          floated="right"
                          className={
                            isError
                              ? `btn-save-note btn-save-note-disabled`
                              : `btn-save-note`
                          }
                          content={'Save'}
                          onClick={() => {
                            if (!isError) {
                              !isEditing ? saveNote() : updateNote(noteId);
                            }
                          }}
                          disabled={projectNoteLoading}
                          loading={projectNoteLoading}
                        />
                      }
                      disabled={!isError}
                    />
                    <Button
                      floated="right"
                      className="btn-cancel-note"
                      content={'Cancel'}
                      onClick={() => toggleCancel(noteId)}
                      disabled={projectNoteLoading}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </Card.Content>
        </Card>
      )}
    </>
  );
};

const ActivityNotes = ({
  setUnSavedNote,
  visiblePanel,
  currentActivity,
  user,
  ...props
}) => {
  let projectNotes = get(currentActivity, 'projectNotes', []);
  projectNotes = !isEmpty(projectNotes)
    ? orderBy(projectNotes, ['id'], ['desc'])
    : [];

  const [deleteModal, setDeleteModal] = React.useState(false);
  const [deleteNoteId, setDeleteNoteId] = React.useState(null);
  const [showNote, setShowNote] = React.useState(false);

  const dispatch = useDispatch();
  // const getProjectNoteLoading = useSelector(
  //   state => state.activity.getProjectNoteLoading
  // );
  const deleteProjectNoteLoading = useSelector(
    (state) => state.activity.deleteProjectNoteLoading
  );

  const pageDisable =
    getPageDisable(currentActivity, user, 'project.edit.notes') ||
    currentActivity.statusId === 3;

  useEffect(() => {
    if (!visiblePanel) {
      setShowNote(false);
    }
  }, [visiblePanel]);

  const deleteNote = async () => {
    const isSubmitted = await dispatch(
      deleteProjectNote(currentActivity.id, deleteNoteId)
    );

    if (isSubmitted) {
      toggleNoteDeleteModal();
    }
  };

  const toggleNoteDeleteModal = (noteId = null) => {
    const _deleteModal = noteId ? true : false;
    setDeleteNoteId(noteId);
    setDeleteModal(_deleteModal);
  };

  const addNewNote = () => {
    setShowNote(true);
  };

  const deleteNewNoteCard = () => {
    setShowNote(false);
    setUnSavedNote(false);
  };

  const DeleteConfirmationModal = ({ noteId }) => {
    return (
      <Modal
        open={deleteModal}
        closeIcon={
          <Icon
            onClick={() => toggleNoteDeleteModal()}
            name="close"
            className="closeicon"
          />
        }
        size="tiny"
      >
        <Modal.Header className="modal-header-font">Delete note</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this note ?</p>
        </Modal.Content>
        <Modal.Actions>
          <div className="model-buttons">
            <Button
              onClick={() => toggleNoteDeleteModal()}
              className="Primary-Button"
            >
              No
            </Button>
            <Button
              onClick={() => deleteNote(noteId)}
              className="Secondary-Button"
              content="Yes"
              loading={deleteProjectNoteLoading}
              disabled={deleteProjectNoteLoading}
            />
          </div>
        </Modal.Actions>
      </Modal>
    );
  };

  return (
    <div className="action-container px-2 py-3">
      <Grid className="mt-2 p-0 mx-0">
        <Grid.Column floated="right" width={16}>
          <div>
            {!pageDisable && (
              <Button
                floated="right"
                className={
                  showNote
                    ? 'cursor-disabled btn-add-note-disable'
                    : 'btn-add-note'
                }
                content={
                  <span className="add-note-lbl">
                    <Icon name="plus" />
                    Add Note
                  </span>
                }
                onClick={() => addNewNote()}
              />
            )}
          </div>
        </Grid.Column>
        <div className="note-card-container">
          <Container>
            {!pageDisable && showNote && (
              <NoteCard
                isNew={true}
                currentActivity={currentActivity}
                deleteNewNoteCard={() => deleteNewNoteCard()}
                pageDisable={pageDisable}
                setUnSavedNote={setUnSavedNote}
                visiblePanel={visiblePanel}
              />
            )}

            {projectNotes.map((note) => (
              <>
                <NoteCard
                  currentActivity={currentActivity}
                  key={note.id}
                  isNew={false}
                  data={note}
                  toggleDeleteModal={(noteId) => {
                    toggleNoteDeleteModal(noteId);
                  }}
                  pageDisable={pageDisable}
                  setUnSavedNote={setUnSavedNote}
                  visiblePanel={visiblePanel}
                  {...props}
                />
              </>
            ))}
          </Container>
        </div>
      </Grid>
      <DeleteConfirmationModal />
    </div>
  );
};

export default ActivityNotes;
