/* eslint-disable no-fallthrough */
import React, { Children, cloneElement, Fragment, isValidElement } from 'react';

import $ from 'jquery';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ScrollTop from 'react-scrolltop-button';
import { bindActionCreators } from 'redux';
import AdvancedFilter from './sideItems/advancedFilter';
import {
  Container,
  Grid,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Popup,
  Button,
  Modal,
} from 'semantic-ui-react';

import logo from '../../assets/navigation/dashboard-logo.svg';
import {
  ACTIVITIES_ROUTE,
  REPORT_ROUTE,
  INFO_ROUTES,
  ORG_ONLY_ROUTES,
  ALLOWED_ONBOARDING_TIPS_PATH,
  SIDEBAR_TYPES,
} from '../../utils/constant';
import { cloneDeep, get, map, remove } from '../../utils/lodash';
import { getActivityType, renderInfo, getNotePermission } from '../../utils';
import { Profile } from '../../components/index';
import ActivityNotes from './sideItems/activityNotes';
import { isParentProgramActivity } from '../../utils/activity';
import {
  isFeature,
  isIndividualUser,
} from '../../utils/activity/userPermission';
import getMinifiedText from '../../utils/minifiedText';
import { sendEmailVerification } from '../../store/actions';
import { setSidebarType, setVisibleTips } from '../../store/modules/sidebar';

export class UnsavedNoteModal extends React.PureComponent {
  render() {
    const { unSavedNoteModal, toggleUnSavedNoteModal, onConfirmQuit } =
      this.props;
    return (
      <Modal
        open={unSavedNoteModal}
        closeIcon={
          <Icon
            onClick={() => {
              toggleUnSavedNoteModal(false);
            }}
            name="close"
            className="closeicon"
          />
        }
        size="tiny"
      >
        <Modal.Header className="warning-header-text">
          Are you sure you want to leave ?
        </Modal.Header>
        <Modal.Content className="warning-content-text">
          You have unsaved changes. Do you want to leave ?
        </Modal.Content>

        <Modal.Actions>
          <div className="model-buttons">
            <Button
              onClick={() => toggleUnSavedNoteModal(false)}
              className="Primary-Button mr-2"
            >
              Stay
            </Button>
            <Button
              onClick={() => {
                onConfirmQuit();
              }}
              className="Secondary-Button"
              content="Leave"
            />
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

class WebSideBar extends React.PureComponent {
  constructor(props) {
    const { user } = props;
    super(props);
    this.state = {
      title: props.title,
      group: null,
      routeSelection: '',
      isUnSavedNote: false,
      unSavedNoteModal: false,
      shownNotVerifiedMessage: !get(user, 'isVerifiedEmail', false),
      sentEmailVerification: false,
    };
  }

  componentWillUnmount() {
    this.setState({
      title: this.props.title,
      visiblePanel: false,
      group: null,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { match: prevMatch } = prevProps;
    const {
      match,
      currentActivity,
      isAdvancedFilter,
      visiblePanel,
      setSidebarType,
    } = this.props;

    if (ACTIVITIES_ROUTE.includes(this.props.match.path)) {
      const group = currentActivity && currentActivity.isLinkedActivity;

      this.setState({
        title: currentActivity ? currentActivity.name : '',
        group,
      });
    } else {
      if (prevState.title !== this.props.title) {
        this.setState({
          title: this.props.title,
          group: null,
          visiblePanel: false,
          unSavedNoteModal: false,
        });
      }
    }

    if (
      isAdvancedFilter &&
      visiblePanel &&
      (prevMatch.path !== match.path ||
        (match.path === '/activities/:id/:type' &&
          match.params.type !== 'documentation'))
    ) {
      setSidebarType(SIDEBAR_TYPES[2], false);
    }
  }

  // to control help panel (show/hide)
  togglerHelpPanelDrawer = () => {
    window.scrollTo(0, 0); // solved -> left help panel not focusing top of help panel in mobile view
    const { setSidebarType, visiblePanel } = this.props;
    setSidebarType(SIDEBAR_TYPES[0], !visiblePanel);
  };

  // to control advances filter
  togglerHideAdvancedFilterDrawer = () => {
    const { setSidebarType, visiblePanel } = this.props;
    setSidebarType(SIDEBAR_TYPES[2], !visiblePanel);
  };

  // to control activity notes (show/hide)
  togglerActivityNotesDrawer = () => {
    const { setSidebarType, visiblePanel } = this.props;

    if (this.state.isUnSavedNote && visiblePanel) {
      this.setState({ unSavedNoteModal: true });
    } else {
      window.scrollTo(0, 0); // solved -> left help panel not focusing top of help panel in mobile view

      setSidebarType(SIDEBAR_TYPES[1], !visiblePanel);
    }
  };

  // to control advanced filter panel (show/hide)
  onAdvancedFilterClicked() {
    window.scrollTo(0, 0); // solved -> left help panel not focusing top of help panel in mobile view
    const { setSidebarType, visiblePanel } = this.props;
    setSidebarType(SIDEBAR_TYPES[2], !visiblePanel);
  }

  // to control help panel from child components
  togglerHelpPanelDrawerFromChild = (visiblePanel) => {
    const { setSidebarType } = this.props;
    setSidebarType(SIDEBAR_TYPES[0], visiblePanel);
  };

  toggleUnSavedNoteModal = (status) => {
    this.setState({ unSavedNoteModal: status });
  };

  setUnSavedNote = (status) => {
    this.setState({ isUnSavedNote: status });
  };

  onConfirmQuit = () => {
    window.scrollTo(0, 0); // solved -> left help panel not focusing top of help panel in mobile view
    this.setState({
      unSavedNoteModal: false,
      isUnSavedNote: false,
    });
    const { setSidebarType, visiblePanel } = this.props;
    setSidebarType(SIDEBAR_TYPES[1], !visiblePanel);
  };

  render() {
    const {
      match,
      children,
      sub,
      actGetLoading,
      user,
      currentActivity,
      routes,
      isAdminRoute,
      visiblePanel,
      isHelpPanel,
      isAdvancedFilter,
      isActivityNote,
      setVisibleTips,
    } = this.props;

    const { title, group, unSavedNoteModal, sentEmailVerification } =
      this.state;

    const actType = getActivityType(currentActivity);

    const orgType = get(user, 'organisation.type', null);
    let role = get(user, 'role', null);

    if (isIndividualUser(user)) {
      role = 'individualAdmin';
    }

    const childrenWithProps = Children.map(children, (child) => {
      // Checking isValidElement is the safe way and avoids a TS error too.
      if (isValidElement(child)) {
        return cloneElement(child, {
          togglerHelpPanelDrawer: this.togglerHelpPanelDrawerFromChild,
        });
      }

      return child;
    });

    let getRoutes = cloneDeep(routes);
    let routeSelection = getRoutes.userRoutes;

    if (orgType && orgType === 'INDIVIDUAL' && !isAdminRoute) {
      routeSelection = remove(
        routeSelection,
        (n) => !ORG_ONLY_ROUTES.includes(n.id)
      );
    }
    if (role === 'admin' && isAdminRoute) {
      routeSelection = getRoutes.adminRoutes;
    }

    const isNoteAvailable =
      ACTIVITIES_ROUTE.includes(match.path) &&
      getNotePermission(this.props.currentActivity, user, 'read');

    const isOrgReportAdvancedFilterAvailable = REPORT_ROUTE.includes(
      match.path
    );

    const _isParentProgramActivity =
      !isOrgReportAdvancedFilterAvailable &&
      isParentProgramActivity(currentActivity.activityPlanTypeId);
    return (
      <Segment basic>
        <Grid>
          <Grid.Column
            mobile={16}
            tablet={1}
            computer={2}
            className="web-navbar no-print"
          >
            <div className="navbar-main">
              <div className="web-navbar-heading">
                <Menu.Item>
                  <Link to="/dashboard">
                    <div className="logo-container">
                      <Image src={logo} alt="logo" size="small" />
                    </div>
                  </Link>
                </Menu.Item>
              </div>
              {/*
               * Note:  nav-routes-container class name used for help tip showing class name (don't change it or remove it)
               */}
              <div className="nav-routes-container">
                {map(routeSelection, (item, i) => {
                  if (!item.permission.includes(role)) return null;
                  if (!isFeature(user, item.features)) return null;
                  const image = get(item, 'image', null);
                  return (
                    <Menu.Item key={i}>
                      <Link
                        to={match.path !== item.route ? item.route : '#'}
                        onClick={() => {
                          $('.content-layer').scrollTop(0);
                        }}
                      >
                        <Popup
                          key={i}
                          inverted
                          trigger={
                            <div
                              className={`route-container web-route-container ${
                                match.path === item.route ||
                                item.subRoutes.includes(match.path)
                                  ? 'route-container-active'
                                  : null
                              } ${item.id}`}
                            >
                              <Fragment>
                                {image ? (
                                  <Image
                                    src={image}
                                    alt={item.title}
                                    className={
                                      item.resize
                                        ? 'nav-image-resize'
                                        : 'nav-image'
                                    }
                                  />
                                ) : (
                                  <Icon
                                    size="big"
                                    name={item.icon}
                                    className="nav-icon-opacity"
                                    inverted
                                  />
                                )}
                              </Fragment>
                            </div>
                          }
                          content={item.title}
                          position="right center"
                        />
                      </Link>
                    </Menu.Item>
                  );
                })}
              </div>
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={15}
            computer={14}
            className="main-content"
          >
            {this.state.shownNotVerifiedMessage && (
              <div className="user-email-verification">
                {/* <div className="d-flex"> */}
                <div className="email-verification-lbl ml-5">
                  <Icon name="warning circle mr-1" />
                  {sentEmailVerification ? (
                    <>
                      Verification email has been sent. Please check your email
                      to verify your email address
                    </>
                  ) : (
                    <>
                      Takso email policy has been updated. To keep receiving
                      emails, please verify your email address.
                    </>
                  )}
                </div>

                <div className="ml-auto pr-5">
                  {' '}
                  <span>
                    <span
                      className="mt-0 verify-btn mr-2"
                      onClick={() => {
                        const {
                          sendEmailVerificationLoading,
                          sendEmailVerification,
                        } = this.props;
                        if (!sendEmailVerificationLoading) {
                          this.setState({ sentEmailVerification: true });
                          sendEmailVerification();
                        }
                      }}
                    >
                      {sentEmailVerification ? 'Resend Verification' : 'Verify'}
                    </span>{' '}
                    <span
                      className="mt-0 dismiss-btn mr-5"
                      onClick={() => {
                        this.setState({ shownNotVerifiedMessage: false });
                      }}
                    >
                      Dismiss
                    </span>
                  </span>
                </div>
                {/* </div> */}
              </div>
            )}
            <div className="main-content-header no-print">
              <Grid columns="equal" className="header-grid">
                <Grid.Column width={10}>
                  <Container className="header-title">
                    <div className="header-title-container">
                      <label className="header-title-text">
                        <div className="header-title-flex">
                          {actGetLoading ? null : title && title.length > 50 ? (
                            <Popup
                              content={<label>{title}</label>}
                              wide
                              position="bottom center"
                              trigger={
                                <label>{`${title.slice(0, 50)}...`}</label>
                              }
                            />
                          ) : (
                            <label>{title}</label>
                          )}
                          {title ? (
                            title === 'Activity Participants' ? (
                              <label className="activity-participant-sub">{`Activity Name: ${getMinifiedText(
                                currentActivity.name,
                                20
                              )}`}</label>
                            ) : null
                          ) : null}

                          {group ? (
                            <label className="header-title-group">
                              {actType.parentActivity ? (
                                <>
                                  {' '}
                                  Program Activity:{' '}
                                  <span className="header-title-group-color">
                                    {getMinifiedText(
                                      actType.parentActivity,
                                      20
                                    )}
                                  </span>
                                </>
                              ) : null}
                              {actType.organisation ? (
                                <>
                                  {' '}
                                  | Organisation:{' '}
                                  <span className="header-title-group-color">
                                    {getMinifiedText(actType.organisation, 20)}
                                  </span>
                                </>
                              ) : null}
                              <>
                                {/* Activity Type:{' '} */}
                                {actType.parentActivity || actType.organisation
                                  ? ' | '
                                  : null}
                                <span className="header-title-group-color">
                                  {getMinifiedText(actType.title, 20)}
                                </span>
                              </>
                            </label>
                          ) : null}
                        </div>
                      </label>
                    </div>
                  </Container>
                </Grid.Column>
                <Grid.Column width={2} />
                <Grid.Column width={4}>
                  <div className="profile-container">
                    <Profile {...this.props} isAdminRoute={isAdminRoute} />
                  </div>
                </Grid.Column>
              </Grid>
            </div>
            {/* changes */}
            <Sidebar.Pushable as={Segment}>
              <Sidebar
                as={Menu}
                animation={
                  !_isParentProgramActivity &&
                  isAdvancedFilter &&
                  window.screen.width > 1000
                    ? 'push'
                    : 'overlay'
                }
                direction="right"
                vertical
                visible={visiblePanel}
                width="wide"
                className={
                  !_isParentProgramActivity && isAdvancedFilter
                    ? 'filter-width advanced-filter'
                    : 'help-note-width'
                }
              >
                {isAdvancedFilter ? (
                  <AdvancedFilter
                    togglerHideAdvancedFilterDrawer={
                      this.togglerHideAdvancedFilterDrawer
                    }
                    match={this.props.match}
                    navigate={this.props.navigate}
                    isProgram={Boolean(_isParentProgramActivity)}
                    projectId={currentActivity.id}
                  />
                ) : isActivityNote ? (
                  <>
                    <div className="page-info">
                      <div className="header">
                        <label>Activity Notes</label>
                        <Icon
                          name="close"
                          className="info-close"
                          onClick={() => this.togglerActivityNotesDrawer()}
                        />
                      </div>
                      {
                        <ActivityNotes
                          setUnSavedNote={this.setUnSavedNote}
                          visiblePanel={visiblePanel}
                          {...this.props}
                        />
                      }
                    </div>
                  </>
                ) : isHelpPanel ? (
                  <div className="page-info">
                    <div className="header">
                      <label>Help Center</label>
                      <Icon
                        name="close"
                        className="info-close"
                        onClick={this.togglerHelpPanelDrawer}
                      />
                    </div>
                    {ALLOWED_ONBOARDING_TIPS_PATH.includes(match.path) && (
                      <div id="helpPageAction" className="action-container">
                        <div className="content">
                          <label className="fw-bold ">
                            Take a quick tour of Takso{' '}
                          </label>
                          <p>
                            Walk through Takso’s unique activity planing and
                            evaluation features.
                          </p>
                          <Button
                            className="btn-show-onboard "
                            content="Show Me Around"
                            onClick={() => setVisibleTips(true)}
                          />
                        </div>
                      </div>
                    )}
                    <>
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: renderInfo(this.props),
                        }}
                      />
                    </>
                  </div>
                ) : (
                  <></>
                )}
              </Sidebar>
              <Sidebar.Pusher dimmed={false}>
                <div
                  className={
                    !_isParentProgramActivity &&
                    isOrgReportAdvancedFilterAvailable
                      ? 'sidebar-pusher-container'
                      : ''
                  }
                >
                  {sub ? (
                    <div
                      className={
                        isParentProgramActivity(
                          currentActivity.activityPlanTypeId
                        )
                          ? 'content-steps parent-act'
                          : 'content-steps'
                      }
                    >
                      <div className="content-container-steps">
                        {childrenWithProps}
                      </div>
                    </div>
                  ) : (
                    // <div className="content-layer">
                    //   <div className="content-container">
                    //     <Grid>{childrenWithProps}</Grid>
                    //   </div>
                    // </div>
                    <div
                      className={
                        visiblePanel
                          ? 'content-layer panel-open'
                          : 'content-layer'
                      }
                    >
                      <div
                        className={
                          !_isParentProgramActivity &&
                          visiblePanel &&
                          isAdvancedFilter
                            ? 'content-container panel-open'
                            : 'content-container'
                        }
                      >
                        <Grid>{childrenWithProps}</Grid>
                      </div>
                    </div>
                  )}
                </div>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Grid.Column>
        </Grid>
        <ScrollTop
          breakpoint={2560}
          text="scroll to top"
          className="scroll-your-role"
          icon={
            <Icon
              size="big"
              name="chevron circle up"
              className="scrolltop-custom"
            />
          }
        />
        {!visiblePanel ? (
          <>
            {/* Note: help-page class name used for help tip showing class name
            (don't change it or remove it) */}
            <div
              id="helpPage"
              className={
                isOrgReportAdvancedFilterAvailable && isNoteAvailable
                  ? 'help-page right-with-filters-note'
                  : isOrgReportAdvancedFilterAvailable && !isNoteAvailable
                    ? 'help-page right-with-filters'
                    : isNoteAvailable
                      ? 'help-page right-with-note'
                      : 'help-page right-without-note'
              }
            >
              <div className="right-centre">
                {INFO_ROUTES.includes(match.path) && (
                  <span
                    className="panel-lbl mr-2"
                    onClick={this.togglerHelpPanelDrawer}
                  >
                    {' '}
                    HELP&nbsp;
                    <Icon
                      name="question"
                      size="small"
                      className="ques-icon"
                    />{' '}
                  </span>
                )}

                {isNoteAvailable && (
                  <span
                    className="panel-lbl mr-2"
                    onClick={this.togglerActivityNotesDrawer}
                  >
                    NOTES
                  </span>
                )}

                {isOrgReportAdvancedFilterAvailable && (
                  <span
                    className="panel-lbl mr-2"
                    onClick={() => this.onAdvancedFilterClicked()}
                  >
                    ADVANCED FILTER
                  </span>
                )}
              </div>
            </div>
          </>
        ) : null}
        <UnsavedNoteModal
          unSavedNoteModal={unSavedNoteModal}
          toggleUnSavedNoteModal={this.toggleUnSavedNoteModal}
          onConfirmQuit={this.onConfirmQuit}
        />
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  const { authentication, extra, activity, user } = state;
  return {
    user: authentication.user,
    sendEmailVerificationLoading: user.sendEmailVerificationLoading,
    helpPages: extra.helpPages,
    visiblePanel: state.sidebar.isVisibleSidebar,
    isHelpPanel: state.sidebar.sideBarType === SIDEBAR_TYPES[0],
    isActivityNote: state.sidebar.sideBarType === SIDEBAR_TYPES[1],
    isAdvancedFilter: state.sidebar.sideBarType === SIDEBAR_TYPES[2],
    visibleTips: state.sidebar.isVisibleTips,
    ...activity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { sendEmailVerification, setSidebarType, setVisibleTips },
    dispatch
  );
};

WebSideBar = connect(mapStateToProps, mapDispatchToProps)(WebSideBar);

export { WebSideBar };
