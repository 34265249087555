import React, { Fragment, Children, isValidElement, cloneElement } from 'react';

import $ from 'jquery';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  Button,
  Container,
  Grid,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
} from 'semantic-ui-react';

import logo from '../../assets/navigation/dashboard-logo.svg';
import {
  ACTIVITIES_ROUTE,
  INFO_ROUTES,
  ORG_ONLY_ROUTES,
  ALLOWED_ONBOARDING_TIPS_PATH,
} from '../../utils/constant';
import { cloneDeep, get, map, remove } from '../../utils/lodash';
import { renderInfo, getNotePermission } from '../../utils';
import { Profile } from '../../components/index';
import { UnsavedNoteModal } from './sideBar';
import ActivityNotes from './sideItems/activityNotes';
import {
  isFeature,
  isIndividualUser,
} from '../../utils/activity/userPermission';

class MobileSideBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      routeSelection: '',
      visibleNavigationBar: false, // left navigation bar visibility
      visiblePanel: false, // help panel visibility
      visibleTips: false, // help tips visibility
      isActivityNote: false,
      isUnSavedNote: false,
      unSavedNoteModal: false,
    };
  }

  componentWillUnmount() {
    this.setState({
      visibleNavigationBar: false,
      title: this.props.title,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (ACTIVITIES_ROUTE.includes(this.props.match.path)) {
      this.setState({
        title: this.props.currentActivity
          ? this.props.currentActivity.name
            ? this.props.currentActivity.name
            : ''
          : this.props.title,
      });
    } else {
      if (prevState.title !== this.props.title) {
        this.setState({
          title: this.props.title,
          visiblePanel: false,
          unSavedNoteModal: false,
        });
      }
    }
  }

  // left navigation bar toggler (show/hide)
  togglerDrawer = () => {
    this.setState((prevState) => {
      return { visibleNavigationBar: !prevState.visibleNavigationBar };
    });
  };

  // left navigation bar toggler from child components
  togglerDrawerFromChild = (visibleNavigationBar) => {
    this.setState({ visibleNavigationBar });
  };

  handleSidebarHide = () => this.setState({ visibleNavigationBar: false });

  // to control help panel (show/hide)
  togglerHelpPanelDrawer = () => {
    window.scrollTo(0, 0); // solved -> left help panel not focusing top of help panel in mobile view
    this.setState((prevState) => {
      return { visiblePanel: !prevState.visiblePanel };
    });
  };

  // to control help panel from child components
  togglerHelpPanelDrawerFromChild = (visiblePanel) => {
    this.setState({ visiblePanel, isActivityNote: false });
  };

  // to control activity notes (show/hide)
  togglerActivityNotesDrawer = () => {
    if (this.state.isUnSavedNote && this.state.visiblePanel) {
      this.setState({ unSavedNoteModal: true });
    } else {
      window.scrollTo(0, 0); // solved -> left help panel not focusing top of help panel in mobile view
      this.setState({
        visiblePanel: !this.state.visiblePanel,
        isActivityNote: true,
      });
    }
  };

  // to trigger help tip onboarding in child components
  togglerOnboardingTips = (visibleTips) => {
    window.scrollTo(0, 0);
    this.setState({ visibleTips: visibleTips, visiblePanel: false });
  };

  handleInfoSidebarHide = () => this.setState({ visiblePanel: false });

  changeRoute() {
    $('.content-layer').scrollTop(0);
    this.setState({ visibleNavigationBar: false, visiblePanel: false });
  }

  toggleUnSavedNoteModal = (status) => {
    this.setState({ unSavedNoteModal: status });
  };

  setUnSavedNote = (status) => {
    this.setState({ isUnSavedNote: status });
  };

  onConfirmQuit = () => {
    window.scrollTo(0, 0); // solved -> left help panel not focusing top of help panel in mobile view
    this.setState({
      visiblePanel: !this.state.visiblePanel,
      unSavedNoteModal: false,
      isUnSavedNote: false,
    });
  };

  render() {
    const { match, children, actGetLoading, user, routes, isAdminRoute } =
      this.props;
    const {
      visibleNavigationBar,
      visiblePanel,
      title,
      visibleTips,
      isActivityNote,
      unSavedNoteModal,
    } = this.state;

    const orgType = get(user, 'organisation.type', null);
    let role = get(user, 'role', null);
    if (isIndividualUser(user)) {
      role = 'individualAdmin';
    }

    let routeSelection = routes.userRoutes;
    if (orgType && orgType === 'INDIVIDUAL' && !isAdminRoute) {
      let getRoutes = cloneDeep(routeSelection);
      routeSelection = remove(
        getRoutes,
        (n) => !ORG_ONLY_ROUTES.includes(n.id)
      );
    }
    if (role === 'admin' && isAdminRoute) {
      routeSelection = routes.adminRoutes;
    }

    const childrenWithProps = Children.map(children, (child) => {
      // Checking isValidElement is the safe way and avoids a TS error too.
      if (isValidElement(child)) {
        return cloneElement(child, {
          togglerDrawer: this.togglerDrawerFromChild,
          togglerHelpPanelDrawer: this.togglerHelpPanelDrawerFromChild,
          togglerOnboardingTips: this.togglerOnboardingTips,
          visibleTips: visibleTips,
        });
      }
      return child;
    });

    const isNoteAvailable =
      ACTIVITIES_ROUTE.includes(match.path) &&
      getNotePermission(this.props.currentActivity, user, 'read');

    return (
      <>
        <Sidebar.Pushable
          as={Segment}
          className="sidebar-custom-segment no-print"
        >
          <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            onHide={this.handleSidebarHide}
            vertical
            visible={visibleNavigationBar}
            width="very thin"
            className="custom-sidebar"
          >
            <Menu.Item className="mobile-logo-container">
              <Link to="/dashboard" onClick={() => this.changeRoute()}>
                <div className="logo-container">
                  <Image src={logo} alt="logo" size="small" />
                </div>
              </Link>
            </Menu.Item>
            {map(routeSelection, (item, i) => {
              if (!item.permission.includes(role)) return null;
              if (!isFeature(user, item.features)) return null;
              const image = get(item, 'image', null);
              return (
                <Menu.Item
                  as="a"
                  key={i}
                  className={`mobile-nav-container ${
                    match.path === item.route ? 'navbar-active' : null
                  }`}
                >
                  <Link
                    onClick={() => this.changeRoute()}
                    to={match.path !== item.route ? item.route : '#'}
                  >
                    <div className="route-container">
                      <Fragment>
                        {image ? (
                          <Image
                            src={image}
                            alt={item.title}
                            className={
                              item.resize ? 'nav-image-resize' : 'nav-image'
                            }
                          />
                        ) : (
                          <Icon
                            size="big"
                            name={item.icon}
                            className="nav-icon-opacity"
                            inverted
                          />
                        )}
                      </Fragment>
                    </div>
                  </Link>
                </Menu.Item>
              );
            })}
          </Sidebar>
          <Sidebar.Pusher dimmed={visibleNavigationBar}>
            <Sidebar.Pushable as={Segment}>
              <Sidebar
                as={Menu}
                animation="overlay"
                direction="right"
                vertical
                visible={visiblePanel}
                width={'wide'}
              >
                {isActivityNote ? (
                  <>
                    <div className="page-info">
                      <div className="header">
                        <label>Activity Notes</label>
                        <Icon
                          name="close"
                          className="info-close"
                          onClick={() => this.togglerActivityNotesDrawer()}
                        />
                      </div>
                      {
                        <ActivityNotes
                          visiblePanel={visiblePanel}
                          setUnSavedNote={this.setUnSavedNote}
                          {...this.props}
                        />
                      }
                    </div>
                  </>
                ) : (
                  <div className="page-info">
                    <div className="header">
                      <label>Help Center</label>
                      <Icon
                        name="close"
                        className="info-close"
                        onClick={this.handleInfoSidebarHide}
                      />
                    </div>
                    {ALLOWED_ONBOARDING_TIPS_PATH.includes(match.path) && (
                      <div className="action-container">
                        <div className="content">
                          <label className="fw-bold ">
                            Take a quick tour of Takso{' '}
                          </label>
                          <p>
                            Walk through Takso’s unique activity planing and
                            evaluation features.
                          </p>
                          <Button
                            className="btn-show-onboard "
                            content="Show Me Around"
                            onClick={() => this.togglerOnboardingTips(true)}
                          />
                        </div>
                      </div>
                    )}
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: renderInfo(this.props),
                      }}
                    />
                  </div>
                )}
              </Sidebar>
              <Sidebar.Pusher>
                <Segment basic>
                  <Grid>
                    <Grid.Row className="mobile-header">
                      <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={16}
                        className="mob-main-content"
                      >
                        <Grid columns="equal">
                          <Grid.Column
                            width={4}
                            className="mob-toggle-container"
                          >
                            <Button.Group>
                              <Button basic icon>
                                <Icon
                                  size="large"
                                  name="bars"
                                  color="black"
                                  onClick={this.togglerDrawer}
                                />
                              </Button>
                            </Button.Group>
                          </Grid.Column>
                          <Grid.Column width={8}>
                            <Container className="header-title">
                              <label className="header-title-text">
                                {actGetLoading ? null : title}
                              </label>
                            </Container>
                          </Grid.Column>
                          <Grid.Column width={4}>
                            <div className="profile-container">
                              <Profile {...this.props} />
                            </div>
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <div className="mob-content">
                        <Grid className="mob-content-container">
                          {childrenWithProps}
                        </Grid>
                      </div>
                    </Grid.Row>
                  </Grid>
                </Segment>
                {!visibleNavigationBar ? (
                  <>
                    {/* Note: help-page class name used for help tip showing class name
            (don't change it or remove it) */}
                    <div
                      className={
                        isNoteAvailable
                          ? 'help-page right-with-note'
                          : 'help-page right-without-note'
                      }
                    >
                      <div className="right-centre">
                        {INFO_ROUTES.includes(match.path) && (
                          <span
                            className="panel-lbl mr-2"
                            onClick={this.togglerHelpPanelDrawer}
                          >
                            {' '}
                            HELP&nbsp;
                            <Icon
                              name="question"
                              size="small"
                              className="ques-icon"
                            />{' '}
                          </span>
                        )}

                        {isNoteAvailable && (
                          <span
                            className="panel-lbl"
                            onClick={this.togglerActivityNotesDrawer}
                          >
                            NOTES
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                ) : null}
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
        <UnsavedNoteModal
          unSavedNoteModal={unSavedNoteModal}
          toggleUnSavedNoteModal={this.toggleUnSavedNoteModal}
          onConfirmQuit={this.onConfirmQuit}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { authentication, extra, activity } = state;
  return {
    user: authentication.user,
    helpPages: extra.helpPages,
    ...activity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

MobileSideBar = connect(mapStateToProps, mapDispatchToProps)(MobileSideBar);

export { MobileSideBar };
