import { AvForm, AvField } from 'availity-reactstrap-validation';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Container,
  Grid,
  Segment,
  Button,
  Dimmer,
  Modal,
  Loader,
  Icon,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import React, { Fragment } from 'react';

import { ageOptions } from '../../utils/constant';
import {
  CustomSegment,
  SurveyReview,
  EmptyContainer,
  SuccessContainer,
} from '../../components';
import { decodeBase64String, removeEditable } from '../../utils';
import { getSurvey, postSurvey } from '../../store/actions';
import { isEmpty, find, map, isNumber, orderBy } from '../../utils/lodash';
import { PostalCodeSearchInput } from '../../components/postalCodeSearchInput';

import './survey.scss';
import loginLogo from '../../assets/whitebox-login-logo.svg';

function QuestionComponent({ id, type, label }) {
  let component = null;
  const [postalCode, setPostalCode] = React.useState(null);

  const renderAgeOptions = () => {
    return map(ageOptions, (ageOption, i) => {
      return <option value={ageOption}>{ageOption}</option>;
    });
  };

  const changePostalCode = ({ target }) => {
    if (isNumber(parseInt(target.value))) {
      setPostalCode(target.value);
    }
  };

  switch (type) {
    case 'FREE':
      component = (
        <AvField
          name={`customQuestion_${id}`}
          style={{ minHeight: '6.25rem' }}
          type="textarea"
          label={label ? label : 'How are you?'}
        />
      );
      break;
    case 'AGE':
      component = (
        <Fragment>
          <AvField
            label="What is your age?"
            name={`customQuestion_${id}`}
            type="select"
            className="org-inputs"
          >
            <option value="" disabled defaultValue>
              Select Age
            </option>
            {renderAgeOptions()}
          </AvField>
          <small className="form-text text-muted">ABS Age Classification</small>
        </Fragment>
      );
      break;
    case 'EMAIL':
      component = (
        <AvField
          name={`customQuestion_${id}`}
          type="email"
          className="org-inputs"
          label={label ? label : "What's your company email?"}
          validate={{
            maxLength: {
              value: 200,
              errorMessage: 'Maximum Number of Characters is 200',
            },
            email: {
              value: true,
              errorMessage: 'Please enter valid email address',
            },
          }}
        />
      );
      break;
    case 'POSTCODE':
      component = (
        <div className="hidden-postcode">
          <PostalCodeSearchInput
            value={postalCode}
            onChange={changePostalCode}
            getLocation={(data) => setPostalCode(data.postcode)}
          />
          <AvField
            name={`customQuestion_${id}`}
            type="hidden"
            className="hidden-component"
            value={postalCode}
          />
        </div>
      );
      break;

    default:
      break;
  }

  return (
    component && (
      <Grid.Row>
        <Grid.Column computer={12}>{component}</Grid.Column>
      </Grid.Row>
    )
  );
}

class Surveys extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      surveyId: null,
      evaluations: [],
      results: [],
      surveySubmitted: false,
      ipLock: false,
      preview: false,
    };
    this.updateResults = this.updateResults.bind(this);
  }
  toggle() {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  }
  async getSetSurveyData() {
    this.setState({ surveyId: this.props.match.params.surveyId });
    await this.props.getSurvey(
      NotificationManager,
      this.props.match.params.surveyId
    );
    let { surveyData } = this.props;
    let evaluations = [],
      results = [];

    if (isEmpty(surveyData)) return;
    for (const val of surveyData.evaluation) {
      evaluations.push({
        question: {
          heading: val.heading ? removeEditable(val.heading) : '',
          methodHeading: val.methodHeading
            ? removeEditable(val.methodHeading)
            : '',
          question1: val.question1 ? removeEditable(val.question1) : '',
          question2: val.question2 ? removeEditable(val.question2) : '',
        },
        objectiveId: val.objectiveId,
        outcome: val.outcome,
        method: val.method,
        cumulative: val.cumulative,
      });
      results.push({
        id: val.objectiveId,
        result1: 1,
        result2: Boolean(val.cumulative) ? 1 : 0,
      });
    }
    this.setState({
      results,
      evaluations,
      preview:
        this.props.match.path === '/preview-survey/:surveyId' ? true : false,
    });
  }

  async componentDidMount() {
    await this.getSetSurveyData();
  }

  async componentDidUpdate(nextProps) {
    if (nextProps !== this.props) {
      // await this.getSetSurveyData ();
    }
  }
  updateResults(data) {
    let { results } = this.state;
    results.map((val) => {
      if (val.id === data.id) {
        return data;
      }
      return val;
    });
    this.setState({ results }, () => {});
  }
  renderQuestions() {
    let { evaluations = [], results } = this.state;
    evaluations = orderBy(evaluations, (i) => i.objectiveId);
    return evaluations.map((evaluation, i) => {
      let result = find(results, { id: evaluation.objectiveId });
      return (
        <Segment key={i} stacked className="my-3">
          <SurveyReview
            index={i}
            updateResults={this.updateResults}
            ratingData={result}
            {...evaluation}
          />{' '}
        </Segment>
      );
    });
  }
  handleValidSubmit = async (event, values) => {
    try {
      let { results } = this.state;
      results.map((val) => {
        val.reason = values[`${val.id}_Reason`] || '';
        val.email = values[`${val.id}_Email`] || '';
        val.postcode = values[`${val.id}_Postcode`] || '';
        val.comment = values[`${val.id}_Comment`] || '';
        return val;
      });

      const customAnswers = [];
      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          if (key.indexOf('customQuestion_') >= 0) {
            const question = key.split('_');
            if (question[0] === 'customQuestion') {
              const answer = values[key];
              if (answer !== '') {
                customAnswers.push({
                  id: Number(question[1]),
                  answer,
                });
              }
            }
          }
        }
      }

      let data = {
        customAnswers,
        results,
        email: values.email,
        evalPlanId: this.props.surveyData.evalPlanId,
      };
      const { ipLock } = await decodeBase64String(this.state.surveyId);

      await this.props.postSurvey(
        NotificationManager,
        data,
        this.props.match.params.surveyId
      );
      this.setState({ surveySubmitted: true, ipLock });
    } catch (error) {}
  };

  redirectToWebsite() {
    window.location.href = 'https://culturaldevelopment.net.au/';
  }

  redirectToNextSurvey(projectId) {
    if (projectId === 1879) {
      window.location.href =
        'https://www.basscoast.vic.gov.au/robertsmithsurvey';
    } else {
      window.location.href = '';
    }
  }

  render() {
    const { ipLock, modal, preview } = this.state;
    const { surveyData, surveyGetLoading = false, errorMessage } = this.props;
    const surveyValidation = moment(surveyData.eventStartDate) > moment();

    return (
      <div className="full-width">
        <Container fluid className="survey-header">
          {surveyGetLoading ? (
            <Segment className="loaderSegment">
              <Dimmer active>
                <Loader />
              </Dimmer>
            </Segment>
          ) : (
            <>
              <Grid.Row className="survey-sub-header">
                <Link className="whiteboxHeading" to={'/'}>
                  <img alt="TaksoLogo" className="takso-logo" src={loginLogo} />
                </Link>
              </Grid.Row>
              <div className="mainRow">
                {this.state.surveySubmitted ? (
                  <Segment className="notFoundSegment" textAlign="center">
                    <Grid>
                      <Grid.Row>
                        <SuccessContainer success surveyData={surveyData} />
                      </Grid.Row>
                      {!ipLock ? (
                        <Grid.Row className="survey-center">
                          <div className="display-flex mt-24">
                            {/* eslint-disable-next-line */}
                            {surveyData.projectId === 1879 ? null : (
                              <Modal
                                open={modal}
                                onOpen={() => this.toggle()}
                                onClose={() => this.toggle()}
                                size="mini"
                                trigger={
                                  <Button className="Secondary-Button">
                                    Close
                                  </Button>
                                }
                                className="close-confirmation-modal"
                              >
                                <Modal.Header>Close Confirmation</Modal.Header>
                                <Modal.Content>
                                  <p>
                                    Are You Sure You Want to Close This Survey?
                                  </p>
                                </Modal.Content>
                                <Modal.Actions className="model-buttons">
                                  <Button
                                    className="Secondary-Button"
                                    onClick={() => this.toggle()}
                                  >
                                    No
                                  </Button>
                                  <Button
                                    className="Primary-Button"
                                    onClick={() => this.redirectToWebsite()}
                                  >
                                    Yes
                                  </Button>
                                </Modal.Actions>
                              </Modal>
                            )}
                            {/*eslint-disable-next-line */}
                            <button
                              onClick={() =>
                                this.redirectToNextSurvey(surveyData.projectId)
                              }
                              className="Primary-Button"
                            >
                              Next response
                            </button>
                          </div>
                        </Grid.Row>
                      ) : null}
                    </Grid>
                  </Segment>
                ) : !isEmpty(surveyData) ? (
                  <CustomSegment
                    title={
                      surveyData.name
                        ? `SURVEY FOR ${surveyData.name.toUpperCase()}`
                        : ''
                    }
                    children={
                      <Segment>
                        <AvForm
                          onValidSubmit={this.handleValidSubmit}
                          className="survey-form"
                        >
                          {/* <Grid columns={2} stackable>
                        <Grid.Column>
                          <AvField
                            label="EMAIL"
                            className="profile-inputs"
                            name="email"
                            type="email"
                            placeholder="johndoe@whitebox.com"
                          />
                        </Grid.Column>
                      </Grid>

                      <div className="statement">
                        PRIVACY STATEMENT: As this is an online survey, we
                        require a unique identifier to ensure the response is
                        from a single source, in order to count this as a unique
                        response can you please provide your email address and
                        note the privacy policy. The email is encrypted and
                        stored and only used as a unique identifier to the
                        response. This information is not shared with any party
                        or used by the system for communication.
                      </div> */}
                          <div
                            style={{
                              fontSize: '1.125rem',
                              lineHeight: '1.25rem',
                            }}
                          >
                            <Segment.Group>
                              {this.renderQuestions()}
                              <Fragment>
                                {!isEmpty(surveyData.customQuestions) ? (
                                  <Segment stacked>
                                    <Grid className="custom-gen-questions">
                                      {map(
                                        surveyData.customQuestions,
                                        (question, i) => {
                                          return (
                                            <QuestionComponent
                                              key={i}
                                              type={question.type}
                                              label={question.label}
                                              id={question.id}
                                            />
                                          );
                                        }
                                      )}
                                    </Grid>
                                  </Segment>
                                ) : null}
                              </Fragment>
                            </Segment.Group>
                            {surveyValidation && (
                              <div className="info-box">
                                <p>
                                  <Icon name="info circle" /> This evaluation is
                                  not currently collecting responses as the
                                  activity has not started. Responses may be
                                  submitted once the activity is underway
                                </p>
                              </div>
                            )}
                            {preview ? null : (
                              <Button
                                disabled={
                                  this.props.surveyPostLoading ||
                                  surveyValidation
                                }
                                loading={this.props.surveyPostLoading}
                                type="submit"
                                className="Primary-Button mt-24"
                              >
                                Submit
                              </Button>
                            )}
                          </div>
                        </AvForm>
                      </Segment>
                    }
                    className="survey-for-test-type"
                  />
                ) : (
                  <Segment
                    className="notFoundSegment"
                    textAlign="center"
                    loading={this.props.surveyGetLoading}
                  >
                    {/* <div style={{color:'red',fontWeight:'bold',fontSize:'1.125rem'}}>Oops! Looks like the link you clicked is broken or expired</div> */}
                    <Grid>
                      <Grid.Row>
                        <EmptyContainer
                          msg={
                            errorMessage
                              ? errorMessage
                              : 'Oops! the requested survey link is broken'
                          }
                        />
                      </Grid.Row>
                    </Grid>
                  </Segment>
                )}
              </div>
            </>
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { survey } = state;
  return {
    surveyData: survey.surveyData,
    surveyGetLoading: survey.surveyGetLoading,
    surveyPostLoading: survey.surveyPostLoading,
    errorMessage: survey.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSurvey,
      postSurvey,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Surveys);
