import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';

export default class MessageComponent extends Component {
  render() {
    const { msg, color } = this.props;
    return (
      <Message color={color}>
        <p>{msg}</p>
      </Message>
    );
  }
}
