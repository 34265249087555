/* eslint-disable eqeqeq */
import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import {
  AvField,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from 'availity-reactstrap-validation';
import { CustomTooltip } from './popup';
import { map, uniq, isEmpty, get } from '../utils/lodash';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import { isMobile } from 'react-device-detect';

const marks = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
};
const trackStyle = {
  backgroundColor: '#dad3d3c9',
  height: '0.938rem',
  marginTop: '-1rem',
};
const handleStyle = {
  height: '3.125rem',
  width: '3.125rem',
  marginTop: '-2.188rem',
  marginLeft:
    process.env.REACT_APP_ENV_VALUE !== 'production' ? '-1.563rem' : '',
  backgroundColor: '#ef8123',
  border: 'none',
};
const activeDotStyle = {
  borderColor: '#dad3d3c9',
  backgroundColor: '#dad3d3c9',
  height: '2rem',
  width: '2rem',
  border: 'none',
  marginLeft: '-0.875rem',
};

class SurveyReview extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      methods: [],
      reasonId: null,
    };
  }

  componentDidMount() {
    const { data } = this.props;
    if (get(data, 'methodId', null) === 9) {
      const assessmentTypes = get(data, 'assessmentTypes', []);
      this.setState({
        methods: isEmpty(assessmentTypes)
          ? []
          : map(assessmentTypes, (data) => {
              return data.id;
            }),
        reasonId: get(data, 'methodReason', null),
      });
    }
  }

  componentDidUpdate(prevProps) {
    try {
      const { data } = this.props;
      if (get(data, 'methodId', null) === 9) {
        const assessmentTypes = get(data, 'assessmentTypes', []);
        const methodReason = get(data, 'methodReason', null);
        if (
          !isEmpty(assessmentTypes) &&
          get(prevProps, 'data.assessmentTypes', []) !== assessmentTypes
        ) {
          this.setState({
            methods: isEmpty(assessmentTypes)
              ? []
              : map(assessmentTypes, (data) => {
                  return data.id;
                }),
          });
        }

        if (
          methodReason &&
          get(prevProps, 'data.methodReason', null) !== methodReason
        ) {
          this.setState({
            reasonId: methodReason,
          });
        }
      }
    } catch (error) {
      console.error('SurveyReview :: componentDidUpdate -> error', error);
    }
  }

  onRatingChange(rating, name) {
    let { ratingData, updateResults } = this.props;
    ratingData[name] = rating;
    updateResults(ratingData);
  }

  handleReasonChange(id) {
    this.setState({
      reasonId: id,
    });
  }

  handleMethodChange(check, value, id) {
    try {
      const { ratingData, updateResults } = this.props;
      const methods = [...this.state.methods];
      let index = methods.indexOf(id);

      if (check === true) {
        methods.push(id);
        this.setState({
          methods: uniq(methods),
        });
      } else {
        if (methods.includes(id)) {
          if (index > -1) {
            methods.splice(index, 1);
          }
          this.setState({
            methods: uniq(methods),
          });
        }
      }
      ratingData.assessmentTypes = methods;
      updateResults(ratingData);
    } catch (error) {
      console.error('SurveyReview :: handleMethodChange -> error', error);
    }
  }

  render() {
    let {
      index,
      question,
      ratingData,
      objectiveId,
      evalView,
      cumulative,
      method,
      data,
      pageDisabled,
    } = this.props;

    const { reasonId, methods } = this.state;

    return (
      <div>
        {evalView ? null : (
          <div>
            {question.methodHeading && !index ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: question.methodHeading,
                }}
              />
            ) : null}
            {question.heading ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: question.heading,
                }}
              />
            ) : null}
          </div>
        )}

        {evalView ? (
          <div>
            <Grid.Row>
              <label className="eval-method-title">
                <strong>Self-assessed evaluation method</strong>
              </label>
              {question.methodHeading ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: question.methodHeading,
                  }}
                  className="survey-gap"
                />
              ) : null}
              <div className="eval-method-container">
                <label className="eval-method-header">
                  Reason for this evaluation method
                </label>
                <Segment
                  disabled={
                    this.props.currentActivity.statusId == 3 || pageDisabled
                      ? true
                      : false
                  }
                >
                  <AvRadioGroup
                    name="methodReason"
                    className="eval-checkBox"
                    value={reasonId}
                    validate={{
                      required: {
                        value: true,
                        errorMessage:
                          'Reason for evaluation method is required',
                      },
                    }}
                  >
                    <Grid>
                      {map(evalView.evalReasons, (reason, i) => (
                        <Grid.Column
                          key={i}
                          mobile={16}
                          tablet={8}
                          computer={5}
                        >
                          <div className="eval-method-radio">
                            <AvRadio
                              name="reason"
                              type="radio"
                              label={reason.name}
                              value={reason.id}
                              onChange={() =>
                                this.handleReasonChange(reason.id)
                              }
                            />
                          </div>
                        </Grid.Column>
                      ))}
                    </Grid>
                  </AvRadioGroup>
                </Segment>
              </div>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                style={{ marginTop: '0.938rem' }}
              >
                {question.heading ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: question.heading,
                    }}
                    className="survey-gap"
                  />
                ) : null}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <div className="eval-method-container">
                <label className="eval-method-header">
                  What is your assessment based on?
                </label>
                <Segment
                  disabled={
                    this.props.currentActivity.statusId == 3 || pageDisabled
                      ? true
                      : false
                  }
                >
                  <AvCheckboxGroup
                    className="eval-checkBox"
                    name="assessmentTypes"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Assessment basis is required',
                      },
                    }}
                  >
                    <Grid>
                      {map(evalView.evalAssesmentTypes, (types, i) => (
                        <Grid.Column
                          key={i}
                          mobile={16}
                          tablet={8}
                          computer={16}
                          className="grid-column-assessment"
                        >
                          <div className="eval-method-checkbox">
                            <AvCheckbox
                              name="evalAssessmentTypes"
                              label={types.name}
                              onChange={(e) =>
                                this.handleMethodChange(
                                  e.target.checked,
                                  e.target.value,
                                  types.id
                                )
                              }
                              value={types.id}
                              checked={
                                methods.includes(types.id) ? true : false
                              }
                            />
                          </div>
                        </Grid.Column>
                      ))}
                    </Grid>
                  </AvCheckboxGroup>
                </Segment>
              </div>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <div className="disabled-padding">
                  <Segment
                    disabled={
                      this.props.currentActivity.statusId == 3 || pageDisabled
                        ? true
                        : false
                    }
                  >
                    <div className="number-participants-div">
                      <CustomTooltip
                        className="number-participants-bottom"
                        position="bottom left"
                      >
                        <AvField
                          label="Number Of Participants Considered"
                          className={`number-participants-input ${
                            (get(data, 'collectionMethod') == 'LINK' ||
                              get(data, 'collectionMethod') == 'CSV') &&
                            get(data, 'method.id', 0) !== 9
                              ? 'participants-disabled'
                              : ''
                          }`}
                          name="actualParticipantsEvaluated"
                          disabled={
                            (get(data, 'collectionMethod') == 'LINK' ||
                              get(data, 'collectionMethod') == 'CSV') &&
                            get(data, 'method.id', 0) !== 9
                              ? true
                              : false
                          }
                          type="number"
                          min="1"
                          validate={{
                            required: {
                              value: true,
                              errorMessage:
                                'Number Of Participants Considered is required',
                            },
                          }}
                        />
                        {/* <label className="sample-size-text">
                          Recommended sample size is 102 participants
                        </label> */}
                      </CustomTooltip>
                      {/*  */}
                    </div>
                  </Segment>
                </div>
              </Grid.Column>
            </Grid.Row>
          </div>
        ) : null}

        <div className="my-2">
          <Segment
            disabled={
              this.props.currentActivity.statusId == 3 || pageDisabled
                ? true
                : false
            }
          >
            {Boolean(cumulative) ? (
              <div>
                <div className="survey-gap">
                  <div className="my-3"></div>
                  {question.question1 ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: question.question1,
                      }}
                      className="mb-24"
                    />
                  ) : get(question, 'question1') ? (
                    <div>
                      {question.heading ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: question.heading,
                          }}
                          className="survey-gap"
                        />
                      ) : null}

                      <div
                        dangerouslySetInnerHTML={{
                          __html: question.question1,
                        }}
                        className="survey-gap"
                      />
                    </div>
                  ) : null}

                  <label>Select your response.</label>
                  <div className="slider-margin">
                    <Slider
                      min={0.5}
                      defaultValue={ratingData.result1}
                      onAfterChange={(rating) =>
                        this.onRatingChange(rating, 'result1')
                      }
                      marks={marks}
                      max={10.5}
                      step={null}
                      trackStyle={isMobile ? '' : trackStyle}
                      handleStyle={isMobile ? '' : handleStyle}
                      activeDotStyle={isMobile ? '' : activeDotStyle}
                      dotStyle={isMobile ? '' : activeDotStyle}
                      railStyle={isMobile ? '' : trackStyle}
                    />
                  </div>

                  {!isMobile ? (
                    <div className="slider-info-text">
                      <label>Not at all</label>
                      <label>Most imaginable</label>
                    </div>
                  ) : (
                    <>
                      {' '}
                      <br />
                      <div className="slider-info-muted">
                        <label>Not at all</label>
                        <label>Most imaginable</label>
                      </div>
                    </>
                  )}
                </div>
                {/* On a scale of 1 to 10 from not at all to most imaginable. */}

                <br />
                <hr />
                <div className="survey-gap">
                  {question.question2 ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: question.question2,
                      }}
                      className="mb-24"
                    />
                  ) : get(question, 'question2') ? (
                    <div>
                      {question.heading ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: question.heading,
                          }}
                          className="survey-gap"
                        />
                      ) : null}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: question.question2,
                        }}
                        className="survey-gap"
                      />
                    </div>
                  ) : null}
                  <label>
                    Select your <strong>before</strong> response.
                  </label>
                  <>
                    <div className="slider-margin">
                      <Slider
                        min={0.5}
                        defaultValue={ratingData.result2}
                        onAfterChange={(rating) =>
                          this.onRatingChange(rating, 'result2')
                        }
                        marks={marks}
                        max={10.5}
                        step={null}
                        trackStyle={isMobile ? '' : trackStyle}
                        handleStyle={isMobile ? '' : handleStyle}
                        activeDotStyle={isMobile ? '' : activeDotStyle}
                        dotStyle={isMobile ? '' : activeDotStyle}
                        railStyle={isMobile ? '' : trackStyle}
                      />
                    </div>

                    {!isMobile ? (
                      <div className="slider-info-text">
                        <label>Not at all</label>
                        <label>Most imaginable</label>
                      </div>
                    ) : (
                      <>
                        {' '}
                        <br />
                        <div className="slider-info-muted">
                          <label>Not at all</label>
                          <label>Most imaginable</label>
                        </div>
                      </>
                    )}
                  </>
                  {/* On a scale of 1 to 10 from not at all to most imaginable. */}
                  <br />
                </div>
              </div>
            ) : (
              <div className="survey-gap">
                <br />
                <div className="my-3"></div>
                {question.question1 ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: question.question1,
                    }}
                    className="mb-24"
                  />
                ) : get(question, 'question1') ? (
                  <div>
                    {question.heading ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: question.heading,
                        }}
                        className="survey-gap"
                      />
                    ) : null}

                    <div
                      dangerouslySetInnerHTML={{
                        __html: question.question1,
                      }}
                      className="survey-gap"
                    />
                  </div>
                ) : null}
                <label>Select your response.</label>
                <>
                  <div className="slider-margin">
                    <Slider
                      min={0.5}
                      defaultValue={ratingData.result1}
                      onAfterChange={(rating) =>
                        this.onRatingChange(rating, 'result1')
                      }
                      marks={marks}
                      max={10.5}
                      step={null}
                      trackStyle={isMobile ? '' : trackStyle}
                      handleStyle={isMobile ? '' : handleStyle}
                      activeDotStyle={isMobile ? '' : activeDotStyle}
                      dotStyle={isMobile ? '' : activeDotStyle}
                      railStyle={isMobile ? '' : trackStyle}
                    />
                  </div>

                  {!isMobile ? (
                    <div className="slider-info-text">
                      <label>Not at all</label>
                      <label>Most imaginable</label>
                    </div>
                  ) : (
                    <>
                      {' '}
                      <br />
                      <div className="slider-info-muted">
                        <label>Not at all</label>
                        <label>Most imaginable</label>
                      </div>
                    </>
                  )}
                </>
                {/* On a scale of 1 to 10 from not at all to most imaginable. */}
                <br />
              </div>
            )}
          </Segment>
        </div>
        <br />
        {get(method, 'method', null) === 'Intercept' ? null : (
          <Grid className="custom-gen-questions">
            <Grid.Row>
              <Grid.Column computer={8} mobile={16}>
                <AvField
                  name={`${objectiveId}_Reason`}
                  className="custom-gen-questions-textarea-style"
                  type="textarea"
                  label={
                    Boolean(cumulative)
                      ? `Can you tell us more about why you gave these two ratings?`
                      : 'Can you tell us more about why you gave this rating?'
                  }
                  disabled={
                    this.props.currentActivity.statusId == 3 ? true : false
                  }
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Field is required',
                    },
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    user: state.authentication.user,
    helpTips: state.extra.activityHelpTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

SurveyReview = connect(mapStateToProps, mapDispatchToProps)(SurveyReview);

export { SurveyReview };
