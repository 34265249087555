/* eslint-disable eqeqeq */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { map, cloneDeep } from '../../../utils/lodash';
import { Grid, Segment, Checkbox } from 'semantic-ui-react';
import { putActivity } from '../../../store/actions';
import './activity.scss';

class ActivityChecklist extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async handleChange(checked, id) {
    try {
      const { documentation, putActivity } = this.props;
      const { activityPlanCheckList, activityReportCheckList } =
        this.props.currentActivity;
      const type = documentation
        ? 'activityReportCheckList'
        : 'activityPlanCheckList';

      const form = map(
        documentation
          ? cloneDeep(activityReportCheckList)
          : cloneDeep(activityPlanCheckList),
        (item) => {
          if (item.id !== id) return item;
          item.answer = checked ? 'YES' : 'NO';
          return item;
        }
      );
      putActivity(type, { [type]: form });
    } catch (error) {
      // console.error('ActivityObjective -> handleChange -> error', error);
    }
  }

  renderCheckList() {
    const { documentation } = this.props;
    const { activityPlanCheckList, activityReportCheckList } =
      this.props.currentActivity;

    return map(
      documentation ? activityReportCheckList : activityPlanCheckList,
      (item, i) => {
        return (
          <Checkbox
            className="check-box"
            label={item.question}
            key={i}
            value={item.id}
            onChange={(e, { checked }) => this.handleChange(checked, item.id)}
            checked={item.answer === 'YES'}
          />
        );
      }
    );
  }

  render() {
    const { actPut, documentation, pageDisabled } = this.props;

    return (
      <div className="activity-form">
        <Segment
          loading={
            documentation
              ? actPut.activityReportCheckList
              : actPut.activityPlanCheckList
          }
          className="content-segment-checklist"
          disabled={pageDisabled}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <div className="objective-select-check">
                  {this.renderCheckList()}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    user: state.authentication.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      putActivity,
    },
    dispatch
  );
};

ActivityChecklist = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityChecklist);

export { ActivityChecklist };
