import React from 'react';
import { Button, Icon, Message, Modal, Radio } from 'semantic-ui-react';
import './style.scss';

const ArchivedActivityInfoModal = ({
  modal,
  setModal,
  count = 0,
  handleAssignAllActivities,
}) => {
  // TODO: if needed for transferring invited one uncomment below code
  // const [checkArchived, setCheckedArchived] = React.useState(true);
  // const [
  //   checkInvitedActiveDevelopment,
  //   setCheckedInvitedActiveDevelopment,
  // ] = React.useState(false);
  const [confirmation, setConfirmation] = React.useState(true);

  const completedOwned = count.completedOwned;
  // TODO: if needed for transferring invited one uncomment below code
  // const invitedActiveOrInDevelopment =
  //   count.inActiveInvited + count.inDevelopmentInvited;

  const handleConfirmation = (value) => {
    if (value !== undefined) {
      setConfirmation(value);
    }
  };

  const onClick = () => {
    handleAssignAllActivities(
      completedOwned > 0 ? confirmation : false,
      // TODO: if needed for transferring invited one uncomment below code
      // invitedActiveOrInDevelopment > 0 ? checkInvitedActiveDevelopment : false
      false
    );
    setModal(false);
  };

  return (
    <Modal
      size="tiny"
      open={modal}
      closeIcon={
        <Icon
          onClick={() => setModal(false)}
          name="close"
          className="closeicon"
        />
      }
    >
      <Modal.Header>Reassign Activities</Modal.Header>
      <Modal.Content>
        <label>
          User has {completedOwned} archived activities. Reassign archived
          activities to selected user?
        </label>

        {/* TODO: for checking both completed and invited activities use below scenario  */}
        {/* <label className="mb-3">
          All activities need to be transferred before users can be removed from
          the account.
        </label>

        <div className="row pt-1">
          {completedOwned > 0 && (
            <>
              <Checkbox
                className="check-box"
                label="Transfer Archived Activities"
                checked={checkArchived}
                onChange={() => {
                  setCheckedArchived(!checkArchived);
                }}
              />
            </>
          )}
        </div>
        <div className="row pt-1">
          {invitedActiveOrInDevelopment > 0 && (
            <Checkbox
              className="check-box"
              label="Transfer Invited Activities"
              checked={checkInvitedActiveDevelopment}
              onChange={() => {
                setCheckedInvitedActiveDevelopment(
                  !checkInvitedActiveDevelopment
                );
              }}
            />
          )}
        </div>
        <hr />
        <div>
          <h6>Summary</h6>

          {completedOwned > 0 &&
            (checkArchived ? (
              <p>Transfer All Archived Activities belongs to user</p>
            ) : (
              <p>Not Transfer Archived Activities belongs to user</p>
            ))}
          {invitedActiveOrInDevelopment > 0 &&
            (checkInvitedActiveDevelopment ? (
              <p>Transfer All Development/ Active Activities </p>
            ) : (
              <p>
                <p>Not Transfer All Development/ Active Activities </p>
              </p>
            ))}
        </div> */}
        <div className="row pt-2">
          <div className="col-2 p-0">
            <Radio
              label="Yes"
              className="modal-toggle"
              checked={confirmation}
              onChange={() => handleConfirmation(true)}
            />
          </div>
          <div className="col-2 p-0">
            {' '}
            <Radio
              label="No"
              className="modal-toggle"
              checked={!confirmation}
              onChange={() => handleConfirmation(false)}
            />
          </div>
        </div>
        <div className="msg-report">
          <Message
            color="blue"
            icon="exclamation circle"
            content="All activities need to be transferred before users can be removed from the account."
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setModal(false)} className="modal-cancel">
          Cancel
        </Button>
        <Button
          onClick={() => onClick()}
          className="modal-proceed"
          content="Save"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ArchivedActivityInfoModal;
