import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Segment, Modal, Icon, Search, Button } from 'semantic-ui-react';
import { isEmpty, debounce, get, size } from '../../../utils/lodash';

import { EmptyContainer, CustomTable } from '../../../components';
import { getOrganizationGuestUsersList } from '../../../store/actions';

import './organization.scss';
import { getActivityStatus, getActivityType } from '../../../utils';

class GuestView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      columns: [
        {
          title: 'Activity Name',
          width: 6,
          render: (data) => {
            return (
              <div className="activity-description">
                <div
                  className={`square activity-${getActivityStatus(
                    get(data, 'project.statusId', 1)
                  )}`}
                />
                <label>{get(data, 'project.name', '')}</label>
              </div>
            );
          },
        },
        {
          title: 'Activity Type',
          width: 6,
          render: (data) => {
            const actType = getActivityType(data.project);
            return (
              <div className="activity-description">
                <label>{actType.type}</label>
              </div>
            );
          },
        },
        {
          title: 'Owner',
          width: 4,
          render: (data) => {
            return (
              <div className="activity-description">
                <label>{get(data, 'project.owner.name', '')}</label>
              </div>
            );
          },
        },
      ],
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal, columns } = this.state;
    const { user } = this.props;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="small"
        trigger={
          <Button className="Primary-Button">
            {isEmpty(user.invitedProjects) ? 0 : size(user.invitedProjects)}{' '}
            Activities
          </Button>
        }
        className="view-activities-guest-user"
      >
        <Modal.Header>View Activities of Guest Users: {user.name}</Modal.Header>
        <Modal.Content>
          <CustomTable
            customClass="evidence-table-grant-table"
            header
            columns={columns}
            data={user.invitedProjects}
            keyExtractor={(item, index) => item.id}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

class OrganizationGuestManagement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      userName: '',
      activityName: '',
      columns: [
        {
          title: 'User Name',
          width: 4,
          render: (data) => {
            return <label>{data.name}</label>;
          },
        },
        {
          title: 'Email',
          width: 4,
          render: (data) => {
            return <label>{data.email}</label>;
          },
        },
        {
          title: 'Phone',
          width: 4,
          render: (data) => {
            return data.phone ? <label>{data.phone}</label> : null;
          },
        },
        {
          title: '',
          width: 4,
          render: (data) => {
            return isEmpty(data.invitedProjects) ? null : (
              <div className="org-users-actions">
                <GuestView
                  user={data}
                  {...this.props}
                  resetPage={() => this.resetPage()}
                />
              </div>
            );
          },
        },
      ],
    };
  }

  resetPage() {
    this.setState({ currentPage: 1 }, async () => {
      const { currentPage } = this.state;
      const { getOrganizationGuestUsersList, auth } = this.props;
      if (!isEmpty(auth.user) && auth.user.organisation) {
        await Promise.all([getOrganizationGuestUsersList(currentPage)]);
      }
    });
  }

  componentDidMount() {
    this.resetPage();
  }

  pageChange(page) {
    const { auth, getOrganizationGuestUsersList } = this.props;
    this.setState(
      {
        currentPage: page,
      },
      () => {
        if (!isEmpty(auth.user) && auth.user.organisation) {
          getOrganizationGuestUsersList(page);
        }
      }
    );
  }

  handleSearchUserChange = (e, { value }) => {
    this.setState({ userName: value }, () => {
      const { getOrganizationGuestUsersList, auth } = this.props;
      const { currentPage, activityName } = this.state;
      getOrganizationGuestUsersList(currentPage, activityName, value);
    });
  };

  handleSearchActivityChange = (e, { value }) => {
    this.setState({ activityName: value }, () => {
      const { getOrganizationGuestUsersList, auth } = this.props;
      const { currentPage, userName } = this.state;
      getOrganizationGuestUsersList(currentPage, value, userName);
    });
  };

  render() {
    const { columns, currentPage, userName, activityName } = this.state;
    const {
      loading,
      orgGuestUsersLoading,
      orgGuestUsersList = {},
    } = this.props.organization;
    return (
      <Segment
        className="content-segment org-users"
        loading={loading || orgGuestUsersLoading}
      >
        <Grid>
          <Grid.Row className="org-users-search-row">
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="guest-search">
                <div className="guest-search-container">
                  <Search
                    placeholder="Search by Activity"
                    className="activitySearch"
                    onSearchChange={debounce(
                      this.handleSearchActivityChange,
                      1000,
                      {
                        leading: true,
                      }
                    )}
                    value={activityName}
                  />
                </div>
                <div className="guest-search-container">
                  <Search
                    placeholder="Search by User"
                    className="activitySearch"
                    onSearchChange={debounce(
                      this.handleSearchUserChange,
                      1000,
                      {
                        leading: true,
                      }
                    )}
                    value={userName}
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {!isEmpty(orgGuestUsersList) &&
        orgGuestUsersList.docs &&
        orgGuestUsersList.docs.length ? (
          <div className="org-table-container">
            <CustomTable
              header
              columns={columns}
              data={orgGuestUsersList.docs}
              customClass="org-users-table"
              pagination
              handlePaginationChange={(page) => this.pageChange(page)}
              page={currentPage}
              noOfPages={orgGuestUsersList.pages}
              keyExtractor={(item, index) => item.id}
            />
          </div>
        ) : (
          <EmptyContainer msg="No guest users have been added…" />
        )}
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    organization: state.organization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrganizationGuestUsersList,
    },
    dispatch
  );
};

OrganizationGuestManagement = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationGuestManagement);

export { OrganizationGuestManagement };
