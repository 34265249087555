import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Segment, Loader } from 'semantic-ui-react';
import { CustomSegment } from '../../../../components';
import {
  getEvaluationPlans,
  getProjectOutcomeSummary,
  updateOutcomeChart,
} from '../../../../store/actions';
import {
  get,
  map,
  compact,
  isEmpty,
  groupBy,
  find,
} from '../../../../utils/lodash';
import {
  ContentAndHeaderEvaluation,
  getChartType,
  getCollectionMethod,
} from '../../../../utils';
import {
  TrendRateOfChangeChart,
  IndividualRateOfChangeChart,
  AggregateChart,
  SeparateChart,
} from '../../../../components/chartsWidgets';
import '../activity.scss';
import { MeasureEvaluation } from './measureEvaluation';
import {
  INTERCEPT_CHART_TYPES,
  ALL_CHART_TYPES,
  SELF_ASSESSED_CHART_TYPES,
} from '../../../../utils/constant';

export const SummaryCharts = (props) => {
  const getChart = () => {
    let {
      chartType,
      chartMethod,
      method,
      outComeChart,
      participants,
      participantsChange,
      participantsChangePrePost,
    } = props;

    const outcomeId = outComeChart.outcomeId;

    // common for all method & intercept
    let _participants =
      participants && participants.length > 0
        ? participants.find((item) => Number(item.id) === Number(outcomeId))
        : null;

    let _participantsChange = null;

    let _participantsChangePrePost = null;

    //  post pre chart not available for intercept
    if (method === 'all') {
      _participantsChange =
        participantsChange && participantsChange.length > 0
          ? participantsChange.find(
              (item) => Number(item.id) === Number(outcomeId)
            )
          : null;

      _participantsChangePrePost =
        participantsChangePrePost && participantsChangePrePost.length > 0
          ? participantsChangePrePost.find(
              (item) => Number(item.id) === Number(outcomeId)
            )
          : null;
    }

    if (Number(method) === 12) {
      chartType = 'postChart';

      // if intercept method data coming through byMethod key
      _participants = get(_participants, 'byMethod', []).find(
        (item) => Number(item.methodId) === 12
      );
    }

    switch (chartType) {
      case 'postChart':
        const aggregate = [
          'SINGLE',
          'INTERCEPT_SINGLE',
          'AGGREGATE',
          'INTERCEPT_AGGREGATE',
        ];
        const seperate = ['SEPERATE', 'INTERCEPT_SEPERATE'];
        const selfAssessedPost = ['SELF-ASSESSED-POST'];

        if (selfAssessedPost.includes(chartMethod)) {
          return (
            <>
              {/* SelfAssessedGraph */}

              {chartType === 'prePostChart' ? (
                <TrendRateOfChangeChart
                  {...props}
                  method={method}
                  participantsChangePrePost={_participantsChangePrePost}
                />
              ) : (
                <AggregateChart
                  {...props}
                  method={method}
                  participants={_participants}
                />
              )}
            </>
          );
        }

        if (aggregate.includes(chartMethod)) {
          return (
            <AggregateChart
              {...props}
              method={method}
              participants={_participants}
            />
          );
        }
        if (seperate.includes(chartMethod)) {
          return (
            <SeparateChart
              {...props}
              method={method}
              participants={_participants}
            />
          );
        }
        return null;
      case 'prePostChart':
        const trend = ['SINGLE_TREND', 'TREND'];
        const individual = ['SINGLE_INDIVIDUAL', 'INDIVIDUAL'];
        const selfAssessedPre = ['SELF-ASSESSED-PRE'];

        if (trend.includes(chartMethod)) {
          return (
            <TrendRateOfChangeChart
              {...props}
              method={method}
              participantsChangePrePost={_participantsChangePrePost}
            />
          );
        }
        if (individual.includes(chartMethod)) {
          return (
            <IndividualRateOfChangeChart
              {...props}
              method={method}
              participantsChange={_participantsChange}
            />
          );
        }
        if (selfAssessedPre.includes(chartMethod)) {
          return (
            <>
              {chartType === 'prePostChart' ? (
                <TrendRateOfChangeChart
                  {...props}
                  method={method}
                  participantsChangePrePost={_participantsChangePrePost}
                />
              ) : (
                <AggregateChart
                  {...props}
                  method={method}
                  participants={_participants}
                />
              )}
            </>
          );
        }
        return null;
      default:
        return null;
    }
  };

  return <div className="outcomes-summary-chart-div">{getChart()}</div>;
};

const OutcomeSummaryCharts = (props) => {
  const chartType = find(ALL_CHART_TYPES, (i) => i.value === props.chartMethod);

  const updateBase64Img = async (imageData) => {
    let { updateOutcomeChart, outComeChart, currentActivity, getAllImages } =
      props;

    await getAllImages({ getAllGraphImages: false, reportLoaded: true });
    // if (currentActivity.statusId < 3) {
    await updateOutcomeChart(
      currentActivity.id,
      {
        status: true,
        outcome: outComeChart.outcomeId,
        type: outComeChart.type,
      },
      true
    ); //passed true to not give any message on success
    // }
    await getAllImages({ getAllGraphImages: false, reportLoaded: false });
  };

  return (
    <Segment basic>
      <SummaryCharts
        chartTypeLabel={get(chartType, 'label', '-')}
        updateBase64Img={(image) => updateBase64Img(image)}
        {...props}
      />
    </Segment>
  );
};

const OutcomeSummary = (props) => {
  const { haveCharts } = props;

  if (isEmpty(haveCharts)) return null;
  return (
    <div className="chart-container">
      <Segment className="report-activity-seg outcome-summary-border" basic>
        <div className="report-activity-single-div">
          <div className="row">
            <div className="col-lg-6">
              <label className="outcome-summary-heading">Outcome Summary</label>
            </div>
          </div>
          {map(haveCharts, (outcome, title) => {
            return (
              <Fragment key={title}>
                <div className="row">
                  <div className="col-lg-6 objective-margin-bottom">
                    <label className="info-title">Objective</label>
                    <p className="info-desc">{title}</p>
                  </div>
                </div>
                <div className="graph-div">
                  {map(outcome, (chart, i) => (
                    <OutcomeSummaryCharts
                      key={i}
                      outComeChart={chart}
                      chartMethod={chart.type}
                      chartType={getChartType({
                        title,
                        ...props,
                      })}
                      type={title}
                      outcome={title}
                      method={
                        INTERCEPT_CHART_TYPES.includes(chart.type)
                          ? '12'
                          : 'all'
                      }
                      {...props}
                    />
                  ))}
                </div>
              </Fragment>
            );
          })}
        </div>
      </Segment>
    </div>
  );
};

const EvaluationPlan = (props) => {
  const evalPlan = get(props, 'data', {});
  const selfAssessed = get(evalPlan, 'method.response', '') === 'Self-assessed';
  const evaluationQuestions = get(props, 'data.evaluationQuestions', []);

  return (
    <Fragment>
      <div className="report-eval-plan-top-border">
        <Grid>
          <Grid.Row>
            <label className="evaluation-heading">
              {get(props, 'data.name', '-')}
            </label>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column tablet={5} computer={5} mobile={5}>
              <ContentAndHeaderEvaluation title="Objective">
                {get(evalPlan, 'evaluationQuestions', []).length > 1
                  ? map(
                      get(evalPlan, 'evaluationQuestions', []),
                      (evaluation, i) => (
                        <span className="objective-list-actual-outcome" key={i}>
                          {get(evaluation, 'outcome.title', '-')}
                        </span>
                      )
                    )
                  : map(
                      get(evalPlan, 'evaluationQuestions', []),
                      (evaluation, i) => (
                        <span
                          className="objective-non-list-actual-outcome"
                          key={i}
                        >
                          {get(evaluation, 'outcome.title', '-')}
                        </span>
                      )
                    )}
              </ContentAndHeaderEvaluation>
            </Grid.Column>
            <Grid.Column tablet={5} computer={5} mobile={5}>
              <ContentAndHeaderEvaluation title="Evaluation Groups">
                {get(evalPlan, 'participantGroups', []).length > 1
                  ? map(
                      get(evalPlan, 'participantGroups', []),
                      (evaluation, i) => (
                        <span className="objective-list-actual-outcome" key={i}>
                          {get(evaluation, 'targetGroup.name', '-')}
                        </span>
                      )
                    )
                  : map(
                      get(evalPlan, 'participantGroups', []),
                      (evaluation, i) => (
                        <span
                          className="objective-non-list-actual-outcome"
                          key={i}
                        >
                          {get(evaluation, 'targetGroup.name', '-')}
                        </span>
                      )
                    )}
              </ContentAndHeaderEvaluation>
            </Grid.Column>
            <Grid.Column tablet={5} computer={5} mobile={5}>
              <ContentAndHeaderEvaluation title="Evaluation Information">
                {selfAssessed ? (
                  <span className="objective-non-list-actual-outcome">
                    <span>{`${get(evalPlan, 'method.response', '-')}`}</span>
                  </span>
                ) : (
                  <span className="objective-non-list-actual-outcome">
                    <span>{`${get(evalPlan, 'method.method', '-')} - ${get(
                      evalPlan,
                      'method.response',
                      '-'
                    )} - ${getCollectionMethod(
                      evalPlan.collectionMethod
                    )}`}</span>
                  </span>
                )}
              </ContentAndHeaderEvaluation>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {/* <br /> */}
        {map(evaluationQuestions, (evaluation, i) =>
          evaluation.inReport ? (
            <MeasureEvaluation
              key={i}
              evaluation={evaluation}
              selfAssessed={selfAssessed}
            />
          ) : null
        )}
      </div>
    </Fragment>
  );
};

const EvaluationPlans = ({ evalPlans }) => {
  if (isEmpty(evalPlans)) return null;
  return (
    <div className="chart-container">
      {map(
        evalPlans,
        (plan, i) =>
          !get(plan, 'isDisabled', false) && (
            <EvaluationPlan key={i} data={plan} />
          )
      )}
    </div>
  );
};

class ActualOutcomes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      haveCharts: {},
      evalPlans: [],
    };
  }

  async componentDidMount() {
    const {
      getEvaluationPlans,
      getProjectOutcomeSummary,
      currentActivity,
      getAllImages,
    } = this.props;
    await getAllImages({ getAllGraphImages: true, reportLoaded: true });
    await getEvaluationPlans(currentActivity.id);
    getProjectOutcomeSummary(currentActivity.id);
    await this.setData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentActivity !== this.props.currentActivity) {
      this.setData();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !isEmpty(nextProps.participants) ||
      !isEmpty(nextProps.participantsChange) ||
      !isEmpty(nextProps.participantsChangePrePost)
    ) {
      return true;
    }
    return false;
  }

  async setData() {
    const haveCharts = groupBy(
      get(this.props, 'currentActivity.reportCharts.outcomeCharts', []),
      (chart) => chart.outcome.title
    );

    // change the order of chart type
    for (const outcomeTitle in haveCharts) {
      haveCharts[outcomeTitle].sort((a, b) => {
        const priorityMap = {
          AGGREGATE: 1,
          TREND: 1,
          SEPERATE: 2,
          INDIVIDUAL: 2,
          INTERCEPT_AGGREGATE: 3,
          INTERCEPT_SEPERATE: 3,
        };
        return priorityMap[a.type] - priorityMap[b.type];
      });
    }

    const evalPlans = compact(
      map(get(this.props, 'currentActivity.evaluationPlans', []), (plan) => {
        const evaluationQuestions = compact(
          map(plan.evaluationQuestions, (evaluation) => {
            if (plan.isDisabled && !evaluation.haveResponses) {
              return;
            }

            return evaluation;
          })
        );

        if (!isEmpty(evaluationQuestions)) {
          return {
            ...plan,
            evaluationQuestions,
          };
        }
      })
    );

    if (isEmpty(haveCharts)) {
      await this.props.getAllImages({
        getAllGraphImages: false,
        reportLoaded: false,
      });
    } else {
      await this.props.getAllImages({
        getAllGraphImages: false,
        reportLoaded: true,
      });
    }

    this.setState({
      haveCharts,
      evalPlans,
    });
  }

  render() {
    const { currentActivity, evalDetail, projectLoading } = this.props;
    const { haveCharts, evalPlans } = this.state;
    if (isEmpty(currentActivity)) return null;
    return currentActivity.evaluationPlans &&
      currentActivity.evaluationPlans.length ? (
      <CustomSegment
        title="Actual Outcomes"
        className="actual-outcomes"
        children={
          <Segment className="actual-outcomes-seg">
            <div className="outcomes-container">
              <Fragment>
                {isEmpty(haveCharts) ? null : (
                  <OutcomeSummary
                    //evalPlan true because to show evaluation plan count in report header
                    evalPlan={true}
                    haveCharts={haveCharts}
                    {...this.props}
                    singleReport={true}
                  />
                )}
                {isEmpty(evalPlans) ? null : (
                  <EvaluationPlans
                    evalDetail={evalDetail}
                    evalPlans={evalPlans}
                    {...this.props}
                  />
                )}
              </Fragment>
            </div>
          </Segment>
        }
        rightHeader={<Loader active={projectLoading} inline />}
        extraMargin
        toggle={false}
      />
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    participantsChange: state.report.participantsChange,
    participantsChangePrePost: state.report.participantsChangePrePost,
    participants: state.report.participants,
    projectLoading: state.report.projectLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProjectOutcomeSummary,
      updateOutcomeChart,
      getEvaluationPlans,
    },
    dispatch
  );
};

ActualOutcomes = connect(mapStateToProps, mapDispatchToProps)(ActualOutcomes);

export { ActualOutcomes };
