import React, { useState, useEffect } from 'react';
import { Grid, Loader, Button, Segment } from 'semantic-ui-react';
import { ChartComponent, makeChartObject } from '../activitySummary';
import { useDispatch, useSelector } from 'react-redux';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';

import { isEmpty, find, get, compact, sum } from '../../../../utils/lodash';
import '../report.scss';
import { CustomSegment, EmptyContainer } from '../../../../components';
import { PROGRAM_REPORT_LOADING } from '../../../../utils/constant';
import { setParentChartIncludeIn } from '../../../../store/actions';
import { getPageDisable } from '../../../../utils';
import { isFiltersInitState } from '../../../../utils/activity/isFiltersInitState';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const ProgrammeOutcomeAddress = (props) => {
  const [participantsResponses, setParticipantsResponses] = useState({
    mainTitle: [],
    dataset: [],
    development: [],
    active: [],
    completed: [],
    styles: {
      color: [],
      weight: [],
      highlight: [],
      barColor: [],
      size: [],
    },
  });

  const dispatch = useDispatch();

  const currentActivity = props.currentActivity;
  const user = useSelector((state) => state.authentication.user);
  const reportFilterOptions = useSelector(
    (state) => state.report.reportFilterOptions
  );

  const _isFiltersInitState = isFiltersInitState(reportFilterOptions, [
    'outcome',
    'startDate',
    'endDate',
    'method',
  ]);

  const isDisabledInReportGrpBtn =
    getPageDisable(currentActivity, user, 'project.edit.documentation') ||
    !_isFiltersInitState;

  const outcomeAddressedCharts = get(
    currentActivity,
    'reportCharts.outcomeAddressedCharts',
    null
  );

  const [inReport, setInReport] = useState(
    outcomeAddressedCharts && outcomeAddressedCharts.length > 0
  );

  const reportFilter = useSelector((state) => state.report.reportFilterOptions);

  const outcomeSummaryChart = useSelector(
    (state) => state.report.outcomeSummaryChart
  );
  const organization = useSelector((state) => state.organization.organization);

  const industrySectorTypes = useSelector(
    (state) => state.extra.industrySectorTypes
  );

  const reportInitialState = useSelector((state) => state.report);
  const outcomeReportLoader = get(
    reportInitialState,
    `outcomeSummaryReportLoading.${PROGRAM_REPORT_LOADING.OUTCOME_ADDRESS_LOADING}`,
    false
  );
  const getHeight = (data) => {
    return `${5 * data.length}rem`;
  };

  useEffect(() => {
    if (!isEmpty(outcomeSummaryChart)) {
      setParticipantsResponses({
        mainTitle: [],
        dataset: [],
        development: [],
        active: [],
        completed: [],
        styles: {
          color: [],
          weight: [],
          highlight: [],
          barColor: [],
          size: [],
        },
      });

      makeChartObject(
        outcomeSummaryChart,
        setParticipantsResponses,
        reportFilter
      );
    }
  }, [outcomeSummaryChart, inReport, reportFilter]);

  const getActivityTotal = (chart, title) =>
    (chart.find((item) => item.mainTitle === title) || {}).totalActivities;

  const cultural = getActivityTotal(outcomeSummaryChart, 'Cultural');
  const social = getActivityTotal(outcomeSummaryChart, 'Social');
  const economic = getActivityTotal(outcomeSummaryChart, 'Economic');
  const governance = getActivityTotal(outcomeSummaryChart, 'Governance');
  const environmental = getActivityTotal(outcomeSummaryChart, 'Environmental');

  const getOrganizationSectorType = () => {
    try {
      const type = find(industrySectorTypes, (o) => {
        return o.id === organization.industrySectorTypeId;
      });
      if (isEmpty(type)) {
        return '-';
      } else {
        return type.name;
      }
    } catch (error) {
      return '-';
    }
  };

  const onChangeInReport = () => {
    const _inReport = !inReport;
    setInReport(_inReport);
    dispatch(
      setParentChartIncludeIn(
        currentActivity.id,
        'OUTCOME_ADDRESSED_CHART',
        _inReport
      )
    );
  };

  const { parent = false, superAdmin = false, inParentReportPage } = props;

  return (
    <Segment
      className={inParentReportPage ? 'p-0' : ''}
      loading={outcomeReportLoader}
    >
      {/* <CustomSegment title="Outcome Overview"> */}
      {inParentReportPage ? null : (
        <div className="activity-count-info">
          <Grid>
            <Grid.Row className="programme-activity">
              <Grid.Column width={16}>
                <Grid.Column className="activity-summary-grid">
                  {parent || superAdmin ? null : (
                    <div className="org-down">
                      <Grid.Row>
                        <label>Organisation Type</label>
                      </Grid.Row>
                      <Grid.Row>
                        {outcomeReportLoader ? (
                          <Loader active size="medium" />
                        ) : (
                          <h6>{getOrganizationSectorType()}</h6>
                        )}
                      </Grid.Row>
                    </div>
                  )}

                  <div className="left-align">
                    <div className="activity-name">
                      <div className="report-circle circle-bottom reports-cultural" />
                      <div className="col-wise">
                        <div>
                          <label>Cultural</label>
                        </div>
                        <div className="number-centre">{cultural || 0}</div>
                      </div>
                    </div>

                    <div className="activity-name">
                      <div className="report-circle circle-bottom reports-economic" />
                      <div className="col-wise">
                        <div>
                          <label>Economic</label>
                        </div>
                        <div className="number-centre">{economic || 0}</div>
                      </div>
                    </div>

                    <div className="activity-name">
                      <div className="report-circle circle-bottom reports-social" />
                      <div className="col-wise">
                        <div>
                          <label>Social</label>
                        </div>
                        <div className="number-centre">{social || 0}</div>
                      </div>
                    </div>

                    <div className="activity-name">
                      <div className="report-circle circle-bottom reports-environmental" />
                      <div className="col-wise">
                        <div>
                          <label>Environment</label>
                        </div>
                        <div className="number-centre">
                          {environmental || 0}
                        </div>
                      </div>
                    </div>

                    <div className="activity-name">
                      <div className="report-circle circle-bottom reports-governance" />
                      <div className="col-wise">
                        <div>
                          <label>Governance</label>
                        </div>
                        <div className="number-centre">{governance || 0}</div>
                      </div>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      )}

      {/* </CustomSegment> */}

      <br />
      <CustomSegment
        title="Outcomes Addressed"
        rightHeader={
          !props.inParentReportPage && (
            <div className="checkbox-container-include-in-report">
              <div className="include-in-report-label">Include in report</div>
              <div className="button-include">
                <Button.Group
                  className="btn-include-in-report"
                  onClick={() =>
                    !isDisabledInReportGrpBtn && onChangeInReport()
                  }
                  disabled={isDisabledInReportGrpBtn}
                >
                  <Button active={inReport} disabled={isDisabledInReportGrpBtn}>
                    Yes
                  </Button>
                  <Button
                    active={!inReport}
                    disabled={isDisabledInReportGrpBtn}
                  >
                    No
                  </Button>
                </Button.Group>
              </div>
            </div>
          )
        }
      >
        <Segment className="chart-holder">
          {!isEmpty(get(participantsResponses, 'dataset', [])) ? (
            !inParentReportPage ||
            (inParentReportPage &&
              sum(compact(get(participantsResponses, 'dataset', []))) !== 0) ? (
              <div
                style={{
                  height: getHeight(get(participantsResponses, 'dataset', 0)),
                  width: 'auto',
                  background: 'white',
                }}
              >
                <ChartComponent
                  viewOnly={props.inParentReportPage}
                  toggle={inReport}
                  data={participantsResponses}
                  chartType={'activitiesOnly'}
                  status={reportFilter.statuses}
                  dynamicBarColor={true}
                  props={props}
                  reportType={props.reportType}
                />
              </div>
            ) : (
              <EmptyContainer msg="No Data Available" />
            )
          ) : (
            <EmptyContainer msg="No Data Available" />
          )}
        </Segment>
      </CustomSegment>
    </Segment>
  );
};
