import * as Yup from 'yup';
import { SUPPORT_DOCUMENT_TYPES } from '../utils/fileTypes';

const FILE_SIZE = 50 * 1024;

export const crossReferenceInitialValues = (value, isNew) => {
  return {
    documentTitle: isNew ? null : value.documentTitle,
    referenceFieldLabel: isNew ? null : value.referenceFieldLabel,
    file: '',
  };
};

export const crossReferenceValidationSchema = Yup.object().shape({
  documentTitle: Yup.string().required('Document Title is required!'),
  referenceFieldLabel: Yup.string().required(
    'Reference Field Label is required!'
  ),
  file: Yup.mixed()
    .test('fileSize', 'File too large', (value) => {
      if (!value) {
        return true;
      } else {
        const sizeComparison = Math.round(value.size / 1000) <= FILE_SIZE;
        return sizeComparison;
      }
    })
    .test('fileFormat', 'Unsupported File Format', (value) => {
      if (!value) {
        return true;
      } else return SUPPORT_DOCUMENT_TYPES.includes(value.type);
    }),
});
