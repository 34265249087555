import './activity.scss';

/* eslint-disable eqeqeq */
import React, { Fragment } from 'react';

import { AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import Alert from 'reactstrap/lib/Alert';
import { bindActionCreators } from 'redux';
import { Checkbox, Confirm, Grid, Segment } from 'semantic-ui-react';

import { CustomTooltip } from '../../../components';
import { filter, find, get, isEmpty, map } from '../../../utils/lodash';
import { putParentObjective } from '../../../store/actions';
import { getPageDisable } from '../../../utils';
import './activity.scss';
import ShowMoreText from 'react-show-more-text';

class ActivityParentObjective extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
    };
  }

  async handleChange(outcome, policy) {
    try {
      const { currentActivity, putParentObjective } = this.props;
      const isLinkedActivity = currentActivity.isLinkedActivity;

      await putParentObjective(currentActivity.id, {
        isLinkedActivity,
        outcome,
        policy,
      });
    } catch (error) {
      // console.error('ActivityObjective -> handleChange -> error', error);
    }
  }

  renderObjectiveCheckOne() {
    let { outcomes, currentActivity } = this.props;
    if (!currentActivity.goal) return null;
    const objectives = filter(outcomes, (o) => {
      return o.policyId === currentActivity.primaryPolicyId;
    });

    if (isEmpty(objectives)) return null;
    return map(objectives, (item, i) => {
      const parentOutcomes = find(currentActivity.parentOutcomes, (o) => {
        return (
          o.outcomeId === item.id &&
          o.policyValue === currentActivity.primaryPolicyId
        );
      });

      const isLinkedActivity = currentActivity.isLinkedActivity;

      const isEmptyParentOutcomes = isEmpty(parentOutcomes);

      if (
        (!isLinkedActivity && isEmptyParentOutcomes && !item.isEnable) ||
        // for  linked activities [mother program => child program linked activity]
        (isLinkedActivity && isEmptyParentOutcomes)
      )
        return null;

      const isChecked =
        (!isLinkedActivity && !isEmptyParentOutcomes) ||
        (isLinkedActivity &&
          !isEmptyParentOutcomes &&
          !isEmpty(parentOutcomes.linkedDateTime));

      return (
        <Fragment key={i}>
          <Grid.Column className="objective-select-spacing">
            <Checkbox
              className="check-box"
              label={item.title}
              key={i}
              value={item.id}
              onChange={() =>
                this.handleChange(item.id, currentActivity.primaryPolicyId)
              }
              checked={isChecked}
              disabled={isLinkedActivity}
            />
            <label className="check-box-description">{item.description}</label>
          </Grid.Column>
        </Fragment>
      );
    });
  }

  renderObjectiveCheckTwo() {
    let { outcomes, currentActivity } = this.props;
    if (!currentActivity.goal) return null;
    const objectives = filter(outcomes, (o) => {
      return o.policyId === currentActivity.secondaryPolicyId;
    });
    if (isEmpty(objectives)) return null;
    return map(objectives, (item, i) => {
      const parentOutcomes = find(currentActivity.parentOutcomes, (o) => {
        return (
          o.outcomeId === item.id &&
          o.policyValue === currentActivity.secondaryPolicyId
        );
      });

      const isLinkedActivity = currentActivity.isLinkedActivity;

      const isEmptyParentOutcomes = isEmpty(parentOutcomes);

      if (
        (!isLinkedActivity && isEmptyParentOutcomes && !item.isEnable) ||
        // for  linked activities [mother program => child program linked activity]
        (isLinkedActivity && isEmptyParentOutcomes)
      )
        return null;

      const isChecked =
        (!isLinkedActivity && !isEmptyParentOutcomes) ||
        (isLinkedActivity &&
          !isEmptyParentOutcomes &&
          !isEmpty(parentOutcomes.linkedDateTime));

      return (
        <Fragment key={i}>
          <Grid.Column className="objective-select-spacing">
            <Checkbox
              className="check-box"
              label={item.title}
              key={i}
              value={item.id}
              onChange={() =>
                this.handleChange(item.id, currentActivity.secondaryPolicyId)
              }
              checked={isChecked}
              disabled={isLinkedActivity}
            />
            <label className="check-box-description">{item.description}</label>
          </Grid.Column>
        </Fragment>
      );
    });
  }

  renderObjectiveCheck(policyId) {
    let { outcomes, currentActivity } = this.props;
    if (!currentActivity.goal) return null;
    const objectives = filter(outcomes, (o) => {
      return o.policyId === policyId;
    });
    if (isEmpty(objectives)) return null;
    return map(objectives, (item, i) => {
      const parentOutcomes = find(currentActivity.parentOutcomes, (o) => {
        return o.outcomeId === item.id && o.policyValue === policyId;
      });

      const isLinkedActivity = currentActivity.isLinkedActivity;

      const isEmptyParentOutcomes = isEmpty(parentOutcomes);

      if (
        (!isLinkedActivity && isEmptyParentOutcomes && !item.isEnable) ||
        // for  linked activities [mother program => child program linked activity]
        (isLinkedActivity && isEmptyParentOutcomes)
      )
        return null;

      const isChecked =
        (!isLinkedActivity && !isEmptyParentOutcomes) ||
        (isLinkedActivity &&
          !isEmptyParentOutcomes &&
          !isEmpty(parentOutcomes.linkedDateTime));

      return (
        <Fragment key={i}>
          <Grid.Column className="objective-select-spacing">
            <Checkbox
              className="check-box"
              label={item.title}
              key={i}
              value={item.id}
              onChange={() => this.handleChange(item.id, policyId)}
              checked={isChecked}
              disabled={isLinkedActivity}
            />
            <label className="check-box-description">{item.description}</label>
          </Grid.Column>
        </Fragment>
      );
    });
  }

  close = () => {
    this.setState({ confirm: false });
  };

  onConfirm = async () => {
    this.setState({ confirm: false });
  };

  render() {
    let {
      user,
      helpTips,
      currentActivity,
      currentState,
      parentOutcomesLoading,
    } = this.props;
    const { confirm } = this.state;

    return (
      <AvForm ref={(c) => (this.form = c)} className="activity-form">
        <Confirm
          open={confirm}
          onCancel={this.close}
          onConfirm={() => this.onConfirm()}
          header="Warning!"
          content="You are about to change an objective of this activity, this may mean that any evidence and theory of change attached to the outcome is no longer relevant. If you proceed, review any evidence and theory of change."
          confirmButton="Proceed"
          size="tiny"
        />
        <Segment
          className="content-segment-goal parent-objectives"
          disabled={getPageDisable(currentActivity, user, currentState)}
        >
          <Grid>
            <Grid.Row>
              {/* goal */}
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <div className="form-group">
                  <label className="goal-label">Goal</label>
                  <br />
                  <label className="objectiveGoal-title-new">
                    {currentActivity.goal ? (
                      currentActivity.goal
                    ) : (
                      <Alert color="warning">Please select an goal</Alert>
                    )}
                  </label>
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <div className="form-group">
                  <label className="goal-label">Goal Description</label>
                  <br />
                  <ShowMoreText
                    lines={3}
                    more="Read More"
                    less="Read Less"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    truncatedEndingComponent={'... '}
                    className="pre-line"
                  >
                    <label className="objective-description-new">
                      {currentActivity.goalDescription
                        ? currentActivity.goalDescription
                        : '-'}
                    </label>
                  </ShowMoreText>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment
          className="content-segment-goal"
          disabled={getPageDisable(currentActivity, user, currentState)}
          attached
          loading={parentOutcomesLoading}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column
                className="objective-goal"
                mobile={16}
                tablet={16}
                computer={8}
              >
                <CustomTooltip
                  state={get(user, 'options.tips')}
                  contentObject={{
                    data: helpTips,
                    key: '104',
                  }}
                  position="bottom left"
                  wide="very"
                >
                  {!currentActivity.goal ? null : (
                    <div className="select-objective-label">
                      {`Select objective for ${
                        currentActivity.primaryPolicy
                          ? currentActivity.primaryPolicy.policyName.toLowerCase()
                          : ''
                      } domain`}
                    </div>
                  )}
                </CustomTooltip>
                <div className="objective-select-check-new">
                  {this.renderObjectiveCheckOne()}
                </div>
              </Grid.Column>
              {isEmpty(currentActivity.parentDomains) ? (
                currentActivity.primaryPolicyId ===
                currentActivity.secondaryPolicyId ? null : currentActivity.secondaryPolicyId ? (
                  <Grid.Column
                    className="objective-goal"
                    mobile={16}
                    tablet={16}
                    computer={8}
                  >
                    <CustomTooltip
                      state={get(user, 'options.tips')}
                      contentObject={{
                        data: helpTips,
                        key: '104',
                      }}
                      position="bottom left"
                      wide="very"
                    >
                      {!currentActivity.goal ? null : (
                        <div className="select-objective-label">
                          {`Select objective for ${
                            currentActivity.secondaryPolicy
                              ? currentActivity.secondaryPolicy.policyName.toLowerCase()
                              : ''
                          } domain`}
                        </div>
                      )}
                    </CustomTooltip>
                    <div className="objective-select-check-new">
                      {this.renderObjectiveCheckTwo()}
                    </div>
                  </Grid.Column>
                ) : null
              ) : (
                map(currentActivity.parentDomains, (item, i) => {
                  if (currentActivity.primaryPolicyId === item.policyId) return;
                  return (
                    <Grid.Column
                      className="objective-goal"
                      mobile={16}
                      tablet={16}
                      computer={8}
                      key={i}
                    >
                      <CustomTooltip
                        state={get(user, 'options.tips')}
                        contentObject={{
                          data: helpTips,
                          key: '104',
                        }}
                        position="bottom left"
                        wide="very"
                      >
                        <div className="select-objective-label">
                          {`Select Objective for ${get(
                            item,
                            'policy.policyName',
                            ''
                          ).toLowerCase()} domain`}
                        </div>
                      </CustomTooltip>
                      <div className="objective-select-check-new">
                        {this.renderObjectiveCheck(item.policyId)}
                      </div>
                    </Grid.Column>
                  );
                })
              )}
            </Grid.Row>
          </Grid>
        </Segment>
      </AvForm>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    outcomes: state.extra.outcomes,
    user: state.authentication.user,
    helpTips: state.extra.activityHelpTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      putParentObjective,
    },
    dispatch
  );
};

ActivityParentObjective = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityParentObjective);

export { ActivityParentObjective };
