/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, PureComponent, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import {
  Button,
  Form,
  Grid,
  Modal,
  Popup,
  Segment,
  Tab,
  Input,
  Icon,
} from 'semantic-ui-react';
import {
  EvalPlanDisable,
  CustomAccordion,
  BrowseFile,
} from '../../../../components';
import {
  evalPlanManage,
  evalManage,
  evalPlanManageComplete,
  evaluationPlanManageDisable,
  updateEvaluationPlan,
  updateEvaluationPlanName,
  evaluationPlanTransformSelfAssess,
  getProjectOutcomeSummary,
  updateOutcomeChart,
  uploadActivityByMultipleObjectives,
  updateSeriesSubtitle,
  deleteSeriesSubtitle,
  getEvalReasons,
  getEvalAssesmentTypes,
  getEvaluationPlans,
} from '../../../../store/actions';

import { isEmpty, map, get, find, concat } from '../../../../utils/lodash';
import {
  ContentAndHeaderEvaluation,
  getCollectionMethod,
  getPageDisable,
} from '../../../../utils';
import '../activity.scss';
import { EvaluationQuestion } from './evaluationQuestion';
import editIcon from '../../../../assets/edit-icon.png';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import ActivityEvaluationSurvey from './activityEvaluationSurvey';
import SurveyContainer from './surveyContainer';

const EvaluationPlanMeasure = ({
  evalPlan,
  user,
  helpTips,
  evalPlanManage,
  evalPlanManageComplete,
  evaluationPlanManageDisable,
  updateEvaluationPlan,
  updateEvaluationPlanName,
  evaluationPlanTransformSelfAssess,
  updateSeriesSubtitle,
  currentActivity,
  deleteSeriesSubtitle,
}) => {
  const [isSubtitleDeleteLoading, setIsSubtitleDeleteLoading] = useState(false);
  const [isNameInputVisible, setIsNameInputVisible] = useState(false);
  const [isSubtitleVisible, setIsSubtitleVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [evaluationName, setEvaluationName] = useState('');
  const [title, setTitle] = useState(get(evalPlan, 'name', ''));
  const [subtitleName, setSubtitleName] = useState(
    get(evalPlan, 'seriesSubtitle', null)
  );
  const [modalSubtitleName, setModalSubtitleName] = useState(
    get(evalPlan, 'seriesSubtitle', null)
  );
  const [modal, setModal] = useState(false);
  const [errorResponseModal, setErrorResponseModal] = useState(false);
  const [errorConflictModal, setErrorConflictModal] = useState(false);
  const [disableReasonModal, setDisableReasonModal] = useState(false);
  const [disableReason, setDisableReason] = useState('');
  const [isSelfAssessed, setSelfAssessed] = useState(null);
  const [dateMeasured, setDateMeasured] = useState(new Date());
  const [completeEvaluationModal, setCompleteEvaluationModal] = useState(false);
  // manage was evaluated complete check box
  const [yesChecked, setYesChecked] = useState(false); // checkbox yes
  const [noChecked, setNoChecked] = useState(false); // checkbox no
  const [seriesConflict, setSeriesConflict] = useState(false); //

  const selfAssessed = get(evalPlan, 'method.response', '') === 'Self-assessed';
  const isDisabled = get(evalPlan, 'isDisabled', false);
  const seriesParentId = get(evalPlan, 'seriesParentId', null);
  const isSeriesActive = get(evalPlan, 'isSeriesActive', false);
  const _isSelfAssessed = get(evalPlan, 'isSelfAssessed', null);
  const isDateMeasured =
    get(evalPlan, 'dateMeasured', null) && !_isSelfAssessed ? true : null;

  useEffect(() => {
    if (_isSelfAssessed) {
      // for "no" status is self assessed activities
      setNoChecked(true);
      setSelfAssessed(true);
    } else if (isDisabled) {
      // for "no" status is disabled activities
      setNoChecked(true);
      setSelfAssessed(true);
    } else if (isDateMeasured) {
      // for "yes" status is completed activities
      setYesChecked(true);
      setSelfAssessed(false);
    } else {
      // no input data
      setSelfAssessed(null);
    }
    // setDateMeasured(get(evalPlan, 'dateMeasured', new Date()));

    let _title = evalPlan.name;
    if (evalPlan.seriesParentId) {
      _title = _title + ` (${evalPlan.seriesPosition})`;
      setTitle(_title);
    }
  }, []);

  const modalStatus = (status) => {
    setModal(status);
    setNoChecked(false);
  };

  const errorResponseModalStatus = (status) => {
    setErrorResponseModal(status);
    if (!status) {
      modalStatus(false);
    }
  };

  const errorConflictModalStatus = (status) => {
    setErrorConflictModal(status);
    if (!status) {
      modalStatus(false);
    }
  };

  const showModal = () => {
    setCompleteEvaluationModal(true);
  };

  const completeEvalCancel = () => {
    // self assess = true means check box -> No
    setSelfAssessed(true);
    setYesChecked(false);
    setCompleteEvaluationModal(false);
  };

  function handlerEvaluationComplete(status) {
    setSelfAssessed(status);
    setYesChecked(!status);
  }

  const completeEvalPlan = (status) => {
    setCompleteEvaluationModal(false);
    if (status) {
      setDateMeasured(moment().toDate());
      const form = {
        isSelfAssessed: false,
        dateMeasured,
        isSeries: evalPlan.seriesParentId ? true : false,
        collectionMethod: evalPlan.collectionMethod,
      };
      evalPlanManageComplete(
        currentActivity.id,
        evalPlan.id,
        form,
        evalPlan.name,
        handlerEvaluationComplete
      );
    }
  };

  const getPutValues = () => {
    let allValue = {
      name: get(evalPlan, 'name', ''),
      collectionMethod: 'LINK',
      objectives: map(
        get(evalPlan, 'evaluationQuestions', []),
        (evaluation) => evaluation.objectiveId
      ),
      participantGroups: map(
        get(evalPlan, 'participantGroups', []),
        (participantGroup) => participantGroup.targetGroup.id
      ),
      methodId: 9, //set to self-assessed
      sampleSize: get(evalPlan, 'sampleSize', ''),
      totalParticipants: get(evalPlan, 'totalParticipants', ''),
      isSelfAssessed: true,
    };
    allValue.evaluationQuestions = map(allValue.objectives, (objective) => {
      const outcome = find(
        get(currentActivity, 'objective', []),
        (e) => e.id === objective
      );
      return {
        objectiveId: objective,
        outcomeId: outcome.outcomeId,
        methodId: 9,
      };
    });
    return allValue;
  };

  // was evaluation completed No -> Select Yes in 1st prompt modal
  const onConfirm = async () => {
    const allValue = getPutValues();
    allValue.onConfirm = true;
    allValue.isSeries = evalPlan.seriesParentId ? true : false;
    await evaluationPlanTransformSelfAssess(
      currentActivity.id,
      evalPlan.id,
      allValue,
      {
        resetForm,
        forceFormReset,
        setErrorResponseModal,
        setErrorConflictModal,
        modalStatus,
        setNoChecked,
        setSeriesConflict,
      }
    );
  };

  // handler for making evaluation to => self assess evaluation
  // when using force it forcefully ignore validations when plan -> self assess
  const onConfirmForce = async (removeModal) => {
    try {
      let allValue = getPutValues();
      allValue.force = true;
      await evaluationPlanTransformSelfAssess(
        currentActivity.id,
        evalPlan.id,
        allValue,
        {
          resetForm,
          forceFormReset,
        }
      );
      removeModal(false);
    } catch (error) {
      // Handle Error here
    }
  };

  const resetForm = () => {
    setModal(false);
    setSelfAssessed(false);
  };

  const forceFormReset = () => {
    setModal(false);
    setSelfAssessed(null);
  };

  // const selfAssessedToggle = status => {
  //   if (!status) {
  //     setModal(true);
  //   } else {
  //     let checked = typeof isSelfAssessed === 'boolean' ? isSelfAssessed : null;
  //     if (!checked) {
  //       setDateMeasured(moment().toDate());
  //       setSelfAssessed(true);
  //       const form = {
  //         isSelfAssessed: true,
  //         dateMeasured,
  //         isSeries: evalPlan.seriesParentId ? true : false,
  //       };
  //       evalPlanManage(currentActivity.id, evalPlan.id, form, evalPlan.name);
  //     }
  //   }
  // };

  const showSelfAssessModal = () => {
    setModal(true);
  };

  const confirmDisableEvalPlan = async (removeModal) => {
    removeModal(false);
    // ask reason when disable plan
    if (seriesParentId) {
      setDisableReasonModal(true);
    } else {
      setDateMeasured(moment().toDate());
      const form = {
        isDisabled: true,
        dateMeasured,
        isSeries: evalPlan.seriesParentId ? true : false,
      };
      await evaluationPlanManageDisable(
        currentActivity.id,
        evalPlan.id,
        form,
        evalPlan.name
      );
      setDisableReasonModal(false);
      setSelfAssessed(true);
      setNoChecked(true);
    }
  };

  const disableEvalPlan = async () => {
    try {
      setDateMeasured(moment().toDate());
      const form = {
        isDisabled: true,
        dateMeasured,
        isSeries: evalPlan.seriesParentId ? true : false,
        disabledReason: disableReason,
      };
      await evaluationPlanManageDisable(
        currentActivity.id,
        evalPlan.id,
        form,
        evalPlan.name
      );

      // removeModal(false);
      setDisableReasonModal(false);
      setSelfAssessed(true);
      setNoChecked(true);
    } catch (error) {
      // Handle Error here
    }
  };

  const handleDeleteSubtitle = async () => {
    try {
      setIsSubtitleDeleteLoading(true);
      await deleteSeriesSubtitle(
        currentActivity.id,
        evalPlan.id,
        toggleSubtitleDeleteModal
      );
      setIsSubtitleDeleteLoading(false);
      toggleSubtitleDeleteModal(false);
      setSubtitleName('');
    } catch (error) {
      console.error(error);
    }
  };

  const toggleSubtitleDeleteModal = (value) => {
    setIsSubtitleVisible(false);
    setIsDeleteVisible(value);
    setIsSubtitleDeleteLoading(false);
    setSubtitleName(null);
    setModalSubtitleName(null);
  };

  const seriesDateMeasured = get(evalPlan, 'dateMeasured', null); // to check series evaluation was completed

  const handleChange = async () => {
    let value = {
      name: evaluationName,
    };
    await updateEvaluationPlanName(currentActivity.id, evalPlan.id, value);

    setIsNameInputVisible(false);
  };

  const subtitleHandleChange = () => {
    updateSeriesSubtitle(currentActivity.id, evalPlan.id, modalSubtitleName);
    setSubtitleName(modalSubtitleName);
    setIsSubtitleVisible(false);
  };
  const onChangeDisableReason = (value) => {
    setDisableReason(value);
  };

  const pageDisabled = getPageDisable(
    currentActivity,
    user,
    'project.edit.evaluationPlan'
  );

  return (
    <Fragment>
      <Grid>
        <Grid.Row></Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <div className="evaluation-name-column">
              <label className="evaluation-heading-doc">{title} </label>
              {(evalPlan.seriesParentId === null ||
                evalPlan.seriesParentId === evalPlan.id) &&
              !pageDisabled &&
              currentActivity.statusId !== 3 ? (
                <Modal
                  className="evalation-model"
                  open={isNameInputVisible}
                  onOpen={() => setIsNameInputVisible(true)}
                  closeIcon={
                    <Icon
                      onClick={() => setIsNameInputVisible(false)}
                      name="close"
                      className="closeicon"
                    />
                  }
                  size="small"
                  trigger={
                    <div className="evaluation-edit-icon">
                      <img
                        className="icons-edit"
                        src={editIcon}
                        alt="icon"
                        onClick={() => {
                          setEvaluationName(evalPlan.name);
                        }}
                      />
                    </div>
                  }
                >
                  <Modal.Header>Evaluation Plan Rename</Modal.Header>
                  <Modal.Content>
                    <div>
                      <div className="evaluation-label">
                        {' '}
                        New Evaluation Name
                      </div>
                      <Input
                        type="text"
                        className="evaluation-heading-input"
                        onChange={(e) => setEvaluationName(e.target.value)}
                        value={evaluationName}
                      />
                    </div>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      className="modal-cancel"
                      onClick={() => {
                        setIsNameInputVisible(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="modal-proceed"
                      onClick={() => handleChange()}
                    >
                      Save
                    </Button>
                  </Modal.Actions>
                </Modal>
              ) : null}
              {evalPlan.seriesParentId === evalPlan.id &&
                !pageDisabled &&
                currentActivity.statusId !== 3 && (
                  <label className="eval-button-separate"> | </label>
                )}
              {evalPlan.seriesParentId !== null &&
              !pageDisabled &&
              currentActivity.statusId !== 3 ? (
                <>
                  <Modal
                    open={isSubtitleVisible}
                    onOpen={() => setIsSubtitleVisible(true)}
                    size="tiny"
                    closeIcon={
                      <Icon
                        onClick={() => setIsSubtitleVisible(false)}
                        name="close"
                        className="closeicon"
                      />
                    }
                    trigger={
                      <div className="evaluation-subtitle">
                        {subtitleName ? (
                          <>
                            <Icon name="edit" /> {subtitleName}
                          </>
                        ) : (
                          <>
                            <Icon name="plus" /> Add Subtitle
                          </>
                        )}
                      </div>
                    }
                  >
                    <Modal.Header>
                      {subtitleName
                        ? 'Edit Subtitle'
                        : 'Add Subtitle to Evaluation Series'}
                    </Modal.Header>
                    <Modal.Content>
                      <div>
                        <div className="evaluation-label">
                          {' '}
                          New Evaluation Name
                        </div>
                        <Input
                          type="text"
                          className="evaluation-heading-input"
                          onChange={(e) => setModalSubtitleName(e.target.value)}
                          value={modalSubtitleName}
                        />
                      </div>
                    </Modal.Content>
                    <Modal.Actions>
                      <div className="d-flex mt-2">
                        <div className="">
                          {subtitleName && (
                            <Button
                              className="modal-delete"
                              onClick={() => toggleSubtitleDeleteModal(true)}
                            >
                              Delete
                            </Button>
                          )}
                        </div>
                        <div className="ml-auto">
                          <Button
                            className="modal-cancel mr-2"
                            onClick={() => setIsSubtitleVisible(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => subtitleHandleChange()}
                            className="modal-proceed"
                          >
                            {subtitleName ? 'Save' : 'Add'}
                          </Button>
                        </div>
                      </div>
                    </Modal.Actions>
                  </Modal>
                </>
              ) : (
                <div className="evaluation-subtitle-lbl">{subtitleName}</div>
              )}
              <SubtitleDeleteModal
                subtitleDeleteModal={isDeleteVisible}
                handleDeleteSubtitle={handleDeleteSubtitle}
                toggleSubtitleDeleteModal={toggleSubtitleDeleteModal}
                subtitleDeleteLoading={isSubtitleDeleteLoading}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column
            mobile={5}
            tablet={5}
            computer={5}
            className="eval-plan-objective-row-documentation"
          >
            <ContentAndHeaderEvaluation title="Objective">
              <ul className="outcome-title-ul-custom">
                {map(
                  get(evalPlan, 'evaluationQuestions', []),
                  (evaluation, key) => (
                    <li key={`evaluation+plan_section_${key}`}>
                      {get(evaluation, 'outcome.title', '-')}
                    </li>
                  )
                )}
              </ul>
            </ContentAndHeaderEvaluation>
          </Grid.Column>
          <Grid.Column
            mobile={5}
            tablet={5}
            computer={5}
            className="eval-plan-eval-grp-row-documentation"
          >
            <ContentAndHeaderEvaluation title="Evaluation Groups">
              {map(get(evalPlan, 'participantGroups', []), (evaluation, i) => (
                <span key={i}>{get(evaluation, 'targetGroup.name', '-')}</span>
              ))}
            </ContentAndHeaderEvaluation>
          </Grid.Column>
          <Grid.Column
            mobile={5}
            tablet={5}
            computer={5}
            className="eval-plan-eval-info-row-documentation"
          >
            <ContentAndHeaderEvaluation title="Evaluation Information">
              {selfAssessed
                ? get(evalPlan, 'method.response', '-')
                : `${get(evalPlan, 'method.method', '-')} - ${get(
                    evalPlan,
                    'method.response',
                    '-'
                  )} - ${getCollectionMethod(evalPlan.collectionMethod)}`}
            </ContentAndHeaderEvaluation>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {/* isSelfAssessed is true when transform from normal evaluation plan to self asses */}
      {(!seriesParentId && selfAssessed && !isSelfAssessed) ||
      !dateMeasured ||
      (seriesParentId && !seriesDateMeasured && !isSeriesActive) ? null : (
        <Segment disabled={currentActivity.statusId === 3 ? true : false}>
          <div className="url-card  plan-documentation">
            <div className="obj-div">
              <div className="radio-container">
                <Popup
                  content="This cannot be undone. if you want to apply a new evaluation method, please go to evaluation page"
                  disabled={
                    isDisabled
                      ? true
                      : typeof isSelfAssessed === 'boolean' && !isSelfAssessed
                        ? false
                        : true
                  }
                  wide="very"
                  trigger={
                    <div>
                      <label className="radio-label">
                        Was The Evaluation Completed ?
                      </label>
                      <Form>
                        <Form.Field>
                          <div className="d-flex">
                            <div className="checkbox-container">
                              <div className="checkbox-margin ">
                                <input
                                  type="checkbox"
                                  label="YES"
                                  name="checkBoc-group"
                                  id="button1"
                                  checked={yesChecked}
                                  onChange={() => showModal()}
                                  className="radio-btn"
                                  disabled={
                                    isDisabled ||
                                    pageDisabled ||
                                    (seriesParentId &&
                                      seriesDateMeasured &&
                                      !isSeriesActive)
                                      ? true
                                      : typeof isSelfAssessed === 'boolean'
                                        ? !seriesParentId && isSelfAssessed
                                        : null
                                  }
                                />
                                <div className="checkbox-label">Yes</div>
                              </div>
                            </div>

                            <div className="d-flex">
                              <div className="checkbox-margin ">
                                <input
                                  type="checkbox"
                                  label="NO"
                                  name="checkBoc-group"
                                  id="button1"
                                  checked={noChecked}
                                  onChange={() => showSelfAssessModal()}
                                  className="radio-btn"
                                  disabled={
                                    isDisabled ||
                                    pageDisabled ||
                                    (seriesParentId &&
                                      seriesDateMeasured &&
                                      !isSeriesActive)
                                      ? true
                                      : typeof isSelfAssessed === 'boolean'
                                        ? !seriesParentId && isSelfAssessed
                                        : null
                                  }
                                />
                                <div className="checkbox-label">No</div>
                              </div>
                            </div>
                          </div>
                        </Form.Field>
                      </Form>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </Segment>
      )}

      <Modal
        onClose={() => completeEvalPlan(false)}
        open={completeEvaluationModal}
        size="small"
      >
        <Modal.Header>Evaluation Complete Confirmation</Modal.Header>
        <Modal.Content>
          <p>
            You have selected that an evaluation was completed for this
            objective, do you want to proceed? The selection cannot be undone
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button className="modal-cancel" onClick={() => completeEvalCancel()}>
            No
          </Button>
          <Button
            className="modal-proceed"
            onClick={() => completeEvalPlan(true)}
          >
            Yes
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        onClose={() => modalStatus(!modal)}
        onOpen={() => modalStatus(!modal)}
        open={modal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Evaluation completed</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              You have selected that an evaluation was not completed for this
              objective, do you want to proceed? The selection cannot be undone
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="modal-cancel"
            content="No"
            onClick={() => modalStatus(!modal)}
          />
          <Button
            className="modal-proceed"
            content="Yes"
            onClick={() => onConfirm()}
          />
        </Modal.Actions>

        <Modal
          onClose={() => errorResponseModalStatus(!errorResponseModal)}
          open={errorResponseModal}
          size="small"
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header>Self Evaluation Confirmation</Modal.Header>
          <Modal.Content>
            <p>
              This evaluation plan has responses recorded; do you wish to delete
              any responses and complete a self-evaluation for this plan?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="No"
              className="modal-cancel"
              onClick={() => confirmDisableEvalPlan(errorResponseModalStatus)}
            />
            <Button
              content="Yes"
              className="modal-proceed"
              onClick={() => onConfirmForce(errorResponseModalStatus)}
            />
          </Modal.Actions>
        </Modal>
        <Modal
          onClose={() => errorConflictModalStatus(!errorConflictModal)}
          open={errorConflictModal}
          size="small"
        >
          <Modal.Header>Self Evaluation Confirmation</Modal.Header>
          <Modal.Content>
            {seriesParentId && seriesConflict ? (
              <>
                <p>Do you want to do a self-assessment for this plan?</p>
              </>
            ) : (
              <>
                <p>
                  Another evaluation plan including this group and outcome/s
                  exits, do you want to do a self-assessment for this plan?
                </p>
              </>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              className="modal-cancel"
              onClick={() => confirmDisableEvalPlan(errorConflictModalStatus)}
            >
              No
            </Button>
            <Button
              className="modal-proceed"
              onClick={() => onConfirmForce(errorConflictModalStatus)}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      </Modal>

      {/* disabled reason modal */}
      <Modal
        size="small"
        onClose={() => setDisableReasonModal(!disableReasonModal)}
        onOpen={() => setDisableReasonModal(!disableReasonModal)}
        open={disableReasonModal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Evaluation disabled</Modal.Header>
        <Modal.Content>
          <AvForm>
            <AvField
              type="textarea"
              style={{ minHeight: '5rem' }}
              name="disable-reason"
              label="Reason for disabling this evaluation"
              placeholder="Type your reason here"
              className="sub-inputs"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Reason is required',
                },
              }}
              onChange={(e) => onChangeDisableReason(e.target.value)}
            />
          </AvForm>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="modal-cancel"
            content="Cancel"
            onClick={() => {
              setNoChecked(false);
              setDisableReasonModal(false);
            }}
          />
          <Button
            className="modal-proceed"
            content="Save"
            onClick={() => disableEvalPlan()}
          />
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

export class SubtitleDeleteModal extends React.PureComponent {
  render() {
    const {
      subtitleDeleteModal,
      handleDeleteSubtitle,
      toggleSubtitleDeleteModal,
      deleteSeriesSubtitleLoading,
    } = this.props;
    return (
      <Modal
        open={subtitleDeleteModal}
        onOpen={() => toggleSubtitleDeleteModal(true)}
        closeIcon={
          <Icon
            onClick={() => toggleSubtitleDeleteModal(false)}
            name="close"
            className="closeicon"
          />
        }
        size="small"
      >
        <Modal.Header>Delete Confirmation</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this subtitle?</p>

          <div className="participant-delete-button-container">
            <Button
              onClick={() => toggleSubtitleDeleteModal(false)}
              className="modal-cancel"
            >
              Cancel
            </Button>
            <Button
              className="modal-proceed"
              onClick={() => handleDeleteSubtitle()}
              content="Delete "
              type="submit"
              loading={deleteSeriesSubtitleLoading}
              negative
            />
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

const EvaluationPlanCharts = ({
  checked,
  setToggle,
  setChartToReport,
  ...props
}) => {
  const { evalPlan, isFileChanged } = props;
  const [selectedObjective, setObjective] = useState(null);
  const [objectives, setObjectives] = useState([]);
  const [one = {}] = get(evalPlan, 'evaluationQuestions', []);

  useEffect(() => {
    const objectiveId = get(one, 'objectiveId', null);
    if (objectiveId) {
      setObjective(objectiveId);
      const getEval = find(
        evalPlan.evaluationQuestions,
        (evaluation) => evaluation.objectiveId === objectiveId
      );
      setToggle(get(getEval, 'inReport', false));
      const getObjectives = map(
        get(evalPlan, 'evaluationQuestions', []),
        (obj) => {
          return {
            key: obj.objectiveId,
            text: get(obj, 'outcome.title', '-'),
            value: obj.objectiveId,
          };
        }
      );
      setObjectives(getObjectives);
    }
  }, []);

  useEffect(() => {
    const objectiveId = get(one, 'objectiveId', null);
    if (objectiveId) {
      setObjective(objectiveId);
      const getEval = find(
        evalPlan.evaluationQuestions,
        (evaluation) => evaluation.objectiveId === objectiveId
      );
      setToggle(get(getEval, 'inReport', false));
      const getObjectives = map(
        get(evalPlan, 'evaluationQuestions', []),
        (obj) => {
          return {
            key: obj.objectiveId,
            text: get(obj, 'outcome.title', '-'),
            value: obj.objectiveId,
          };
        }
      );
      setObjectives(getObjectives);
    }
  }, [isFileChanged]);

  const changeObjective = (e, data) => {
    let objectiveId = objectives[data.activeIndex].value;
    setObjective(objectiveId);
    const getEval = find(
      evalPlan.evaluationQuestions,
      (evaluation) => evaluation.objectiveId === objectiveId
    );
    setToggle(get(getEval, 'inReport', false));
  };

  const renderTabs = () => {
    const outcomes = map(objectives, (objective, i) => {
      return {
        menuItem: get(objective, 'text', '-'),
        render: () => (
          <Tab.Pane key={i} menu={{ secondary: true, pointing: true }}>
            <div className="url-card ">
              {map(evalPlan.evaluationQuestions, (evaluation, i) => {
                if (evaluation.objectiveId === selectedObjective) {
                  return (
                    <EvaluationQuestion
                      objectiveName={get(objective, 'text', '-')}
                      evaluation={find(
                        evalPlan.evaluationQuestions,
                        (evaluation) =>
                          evaluation.objectiveId === selectedObjective
                      )}
                      setChartToReport={() =>
                        setChartToReport(!checked, selectedObjective)
                      }
                      checked={checked}
                      evalPlan={evalPlan}
                      key={i}
                      index={i}
                      currentState="project.edit.evaluationPlan"
                      selectedObjective={selectedObjective}
                      isFileChanged={isFileChanged}
                      {...props}
                    />
                  );
                }
              })}
            </div>
          </Tab.Pane>
        ),
      };
    });
    const newArray = concat(outcomes);
    return newArray;
  };

  return (
    <Fragment>
      {isFileChanged ? (
        <Segment loading={isFileChanged}></Segment>
      ) : (
        <Tab
          className="tabs-graph"
          menu={{ secondary: true, pointing: true }}
          panes={renderTabs()}
          onTabChange={(e, data) => changeObjective(e, data)}
        />
      )}
    </Fragment>
  );
};

class UploadCsv extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      value: {},
      file: null,
      isNoFile: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  setFile = (file) => {
    this.setState({ file });
  };

  uploadFileHandler = () => {
    this.setState({ loading: true }, async () => {
      try {
        const { file } = this.state;

        if (!file) {
          this.setState({ loading: false, isNoFile: true });
        } else {
          const {
            uploadActivityByMultipleObjectives,
            toggle,
            evalPlan,
            currentActivity,
            setIsFileChanged,
          } = this.props;
          await setIsFileChanged(true);
          const activeCultureCount =
            get(evalPlan, 'method.response', null) === 'Participant' &&
            get(evalPlan, 'method.method', null) === 'Intercept' &&
            get(evalPlan, 'collectionMethod', null) === 'CULTURECOUNTS';

          const evalQuestion = evalPlan.evaluationQuestions;
          const projectId = currentActivity.id;
          const evaluationPlanId = evalPlan.id;

          let formData = new FormData();
          formData.set('uploadFile', file);

          const objectives = map(
            get(evalPlan, 'evaluationQuestions', []),
            (obj) => {
              return {
                key: obj.objectiveId,
                text: get(obj, 'outcome.title', '-'),
                value: obj.objectiveId,
              };
            }
          );

          if (evalQuestion) {
            if (activeCultureCount) {
              formData.append('isCultureCount', true);
            } else {
              formData.append('isCultureCount', false);
            }

            let objArr = [];
            objectives.forEach((objective) => {
              objArr.push(objective.value);
            });
            formData.append('objectives', JSON.stringify(objArr)); // adding array of objectives
          }

          // use this for passing value to route params
          const evaluation = {
            projectId: projectId,
            evaluationPlanId: evaluationPlanId,
          };

          await uploadActivityByMultipleObjectives(evaluation, formData);
          await setIsFileChanged(false);
          this.setFile(null); // empty file state data
          toggle();
          this.setState({ loading: false, isNoFile: false });
        }
      } catch (error) {
        this.setState({ loading: false });
      }
    });
  };

  csvFileUploadForm = (toggle) => {
    return (
      <>
        <div className="p-4">
          <BrowseFile
            label="UPLOAD EVALUATION TEMPLATE"
            name="strategic"
            accept=".csv"
            buttonText="BROWSE"
            setFile={(data) => this.setFile(data.file)}
            loading={this.state.loading}
            csv
          />
          {this.state.isNoFile && (
            <span className="error-msg">Please select a file</span>
          )}
        </div>
        <hr className="w-100 my-2" />
        <div className="model-buttons p-3">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => {
              this.setFile(null);
              toggle();
            }}
          >
            Cancel
          </Button>
          <Button
            className="Primary-Button"
            loading={this.state.loading}
            disabled={this.state.loading}
            type="submit"
            onClick={() => this.uploadFileHandler()}
          >
            Save
          </Button>
        </div>
      </>
    );
  };

  render() {
    const { modal, toggle } = this.props;
    return (
      <Modal
        size="tiny"
        open={modal}
        onOpen={() => toggle()}
        closeIcon={
          <Icon onClick={() => toggle()} name="close" className="closeicon" />
        }
      >
        <Modal.Header>Upload a CSV file</Modal.Header>
        {this.csvFileUploadForm(toggle)}
      </Modal>
    );
  }
}

const EvaluationPlanChild = (props) => {
  const [modal, setModal] = useState(false);
  const [isFileChanged, setIsFileChanged] = useState(false); // to handle common upload file was uploaded/ modified
  const [checked, setToggle] = useState(false);

  const { evalPlan, currentActivity, user } = props;

  const isDisabledCsvFunctionalities =
    get(evalPlan, 'dateMeasured', false) || get(evalPlan, 'isDisabled', false);

  const isDisabled =
    get(evalPlan, 'isDisabled', false) ||
    getPageDisable(currentActivity, user, 'project.edit.evaluationPlan');

  // toggler for managing upload csv modal (import csv section)
  const toggle = () => {
    setModal(!modal);
  };

  const setChartToReport = (
    inReport,
    selectedObjective,
    ignoreAPISave = false
  ) => {
    const { evalManage } = props;
    if (!ignoreAPISave) {
      evalManage(currentActivity.id, evalPlan.id, selectedObjective, {
        inReport,
      });
    }
    setToggle(inReport);
  };

  // =============================== Upload Template  ===================================

  const activeCultureCount =
    get(evalPlan, 'method.response', null) === 'Participant' &&
    get(evalPlan, 'method.method', null) === 'Intercept' &&
    get(evalPlan, 'collectionMethod', null) === 'CULTURECOUNTS';

  const evalQuestion = evalPlan.evaluationQuestions;

  let headers = [
    {
      label: 'Response date',
      key: 'responseDate',
    },
  ];

  for (let index = 0; index < evalQuestion.length; index++) {
    let title = evalPlan.evaluationQuestions[index].outcome.title;
    let cumulative = evalQuestion[index].cumulative; // cumulative decides whether prev responses is there

    if (Boolean(cumulative)) {
      headers.push(
        {
          label: `After ${title} (1-10)`,
          key: 'afterResponse',
        },
        {
          label: `Before ${title} (1-10)`,
          key: 'beforeResponse',
        },
        {
          label: `${title} comment`,
          key: 'reason',
        }
      );
    } else {
      headers.push(
        {
          label: `${title} - rating (1-10)`,
          key: 'participantResponse',
        },
        {
          label: `${title} comment`,
          key: 'reason',
        }
      );
    }
  }

  return (
    <Segment className="p-2">
      <div className="eval-que-div">
        <div className="eval-que-header">
          {evalPlan.collectionMethod === 'MANUAL' ? (
            <p>
              In the fields below please specify the number of evaluation
              participants who selected the number on the scale from not-at-all
              to most imaginable.
            </p>
          ) : null}

          {/* // evaluation isNewCsvFeature: upload all objectives data in one CSV file */}
          {(evalPlan.collectionMethod === 'CSV' ||
            evalPlan.collectionMethod === 'CULTURECOUNTS') &&
          evalPlan.isNewCsvFeature ? (
            <Segment
              disabled={
                currentActivity.statusId === 3 || isDisabledCsvFunctionalities
                  ? true
                  : false
              }
            >
              <label className="eval-que-sub-header mt-4 mb-2">
                Import CSV Data
              </label>
              <p>
                {activeCultureCount ? (
                  <div className="ml-4 my-2">
                    <ol className="p-0">
                      <li>
                        <p className="mb-3">
                          {' '}
                          Download evaluation results from Culture Counts as a
                          CSV format file.
                        </p>

                        {activeCultureCount && (
                          <>
                            <div className="eval-culture-count-container my-2">
                              <a
                                href={'https://culturecounts.cc/sign-in'}
                                rel="noopener noreferrer"
                                target="_blank"
                                className="culture-count-a"
                              >
                                <div className="eval-culture-count">
                                  <span>
                                    Go to Culture Counts
                                    <Icon
                                      name="external alternate"
                                      className="ml-3 mb-1"
                                    />
                                  </span>
                                </div>
                              </a>
                            </div>
                          </>
                        )}
                      </li>

                      <li>
                        <p className="mb-2 mt-4">
                          Import Culture Counts CSV file into Takso.
                        </p>
                        <div className="row">
                          <Button
                            icon
                            labelPosition="left"
                            className="upload-btn my-2"
                            onClick={() => toggle()}
                            disabled={
                              isDisabled || isDisabledCsvFunctionalities
                            }
                          >
                            <Icon name="upload" />
                            Import Culture Counts Survey
                          </Button>
                        </div>
                      </li>
                    </ol>
                  </div>
                ) : (
                  `Download the evaluation template, populate with your data
                      and use the upload button to load your participant
                      responses.`
                )}
              </p>
              <div>
                {!activeCultureCount ? (
                  <div className="row">
                    {' '}
                    <CSVLink
                      className={isDisabled ? 'disabled-csv' : ''}
                      headers={headers}
                      data={[]}
                      filename={'template.csv'}
                    >
                      <Button
                        icon
                        labelPosition="left"
                        className="upload-btn"
                        disabled={isDisabled || isDisabledCsvFunctionalities}
                      >
                        <Icon name="cloud download" />
                        Download CSV Template
                      </Button>
                    </CSVLink>
                    <Button
                      icon
                      labelPosition="left"
                      className="upload-btn"
                      onClick={() => toggle()}
                      disabled={isDisabled || isDisabledCsvFunctionalities}
                    >
                      <Icon name="upload" />
                      Upload CSV
                    </Button>{' '}
                  </div>
                ) : null}
              </div>
            </Segment>
          ) : null}
        </div>

        <UploadCsv
          modal={modal}
          toggle={() => toggle()}
          setIsFileChanged={setIsFileChanged} // to set common upload file was uploaded/ modified
          {...props}
        />

        {evalPlan.methodId === 9 ? (
          <div className="new-self-assess">
            <ActivityEvaluationSurvey
              {...props}
              checked={checked}
              setToggle={setToggle}
              setChartToReport={setChartToReport}
              isDisabled={isDisabled}
            />
          </div>
        ) : activeCultureCount ? (
          <div className="px-4">
            <EvaluationPlanCharts
              isFileChanged={isFileChanged} // to check common upload file was uploaded/ modified
              setIsFileChanged={setIsFileChanged} // to handle common upload file was uploaded/ modified from child component
              checked={checked}
              setToggle={setToggle}
              setChartToReport={setChartToReport}
              {...props}
            />
          </div>
        ) : (
          <div className="url-card  plan-documentation">
            <div className="obj-div">
              <div className="tabs-evaluation">
                <EvaluationPlanCharts
                  isFileChanged={isFileChanged} // to check common upload file was uploaded/ modified
                  setIsFileChanged={setIsFileChanged} // to handle common upload file was uploaded/ modified from child component
                  checked={checked}
                  setToggle={setToggle}
                  setChartToReport={setChartToReport}
                  {...props}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Segment>
  );
};

const EvaluationPlan = (props) => {
  const { user, currentActivity } = props;

  const dispatch = useDispatch();
  const evalAssesmentTypes = useSelector(
    (state) => state.extra.evalAssesmentTypes
  );
  const evalReasons = useSelector((state) => state.extra.evalReasons);

  const [isFileChanged, setIsFileChanged] = useState(false);

  useEffect(() => {
    dispatch(getEvalReasons());
    dispatch(getEvalAssesmentTypes());
  }, []);

  const dateMeasured = get(props.evalPlan, 'dateMeasured', null);
  const seriesParentId = get(props.evalPlan, 'seriesParentId', null);
  const isSeriesActive = get(props.evalPlan, 'isSeriesActive', null);
  const selfAssessed =
    get(props.evalPlan, 'method.response', '') === 'Self-assessed';
  const isDisabled = get(props.evalPlan, 'isDisabled', false);

  const pageDisabled = getPageDisable(
    currentActivity,
    user,
    'project.edit.evaluationPlan'
  );

  return (
    <Fragment>
      <Segment>
        {get(props, 'evalPlan.isDisabled', false) ? (
          <EvalPlanDisable {...props.evalPlan} />
        ) : null}
        <EvaluationPlanMeasure {...props} />
        {(seriesParentId && dateMeasured) ||
        (!seriesParentId && selfAssessed) ||
        !seriesParentId ||
        (seriesParentId && isSeriesActive) ? (
          <div className="eval-plan-accordion">
            {!selfAssessed && !dateMeasured && (
              <CustomAccordion
                extraMargin
                headerStyle={{ color: '#EF8123' }}
                titleAvailable={false}
                title={''}
                open={'Show Survey Information'}
                close={'Hide Survey Information'}
                evalPlan={true}
                children={
                  <>
                    <SurveyContainer
                      key={props.evalPlan.name}
                      evalPlan={props.evalPlan}
                      {...props}
                      pageDisabled={pageDisabled}
                    />
                  </>
                }
              />
            )}

            {selfAssessed && !isDisabled && (
              <Grid>
                <Grid.Column mobile={16} tablet={8} computer={16}>
                  <div className="url-card  plan-documentation">
                    <div className="obj-div">
                      Please click show evaluation details button and complete
                      the self assessment questions below to ensure that your
                      activity is evaluated and the record complete.
                    </div>
                  </div>
                </Grid.Column>
              </Grid>
            )}

            <CustomAccordion
              id={`accordion_${props.evalPlan.id}`}
              name={`accordion_${props.evalPlan.id}`}
              extraMargin
              headerStyle={{ color: '#EF8123' }}
              titleAvailable={false}
              title={''}
              open={'Show Evaluation Details'}
              close={'Hide Evaluation Details'}
              evalPlan={true}
              children={
                !isEmpty(evalAssesmentTypes) &&
                !isEmpty(evalReasons) && (
                  <EvaluationPlanChild
                    isFileChanged={isFileChanged} // to check common upload file was uploaded/ modified
                    setIsFileChanged={setIsFileChanged} // to handle common upload file was uploaded/ modified from child component
                    evalAssesmentTypes={evalAssesmentTypes}
                    evalReasons={evalReasons}
                    {...props}
                    pageDisabled={pageDisabled}
                  />
                )
              }
            />
          </div>
        ) : null}
      </Segment>
      <br />
    </Fragment>
  );
};

class EvaluationPlanSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { currentActivity, getEvaluationPlans } = this.props;
    getEvaluationPlans(currentActivity.id);
  }

  render() {
    return (
      <Segment className="survey-seg">
        <div className="survey-div">
          <EvalPlans singleEvalPlans {...this.props} />
        </div>
      </Segment>
    );
  }
}

class EvalPlans extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      currentActivity: { evaluationPlans = [] },
    } = this.props;

    if (isEmpty(evaluationPlans)) return null;
    return (
      <Fragment>
        {map(evaluationPlans, (plan, i) => (
          <Fragment key={i}>
            <div className="report-eval-plan-top-border">
              <EvaluationPlan key={i} evalPlan={plan} {...this.props} />
            </div>
          </Fragment>
        ))}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    user: state.authentication.user,
    helpTips: state.extra.activityHelpTips,
    participantsChange: state.report.participantsChange,
    participantsChangePrePost: state.report.participantsChangePrePost,
    participants: state.report.participants,
    projectLoading: state.report.projectLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      evalPlanManage,
      evalManage,
      evalPlanManageComplete,
      evaluationPlanManageDisable,
      updateEvaluationPlan,
      evaluationPlanTransformSelfAssess,
      getProjectOutcomeSummary,
      updateOutcomeChart,
      updateEvaluationPlanName,
      uploadActivityByMultipleObjectives,
      updateSeriesSubtitle,
      deleteSeriesSubtitle,
      getEvaluationPlans,
    },
    dispatch
  );
};

EvaluationPlanSection = connect(
  mapStateToProps,
  mapDispatchToProps
)(EvaluationPlanSection);

export { EvaluationPlanSection };
