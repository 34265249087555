import * as Yup from 'yup';

export const newActivityInitialValues = {
  name: '',
  activityPlanTypeId: '',
};

export const NewActivityValidationSchema = Yup.object().shape({
  name: Yup.string().required(
    'Activity title or temporarily title is required'
  ),
  activityPlanTypeId: Yup.number().required('Activity plan type is required'),
});
