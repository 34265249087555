import './styles.scss';

import React from 'react';

import { isMobile, isTablet } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dropdown, Icon, Image } from 'semantic-ui-react';

import history from '../utils/history';
import { map, get } from '../utils/lodash';
import { threeDotSeparator } from '../utils';
import Initicon from 'react-initicon';
import { logoutUser, setSidebarType } from '../store/actions';
import { isIndividualUser } from '../utils/activity/userPermission';
import { SIDEBAR_TYPES } from '../utils/constant';

const optionsGoToAdmin = [
  { key: 'user', text: 'User Profile', value: '/user-profile' },
  {
    key: 'admin-activity',
    text: 'Go To Admin',
    value: '/admin-activity-settings',
  },
  { key: 'help', text: 'Help', value: '/help' },
  { key: 'sign-out', text: 'Log Out', value: '/' },
];

const optionsToGoHome = [
  { key: 'user', text: 'User Profile', value: '/user-profile' },
  {
    key: 'home',
    text: 'Go To Home',
    value: '/dashboard',
  },
  { key: 'help', text: 'Help', value: '/help' },
  { key: 'sign-out', text: 'Log Out', value: '/' },
];
const optionsForUser = [
  { key: 'user', text: 'User Profile', value: '/user-profile' },
  { key: 'help', text: 'Help', value: '/help' },
  { key: 'sign-out', text: 'Log Out', value: '/' },
];

class Profile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  async getAction(route) {
    const { logoutUser, navigate, setSidebarType } = this.props;

    switch (route) {
      case '/':
        await logoutUser(navigate);
        break;

      default:
        navigate(route);
        // hide side bar
        setSidebarType(SIDEBAR_TYPES[0], false);
        break;
    }
  }

  render() {
    let { user, isAdminRoute } = this.props;

    const file = get(user, 'organisation.logo.documentUrl', null);
    const name = get(user, 'organisation.name', get(user, 'name', ''));
    const options =
      user.role === 'admin'
        ? isAdminRoute
          ? optionsToGoHome
          : optionsGoToAdmin
        : optionsForUser;

    return (
      <div className="dropdown-adjust user-profile-dropdown">
        <Dropdown
          pointing="top left"
          inline
          selectOnBlur={false}
          icon={null}
          trigger={
            /*
             * Note:  user-profile-drop class name used for help tip showing class name (don't change it or remove it)
             */
            <div className="user-profile-drop">
              {!file ? (
                <Initicon size={32} text={name} seed={23} single={true} />
              ) : (
                <Image
                  src={file}
                  className="img-wid"
                  alt="logo"
                  size="mini"
                  rounded
                />
              )}
              <div className="name-wid">
                {/*
                 * Note: user-pro-panel class name used for help tip showing class name (don't change it or remove it)
                 */}
                <span className="user-pro-panel">
                  {isMobile ? (
                    isTablet ? (
                      <>
                        <label className="user-pro-text">
                          {`${user.name}`.substring(0, 10)}
                        </label>
                        <label className="user-sub-text">{name}</label>
                      </>
                    ) : null
                  ) : (
                    <div className="user-info">
                      <div>
                        <label className="user-pro-text">
                          {threeDotSeparator({
                            name: user.name,
                            length: 17,
                            isPopUp: false,
                          })}
                        </label>
                        <Icon
                          className="icon-re"
                          name="caret down"
                          size="large"
                        />
                      </div>

                      <label className="user-sub-text">
                        {user.role === 'guest'
                          ? 'Guest Account'
                          : isIndividualUser(user)
                            ? 'Individual Account'
                            : name}
                      </label>
                    </div>
                  )}
                </span>
              </div>
              {/* <Icon className="icon-re" name="caret down" size="large" /> */}
            </div>
          }
        >
          <Dropdown.Menu>
            {user.role === 'guest' ? null : (
              <div className="disabled-item">
                <div className="drop-in-userName">
                  {!file ? (
                    <Initicon size={32} text={name} seed={23} single={true} />
                  ) : (
                    <Image
                      src={file}
                      className="img-wid"
                      alt="logo"
                      size="mini"
                      rounded
                    />
                  )}
                  <div className="user-pro-text">
                    {threeDotSeparator({
                      name: name,
                      length: 15,
                      isPopUp: false,
                    })}
                  </div>
                </div>
              </div>
            )}
            {map(options, (item, idx) => {
              return (
                <Dropdown.Item
                  key={idx}
                  onClick={() => this.getAction(item.value)}
                >
                  <div className="text-color">{item.text}</div>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.authentication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logoutUser,
      setSidebarType,
    },
    dispatch
  );
};

Profile = connect(mapStateToProps, mapDispatchToProps)(Profile);

export { Profile };
