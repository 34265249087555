import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Segment, Icon, Button, Modal, Image } from 'semantic-ui-react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import moment from 'moment';
import DropIn from 'braintree-web-drop-in-react';
import {
  addCard,
  updateCard,
  getUserProfileOnly,
  endSubscription,
  newSubscription,
} from '../../../store/actions';
import { SUBSCRIPTIONS, DROP_IN_AUTH_KEY } from '../../../utils/constant';
import { map, find, get, isEmpty } from '../../../utils/lodash';
import './account.scss';

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggle() {
    const { cardAlert } = this.props;
    cardAlert(false);
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async handleValidSubmit(event, values) {
    try {
      const { user, addCard, updateCard, edit } = this.props;
      const { cardHolderName } = values;
      // Send the nonce to your server
      const { nonce } = await this.instance.requestPaymentMethod();
      const btCustomer = get(user, 'organisation.btCustomer', {});
      if (edit || !isEmpty(btCustomer)) {
        await updateCard(user.organisationId, {
          nonce,
          cardHolderName,
        });
      } else {
        await addCard(user.organisationId, { nonce, cardHolderName });
      }
      this.toggle();
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const { modal } = this.state;
    const { edit, manageBtCardLoading } = this.props;
    return (
      <Modal
        trigger={
          <Button
            className="subscription"
            content={edit ? 'Change' : 'Add Card'}
            onClick={() => this.toggle()}
          />
        }
        size="tiny"
        open={modal}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
      >
        <Modal.Header>{edit ? 'Change' : 'Add'} Card</Modal.Header>
        <Modal.Content>
          <AvForm
            onValidSubmit={(event, values) =>
              this.handleValidSubmit(event, values)
            }
          >
            <AvField
              type="text"
              name="cardHolderName"
              label="CARD HOLDER NAME"
              className="sub-inputs"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Card holder name is required',
                },
              }}
            />
            <DropIn
              options={{
                authorization: DROP_IN_AUTH_KEY,
                card: {
                  overrides: {
                    fields: {
                      number: {
                        placeholder: '1111 1111 1111 1111', // Update the number field placeholder
                      },
                    },
                    styles: {
                      input: {
                        'font-size': '1.125rem', // Change the font size for all inputs
                      },
                      ':focus': {
                        color: 'black', // Change the focus color to red for all inputs
                      },
                    },
                  },
                },
              }}
              onInstance={(instance) => (this.instance = instance)}
              onError={(error) => console.error(error)}
            />
            <div className="model-buttons">
              <Button
                disabled={manageBtCardLoading}
                className="billing-cancel"
                type="button"
                onClick={() => this.toggle()}
              >
                Cancel
              </Button>
              <Button
                loading={manageBtCardLoading}
                disabled={manageBtCardLoading}
                content="Save"
                type="submit"
                className="billing"
              />
            </div>
          </AvForm>
        </Modal.Content>
      </Modal>
    );
  }
}

class AddSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      subscription: null,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      subscription: null,
    }));
  }

  onChangeSubs(subscription) {
    this.setState({
      subscription,
    });
  }

  async handleValidSubmit(event, values) {
    try {
      const { user, newSubscription } = this.props;
      await newSubscription(user.organisationId, {
        subscriptionId: Number(values.subscription),
      });
      this.toggle();
    } catch (error) {
      console.error(error);
    }
  }

  renderIncludes(subscription) {
    const subDetails = find(
      SUBSCRIPTIONS,
      (sub) => sub.id === Number(subscription)
    );
    return subDetails ? (
      <div className="sub-detail-container-verification">
        <label className="plan-include-text">Your plan includes</label>

        <Grid columns={1} stackable>
          <Grid.Row className="detail">
            {map(subDetails.includes, (inc, i) => (
              <div>
                <label className={'orangeCorrectIcon'}>
                  &#10004;&nbsp;&nbsp;
                </label>
                <label className={'planList'}>{inc}</label>
              </div>
            ))}
          </Grid.Row>
        </Grid>
      </div>
    ) : null;
  }

  renderGroups() {
    const { subscriptions = [], user } = this.props;
    return map(subscriptions, (item, i) => {
      const isIndividual = item.accountTypes.includes(
        get(user, 'organisation.type', 'INDIVIDUAL')
      );
      if (!isIndividual) return null;
      return (
        <option key={i} value={item.id}>
          {`${item.title} - ${item.amount}$ /year`}
        </option>
      );
    });
  }

  render() {
    const { modal, subscription } = this.state;
    const { cardEdit, cardAlert, addSubscriptionLoading } = this.props;
    return (
      <Modal
        trigger={
          <Button
            className="subscription"
            content="Add New Subscription"
            onClick={() => (cardEdit ? this.toggle() : cardAlert(true))}
          />
        }
        size="tiny"
        open={modal}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
      >
        <Modal.Header>Add New Subscription</Modal.Header>
        <div className="subscription-change-info">
          <label>Your new subscription rate will begin immediately</label>
        </div>
        <Modal.Content className="subs-form">
          <AvForm
            ref={(c) => (this.form = c)}
            onValidSubmit={(event, values) =>
              this.handleValidSubmit(event, values)
            }
          >
            <AvField
              type="select"
              name="subscription"
              label="SUBSCRIPTION"
              className="sub-inputs"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Subscription type is required',
                },
              }}
              onChange={(e) => this.onChangeSubs(e.target.value)}
            >
              <option value="" defaultValue disabled>
                Select Subscription Type...
              </option>
              {this.renderGroups()}
            </AvField>
            {this.renderIncludes(subscription)}
            <div className="model-buttons">
              <Button
                className="billing-cancel"
                type="button"
                onClick={() => this.toggle()}
              >
                Cancel
              </Button>
              <Button
                loading={addSubscriptionLoading}
                disabled={addSubscriptionLoading}
                content="Save"
                type="submit"
                className="billing"
              />
            </div>
          </AvForm>
        </Modal.Content>
      </Modal>
    );
  }
}

class ChangeSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      subscription: null,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const subscriptionId = get(user, 'organisation.subscriptionId', null);
    if (subscriptionId) {
      this.setState({
        subscription: subscriptionId,
      });
    }
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  onChangeSubs(subscription) {
    this.setState({
      subscription,
    });
  }

  handleValidSubmit(event, values) {
    this.toggle();
  }

  renderIncludes(subscription) {
    const subDetails = find(
      SUBSCRIPTIONS,
      (sub) => sub.id === Number(subscription)
    );
    return subDetails ? (
      <div className="sub-detail-container-verification">
        <label className="plan-include-text">Your plan includes</label>

        <Grid columns={1} stackable>
          <Grid.Row className="detail">
            {map(subDetails.includes, (inc, i) => (
              <div>
                <label className={'orangeCorrectIcon'}>
                  &#10004;&nbsp;&nbsp;
                </label>
                <label className={'planList'}>{inc}</label>
              </div>
            ))}
          </Grid.Row>
        </Grid>
      </div>
    ) : null;
  }

  renderGroups() {
    const { subscriptions = [], user } = this.props;
    return map(subscriptions, (item, i) => {
      const isIndividual = item.accountTypes.includes(
        get(user, 'organisation.type', 'INDIVIDUAL')
      );
      if (!isIndividual) return null;
      return (
        <option key={i} value={item.id}>
          {`${item.title} - ${item.amount}$ /year`}
        </option>
      );
    });
  }

  render() {
    const { modal, subscription } = this.state;
    const { user } = this.props;
    const orgType = get(user, 'organisation.type', 'INDIVIDUAL');
    return (
      <Modal
        trigger={
          <Button
            className="subscription"
            content="Change Subscription"
            onClick={() => this.toggle()}
          />
        }
        size="tiny"
        open={modal}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
      >
        <Modal.Header>Change Subscription</Modal.Header>
        <div className="subscription-change-info">
          <label>Your new subscription rate will begin immediately</label>
        </div>
        <Modal.Content className="subs-form">
          <AvForm
            ref={(c) => (this.form = c)}
            onValidSubmit={(event, values) =>
              this.handleValidSubmit(event, values)
            }
            model={{
              subscription: get(user, 'organisation.subscriptionId', null),
            }}
          >
            <AvField
              type="select"
              name="subscription"
              label="SUBSCRIPTION"
              className="sub-inputs"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Subscription type is required',
                },
              }}
              onChange={(e) => this.onChangeSubs(e.target.value)}
            >
              <option value="" defaultValue disabled>
                Select Subscription Type...
              </option>
              {this.renderGroups()}
            </AvField>
            {this.renderIncludes(subscription)}
            <div className="model-buttons">
              <Button
                className="billing-cancel"
                type="button"
                onClick={() => this.toggle()}
              >
                Cancel
              </Button>
              <Button
                // loading={}
                disabled={orgType === 'INDIVIDUAL'}
                content="Save"
                type="submit"
                className="billing"
              />
            </div>
          </AvForm>
        </Modal.Content>
      </Modal>
    );
  }
}

class EndSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async endSub() {
    try {
      const { user, endSubscription } = this.props;
      await endSubscription(user.organisationId);
      this.toggle();
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const { modal } = this.state;
    const { endSubLoading } = this.props;
    return (
      <Modal
        trigger={
          <Button
            className="subscription-cancel"
            content="End Subscription"
            onClick={() => this.toggle()}
          />
        }
        size="tiny"
        open={modal}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
      >
        <Modal.Header>End Subscription</Modal.Header>
        <Modal.Content>
          <label className="billing-info">
            Are you sure that you want to end your subscription?
          </label>
          <div className="model-buttons">
            <Button
              className="billing-end-cancel"
              type="button"
              onClick={() => this.toggle()}
            >
              Cancel
            </Button>
            <Button
              loading={endSubLoading}
              disabled={endSubLoading}
              onClick={() => this.endSub()}
              content="End"
              type="submit"
              className="billing-end"
            />
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

class SubscriptionDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  messageNotification() {
    const {
      orgSubscription,
      latestOrgInvoice,
      user,
      trialExpiredAt = null,
    } = this.props;

    const subscriptionEndedAt = get(
      user,
      'organisation.subscriptionEndedAt',
      null
    );

    const isActive = get(user, 'organisation.isActive', null);

    let message = '';
    if (trialExpiredAt) {
      message = (
        <label className="subs-header-alert">
          Trial period ends on {moment(trialExpiredAt).format('MMM DD YYYY')}
        </label>
      );
    }

    if (!isEmpty(orgSubscription) && !isEmpty(latestOrgInvoice)) {
      if (moment(latestOrgInvoice.nextBillingDate).diff(moment(), 'days') < 0) {
        message = null;
      } else {
        message = (
          <label
            className={
              isEmpty(orgSubscription) ? 'subs-header-alert' : 'subs-header'
            }
          >
            {latestOrgInvoice
              ? `${
                  isEmpty(orgSubscription)
                    ? 'Subscription will end on'
                    : 'Next charge on'
                } ${moment(latestOrgInvoice.nextBillingDate).format(
                  'MMM DD YYYY'
                )}`
              : 'Subscription Details'}
          </label>
        );
      }
    }

    if (!isEmpty(subscriptionEndedAt) && !isEmpty(latestOrgInvoice)) {
      let difference = moment().diff(latestOrgInvoice.nextBillingDate, 'days');
      if (difference <= 0) {
        message = (
          <label className="subs-header-alert">
            Earlier subscription will work until{' '}
            {moment(latestOrgInvoice.nextBillingDate).format('MMM DD YYYY')}
          </label>
        );
      }
    }

    if (!isEmpty(latestOrgInvoice)) {
      let overDueBy = moment(moment()).diff(
        latestOrgInvoice.nextBillingDate,
        'days'
      );

      if (
        isActive &&
        moment(moment()).diff(latestOrgInvoice.nextBillingDate, 'days') < 7 &&
        moment(moment()).diff(latestOrgInvoice.nextBillingDate, 'days') > 0
      ) {
        message = (
          <label className="subs-header-alert">
            Payment is overdue by {overDueBy} {overDueBy === 1 ? 'day' : 'days'}
          </label>
        );
      }
    }

    return message;
  }

  render() {
    const { orgSubscription, trialExpiredAt = null } = this.props;

    return (
      <Grid columns={2} stackable>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={isEmpty(orgSubscription) ? 16 : 8}
            computer={isEmpty(orgSubscription) ? 16 : 8}
          >
            <div className="subs-details">
              {this.messageNotification()}

              {/* {!isEmpty(subscriptionEndedAt) && !isEmpty(latestOrgInvoice) ? (
                <>{this.showSubscriptionMessage(latestOrgInvoice)}</>
              ) : (
                <>
                  {trialExpiredAt ? (
                    <label className="subs-header-alert">
                      Trial period ends on{' '}
                      {moment(trialExpiredAt).format('MMM DD YYYY')}
                    </label>
                  ) : (
                    <>
                      {!isEmpty(orgSubscription) &&
                      !isEmpty(latestOrgInvoice) ? (
                        moment(latestOrgInvoice.nextBillingDate).diff(
                          moment(),
                          'days'
                        ) < 0 ? null : (
                          <label
                            className={
                              isEmpty(orgSubscription)
                                ? 'subs-header-alert'
                                : 'subs-header'
                            }
                          >
                            {latestOrgInvoice
                              ? `${
                                  isEmpty(orgSubscription)
                                    ? 'Subscription will end on'
                                    : 'Next charge on'
                                } ${
                                  latestOrgInvoice.nextBillingDate
                                    ? moment(
                                        latestOrgInvoice.nextBillingDate
                                      ).format('MMM DD YYYY')
                                    : '-'
                                }`
                              : 'Subscription Details'}
                          </label>
                        )
                      ) : null} */}

              {/* {this.overDueMessage(
                        isActive,
                        overDueBy,
                        latestOrgInvoice
                      )} 
                    </>
                  )}
                </>
              )}*/}
            </div>
          </Grid.Column>
          {isEmpty(orgSubscription) ? null : (
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="subs-details-amount">
                <label className="subs-amount">
                  ${get(orgSubscription, 'amount', 0)}&nbsp;
                  <span className="gst-view">/ +GST (10%) </span>
                </label>
              </div>
            </Grid.Column>
          )}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <div className="subs-btn-container">
              {isEmpty(orgSubscription) ? (
                <AddSubscription {...this.props} />
              ) : (
                <Fragment>
                  <ChangeSubscription {...this.props} />
                  {trialExpiredAt ? null : <EndSubscription {...this.props} />}
                </Fragment>
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

class SubscriptionCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { cardEdit, btCustomer } = this.props;
    return (
      <Grid className="subs-card-container">
        <Grid.Row>
          <Grid.Column>
            <div className="subs-details">
              <label className="subs-header">Payment Method</label>
              {cardEdit ? (
                <div className="card-details-container">
                  <label className="card-owner">
                    {get(btCustomer, 'btCardHolderName', '-')}
                  </label>
                  <span className="card-number">
                    <label>
                      **** ***** **** {get(btCustomer, 'btLast4', '****')}
                    </label>
                    &nbsp;
                    <Image
                      src={get(
                        btCustomer,
                        'btImageUrl',
                        'https://assets.braintreegateway.com/payment_method_logo/visa.png'
                      )}
                      size="mini"
                    />
                  </span>
                </div>
              ) : (
                <label className="subs-dec-card">Credit Card/ Debit Card</label>
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <div className="subs-btn-container">
              <Card edit={cardEdit} {...this.props} />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

class Subscription extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { getUserProfileOnly } = this.props;
    getUserProfileOnly();
  }

  render() {
    return (
      <Segment className="content-segment" loading={false}>
        <Grid columns={2} divided stackable>
          <Grid.Row className="org-row">
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              className="account-sub-column mt-5"
            >
              <SubscriptionDetails {...this.props} />
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              className="account-sub-column"
            >
              <SubscriptionCard {...this.props} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  const { authentication, organization } = state;
  const btCustomer = get(authentication.user, 'organisation.btCustomer', null);
  const trialExpiredAt = get(
    authentication.user,
    'organisation.trialExpiredAt',
    null
  );
  const orgSubscription = get(
    authentication.user,
    'organisation.subscription',
    null
  );
  const latestOrgInvoice = get(
    authentication.user,
    'organisation.invoices[0]',
    null
  );
  return {
    user: authentication.user,
    cardEdit: btCustomer && btCustomer.btCardHolderName ? true : false,
    btCustomer,
    orgSubscription,
    latestOrgInvoice,
    manageBtCardLoading: organization.manageBtCardLoading,
    addSubscriptionLoading: organization.addSubscriptionLoading,
    endSubLoading: organization.endSubLoading,
    subscriptions: state.extra.subscriptions,
    trialExpiredAt,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addCard,
      updateCard,
      getUserProfileOnly,
      endSubscription,
      newSubscription,
    },
    dispatch
  );
};

Subscription = connect(mapStateToProps, mapDispatchToProps)(Subscription);

export { Subscription };
