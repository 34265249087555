import moment from 'moment';
import React from 'react';
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from 'availity-reactstrap-validation';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Grid,
  Segment,
  Modal,
  Button,
  Icon,
  Dropdown,
} from 'semantic-ui-react';
import { map, get } from '../../../../utils/lodash';
import { EmptyContainer, CustomTable } from '../../../../components';
import {
  getBroadcastNotifications,
  createBroadcastNotifications,
  getNotifications,
  updateNotificationReadStatus,
  getOrganizationUsersByAdmin,
} from '../../../../store/actions';
import '../../../organization';

class NotificationForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      usersError: false,
      isChecked: true,
    };
  }

  async handleValidSubmit(event, values) {
    try {
      const { isChecked, users } = this.state;
      const { toggle, createBroadcastNotifications, resetPage } = this.props;

      if (!isChecked && !users.length) {
        this.setState({ usersError: true });
      } else {
        values.isAllUsers = isChecked;
        values.users = users;
        await createBroadcastNotifications(values);
        this.form && this.form.reset();
        if (resetPage) resetPage();
        toggle();
      }
    } catch (error) {
      console.error(
        'Error: createBroadcastNotifications ~ handleValidSubmit',
        error
      );
    }
  }

  getUsersOptions() {
    let { orgUsers } = this.props;
    return map(orgUsers, (item) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
  }

  async onChangeUsers(event, data) {
    this.setState({
      users: data.value,
      usersError: !data.value.length,
    });
  }

  async onChangeCheckBox() {
    await this.setState((prevState) => ({
      isChecked: !prevState.isChecked,
    }));
  }

  render() {
    const { usersError, isChecked } = this.state;
    const { toggle } = this.props;
    const options = this.getUsersOptions();
    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="broadcast-form"
      >
        <AvField
          label="Title"
          className="org-inputs"
          name="title"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: 'Title is required',
            },
            maxLength: {
              value: 100,
            },
          }}
        />
        <AvField
          label="Message"
          className="org-inputs-textarea"
          name="message"
          type="textarea"
          validate={{
            required: {
              value: true,
              errorMessage: 'Message is required',
            },
          }}
        />

        <AvCheckboxGroup name="isAllUsers">
          <AvCheckbox
            label="Send to All Users"
            checked={isChecked}
            onChange={(e) => this.onChangeCheckBox()}
          />
        </AvCheckboxGroup>

        {!isChecked ? (
          <div>
            <label>Select Users</label>
            <Dropdown
              fluid
              multiple
              search
              selection
              options={options}
              onChange={(event, data) => this.onChangeUsers(event, data)}
              error={usersError}
              className="broadcast-dropdown"
            />
            {usersError ? (
              <div className="invalid-dropdown-input">
                Select user is required
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="model-buttons">
          <Button
            className="goal-cancel-button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button content="Send" type="submit" className="goal-create-button" />
        </div>
      </AvForm>
    );
  }
}

class CreateNotification extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    return (
      <div className="add-goal">
        <Modal
          trigger={
            <Button
              onClick={() => this.toggle()}
              className="customColor custom-margin-goals"
            >
              CONTACT ORGANISATION USERS
            </Button>
          }
          size="small"
          open={modal}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
        >
          <Modal.Header>Broadcast New Message</Modal.Header>
          <Modal.Content>
            <NotificationForm {...this.props} toggle={() => this.toggle()} />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export class ViewNotification extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  async toggle() {
    await this.setState((prevState) => ({
      modal: !prevState.modal,
    }));

    const { records, updateNotificationReadStatus, isBroadcast } = this.props;
    const { modal } = this.state;
    if (modal && !isBroadcast && !records.isRead) {
      updateNotificationReadStatus(records.id);
    }
  }

  render() {
    const { modal } = this.state;
    const { records, isBroadcast } = this.props;
    return (
      <Modal
        trigger={<label className="org-checklist-actions-warn">View</label>}
        size="small"
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        className="view-broadcast-message"
      >
        <Modal.Header>View Broadcast Message</Modal.Header>
        <Modal.Content className="pre-line">
          {isBroadcast
            ? get(records, 'message')
            : get(records, 'notification.message')}
        </Modal.Content>
      </Modal>
    );
  }
}

class Communication extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      columns: [
        {
          title: 'Title',
          render: (data) => {
            return (
              <label className="org-checklist-text">
                {get(data, 'title', '-')}
              </label>
            );
          },
        },
        {
          title: 'Sender',
          render: (data) => {
            return (
              <label className="org-checklist-text">
                {get(data, 'sender.firstName', '-')}{' '}
                {get(data, 'sender.surname')}
              </label>
            );
          },
        },
        {
          title: 'Created At',
          render: (data) => {
            return (
              <label className="org-checklist-text">
                {get(data, 'createdAt')
                  ? moment(get(data, 'createdAt')).format(
                      'D MMMM YYYY, hh:mm A'
                    )
                  : '-'}
              </label>
            );
          },
        },
        {
          title: '',
          render: (data) => {
            return (
              <div className="org-checklist-actions">
                <ViewNotification {...this.props} records={data} />
              </div>
            );
          },
        },
      ],
      notificationsColumns: [
        {
          title: 'Title',
          render: (data) => {
            return (
              <div>
                <label className="org-checklist-text">
                  {get(data, 'notification.title', '-')}
                </label>
                {!get(data, 'isRead') ? (
                  <span className="new-label">New</span>
                ) : null}
              </div>
            );
          },
        },
        {
          title: 'Sender',
          render: (data) => {
            return (
              <label className="org-checklist-text">
                {get(data, 'notification.sender.firstName', '-')}{' '}
                {get(data, 'notification.sender.surname')}
              </label>
            );
          },
        },
        {
          title: 'Created At',
          render: (data) => {
            return (
              <label className="org-checklist-text">
                {get(data, 'createdAt')
                  ? moment(get(data, 'createdAt')).format(
                      'D MMMM YYYY, hh:mm A'
                    )
                  : '-'}
              </label>
            );
          },
        },
        {
          title: '',
          render: (data) => {
            return (
              <div className="org-checklist-actions">
                <ViewNotification {...this.props} records={data} />
              </div>
            );
          },
        },
      ],
    };
  }

  resetPage() {
    this.setState({
      currentPage: 1,
    });
  }

  componentDidMount() {
    const {
      user,
      getBroadcastNotifications,
      getNotifications,
      getOrganizationUsersByAdmin,
      isBroadcast,
    } = this.props;
    if (isBroadcast) {
      getBroadcastNotifications();
      getOrganizationUsersByAdmin(user.organisation.id);
    } else {
      getNotifications();
    }
  }

  async notificationsPageChange(page) {
    const { getNotifications } = this.props;
    await this.setState({ currentPage: page });
    getNotifications(page);
  }

  async broadcastPageChange(page) {
    const { getBroadcastNotifications } = this.props;
    await this.setState({ currentPage: page });
    getBroadcastNotifications(page);
  }

  render() {
    const { columns, notificationsColumns, currentPage } = this.state;
    const {
      isBroadcast,
      notificationsLoading,
      broadcastMessage,
      notifications,
    } = this.props;
    return (
      <Segment
        className="content-segment org-checklist"
        loading={
          isBroadcast
            ? notificationsLoading.broadcast
            : notificationsLoading.notification
        }
      >
        <Grid>
          {isBroadcast ? (
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={8}
                computer={8}
                textAlign="left"
                verticalAlign="bottom"
              >
                <div>
                  <a
                    href="mailto:support@takso-outcomes.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ui button customColor custom-margin-goals support-mail"
                  >
                    CONTACT TAKSO SUPPORT
                  </a>
                </div>
              </Grid.Column>
              <Grid.Column
                mobile={16}
                tablet={8}
                computer={8}
                textAlign="right"
                className="btn-right"
              >
                <CreateNotification {...this.props} />
              </Grid.Column>
            </Grid.Row>
          ) : null}

          <Grid.Row>
            {isBroadcast ? (
              get(broadcastMessage, 'docs') && broadcastMessage.docs.length ? (
                <Grid.Column>
                  <CustomTable
                    columns={columns}
                    data={broadcastMessage.docs}
                    customClass="org-checklist-table"
                    pagination
                    handlePaginationChange={(page) =>
                      this.broadcastPageChange(page)
                    }
                    page={currentPage}
                    noOfPages={broadcastMessage.pages}
                    keyExtractor={(item, index) => item.id}
                  />
                </Grid.Column>
              ) : (
                <EmptyContainer msg="No notification found…" />
              )
            ) : get(notifications, 'docs') && notifications.docs.length ? (
              <Grid.Column>
                <CustomTable
                  columns={notificationsColumns}
                  data={notifications.docs}
                  customClass="org-checklist-table"
                  pagination
                  handlePaginationChange={(page) =>
                    this.notificationsPageChange(page)
                  }
                  page={currentPage}
                  noOfPages={notifications.pages}
                  keyExtractor={(item, index) => item.id}
                />
              </Grid.Column>
            ) : (
              <EmptyContainer msg="No notification found…" />
            )}
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  const { organization, authentication, user } = state;
  return {
    user: authentication.user,
    orgUsers: organization.orgUsers,
    notificationsLoading: user.notificationsLoading,
    broadcastMessage: user.broadcastMessage,
    notifications: user.notifications,
    organization: organization.organization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getBroadcastNotifications,
      createBroadcastNotifications,
      getNotifications,
      getOrganizationUsersByAdmin,
      updateNotificationReadStatus,
    },
    dispatch
  );
};

Communication = connect(mapStateToProps, mapDispatchToProps)(Communication);

export { Communication };
