import './auth.scss';
import React from 'react';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import ReactPhoneInput from 'react-phone-input-2';
import { Link } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { SUBSCRIPTIONS } from '../../utils/constant';
import { map, find } from '../../utils/lodash';
import { Button, Container, Grid, Icon } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import {
  clearSignUpCache,
  signUpCache,
  getSubscriptions,
  postSignup,
  postSignupRequest,
} from '../../store/actions';

class SignUp extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      country: '61',
      isOrganisation: false,
      telError: false,
    };
  }

  componentDidMount() {
    const { userSignupData } = this.props;

    this.setState({
      phone: userSignupData ? userSignupData.phone : '',
    });
  }

  resetForm() {
    this.setState(
      {
        phone: '',
        telError: false,
      },
      () => {
        this.form && this.form.reset();
      }
    );
  }

  async handleValidSubmit(event, values) {
    try {
      const { signUpCache, setFinal, postSignupRequest } = this.props;
      const { phone, isOrganisation } = this.state;
      const telError = this.teleError();
      if (telError) {
        return;
      }
      values.phone = phone.replace(/\D/g, '');

      if (isOrganisation && !telError) {
        await postSignupRequest(values, this);
        return;
      }

      if (!telError && !isOrganisation) {
        await signUpCache(values);
        if (!isOrganisation) {
          setFinal();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleInvalidSubmit() {
    this.teleError();
    return;
  }

  organisation(isState) {
    this.setState({
      isOrganisation: isState ? true : false,
      telError: false,
    });
  }

  teleError() {
    const { phone, country } = this.state;
    const telError =
      phone === '+' || phone === `+${country}`
        ? true
        : isEmpty(phone)
          ? true
          : false;
    this.setState({ telError });
    return telError;
  }

  render() {
    const { isOrganisation, telError } = this.state;
    const { userSignupData, postSubOrgLoading } = this.props;
    return (
      <Grid.Row>
        <Container className="right-heading-container">
          <label className="headerText">Get Started</label>
          <br></br>
          <label className="headerDescription">Sign Up to Continue</label>
          <br></br>
          <label className="accountTypeHeader">What type of account?</label>
          <div className="cardStyle">
            <div
              className={
                isOrganisation
                  ? 'card-style-custom-one'
                  : 'card-style-custom-one-clicked'
              }
              onClick={() => this.organisation(false)}
            >
              <div className="icon-style">
                <div className="icon-style-custom">
                  <Icon
                    name="user outline"
                    size="large"
                    className={'icon-color-style-one'}
                  ></Icon>
                </div>

                <div className="para-style-custom">
                  <p
                    className={isOrganisation ? 'MainText' : 'MainText-clicked'}
                  >
                    Individual
                  </p>
                  <p className={'SubText-one'}>To manage your own activities</p>
                </div>
              </div>
            </div>

            <div
              className={
                isOrganisation
                  ? 'card-style-custom-two-clicked'
                  : 'card-style-custom-two'
              }
              onClick={() => this.organisation(true)}
            >
              <div className="icon-style">
                <div className="icon-style-custom">
                  <Icon
                    name="suitcase"
                    size="large"
                    className={'icon-color-style-two'}
                  ></Icon>
                </div>

                <div className="para-style-custom">
                  <p
                    className={isOrganisation ? 'MainText-clicked' : 'MainText'}
                  >
                    Organisation
                  </p>
                  <p className={'SubText-two'}>
                    Multiple users in one organisation
                  </p>
                </div>
              </div>
            </div>
          </div>
          {!isOrganisation ? (
            <AvForm
              className={'form-group-conatiner'}
              model={userSignupData ? userSignupData : null}
              ref={(c) => (this.form = c)}
              onValidSubmit={(event, values) =>
                this.handleValidSubmit(event, values)
              }
              onInvalidSubmit={() => this.handleInvalidSubmit()}
              autoComplete="none"
            >
              <div className=" nameFields">
                <div className="form-group-style-one">
                  <AvField
                    label="First Name"
                    placeholder="Enter your first name"
                    className="signin-inputs"
                    name="firstName"
                    type="text"
                    errorMessage="Please enter valid first name"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'First name is required',
                      },
                    }}
                  />
                </div>

                <div className="form-group-style-two">
                  <AvField
                    label="Surname"
                    placeholder="Enter your Surname"
                    className="signin-inputs"
                    name="surname"
                    errorMessage="Please enter valid surname"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Surname is required',
                      },
                    }}
                  />
                </div>
              </div>

              <div className="form-group">
                <label for="phone">Phone Number</label>
                <ReactPhoneInput
                  inputExtraProps={{
                    name: 'phone',
                    required: true,
                  }}
                  defaultCountry={'au'}
                  value={this.state.phone}
                  onChange={(phone, country) =>
                    this.setState({
                      phone,
                      country: country.dialCode,
                    })
                  }
                  inputClass={
                    telError
                      ? 'phone-input-style-tel-error'
                      : 'phone-input-style  '
                  }
                  onBlur={() => this.teleError()}
                />
                {telError ? (
                  <label className="required-input">Phone is required</label>
                ) : null}
              </div>

              <AvField
                label="Email"
                placeholder="yourname@mail.com"
                autoComplete="new-email"
                className="signin-inputs"
                name="email"
                type="email"
                errorMessage="Please enter valid email address"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Please Enter Email',
                  },
                }}
              />

              <AvField
                label="Password"
                autoComplete="new-password"
                placeholder="7+ characters"
                className="signin-inputs pass"
                name="password"
                type="password"
                minLength="7"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Please Enter Password',
                  },
                  minLength: {
                    value: 7,
                    errorMessage: 'Password must have at least 6 characters',
                  },
                }}
              />

              <Button
                type="submit"
                className="btn btn-primary signin-button"
                content={isOrganisation ? 'Sign Up' : 'Submit'}
                loading={isOrganisation ? postSubOrgLoading : null}
                disabled={isOrganisation ? postSubOrgLoading : null}
              />
              <label className={'alreadyContent'}>
                Already have an account?{' '}
                <Link to={'/signin'}>
                  <label className={'signin-label'}> Sign in</label>
                </Link>
              </label>
            </AvForm>
          ) : (
            <AvForm
              className={'form-group-conatiner'}
              model={userSignupData ? userSignupData : null}
              ref={(c) => (this.form = c)}
              onValidSubmit={(event, values) =>
                this.handleValidSubmit(event, values)
              }
              onInvalidSubmit={() => this.handleInvalidSubmit()}
              autoComplete="none"
            >
              <AvField
                label="Organisation Name"
                placeholder="Enter organisation name"
                className="signin-inputs"
                name="orgName"
                type="text"
                errorMessage="Please enter valid first name"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'First name is required',
                  },
                }}
              />
              <div className=" nameFields">
                <div className="form-group-style-one">
                  <AvField
                    label="First Name"
                    placeholder="Enter your first name"
                    className="signin-inputs"
                    name="orgFirstName"
                    type="text"
                    errorMessage="Please enter valid first name"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'First name is required',
                      },
                    }}
                  />
                </div>

                <div className="form-group-style-two">
                  <AvField
                    label="Surname"
                    placeholder="Enter your Surname"
                    className="signin-inputs"
                    name="orgSurname"
                    errorMessage="Please enter valid surname"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Surname is required',
                      },
                    }}
                  />
                </div>
              </div>

              <AvField
                label="Email"
                placeholder="yourname@mail.com"
                autoComplete="new-email"
                className="signin-inputs"
                name="orgEmail"
                type="email"
                errorMessage="Please enter valid email address"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Please Enter Email',
                  },
                }}
              />
              <div className="form-group">
                <label for="orgPhone">Phone Number</label>
                <ReactPhoneInput
                  inputExtraProps={{
                    name: 'phone',
                    required: true,
                  }}
                  defaultCountry={'au'}
                  value={this.state.phone}
                  onChange={(phone, country) =>
                    this.setState({
                      phone,
                      country: country.dialCode,
                    })
                  }
                  inputClass={
                    telError
                      ? 'phone-input-style-tel-error'
                      : 'phone-input-style  '
                  }
                  onBlur={() => this.teleError()}
                />
                {telError ? (
                  <label className="required-input">Phone is required</label>
                ) : null}
              </div>
              <Button
                type="submit"
                className="btn btn-primary signin-button"
                content={isOrganisation ? 'Submit' : 'Sign Up'}
                loading={isOrganisation ? postSubOrgLoading : null}
                disabled={isOrganisation ? postSubOrgLoading : null}
              />
              <label className={'alreadyContent'}>
                Already have an account?{' '}
                <Link to={'/signin'}>
                  <label className={'signin-label'}> Sign in</label>
                </Link>
              </label>
            </AvForm>
          )}
        </Container>
      </Grid.Row>
    );
  }
}

class SubscriptionDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      subscription: null,
    };
  }

  componentDidMount() {
    const { getSubscriptions } = this.props;

    getSubscriptions();
  }

  async handleValidSubmit(event, values) {
    try {
      const { userSignupData, postSignup, navigate } = this.props;

      values = {
        ...userSignupData,
        ...values,
      };

      await postSignup(values, navigate);
    } catch (error) {
      console.error(error);
    }
  }

  onChangeSubs(subscription) {
    this.setState({
      subscription,
    });
  }

  renderGroups() {
    return map(this.props.subscriptions, (item, i) => {
      const isIndividual = item.accountTypes.includes('INDIVIDUAL');
      if (!isIndividual) return null;
      return (
        <option key={i} value={item.id}>
          {item.title}
        </option>
      );
    });
  }

  render() {
    const { subscription } = this.state;
    const { postOrgLoading } = this.props;

    const subDetails = find(
      SUBSCRIPTIONS,
      (sub) => sub.id === Number(subscription)
    );

    return (
      <Grid.Row>
        <Container className="right-heading-container">
          <label className="headerText">Subscription Details</label>
          <br></br>
          <label className="headerDescription">
            Select your subscription plan and enjoy a 14 days free trial
          </label>
          <label className={'subscriptionText'}>Subscription Plan</label>
          <AvForm
            onValidSubmit={(event, values) =>
              this.handleValidSubmit(event, values)
            }
          >
            <AvField
              type="select"
              name="subscription"
              className="sub-inputs"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Subscription type is required',
                },
              }}
              onChange={(e) => this.onChangeSubs(e.target.value)}
            >
              <option value="" defaultValue disabled>
                Select Subscription Type...
              </option>
              {this.renderGroups()}
            </AvField>
            {subDetails ? (
              <div className="sub-detail-container-verification">
                <label className="plan-include-text">Your plan includes</label>

                <Grid columns={1} stackable>
                  <Grid.Row className="detail">
                    {map(subDetails.includes, (inc, i) => (
                      <div>
                        <label className={'orangeCorrectIcon'}>
                          &#10004;&nbsp;&nbsp;
                        </label>
                        <label className={'planList'}>{inc}</label>
                      </div>
                    ))}
                  </Grid.Row>
                </Grid>
              </div>
            ) : null}
            <Button
              type="submit"
              className="btn btn-primary signin-button "
              loading={postOrgLoading}
              disabled={postOrgLoading}
            >
              Submit
            </Button>
          </AvForm>
        </Container>
      </Grid.Row>
    );
  }
}

class Parent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      final: false,
      load: false,
    };
  }

  componentDidMount() {
    const { clearSignUpCache } = this.props;
    this.setState({ load: true }, () => {
      clearSignUpCache();
      this.setState({ load: false });
    });
  }

  setFinal() {
    const { final } = this.state;
    this.setState({
      final: !final,
    });
  }

  render() {
    const { final, load } = this.state;
    if (load) return null;
    return (
      <div className="parent-width">
        {final ? (
          <Grid>
            {' '}
            <SubscriptionDetails {...this.props} />
          </Grid>
        ) : (
          <Grid>
            {' '}
            <SignUp {...this.props} setFinal={() => this.setFinal()} />{' '}
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userSignupData: state.authentication.userSignupData,
    subscriptions: state.extra.subscriptions,
    organization: state.organization,
    postOrgLoading: state.admin.postOrgLoading,
    postSubOrgLoading: state.admin.postSubOrgLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signUpCache,
      clearSignUpCache,
      getSubscriptions,
      postSignup,
      postSignupRequest,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Parent);
