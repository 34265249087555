import { bindActionCreators } from 'redux';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { isMobile, isTablet } from 'react-device-detect';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Paragraph,
  List,
  Heading,
} from 'ckeditor5';
import moment from 'moment';
import React, { Component } from 'react';
import addIcon from '../../../../assets/add-icon.svg';

import { map } from '../../../../utils/lodash';
import {
  getHelpPages,
  postHelpPage,
  postHelpTips,
  updateHelpPage,
  getActivityHelpTips,
  putHelpTips,
} from '../../../../store/actions';
import {
  CustomAccordion,
  CustomTable,
  CustomTooltip,
} from '../../../../components';

class ParentHelpComponent extends Component {
  componentDidMount() {
    const { getHelpPages, getActivityHelpTips } = this.props;
    getHelpPages();
    getActivityHelpTips();
  }

  render() {
    return (
      <div>
        <Helps {...this.props} />

        <HelpList {...this.props} />
      </div>
    );
  }
}

export class Helps extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="outcome-admin-padded-help">
          <div className="add-help-page">
            <div className="add-page-right">
              <AddHelpPage {...this.props} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class AddHelpTips extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="small"
        trigger={
          <Button className="Secondary-Button">
            <Icon name="plus" />
            Add Help Tip
          </Button>
        }
        className="create-tip"
      >
        <Modal.Header>Create Tip</Modal.Header>
        <Modal.Content>
          <HelpTipsModal toggle={() => this.toggle()} {...this.props} />
        </Modal.Content>
      </Modal>
    );
  }
}

class AddHelpPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  render() {
    const { modal } = this.state;

    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="small"
        trigger={
          <Button className="Primary-Button">
            <div className="add-icon-activity-add">
              <img className="icons-add" src={addIcon} alt="icon" />
              <div className="add-text">Add Help Page</div>
            </div>
          </Button>
        }
        className="create-help-page"
      >
        <Modal.Header>Create Help Page</Modal.Header>
        <Modal.Content>
          <HelpPageFormPost toggle={() => this.toggle()} {...this.props} />
        </Modal.Content>
      </Modal>
    );
  }
}

class HelpPageFormPost extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
    };
  }

  async handleValidSubmit(event, values) {
    try {
      const { postHelpPage, toggle } = this.props;
      const { content } = this.state;

      const data = { ...values, content };

      await postHelpPage(data);
      toggle();
    } catch {}
  }

  render() {
    const { toggle, hPageLoading } = this.props;

    return (
      <AvForm
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="venue-form"
      >
        <AvField
          label="Title"
          className="org-inputs"
          name="title"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: 'Title is required',
            },
          }}
        />
        <label>Content</label>
        <CKEditor
          editor={ClassicEditor}
          data=""
          config={{
            plugins: [Essentials, Bold, Italic, List, Heading, Paragraph],
          }}
          onBlur={(event, editor) => {
            const content = editor.getData();
            this.setState({
              content,
            });
          }}
        />

        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            content="Save"
            type="submit"
            className="Primary-Button"
            loading={hPageLoading}
            disabled={hPageLoading}
          />
        </div>
      </AvForm>
    );
  }
}
class HelpTipsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  helpPageList() {
    const { helpPages } = this.props;
    return map(helpPages, (item, i) => {
      return (
        <option key={i} value={item.id}>
          {item.title}
        </option>
      );
    });
  }

  async handleValidSubmit(event, values) {
    try {
      const { postHelpTips, toggle } = this.props;
      values.content = values.contentTip;
      values.helpPageId = values.id;
      delete values.contentTip;
      delete values.id;

      await postHelpTips(values);
      toggle();
    } catch (error) {
      error.log('Submit', error);
    }
  }

  render() {
    const { toggle, hTipLoading, helpPageItem } = this.props;

    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="venue-form"
        model={helpPageItem}
      >
        <AvField
          label="Select Help Page"
          className="org-inputs"
          name="id"
          type="select"
          validate={{
            required: {
              value: true,
              errorMessage: 'Help Page is required',
            },
          }}
        >
          <option value="" disabled defaultValue>
            Select an Type
          </option>
          {this.helpPageList()}
        </AvField>

        <AvField
          label="Field Name"
          className="org-inputs"
          name="fieldName"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: 'Field Name is required',
            },
          }}
        />

        <AvField
          label="Content"
          className="org-inputs-textarea"
          name="contentTip"
          type="textarea"
          validate={{
            required: {
              value: true,
              errorMessage: 'Content is required',
            },
          }}
        />

        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            content="Create"
            type="submit"
            className="Primary-Button"
            loading={hTipLoading}
            disabled={hTipLoading}
          />
        </div>
      </AvForm>
    );
  }
}

export class HelpList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { helpPages } = this.props;
    return (
      <div className="help-list-container pb-3">
        {map(helpPages, (item, index) => {
          return (
            <HelpPageList key={index} helpPageItem={item} {...this.props} />
          );
        })}
      </div>
    );
  }
}

class HelpPageList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
  }
  render() {
    const { helpPageItem } = this.props;
    return (
      <div className="accordion-container">
        <div className="gap-between-windows-help">
          <CustomAccordion
            title={helpPageItem.title}
            children={<HelpTipsView {...this.props} />}
          />
        </div>
      </div>
    );
  }
}
class HelpTipsView extends React.PureComponent {
  renderColumns(activityHelpTips) {
    const columns = [
      {
        title: (
          <div className="user-act-heading-container-custom-feature">
            <strong>ID</strong>
          </div>
        ),
        render: (data) => {
          return <div>{data.id}</div>;
        },
        width: 1,
      },
      {
        title: (
          <div className="user-act-heading-container-custom-feature">
            <strong>Field name</strong>
          </div>
        ),
        render: (data) => {
          return <div>{data.fieldName}</div>;
        },
        width: 3,
      },
      {
        title: (
          <div className="user-act-heading-container-custom-feature">
            <strong>Created Date</strong>
          </div>
        ),
        render: (data) => {
          return <div>{moment(data.createdAt).format('ll hh:mm A')}</div>;
        },
        width: 2,
      },
      {
        title: (
          <div className="user-act-heading-container-custom-feature">
            <strong>Updated Date</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className="active-status-container">
              {moment(data.updatedAt).format('ll hh:mm A')}
            </div>
          );
        },
        width: 2,
      },
      {
        title: '',
        render: (data) => {
          return <EditHelpTip item={data} {...this.props} />;
        },
        width: 1,
      },
    ];

    if (!isMobile && !isTablet) {
      const contentColumn = {
        title: (
          <div className="user-act-heading-container-custom-feature">
            <strong>Content</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div>
              {data.content && data.content.length > 100 ? (
                <CustomTooltip
                  state={true}
                  contentObject={{
                    data: activityHelpTips,
                    key: data.id,
                  }}
                  position="top left"
                  wide="very"
                >
                  <span>{data.content.substring(0, 80) + '...'}</span>
                </CustomTooltip>
              ) : (
                data.content
              )}
            </div>
          );
        },
        width: 6,
      };

      columns.splice(1, 0, contentColumn); // added content column as second column if only desktop
    }

    return columns;
  }

  render() {
    let tips = [];
    const { activityHelpTips, helpPageItem } = this.props;

    return (
      <div className="view-accordion-com">
        <div className="scroll-accordion">
          {/* action buttons */}
          <div className="help-tip-right">
            <div className="margin-add-help-tip">
              <AddHelpTips helpPageItem={helpPageItem} {...this.props} />
            </div>
            <div className="margin-edit-help-page">
              <EditHelpPage helpPageItem={helpPageItem} {...this.props} />
            </div>
          </div>

          {/* list of help tips  */}
          {map(activityHelpTips, (item, i) => {
            if (helpPageItem.id !== item.helpPageId) return null;
            tips.push(item);
          })}
          <CustomTable
            header
            customClass="super-admin-table tbody-hover"
            columns={this.renderColumns(activityHelpTips)}
            data={tips}
            emptyMessage="No tooltips"
            keyExtractor={(item, index) => item.id}
          />
        </div>
      </div>
    );
  }
}

class EditHelpPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      form: {
        title: '',
      },
      content: '',
    };
  }

  editSelected(form) {
    this.setState({
      form,
      content: form.content,
    });
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async handleValidSubmit(event, values) {
    try {
      const { updateHelpPage, helpPageItem } = this.props;
      const { content } = this.state;
      const data = { ...values, content };

      await updateHelpPage(helpPageItem.id, data);
      this.toggle();
    } catch (error) {
      error.log('EditHelpPage ~ handleValidSubmit ~ error :', error);
    }
  }

  render() {
    const { modal, form, content } = this.state;
    const { helpPageItem, updatePageLoading } = this.props;

    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="small"
        trigger={
          <Button
            className="Secondary-Button"
            onClick={() => this.editSelected(helpPageItem)}
          >
            <Icon name="edit" />
            Edit Help Page
          </Button>
        }
        className="edit-help-page"
      >
        <Modal.Header>Edit Help Page</Modal.Header>
        <Modal.Content>
          <AvForm
            ref={(c) => (this.form = c)}
            onValidSubmit={(event, values) =>
              this.handleValidSubmit(event, values)
            }
            model={form}
            className="venue-form"
          >
            <AvField
              label="Title"
              className="org-inputs"
              name="title"
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Title is required',
                },
              }}
            ></AvField>

            <label>Content</label>
            <CKEditor
              name="content"
              editor={ClassicEditor}
              data={content}
              config={{
                plugins: [Essentials, Bold, Italic, List, Heading, Paragraph],
              }}
              onReady={(editor) => {}}
              onBlur={(event, editor) => {
                const content = editor.getData();
                this.setState({
                  content,
                });
              }}
            />

            <div className="model-buttons">
              <Button
                className="Secondary-Button"
                type="button"
                onClick={() => this.toggle()}
              >
                Cancel
              </Button>
              <Button
                content="Save"
                type="submit"
                className="Primary-Button"
                loading={updatePageLoading}
                disabled={updatePageLoading}
              />
            </div>
          </AvForm>
        </Modal.Content>
      </Modal>
    );
  }
}
class EditHelpTip extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      form: {
        angularState: '',
        fieldName: '',
        content: '',
      },
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  editSelected(form) {
    this.setState({
      form,
    });
  }

  async handleValidSubmit(event, values) {
    try {
      const { putHelpTips, item } = this.props;

      await putHelpTips(item.id, values);
      this.toggle();
    } catch (error) {
      error.log('EditHelpTip ~ handleValidSubmit ~ error :', error);
    }
  }

  render() {
    const { modal, form } = this.state;
    const { item, helpPages, updateTipLoading } = this.props;

    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="small"
        trigger={
          <label
            className="activity-actions-warn hover-btn mb-0 gap-icon"
            onClick={() => this.editSelected(item)}
          >
            Edit
          </label>
        }
        className="edit-help-tip"
      >
        <Modal.Header>Edit Help Tip</Modal.Header>
        <Modal.Content>
          <AvForm
            onValidSubmit={(event, values) =>
              this.handleValidSubmit(event, values)
            }
            className="venue-form"
            model={form}
          >
            <AvField
              label="Help Page Name"
              className="org-inputs"
              name="helpPageId"
              type="select"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Help page is required',
                },
              }}
            >
              <option value="" defaultValue disabled>
                Select type...
              </option>
              {map(helpPages, (help, i) => (
                <option key={i} value={help.id}>
                  {help.title}
                </option>
              ))}
            </AvField>

            <AvField
              label="Field name"
              className="org-inputs"
              name="fieldName"
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Field name is required',
                },
              }}
            />
            <AvField
              label="Content"
              className="org-inputs-textarea"
              name="content"
              type="textarea"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Content is required',
                },
              }}
            />

            <div className="model-buttons">
              <Button
                className="Secondary-Button"
                type="button"
                onClick={() => this.toggle()}
              >
                Cancel
              </Button>
              <Button
                content="Save"
                type="submit"
                className="Primary-Button"
                loading={updateTipLoading}
                disabled={updateTipLoading}
              />
            </div>
          </AvForm>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    helpPages: state.extra.helpPages,
    activityHelpTips: state.extra.activityHelpTips,
    hTipLoading: state.admin.hTipLoading,
    hPageLoading: state.admin.hPageLoading,
    updatePageLoading: state.admin.updatePageLoading,
    updateTipLoading: state.admin.updateTipLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHelpPages,
      postHelpPage,
      postHelpTips,
      updateHelpPage,
      getActivityHelpTips,
      putHelpTips,
    },
    dispatch
  );
};

ParentHelpComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ParentHelpComponent);

export { ParentHelpComponent };
