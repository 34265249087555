import './auth.scss';

import React from 'react';

import { Grid, Container } from 'semantic-ui-react';

import { SuccessContainer } from '../../components';
import { APP_NAME } from '../../utils/constant';

class AccountDisable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
    };
  }

  componentDidMount() {
    document.title = `Access | ${APP_NAME}`;
  }

  render() {
    return (
      <Grid.Row>
        <Container className="email-verification-container">
          <SuccessContainer
            msg={'Inactive account'}
            description={[
              <span>This account has been deactivated.</span>,
              <br />,
              <span>
                For more information or help, contact us{' '}
                <a
                  className="contactUs"
                  href="mailto:support@takso-outcomes.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  support@takso-outcomes.com
                </a>
              </span>,
            ]}
            success={false}
            linkExpired={false}
          />
        </Container>
      </Grid.Row>
    );
  }
}

export default AccountDisable;
