// import { createStore, applyMiddleware } from 'redux'; // compose is no needed
import { persistStore, persistReducer } from 'redux-persist';
import initSubscriber from 'redux-subscriber';
// import reduxReset from 'redux-reset';
import { thunk } from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { configureStore, Tuple } from '@reduxjs/toolkit';

import rootReducer from './reducer';

const persistConfig = {
  key: 'root',
  stateReconciler: autoMergeLevel2,
  storage,
  whitelist: ['authentication'], // only app will be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// // ------------- these are the actions -------------
export * from './reducer';

// const middleware = applyMiddleware(thunk);

let store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: () => new Tuple(thunk),
  // middleware: getDefaultMiddleware => getDefaultMiddleware(thunk),
});

let persistor = persistStore(store);
initSubscriber(store);
export { store, persistor };
