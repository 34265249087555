import React from 'react';
import { Grid, Tab } from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';
import { find } from '../../../utils/lodash';
import { APP_NAME, ADMIN_FEATURES } from '../../../utils/constant';
import { CustomSegment } from '../../../components';
import '../admin.scss';
// import { useSelector } from 'react-redux';
// import { exportSystemUsers } from '../../../store/actions';
import { Features, PermissionGroups } from './forms';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SystemFeatures = ({ match, exportSystemUsers, usersCsvLink }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const navigate = useNavigate();

  // const dispatch = useDispatch();
  // const auth = useSelector(state => state.authentication);
  // const user = useSelector(state => state.authentication.user);
  // const helpTips = useSelector(state => state.extra.activityHelpTips);

  useEffect(() => {
    try {
      if (match.params.type) {
        changeRoute(match.params.type);
      }
    } catch (error) {
      console.error(error);
    }
  }, [match.params.type]);

  useEffect(() => {
    document.title = `Features | ${APP_NAME}`;
  }, []);

  const generateTabs = () => {
    const panes = [
      {
        menuItem: 'Features',
        render: () => (
          <Tab.Pane attached={false} className="custom-tab">
            <CustomSegment title="Features" children={<Features />} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Permission Groups',
        render: () => (
          <Tab.Pane attached={false} className="custom-tab">
            <CustomSegment
              title="Permission Groups"
              children={<PermissionGroups />}
            />
          </Tab.Pane>
        ),
      },
    ];

    return panes;
  };

  const onTabClick = (data) => {
    changeRoute(data.activeIndex);
  };

  // const onClickUsersExport = async () => {
  //   await exportSystemUsers();
  //   if (!isEmpty(usersCsvLink)) {
  //     window.open(usersCsvLink.download_link, '_blank');
  //   }
  // };

  const changeRoute = async (value) => {
    try {
      const tab = await find(ADMIN_FEATURES, (o) => {
        return o.id === value || o.route === value;
      });
      setCurrentTab(tab.id);
      navigate(`/system-features/${tab.route}`);
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <Grid.Row>
      <Tab
        menu={{ borderless: true, attached: false, tabular: false }}
        panes={generateTabs()}
        className={isMobile ? 'custom-tabs-mobile' : 'custom-tabs'}
        activeIndex={currentTab}
        onTabChange={(e, data) => onTabClick(data)}
      />
    </Grid.Row>
  );
};

export default SystemFeatures;
