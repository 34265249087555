/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import LinkedActivityForm from '../forms/linkedActivityForm';
import '../../../activity.scss';
import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { get } from '../../../../../../utils/lodash';

export default function EditLinkedActivity(props) {
  const { activity, trigger } = props;
  const [viewModal, setViewModal] = useState(false);

  const handelViewToggle = () => {
    setViewModal(!viewModal);
  };

  const parentActivityPlanTypeId = get(
    activity,
    'parentActivity.activityPlanTypeId',
    null
  );
  const activityPlanTypeId = get(activity, 'activityPlanTypeId', null);

  return (
    <Modal
      trigger={<div onClick={() => handelViewToggle()}>{trigger}</div>}
      size="small"
      open={viewModal}
    >
      <Modal.Header className="goal-confirm-modal-header">
        <div className="modal-heading"> {activity.name} </div>
        <div className="slim-close-icon" onClick={() => handelViewToggle()}>
          &times;
        </div>
      </Modal.Header>
      <Modal.Content>
        <LinkedActivityForm
          activityPlanTypeId={activityPlanTypeId}
          parentActivityPlanTypeId={parentActivityPlanTypeId}
          toggle={handelViewToggle}
          {...props}
        />
      </Modal.Content>
    </Modal>
  );
}
