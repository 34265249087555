import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Segment, Radio } from 'semantic-ui-react';
import React from 'react';

import { CustomTooltip } from '../../../components';
import { getPageDisable } from '../../../utils';
import { get } from '../../../utils/lodash';
import { putActivity } from '../../../store/actions';

import './activity.scss';
import ProjectText from '../../../components/projectText';

class LinkedSettings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      library: false,
    };
  }

  componentDidMount() {
    const { currentActivity } = this.props;
    this.setState({
      library: currentActivity.inLibrary,
    });
  }

  libraryToggle = () => {
    const { putActivity } = this.props;
    this.setState(
      (prevState) => ({
        library: !prevState.library,
      }),
      () => {
        putActivity('linkedSettings', {
          inLibrary: this.state.library,
        });
      }
    );
  };

  render() {
    const { library } = this.state;
    const { currentActivity, currentState, user, actPut, helpTips } =
      this.props;
    return (
      <Segment
        loading={actPut['linkedSettings']}
        disabled={getPageDisable(currentActivity, user, currentState, true)}
        className="content-segment"
        attached
      >
        <div className="linked-activity-toggle-container">
          <Radio toggle checked={library} onChange={this.libraryToggle} />
          <CustomTooltip
            state={get(user, 'options.tips')}
            contentObject={{ data: helpTips, key: '111' }}
            position="top left"
          >
            <label>
              {' '}
              <ProjectText
                defaultText="Make visible in the Takso Library"
                textId={9}
              />{' '}
            </label>
          </CustomTooltip>
        </div>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    user: state.authentication.user,
    helpTips: state.extra.activityHelpTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      putActivity,
    },
    dispatch
  );
};

LinkedSettings = connect(mapStateToProps, mapDispatchToProps)(LinkedSettings);

export { LinkedSettings };
