import React from 'react';
import { removeEditable } from '../utils';

export function ShowEvaluationQuestion({ question, index }) {
  const questioner = {
    __html: question
      ? removeEditable(`          
                  ${index === 0 ? question.methodHeading || '' : ''}
                  ${question.heading || ''}<br/>
                  ${question.question1 || ''}<br/>
                  ${
                    question.question2
                      ? `${question.question2} <br/> Can you tell us more about why you gave these two ratings?`
                      : 'Can you tell us more about why you gave this rating?'
                  }
      `)
      : null,
  };

  return (
    <div
      className="show-evaluation-questions"
      dangerouslySetInnerHTML={questioner}
    />
  );
}
