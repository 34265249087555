import * as Yup from 'yup';
import {
  LOWERCASE_REG_EX,
  NUMERIC_REG_EX,
  SPECIAL_CHARACTER_REG_EX,
  UPPERCASE_REG_EX,
} from '../utils/regex';

export const changePasswordInitialValues = {
  password: '',
  confirmPassword: '',
};

export const changePasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'LENGTH_ERROR')
    .required('LENGTH_ERROR')
    .matches(LOWERCASE_REG_EX, 'LOWERCASE_ERROR')
    .matches(UPPERCASE_REG_EX, 'UPPERCASE_ERROR')
    .matches(NUMERIC_REG_EX, 'NUMERIC_ERROR')
    .matches(SPECIAL_CHARACTER_REG_EX, 'SPECIAL_CHARACTER_ERROR'),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password'), null], "Doesn't match the new password"),
});
