/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { useField } from 'formik';
import { compact, find, flatten, map } from 'lodash';
import { Dropdown } from 'semantic-ui-react';
import getMinifiedText from '../../../utils/minifiedText';

export const CustomOptionGroupDropDown = ({
  labelText,
  renderHeaders,
  renderItems,
  onChange = null,
  requiredStar,
  formValues = null,
  resetTextByFormVal = null,
  // disabled,
  ...props
}) => {
  const [, meta, helpers] = useField({ ...props });
  const { setValue } = helpers;
  const [text, setText] = useState();
  const { value } = meta;

  useEffect(() => {
    getDefaultValue();
  }, []);

  useEffect(() => {
    if (
      formValues &&
      resetTextByFormVal &&
      formValues[resetTextByFormVal] === value
    ) {
      setText(null);
      setValue('');
    }
  }, [formValues]);

  const getDefaultValue = () => {
    map(renderItems, (items) => {
      map(items, (item) => {
        if (item.value === value) {
          setText(item.text);
        }
      });
    });
  };

  renderHeaders = renderHeaders.filter((item) =>
    compact(flatten(renderItems)).find(
      (data) => Number(data.rest.key) === Number(item.id)
    )
  );

  const optionList = (id) => {
    return find(renderItems, function (items) {
      return (
        items && items.length > 0 && items[0].rest && items[0].rest.key === id
      );
    });
  };

  const onChangeHandler = (value) => {
    onChange && onChange(value);
  };

  return (
    <div className="formik-custom-field act-inputs-new">
      <label
        className={requiredStar ? 'required-stars' : ''}
        htmlFor={props.id || props.name}
      >
        {labelText}
      </label>
      {/* {disabled ? (
        <>
          <br />
          <label>{text}</label>
        </>
      ) : ( */}
      <Dropdown
        text={text ? getMinifiedText(text) : props.placeholder}
        fluid
        // selection
        {...props}
        className="dropdown-outline"
        error={meta.touched && meta.error}
      >
        <Dropdown.Menu className="w-100 mt-1 scrolling">
          {map(renderHeaders, (headers, index) => {
            return (
              <Fragment key={index}>
                {index > 0 && <Dropdown.Divider />}
                <Dropdown.Header
                  className="dropdown-header"
                  content={headers.content}
                />
                {map(optionList(headers.id), (item, i) => {
                  return (
                    <Dropdown.Item
                      className="sub-header"
                      id={item.value}
                      key={item.value}
                      text={item.text}
                      value={item.value}
                      onClick={(e, data) => {
                        onChangeHandler(item.value);
                        setText(data.text);
                        setValue(data.value);
                      }}
                    />
                  );
                })}
              </Fragment>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
      {/* )} */}
      {meta.touched && meta.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
    </div>
  );
};
