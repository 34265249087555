import * as Yup from 'yup';
import { PHONE_REG_EX } from '../utils/regex';

export const getProfileDetailsInitialValues = (user) => {
  return {
    firstName: user && user.firstName ? user.firstName : '',
    email: user && user.email ? user.email : '',
    positionTitle: user && user.positionTitle ? user.positionTitle : '',
    surname: user && user.surname ? user.surname : '',
    phone: user && user.phone ? user.phone : '',
    department: user && user.department ? user.department.name : '',
    unit: user && user.departmentUnit ? user.departmentUnit.name : '',
  };
};

export const profileDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required!'),
  email: Yup.string().required('Email is required!'),
  positionTitle: Yup.string(),
  surname: Yup.string().required('Surname is required'),
  phone: Yup.string()
    .required('Number is required')
    .matches(PHONE_REG_EX, 'Phone number is not valid!')
    .min(10, 'Must be a minimum of 10 digits!'),
});
