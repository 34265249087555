import axios from 'axios';
import { authMsg, getCommonError } from '../../utils';

/*** TYPES ***/

const GET_POLICIES = 'GET_POLICIES';
const GET_OUTCOMES = 'GET_OUTCOMES';
const GET_EVENT_TYPES = 'GET_EVENT_TYPES';
const GET_OUTPUT_FORMAT_TYPES = 'GET_OUTPUT_FORMAT_TYPES';
const GET_EVENT_DETAILS = 'GET_EVENT_DETAILS';
const GET_MEASURES = 'GET_MEASURES';
const GET_METHODS = 'GET_METHODS';
const GET_TYPES = 'GET_TYPES';
const GET_STATES = 'GET_STATES';
const GET_SECTOR_TYPES = 'GET_SECTOR_TYPES';
const GET_ORG_VENUE_TYPES = 'SET_ORG_VENUE_TYPES';
const GET_OCCUPATION_TYPES = 'GET_OCCUPATION_TYPES';
const GET_OCCUPATION = 'GET_OCCUPATION';
const GET_EVIDENCE_TYPES = 'GET_EVIDENCE_TYPES';
const GET_EVALUATION_ASSESMENT_TYPES = 'GET_EVALUATION_ASSESMENT_TYPES';
const GET_EVALUATION_REASONS = 'GET_EVALUATION_REASONS';
const GET_HELP_TIPS = 'GET_HELP_TIPS';
const GET_HELP_PAGES = 'GET_HELP_PAGES';
const GET_GENERATE_QUESTION = 'GET_GENERATE_QUESTION';
const GET_ACTIVITY_PLAN_TYPES = 'GET_ACTIVITY_PLAN_TYPES';
const GET_METHOD_QUESTIONS_LOADING = 'GET_METHOD_QUESTIONS_LOADING';
const SET_METHOD_QUESTIONS = 'SET_METHOD_QUESTIONS';
const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
const GET_PAYMENT_PREFERENCE = 'GET_PAYMENT_PREFERENCE';
const GET_FESTIVAL_TYPES = 'GET_FESTIVAL_TYPES';
const GET_VENUE_TYPES = 'GET_VENUE_TYPES';
const SET_CURRENCY_TYPES = 'SET_CURRENCY_TYPES';

/*** REDUCERS ***/

const initialState = {
  policies: [],
  outcomes: [],
  eventTypes: [],
  eventDetails: [],
  measures: [],
  methods: [],
  types: [],
  australianStates: [],
  industrySectorTypes: [],
  orgVenueTypes: [],
  occupationTypes: [],
  occupations: [],
  evidenceTypes: [],
  activityHelpTips: [],
  helpPages: [],
  evalAssesmentTypes: [],
  evalReasons: [],
  generatedQuestion: {},
  actPlanTypes: [],
  surveyQuestions: [],
  surveyQuestionsLoading: false,
  subscriptions: [],
  paymentPreferences: [],
  festivalTypes: [],
  venueTypes: [],
  outputFormatItemTypes: [],
  currencyTypes: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_POLICIES:
      return { ...state, policies: action.payload };
    case GET_OUTCOMES:
      return { ...state, outcomes: action.payload };
    case GET_EVENT_TYPES:
      return { ...state, eventTypes: action.payload };
    case GET_EVENT_DETAILS:
      return { ...state, eventDetails: action.payload };
    case GET_MEASURES:
      return { ...state, measures: action.payload };
    case GET_METHODS:
      return { ...state, methods: action.payload };
    case GET_TYPES:
      return { ...state, types: action.payload };
    case GET_STATES:
      return { ...state, australianStates: action.payload };
    case GET_SECTOR_TYPES:
      return { ...state, industrySectorTypes: action.payload };
    case GET_ORG_VENUE_TYPES:
      return { ...state, orgVenueTypes: action.payload };
    case GET_OCCUPATION_TYPES:
      return { ...state, occupationTypes: action.payload };
    case GET_OCCUPATION:
      return { ...state, occupations: action.payload };
    case GET_EVIDENCE_TYPES:
      return { ...state, evidenceTypes: action.payload };
    case GET_HELP_TIPS:
      return { ...state, activityHelpTips: action.payload };
    case GET_HELP_PAGES:
      return { ...state, helpPages: action.payload };
    case GET_EVALUATION_ASSESMENT_TYPES:
      return { ...state, evalAssesmentTypes: action.payload };
    case GET_EVALUATION_REASONS:
      return { ...state, evalReasons: action.payload };
    case GET_GENERATE_QUESTION:
      return { ...state, generatedQuestion: action.payload };
    case GET_ACTIVITY_PLAN_TYPES:
      return { ...state, actPlanTypes: action.payload };
    case GET_METHOD_QUESTIONS_LOADING:
      return { ...state, surveyQuestionsLoading: action.payload };
    case SET_METHOD_QUESTIONS:
      return { ...state, surveyQuestions: action.payload };
    case GET_SUBSCRIPTIONS:
      return { ...state, subscriptions: action.payload };
    case GET_PAYMENT_PREFERENCE:
      return { ...state, paymentPreferences: action.payload };
    case GET_FESTIVAL_TYPES:
      return { ...state, festivalTypes: action.payload };
    case GET_VENUE_TYPES:
      return { ...state, venueTypes: action.payload };
    case GET_OUTPUT_FORMAT_TYPES:
      return { ...state, outputFormatItemTypes: action.payload };
    case SET_CURRENCY_TYPES:
      return { ...state, currencyTypes: action.payload };

    default:
      return state;
  }
}

export function getEvalAssesmentTypes() {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `/enum/project/evaluation-assessment-types`
      );
      dispatch({
        type: GET_EVALUATION_ASSESMENT_TYPES,
        payload: response.data,
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getEvalReasons() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/enum/project/evaluation-reasons`);
      dispatch({ type: GET_EVALUATION_REASONS, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getPaymentPreferences() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/enum/project/payment-preferences`);
      dispatch({ type: GET_PAYMENT_PREFERENCE, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getPolicies() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/enum/project/policies`);
      dispatch({ type: GET_POLICIES, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOutcomes() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/enum/project/outcomes`);
      dispatch({ type: GET_OUTCOMES, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getEventDetails(props) {
  return async (dispatch) => {
    try {
      let params = {};
      if (props) {
        params.all = props.all;
      }
      const response = await axios.get(`/enum/project/event-details`, {
        params,
      });
      dispatch({ type: GET_EVENT_DETAILS, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getEventTypes() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/enum/project/event-types`);
      dispatch({ type: GET_EVENT_TYPES, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOutputFormatItemTypes() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/enum/output-formatters`);
      dispatch({ type: GET_OUTPUT_FORMAT_TYPES, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getVenueTypes() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/enum/project/venue-types`);
      dispatch({ type: GET_VENUE_TYPES, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getMethods() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/enum/project/methods`);
      dispatch({ type: GET_METHODS, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function setMethods(methods) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_METHODS, payload: methods });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getAustralianStates() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/enum/states`);
      dispatch({ type: GET_STATES, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getIndustrySectorTypes() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/enum/industry-sector-types`);
      dispatch({ type: GET_SECTOR_TYPES, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrgVenuesTypes() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/enum/venue-types`);
      dispatch({ type: GET_ORG_VENUE_TYPES, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOccupationTypes() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/enum/occupation-types`);
      dispatch({ type: GET_OCCUPATION_TYPES, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOccupation() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/enum/occupations`);
      dispatch({ type: GET_OCCUPATION, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getEvidenceTypes() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/enum/evidence/types`);
      dispatch({ type: GET_EVIDENCE_TYPES, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getActivityHelpTips() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/enum/help-tips`);
      dispatch({ type: GET_HELP_TIPS, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getHelpPages() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/enum/help-pages`);
      dispatch({ type: GET_HELP_PAGES, payload: response.data });
    } catch (error) {
      // authMsg('error', getCommonError(error));
    }
  };
}

export function getSubscriptions() {
  return async (dispatch) => {
    try {
      const response = await axios.get('/enum/subscriptions');
      dispatch({ type: GET_SUBSCRIPTIONS, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function generateQuestion(params) {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(`/enum/eval/question`, { params });
      dispatch({ type: GET_GENERATE_QUESTION, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function activityPlanTypes() {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(`/enum/activity-plan-types`);
      dispatch({ type: GET_ACTIVITY_PLAN_TYPES, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function festivalTypes() {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(`/enum/festival-types`);
      dispatch({ type: GET_FESTIVAL_TYPES, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getMethodQuestions(methodId = null, outcomeId = []) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_METHOD_QUESTIONS_LOADING, payload: true });
      const response = await axios.post('/enum/eval/questions', {
        methodId,
        outcomeId,
      });
      dispatch({ type: SET_METHOD_QUESTIONS, payload: response.data });
      dispatch({ type: GET_METHOD_QUESTIONS_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: GET_METHOD_QUESTIONS_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function clearMethodQuestions() {
  return async (dispatch) => {
    dispatch({ type: SET_METHOD_QUESTIONS, payload: [] });
  };
}

export function getCurrencyTypes() {
  return async (dispatch) => {
    const response = await axios.get('/enum/currency-types');

    dispatch({ type: SET_CURRENCY_TYPES, payload: response.data });
  };
}
