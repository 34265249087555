import React from 'react';
import { Message } from 'semantic-ui-react';
import ShowMoreText from 'react-show-more-text';
import { threeDotSeparator } from '../utils';
import './styles.scss';

export const NotificationMessage = ({
  title,
  message,
  width,
  classNames,
  updateNotificationReadStatus = null,
}) => {
  return (
    <div
      className={`notify-message pre-line ${classNames ? classNames : ''}`}
      style={{
        width: width ? width : '100%',
        margin: '0rem',
      }}
    >
      <Message
        negative
        onDismiss={
          updateNotificationReadStatus !== null
            ? () => {
                updateNotificationReadStatus();
              }
            : false
        }
      >
        <div>
          <div className="notification-title">
            {threeDotSeparator({
              name: title,
              length: 52,
              isPopUp: false,
            })}
          </div>

          <ShowMoreText
            lines={2}
            more="Read More"
            less="Read Less"
            anchorClass="show-more-less-clickable"
            expanded={false}
            truncatedEndingComponent={'... '}
            className="pre-line"
          >
            <p>{message}</p>
          </ShowMoreText>
        </div>
      </Message>
    </div>
  );
};
