import './account.scss';
import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import {
  Button,
  Grid,
  Icon,
  Menu,
  Message,
  Modal,
  Popup,
  Tab,
} from 'semantic-ui-react';
import {
  getSubscriptions,
  getEmptyMessage,
  exportIndividualProject,
} from '../../store/actions';
import { CustomSegment, CustomTooltip, FeatureWrapper } from '../../components';
import { find, isEmpty, get, compact, findIndex } from '../../utils/lodash';
import { APP_NAME } from '../../utils/constant';
import {
  OrganizationChecklistReport,
  OrganizationGLApproval,
  OrganizationGoals,
  OrganizationProfile,
} from '../organization/forms';
import { Invoices, Subscription } from './forms';
import { isFeature } from '../../utils/activity/userPermission';
import { useDispatch } from 'react-redux';

class ExportData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async exportProjects() {
    const { user } = this.props;
    await this.props.exportIndividualProject();
    const { individualProjectExport } = this.props;

    if (!isEmpty(individualProjectExport)) {
      window.open(individualProjectExport.download_link, '_blank');
    }
    this.toggle();
  }

  render() {
    const { modal } = this.state;
    return (
      <Modal
        trigger={
          <Button
            icon="download"
            className="account-export"
            content="Export"
            onClick={() => this.toggle()}
          />
        }
        size="tiny"
        open={modal}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
      >
        <Modal.Header>Export my data</Modal.Header>
        <Modal.Content>
          <label className="billing-info">
            All your activity data will be emailed
          </label>
          <div className="model-buttons">
            <Button
              className="billing-cancel"
              type="button"
              onClick={() => this.toggle()}
            >
              Cancel
            </Button>
            <Button
              loading={this.props.individualProjectExportLoading}
              disabled={this.props.individualProjectExportLoading}
              onClick={() => this.exportProjects()}
              content="Export"
              className="billing"
            />
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

const Account = (props) => {
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(0);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const auth = useSelector((state) => state.authentication);
  const user = useSelector((state) => state.authentication.user);
  const emptyMessage = useSelector((state) => state.organization.emptyMessage);

  const goalEmpty = get(emptyMessage, 'goalEmpty', false);
  const helpTips = useSelector((state) => state.extra.activityHelpTips);

  const changeRoute = async (value) => {
    try {
      const tabs = generateTabs();
      const [tab, index] = await Promise.all([
        find(tabs, (o, i) => {
          return i === value || o.route === value;
        }),
        findIndex(tabs, (o, i) => {
          return i === value || o.route === value;
        }),
      ]);

      if (tab) {
        setCurrentTab(index);
        navigate(`/account/${tab.route}`);
      } else {
        setCurrentTab((state) => ({
          currentTab: state.prevState,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    try {
      const { match } = props;
      if (match.params.type) {
        changeRoute(match.params.type);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    document.title = `Account | ${APP_NAME}`;

    dispatch(getSubscriptions());
    dispatch(getEmptyMessage());
  }, []);

  const cardAlert = (visible) => {
    setVisible(visible);
  };

  const generateTabs = () => {
    const panes = compact([
      isFeature(user, 'account_profile')
        ? {
            menuItem: (
              <Menu.Item>
                <CustomTooltip
                  state={get(user, 'options.tips')}
                  //TODO: change account help tip here
                  contentObject={{ data: helpTips, key: '194' }}
                  position="top left"
                  wide="very"
                >
                  <label>Profile</label>
                </CustomTooltip>
              </Menu.Item>
            ),
            route: 'profile',
            render: () => (
              <Tab.Pane attached={false} className="custom-tab">
                <CustomSegment
                  title="UPDATE ACCOUNT DETAILS"
                  children={<OrganizationProfile account />}
                  className="update-organisation-details"
                />
              </Tab.Pane>
            ),
          }
        : null,
      isFeature(user, 'account_settings')
        ? {
            menuItem: (
              <Menu.Item>
                <CustomTooltip
                  state={get(user, 'options.tips')}
                  //TODO: change account help tip here
                  contentObject={{ data: helpTips, key: '196' }}
                  position="top left"
                  wide="very"
                >
                  <label>Settings</label>
                </CustomTooltip>
              </Menu.Item>
            ),
            route: 'settings',
            render: () => (
              <Tab.Pane attached={false} className="custom-tab">
                <CustomSegment
                  title="ACCOUNT SETTINGS"
                  extraMargin
                  children={<OrganizationGLApproval account />}
                  className="update-organisation-details"
                />
                <FeatureWrapper
                  featureName="account_checklist"
                  children={
                    <div>
                      <CustomSegment
                        tooltip={{
                          user,
                          helpTips,
                          id: '172',
                        }}
                        extraMargin
                        title="Checklist"
                        children={<OrganizationChecklistReport account />}
                      />
                    </div>
                  }
                />
              </Tab.Pane>
            ),
          }
        : null,
      isFeature(user, 'account_billing')
        ? {
            menuItem: (
              <Menu.Item>
                <CustomTooltip
                  state={get(user, 'options.tips')}
                  //TODO: change account help tip here
                  contentObject={{ data: helpTips, key: '205' }}
                  position="top left"
                  wide="very"
                >
                  <label>Billing</label>
                </CustomTooltip>
              </Menu.Item>
            ),
            route: 'billing',
            render: () => (
              <Tab.Pane attached={false} className="custom-tab">
                {visible ? (
                  <Message
                    className="card-notification"
                    onDismiss={() => cardAlert(false)}
                    header="Add your card first"
                    content="You need to add a card before add a new subscription plan. Click the Add Card button to start your subscription."
                  />
                ) : null}
                <CustomSegment
                  title="SUBSCRIPTION"
                  children={
                    <Subscription cardAlert={(visible) => cardAlert(visible)} />
                  }
                  rightHeader={<ExportData {...props} />}
                  className="update-organisation-details"
                />
                <CustomSegment
                  extraMargin
                  title="INVOICES"
                  children={
                    <Invoices cardAlert={(visible) => cardAlert(visible)} />
                  }
                />
              </Tab.Pane>
            ),
          }
        : null,
      isFeature(user, 'account_goals')
        ? {
            menuItem: (
              <Menu.Item>
                <div className="goal-tab-container">
                  {goalEmpty ? (
                    <Popup
                      position="bottom center"
                      content={<div>Goals are not yet created.</div>}
                      trigger={
                        <Icon
                          name="warning circle"
                          color="red"
                          size="large"
                        ></Icon>
                      }
                    />
                  ) : null}
                  <CustomTooltip
                    state={get(user, 'options.tips')}
                    //TODO: change account help tip here
                    contentObject={{
                      data: helpTips,
                      key: '206',
                    }}
                    position="top left"
                    wide="very"
                  >
                    <label>Goals</label>
                  </CustomTooltip>
                </div>
              </Menu.Item>
            ),
            route: 'goals',
            render: () => (
              <Tab.Pane attached={false} className="custom-tab">
                {/* <CustomSegment
                  title="SET ACCOUNT GOALS"
                  children={<OrganizationGoals account />}
                  className="ml-0 pl-0"
                  // className="update-organisation-details"
                /> */}
                <OrganizationGoals account />
              </Tab.Pane>
            ),
          }
        : null,
    ]);
    return panes;
  };

  const onTabClick = (data) => {
    changeRoute(data.activeIndex);
  };

  return (
    <Grid.Row>
      <Tab
        menu={{ borderless: true, attached: false, tabular: false }}
        panes={generateTabs()}
        className={isMobile ? 'custom-tabs-mobile' : 'custom-tabs'}
        activeIndex={currentTab}
        onTabChange={(e, data) => onTabClick(data)}
      />
    </Grid.Row>
  );
};

export default Account;
