import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isFeature } from '../utils/activity/userPermission';

class FeatureWrapper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      user,
      featureName,
      children,
      fallbackComponent = null,
      currentActivity,
    } = this.props;

    return isFeature(user, featureName, currentActivity)
      ? children
      : fallbackComponent
        ? fallbackComponent
        : null;
  }
}

const mapStateToProps = (state) => {
  const { authentication, activity } = state;
  return {
    user: authentication.user,
    currentActivity: activity.currentActivity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

FeatureWrapper = connect(mapStateToProps, mapDispatchToProps)(FeatureWrapper);

export { FeatureWrapper };
