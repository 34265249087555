/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { FieldArray, useField } from 'formik';
import { isEmpty, size } from 'lodash';
import { Button, Dropdown } from 'semantic-ui-react';
import { SeeMore } from '../../customToggle';
import getMinifiedText from '../../../utils/minifiedText';

export const CustomMultiSelectDropdown = ({
  labelText = '',
  options = [],
  placeholder = '',
  setFieldValue,
  values,
  defaultValuesTexts, // passing default values from parent; data has different keys
  setDisabledOtherFields = null,
  requiredStar = false,
  pointing,
  disabled = false,
  isView = false, // if this true only show default values in list
  loading = false,
  specificGoalSelected = false,
  ...props
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [, meta, helpers] = useField({ ...props });
  const { setTouched } = helpers;
  const [selectedList, setSelectedList] = React.useState([]);

  useEffect(() => {
    if (specificGoalSelected) {
      resetSelectedValues();
    }
    defaultValuesTexts && setDefaultValuesTexts();

    return () => {
      setSelectedList([]);
    };
  }, []);

  useEffect(() => {
    if (
      values[props.name] &&
      values[props.name].length > 0 &&
      loading &&
      !isView
    ) {
      setFieldValue(values[props.name], []);
    }
    if (!isEmpty(selectedList) && loading && !isView) {
      setSelectedList([]);
    }
  }, [loading]);

  const setDefaultValuesTexts = () => {
    if (defaultValuesTexts && !isEmpty(defaultValuesTexts)) {
      setSelectedList(defaultValuesTexts);
    }
  };

  const onChangeHandler = (e, item, arrayHelpers) => {
    let _selectedList = [...selectedList]; // Create a copy of the selected values array

    if (e.target.checked) {
      arrayHelpers.push(item.value);
      _selectedList.push(item.text);
    } else {
      const idx = values[props.name].indexOf(item.value);
      arrayHelpers.remove(idx);
      _selectedList = _selectedList.filter((obj) => {
        return obj.trim() !== item.text.trim();
      });
    }
    setSelectedList(_selectedList); // Update the selected values state
  };

  const resetSelectedValues = () => {
    setSelectedList([]);
    setFieldValue(props.name, []);
  };

  const isOptionListAvailable = !isEmpty(options);

  return (
    <div className="formik-custom-multiple-field act-inputs-new">
      <label
        className={requiredStar ? 'required-stars' : ''}
        htmlFor={props.id || props.name}
      >
        {labelText}
      </label>
      <>
        {!isView && (
          <Dropdown
            pointing={pointing}
            open={isDropdownOpen}
            // allowAdditions
            multiple
            // autoClose={false}
            closeOnChange={false}
            closeOnBlur={false}
            fluid
            // selection
            text={
              isEmpty(selectedList)
                ? getMinifiedText(placeholder)
                : `${size(selectedList)} selected`
            }
            error={meta.touched && meta.error}
            onOpen={() => {
              setIsDropdownOpen(true);
              setDisabledOtherFields && setDisabledOtherFields(true);
            }}
            loading={loading}
            disabled={
              isDropdownOpen
                ? false
                : disabled || loading || !isOptionListAvailable
            }
            className="dropdown-outline"
            {...props}
          >
            <Dropdown.Menu className="scrolling">
              {isOptionListAvailable ? (
                <>
                  <FieldArray
                    name={props.name}
                    render={(arrayHelpers) => (
                      <div>
                        {options.map((item, index) => (
                          <Dropdown.Item
                            key={index}
                            className="sub-header-checkbox"
                          >
                            <label key={item.value}>
                              <input
                                name={props.name}
                                type="checkbox"
                                value={item.value}
                                checked={
                                  values[props.name] &&
                                  values[props.name].length > 0 &&
                                  values[props.name].includes(item.value)
                                }
                                onChange={(e) =>
                                  onChangeHandler(e, item, arrayHelpers)
                                }
                              />
                              <span className="ml-2">{item.text}</span>
                            </label>
                          </Dropdown.Item>
                        ))}
                      </div>
                    )}
                  />
                </>
              ) : (
                <span className=" text-empty-info">
                  No results available for {labelText}
                </span>
              )}
              <div className="d-flex">
                <div className="ml-auto p-2 mb-3">
                  <Button
                    type="button"
                    className="Primary-Button"
                    onClick={() => {
                      setIsDropdownOpen(false);
                      setTouched(true);
                      setDisabledOtherFields && setDisabledOtherFields(false);
                    }}
                  >
                    {'Save'}
                  </Button>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}

        {meta.touched && meta.error && !isView ? (
          <div className="formik-multiple-dropdown-error">{meta.error}</div>
        ) : !isEmpty(selectedList) && !isView ? (
          <>
            <ul className="custom-multiple-checkbox-ul">
              {selectedList.length < 4 ? (
                selectedList.map((item) => <li>{item}</li>)
              ) : (
                <SeeMore
                  minCount={3}
                  dataSet={selectedList}
                  CustomComponent={(props) => <li>{props.item}</li>}
                  isView={false}
                />
              )}
            </ul>
          </>
        ) : (
          isView &&
          !isEmpty(defaultValuesTexts) && (
            <ul className="custom-multiple-checkbox-ul">
              {defaultValuesTexts.length < 4 ? (
                defaultValuesTexts.map((item, idx) => <li key={idx}>{item}</li>)
              ) : (
                <SeeMore
                  minCount={3}
                  dataSet={defaultValuesTexts}
                  CustomComponent={(props) => <li>{props.item}</li>}
                  isView={false}
                />
              )}
            </ul>
          )
        )}
      </>
      {!isOptionListAvailable && !meta.touched && !meta.error && (
        <span className="text-empty-info">
          No results available for {labelText.toLowerCase()}
        </span>
      )}
    </div>
  );
};
