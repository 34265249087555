import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Segment, Dropdown } from 'semantic-ui-react';
import moment from 'moment';
import React from 'react';
import thousands from 'thousands';
import DateRangeChart from '../../../components/dateRangeChart';
import {
  adminActivitySummary,
  adminAllChartData,
  adminSystemOverview,
  getIndustrySectorTypes,
  adminHeadlineData,
  getOutcomeSummaryGoals,
  getOutcomeSummaryReferences,
} from '../../../store/actions';
import { isEmpty, find } from '../../../utils/lodash';
import history from '../../../utils/history';

import '../../reports/reportHome/report.scss';
import { OutcomesSummary } from '../../reports/reportHome';
import { SystemOverview } from './reports';
import { getReportYears } from '../../../utils';
import ManualOutcomesSummary from './reports/manualOutcomesSummary';
const REPORT_TYPES = [
  'activity-summary',
  'outcome-summary',
  'headline-data',
  'system-overview',
];

const financialYearOptions = [
  {
    key: 1,
    text: 'Financial Year',
    value: 'financialYear',
    content: (
      <div className="financial-flex">
        <div className="title-fin">Financial Year</div>
        <div className="sub-fin">July 1 to June 30 Following Year</div>
      </div>
    ),
  },
  {
    key: 2,
    text: 'Calendar Year',
    content: (
      <div className="financial-flex">
        <div className="title-fin">Calendar Year</div>
        <div className="sub-fin">January 1 to December 31</div>
      </div>
    ),
    value: 'calendarYear',
  },
];

const activityTypes = [
  {
    key: 1,
    text: 'All',
    value: 'all',
  },
  {
    key: 2,
    text: 'Single Activity',
    value: 'single_activity',
  },
  {
    key: 3,
    text: 'Linked Activity',
    value: 'linked_activity',
  },
  {
    key: 4,
    text: 'Grant Program',
    value: 'grant_program',
  },
  {
    key: 5,
    text: 'Festival Program',
    value: 'festival_program',
  },
  {
    key: 6,
    text: 'Multi-Activity Program',
    value: 'multi_activity_program',
  },
];
const reportTypes = [
  {
    key: 1,
    text: 'Activity Summary',
    value: 'activity-summary',
  },
  {
    key: 2,
    text: 'Outcomes Summary',
    value: 'outcome-summary',
  },
  {
    key: 3,
    text: 'Headline Data',
    value: 'headline-data',
  },
  {
    key: 4,
    text: 'System Overview',
    value: 'system-overview',
  },
  {
    key: 5,
    text: 'Manual Outcomes Summary',
    value: 'manual-outcome-summary',
  },
];

const manualChartTypes = [
  {
    key: 1,
    text: 'Aggregate',
    value: 'AGGREGATE',
  },
  {
    key: 2,
    text: 'Separate',
    value: 'SEPARATE',
  },
  {
    key: 3,
    text: 'Trend Rate of Change',
    value: 'TREND_RATE_OF_CHANGE',
  },
  {
    key: 4,
    text: 'Individual Rate Of Change',
    value: 'INDIVIDUAL_RATE_OF_CHANGE',
  },
  {
    key: 5,
    text: 'Intercept Aggregate',
    value: 'INTERCEPT_AGGREGATE',
  },
  // {
  //   key: 6,
  //   text: 'Intercept Separate',
  //   value: 'INTERCEPT_SEPARATE',
  // },
];

class ReportSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.year = new Date().getFullYear();
    this.state = {
      reportType: undefined,
      manualChartType: 'AGGREGATE',
      outcomeType: '',
      methodType: 'all',
      orgType: 'all',
      startDate: moment('2018-01-01'),
      endDate: moment(),
      chartType: undefined,
      reportingYearsOptions: [],
      reportingYearTitle: '',
      reportingYearStart: null,
      reportingYearEnd: null,
      financialYearDropdown: 'financialYear',
      activityType: 'all',
      dateRangeChosenLabel: 'Since the Start',
      goalType: 'all',
      referenceType: 'all',
      references: [],
      outcomeSummaryGoalTitle: 'all',
      outcomeError: false,
    };
  }

  async componentDidMount() {
    const { match, getIndustrySectorTypes } = this.props;

    getIndustrySectorTypes();
    if (isEmpty(match.params)) {
      this.setState({ reportType: 'activity-summary' }, () => {
        this.getData('activity-summary');
      });
    } else {
      if (REPORT_TYPES.includes(match.params.type)) {
        this.onTypeChange(null, { value: match.params.type });
      } else {
        this.setState({ reportType: '' });
      }
    }
    this.getReportingYears();
  }

  getReportingYears() {
    let title, yearStart, yearEnd;
    let isFinancial = true;
    if (this.state.financialYearDropdown !== 'financialYear') {
      isFinancial = false;
    }

    const reportingYears = getReportYears(isFinancial, this.year);

    title = reportingYears[0].title;
    yearStart = reportingYears[0].yearStart;
    yearEnd = reportingYears[0].yearEnd;

    this.setState({
      reportingYearsOptions: reportingYears,
      reportingYearTitle: title,
      reportingYearStart: yearStart,
      reportingYearEnd: yearEnd,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { match } = this.props;
    if (isEmpty(match.params)) return;
    if (prevState.reportType && prevState.reportType !== match.params.type) {
      this.onTypeChange(null, { value: match.params.type });
    }

    if (prevState.goalType !== this.state.goalType) {
      this.setState({ outcomeSummaryGoalTitle: this.state.goalType });
    }

    if (!this.state.outcomeType) {
      this.setState({
        outcomeError: true,
      });
    } else {
      this.setState({
        outcomeError: false,
      });
    }

    if (this.state.financialYearDropdown !== prevState.financialYearDropdown) {
      this.getReportingYears();
    }
  }

  onTypeChange = async (e, { value }) => {
    const { startDate, endDate, activityType } = this.state;
    this.setState(
      {
        reportType: value,
        outcomeType: value === 'system-overview' ? 'All Outcomes' : '',
        methodType: 'all',
        orgType: 'all',
        goalType: 'all',
        chartType: undefined,
      },
      async () => {
        const { getOutcomeSummaryReferences } = this.props;
        // history.push(`/system-reports/${this.state.reportType}`);

        await getOutcomeSummaryReferences(
          startDate.format('YYYY-M-D'),
          endDate.format('YYYY-M-D'),
          activityType,
          'all',
          'all' // goal type
        );

        this.getData(this.state.reportType);
      }
    );
  };

  onManualChartChange = async (e, { value }) => {
    this.setState({ manualChartType: value });
  };

  optionYears() {
    const { reportingYearsOptions } = this.state;
    const reportYear = reportingYearsOptions.map((item, i) => {
      return {
        key: i,
        text: item.title,
        value: item.title,
        year_start: item.yearStart,
        year_end: item.yearEnd,
      };
    });
    return reportYear;
  }
  participantsOutcomes() {
    const { participantsOutcomes } = this.props;
    const outcomes = participantsOutcomes.map((outcome, i) => {
      return { key: i, text: outcome, value: outcome };
    });
    return outcomes;
  }

  methodType() {
    const { methods } = this.props;
    const optionAll = [{ key: '', text: 'All', value: 'all' }];

    const method = methods.map((method, i) => {
      return { key: i, text: method.details, value: method.id };
    });
    return optionAll.concat(method);
  }

  outcomeSummaryGoals() {
    const { outcomeSummaryGoals } = this.props;
    const optionAll = [{ key: '', text: 'All', value: 'all' }];

    const goal = outcomeSummaryGoals.map((goal, i) => {
      return { key: i, text: goal.goalTitle, value: goal.id };
    });
    return optionAll.concat(goal);
  }

  outcomeSummaryReference() {
    const { outcomeSummaryReferences } = this.props;
    const optionAll = [{ key: '', text: 'All', value: 'all' }];

    const reference = outcomeSummaryReferences.map((ref, i) => {
      return { key: i, text: ref.crossReferenceLabel, value: ref.id };
    });
    return optionAll.concat(reference);
  }

  organizationTypes() {
    const { industrySectorTypes } = this.props;
    const optionAll = [{ key: '', text: 'All', value: 'all' }];

    const orgTypes = industrySectorTypes.map((orgType, i) => {
      return { key: i, text: orgType.name, value: orgType.id };
    });
    return optionAll.concat(orgTypes);
  }

  onOutComeChange = async (e, { value }) => {
    this.setState(
      {
        outcomeType: value,
        goalType: 'all',
      },
      async () => {
        const { adminSystemOverview, outcomes } = this.props;
        const { reportType, startDate, endDate, orgType, activityType } =
          this.state;
        if (reportType === 'system-overview') {
          const outcome = find(outcomes, (o) => o.title === value);
          adminSystemOverview(
            startDate.format('YYYY-M-D'),
            endDate.format('YYYY-M-D'),
            orgType,
            outcome ? outcome.id : null,
            activityType
          );
        } else if (reportType === 'outcome-summary') {
          const { getOutcomeSummaryReferences } = this.props;
          await this.getOutcomeSummaryGoals();

          await getOutcomeSummaryReferences(
            startDate.format('YYYY-M-D'),
            endDate.format('YYYY-M-D'),
            activityType,
            'all',
            'all' // goal type
          );

          this.getData('outcome-summary');
        }
      }
    );
  };

  setChartType(value) {
    this.setState({
      chartType: value,
    });
  }

  onMethodChange = (e, { value }) => {
    this.setState({
      methodType: value,
    });
  };

  async getOutcomeSummaryGoals() {
    const { startDate, endDate, outcomeType, activityType } = this.state;
    const { getOutcomeSummaryGoals } = this.props;

    if (outcomeType) {
      await getOutcomeSummaryGoals(
        startDate.format('YYYY-M-D'),
        endDate.format('YYYY-M-D'),
        activityType,
        outcomeType
      );
    }
  }

  onChangeGoal = (e, { value }) => {
    const { outcomeSummaryGoals, getOutcomeSummaryReferences } = this.props;

    this.setState(
      {
        goalType: value === 'all' ? 'all' : value,
        referenceType: 'all',
      },
      async () => {
        const { adminAllChartData } = this.props;
        const { startDate, endDate, activityType, referenceType, outcomeType } =
          this.state;

        let goalId;
        let goalTitle;
        if (value !== 'all') {
          const goal = find(
            outcomeSummaryGoals,
            (f) => Number(f.id) === Number(value)
          );
          goalId = goal.id;
          goalTitle = goal.goalTitle;
        } else {
          goalId = 'all';
          goalTitle = 'all'; // this display in outcome summary chart
        }

        if (goalTitle) {
          this.setState({
            outcomeSummaryGoalTitle: goalTitle,
          });
        }
        await adminAllChartData(
          startDate.format('YYYY-M-D'),
          endDate.format('YYYY-M-D'),
          activityType,
          value,
          referenceType,
          outcomeType
        );

        await getOutcomeSummaryReferences(
          startDate.format('YYYY-M-D'),
          endDate.format('YYYY-M-D'),
          activityType,
          outcomeType,
          goalId // goal type
        );
      }
    );
  };

  onChangeReference = async (e, { value }) => {
    this.setState(
      {
        referenceType: value,
      },
      async () => {
        const { adminAllChartData } = this.props;
        const { startDate, endDate, activityType, goalType, outcomeType } =
          this.state;

        await adminAllChartData(
          startDate.format('YYYY-M-D'),
          endDate.format('YYYY-M-D'),
          activityType,
          goalType,
          value,
          outcomeType
        );
      }
    );
  };

  onOrgTypeChange = async (e, { value }) => {
    this.setState(
      {
        orgType: value,
      },
      () => {
        const { adminSystemOverview } = this.props;
        const { startDate, endDate, outcomeType, activityType } = this.state;
        adminSystemOverview(
          startDate.format('YYYY-M-D'),
          endDate.format('YYYY-M-D'),
          value,
          Number(outcomeType) ? outcomeType : null,
          activityType
        );
      }
    );
  };

  getData(type) {
    const {
      adminActivitySummary,
      adminAllChartData,
      adminSystemOverview,
      adminHeadlineData,
    } = this.props;
    const {
      startDate,
      endDate,
      orgType,
      reportingYearStart,
      reportingYearEnd,
      activityType,
      outcomeType,
    } = this.state;

    switch (type) {
      case 'activity-summary':
        adminActivitySummary(
          startDate.format('YYYY-M-D'),
          endDate.format('YYYY-M-D'),
          activityType
        );
        break;
      case 'outcome-summary':
        adminAllChartData(
          startDate.format('YYYY-M-D'),
          endDate.format('YYYY-M-D'),
          activityType,
          'all',
          'all'
        );
        break;
      case 'headline-data':
        adminHeadlineData(
          reportingYearStart,
          reportingYearEnd,
          this.state.financialYearDropdown
        );
        break;
      case 'system-overview':
        adminSystemOverview(
          startDate.format('YYYY-M-D'),
          endDate.format('YYYY-M-D'),
          orgType,
          Number(outcomeType) ? outcomeType : null,
          activityType
        );
        break;
      default:
        break;
    }
  }

  onChangeYearSelection = async (e, { value, options }) => {
    const item = options.find((item) => item.value === value);

    this.setState(
      {
        reportingYearTitle: value,
        reportingYearStart: item.year_start,
        reportingYearEnd: item.year_end,
      },
      () => {
        this.getData(this.state.reportType);
      }
    );
  };

  handleFinancialYear = async (e, { value }) => {
    const { financialYearDropdown } = this.state;
    let reportYear = [],
      title,
      yearStart,
      yearEnd;
    if (value === 'financialYear') {
      reportYear = getReportYears('financialYear', this.year);
    } else {
      reportYear = getReportYears('calendarYear', this.year);
    }

    title = reportYear[0].title;
    yearStart = reportYear[0].yearStart;
    yearEnd = reportYear[0].yearEnd;

    await this.setState({
      financialYearDropdown: value,
      reportingYearsOptions: reportYear,
      reportingYearTitle: title,
      reportingYearStart: yearStart,
      reportingYearEnd: yearEnd,
    });

    if (financialYearDropdown !== value) {
      await this.props.adminHeadlineData(
        this.state.reportingYearStart,
        this.state.reportingYearEnd,
        this.state.financialYearDropdown
      );
    }
  };

  renderReportType(reportType) {
    const {
      outcomeType,
      methodType,
      startDate,
      endDate,
      chartType,
      activityType,
      outcomeSummaryGoalTitle,
      manualChartType,
    } = this.state;
    const { outcomeSummaryReferences } = this.props;

    switch (reportType) {
      // case 'activity-summary':
      //   return (
      //     <ActivitySummary
      //       superAdmin
      //       startDate={startDate}
      //       endDate={endDate}
      //       dateRangeChosenLabel={dateRangeChosenLabel}
      //     />
      //   );

      case 'manual-outcome-summary':
        return <ManualOutcomesSummary chartType={manualChartType} />;
      case 'outcome-summary':
        return (
          <OutcomesSummary
            type={outcomeType}
            method={methodType.toString()}
            date={`${moment(startDate).format('DD MMM YYYY')} to ${moment(
              endDate
            ).format('DD MMM YYYY')}`}
            outcome={outcomeType}
            chartType={chartType}
            activityType={activityType}
            setChartType={(value) => this.setChartType(value)}
            outcomeSummaryGoalTitle={outcomeSummaryGoalTitle}
            outcomeSummaryReferences={outcomeSummaryReferences} // all references
          />
        );
      // case 'headline-data':
      //   return <HeadlineData user={auth.user} />;
      case 'system-overview':
        return (
          <SystemOverview
            type={outcomeType}
            outcome={outcomeType}
            chartType={chartType}
            setChartType={(value) => this.setChartType(value)}
          />
        );
      default:
        return (
          <div className="blank-report-div">
            Your report will be previewed here
          </div>
        );
    }
  }

  datePicker = async (event, picker) => {
    this.setState(
      {
        startDate: picker.startDate,
        endDate: picker.endDate,
        dateRangeChosenLabel: picker.chosenLabel,
        goalType: 'all',
      },
      async () => {
        this.getData(this.state.reportType);
        await this.getOutcomeSummaryGoals();
      }
    );
  };

  // change activity type
  onActivityChange = async (e, { value }) => {
    const { startDate, endDate, activityType } = this.state;
    this.setState(
      {
        activityType: value,
        goalType: 'all',
      },
      async () => {
        const { getOutcomeSummaryReferences } = this.props;

        this.getData(this.state.reportType);
        await this.getOutcomeSummaryGoals();

        await getOutcomeSummaryReferences(
          startDate.format('YYYY-M-D'),
          endDate.format('YYYY-M-D'),
          activityType,
          'all',
          'all' // goal type
        );
      }
    );
  };

  render() {
    const {
      reportType,
      methodType,
      outcomeType,
      orgType,
      startDate,
      endDate,
      reportingYearTitle,
      activityType,
      manualChartType,
      goalType,
      referenceType,
    } = this.state;

    const {
      actLoading,
      systemOverviewSummary,
      outcomeSummaryGoals,
      outcomeSummaryReferences,
    } = this.props;

    return (
      <>
        <Segment.Group className="report-preview">
          <Segment className="control-holder">
            <div>
              <Grid>
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={16} computer={4}>
                    <label className="title">Preview Report</label>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={8} computer={4}>
                    <div className="fin-head">
                      <label className="input-label">Report Type</label>
                      <Dropdown
                        className="report-type"
                        name="reportType"
                        onChange={this.onTypeChange}
                        options={reportTypes}
                        placeholder="Choose an option"
                        selection
                        value={reportType}
                      />
                    </div>
                  </Grid.Column>

                  {reportType === 'manual-outcome-summary' && (
                    <Grid.Column mobile={16} tablet={8} computer={4}>
                      <div className="fin-head">
                        <label className="input-label">Chart Type</label>
                        <Dropdown
                          className="activity-type"
                          name="activityType"
                          onChange={this.onManualChartChange}
                          options={manualChartTypes}
                          placeholder="Choose an option"
                          selection
                          value={manualChartType}
                          defaultValue="AGGREGATE"
                        />
                      </div>
                    </Grid.Column>
                  )}

                  {reportType === 'headline-data' && (
                    <Grid.Column mobile={16} tablet={8} computer={4}>
                      <div className="fin-head">
                        <label className="input-label">
                          Financial Reporting Period
                        </label>
                        <Dropdown
                          onChange={this.handleFinancialYear}
                          options={financialYearOptions}
                          placeholder="Choose an option"
                          selection
                          value={this.state.financialYearDropdown}
                          className="financial-report"
                        />
                      </div>
                    </Grid.Column>
                  )}

                  {reportType !== 'headline-data' &&
                    reportType !== 'manual-outcome-summary' && (
                      <Grid.Column mobile={16} tablet={8} computer={4}>
                        <div className="fin-head">
                          <label className="input-label">Activity Type</label>
                          <Dropdown
                            className="activity-type"
                            name="activityType"
                            onChange={this.onActivityChange}
                            options={activityTypes}
                            placeholder="Choose an option"
                            selection
                            value={activityType}
                          />
                        </div>
                      </Grid.Column>
                    )}
                  <Grid.Column mobile={16} tablet={8} computer={4}>
                    {reportType !== 'manual-outcome-summary' &&
                      (reportType !== 'headline-data' ? (
                        <div className="date-range-container">
                          <div>
                            <label className="input-label">Date Range</label>
                          </div>
                          <DateRangeChart
                            startDate={startDate}
                            endDate={endDate}
                            datePicker={this.datePicker}
                            preDefinedDateRanges
                          />
                        </div>
                      ) : (
                        <div className="fin-head">
                          <label className="input-label">Report Year</label>
                          <Dropdown
                            onChange={this.onChangeYearSelection}
                            options={this.optionYears()}
                            placeholder="Choose an option"
                            selection
                            value={reportingYearTitle}
                            className="report-year"
                          />
                        </div>
                      ))}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  {reportType === 'outcome-summary' ||
                  reportType === 'system-overview' ? (
                    <Grid.Column mobile={16} tablet={8} computer={4}>
                      <div className="fin-head">
                        <label className="input-label">Outcome</label>
                        <Dropdown
                          onChange={this.onOutComeChange}
                          options={this.participantsOutcomes()}
                          placeholder="Choose an option"
                          selection
                          value={outcomeType}
                          className={
                            this.state.outcomeError
                              ? 'outcome-error-contractor'
                              : 'report-outcome'
                          }
                        />
                        {this.state.outcomeError && (
                          <div className="outcomeError">
                            Outcome is required
                          </div>
                        )}
                      </div>
                    </Grid.Column>
                  ) : null}

                  {reportType === 'system-overview' ? (
                    <Grid.Column mobile={16} tablet={8} computer={4}>
                      <div className="fin-head">
                        <label className="input-label">Organization Type</label>
                        <Dropdown
                          onChange={this.onOrgTypeChange}
                          options={this.organizationTypes()}
                          placeholder="Choose an option"
                          selection
                          value={orgType}
                          className="report-org-type"
                        />
                      </div>
                    </Grid.Column>
                  ) : null}

                  {reportType === 'outcome-summary' ? (
                    <Grid.Column mobile={16} tablet={8} computer={4}>
                      <div className="fin-head">
                        <label className="input-label">Method</label>
                        <Dropdown
                          className="report-method"
                          onChange={this.onMethodChange}
                          options={this.methodType()}
                          placeholder="Choose an option"
                          selection
                          value={methodType}
                        />
                      </div>
                    </Grid.Column>
                  ) : null}

                  {reportType === 'outcome-summary' &&
                  !isEmpty(outcomeSummaryGoals) ? (
                    <Grid.Column mobile={16} tablet={8} computer={4}>
                      <div className="fin-head">
                        <label className="input-label">Goal</label>
                        <Dropdown
                          className="report-goal"
                          onChange={this.onChangeGoal}
                          options={this.outcomeSummaryGoals()}
                          placeholder="Choose an option"
                          selection
                          value={goalType}
                        />
                      </div>
                    </Grid.Column>
                  ) : null}

                  {reportType === 'outcome-summary' &&
                  goalType !== 'all' &&
                  !isEmpty(outcomeSummaryGoals) &&
                  outcomeSummaryReferences.length > 0 ? (
                    <Grid.Column mobile={16} tablet={8} computer={4}>
                      <div className="fin-head">
                        <label className="input-label">Reference</label>
                        <Dropdown
                          className="report-reference"
                          onChange={this.onChangeReference}
                          options={this.outcomeSummaryReference()}
                          placeholder="Choose an option"
                          selection
                          value={referenceType}
                        />
                      </div>
                    </Grid.Column>
                  ) : null}
                </Grid.Row>
              </Grid>
            </div>
          </Segment>
        </Segment.Group>

        <Segment.Group className="control-holder margin-down">
          <Segment
            className={
              reportType === 'organisation-summary' ? '' : 'chart-holder'
            }
            loading={actLoading}
          >
            {this.renderReportType(reportType)}
          </Segment>
        </Segment.Group>

        {reportType === 'system-overview' ? (
          <Segment.Group className="control-holder margin-down">
            <Segment>
              <div className="system-report-admin">
                <div className="com-title">Ongoing Activities</div>
              </div>
              <div className="ongoing">
                <Grid className="system-summary-grid" padded>
                  <div className="org-count">
                    <div>
                      <div className="title-org">Account count</div>
                      <div className="count-value">
                        {systemOverviewSummary.ongoing
                          ? thousands(systemOverviewSummary.ongoing.orgCount)
                          : '-'}
                      </div>
                    </div>
                    <div className="adjust-left">
                      <div className="title-org">Activity count</div>
                      <div className="count-value">
                        {systemOverviewSummary.ongoing
                          ? thousands(
                              systemOverviewSummary.ongoing.activityCount
                            )
                          : '-'}
                      </div>
                    </div>
                    <div className="adjust-left">
                      <div className="title-org">Projected Expenditures</div>
                      <div className="count-value">
                        {systemOverviewSummary.ongoing
                          ? `$${thousands(
                              systemOverviewSummary.ongoing.projectedExpenditure
                            )}`
                          : '-'}
                      </div>
                    </div>
                  </div>
                </Grid>
              </div>
            </Segment>
          </Segment.Group>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    actLoading: state.report.loading,
    participantsOutcomes: state.report.participantsOutcomes,
    reportFilterOptions: state.report.reportFilterOptions,
    exportOrg: state.report.exportOrg,
    exportLoading: state.report.exportLoading,
    organization: state.organization.organization,
    industrySectorTypes: state.extra.industrySectorTypes,
    outcomes: state.extra.outcomes,
    methods: state.extra.methods,
    systemOverviewSummary: state.report.systemOverviewSummary,
    outcomeSummaryGoals: state.report.outcomeSummaryGoals,
    outcomeSummaryReferences: state.report.outcomeSummaryReferences,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      adminActivitySummary,
      adminAllChartData,
      adminSystemOverview,
      getIndustrySectorTypes,
      adminHeadlineData,
      getOutcomeSummaryGoals,
      getOutcomeSummaryReferences,
    },
    dispatch
  );
};

ReportSelector = connect(mapStateToProps, mapDispatchToProps)(ReportSelector);

export { ReportSelector };
