import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Icon,
  Modal,
  Segment,
  Button,
  Radio,
  Search,
  Grid,
  Label,
} from 'semantic-ui-react';
import { isBrowser, isTablet } from 'react-device-detect';
import { NotificationManager } from 'react-notifications';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import moment from 'moment';
import React, { Component } from 'react';

import {
  cloneDeep,
  isEmpty,
  map,
  get,
  debounce,
} from '../../../../utils/lodash';
import { CustomTable, EmptyContainer } from '../../../../components';
import {
  postFeatures,
  getFeatures,
  updateFeatures,
} from '../../../../store/actions';

export default class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      keyword: '',
      currentPage: 1,
    };
    this.LIMIT = 15;
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  handleSearchChange = (e, { value }) => {
    const { getFeatures } = this.props;
    const pageNumber = 1;
    this.setCurrentPage(pageNumber);
    getFeatures(pageNumber, this.LIMIT, value);
    this.setState({ keyword: value });
  };

  setCurrentPage(number) {
    this.setState({ currentPage: number });
  }

  render() {
    const { modal, keyword, currentPage } = this.state;

    return (
      <div>
        <div className="outcome-admin">
          <div className="search-bar mr-3">
            <Search
              onSearchChange={debounce(this.handleSearchChange, 500)}
              placeholder="Search by feature name"
              showNoResults={false}
            />
          </div>
          <div className="button-outcome">
            <Modal
              open={modal}
              onOpen={() => this.toggle()}
              closeIcon={
                <Icon
                  onClick={() => this.toggle()}
                  name="close"
                  className="closeicon"
                />
              }
              size="large"
              trigger={
                <Button className="Primary-Button">
                  <div className="add-icon-activity-add">
                    <Icon name="plus circle" />
                    <div className="add-text">Add New Feature</div>
                  </div>
                </Button>
              }
              className="create-new-feature"
            >
              <Modal.Header>Create New Feature</Modal.Header>
              <Modal.Content>
                <FeatureForm
                  edit={false}
                  toggle={() => this.toggle()}
                  {...this.props}
                />
              </Modal.Content>
            </Modal>
          </div>
        </div>
        <FeatureList
          LIMIT={this.LIMIT}
          keyword={keyword}
          currentPage={currentPage}
          setCurrentPage={(number) => this.setCurrentPage(number)}
          {...this.props}
        />
      </div>
    );
  }
}

class FeatureList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  renderColumns() {
    const { currentPage } = this.props;
    return [
      {
        title: (
          <div className="user-act-heading-container-custom-feature">
            <strong>Feature Name</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className="blocked-badge-container">
              <div className={!data.isActive && 'disabled'}>
                {data.displayName ? data.displayName : ''}
              </div>
              {!data.isActive && (
                <Label className="blocked-badge" color="red" size="tiny">
                  Disabled
                </Label>
              )}
            </div>
          );
        },
        width: 3,
        textAlign: isBrowser || isTablet ? 'left' : null,
      },
      {
        title: (
          <div className="user-act-heading-container-custom-feature">
            <strong>Description</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className={!data.isActive && 'disabled'}>
              {' '}
              {data.description.length <= 100
                ? data.description
                : data.description.substring(0, 100) + '...'}
            </div>
          );
        },
        width: 6,
        textAlign: isBrowser || isTablet ? 'left' : null,
      },
      {
        title: (
          <div className="user-act-heading-container-custom-feature">
            <strong>Created Date</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className={!data.isActive && 'disabled'}>
              {moment(data.createdAt).format('ll hh:mm A')}
            </div>
          );
        },
        width: 3,
        textAlign: isBrowser || isTablet ? 'left' : null,
      },
      {
        title: (
          <div className="user-act-heading-container-custom-feature">
            <strong>Updated Date</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className={!data.isActive && 'disabled'}>
              {' '}
              {moment(data.updatedAt).format('ll hh:mm A')}
            </div>
          );
        },
        width: 3,
        textAlign: isBrowser || isTablet ? 'left' : null,
      },
      {
        title: '',
        render: (data) => {
          return (
            <EditNewFeature
              item={data}
              currentPage={currentPage}
              {...this.props}
            />
          );
        },
        width: 1,
        textAlign: isBrowser || isTablet ? 'left' : null,
      },
    ];
  }

  async componentDidMount() {
    try {
      const { getFeatures, LIMIT, keyword, currentPage } = this.props;
      await getFeatures(currentPage, LIMIT, keyword);
    } catch (error) {}
  }

  toggleCheckbox(data, isActive) {
    const { updateFeatures, currentPage } = this.props;
    const { id } = data;

    updateFeatures(id, { isActive }, currentPage);
  }

  pageChange(page) {
    const { getFeatures, LIMIT, keyword, setCurrentPage } = this.props;
    setCurrentPage(page);
    getFeatures(page, LIMIT, keyword);
  }

  render() {
    const { features, getfeatureLoading, currentPage } = this.props;

    return (
      <Segment
        className="content-segment p-0"
        loading={getfeatureLoading}
        disabled={getfeatureLoading}
      >
        {features.docs && features.docs.length ? (
          <CustomTable
            header
            columns={this.renderColumns()}
            data={features.docs}
            customClass="super-admin-table tbody-hover"
            pagination
            handlePaginationChange={(page) => this.pageChange(page)}
            page={currentPage}
            noOfPages={features.pages}
            keyExtractor={(item, index) => item.id}
          />
        ) : (
          <EmptyContainer msg="No Features..." />
        )}
      </Segment>
    );
  }
}

class EditNewFeature extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;

    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="large"
        trigger={<label className="activity-actions-warn mb-0">Edit</label>}
        className="edit-feature"
      >
        <Modal.Header>Edit Feature</Modal.Header>
        <Modal.Content>
          <EditNewFeatureForm
            edit={true}
            toggle={() => this.toggle()}
            {...this.props}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

class EditNewFeatureForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      form: {
        id: get(props, 'item.id', ''),
        displayName: get(props, 'item.displayName', ''),
        description: get(props, 'item.description', ''),
        userStory: get(props, 'item.userStory', ''),
        isActive: get(props, 'item.isActive', false),
        createdBy: get(props, 'item.createdBy', ''),
        version: get(props, 'item.featureVersion', []),
      },
      version: [
        {
          name: '',
          description: '',
          userStory: '',
          isDefault: false,
        },
      ],
    };
  }

  componentDidMount() {
    const { form } = this.state;
    const {
      form: { version = [] },
    } = this.state;

    this.setState({
      version: isEmpty(version)
        ? [
            {
              name: '',
              description: '',
              userStory: '',
              isDefault: false,
            },
          ]
        : version,
      isActive: form.isActive,
    });
  }

  addVersion() {
    this.setState((preState) => {
      let version = cloneDeep(preState.version) || [];

      version.push({
        name: '',
        description: '',
        userStory: '',
        isDefault: false,
      });
      return { ...preState, version };
    });
  }

  onBlurFields(id, type, value) {
    const { version } = this.state;
    for (const [key, item] of Object.entries(version)) {
      if (parseInt(key) === id) {
        if (['description', 'name', 'userStory', 'isDefault'].includes(type)) {
          item[type] = value;
        }
      }
    }
  }

  removeField(index) {
    this.setState((prevState) => {
      let { version } = cloneDeep(prevState);
      version.splice(index, 1);
      return {
        version,
      };
    });
  }

  toggleCheckbox(index, checked) {
    let { version } = cloneDeep(this.state);

    version[index].isDefault = checked;
    if (checked) {
      map(version, (item, i) => {
        if (index !== i) {
          item.isDefault = false;
        }
      });
    }
    this.setState({
      version,
    });
  }

  toggleCheckboxIsEnabled() {
    this.setState((prevState) => {
      return { isEnabled: !prevState.isEnabled };
    });
  }

  async handleValidSubmit(event, values) {
    try {
      const { updateFeatures, toggle, currentPage } = this.props;
      const { form, isActive } = this.state;
      //disabled name
      delete values.displayName;
      values.isActive = isActive;
      await updateFeatures(form.id, values, currentPage);
      toggle();
    } catch (error) {
      console.error('Error: CreateFeature ~ handleValidSubmit', error);
    }
  }

  handleEnabledToggle() {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  }

  render() {
    const { toggle, featureUpdateLoading } = this.props;
    const { form } = this.state;

    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="venue-form"
        model={form}
      >
        <div className="feature-details">Feature Details</div>

        <AvField
          label="Name"
          className="org-inputs"
          name="displayName"
          type="text"
          disabled
          validate={{
            required: {
              value: true,
              errorMessage: 'Feature Name is required',
            },
          }}
        />

        <AvField
          label="Description"
          className="org-inputs-textarea"
          name="description"
          type="textarea"
          validate={{
            required: {
              value: true,
              errorMessage: 'Description is required',
            },
          }}
        />

        <AvField
          label="Userstory Link"
          className="org-inputs"
          name="userStory"
          type="url"
          placeholder="https://example.com"
        />

        <Grid className="activate-notify-row mt-2">
          <Grid.Column
            computer={8}
            className="evaluation-activity-toggel-group linked-activity-model-column"
          >
            <label className="lbl-margin-left">Active Status</label>
            <Radio
              toggle
              name="visibleToggel"
              checked={this.state.isActive}
              onChange={() => this.handleEnabledToggle()}
            />
          </Grid.Column>
        </Grid>

        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            content="Save"
            type="submit"
            loading={featureUpdateLoading}
            disabled={featureUpdateLoading}
            className="Primary-Button"
          />
        </div>
      </AvForm>
    );
  }
}

class FeatureForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEnabled: false,
      version: [
        {
          name: '',
          description: '',
          userStory: '',
          isDefault: false,
        },
      ],
    };
  }

  async handleValidSubmit(event, values) {
    try {
      const { postFeatures, toggle } = this.props;
      const { version } = this.state;

      if (isEmpty(version)) {
        NotificationManager.error('Please Enter a Version');
        return;
      }

      await postFeatures(values);
      toggle();
    } catch (error) {
      console.error('Error: CreateFeature ~ handleValidSubmit', error);
    }
  }

  addVersion() {
    this.setState((preState) => {
      let version = cloneDeep(preState.version) || [];

      version.push({
        name: '',
        description: '',
        userStory: '',
        isDefault: false,
      });
      return { ...preState, version };
    });
  }

  removeField(index) {
    this.setState((prevState) => {
      let { version } = cloneDeep(prevState);
      version.splice(index, 1);
      return {
        version,
      };
    });
  }

  toggleCheckbox(index, checked) {
    let { version } = cloneDeep(this.state);

    version[index].isDefault = checked;
    if (checked) {
      map(version, (item, i) => {
        if (index !== i) {
          item.isDefault = false;
        }
      });
    }
    this.setState({
      version,
    });
  }

  toggleCheckboxIsEnabled() {
    this.setState((prevState) => {
      return { isEnabled: !prevState.isEnabled };
    });
  }

  onBlurFields(id, type, value) {
    const { version } = this.state;
    for (const [key, item] of Object.entries(version)) {
      if (parseInt(key) === id) {
        if (['description', 'name', 'userStory', 'isDefault'].includes(type)) {
          item[type] = value;
        }
      }
    }
  }

  render() {
    const { toggle, form, featureLoading } = this.props;

    return (
      <AvForm
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="venue-form"
        modal={form}
      >
        <div className="feature-details">Feature Details</div>

        <AvField
          label="Name"
          className="org-inputs"
          name="displayName"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: 'Feature Name is required',
            },
          }}
        />

        <AvField
          label="Description"
          className="org-inputs-textarea"
          name="description"
          type="textarea"
          validate={{
            required: {
              value: true,
              errorMessage: 'Description is required',
            },
          }}
        />

        <AvField
          label="Userstory Link"
          className="org-inputs"
          name="userStory"
          type="url"
          placeholder="https://example.com"
        />

        <div className="empty-margin-custom-feature"></div>

        <div className="model-buttons ">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            content="Create"
            type="submit"
            className="Primary-Button"
            loading={featureLoading}
            disabled={featureLoading}
          />
        </div>
      </AvForm>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    featureLoading: state.admin.featureLoading,
    features: state.admin.features,
    featureUpdateLoading: state.admin.featureUpdateLoading,
    getfeatureLoading: state.admin.getfeatureLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postFeatures,
      getFeatures,
      updateFeatures,
    },
    dispatch
  );
};
Features = connect(mapStateToProps, mapDispatchToProps)(Features);

export { Features };
