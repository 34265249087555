/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import '../../../activity.scss';
import { SeeMore } from '../../../../../../components';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty, map } from '../../../../../../utils/lodash';
import { isGrantProgramActivity } from '../../../../../../utils/activity';
import { getPageDisable } from '../../../../../../utils';
import React, { useState } from 'react';
import { Grid, Button, Modal } from 'semantic-ui-react';
import { reInviteLinkActivity } from '../../../../../../store/actions';

export default function ViewLinkedActivityForm(props) {
  const dispatch = useDispatch();
  const { activity, projected, toggle, currentState } = props;

  const { currentActivity, linkedActivitiesActivateLoading } = useSelector(
    (state) => state.activity
  );
  const { user } = useSelector((state) => state.authentication);

  const [notifyModal, setNotifyModal] = useState(false);

  const notifyUser = () => {
    dispatch(reInviteLinkActivity(activity.parentId, activity.id));
    setNotifyModal(false);
  };

  return (
    <div>
      {/* Notification modal */}
      <Modal
        open={notifyModal}
        closeIcon={
          <div
            className="new-activity-modal-close-icon"
            onClick={() => setNotifyModal(false)}
          >
            &times;
          </div>
        }
        size="tiny"
      >
        <Modal.Header className="notify-modal-header">
          Notify Activity Manager
        </Modal.Header>
        <Modal.Content>
          <p>
            Are you sure, do you want to notify the manager of this activity?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="activity-activation-cancel-btn"
            onClick={() => setNotifyModal(false)}
          >
            No
          </Button>
          <Button
            loading={linkedActivitiesActivateLoading}
            disabled={linkedActivitiesActivateLoading}
            className="new-collective-save-btn"
            onClick={() => notifyUser()}
            content="Notify"
          />
        </Modal.Actions>
      </Modal>
      {/* Goal */}
      <Grid className="mb-8">
        <Grid.Column className="linked-activity-model-column">
          <label className="goal-label">Goal</label>
          <div>
            <label className="evidence-modal-label">
              {get(currentActivity, 'goal')}
            </label>
          </div>
        </Grid.Column>
      </Grid>

      {/* Primary Domain & Secondary Domain */}
      <Grid className="mb-8">
        <Grid.Column
          className="linked-activity-model-column"
          mobile={16}
          tablet={8}
          computer={8}
        >
          <label className="goal-label">Primary Domain</label>
          <div>
            {currentActivity.primaryPolicy && (
              <label className="evidence-modal-label">
                {currentActivity.primaryPolicy.policyName}
              </label>
            )}
          </div>
        </Grid.Column>
        {!isEmpty(currentActivity.parentDomains) && (
          <Grid.Column
            className="linked-activity-model-column"
            mobile={16}
            tablet={8}
            computer={8}
          >
            <label className="goal-label">Secondary Domain</label>

            <ul className="linked-activity-model-margin-bottom">
              {map(currentActivity.parentDomains, (domain, i) => (
                <li key={i} className="evidence-modal-label">
                  {domain.policy.policyName}
                </li>
              ))}
            </ul>
          </Grid.Column>
        )}
        {!isEmpty(currentActivity.secondaryPolicy) && (
          <Grid.Column
            className="linked-activity-model-column"
            mobile={16}
            tablet={8}
            computer={8}
          >
            <label className="goal-label">Secondary Domain</label>
            <div>
              <label className="evidence-modal-label">
                {currentActivity.secondaryPolicy.policyName}
              </label>
            </div>
          </Grid.Column>
        )}
      </Grid>

      {/* Primary Objective */}
      <Grid className="mb-8">
        {!isEmpty(activity.primaryParentObjectives) && (
          <Grid.Column
            className="linked-activity-model-column"
            mobile={16}
            tablet={8}
            computer={8}
          >
            <label className="goal-label">Primary Objective</label>

            <ul className="custom-multiple-checkbox-ul">
              {activity.primaryParentObjectives.length < 4 ? (
                activity.primaryParentObjectives.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))
              ) : (
                <SeeMore
                  minCount={3}
                  dataSet={activity.primaryParentObjectives}
                  CustomComponent={(props) => <li>{props.item}</li>}
                  isView={false}
                />
              )}
            </ul>
          </Grid.Column>
        )}
      </Grid>

      {/* Secondary Objective */}
      <Grid className="mb-8">
        {!isEmpty(activity.secondaryParentObjectives) && (
          <Grid.Column
            className="linked-activity-model-column"
            mobile={16}
            tablet={8}
            computer={8}
          >
            <label className="goal-label">Secondary Objective</label>

            <ul className="custom-multiple-checkbox-ul">
              {activity.secondaryParentObjectives.length < 4 ? (
                activity.secondaryParentObjectives.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))
              ) : (
                <SeeMore
                  minCount={3}
                  dataSet={activity.secondaryParentObjectives}
                  CustomComponent={(props) => <li>{props.item}</li>}
                  isView={false}
                />
              )}
            </ul>
          </Grid.Column>
        )}
      </Grid>

      {/* Activity Name */}
      <Grid className="mb-8">
        <Grid.Column className="linked-activity-model-column">
          <label className="goal-label">Activity Name</label>
          <div>
            <label className="evidence-modal-label">
              {get(activity, 'activityName')}
            </label>
          </div>
        </Grid.Column>
      </Grid>

      {/* Activity Notes */}
      <Grid className="mb-8">
        <Grid.Column className="linked-activity-model-column">
          <label className="goal-label">Activity Notes</label>
          <div>
            <label className="evidence-modal-label">
              {get(activity, 'activityNotes')}
            </label>
          </div>
        </Grid.Column>
      </Grid>

      {/* Grant ID */}
      {isGrantProgramActivity(currentActivity.activityPlanTypeId) && (
        <Grid className="mb-8">
          <Grid.Column className="linked-activity-model-column">
            <label className="goal-label">Grant ID</label>
            <div>
              <label className="evidence-modal-label">
                {get(activity, 'grantId')}
              </label>
            </div>
          </Grid.Column>
        </Grid>
      )}

      {/* First Name & Last Name */}
      <Grid className="mb-8">
        <Grid.Column
          className="linked-activity-model-column"
          mobile={16}
          tablet={8}
          computer={8}
        >
          <label className="goal-label">First Name</label>
          <div>
            <label className="evidence-modal-label">
              {activity.owner.firstName}
            </label>
          </div>
        </Grid.Column>
        <Grid.Column
          className="linked-activity-model-column"
          mobile={16}
          tablet={8}
          computer={8}
        >
          <label className="goal-label">Last Name</label>
          <div>
            <label className="evidence-modal-label">
              {activity.owner.surname}
            </label>
          </div>
        </Grid.Column>
      </Grid>

      {/* Email & Phone */}
      <Grid className="mb-8">
        <Grid.Column
          className="linked-activity-model-column"
          mobile={16}
          tablet={8}
          computer={8}
        >
          <label className="goal-label">Email</label>
          <div>
            <label className="evidence-modal-label">
              {activity.owner.email}
            </label>
          </div>
        </Grid.Column>
        <Grid.Column
          className="linked-activity-model-column"
          mobile={16}
          tablet={8}
          computer={8}
        >
          <label className="goal-label">Phone</label>
          <div>
            <label className="evidence-modal-label">
              {activity.owner.phone}
            </label>
          </div>
        </Grid.Column>
      </Grid>

      {/* Business/ Organisation Name */}
      <Grid className="mb-8">
        <Grid.Column className="linked-activity-model-column">
          <label className="goal-label">Business/ Organisation Name</label>
          <div>
            <label className="evidence-modal-label">
              {activity.owner.organisationId
                ? get(activity, 'owner.organisation.name', '-')
                : get(activity, 'owner.entityName', '-')}
            </label>
          </div>
        </Grid.Column>
      </Grid>

      {/* Address Line 1 & Address Line 2 */}
      <Grid className="mb-8">
        {activity.owner.country && (
          <Grid.Column
            className="linked-activity-model-column"
            mobile={16}
            tablet={8}
            computer={8}
          >
            <label className="goal-label">Country</label>
            <div>
              <label className="evidence-modal-label">
                {activity.owner.country}
              </label>
            </div>
          </Grid.Column>
        )}
      </Grid>

      <Grid className="mb-8">
        {activity.owner.address1 && (
          <Grid.Column
            className="linked-activity-model-column"
            mobile={16}
            tablet={8}
            computer={8}
          >
            <label className="goal-label">Address Line 1</label>
            <div>
              <label className="evidence-modal-label">
                {activity.owner.address1}
              </label>
            </div>
          </Grid.Column>
        )}
        {activity.owner.address2 && (
          <Grid.Column
            className="linked-activity-model-column"
            mobile={16}
            tablet={8}
            computer={8}
          >
            <label className="goal-label">Address Line 2</label>
            <div>
              <label className="evidence-modal-label">
                {activity.owner.address2}
              </label>
            </div>
          </Grid.Column>
        )}
      </Grid>

      {/* Suburb & State & Post Code */}
      <Grid className="mb-8">
        {activity.owner.suburb && (
          <Grid.Column
            className="linked-activity-model-column"
            mobile={16}
            tablet={8}
            computer={5}
          >
            <label className="goal-label">Suburb</label>
            <div>
              <label className="evidence-modal-label">
                {activity.owner.suburb}
              </label>
            </div>
          </Grid.Column>
        )}
        {activity.owner.postcode && (
          <Grid.Column
            className="linked-activity-model-column"
            mobile={16}
            tablet={8}
            computer={5}
          >
            <label className="goal-label">State</label>
            <div>
              <label className="evidence-modal-label">
                {activity.owner.state}
              </label>
            </div>
          </Grid.Column>
        )}
        {activity.owner.postcode && (
          <Grid.Column
            className="linked-activity-model-column"
            mobile={16}
            tablet={8}
            computer={5}
          >
            <label className="goal-label">Post Code</label>
            <div>
              <label className="evidence-modal-label">
                {activity.owner.postcode}
              </label>
            </div>
          </Grid.Column>
        )}
      </Grid>

      {/* Program Contribution */}
      {/* check the relationship type and if 'inKind' or 'funding' display 'Program Contribution' */}
      <Grid className="mb-8">
        <Grid.Column className="linked-activity-model-column">
          <label className="goal-label">Program Contribution</label>
          <div>
            <label className="evidence-modal-label">
              $ {activity.projectedGrantAmount}
            </label>
          </div>
        </Grid.Column>
      </Grid>

      {/* Notify Activity Manager */}
      {!projected && activity ? (
        <Grid>
          <Grid.Column textAlign="right" verticalAlign="middle">
            <div className="activity-model-buttons">
              {activity.statusId !== 3 &&
              !activity.review &&
              !getPageDisable(currentActivity, user, currentState) ? (
                <Button
                  content={
                    activity.isNotified
                      ? 'Re-Send Notification'
                      : 'Notify Activity Manager'
                  }
                  onClick={() => setNotifyModal(true)}
                  basic
                  color="orange"
                  className="activity-reinvite-button"
                />
              ) : null}
              <Button
                content="Close"
                className="activity-confirm-button"
                onClick={() => toggle()}
              />
            </div>
          </Grid.Column>
        </Grid>
      ) : null}
    </div>
  );
}
