import React, { PureComponent } from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import moment from 'moment';
import './activity.scss';
import { dateRangePickerTitle } from '../../../utils';
import { isEmpty, map, filter } from '../../../utils/lodash';
import ShowMoreText from 'react-show-more-text';

export class ReportActivity extends PureComponent {
  getFestivalTypes(festivalTypes) {
    if (isEmpty(festivalTypes)) return null;
    const usedFestivalTypes = map(festivalTypes, (i) => i.festivalTypeId) || [];
    if (isEmpty(usedFestivalTypes)) return null;
    const getFestivalTypes = filter(this.props.festivalTypes, (i) =>
      usedFestivalTypes.includes(i.id)
    );
    return map(getFestivalTypes, (i) => (
      <div className="projected-input-venues info-desc">{i.title}</div>
    ));
  }
  render() {
    return (
      <Segment className="report-activity-seg">
        <div className="report-activity-div">
          <Grid>
            <Grid.Row className="activity-name-row">
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <label className="info-title">Activity Name</label>
                <p>{this.props.name ? this.props.name : '-'}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="activity-name-row">
              <Grid.Column mobile={16} tablet={16} computer={12}>
                <label className="info-title">Activity Description</label>
                <p>{this.props.summary ? this.props.summary : '-'}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="activity-name-row" columns={2}>
              <Grid.Column mobile={16} tablet={16} computer={6}>
                <label className="info-title">Activity Type</label>
                <p>{this.props.type ? this.props.type : '-'}</p>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={6}>
                <label className="info-title">
                  {dateRangePickerTitle(this.props)}
                </label>
                <p>{this.getPeriod()}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="activity-name-row" columns={2}>
              <Grid.Column mobile={16} tablet={16} computer={6}>
                <label className="info-title">Organisation Goal</label>
                <p>{this.props.goal ? this.props.goal : '-'}</p>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={6}>
                <label className="info-title">Goal Description</label>
                <ShowMoreText
                  lines={3}
                  more="Read More"
                  less="Read Less"
                  anchorClass="show-more-less-clickable"
                  expanded={false}
                  truncatedEndingComponent={'... '}
                  className="pre-line"
                >
                  <p>
                    {this.props.goalDescription
                      ? this.props.goalDescription
                      : '-'}
                  </p>
                </ShowMoreText>
              </Grid.Column>
            </Grid.Row>
            {this.props.activityPlanTypeId === 3 && (
              <Grid.Row className="activity-name-row" columns={2}>
                <Grid.Column mobile={16} tablet={16} computer={6}>
                  <label className="info-title">Festival Types</label>
                  {!isEmpty(this.props.parentFestivals) ? (
                    this.getFestivalTypes(this.props.parentFestivals)
                  ) : (
                    <p>-</p>
                  )}
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </div>
      </Segment>
    );
  }

  getPeriod() {
    const { periodStart, periodEnd } = this.props;
    let start = moment(periodStart).format('DD MMM YYYY');
    let end = moment(periodEnd).format('DD MMM YYYY');
    if (!periodStart) {
      start = '-';
    }
    if (!periodEnd) {
      end = '-';
    }
    let period = start + ' to ' + end;
    if (!periodStart && !periodEnd) {
      period = '-';
    }
    return period;
  }
}
