import React, { useState } from 'react';

import { SelfAssessedSeparateChart } from '../activityCharts/programOutcome/selfAssessedSeparateChart';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Segment } from 'semantic-ui-react';
import {
  setParentChartIncludeIn,
  setSidebarType,
} from '../../../store/actions';
import { useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import {
  ORGANISATION_REPORT_LOADING,
  PROGRAM_REPORT_LOADING,
  SIDEBAR_TYPES,
} from '../../../utils/constant';
import { EmptyContainer } from '../../genericComponents';
import { CustomSegment } from '../../customSegment';
import { SelfAssessedTrendChart } from '../activityCharts/programOutcome/selfAssessedTrendChart';
import { AggregateChart } from '../postCharts/aggregateChart';
import { SeparateChart } from '../postCharts//separateChart';
import { IndividualRateOfChangeChart } from '../postPreCharts/individualRateChart';
import { TrendRateOfChangeChart } from '../postPreCharts/trendRatesOfChart';
import { getPageDisable } from '../../../utils';
import { isFiltersInitState } from '../../../utils/activity/isFiltersInitState';

export const OutcomeChart = (props) => {
  const currentActivity = useSelector(
    (state) => state.activity.currentActivity
  );
  const user = useSelector((state) => state.authentication.user);

  const [outcomeSelected, setOutcomeSelected] = useState({});
  const [isParticipantsEmpty, setIsParticipantsEmpty] = useState(false);
  const [isParticipantsChangeEmpty, setParticipantsChangeEmpty] =
    useState(false);
  const [
    isParticipantsPrePostChangeEmpty,
    setIsParticipantsPrePostChangeEmpty,
  ] = useState(false);

  const dispatch = useDispatch();
  const reportFilter = useSelector((state) => state.report.reportFilterOptions);
  const participants = useSelector((state) => state.report.participants);
  const reportInitialState = useSelector((state) => state.report);

  //check is postPre or post
  const outcomes = useSelector((state) => state.extra.outcomes);
  const participantsChangePrePost = useSelector(
    (state) => state.report.participantsChangePrePost
  );

  const participantsChange = useSelector(
    (state) => state.report.participantsChange
  );

  const item = outcomes.find((item) => item.id === reportFilter.outcome);
  const loaderValue = props.parent
    ? PROGRAM_REPORT_LOADING
    : ORGANISATION_REPORT_LOADING;
  const getTypeOfReport = () => {
    return item && item.prePost
      ? `postPreReportLoading.${loaderValue['POST_PRE_REPORT_LOADING']}`
      : `postReportLoading.${loaderValue['POST_REPORT_LOADING']}`;
  };

  const individualRate = props.parent
    ? PROGRAM_REPORT_LOADING.INDIVIDUAL_RATE_REPORT_LOADING
    : ORGANISATION_REPORT_LOADING.INDIVIDUAL_RATE_REPORT_LOADING;
  const individualRateLoading = get(
    reportInitialState,
    `individualRateReportLoading.${individualRate}`,
    false
  );
  const type = getTypeOfReport();
  const loading = get(reportInitialState, type);

  useEffect(() => {
    setIsParticipantsEmpty(isEmpty(participants));
  }, [participants]);

  useEffect(() => {
    setIsParticipantsPrePostChangeEmpty(
      participantsChangePrePost.activityCount === 0 ||
        participantsChangePrePost.activityCount === undefined
        ? true
        : false
    );
  }, [participantsChangePrePost]);

  useEffect(() => {
    setParticipantsChangeEmpty(isEmpty(participantsChange));
  }, [participantsChange]);

  useEffect(() => {
    setOutcomeSelected(reportFilter.outcome);
  }, [reportFilter.outcome]);

  const isPrePostChart = item && item.prePost;
  const METHOD_ID = reportFilter.method;

  /* --------- start of config included in report  --------- */

  const onChangeInReport = (inReport, reportType) => {
    dispatch(
      setParentChartIncludeIn(
        currentActivity.id,
        'OUTCOME_REPORTED_CHART',
        inReport,
        reportType,
        reportFilter.outcome
      )
    );
  };

  let includeOutcomeCharts = [];
  if (props.inReportLbl) {
    const outcomeCharts = get(
      currentActivity,
      'reportCharts.outcomeCharts',
      null
    );

    includeOutcomeCharts = outcomeCharts.filter(
      (outcome) => outcome.outcomeId === reportFilter.outcome
    );
  }

  const _isFiltersInitState = isFiltersInitState(reportFilter, [
    'outcome',
    'startDate',
    'endDate',
    'method',
  ]);

  const isDisabledInReportGrpBtn =
    getPageDisable(currentActivity, user, 'project.edit.documentation') ||
    !_isFiltersInitState;

  /* --------- end of config included in report  --------- */

  const renderGraphs = () => {
    if (outcomeSelected === null) {
      return (
        <EmptyContainer
          msg="No outcome selected"
          childrenComponent={
            <Button
              className="Secondary-Button"
              type="button"
              onClick={() => dispatch(setSidebarType(SIDEBAR_TYPES[2], true))}
            >
              Select an outcome
            </Button>
          }
        />
      );
    } else {
      if (METHOD_ID === 12) {
        return (
          <>
            {isParticipantsEmpty ? (
              <EmptyContainer msg="No data in that method." />
            ) : (
              <>
                <AggregateChart
                  method={METHOD_ID}
                  participants={participants}
                  isInReport={includeOutcomeCharts.some((item) => {
                    const type =
                      METHOD_ID === 12 ? 'INTERCEPT_AGGREGATE' : 'AGGREGATE';
                    if (item.type === type) {
                      return true;
                    }
                    return false;
                  })}
                  isDisabledInReportGrpBtn={isDisabledInReportGrpBtn}
                  onChangeInReport={(inReport) =>
                    onChangeInReport(
                      inReport,
                      METHOD_ID === 12 ? 'INTERCEPT_AGGREGATE' : 'AGGREGATE'
                    )
                  }
                  {...props}
                />
                <SeparateChart
                  method={METHOD_ID}
                  participants={participants}
                  isInReport={includeOutcomeCharts.some(
                    (item) =>
                      item.type ===
                      (METHOD_ID === 12 ? 'INTERCEPT_SEPERATE' : 'SEPERATE')
                  )}
                  isDisabledInReportGrpBtn={isDisabledInReportGrpBtn}
                  onChangeInReport={(inReport) =>
                    onChangeInReport(
                      inReport,
                      METHOD_ID === 12 ? 'INTERCEPT_SEPERATE' : 'SEPERATE'
                    )
                  }
                  {...props}
                />
              </>
            )}
          </>
        );
      } else if (METHOD_ID === 9) {
        if (isPrePostChart === 0) {
          if (isParticipantsEmpty) {
            return <EmptyContainer msg="No data in that method." />;
          } else if (participants) {
            const formattedSelfPostParticipants =
              get(participants, 'dataAll', []).length > 0 &&
              participants.dataAll.map((item) => {
                return {
                  name: item.name,
                  participantResponse: [item.data],
                };
              });
            return (
              <>
                <SelfAssessedTrendChart
                  {...props}
                  activityCount={participants.activityCount}
                  totalParticipants={participants.totalParticipants}
                  totalRespondents={participants.totalRespondents}
                  response={[participants.data]}
                  isInReport={includeOutcomeCharts.some(
                    (item) => item.type === 'TREND'
                  )}
                  isDisabledInReportGrpBtn={isDisabledInReportGrpBtn}
                  onChangeInReport={(inReport) =>
                    onChangeInReport(inReport, 'TREND')
                  }
                />
                <SelfAssessedSeparateChart
                  {...props}
                  participants={participants}
                  activityCount={participants.activityCount}
                  totalParticipants={participants.totalParticipants}
                  totalRespondents={participants.totalRespondents}
                  evaluations={formattedSelfPostParticipants}
                  isInReport={includeOutcomeCharts.some(
                    (item) => item.type === 'SEPERATE'
                  )}
                  isDisabledInReportGrpBtn={isDisabledInReportGrpBtn}
                  onChangeInReport={(inReport) =>
                    onChangeInReport(inReport, 'SEPERATE')
                  }
                />
              </>
            );
          }
        } else {
          if (isParticipantsPrePostChangeEmpty) {
            return <EmptyContainer msg="No data in that method." />;
          } else if (participantsChangePrePost) {
            return (
              <>
                <SelfAssessedTrendChart
                  {...props}
                  activityCount={participantsChangePrePost.activityCount}
                  totalParticipants={
                    participantsChangePrePost.totalParticipants
                  }
                  totalRespondents={participantsChangePrePost.totalRespondents}
                  response={participantsChangePrePost.data}
                  isInReport={includeOutcomeCharts.some(
                    (item) => item.type === 'TREND'
                  )}
                  isDisabledInReportGrpBtn={isDisabledInReportGrpBtn}
                  onChangeInReport={(inReport) =>
                    onChangeInReport(inReport, 'TREND')
                  }
                />
                <IndividualRateOfChangeChart
                  {...props}
                  participantsChange={participantsChange}
                  isInReport={includeOutcomeCharts.some(
                    (item) => item.type === 'INDIVIDUAL'
                  )}
                  onChangeInReport={(inReport) =>
                    onChangeInReport(inReport, 'INDIVIDUAL')
                  }
                />
              </>
            );
          }
        }
      } else {
        if (isPrePostChart === 0) {
          return (
            <>
              {isParticipantsEmpty ? (
                <EmptyContainer msg="No data in that method." />
              ) : (
                <>
                  <AggregateChart
                    {...props}
                    participants={participants}
                    isInReport={includeOutcomeCharts.some(
                      (item) =>
                        item.type ===
                        (METHOD_ID === 12 ? 'INTERCEPT_AGGREGATE' : 'AGGREGATE')
                    )}
                    isDisabledInReportGrpBtn={isDisabledInReportGrpBtn}
                    onChangeInReport={(inReport) =>
                      onChangeInReport(
                        inReport,
                        METHOD_ID === 12 ? 'INTERCEPT_AGGREGATE' : 'AGGREGATE'
                      )
                    }
                  />
                  <SeparateChart
                    {...props}
                    participants={participants}
                    isInReport={includeOutcomeCharts.some(
                      (item) =>
                        item.type ===
                        (METHOD_ID === 12 ? 'INTERCEPT_SEPERATE' : 'SEPERATE')
                    )}
                    isDisabledInReportGrpBtn={isDisabledInReportGrpBtn}
                    onChangeInReport={(inReport) =>
                      onChangeInReport(
                        inReport,
                        METHOD_ID === 12 ? 'INTERCEPT_SEPERATE' : 'SEPERATE'
                      )
                    }
                  />
                </>
              )}
            </>
          );
        } else {
          if (
            !loading &&
            isParticipantsChangeEmpty &&
            isParticipantsPrePostChangeEmpty
          ) {
            return <EmptyContainer msg="No data in that method." />;
          } else {
            if (participantsChangePrePost || participantsChange) {
              return (
                <>
                  <TrendRateOfChangeChart
                    {...props}
                    participantsChangePrePost={participantsChangePrePost}
                    isInReport={includeOutcomeCharts.some(
                      (item) => item.type === 'TREND'
                    )}
                    isDisabledInReportGrpBtn={isDisabledInReportGrpBtn}
                    onChangeInReport={(inReport) =>
                      onChangeInReport(inReport, 'TREND')
                    }
                  />
                  <IndividualRateOfChangeChart
                    {...props}
                    participantsChange={participantsChange}
                    isInReport={includeOutcomeCharts.some(
                      (item) => item.type === 'INDIVIDUAL'
                    )}
                    isDisabledInReportGrpBtn={isDisabledInReportGrpBtn}
                    onChangeInReport={(inReport) =>
                      onChangeInReport(inReport, 'INDIVIDUAL')
                    }
                  />
                </>
              );
            }
          }
        }
      }
    }
  };

  return (
    <CustomSegment
      title={`Outcomes Reported ${item && item.title ? `: ${item.title}` : ''}`}
      className="outcomes-reported"
    >
      {/* if prepost then check postpre and individual and make load */}
      <Segment
        loading={
          item && item.prePost ? loading || individualRateLoading : loading
        }
      >
        {renderGraphs()}
      </Segment>
    </CustomSegment>
  );
};
