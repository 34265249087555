import './activity.scss';

import React from 'react';

import { AvField, AvForm } from 'availity-reactstrap-validation';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import Alert from 'reactstrap/lib/Alert';
import { bindActionCreators } from 'redux';
import { Grid, Loader, Segment, Button, Modal, Icon } from 'semantic-ui-react';

import { CustomSegment, CustomTooltip } from '../../../components';
import { get, isEmpty } from '../../../utils/lodash';
import { putActivityTheory } from '../../../store/actions';
import { getPageDisable } from '../../../utils';
import { getGrantTrialDefinedTexts } from '../../../utils/pageText';

class ActivityTheoryCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        tocKnown: props.selected ? props.selected.tocKnown : '',
        tocDo: props.selected ? props.selected.tocDo : '',
      },
      openPreviewModal: false,
    };
  }

  onChange(value, type) {
    let { form } = this.state;
    form[type] = value;
    this.setState({ form });
  }

  onBlurInput(type) {
    let { putActivityTheory, theory, data, target } = this.props;
    const { form } = this.state;
    data[target] = Object.assign(data[target], { [type]: form[type] });
    try {
      putActivityTheory(`theory${theory}`, data, target);
    } catch (error) {
      NotificationManager.error('Something went wrong');
    }
  }

  noObjective() {
    return <Alert color="warning">Please select an objective</Alert>;
  }

  noGoal() {
    return <Alert color="warning">Please select an goal</Alert>;
  }

  openPreviewModal() {
    this.setState((prevState) => ({
      openPreviewModal: !prevState.openPreviewModal,
    }));
  }

  render() {
    let {
      goal,
      aim,
      selected,
      currentActivity,
      user,
      helpTips,
      KnowingTipKey,
      weWillTipKey,
      policy,
    } = this.props;

    const setGoal = goal ? goal : this.noGoal();

    const defaultValues = {
      aiming: get(selected, 'outcome.aiming', ''),
      knowing: get(selected, 'outcome.knowing', ''),
      tocKnown: get(selected, 'tocKnown', ''),
      tocDo: get(selected, 'tocDo', ''),
    };

    const pageDisable = getPageDisable(
      currentActivity,
      user,
      'project.edit.theoryOfChange'
    );
    return (
      <>
        <Modal
          size="tiny"
          open={this.state.openPreviewModal}
          closeIcon={
            <Icon
              onClick={() => this.openPreviewModal()}
              name="close"
              className="closeicon"
            />
          }
        >
          <Modal.Header>
            <label className="modal-toc-preview-header">
              Theory of Change - {policy.policyName}{' '}
            </label>
          </Modal.Header>
          <Modal.Content>
            <div className="modal-toc-content">
              <div>
                <strong>Aim for </strong>
                {aim ? (
                  aim
                ) : (
                  <span className="toc-knowing">[Selected outcome]</span>
                )}
                <strong> and knowing </strong>
                {isEmpty(defaultValues.tocKnown) ? (
                  <span className="toc-knowing">
                    [Summarise the strongest points of evidence that leads to
                    the activity]
                  </span>
                ) : (
                  defaultValues.tocKnown
                )}
                <strong> we will </strong>
                {isEmpty(defaultValues.tocDo) ? (
                  <span className="toc-knowing">
                    [Describe the activity required to achieve the objective]
                  </span>
                ) : (
                  defaultValues.tocDo
                )}
              </div>
            </div>
          </Modal.Content>
        </Modal>
        <AvForm className="activity-form" model={defaultValues}>
          <Grid>
            <Grid.Row className="theory-goal-desc-row">
              <Grid.Column mobile={16} tablet={16} computer={9}>
                <div className="theory-container-single">
                  <label className="goal-label">Goal</label>
                  <label className="theory-description">{setGoal}</label>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Segment className="content-segment">
            <Grid>
              <Grid.Row className="textarea-theory">
                <Grid.Column
                  className="theory-textarea-container-single"
                  mobile={16}
                  tablet={16}
                  computer={9}
                >
                  <AvField
                    label={
                      <CustomTooltip
                        state={get(user, 'options.tips')}
                        contentObject={{
                          data: helpTips,
                          key: KnowingTipKey,
                        }}
                        position="top left"
                        wide="very"
                      >
                        <div>
                          <strong>Aiming for </strong>
                          {isEmpty(
                            selected.outcome && selected.outcome.contentFormat
                          )
                            ? '[Selected outcome]'
                            : selected.outcome.contentFormat}
                          <strong> and knowing</strong>
                        </div>
                      </CustomTooltip>
                    }
                    className="theory-textarea"
                    name="tocKnown"
                    type="textarea"
                    placeholder={getGrantTrialDefinedTexts(
                      get(currentActivity, 'parentId', null),
                      'Summarise the strongest points of evidence that leads to the activity',
                      5
                    )}
                    onChange={(e) => this.onChange(e.target.value, 'tocKnown')}
                    onBlur={() => this.onBlurInput('tocKnown')}
                    disabled={
                      !pageDisable || currentActivity.statusId === 1
                        ? goal && aim && !pageDisable
                          ? false
                          : true
                        : pageDisable
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="textarea-theory">
                <Grid.Column
                  className="theory-textarea-container-single"
                  mobile={16}
                  tablet={16}
                  computer={9}
                >
                  <AvField
                    label={
                      <CustomTooltip
                        state={get(user, 'options.tips')}
                        contentObject={{
                          data: helpTips,
                          key: weWillTipKey,
                        }}
                        position="top left"
                        wide="very"
                      >
                        <strong>we will</strong>
                      </CustomTooltip>
                    }
                    className="theory-textarea"
                    name="tocDo"
                    placeholder={getGrantTrialDefinedTexts(
                      get(currentActivity, 'parentId', null),
                      'Describe the activity required to achieve the objective',
                      6
                    )}
                    type="textarea"
                    onChange={(e) => this.onChange(e.target.value, 'tocDo')}
                    onBlur={() => this.onBlurInput('tocDo')}
                    disabled={
                      currentActivity.statusId === 1
                        ? goal && aim && !pageDisable
                          ? false
                          : true
                        : pageDisable
                    }
                  />
                </Grid.Column>
                <Grid.Column
                  className="theory-textarea-container-single"
                  mobile={8}
                  tablet={8}
                  computer={8}
                >
                  <Button
                    className="Primary-Button"
                    type="button"
                    onClick={() => this.openPreviewModal()}
                  >
                    Preview Theory of Change
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </AvForm>
      </>
    );
  }
}

class ActivityTheory extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { currentActivity } = this.props;
    let objective = currentActivity.objective || [];

    const primaryPolicyId = get(currentActivity, 'primaryPolicyId', null);
    const secondaryPolicyId = get(currentActivity, 'secondaryPolicyId', null);

    const primaryPolicyObjective = primaryPolicyId
      ? objective.find(
          (item) =>
            get(item, 'outcome.policyId', null) === primaryPolicyId &&
            primaryPolicyId !== null
        )
      : null;

    let secondaryPolicyObjective = null;
    if (secondaryPolicyId) {
      const filteredObjectives = objective.filter(
        (item) =>
          get(item, 'outcome.policyId', null) === secondaryPolicyId &&
          secondaryPolicyId !== null
      );

      if (filteredObjectives && filteredObjectives.length === 1) {
        [secondaryPolicyObjective] = filteredObjectives;
      } else if (filteredObjectives.length === 2) {
        secondaryPolicyObjective = filteredObjectives[1];
      }
    }

    const form = {
      goal: currentActivity.goal ? currentActivity.goal : null,
      objectiveOne: primaryPolicyObjective
        ? primaryPolicyObjective.outcome
          ? primaryPolicyObjective.outcome.title
          : null
        : null,
      objectiveTwo: secondaryPolicyObjective
        ? secondaryPolicyObjective.outcome
          ? secondaryPolicyObjective.outcome.title
          : null
        : null,
    };
    return (
      <>
        <CustomSegment
          className="create-theory-of-change"
          title={`Create theory of change - ${
            currentActivity.primaryPolicy
              ? currentActivity.primaryPolicy.policyName
              : ''
          }`}
          children={
            <ActivityTheoryCard
              data={objective ? objective : []}
              selected={primaryPolicyObjective ? primaryPolicyObjective : {}}
              target={0}
              goal={form.goal}
              aim={form.objectiveOne}
              theory="One"
              KnowingTipKey="33"
              weWillTipKey="35"
              currentState="project.edit.theoryOfChange"
              policy={currentActivity.primaryPolicy}
              {...this.props}
            />
          }
          rightHeader={
            <Loader
              active={
                this.props.actPut.theoryOne
                  ? this.props.actPut.theoryOne
                  : false
              }
              inline
            />
          }
        />
        {!currentActivity.secondaryPolicyId ? null : (
          <CustomSegment
            className="create-theory-of-change"
            title={`Create theory of change - ${
              currentActivity.secondaryPolicy
                ? currentActivity.secondaryPolicy.policyName
                : ''
            }`}
            children={
              <ActivityTheoryCard
                data={objective ? objective : []}
                selected={
                  secondaryPolicyObjective ? secondaryPolicyObjective : {}
                }
                target={1}
                goal={form.goal}
                aim={form.objectiveTwo}
                theory="Two"
                KnowingTipKey="34"
                weWillTipKey="36"
                currentState="project.edit.theoryOfChange"
                policy={currentActivity.secondaryPolicy}
                {...this.props}
              />
            }
            rightHeader={
              <Loader
                active={
                  this.props.actPut.theoryTwo
                    ? this.props.actPut.theoryTwo
                    : false
                }
                inline
              />
            }
            extraMargin
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    extra: state.extra,
    user: state.authentication.user,
    helpTips: state.extra.activityHelpTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      putActivityTheory,
    },
    dispatch
  );
};

ActivityTheory = connect(mapStateToProps, mapDispatchToProps)(ActivityTheory);

export { ActivityTheory };
