import '../admin.scss';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Grid, Tab } from 'semantic-ui-react';
import { CustomSegment } from '../../../components';
import { ADMIN_USER, APP_NAME } from '../../../utils/constant';
import { find, isEmpty } from '../../../utils/lodash';
import { exportSystemUsers } from '../../../store/actions';
import { Broadcast, Communication, Organizations, ParentUsers } from './forms';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const Organisations = ({ match }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authentication);

  useEffect(() => {
    try {
      if (match.params.type) {
        changeRoute(match.params.type);
      }
    } catch (error) {
      console.error(error);
    }
  }, [match.params.type]);

  useEffect(() => {
    document.title = `User & Organisations | ${APP_NAME}`;
  }, []);

  const generateTabs = () => {
    const panes = [
      {
        menuItem: 'Accounts',
        render: () => (
          <Tab.Pane attached={false} className="custom-tab">
            <CustomSegment
              title="Manage Account"
              children={<Organizations />}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Users',
        render: () => (
          <Tab.Pane attached={false} className="custom-tab">
            <ParentUsers />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Communication',
        render: () => (
          <Tab.Pane attached={false} className="custom-tab">
            {auth.user.role === 'admin' ? (
              <CustomSegment
                title="Broadcast Notification - Super Admin"
                children={<Broadcast />}
              />
            ) : (
              <>
                <CustomSegment
                  extraMargin
                  title="Broadcast Notification"
                  className="broadcast-notification"
                  children={<Communication isBroadcast={true} />}
                />
                <CustomSegment
                  extraMargin
                  title="Received Notification"
                  children={<Communication />}
                />
              </>
            )}
          </Tab.Pane>
        ),
      },
    ];

    return panes;
  };

  const onTabClick = (data) => {
    changeRoute(data.activeIndex);
  };

  // const onClickUsersExport = async () => {
  //   await dispatch(exportSystemUsers());
  //       const { usersCsvLink } = this.props;
  //   if (!isEmpty(usersCsvLink)) {
  //     window.open(usersCsvLink.download_link, '_blank');
  //   }
  // };

  const changeRoute = async (value) => {
    try {
      const tab = await find(ADMIN_USER, (o) => {
        return o.id === value || o.route === value;
      });
      setCurrentTab(tab.id);
      navigate(`/user-accounts/${tab.route}`);
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <Grid.Row>
      <Tab
        menu={{ borderless: true, attached: false, tabular: false }}
        panes={generateTabs()}
        className={isMobile ? 'custom-tabs-mobile' : 'custom-tabs'}
        activeIndex={currentTab}
        onTabChange={(e, data) => onTabClick(data)}
      />
    </Grid.Row>
  );
};

export default Organisations;
