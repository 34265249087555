import React, { useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';

const CopyText = (props) => {
  const { text } = props;

  const [copied, setCopied] = useState(false);
  const [popupContent, setPopupContent] = useState('Copy');
  const [open, setOpen] = useState(false);

  const handleCopyText = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        setPopupContent('Copied');
        setOpen(true);
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setTimeout(() => {
          setCopied(false);
          setPopupContent('Copy');
          setOpen(false);
        }, 500);
      });
  };

  return (
    <Popup
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => {
        if (!copied) {
          setOpen(false);
        }
      }}
      trigger={
        <Icon
          className="activity-actions-warn ml-2"
          name="copy outline"
          onClick={() => {
            handleCopyText();
          }}
        />
      }
      content={popupContent}
      position="right center"
    />
  );
};

export default CopyText;
