import './auth.scss';

import React from 'react';

import { AvField, AvForm } from 'availity-reactstrap-validation';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Container, Grid, Dimmer, Loader } from 'semantic-ui-react';
import { APP_NAME } from '../../utils/constant';
import { userRegister, registerRequest } from '../../store/actions';

class UserRegistration extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      errorHandling: null,
    };
  }

  async componentDidMount() {
    document.title = `User Registration | ${APP_NAME}`;
    try {
      const { registerRequest, navigate } = this.props;
      const query = queryString.parse(this.props.location.search);
      await registerRequest(query.req || null, navigate);
      this.form && this.form.reset();
    } catch (err) {
      console.error(err);
    }
  }

  async handleValidSubmit(event, values) {
    try {
      const { requestedRegUser, userRegister, navigate } = this.props;

      const data = {
        userId: requestedRegUser.id,
        firstName: values.firstName,
        surname: values.lastName,
        password: values.password,
      };
      await userRegister(data, navigate);
      this.setState({ notification: true, error: null });
    } catch (err) {
      console.error(err);
      this.setState({ errorHandling: err });
    }
  }

  render() {
    const {
      registerLoading,
      requestedRegUser,
      registerTokenLoading,
      errorHandling,
    } = this.props;

    return (
      <Grid.Row>
        <Container
          className="right-heading-container"
          loading={registerTokenLoading}
        >
          <Dimmer active={registerTokenLoading} inverted>
            <Loader content="Loading" />
          </Dimmer>
          {registerTokenLoading ? null : (
            <>
              <label className="headerText">You Are Invited!</label>
              <br></br>
              <label className="headerDescription">
                You have been invites to Takso. Complete the registration to
                continue
              </label>
              <br></br>
              {errorHandling ? (
                <label className={'errorBox'}>
                  <label className={'errorBoxContent'}>{errorHandling}</label>
                </label>
              ) : null}
              <AvForm
                className={'form-group-conatiner'}
                ref={(c) => (this.form = c)}
                onValidSubmit={(event, values) =>
                  this.handleValidSubmit(event, values)
                }
                model={{
                  firstName: requestedRegUser.firstName,
                  lastName: requestedRegUser.surname,
                }}
                autoComplete="off"
              >
                <AvField
                  label="FIRST NAME"
                  placeholder="Enter your first name"
                  className="signin-inputs"
                  name="firstName"
                  type="text"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'First name is required',
                    },
                  }}
                  autoComplete="nope"
                />

                <AvField
                  label="SURNAME"
                  placeholder="Enter your Surname"
                  className="signin-inputs"
                  name="lastName"
                  type="text"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Surname is required',
                    },
                  }}
                  autoComplete="nope"
                />

                <AvField
                  label="PASSWORD"
                  className="signin-inputs"
                  name="password"
                  placeholder="7+ characters"
                  type="password"
                  errorMessage="Please enter valid password"
                  minLength="7"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Password is required',
                    },
                  }}
                  autoComplete="new-password"
                />

                <Button
                  type="submit"
                  className="btn btn-primary signin-button"
                  loading={registerLoading}
                  disabled={registerLoading}
                >
                  Submit
                </Button>
              </AvForm>
            </>
          )}
        </Container>
      </Grid.Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.authentication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      registerRequest,
      userRegister,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRegistration);
