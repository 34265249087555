import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { APP_NAME } from '../../utils/constant';
import { ReportSelector } from './reportHome';
import {
  getAllAdvancedFilterRelatedApi,
  getAllReportData,
} from '../../store/actions';
import './reports.scss';
import { FeatureWrapper, UnAuthorizedAccess } from '../../components';

class Reports extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    document.title = `Reports | ${APP_NAME}`;
    const { getAllReportData, getAllAdvancedFilterRelatedApi, user } =
      this.props;
    //ADVANCED FILTER RELATED DATA
    await Promise.all([
      getAllReportData(),
      getAllAdvancedFilterRelatedApi(user.organisation.id),
    ]);
  }

  render() {
    return (
      <FeatureWrapper
        featureName="report"
        children={<ReportSelector {...this.props} />}
        fallbackComponent={<UnAuthorizedAccess />}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllReportData,
      getAllAdvancedFilterRelatedApi,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
