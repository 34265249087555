import './auth.scss';

import React, { useEffect, useState } from 'react';
import { Button, Grid, Icon, Form, Container } from 'semantic-ui-react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import { APP_NAME, LOGIN_ENCRYPTION_KEY } from '../../utils/constant';
import { loginUser } from '../../store/actions';
import { Formik } from 'formik';
import { CustomInput, scrollToFirstErrorField } from '../../components/form';
import {
  createErrorObject,
  formData,
  validationSchema,
} from '../../validations/signIn-validation';
import { userAuth } from '../../utils';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

const SignIn = (props) => {
  const authentication = useSelector((state) => state.authentication);
  // inject authentication data to props
  props = { ...props, ...authentication };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    checkbox: false,
    email: null,
    password: null,
    redirectToReferrer: false,
    errorHandling: null,
    isShownPass: false,
    redirectUrl: '/dashboard',
  });

  useEffect(() => {
    document.title = `Sign In | ${APP_NAME}`;

    const redirectUrl = get(
      props.location.state,
      'redirectRoute',
      '/dashboard'
    );
    const logoutReason = get(props.location.state, 'logoutReason', null);

    setState((prevState) => ({
      ...prevState,
      redirectUrl,
      errorHandling: logoutReason,
    }));
  }, [props.location]);

  const handleValidSubmit = async (values) => {
    try {
      const { redirectUrl } = state;
      await dispatch(
        loginUser({
          username: CryptoJS.AES.encrypt(
            values.email,
            LOGIN_ENCRYPTION_KEY
          ).toString(),
          password: CryptoJS.AES.encrypt(
            values.password,
            LOGIN_ENCRYPTION_KEY
          ).toString(),
        })
      );

      userAuth.authenticate(() => {
        setState((prevState) => ({ ...prevState, redirectToReferrer: true }));
      });
      navigate(redirectUrl);
    } catch (error) {
      console.error('test', error);
      setState((prevState) => ({ ...prevState, errorHandling: error }));
    }
  };

  const onSubmit = async (values) => {
    await handleValidSubmit(values);
  };

  const { userLoginLoading } = authentication;
  let { from } = props.location?.state || { from: { pathname: '/' } };
  let { redirectToReferrer, errorHandling, isShownPass } = state;

  if (redirectToReferrer) return <Navigate to={from} />;

  return (
    <Grid.Row className={'signin-page-wrapper'}>
      <Container className="right-heading-container">
        <label className="headerText">Hello!</label>
        <label className="headerDescription">Please sign in to continue.</label>
        <br></br>

        {errorHandling ? (
          <label className="errorBox">
            <label className="errorBoxContent">{errorHandling}</label>
          </label>
        ) : null}

        <Formik
          onSubmit={(values) => {
            onSubmit(values);
          }}
          initialValues={formData}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, errors, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              {scrollToFirstErrorField(createErrorObject(errors), isSubmitting)}
              <CustomInput
                id="email"
                name="email"
                placeholder="Email"
                labelText="Email"
                type="text"
                disabled={userLoginLoading}
              />
              <CustomInput
                id="password"
                name="password"
                placeholder="Password"
                labelText="Password"
                disabled={userLoginLoading}
                type={isShownPass ? 'text' : 'password'}
                icon={
                  <Icon
                    name={isShownPass ? 'eye' : 'eye slash'}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        isShownPass: !isShownPass,
                      }));
                    }}
                    link
                  />
                }
              />
              <Link className="forgot" to="/forgot-password">
                Forgot password?
              </Link>{' '}
              <Button
                loading={userLoginLoading}
                disabled={userLoginLoading}
                content={'  Sign In'}
                type="submit"
                className="btn btn-primary signin-button"
              />
              <label className={'alreadyContent'}>
                {'New user? Contact us at '}
                <a
                  href="mailto:support@takso-app.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <label className={'signin-label'}>
                    support@takso-app.com
                  </label>
                </a>
              </label>
            </Form>
          )}
        </Formik>
      </Container>
    </Grid.Row>
  );
};

export default SignIn;
