import * as Yup from 'yup';
import { DOMAIN_REG_EX, EMAIL_REG_EX, PHONE_REG_EX } from '../utils/regex';

export const getOrgProfileInitialValues = (organization) => {
  return {
    name: organization && organization.name ? organization.name : '',
    industrySectorTypeId:
      organization && organization.industrySectorTypeId
        ? organization && organization.industrySectorTypeId
        : '',
    phone: organization && organization.phone ? organization.phone : '',
    domain: organization && organization.domain ? organization.domain : '',
    email: organization && organization.email ? organization.email : '',

    country: organization && organization.country ? organization.country : '',
    streetAddress:
      organization && organization.streetAddress
        ? organization.streetAddress
        : '',
    streetAddress2:
      organization && organization.streetAddress2
        ? organization && organization.streetAddress2
        : '',
    citySuburb:
      organization && organization.citySuburb ? organization.citySuburb : '',
    stateId: organization && organization.stateId ? organization.stateId : '',
    postcode:
      organization && organization.postcode ? organization.postcode : '',
    postalStreetAddress:
      organization && organization.postalStreetAddress
        ? organization.postalStreetAddress
        : '',
    postalStreetAddress2:
      organization && organization.postalStreetAddress2
        ? organization.postalStreetAddress2
        : '',
    postalCitySuburb:
      organization && organization.postalCitySuburb
        ? organization.postalCitySuburb
        : '',
    postalStateId:
      organization && organization.postalStateId
        ? organization.postalStateId
        : '',
    postalPostcode:
      organization && organization.postalPostcode
        ? organization.postalPostcode
        : '',
  };
};

export const profileValidationSchema = Yup.object().shape({
  name: Yup.string().required('Organization name is required!'),
  phone: Yup.string()
    .required('Phone number is required')
    .matches(PHONE_REG_EX, 'Please enter the valid format')
    .min(10, 'Must be a minimum of 10 digits!'),
  domain: Yup.string()
    .required('Web Domain is required!')
    .matches(DOMAIN_REG_EX, 'Please enter the valid format'),
  email: Yup.string()
    .required('Email is required')
    .matches(EMAIL_REG_EX, 'Please enter the valid format'),

  country: Yup.string().required('Country is required'),
  streetAddress: Yup.string().required('Street Address is required'),
  citySuburb: Yup.string().required('City / Suburb is required'),
  stateId: Yup.string().required('State is required'),
  postcode: Yup.string().required('Postal Code is required'),
});

export const profileValidationSchemaWithDifferentPostalAddress =
  Yup.object().shape({
    name: Yup.string().required('Organization name is required!'),
    phone: Yup.string()
      .required('Phone number is required')
      .matches(PHONE_REG_EX, 'Please enter the valid format')
      .min(10, 'Must be a minimum of 10 digits!'),
    domain: Yup.string()
      .required('Web Domain is required!')
      .matches(DOMAIN_REG_EX, 'Please enter the valid format'),
    email: Yup.string()
      .required('Email is required')
      .matches(EMAIL_REG_EX, 'Please enter the valid format'),
    country: Yup.string().required('Country is required'),
    streetAddress: Yup.string().required('Street Address is required'),
    citySuburb: Yup.string().required('City / Suburb is required'),
    stateId: Yup.string().required('State is required'),
    postcode: Yup.string().required('Postal Code is required'),
    postalStreetAddress: Yup.string()
      .required('Postal Address is required')
      .nullable(),
    postalCitySuburb: Yup.string()
      .required('Postal City / Suburb is required')
      .nullable(),
    postalStateId: Yup.string().required('Postal State is required').nullable(),
    postalPostcode: Yup.string()
      .required('Postal Postcode is required')
      .nullable(),
  });
