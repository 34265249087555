import axios from 'axios';
import { cloneDeep } from '../../utils/lodash';
import { LIMIT, DASHBOARD_LIMIT } from '../../utils/constant';
import { authMsg, getCommonError } from '../../utils';
import { getUserProfile } from './auth';

/*** TYPES ***/

const STAT_USER_LOADING = 'STAT_USER_LOADING';
const STAT_USER = 'STAT_USER';
const REMINDER_USER_LOADING = 'REMINDER_USER_LOADING';
const REMINDER_USER = 'REMINDER_USER';

const NOTIFICATION_USER_LOADING = 'NOTIFICATION_USER_LOADING';
const NOTIFICATION_USER = 'NOTIFICATION_USER';

const GET_USERS_CSV = 'GET_USERS_CSV';
const GET_ORGANIZATION_USERS = 'GET_ORGANIZATION_USERS';

const SET_NOTIFICATION_LOADING = 'SET_NOTIFICATION_LOADING';
const GET_BROADCAST_NOTIFICATION = 'GET_BROADCAST_NOTIFICATION';
const GET_NOTIFICATION = 'GET_NOTIFICATION';

const GET_NOTIFICATION_ALL = 'GET_NOTIFICATION_ALL';

const SET_GUEST_USER_LOADING = 'SET_GUEST_USER_LOADING';
const DELETE_NOTIFICATION_LOADING = 'DELETE_NOTIFICATION_LOADING';

const UPDATE_USER_ONBOARDING_OPTION = 'UPDATE_USER_ONBOARDING_OPTION';

const SEND_EMAIL_VERIFICATION_LOADING = 'SEND_EMAIL_VERIFICATION_LOADING';

/*** REDUCERS ***/

const initialState = {
  loadingStat: false,
  statUser: {},
  loadingReminders: false,
  userReminders: {},
  loadingNotification: false,
  userNotifications: [],
  updatingUser: false,
  usersCsvLink: {},
  broadcastMessage: {},
  notifications: {},
  notificationsLoading: {},
  organizationUsers: [],
  guestUserLoading: false,
  notificationsAll: {},
  deleteNotificationLoading: false,
  updateUserOnboardingOption: {},
  sendEmailVerificationLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STAT_USER_LOADING:
      return { ...state, loadingStat: action.payload };
    case STAT_USER:
      return { ...state, statUser: action.payload };
    case REMINDER_USER_LOADING:
      return { ...state, loadingReminders: action.payload };
    case REMINDER_USER:
      return { ...state, userReminders: action.payload };

    case NOTIFICATION_USER_LOADING:
      return { ...state, loadingNotification: action.payload };
    case NOTIFICATION_USER:
      return { ...state, userNotifications: action.payload };
    case GET_USERS_CSV:
      return { ...state, usersCsvLink: action.payload };
    case GET_ORGANIZATION_USERS:
      return { ...state, organizationUsers: action.payload };

    case GET_BROADCAST_NOTIFICATION:
      return { ...state, broadcastMessage: action.payload };
    case GET_NOTIFICATION_ALL:
      return { ...state, notificationsAll: action.payload };
    case GET_NOTIFICATION:
      return { ...state, notifications: action.payload }; //add a variable here , and another switch case
    //create a new redux func
    case SET_NOTIFICATION_LOADING:
      return {
        ...state,
        notificationsLoading: {
          ...state.notificationsLoading,
          [action.payload.type]: action.payload.status,
        },
      };

    case SET_GUEST_USER_LOADING:
      return { ...state, guestUserLoading: action.payload };

    case DELETE_NOTIFICATION_LOADING:
      return { ...state, deleteNotificationLoading: action.payload };

    case UPDATE_USER_ONBOARDING_OPTION:
      return { ...state, updateUserOnboardingOption: action.payload };
    case SEND_EMAIL_VERIFICATION_LOADING:
      return { ...state, sendEmailVerificationLoading: action.payload };
    default:
      return state;
  }
}

export function getAllDashboardData() {
  return (dispatch) => {
    Promise.all([
      dispatch(getUserActivityStat()),
      dispatch(getUserReminders(1)),
      dispatch(getNotifications()),
      dispatch(getNotificationsAll(1)),
    ]);
  };
}

export function upgradeGuestUser(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_GUEST_USER_LOADING, payload: true });
      await axios.post(`/guestUser`, values);
      authMsg('success', ['Upgraded Successfully!', 'Organisation']);
      dispatch(getUserProfile());
      dispatch({ type: SET_GUEST_USER_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_GUEST_USER_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getUserActivityStat() {
  return async (dispatch) => {
    try {
      dispatch({ type: STAT_USER_LOADING, payload: true });
      const response = await axios.get(`/user/activity-stats`);
      dispatch({ type: STAT_USER_LOADING, payload: false });
      dispatch({ type: STAT_USER, payload: response.data });
    } catch (error) {
      dispatch({ type: STAT_USER_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getUserReminders(page = 1, limit = DASHBOARD_LIMIT) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch({ type: REMINDER_USER_LOADING, payload: true });
      const response = await axios.get(`/user/reminders`, {
        params: {
          limit,
          page,
        },
      });
      const currentReminders = cloneDeep(state.user.userReminders);
      const reminders =
        page === 1
          ? response.data
          : {
              ...response.data,
              docs: [...currentReminders.docs, ...response.data.docs],
            };
      dispatch({ type: REMINDER_USER, payload: reminders }); //concatnate here
      dispatch({ type: REMINDER_USER_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: REMINDER_USER_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function exportSystemUsers() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`users/list`);
      dispatch({ type: GET_USERS_CSV, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getBroadcastNotifications(
  page = 1,
  limit = LIMIT,
  isOwnOrganisation = false
) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_NOTIFICATION_LOADING,
        payload: { type: 'broadcast', status: true },
      });
      const response = await axios.get(`/broadcast`, {
        params: {
          limit,
          page,
          isOwnOrganisation,
        },
      });
      dispatch({
        type: SET_NOTIFICATION_LOADING,
        payload: { type: 'broadcast', status: false },
      });
      dispatch({
        type: GET_BROADCAST_NOTIFICATION,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SET_NOTIFICATION_LOADING,
        payload: { type: 'broadcast', status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function createBroadcastNotifications(formData) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_NOTIFICATION_LOADING,
        payload: { type: 'broadcast', status: true },
      });
      await axios.post(`/broadcast`, formData);
      authMsg('success', [
        'Notification has been sent.',
        'Broadcast Notification ',
      ]);
      dispatch({
        type: SET_NOTIFICATION_LOADING,
        payload: { type: 'broadcast', status: false },
      });
      dispatch(getBroadcastNotifications());
    } catch (error) {
      dispatch({
        type: SET_NOTIFICATION_LOADING,
        payload: { type: 'broadcast', status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getNotificationsAll(page = 1, limit = 3) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch({
        type: SET_NOTIFICATION_LOADING,
        payload: { type: 'notification', status: true },
      });
      const response = await axios.get(`/notification/me`, {
        params: {
          limit,
          page,
        },
      });
      const currentNotification = cloneDeep(state.user.notificationsAll);
      const notifications =
        page === 1
          ? response.data
          : {
              ...response.data,
              docs: [...currentNotification.docs, ...response.data.docs],
            };
      dispatch({
        type: SET_NOTIFICATION_LOADING,
        payload: { type: 'notification', status: false },
      });
      dispatch({ type: GET_NOTIFICATION_ALL, payload: notifications });
    } catch (error) {
      dispatch({
        type: SET_NOTIFICATION_LOADING,
        payload: { type: 'notification', status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getNotifications(page = 1, limit = DASHBOARD_LIMIT) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_NOTIFICATION_LOADING,
        payload: { type: 'notification', status: true },
      });
      const response = await axios.get(`/notification/me`, {
        params: {
          limit,
          page,
        },
      });
      dispatch({
        type: SET_NOTIFICATION_LOADING,
        payload: { type: 'notification', status: false },
      });
      dispatch({ type: GET_NOTIFICATION, payload: response.data });
    } catch (error) {
      dispatch({
        type: SET_NOTIFICATION_LOADING,
        payload: { type: 'notification', status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateNotificationReadStatus(notificationId, dashboard) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_NOTIFICATION_LOADING,
        payload: { type: 'notification', status: true },
      });
      await axios.put(`/notification/${notificationId}/read`);

      dispatch({
        type: SET_NOTIFICATION_LOADING,
        payload: { type: 'notification', status: false },
      });

      if (dashboard) {
        const page = 1;
        const limit = 3;
        dispatch(getNotificationsAll(page, limit));
      } else {
        dispatch(getNotificationsAll());
      }
      // NOTE: uncomment if notifications is used to get the notification msg in the Dashboard component
      // dispatch(getNotifications());
    } catch (error) {
      dispatch({
        type: SET_NOTIFICATION_LOADING,
        payload: { type: 'notification', status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteUserNotification(notificationId) {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_NOTIFICATION_LOADING, payload: true });
      await axios.delete(`/notification/${notificationId}/delete`);
      const page = 1;
      const limit = 3;
      dispatch(getNotificationsAll(page, limit));
      dispatch({ type: DELETE_NOTIFICATION_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: DELETE_NOTIFICATION_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function setUserOnboardingOption(formObj) {
  return async (dispatch) => {
    try {
      const formattedObj = {
        id: formObj.id,
        onboardingOption: formObj.onboardingOption,
      };

      const _payload = await axios.put('/user/onboarding-option', formattedObj);
      dispatch({ type: UPDATE_USER_ONBOARDING_OPTION, payload: _payload.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function sendEmailVerification() {
  return async (dispatch) => {
    try {
      dispatch({ type: SEND_EMAIL_VERIFICATION_LOADING, payload: true });
      await axios.post('/user/send-email-activation');

      authMsg('success', [
        'Verification has been sent. Please check your email',
        'Email Verification ',
      ]);

      dispatch({ type: SEND_EMAIL_VERIFICATION_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SEND_EMAIL_VERIFICATION_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}
