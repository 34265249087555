/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isGrantProgramActivity } from '../../../../../../utils/activity';
import { Segment, Dropdown, Icon, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  CustomTooltip,
  CustomTable,
  SeeMore,
} from '../../../../../../components';
import {
  getLinkedActivities,
  deleteProjectedLinkedActivity,
  deleteLinkedActivity,
  activateActivity,
  activationToggleActivity,
  updateProjectedActivity,
  changeLinkedActivityOwnership,
  reInviteLinkActivity,
} from '../../../../../../store/actions';
import {
  getActivityStatus,
  getPageDisable,
  getCriticalStatus,
  getScaleStatus,
  getDisableAndRemoveAbility,
  activityTypeName,
} from '../../../../../../utils';
import {
  isEmpty,
  map,
  get,
  flattenDeep,
  compact,
  uniq,
  size,
  some,
} from '../../../../../../utils/lodash';
import getMinifiedText from '../../../../../../utils/minifiedText';
import EditLinkedActivity from '../modals/editLinkedActivity';
import { DeleteLinkedActivity } from '../modals/deleteLinkedActivity';
import ChangeOwnership from '../modals/changeOwnership';
import ProjectedActivityView from '../modals/viewProjectedActivity';
import ProjectedActivityDelete from '../modals/deleteProjectedActivity';
import DeactivateLinkedActivity from '../modals/deactivateLinkedActivity';
import ViewLinkedActivity from '../modals/viewLinkedActivity';

class ViewLinkedActivities extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      activity: null,
      currentPage: 1,
      currentPageRows: [],
    };
    this.rowPerPage = 5;
  }

  componentDidMount() {
    this.setRowData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.linkedActivities !== prevProps.linkedActivities) {
      const row = this.getRows();
      this.setState({
        currentPageRows: row.slice(0, this.rowPerPage),
        currentPage: 1,
      });
    }
  }

  async setRowData() {
    try {
      const { getLinkedActivities, currentActivity } = this.props;
      await getLinkedActivities(currentActivity.id);

      const row = this.getRows();

      this.setState({
        currentPageRows: row.slice(0, this.rowPerPage),
        currentPage: 1,
      });
    } catch (error) {
      console.error(error);
    }
  }

  tableHeaderToolTip(name, key) {
    const { user, helpTips } = this.props;
    return (
      <CustomTooltip
        state={get(user, 'options.tips')}
        contentObject={{ data: helpTips, key }}
      >
        <span>{name}</span>
      </CustomTooltip>
    );
  }

  getScale(data) {
    const getScale = get(data, 'scale', []);
    if (isEmpty(getScale)) return '-';
    let [first, second] = getScale;
    if (first) {
      // eslint-disable-next-line
      if (data.activity.objective[0].outcome.id != first.outcomeId) {
        const temp = first;
        first = second;
        second = temp;
      }
    }
    let scale = '';
    map(first, (item, i) => {
      if (i !== 'outcomeId') scale += `${getScaleStatus(i)} ${item} `;
    });
    let scaleTwo = '';
    map(second, (item, i) => {
      if (i !== 'outcomeId') scaleTwo += `${getScaleStatus(i)} ${item} `;
    });
    return (
      <div className="mt-1">
        {!isEmpty(scale) ? <div style={{ width: '125px' }}>{scale}</div> : '-'}
        {!isEmpty(scaleTwo) && <div style={{ width: '125px' }}>{scaleTwo}</div>}
      </div>
    );
  }

  customSeeMoreComponent(props) {
    const { item } = props;
    return <div className="program-objective">{item}</div>;
  }

  generateColumns(currentActivity, currentState) {
    const { user } = this.props;

    const columns = compact([
      {
        // width: 4,
        title: this.tableHeaderToolTip('Activity Name', '120'),
        render: (data) => {
          return (
            <div className="activity-name" style={{ alignItems: 'start' }}>
              <CustomTooltip
                content="Click on text to View the Activity"
                state={get(user, 'options.tips')}
              >
                <div
                  className={`circle circle-bottom activity-${
                    data.projected
                      ? 'outline'
                      : getActivityStatus(data.statusId)
                  }`}
                  style={{
                    marginTop: '0.313rem',
                    marginLeft: '0rem',
                  }}
                />
              </CustomTooltip>

              <div>
                {data.activity.deActive ? (
                  // currentActivity.activityPlanTypeId === 4 &&
                  // isSingleLinkedActivity(
                  //   data.activity.activityPlanTypeId,
                  //   data.activity.isLinkedActivity
                  // ) ? (
                  //   pageDisable ? (
                  //     data.activityName
                  //   ) : (
                  //     <ViewLinkedActivity
                  //       projected={data.projected}
                  //       activity={data.activity}
                  //       {...this.props}
                  //     />
                  //   )
                  // ) : (
                  <EditLinkedActivity
                    activity={data.activity}
                    projected={data.projected}
                    currentState={currentState}
                    reSetFilters={this.props.reSetFilters}
                    {...this.props}
                    trigger={
                      <label
                        style={{ marginBottom: '0rem' }}
                        className="activity-name-trigger"
                      >
                        {data.activityName ? data.activityName : ''}
                      </label>
                    }
                  />
                ) : // )
                data.isAllowedToEnter ? (
                  <Link to={`/activities/${data.activity.id}`} target="_blank">
                    <label
                      style={{ marginBottom: '0rem' }}
                      className="activity-name-trigger"
                    >
                      {data.activityName}
                    </label>
                  </Link>
                ) : (
                  <>
                    <label
                      style={{ marginBottom: '0rem' }}
                      className="activity-name-not-allowed"
                    >
                      {data.activityName}
                    </label>
                  </>
                )}
                <div>
                  <label className="date-display">
                    {data.dateRange && data.dateRange}
                  </label>
                </div>
              </div>
            </div>
          );
        },
        // textAlign: isBrowser || isTablet ? 'center' : null
        textAlign: 'left',
      },
      {
        width: 2,
        title: this.tableHeaderToolTip('Contact/Organisation', ''),
        render: (data) => {
          const { activity } = data;

          return (
            <>
              <p className="mb-0">{get(activity, 'owner.name', '-')}</p>
              <div className="activity-name">
                <label className="date-display">
                  {get(activity, 'owner.organisation.name', '-')}
                </label>
              </div>
            </>
          );
        },
        textAlign: 'left',
      },
      {
        width: 3,
        title: 'Type',
        render: (data) => {
          return activityTypeName(
            data.activityPlanTypeId,
            data.isLinkedActivity
          );
        },
        textAlign: 'left',
      },
      {
        // width: 3,
        title: this.tableHeaderToolTip('Objectives', '119'),
        render: (data) => {
          return !isEmpty(data.objectives) ? (
            data.objectives.length < 4 ? (
              map(data.objectives, (objective, index) => {
                return (
                  <div
                    key={`table_objectives_${index}`}
                    className="program-activities-objective"
                  >
                    {objective}
                  </div>
                );
              })
            ) : (
              <div className="program-activities-objective-container">
                <SeeMore
                  minCount={3}
                  dataSet={data.objectives}
                  CustomComponent={this.customSeeMoreComponent}
                  isView={false}
                />
              </div>
            )
          ) : (
            '-'
          );
        },
        textAlign: 'left',
      },
      {
        width: 1,
        title: this.tableHeaderToolTip('Status', '121'),
        render: (data) => {
          return (
            <div>
              <div>{data.status}</div>
              {data.status === 'Accepted' ? (
                <div className="scale">{this.getScale(data)}</div>
              ) : (
                <div className="percentage">
                  <div>
                    {!data.projected && `${get(data, 'percentage', 0)}%`}
                  </div>
                  {data.note && (
                    <div className="per-right">
                      <Popup
                        content={data.note.label ? data.note.label : null}
                        trigger={
                          <Icon
                            name="warning circle"
                            color={getCriticalStatus(data.note.criticality)}
                            className="icon-org-summary"
                          ></Icon>
                        }
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        },
        textAlign: 'left',
      },
      {
        width: 1,
        title: '',
        render: (data) => {
          return (
            <div className="owned">
              <Dropdown
                icon="ellipsis vertical"
                direction="left"
                inline
                selectOnBlur={false}
                floating
              >
                <Dropdown.Menu>{data.report}</Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ]);
    if (isGrantProgramActivity(currentActivity.activityPlanTypeId)) {
      columns.splice(3, 0, {
        width: 2,
        title: this.tableHeaderToolTip('Grant ID', ''),
        render: (data) => {
          return <div>{data.grantId === null ? '-' : data.grantId}</div>;
        },
        textAlign: 'left',
      });
    }

    return columns;
  }

  getRows() {
    try {
      const { linkedActivities, user, currentActivity, actual } = this.props;

      const currentState = 'project.edit.activityPlan';

      const rows =
        linkedActivities &&
        map(linkedActivities, (linkedActivity, index) => {
          const { linkedDeactivationData } = linkedActivity;

          let disableAndRemoveAbility;
          if (linkedDeactivationData) {
            disableAndRemoveAbility = getDisableAndRemoveAbility(
              linkedActivity.statusId,
              user.id,
              linkedDeactivationData.ownerId,
              linkedDeactivationData.haveResponses
            );
          }

          const isLinkedActivityMember = some(
            linkedActivity.members,
            (item) => item.userId === user.id
          );
          const isAllowedToEnter =
            isLinkedActivityMember && !linkedActivity.deleted;

          let project = {
            tableKey: get(linkedActivity, 'deActive', false)
              ? `projected_${linkedActivity.id}`
              : `active_${linkedActivity.id}`,
            activity: linkedActivity,
            isAllowedToEnter,
            activityName: getMinifiedText(linkedActivity.name, 40),
            activityType: get(linkedActivity, 'eventDetail.detail', '-'),
            dateRange:
              linkedActivity.activityStartDate && linkedActivity.activityEndDate
                ? `${moment(linkedActivity.activityStartDate).format(
                    'DD MMM YYYY'
                  )} - ${moment(linkedActivity.activityEndDate).format(
                    'DD MMM YYYY'
                  )}`
                : linkedActivity.eventStartDate && linkedActivity.eventEndDate
                  ? `${moment(linkedActivity.eventStartDate).format(
                      'DD MMM YYYY'
                    )} - ${moment(linkedActivity.eventEndDate).format(
                      'DD MMM YYYY'
                    )}`
                  : '',
            note: get(linkedActivity.status, 'note', null),
            projected: get(linkedActivity, 'deActive', false),
            percentage: get(linkedActivity.status, 'percentage', null),
            scale: linkedActivity.scale && linkedActivity.scale,
            status: linkedActivity.deActive
              ? 'Projected'
              : linkedActivity.childApproved
                ? 'Accepted'
                : linkedActivity.deleted
                  ? 'Deactivated'
                  : linkedActivity.review
                    ? 'Review'
                    : 'Activated',
            statusId: linkedActivity.statusId ? linkedActivity.statusId : 3,
            grantId: linkedActivity.grantId,
            activityPlanTypeId: linkedActivity.activityPlanTypeId,
            isLinkedActivity: linkedActivity.isLinkedActivity,
            report: linkedActivity.deActive ? (
              <div className="activity-actions-dropdown">
                <ProjectedActivityView
                  activity={linkedActivity}
                  {...this.props}
                />
                {!getPageDisable(currentActivity, user, currentState, actual) && // check page access
                (!linkedDeactivationData || // projected data not contains linkedDeactivationData
                  (linkedDeactivationData &&
                    disableAndRemoveAbility.isAbleToRemove)) ? (
                  // Activity remove container
                  <ProjectedActivityDelete
                    activity={linkedActivity}
                    linkedActivitiesLoading={this.props.linkedActivitiesLoading}
                    {...this.props}
                  />
                ) : null}
              </div>
            ) : (
              <div className="activity-actions-dropdown">
                {isAllowedToEnter ? (
                  <div>
                    {/* TODO: need to remove below code */}
                    {/* {currentActivity.activityPlanTypeId === 4 ? (
                      <ViewLinkedActivity
                        projected={get(linkedActivity, 'deActive', false)}
                        activity={linkedActivity}
                        {...this.props}
                        isFromDropDown={true}
                      />
                    ) : ( */}
                    <div>
                      <ViewLinkedActivity
                        activity={linkedActivity}
                        projected={get(linkedActivity, 'deActive', false)}
                        currentState={currentState}
                        reSetFilters={this.props.reSetFilters}
                        {...this.props}
                        trigger={
                          <Dropdown.Item className="activity-actions-dropdown-view">
                            <label className="activity-actions-text-native-color">
                              Activation Information
                            </label>
                          </Dropdown.Item>
                        }
                      />
                    </div>
                    {/* )} */}
                  </div>
                ) : null}

                <Link
                  to={`/activity/${linkedActivity.id}/preview`}
                  target="_blank"
                >
                  <Dropdown.Item className="activity-actions-dropdown-view">
                    <label className="activity-actions-text">View Report</label>
                    <Icon
                      name="external alternate"
                      size="small"
                      color="black"
                    />
                  </Dropdown.Item>
                </Link>
                {/* tooltip Deactivate container  */}
                {!getPageDisable(currentActivity, user, currentState, actual) &&
                  isAllowedToEnter && (
                    <ChangeOwnership
                      key={linkedActivity.id}
                      activity={linkedActivity}
                      currentActivity={currentActivity}
                    />
                  )}

                {isLinkedActivityMember &&
                  (linkedActivity.deleted === 1 ||
                  (linkedDeactivationData &&
                    disableAndRemoveAbility.isAbleToDeactivate) ? (
                    <DeactivateLinkedActivity
                      currentActivity={currentActivity}
                      activity={linkedActivity}
                      {...this.props}
                    />
                  ) : null)}

                {isLinkedActivityMember &&
                  linkedActivity.statusId !== 3 && // check if not completed
                  linkedActivity.deleted === 1 && // check is already deactivated
                  linkedDeactivationData &&
                  disableAndRemoveAbility.isAbleToRemove && (
                    //check is able to remove
                    <DeleteLinkedActivity
                      activity={linkedActivity}
                      deleteLinkedActivity={this.props.deleteLinkedActivity}
                    />
                  )}
              </div>
            ),
          };

          if (!isEmpty(linkedActivity.primaryParentObjectives)) {
            const allObjectives = [
              ...get(linkedActivity, 'primaryParentObjectives', []),
              ...get(linkedActivity, 'secondaryParentObjectives', []),
            ];

            project.objectives = compact(allObjectives);
          } else if (!isEmpty(linkedActivity.objective)) {
            project.objectives = map(linkedActivity.objective, (p) =>
              get(p, 'outcome.title', '-')
            );
          } else {
            project.objectives = compact([
              get(linkedActivity, 'objective1', null),
              get(linkedActivity, 'objective2', null),
            ]);
          }

          if (!isEmpty(linkedActivity.evaluationPlans)) {
            const existingObjectives = uniq(
              compact(
                flattenDeep(
                  map(linkedActivity.evaluationPlans, (p) => {
                    return map(p.evaluationQuestions, (q) =>
                      get(q, 'outcome.title', '-')
                    );
                  })
                )
              )
            );

            project.evalPlan =
              size(existingObjectives) === size(project.objectives)
                ? true
                : false;
          } else {
            project.evalPlan = false;
          }

          if (linkedActivity.guestOwner) {
            const firstName = get(linkedActivity.guestOwner, 'firstName', '');
            const surname = get(linkedActivity.guestOwner, 'surname', '');
            project.contact = `${firstName} ${surname}`;
          } else {
            project.contact = linkedActivity.firstName
              ? linkedActivity.surname
                ? `${linkedActivity.firstName} ${linkedActivity.surname}`
                : linkedActivity.firstName
              : linkedActivity.surname
                ? linkedActivity.surname
                : '-';
          }

          return project;
        });
      return rows;
    } catch (error) {
      return [];
    }
  }

  pageChange(page) {
    const row = this.getRows();

    const lastIndex = page * this.rowPerPage;
    const firstIndex = lastIndex - this.rowPerPage;
    const currentPageRows = row.slice(firstIndex, lastIndex);

    this.setState({
      currentPage: page,
      currentPageRows,
    });
  }

  render() {
    const { linkedActivitiesLoading, currentActivity, linkedActivities } =
      this.props;
    const { currentPage, currentPageRows } = this.state;
    const currentState = 'project.edit.activityPlan';

    return (
      <Segment className="program-activities" loading={linkedActivitiesLoading}>
        <CustomTable
          header
          columns={this.generateColumns(currentActivity, currentState) || []}
          data={currentPageRows}
          customClass="grant-evaluation-table"
          emptyMessage="You have not created any linked activities yet…"
          keyExtractor={(item, index) => {
            return item.tableKey;
          }}
          pagination
          handlePaginationChange={(page) => this.pageChange(page)}
          page={currentPage}
          noOfPages={Math.ceil(linkedActivities.length / this.rowPerPage)}
        />
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    user: state.authentication.user,
    outcomes: state.extra.outcomes,
    policies: state.extra.policies,
    helpTips: state.extra.activityHelpTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getLinkedActivities,
      deleteProjectedLinkedActivity,
      deleteLinkedActivity,
      activateActivity,
      activationToggleActivity,
      updateProjectedActivity,
      changeLinkedActivityOwnership,
      reInviteLinkActivity,
    },
    dispatch
  );
};

ViewLinkedActivities = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewLinkedActivities);

export { ViewLinkedActivities };
