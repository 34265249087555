import * as Yup from 'yup';
import { SUPPORT_DOCUMENT_TYPES } from '../utils/fileTypes';

const FILE_SIZE = 50 * 1024;

export const getOrgCoreDataInitialValues = (orgcoreData) => {
  return {
    // reportYear:
    //   orgcoreData && orgcoreData.reportYear
    //     ? orgcoreData && orgcoreData.reportYear
    //     : '',
    notes: orgcoreData && orgcoreData.notes ? orgcoreData.notes : '',
    documentFile: orgcoreData && orgcoreData.notes ? orgcoreData.notes : '',
  };
};
export const profileValidationSchemaWithDifferentNotes = Yup.object().shape({
  // notes: Yup.string().required('Notes are required!'),
});

export const coreDataValidationSchema = Yup.object().shape({
  notes: Yup.string().required('Notes are required!'),
  documentFile: Yup.string().required('document file is required!'),
  //reportYear: Yup.string().required('Report year is required!'),
});

export const fileDocumentValidationSchema = (state) => {
  return Yup.object().shape({
    documentTitle: Yup.string().required('Title is required!'),
    documentDescription: Yup.string().required('Description is required!'),
    documentFile: Yup.mixed()

      .test('fileSize', 'File too large', (value) => {
        // if is it updating no need to trigger this validation
        if (state.isUpdate) return true;
        else if (!value) {
          return false;
        } else {
          const sizeComparison = Math.round(value.size / 1000) <= FILE_SIZE;
          return sizeComparison;
        }
      })
      .test('fileFormat', 'Unsupported File Format', (value) => {
        // if is it updating no need to trigger this validation
        if (state.isUpdate) return true;
        else if (!value) {
          return false;
        } else return SUPPORT_DOCUMENT_TYPES.includes(value.type);
      })
      .when([], () => {
        // if there is already uploaded file no need to trigger this validation
        if (!state.documentId || state.documentId === '')
          return Yup.string().required('Document file is required');
      }),
  });
};
