import { Grid, Tab, Menu } from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';
import { React } from 'react';
import { APP_NAME, ADMIN_TABS } from '../../../utils/constant';
import { CustomSegment } from '../../../components';
import EvaluationMethods from './forms/evaluationMethods';
import EvaluationQuestions from './forms/evaluationQuestions';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ParentOutcomes, ParentEventDetails, OutputFormatter } from './forms';
import { find } from '../../../utils/lodash';
import '../admin.scss';
import { useNavigate } from 'react-router-dom';
import { getPolicies, getEventTypes } from '../../../store/actions';

const ActivitySettingAdmin = (props) => {
  const [currentTab, setCurrentTab] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //const auth = useSelector(state => state.authentication);
  //const user = useSelector(state => state.authentication.user);
  const eventTypes = useSelector((state) => state.extra.eventTypes);
  const policies = useSelector((state) => state.extra.policies);
  //const helpTips = useSelector(state => state.extra.activityHelpTips);

  useEffect(() => {
    dispatch(getEventTypes());
    dispatch(getPolicies());
  }, [dispatch]);

  useEffect(() => {
    try {
      const { match } = props;

      if (match.params.type) {
        changeRoute(match.params.type);
      }
    } catch (error) {
      console.error('error', error);
    }
  }, [props]);

  // useEffect(() => {
  //   const { getEventTypes, getPolicies } = props;
  //   document.title = `Activity Settings | ${APP_NAME}`;
  //   getEventTypes();
  //   getPolicies();
  // }, [props]);

  const generateTabs = () => {
    document.title = `Activity Settings | ${APP_NAME}`;

    const panes = [
      {
        menuItem: 'Outcomes',
        render: () => (
          <Menu.Item className="admin-menu-item no-print">
            <CustomSegment
              title="Outcomes"
              children={<ParentOutcomes policies={policies} />}
              className="org-activity-segment"
            />
          </Menu.Item>
        ),
      },
      {
        menuItem: 'Evaluation Methods',
        render: () => (
          <Menu.Item className="admin-menu-item no-print">
            <CustomSegment
              title="Evaluation Methods"
              children={<EvaluationMethods />}
              className="org-activity-segment"
            />
          </Menu.Item>
        ),
      },
      {
        menuItem: 'Evaluation Questions',
        render: () => (
          <Menu.Item className="admin-menu-item no-print">
            <CustomSegment
              title="Evaluation Questions"
              children={<EvaluationQuestions />}
              className="org-activity-segment"
            />
          </Menu.Item>
        ),
      },

      {
        menuItem: 'Output Formatters',
        render: () => (
          <Menu.Item className="admin-menu-item no-print">
            <CustomSegment
              title="Output Formatters"
              children={<OutputFormatter />}
            />
          </Menu.Item>
        ),
      },
      {
        menuItem: 'Activity Types',
        render: () => (
          <Menu.Item className="admin-menu-item no-print">
            <CustomSegment
              title="Activity Types"
              children={<ParentEventDetails eventTypes={eventTypes} />}
              className="org-activity-segment"
            />
          </Menu.Item>
        ),
      },
    ];

    return panes;
  };

  const onTabClick = (data) => {
    changeRoute(data.activeIndex);
  };

  // const onClickUsersExport = async () => {
  //   await props.exportSystemUsers();
  //   const { usersCsvLink } = props;
  //   if (!isEmpty(usersCsvLink)) {
  //     window.open(usersCsvLink.download_link, '_blank');
  //   }
  // };

  const changeRoute = async (value) => {
    try {
      const tab = await find(ADMIN_TABS, (o) => {
        return o.id === value || o.route === value;
      });
      setCurrentTab(tab.id);
      navigate(`/admin-activity-settings/${tab.route}`);
    } catch (error) {
      console.error('ActivitySettingAdmin ~ changeRoute ~ error', error);
      return false;
    }
  };

  return (
    <Grid.Row>
      <Tab
        menu={{ borderless: true, attached: false, tabular: false }}
        panes={generateTabs()}
        className={isMobile ? 'custom-tabs-mobile' : 'custom-tabs-admin'}
        activeIndex={currentTab}
        onTabChange={(e, data) => onTabClick(data)}
      />
    </Grid.Row>
  );
};

export default ActivitySettingAdmin;
