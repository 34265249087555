import './auth.scss';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Grid } from 'semantic-ui-react';
import { SuccessContainer } from '../../components';

class OrganizationWelcome extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Grid.Row>
        {/* <Grid.Column mobile={16} tablet={5} computer={5} />
        <Grid.Column mobile={16} tablet={6} computer={6}> */}
        <Container className="email-verification-container">
          <Grid>
            <Grid.Row>
              <SuccessContainer
                header={'Hello!'}
                msg={'Thank you for signed up with takso.'}
                description={[
                  <span>We got your account details successfully.</span>,
                  <br />,
                  <span>One of our agent will be touch with you.</span>,
                ]}
                success={true}
                button="Back to home page"
                buttonRoute="/signin"
              />
            </Grid.Row>
          </Grid>
        </Container>
        {/* </Grid.Column>
        <Grid.Column mobile={16} tablet={5} computer={5} /> */}
      </Grid.Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationWelcome);
