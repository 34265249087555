/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useField } from 'formik';
import { Icon, Input } from 'semantic-ui-react';
import {
  LOWERCASE_REG_EX,
  NUMERIC_REG_EX,
  SPECIAL_CHARACTER_REG_EX,
  UPPERCASE_REG_EX,
} from '../../../utils/regex';

export const CustomPasswordInput = ({
  labelText,
  getValue,
  requiredStar = false,
  ...props
}) => {
  const [isShownPass, setIsShownPass] = React.useState(false);
  const [field, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;

  const isLengthError = value.length === 0;
  const isUppercase = !UPPERCASE_REG_EX.test(value);
  const isLowercaseError = !LOWERCASE_REG_EX.test(value);
  const isNumericError = !NUMERIC_REG_EX.test(value);
  const isSpecialCharacterError = !SPECIAL_CHARACTER_REG_EX.test(value);

  return (
    <div className="formik-custom-field ">
      <label
        className={requiredStar ? 'required-stars' : ''}
        htmlFor={props.id || props.name}
      >
        {labelText}
      </label>
      <Input
        {...field}
        {...props}
        onChange={(e, data) => {
          setValue(data.value);
          getValue && getValue(data.value);
        }}
        value={value}
        error={meta.error}
        type={isShownPass ? 'text' : 'password'}
        icon={
          <Icon
            name={isShownPass ? 'eye' : 'eye slash'}
            onClick={() => {
              setIsShownPass(!isShownPass);
            }}
            link
          />
        }
      />
      {
        <div className="my-2">
          <div
            className={
              meta.error === 'LENGTH_ERROR' || isLengthError
                ? 'password-fail'
                : 'password-passed'
            }
          >
            <Icon name="check"></Icon>
            At least 8 characters
          </div>
          <div
            className={
              meta.error === 'UPPERCASE_ERROR' || isUppercase
                ? 'password-fail'
                : 'password-passed'
            }
          >
            <Icon name="check"></Icon>
            At least 1 upper case letter (A...Z)
          </div>{' '}
          <div
            className={
              meta.error === 'LOWERCASE_ERROR' || isLowercaseError
                ? 'password-fail'
                : 'password-passed'
            }
          >
            <Icon name="check"></Icon>
            At least 1 lower case letter (a...z)
          </div>{' '}
          <div
            className={
              meta.error === 'NUMERIC_ERROR' || isNumericError
                ? 'password-fail'
                : 'password-passed'
            }
          >
            <Icon name="check"></Icon>
            At least 1 number (0...9)
          </div>{' '}
          <div
            className={
              meta.error === 'SPECIAL_CHARACTER_ERROR' ||
              isSpecialCharacterError
                ? 'password-fail'
                : 'password-passed'
            }
          >
            <Icon name="check"></Icon>
            At least 1 special character
          </div>
        </div>
      }
    </div>
  );
};
