import './auth.scss';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from 'semantic-ui-react';
import leftIcon from '../../assets/circle-quarterCircle.svg';
import rightIcon from '../../assets/circle-rectangle.svg';
import loginLogo from '../../assets/whitebox-login-logo.svg';

class Layout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children, fullPage, rightComponent = null } = this.props;
    return (
      <div>
        {!fullPage ? (
          <Grid columns={2} stackable relaxed="very" className="auth">
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={8}
              className={'signInFormCol'}
            >
              <a href="https://www.takso-outcomes.com">
                <img
                  alt="taksoLoginLogo"
                  className="taksoLoginLogo"
                  src={loginLogo}
                />
              </a>
              {children}
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={8}
              className={'fixedSideDetails'}
            >
              <img alt="letIcon" src={leftIcon} className={'letIcon'} />
              {rightComponent ? rightComponent : null}

              <img alt="rightIcon" src={rightIcon} className={'rightIcon'} />
            </Grid.Column>
          </Grid>
        ) : (
          <Grid className={'auth'}>
            <a
              href="https://www.takso-outcomes.com"
              className={'whiteboxLoginLogo'}
            >
              <img alt="loginLogo" src={loginLogo} />
            </a>
            {children}
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
