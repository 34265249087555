import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Segment, Modal, Button, Icon } from 'semantic-ui-react';
import { EmptyContainer, CustomTable } from '../../../components';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import './organization.scss';
import {
  getOrgChecklistReport,
  createChecklistReport,
  updateChecklistReport,
  deleteChecklistReport,
} from '../../../store/actions';

class ChecklistForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleValidSubmit(event, values) {
    const {
      user,
      isNew,
      toggle,
      createChecklistReport,
      updateChecklistReport,
      checklistRecord,
    } = this.props;
    if (isNew) {
      createChecklistReport(values);
    } else {
      updateChecklistReport(checklistRecord.id, values);
    }
    this.form && this.form.reset();
    toggle();
  }

  render() {
    const { toggle, checklistRecord = {} } = this.props;
    return (
      <AvForm
        ref={(c) => (this.form = c)}
        model={{
          question: checklistRecord.question || null,
        }}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
      >
        <AvField
          label="Checklist Question"
          className="org-inputs"
          name="question"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: 'question is required',
            },
          }}
        />

        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button content="Save" type="submit" className="Primary-Button" />
        </div>
      </AvForm>
    );
  }
}

class ChecklistDelete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  handleChecklistDelete() {
    const { user, deleteChecklistReport, checklistRecord } = this.props;
    deleteChecklistReport(checklistRecord.id);
    this.toggle();
  }

  render() {
    const { modal } = this.state;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        onClose={() => this.toggle()}
        size="mini"
        trigger={
          <label className="org-checklist-actions-error first-down">
            Remove
          </label>
        }
      >
        <Modal.Header>Delete Checklist Item</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this checklist item?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="modal-cancel"
            onClick={() => this.toggle()}
            negative
          >
            No
          </Button>
          <Button
            className="modal-proceed"
            onClick={() => this.handleChecklistDelete()}
            content="Yes"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

class ChecklistUpdate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    return (
      <Modal
        trigger={<label className="org-checklist-actions-warn">Edit</label>}
        size="small"
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
      >
        <Modal.Header>Edit Checklist Item</Modal.Header>
        <Modal.Content>
          <ChecklistForm
            {...this.props}
            isNew={false}
            toggle={() => this.toggle()}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

class ChecklistCreate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    return (
      <div className="add-goal">
        <Modal
          trigger={
            <Button
              onClick={() => this.toggle()}
              className="Primary-Button add-checklist-item-btn"
            >
              <Icon name="plus" />
              Add Checklist Item
            </Button>
          }
          size="small"
          open={modal}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
          className="add-new-checklist-item"
        >
          <Modal.Header>Add New Checklist Item</Modal.Header>
          <Modal.Content>
            <ChecklistForm
              {...this.props}
              isNew={true}
              toggle={() => this.toggle()}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class OrganizationChecklistReport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'Item',
          width: 15,
          render: (data) => {
            return (
              <label className="org-checklist-text">
                {data.question ? data.question || '-' : '-'}
              </label>
            );
          },
        },
        {
          title: '',
          width: 1,
          render: (data) => {
            return (
              <div className="org-checklist-actions">
                <ChecklistUpdate {...this.props} checklistRecord={data} />
                <ChecklistDelete {...this.props} checklistRecord={data} />
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    const { getOrgChecklistReport, user } = this.props;
    getOrgChecklistReport();
  }

  render() {
    const { columns } = this.state;
    const {
      customLoading: { checkListReportLoading },
      checkListReport = [],
    } = this.props;
    return (
      <Segment
        className="content-segment org-checklist"
        loading={checkListReportLoading}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              textAlign="left"
              verticalAlign="bottom"
            >
              {/* <label className="org-checklist-updated">
                Last Updated on {moment(updatedAt).format('DD MMMM YYYY HH:MM')}
              </label> */}
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8} textAlign="right">
              <ChecklistCreate {...this.props} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {checkListReport && checkListReport.length ? (
              <Grid.Column>
                <CustomTable
                  header
                  columns={columns}
                  data={checkListReport}
                  customClass="org-checklist-table"
                  keyExtractor={(item, index) => item.id}
                />
              </Grid.Column>
            ) : (
              <EmptyContainer msg="No checklist have been added …" />
            )}
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  const { organization, authentication } = state;
  return {
    user: authentication.user,
    organization: organization.organization,
    customLoading: organization.customLoading,
    checkListReport: organization.checkListReport,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrgChecklistReport,
      createChecklistReport,
      updateChecklistReport,
      deleteChecklistReport,
    },
    dispatch
  );
};

OrganizationChecklistReport = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationChecklistReport);

export { OrganizationChecklistReport };
