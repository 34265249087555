import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Segment } from 'semantic-ui-react';
import { changePassword } from '../../store/actions';
import PasswordChange from '../../components/passwordChange';

class ChangePassword extends React.PureComponent {
  async handleValidSubmit(values) {
    if (values.confirmPassword) delete values.confirmPassword;
    await this.props.changePassword(values);
    this.form && this.form.reset();
  }

  render() {
    const loading = this.props.auth.updatePasswordLoading;

    return (
      <Segment loading={loading}>
        <Grid.Row className="profile-row">
          <PasswordChange
            loading={loading}
            handleValidSubmit={(values) => this.handleValidSubmit(values)}
          />
        </Grid.Row>
      </Segment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      changePassword,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
