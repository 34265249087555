export const scrollToFirstErrorField = (errors, isSubmitting) => {
  if (!isSubmitting) return;
  for (const val in errors) {
    if (errors[val] !== null) {
      const errorInput = document.getElementById(val);
      if (errorInput !== null)
        errorInput.scrollIntoView({ behavior: 'smooth' });
      break;
    }
  }
};
