import React, { useEffect, useState } from 'react';

import { useField } from 'formik';
import { get, isEmpty } from 'lodash';
import { Form, Grid } from 'semantic-ui-react';

export const GroupCheckbox = ({ checked = false, ...props }) => {
  return (
    <>
      <Form.Field
        control="input"
        type="checkbox"
        checked={checked}
        {...props}
      />
    </>
  );
};

export const CheckboxComp = ({
  labelText,
  options,
  requiredStar = false,
  getValue,
  getData,
  disabled = false,
  ...props
}) => {
  const [array, setArray] = useState([]);

  const [, meta] = useField({ ...props, type: 'checkbox' });

  const { value: defaultValues = null } = meta;

  useEffect(() => {
    defaultValues && setArray(defaultValues);
  }, []);

  return (
    <>
      <div className="act-inputs-new p-0 mb-0">
        {labelText && (
          <label
            className={requiredStar && 'required-stars'}
            htmlFor={props.id || props.name}
          >
            {labelText}
          </label>
        )}
      </div>
      {options.map((option, i) => {
        const foundedValue =
          !isEmpty(defaultValues) &&
          defaultValues.find(
            (item) => item && parseInt(item) === parseInt(option.value)
          );

        const checked = Boolean(foundedValue);

        return (
          <Grid.Column key={i} mobile={16} tablet={16} computer={7}>
            <div className=" formik-custom-field act-inputs-new checkbox-group checkbox-padding">
              <GroupCheckbox
                id={`${i}_${props.name}`}
                name={props.name}
                label={option.label}
                checked={checked}
                value={parseInt(option.value)}
                onChange={(e) => {
                  const filteredArray = e.target.checked
                    ? [...array, e.target.value.toString()]
                    : array.filter(
                        (item) => item.toString() !== e.target.value.toString()
                      );

                  setArray(filteredArray);
                  props.setFieldValue(props.name, filteredArray);
                  getValue && getValue(e);
                  getData && getData(option, e.target.checked);
                }}
                disabled={get(option, 'isDisabled', false) || disabled}
              />
            </div>
          </Grid.Column>
        );
      })}
      <div className="formik-custom-field act-inputs-new checkbox-group">
        {meta.touched && meta.error && (
          <div className="invalid-feedback">{meta.error}</div>
        )}
      </div>
      {/* </Form.Group> */}
    </>
  );
};
