/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { stopPropagation } from '../../../../../../utils';
import { Dropdown, Modal, Icon, Button } from 'semantic-ui-react';

export default class ProjectedActivityDelete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async deleteDocument(id) {
    try {
      const { deleteProjectedLinkedActivity, currentActivity } = this.props;
      await deleteProjectedLinkedActivity(currentActivity.id, id);
      this.toggle();
    } catch (error) {
      // handle error here
      throw error(error);
    }
  }

  render() {
    const { modal } = this.state;
    const { activity, deleteLinkedActivitiesLoading } = this.props;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        onClick={(e) => stopPropagation(e)}
        onFocus={(e) => stopPropagation(e)}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="small"
        trigger={
          <Dropdown.Item className="activity-actions-dropdown-delete">
            <label className="activity-actions-text red-text">Remove</label>
          </Dropdown.Item>
        }
      >
        <Modal.Header>Delete activity - {activity.name}</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to remove this projected activity?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => this.toggle()}
            className="activity-activation-cancel-btn"
          >
            No
          </Button>
          <Button
            loading={deleteLinkedActivitiesLoading}
            disabled={deleteLinkedActivitiesLoading}
            // className="delete-proceed-btn"
            onClick={() => this.deleteDocument(activity.id)}
            // positive
            // labelPosition="right"
            // icon="checkmark"
            className="new-collective-save-btn"
            content="Yes"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
