/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { isCurrentOrganisationUser } from '../../../../../../utils/activity';

import { Button } from 'semantic-ui-react';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { NotificationMessage } from '../../../../../../components';

export default class DeactivateLinkedActivityForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      linkedDeactivateLoading: false,
    };
  }

  handleValidSubmit(event, values) {
    try {
      this.setState({ linkedDeactivateLoading: true }, async () => {
        const { activity, activationToggleActivity, toggle } = this.props;
        values.status = Boolean(activity.deleted);
        await activationToggleActivity(activity.parentId, activity.id, values);
        this.setState({ linkedDeactivateLoading: false });
        toggle();
      });
    } catch (error) {
      console.error(
        'ActivityDeActivateForm -> handleValidSubmit -> error',
        error
      );
    }
  }

  render() {
    const { toggle, activity, guestUserOrganisation, permanentDelete, user } =
      this.props;
    const { linkedDeactivateLoading } = this.state;
    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        autoComplete="off"
      >
        {!activity.deleted &&
          guestUserOrganisation !== null &&
          !isCurrentOrganisationUser(
            guestUserOrganisation,
            user.organisationId
          ) && (
            <NotificationMessage
              classNames="notification-segment"
              message={
                permanentDelete
                  ? 'Linked Activity will be permanently removed and assigned to the contact organisation'
                  : 'This owner does not has the permission feature to contribute to this activity type'
              }
              title="Warning"
            />
          )}
        <AvField
          label={`Reason for ${
            activity.deleted ? 'activation' : 'deactivation'
          }`}
          style={{ minHeight: '70px' }}
          name="reason"
          type="textarea"
          validate={{
            required: {
              value: true,
              errorMessage: 'Reason for deactivation is required',
            },
          }}
          autoComplete="off"
        />
        <div className="model-buttons">
          <div>
            {' '}
            <Button
              className="Secondary-Button"
              size="small"
              type="button"
              onClick={() => toggle()}
            >
              Cancel
            </Button>
          </div>
          <div>
            <Button
              loading={linkedDeactivateLoading}
              disabled={linkedDeactivateLoading}
              content={activity.deleted ? 'Activate' : 'Deactivate'}
              type="submit"
              size="small"
              className="Primary-Button"
            />
          </div>
        </div>
      </AvForm>
    );
  }
}
