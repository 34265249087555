import * as Yup from 'yup';
import { EMAIL_REG_EX, PHONE_REG_EX } from '../utils/regex';

export const getPartnerDetailsInitialValues = (partner) => {
  return {
    description: partner && partner.description ? partner.description : '',

    firstName: partner && partner.firstName ? partner.firstName : '',
    email: partner && partner.email ? partner.email : '',
    secondName: partner && partner.secondName ? partner.secondName : '',
    entityName: partner && partner.entityName ? partner.entityName : '',
    streetAddress:
      partner && partner.streetAddress ? partner.streetAddress : '',
    streetAddress2:
      partner && partner.streetAddress2 ? partner.streetAddress2 : '',
    suburb: partner && partner.suburb ? partner.suburb : '',
    state: partner && partner.state ? partner.state : '',
    postCode: partner && partner.postCode ? partner.postCode : '',
    phone: partner && partner.phone ? partner.phone : '',
    abn: partner && partner.abn ? partner.abn : '',
    acn: partner && partner.acn ? partner.acn : '',
    organisationName:
      partner && partner.organisationName ? partner.organisationName : '',
  };
};

export const partnerDetailsExternalValidationSchema = (isFromActivity) => {
  let validationSchema = {
    firstName: Yup.string().optional(),
    secondName: Yup.string().optional(),
    email: Yup.string()
      .required('Email is required!')
      .matches(EMAIL_REG_EX, 'Email is not valid!'),
    phone: Yup.string()
      .required('Phone number is required!')
      .matches(PHONE_REG_EX, 'Phone number is not valid!')
      .min(10, 'Must be a minimum of 10 digits!')
      .optional(),
    entityName: Yup.string().optional(),
    streetAddress: Yup.string().optional(),
    streetAddress2: Yup.string().optional(),
    suburb: Yup.string().optional(),
    state: Yup.string().optional(),
    postcode: Yup.string().optional(),
    supportType: Yup.string().oneOf(['IN_KIND', 'FUNDING', 'NETWORK']),
    description: Yup.string().optional(),
  };

  return Yup.object().shape(validationSchema);
};

export const partnerDetailsGuestValidationSchema = (isFromActivity) => {
  let validationSchema = {
    firstName: Yup.string().required('first name is required'),
    secondName: Yup.string().required('second name is required'),
    email: Yup.string()
      .required('Email is required!')
      .matches(EMAIL_REG_EX, 'Email is not valid!'),
    phone: Yup.string()
      .required('Phone number is required!')
      .matches(PHONE_REG_EX, 'Phone number is not valid!')
      .min(10, 'Must be a minimum of 10 digits!')
      .optional(),
    entityName: Yup.string().optional(),
    organisationName: Yup.string().optional(),
    address: Yup.string().optional(),
    address2: Yup.string().optional(),
    suburb: Yup.string().optional(),
    state: Yup.string().optional(),
    postcode: Yup.string().optional(),
    description: Yup.string().optional(),
  };

  return Yup.object().shape(validationSchema);
};

export const partnerDetailsAnonymousValidationSchema = Yup.object().shape({
  supportType: Yup.string().oneOf(['IN_KIND', 'FUNDING', 'NETWORK']),
  description: Yup.string().optional(),
});
