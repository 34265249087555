import * as Yup from 'yup';
import { SUPPORT_DOCUMENT_TYPES } from '../utils/fileTypes';
import { URL_REG_EX } from '../utils/regex';

const FILE_SIZE = 50 * 1024;

export const fileDocumentInitialValues = (state) => {
  return {
    documentDescription:
      state.isUpdate && state.documentDescription
        ? state.documentDescription
        : '',
    documentTitle:
      state.isUpdate && state.documentTitle ? state.documentTitle : '',
    documentFile: '',
  };
};

export const linkDocumentInitialValues = (state) => {
  return {
    documentDescription: state.documentDescription
      ? state.isUpdate && state.documentDescription
      : '',
    documentTitle:
      state.isUpdate && state.documentTitle ? state.documentTitle : '',
    documentUrlText:
      state.isUpdate && state.documentUrlText ? state.documentUrlText : '',
  };
};

export const fileDocumentValidationSchema = (state) => {
  return Yup.object().shape({
    documentTitle: Yup.string().required('Title is required!'),
    documentDescription: Yup.string().required('Description is required!'),
    documentFile: Yup.mixed()

      .test('fileSize', 'File too large', (value) => {
        // if is it updating no need to trigger this validation
        if (state.isUpdate) return true;
        else if (!value) {
          return false;
        } else {
          const sizeComparison = Math.round(value.size / 1000) <= FILE_SIZE;
          return sizeComparison;
        }
      })
      .test('fileFormat', 'Unsupported File Format', (value) => {
        // if is it updating no need to trigger this validation
        if (state.isUpdate) return true;
        else if (!value) {
          return false;
        } else return SUPPORT_DOCUMENT_TYPES.includes(value.type);
      })
      .when([], () => {
        // if there is already uploaded file no need to trigger this validation
        if (!state.documentId || state.documentId === '')
          return Yup.string().required('Document file is required');
      }),
  });
};

export const linkDocumentValidationSchema = Yup.object().shape({
  documentTitle: Yup.string().required('Title is required!'),
  documentDescription: Yup.string().required('Description is required!'),
  documentUrlText: Yup.string()
    .required('Link is required!')
    .matches(URL_REG_EX, 'Link is invalid!'),
});
