import React from 'react';

import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Grid, Segment, Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import { getUserProfile, updateUser } from '../../store/actions';
import { CustomInput } from '../../components/form';
import {
  getProfileDetailsInitialValues,
  profileDetailsValidationSchema,
} from '../../validations/profileDetailsValidation';
class ProfileDetails extends React.PureComponent {
  componentDidMount() {
    this.props.getUserProfile();
  }

  async onSubmit(values) {
    let newValues = { ...values };
    const { getUserProfile, updateUser } = this.props;
    if (newValues.hasOwnProperty('email')) delete newValues.email;
    if (newValues.hasOwnProperty('department')) delete newValues.department;
    if (newValues.hasOwnProperty('unit')) delete newValues.unit;

    await updateUser(newValues);
    getUserProfile();
  }

  render() {
    const {
      auth: { updatingUser, user },
    } = this.props;

    const loading = updatingUser;

    return (
      <Segment loading={loading}>
        <Formik
          initialValues={getProfileDetailsInitialValues(user)}
          onSubmit={(values) => {
            this.onSubmit(values);
          }}
          validationSchema={profileDetailsValidationSchema}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <Form onSubmit={handleSubmit}>
              <Grid columns={2} stackable>
                <Grid.Row className="profile-row">
                  <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={6}
                    className="profile-sub-column-new"
                  >
                    <CustomInput
                      id="firstName"
                      name="firstName"
                      labelText="First Name"
                      requiredStar
                    />

                    <CustomInput
                      id="email"
                      name="email"
                      labelText="Email"
                      disabled
                      requiredStar
                    />

                    {values.department && (
                      <CustomInput
                        id="department"
                        name="department"
                        labelText="Department"
                        disabled
                      />
                    )}

                    <CustomInput
                      id="positionTitle"
                      name="positionTitle"
                      labelText="Position / Designation"
                    />

                    {isMobile ? null : (
                      <Button
                        disabled={loading}
                        loading={loading}
                        type="submit"
                        className="Primary-Button"
                      >
                        Save
                      </Button>
                    )}
                  </Grid.Column>
                  <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={6}
                    className="profile-sub-column-new sub-columnRight"
                  >
                    <CustomInput
                      id="surname"
                      name="surname"
                      labelText="Surname"
                      requiredStar
                    />

                    <CustomInput
                      id="phone"
                      name="phone"
                      labelText="Contact Number"
                      requiredStar
                    />

                    {values.department && values.unit && (
                      <CustomInput
                        id="unit"
                        name="unit"
                        labelText="Unit"
                        disabled
                      />
                    )}

                    {!isMobile ? null : (
                      <Button
                        disabled={loading}
                        loading={loading}
                        type="submit"
                        className="Primary-Button"
                      >
                        Save
                      </Button>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUserProfile,
      updateUser,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);
