import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Icon, Input } from 'semantic-ui-react';

class DateRangeChart extends React.PureComponent {
  // getFiscalYearTimestamps() {
  //   const { organization } = this.props;
  //   let startMonthName = 'July',
  //     endMonthName = 'June';
  //   if (
  //     organization &&
  //     organization.settings &&
  //     organization.settings[0].financialYear
  //   ) {
  //     startMonthName = 'July';
  //     endMonthName = 'June';
  //     if (moment().quarter() === 4) {
  //       return {
  //         current: {
  //           start: moment()
  //             .month(startMonthName)
  //             .startOf('month'),
  //           end: moment()
  //             .add(1, 'year')
  //             .month(endMonthName)
  //             .endOf('month')
  //         },
  //         last: {
  //           start: moment()
  //             .subtract(1, 'year')
  //             .month(startMonthName)
  //             .startOf('month'),
  //           end: moment()
  //             .month(endMonthName)
  //             .endOf('month')
  //         }
  //       };
  //     } else {
  //       return {
  //         current: {
  //           start: moment()
  //             .month(startMonthName)
  //             .startOf('month'),
  //           end: moment()
  //             .subtract(1, 'year')
  //             .add(1, 'year')
  //             .month(endMonthName)
  //             .endOf('month')
  //         },
  //         last: {
  //           start: moment()
  //             .subtract(1, 'year')
  //             .month(startMonthName)
  //             .startOf('month'),
  //           end: moment()
  //             .subtract(1, 'year')
  //             .month(endMonthName)
  //             .endOf('month')
  //         }
  //       };
  //     }
  //   } else {
  //     startMonthName = 'January';
  //     endMonthName = 'December';
  //     return {
  //       current: {
  //         start: moment()
  //           .month(startMonthName)
  //           .startOf('month'),
  //         end: moment()
  //           .month(endMonthName)
  //           .endOf('month')
  //       },
  //       last: {
  //         start: moment()
  //           .subtract(1, 'year')
  //           .month(startMonthName)
  //           .startOf('month'),
  //         end: moment()
  //           .subtract(1, 'year')
  //           .month(endMonthName)
  //           .endOf('month')
  //       }
  //     };
  //   }
  // }

  getCalendarYearTimestamps() {
    let startMonthName = 'January',
      endMonthName = 'December';
    return {
      current: {
        start: moment().month(startMonthName).startOf('month'),
        end: moment().month(endMonthName).endOf('month'),
      },
      last: {
        start: moment()
          .subtract(1, 'year')
          .month(startMonthName)
          .startOf('month'),
        end: moment().subtract(1, 'year').month(endMonthName).endOf('month'),
      },
    };
  }

  getFinancialYearTimestamps() {
    let startMonthName = 'July',
      endMonthName = 'June';

    let currentMonthIndex = moment().month();
    // let currentMonthIndex = 7; // used this value to test

    let returnObj;
    if (currentMonthIndex > 6) {
      // 6 == 'June'
      returnObj = {
        current: {
          start: moment().month(startMonthName).startOf('month'),
          end: moment().add(1, 'year').month(endMonthName).endOf('month'),
        },
        last: {
          start: moment()
            .subtract(1, 'year')
            .month(startMonthName)
            .startOf('month'),
          end: moment().month(endMonthName).endOf('month'),
        },
      };
    } else {
      returnObj = {
        current: {
          start: moment()
            .subtract(1, 'year')
            .month(startMonthName)
            .startOf('month'),
          end: moment().month(endMonthName).endOf('month'),
        },
        last: {
          start: moment()
            .subtract(2, 'year')
            .month(startMonthName)
            .startOf('month'),
          end: moment().subtract(1, 'year').month(endMonthName).endOf('month'),
        },
      };
    }

    return returnObj;
  }

  render() {
    const { startDate, endDate, datePicker } = this.props;
    return (
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onApply={datePicker}
        ranges={{
          // Today: [
          //   moment().startOf('day'),
          //   moment().endOf('day')
          // ],
          // Yesterday: [
          //   moment().subtract(1, 'days'),
          //   moment().subtract(1, 'days')
          // ],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          // 'Last 30 Days': [
          //   moment().subtract(29, 'days'),
          //   moment()
          // ],
          'Last 28 Days': [moment().subtract(27, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month'),
          ],
          'This Quarter': [moment().startOf('quarter'), moment()],
          'Last Quarter': [
            moment().startOf('quarter').subtract(3, 'month'),
            moment().startOf('quarter'),
          ],
          'Year to Date': [moment().startOf('year'), moment()],
          // 'Last Year': [moment().subtract(12, 'month'), moment()],
          // 'This Fiscal Year': [
          //   this.getFiscalYearTimestamps().current.start,
          //   this.getFiscalYearTimestamps().current.end
          // ],
          // 'Last Fiscal Year': [
          //   this.getFiscalYearTimestamps().last.start,
          //   this.getFiscalYearTimestamps().last.end
          // ],

          'This financial year': [
            this.getFinancialYearTimestamps().current.start,
            this.getFinancialYearTimestamps().current.end,
          ],
          'Last financial year': [
            this.getFinancialYearTimestamps().last.start,
            this.getFinancialYearTimestamps().last.end,
          ],
          'This calendar year': [
            this.getCalendarYearTimestamps().current.start,
            this.getCalendarYearTimestamps().current.end,
          ],
          'Last calendar year': [
            this.getCalendarYearTimestamps().last.start,
            this.getCalendarYearTimestamps().last.end,
          ],

          'Since the Start': [moment('2018-01-01'), moment()],
        }}
      >
        <Input type="text" className="date-picker-btn">
          <span>
            {`${startDate.format('DD MMM YYYY')} to ${endDate.format(
              'DD MMM YYYY'
            )} `}
          </span>

          <Icon name="calendar alternate" />
        </Input>
      </DateRangePicker>
    );
  }
}

export { DateRangeChart };
